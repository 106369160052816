import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import CasesMenu from "../CasesMenu";
import { Medium14Font } from "../../Fonts/Fonts";
import ToggleButton from "../../buttons/ToggleButton/ToggleButton";
import {
  getActiveSubscription,
  getSubscriptions,
  getSubscriptionsAuthorized,
} from "../../../containers/Auth/auth";
import { route } from "../../../routes";
import Prices from "./Prices";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import PaymentHistory from "./PaymentHistory";

const Subscription = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [plans, setPlans] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [is_annually, setIsAnnually] = useState(false);
  const [history_, setHistory] = useState([]);
  const [isCanceled, setIsCanceled] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [newSubscription, setNewSubscription] = useState(null);
  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const dispatch = useDispatch();
  const tabsPanelItems = [
    {
      id: 1,
      text: "Plans",
      activeType: "plans",
    },
    {
      id: 2,
      text: "Payment History",
      activeType: "payment-history",
    },
  ];

  const getDataPlans = async () => {
    const resp = await getSubscriptionsAuthorized(dispatch);
    // console.log("getDataPlans: ", resp);
    if (resp) {
      setPlans(resp);
    }
  };
  const updateActiveSubscriptions = async (data) => {
    setActiveSubscription(data);
    const resp = await getActiveSubscription(dispatch);
    if (resp) {
      setHistory(resp.history);
      setIsCanceled(resp.canceled);
    }
  }
  const getDataActiveSubscription = async () => {
    const resp = await getActiveSubscription(dispatch);
    if (resp) {
      if (resp.data && Object.keys(resp.data).length) {
        setActiveSubscription(resp.data);
      }
      setHistory(resp.history);
      setIsCanceled(resp.canceled);
    }
  };
  const getData = async () => {
    // setIsLoading(true);
    if (!plans && !activeSubscription && firstPageLoad) {
      await getDataPlans();
      await getDataActiveSubscription();
      setIsLoading(false);
      setFirstPageLoad(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event) => {
    setIsAnnually(event.target.checked);
  };

  const button = (
    <div className="d-flex justify-content-between align-items-center mb-3 m-auto">
      <Medium14Font
        style={{ lineHeight: "25px" }}
        className="d-block mr-5 mr-sm-5 mr-md-2"
      >
        Monthly
      </Medium14Font>
      <div className="mt-3">
        <ToggleButton
          checked={is_annually}
          onChange={handleChange}
          className="d-block mx-2"
        />
      </div>
      <Medium14Font
        style={{ lineHeight: "25px" }}
        className="d-block ml-5 ml-sm-5 ml-md-2"
      >
        Annually
      </Medium14Font>
    </div>
  );
  const setActiveItem = (type) => {
    history.push(`${url}/${type}`);
  };

  return (
    <Fragment>
      <CasesMenu
        className="mb-4"
        noButton={true}
        getType={setActiveItem}
        noBadges={true}
        casesMenu={tabsPanelItems}
        active={
          window.location.href.indexOf("/plans") !== -1
            ? "plans"
            : "payment-history"
        }
        customButton={
          window.location.href.indexOf("/plans") !== -1 ? button : null
        }
      />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <PulseLoader size={30} color={theme.colors.blue} />
        </div>
      ) : (
        <Switch>
          <Route exact={true} path={`${route.baseApp.subscription.plans}`}>
            <Prices
              setActiveSubscription={updateActiveSubscriptions}
              activeSubscription={activeSubscription}
              is_annually={is_annually}
              plans={plans}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Route>
          <Route
            exact={true}
            path={`${route.baseApp.subscription.paymentHistory}`}
          >
            <PaymentHistory
              history_={history_}
              active={activeSubscription}
              isCanceled={isCanceled}
              setIsCanceled={setIsCanceled}
            />
          </Route>
        </Switch>
      )}
    </Fragment>
  );
};

export default Subscription;
