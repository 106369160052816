import styled, {keyframes} from "styled-components/macro";
import {bounceInRight} from 'react-animations';
import rem from "../../../../utils/rem";

const bounceAnimation = keyframes`${bounceInRight}`;
const RightSideStyled = styled.div`
  right: 0;
  animation: 0.5s ${bounceAnimation};
  z-index: 100;
  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export default RightSideStyled;
