import React from "react";

import LeftSideBarMenuButtonStyled from "./styled/LeftSideBarMenuButtonStyled";

const LeftSideBarMenuButton = ({ children, ...props }) => {
  return (
    <LeftSideBarMenuButtonStyled {...props}>
      {children}
    </LeftSideBarMenuButtonStyled>
  );
};

export default LeftSideBarMenuButton;
