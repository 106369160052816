import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Bold30Font, Medium14Font } from "../Fonts/Fonts";
import Button from "../buttons/Button/Button";
import { withTheme } from "styled-components/macro";
import { route } from "../../routes";
import logoAdmin from "../../assets/img/svg/logo_admin.svg";
import DivStyled from "../AdminSignInPage/DivStyled";
import { useSelector } from "react-redux";

const AccessGranted = ({ theme }) => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  let button;
  if (user) {
    button = (
      <Button
        secondary
        clickHandler={() => {
          history.push(
            user.is_admin ? route.admin.baseApp.allCases : route.baseApp.cases
          );
        }}
      >
        Go to Cases
      </Button>
    );
  } else {
    button = (
      <Button
        secondary
        clickHandler={() => {
          history.push(route.auth.signIn);
        }}
      >
        Sign In
      </Button>
    );
  }
  return (
    <Fragment>
      <DivStyled className="d-flex flex-column h-100 justify-content-between">
        <div className="row m-4">
          <Link
            to={
              user && !user.is_admin
                ? "/"
                : user && user.is_admin
                ? route.admin.baseApp.app
                : route.baseApp.app
            }
          >
            <img src={logoAdmin} />
          </Link>
        </div>
        <div className="d-flex flex-column align-items-center pb-5 mb-5">
          <div className="row pl-5 mr-5">
            <div className="col d-flex flex-column align-items-center">
              <Bold30Font as="p">{window.grant_access}</Bold30Font>
              {button}
            </div>
          </div>
        </div>
        <div className="row">&nbsp;</div>
      </DivStyled>
    </Fragment>
  );
};

export default withTheme(AccessGranted);
