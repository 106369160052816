import styled from "styled-components/macro";
import PropTypes from "prop-types";
import rem from "../../utils/rem";

const ImgWidthWrapperStyled = styled.div`
  width: ${({width})=>rem(`${width}px`)};

  @media (max-width: 767.98px) {
    width: 8.7rem;
  }
`;

ImgWidthWrapperStyled.propTypes = {
  /**
   * Set width of img in px
   */
  width: PropTypes.number.isRequired
};

export default ImgWidthWrapperStyled;
