// import axios from "axios";
// axios.defaults.baseURL = "http://localhost:8000";

export const route = {
  index: "/",
  termsAndConditions: "/terms-and-conditions",
  privacyPolicy: "/privacy-policy",
  securityStatement: "/security-statement",
  cookiesPolicy: "/cookies-policy",
  auth: {
    signIn: "/sign-in",
    signUp: "/sign-up",
    signUpWithGoogle: "/role-and-country",
    logOut: "/log-out",
    confirmEmail: "/confirm-email",
    restorePassword: "/restore-password",
    enterNewPassword: "/enter-new-password",
  },
  invite: "/invite/:inviteToken",
  admin: {
    auth: {
      signIn: "/admin/sign-in",
    },
    baseApp: {
      app: "/admin",
      settings: {
        path: "/admin/settings",
        cateTypes: "/admin/settings/case-types",
        causesOfAction: "/admin/settings/causes-of-action",
        offences: "/admin/settings/offences",
        reliefsAndProofs: "/admin/settings/reliefs-and-proofs",
        testimonial: "/admin/settings/testimonials",
      },
      trialProofs: {
        container: "/admin/all-cases/:id/trial-proofs",
        witnessProofs:
          "/admin/all-cases/:id/trial-proofs/witness-proofs/:witness_id([0-9]+)",
        exWitnessProofs:
          "/admin/all-cases/:id/trial-proofs/x-exam-witness-proofs/:witness_id([0-9]+)",
        coreBook:
          "/admin/all-cases/:id/trial-proofs/court-documents/trial/core-book",
        trialAuthorities:
          "/admin/all-cases/:id/trial-proofs/court-documents/trial/authorities",
        trialAdviceOnProofs:
          "/admin/all-cases/:id/trial-proofs/trial-checklist/advice-on-proofs",
        applicationAuthorities:
          "/admin/all-cases/:id/trial-proofs/court-documents/applications/authorities",
        chronology:
          "/admin/all-cases/:id/trial-proofs/court-documents/trial/chronology",
        evidenceChecklist:
          "/admin/all-cases/:id/trial-proofs/trial-checklists/evidence-checklist",
      },
      cases_edit: "/admin/all-cases/edit",
      caseLog: "/app/cases/:case_id/log",
      cases_create: "/admin/all-cases/create",
      trialHub: {
        container: "/admin/all-cases/:id/trial-hub",
        evidence: "/admin/all-cases/:id/trial-hub/evidence",
        evidenceImport: "/admin/all-cases/:id/trial-hub/evidence/import",
        caseDocumentImport: "/app/cases/:id/documents/import",
        createEvidence: "/admin/all-cases/:id/trial-hub/evidence/create",
        addEditEvidence:
          "/admin/all-cases/:id/trial-hub/evidence/edit/:evidenceId([0-9]+)?",
        evidenceDetails:
          "/admin/all-cases/:id/trial-hub/evidence/:evidenceId([0-9]+)?",
        witness: "/admin/all-cases/:id/trial-hub/witness",
        witnessCreate: "/admin/all-cases/:id/trial-hub/witness/create",
        xExamCreate: "/admin/all-cases/:id/trial-hub/x-exam/create",
        witnessDetails:
          "/admin/all-cases/:id/trial-hub/witness/:witnessId([0-9]+)?",
        addEditWitness:
          "/admin/all-cases/:id/trial-hub/witness/edit/:witnessId([0-9]+)?",
        xExam: "/admin/all-cases/:id/trial-hub/x-exam",
        addEditXExam:
          "/admin/all-cases/:id/trial-hub/x-exam/edit/:xExamId([0-9]+)?",
        xExamDetails: "/admin/all-cases/:id/trial-hub/x-exam/:xExamId([0-9]+)?",
        authorities: "/admin/all-cases/:id/trial-hub/authorities",
        authoritiesDetails:
          "/admin/all-cases/:id/trial-hub/authorities/:authorityId([0-9]+)?",
        createAuthorities: "/admin/all-cases/:id/trial-hub/authorities/create",
        addEditAuthorities:
          "/admin/all-cases/:id/trial-hub/authorities/edit/:authorityId([0-9]+)?",
        authoritiesBulkImport:
          "/admin/all-cases/:id/trial-hub/authorities/import",
      },
      applicationsHub: {
        container: "/admin/all-cases/:id/applications-hub",
        createApplication: "/admin/all-cases/:id/applications-hub/create",
        addEditApplication:
          "/admin/all-cases/:id/applications-hub/edit/:applicationId([0-9]+)?",
        applicationDetails:
          "/admin/all-cases/:id/applications-hub/:applicationId([0-9]+)?",
      },

      documents: {
        container: "/admin/all-cases/:id/documents",
        addEditDocument:
          "/admin/all-cases/:id/documents/edit/:documentId([0-9]+)?",
        documentDetails: "/admin/all-cases/:id/documents/:documentId([0-9]+)?",
      },
      pages: "/admin/pages",
      tutorials: "/admin/tutorials",
      userList: "/admin/user-list",
      user: "/admin/user",
      userDetails: "/admin/user/:id([0-9]+)?/details",
      allCases: "/admin/all-cases",
      dashboard: "/admin/dashboard",
    },
  },
  subscription: {
    step2: "/subscription/step2",
    step3: "/subscription/step3",
  },
  baseApp: {
    app: "/app",
    help: "/app/help",
    dashboard: "/app/dashboard",
    cases: "/app/cases",
    trialProofs: {
      container: "/app/cases/:id/trial-proofs",
      witnessProofs:
        "/app/cases/:id/trial-proofs/witness-proofs/:witness_id([0-9]+)",
      exWitnessProofs:
        "/app/cases/:id/trial-proofs/x-exam-witness-proofs/:witness_id([0-9]+)",
      coreBook: "/app/cases/:id/trial-proofs/court-documents/trial/core-book",
      chronology:
        "/app/cases/:id/trial-proofs/court-documents/trial/chronology",
      trialAuthorities:
        "/app/cases/:id/trial-proofs/court-documents/trial/authorities",
      trialAdviceOnProofs:
        "/app/cases/:id/trial-proofs/trial-checklist/advice-on-proofs",
      applicationAuthorities:
        "/app/cases/:id/trial-proofs/court-documents/applications/authorities",
      evidenceChecklist:
        "/app/cases/:id/trial-proofs/trial-checklists/evidence-checklist",
    },
    trialHub: {
      container: "/app/cases/:id/trial-hub",
      evidence: "/app/cases/:id/trial-hub/evidence",
      evidenceImport: "/app/cases/:id/trial-hub/evidence/import",
      caseDocumentImport: "/app/cases/:id/documents/import",
      createEvidence: "/app/cases/:id/trial-hub/evidence/create",
      addEditEvidence:
        "/app/cases/:id/trial-hub/evidence/edit/:evidenceId([0-9]+)?",
      evidenceDetails: "/app/cases/:id/trial-hub/evidence/:evidenceId([0-9]+)?",
      witness: "/app/cases/:id/trial-hub/witness",
      witnessCreate: "/app/cases/:id/trial-hub/witness/create",
      witnessDetails: "/app/cases/:id/trial-hub/witness/:witnessId([0-9]+)?",
      addEditWitness:
        "/app/cases/:id/trial-hub/witness/edit/:witnessId([0-9]+)?",
      xExam: "/app/cases/:id/trial-hub/x-exam",
      xExamCreate: "/app/cases/:id/trial-hub/x-exam/create",
      addEditXExam: "/app/cases/:id/trial-hub/x-exam/edit/:xExamId([0-9]+)?",
      xExamDetails: "/app/cases/:id/trial-hub/x-exam/:xExamId([0-9]+)?",
      authorities: "/app/cases/:id/trial-hub/authorities",
      authoritiesDetails:
        "/app/cases/:id/trial-hub/authorities/:authorityId([0-9]+)?",
      createAuthorities: "/app/cases/:id/trial-hub/authorities/create",
      addEditAuthorities:
        "/app/cases/:id/trial-hub/authorities/edit/:authorityId([0-9]+)?",
      authoritiesBulkImport: "/app/cases/:id/trial-hub/authorities/import",
    },
    applicationsHub: {
      container: "/app/cases/:id/applications-hub",
      createApplication: "/app/cases/:id/applications-hub/create",
      addEditApplication:
        "/app/cases/:id/applications-hub/edit/:applicationId([0-9]+)?",
      applicationDetails:
        "/app/cases/:id/applications-hub/:applicationId([0-9]+)?",
    },

    documents: {
      container: "/app/cases/:id/documents",
      addEditDocument: "/app/cases/:id/documents/edit/:documentId([0-9]+)?",
      documentDetails: "/app/cases/:id/documents/:documentId([0-9]+)?",
    },
    settings: "/app/settings",
    subscription: {
      subscription: "/app/subscription",
      plans: "/app/subscription/plans",
      paymentHistory: "/app/subscription/payment-history",
    },
    cases_edit: "/app/cases/edit",
    cases_create: "/app/cases/create",
    caseArena: "/app/cases/:case_id",
    caseLog: "/app/cases/:case_id/log",
    // trialHub: "/app/cases/:case_id/trial-hub",
    // documentHub: "/app/cases/:case_id/document-hub",
    // applicationsHub: "/app/cases/:case_id/applications-hub",
    // trialProofs: "/app/cases/:case_id/trial-proofs",
  },
  api: {
    dashboard: "/api/dashboard",
    subToNews: "/api/news",
    contactUs: "/api/contact-us",
    auth: {
      signIn: "/api/auth/sign-in",
      signUp: "/api/auth/sign-up",
      signUpWithGoogle: "/api/auth/sign-up-with-google",
      resendVerificationCode: "/api/auth/resend-verification-code",
      emailVerification: "/api/auth/email-verification",
      forgotPassword: "/api/auth/forgot-password",
      resetPassword: "/api/auth/reset-password",
      updatePassword: "/api/auth/update-password",
    },
    user: {
      get: "/api/user",
      resendVerificationCodeSMS: "/api/user/resend-verification-code",
      resendVerificationCodeSMSNoAuth: "/api/auth/resend-sms-code",
      settings: "/api/user/settings",
    },
    cases: {
      get: "/api/cases",
      types: "/api/cases/types",
      log: "/api/cases/:case_id/log",
      total: "/api/cases/total",
      canAdd: "/api/cases/can-add",
    },
    admin: {
      case_types: "/api/admin/case-types",
      users: "/api/admin/users",
      testimony: "/api/admin/testimonial",
      cases: "/api/admin/cases",
      dashboard: "/api/admin/dashboard",
      casesTotal: "/api/admin/cases/total",
      causes_of_actions: "/api/admin/causes-of-actions",
      reliefs: "/api/admin/reliefs",
      subscriptions: {
        getUserSubscription: (id) =>
          `/api/admin/users/${id}/subscriptions/active`,
        addSubscriptionToUser: (id) => `/api/admin/users/${id}/subscriptions`,
      },
    },
    subscriptions: {
      get: "/api/subscriptions",
      getAuthorized: "/api/subscriptions/authorized",
      addToUser: "/api/subscriptions",
      createStripeIntent: "/api/subscriptions/intent",
      cancel: "/api/subscriptions/cancel",
    },
    caseTeams: {
      addMember: (caseId) => `/api/case-team/${caseId}`,
      getMembersList: (caseId) => `/api/case-team/${caseId}/list`,
      getTeamLimitUrl: (caseId) => `/api/admin/cases/${caseId}/team-limit`,
      deleteMember: (caseMemberId) => `/api/case-team/${caseMemberId}`,
      getInvitesList: () => "/api/case-team/invites",
      getEmail: (inviteToken) => `/api/case-team/getEmail?token=${inviteToken}`,
      acceptInvite: () => "/api/case-team/accept",
      rejectInvite: () => "/api/case-team/reject",
      teamSizeRequest: (caseId) => `/api/case-team/${caseId}/team-size-request`,
    },
  },
};
