import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {Bold14Font, Medium14Font} from "../../FontsNewComponent/Fonts";
const RadioButtonGroupRoundedStyled = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: ${({ theme, checked }) => checked ? theme.colors.blue : '#ffffff'};
    ${Medium14Font} {
    color: ${({ checked }) => checked ? '#ffffff' : '#0f122f'};
  }
    cursor: pointer;
    border: ${({ checked }) => checked ? 'none' : '1px solid #dcdce6'};
`;
const RadioButtonGroupRounded = ({
  label,
  itemsObjects,
  checkedButton,
  setCheckedButton,
  ...props
}) => {
  const onClickHandler = (itemType) => {
    setCheckedButton(itemType);
  };

  return (
    <Fragment>
      {label ? (
        <div className="row mb-2">
          <div className="col-12">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col">
          <div
            className={`d-flex`}
          >
            {itemsObjects.map((item, index) => (
              <RadioButtonGroupRoundedStyled
                key={index}
                onClick={() => onClickHandler(item.type)}
                checked={item.type === checkedButton}
                className={`mb-2 mb-sm-0 d-flex justify-content-center ${index < itemsObjects.length + 1 ? 'mr-2' : ''}`}
              >
                {item.title}
              </RadioButtonGroupRoundedStyled>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RadioButtonGroupRounded.propTypes = {
  label: PropTypes.string,
  itemsObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkedButton: PropTypes.string.isRequired,
  setCheckedButton: PropTypes.func.isRequired,
};

export default RadioButtonGroupRounded;
