import { Mark, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

export const TagCreationNode = Mark.create({
  name: "tag-creation",

  state: {
    // Apply changes to the plugin state from a view transaction.
    apply(transaction, prev, oldState, state) {
      console.log("yo");
    },
  },

  group: "inline",
  inline: true,
  atom: false,
  selectable: true,

  parseHTML() {
    return [
      {
        tag: "tag-creation",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["tag-creation", mergeAttributes(HTMLAttributes)];
    // return ["tag-creation", ["span", mergeAttributes(HTMLAttributes), 0]];
  },

  addCommands() {
    return {
      setCodeBlock: (attributes) => ({ commands }) => {
        return commands.setNode(this.name, attributes);
      },
      toggleTagCreation: (attributes) => ({ commands }) => {
        return commands.toggleNode(this.name, "paragraph", attributes);
      },
    };
  },

  // addKeyboardShortcuts() {
  //   return {
  //     Enter: () => {
  //       if (this.editor.isActive("tag-creation")) {
  //         onCreate(this.editor);
  //         // createTag(this.editor, onCreate);
  //       }
  //     },
  //     Escape: () => {
  //       if (this.editor.isActive("tag-creation")) {
  //         // this.editor.commands.unsetMark("tag-creation", {
  //         //   extendEmptyMarkRange: true,
  //         // });
  //         // alert("escape");
  //         return true;
  //       }
  //     },
  //   };
  // },

  addAttributes() {
    return {
      type: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-type"),
        renderHTML: (attributes) => {
          if (!attributes.type) {
            return {};
          }
          return {
            "data-type": attributes.type,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
      tagType: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagType"),
        renderHTML: (attributes) => {
          if (!attributes.tagType) {
            return {};
          }
          return {
            "data-tagType": attributes.tagType,
          };
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("tag-creation-ex"),

        state: {
          // Initialize the plugin's internal state.
          init() {
            const state = {
              isCreating: false,
              creatingPos: undefined,
            };
            return state;
          },
          // Apply changes to the plugin state from a view transaction.
          apply(transaction, prev, oldState, state) {
            //
          },
        },
        filterTransaction(tr, state) {
          // console.log(state);
          // console.log(this);
          // console.log("tr");
          // console.log(tr);
          return true;
          // console.log(oldState.selection.anchor);
          // console.log(oldState);
          // console.log(newState.selection.anchor);
          // console.log(newState.isActive("tag-creation"));
        },
        // appendTransaction(tr, oldState, newState) {
        //   console.log("tr");
        //   console.log(oldState.selection.anchor);
        //   console.log(oldState);
        //   console.log(newState.selection.anchor);
        //   // console.log(newState.isActive("tag-creation"));
        // },
      }),
    ];
  },

  // addNodeView() {
  //   return ReactNodeViewRenderer(Component);
  // },
});

export default TagCreationNode;

const createTag = async (editor, callback) => {
  console.log(editor);
  // const editor = editor;

  const caseId = window.location.pathname.split("/")[3];
  const config = {
    headers: {
      Authorization: `Bearer ${await loadAccessToken()}`,
    },
  };

  // TODO: here find which tagType and which tag label
  const tagType = "evidence";
  const label = "damn";

  let res;

  if (tagType === "evidence") {
    res = await axios
      .post(`/api/cases/${caseId}/evidence`, { label: label }, config)
      .catch((error) => {
        alert(error);
      });
  }

  if (tagType === "witness") {
    res = await axios
      .post(`/api/cases/${caseId}/witness`, { name: label }, config)
      .catch((error) => {
        alert(error);
      });
  }

  const newTag = {
    // tagId: res.data.data.id,
    // id: res.data.data.label,
    // tagType,
    tagId: 2,
    id: "label",
    tagType: "evidence",
  };

  const prevText = editor.view.state.selection.$from.nodeBefore.text;
  editor
    .chain()
    .insertContentAt(
      {
        from: editor.view.state.selection.anchor - prevText.length,
        to: editor.view.state.selection.anchor,
      },
      `<tag data-id="${newTag.id}" data-tagType=${newTag.tagType} data-tagId=${newTag.tagId} className={tag tag-${newTag.tagType}}>${newTag.label}</tag> `
    )
    .setMeta("createTag", newTag)
    .run();

  // here push to evidenceItem
  callback(newTag);

  return true;
};
