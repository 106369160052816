import styled from "styled-components/macro";
import AssociationTableRowStyled from "./AssociationTableRowStyled";

const AssociationTableStyled = styled.div`
  width: 100%;
  min-height: 42px;
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 21px;

  :hover {
    border-color: ${({ theme }) => theme.colors.blue};

    // TODO: Look's strange...
    // ${AssociationTableRowStyled} {
    //   border-color: ${({ theme }) => theme.colors.blue};
    // }
  }
`;

export default AssociationTableStyled;
