import React, { useState } from "react";
import PropTypes from "prop-types";

import { Editor } from "@tinymce/tinymce-react";

const TinyMCE = ({ content, setContent, ...props }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);

  // send data

  // button handlers

  // input handlers

  const onChangeEditorContent = (content, editor) => {
    setContent(content);
  };

  return (
    <Editor
      value={content}
      init={{
        height: 500,
        skin: false,
        content_style:
          'p.tp-paragraph, li::marker {font-family: "PT Root UI", sans-serif;font-style: normal;font-weight: 500;font-size: 1.125rem;color: rgba(15, 18, 47, 0.6);}' +
          'h2.tp-header {font-family: "PT Root UI", sans-serif;font-style: normal;font-weight: bold;font-size: 1.5rem;color: rgb(15, 18, 47);}',
        content_css: false,
        toolbar_sticky: true,
        // branding: false,
        // block_unsupported_drop: false,
        paste_data_images: true,
        plugins: [
          "advlist autolink lists paste link table media image imagetools",
        ],
        menubar: false,
        toolbar:
          "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
          "bullist numlist outdent indent | table link media | removeformat",
        style_formats: [
          {
            name: "tp-header",
            title: "Header",
            block: "h2",
            classes: ["tp-header"],
          },
          {
            name: "tp-paragraph",
            title: "Paragraph",
            block: "p",
            classes: ["tp-paragraph"],
          },
          {
            name: "tp-table",
            title: "Table",
            selector: "table",
            classes: ["table"],
          },
        ],
        style_formats_merge: false,
      }}
      onEditorChange={onChangeEditorContent}
      {...props}
    />
  );
};

TinyMCE.propTypes = {
  /**
   * Content to view in editor
   */
  content: PropTypes.string.isRequired,
  /**
   * Function to update data
   */
  setContent: PropTypes.func.isRequired,
};

export default TinyMCE;
