import React, { Fragment, useState } from "react";
import TrialHubSearchSortBar from "./baseAppComponents/TrialHubSearchSortBar/TrialHubSearchSortBar";
import { Bold18Font, Medium14Font } from "./FontsNewComponent/Fonts";
import FlagRelevanceFilter from "./FlagRelevanceFilter/FlagRelevanceFilter";
import CustomReactDatepicker from "./inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "./inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import Table from "./Table/Table";
import { setTagField } from "../utils/function";
import flagRelevanceCheck from "./FlagRelevanceFilter/flagRelevanceCheck";
import NoRecords from "./NoRecords";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const dateFormat = "dd-MM-yyyy";
const dateToInt = (date) => parseInt(moment(date).format(dateFormatToCompare));
const dateFormatToCompare = "YYYYMMDD";
export default function KeySections({ evidenceObject, caseObject, isWitness }) {
  const history = useHistory();
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);
  const [flagRelevance, setFlagRelevance] = useState([]);
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const onFlagRelevanceChange = (options) => {
    setFlagRelevance(options);
  };
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };
  const noRecordsFragment = (
    <NoRecords>
      <Bold18Font textColor={"#0f122f"}>
        Any Key Sections of the document that you create will appear here
      </Bold18Font>
    </NoRecords>
  );
  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };
  const clearSearchBarHandler = () => {
    setSearchBarValue("");
  };
  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };
  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };
  const filterEvidences = (value) => {
    let check = true;
    if (searchBarValue && check) {
      check =
        (value.label && searchField(value.label)) ||
        (value.objectives &&
          value.objectives.length &&
          searchField(value.objectives));
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }

    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }

    return check;
  };
  const onClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  const evidenceTableSettings = {
    collapsed: false,
    actions: [],
    canSort: true,
    clickAction: (object) => {
      object.case = caseObject;
      history.push({
        state: { subevidence: object.id },
        pathname: `/${is_admin ? "admin/all-cases" : "app/cases"}/${
          caseObject.id
        }/documents/${
          !evidenceObject.file_id
            ? object.document.fake_id
            : evidenceObject.file_id
        }`,
      });
    },
  };
  if (isWitness) {
    evidenceTableSettings.fields = [
      {
        name: "fake_id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "KEY SECTION",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "page",
        canSort: true,
        label: "PAGE NUMBER",
        renderer: (object) => {
          return <Medium14Font>{object.page}</Medium14Font>;
        },
      },
      {
        name: "evidence",
        label: "EVIDENCE",
        renderer: (object) => {
          return <Medium14Font>{object.document.name}</Medium14Font>;
        },
      },
      {
        name: "date",
        label: "DATE",
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd-MM-yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ];
  } else {
    evidenceTableSettings.fields = [
      {
        name: "fake_id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "KEY SECTION",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "page",
        canSort: true,
        label: "PAGE NUMBER",
        renderer: (object) => {
          return <Medium14Font>{object.page}</Medium14Font>;
        },
      },
      {
        name: "date",
        canSort: true,
        label: "DATE",
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd-MM-yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ];
  }
  return (
    <Fragment>
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onSearchBarChangeHandler}
            clearSearchBarHandler={clearSearchBarHandler}
            searchBarInputName="search"
            searchBarPlaceholder="Search"
            onFilterDatesAlphabetChangeHandler={
              onFilterDatesAlphabetChangeHandler
            }
          />
        </div>
      </div>

      <div className="row mb-1">
        <div className="col">
          <Medium14Font style={{ marginRight: "11px", width: "70px" }}>
            Filter by:
          </Medium14Font>
        </div>
      </div>
      <div className="row">
        <FlagRelevanceFilter
          size={3}
          flagRelevance={flagRelevance}
          caseObject={caseObject}
          onFlagRelevanceChange={setFlagRelevance}
        />
        <div className="col-lg-3 col-12">
          <div style={{ maxWidth: "230px", width: "100%" }}>
            <CustomReactDatepicker
              // selected={datePickerStartDate}
              customInput={
                <CustomDatepickerRangeDivInput
                  startDate={
                    datePickerStartDate
                      ? format(datePickerStartDate, dateFormat)
                      : ""
                  }
                  endDate={
                    datePickerEndDate
                      ? format(datePickerEndDate, dateFormat)
                      : ""
                  }
                  clearDatesHandler={onClearDatesHandler}
                />
              }
              startDate={datePickerStartDate}
              endDate={datePickerEndDate}
              dateFormat={"dd-MM-yyyy"}
              dateFormatCalendar={"dd-MM-yyyy"}
              onChange={onChangeDatesHandler}
              selectsRange
              shouldCloseOnSelect={false}
              disabledKeyboardNavigation
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="col">
            {evidenceObject.sub_evidence &&
            evidenceObject.sub_evidence.length ? (
              <Table
                data={setTagField(
                  caseObject,
                  evidenceObject.sub_evidence.filter(filterEvidences)
                )}
                settings={evidenceTableSettings}
              />
            ) : (
              noRecordsFragment
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
