import styled from "styled-components/macro";

const EvidenceDetailsColumnWithBorderStyled = styled.div`
  border-right: 1px solid ${({ theme }) => theme.colors.gray};

  @media (max-width: 575.98px) {
    border-right: none;
  }

  // @media (max-width: 767.98px) {
  //   border-right: 1px solid ${({ theme }) => theme.colors.gray};
  // }
`;

export default EvidenceDetailsColumnWithBorderStyled;
