import React from "react";
import PropTypes from "prop-types";
import CaseSearchInputStyled from "./styled/CaseSearchInputStyled";

const CasesSearchInput = ({ ...props }) => {
  return <CaseSearchInputStyled {...props} />;
};

CasesSearchInput.propTypes = {
  /**
   * Rounded the corners of the border to the left
   * */
  positionStart: PropTypes.bool,

  /**
   * Disable round corners
   * */
  positionMiddle: PropTypes.bool,

  /**
   * Rounded the corners of the border to the right
   * */
  positionEnd: PropTypes.bool,
};

export default CasesSearchInput;
