import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";

import ModalBackdropStyled from "./styled/ModalBackdropStyled";
import ModalStyled from "./styled/ModalStyled";

import { route } from "../../routes";

import {
  HIDE_MODAL,
  MODAL_ADD_CARD_ELEMENT,
  MODAL_ADMIN_GRANT_FREE_LICENSE,
  MODAL_BLOCK_ELEMENT,
  MODAL_CONFIRM_ELEMENT,
  MODAL_CREATE_KEY_SECTION,
  MODAL_DELETE_ELEMENT,
  MODAL_DELETE_KEY_SECTION,
  MODAL_ERROR,
  MODAL_ERROR_SIGN_IN,
  MODAL_DOC_TYPE,
  MODAL_SUCCESS,
  MODAL_SUCCESS_EMAIL_VERIFICATION,
  MODAL_SUCCESS_PASSWORD_RESET,
  MODAL_SUCCESS_PASSWORD_RESET_ADMIN,
  MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
  RESET_MODAL,
  RESET_PASSWORD,
  SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET,
  SHOW_MODAL,
  UPDATE_USER,
  MODAL_ALERT,
} from "../../redux/types";
import { Bold18Font, Medium14Font } from "../Fonts/Fonts";
import Button from "../buttons/Button";
import { theme } from "../../styled-components/Theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "./ModalWrapper";
import af from "moment/locale/af";
import {
  adminSetSubscriptionToUser,
  getSubscriptions,
} from "../../containers/Auth/auth";
import FreeLicensePlanCard from "../FreeLicensePlanCard";
import { Bold14Font } from "../FontsNewComponent/Fonts";
import Checkbox from "../Checkbox";
import modalErrorHandler from "./modalErrorHandler";

const modalError = (dispatch, afterCloseHandler, header, description) => {
  const onClickHandler = async () => {
    if (afterCloseHandler) {
      await afterCloseHandler();
    }
    dispatch({ type: HIDE_MODAL });
  };

  const buttons = [
    {
      type: "Okay",
      clickHandler: () => onClickHandler(),
    },
  ];

  return (
    <ModalWrapper
      // successIcon
      header={header ? header : "Error"}
      description={description}
      buttons={buttons}
    />
  );
};

const modalSuccess = (dispatch, afterCloseHandler, header, description) => {
  const onClickHandler = async () => {
    if (afterCloseHandler) {
      await afterCloseHandler();
    }
    dispatch({ type: HIDE_MODAL });
  };

  const buttons = [
    {
      type: "Okay",
      clickHandler: () => onClickHandler(),
    },
  ];

  return (
    <ModalWrapper
      successIcon
      header={header ? header : "Success"}
      description={description}
      buttons={buttons}
    />
  );
};

const modalSuccessPasswordReset = (dispatch, history, admin) => {
  const onClickHandler = () => {
    dispatch({ type: HIDE_MODAL });
    dispatch({ type: SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET });
    if (admin) {
      history.push(route.admin.auth.signIn);
    } else {
      history.push(route.auth.signIn);
    }
  };

  const header = "Success";
  const description = "You have successfully reset your password";
  const buttons = [
    {
      type: "Okay",
      clickHandler: () => onClickHandler(),
    },
  ];
  return (
    <ModalWrapper buttons={buttons} description={description} header={header} />
  );
};

/**
 * Content of success email verification modal
 * @param dispatch: dispatch hook from redux
 * @param history: history hook from react-router-dom
 * @param authAction: check if user current try reset password
 * @return {*}: modal content
 */
const modalSuccessEmailVerification = (dispatch, history, authAction) => {
  const onClickHandler = () => {
    if (authAction === RESET_PASSWORD) {
      dispatch({ type: HIDE_MODAL });
      history.push(route.auth.enterNewPassword);
    } else {
      dispatch({ type: HIDE_MODAL });
      history.push(route.baseApp.app);
    }
  };

  const header = "Success";
  const description = "You have successfully confirmed your email address";
  const buttons = [
    {
      type: "Okay",
      clickHandler: () => onClickHandler(),
    },
  ];

  return (
    <ModalWrapper
      header={header}
      description={description}
      successIcon
      buttons={buttons}
    />
  );
};

const modalSuccessSubscriptionVerification = (dispatch, history) => {
  const onClickHandler = () => {
    dispatch({ type: HIDE_MODAL });
    history.push(route.baseApp.app);
  };

  const header = "Thank You";

  const description = "You have successfully subscribed to TrialProofer";

  const buttons = [
    {
      type: "Okay",
      clickHandler: () => onClickHandler(),
    },
  ];

  return (
    <ModalWrapper
      header={header}
      description={description}
      successIcon
      buttons={buttons}
    />
  );
};

const modalErrorSignIn = (dispatch, errors, afterModalClose) => {
  const onClickHandler = () => {
    dispatch({ type: HIDE_MODAL });
    if (afterModalClose) {
      afterModalClose();
    }
    // dispatch({ type: RESET_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Error</Bold18Font>
          </div>
        </div>
        {typeof errors === "string" || React.isValidElement(errors) ? (
          <div className="row mb-2">
            <div className="col">
              <Medium14Font as="p">{errors}</Medium14Font>
            </div>
          </div>
        ) : (
          errors.map((el, index) => (
            <div className="row mb-2" key={index}>
              <div className="col">
                <Medium14Font as="p">
                  {el.error}: {el.message}
                </Medium14Font>
              </div>
            </div>
          ))
        )}
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button primary clickHandler={onClickHandler}>
              Okay
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const modalAlert = (dispatch, errors, afterModalClose) => {
  const onClickHandler = () => {
    dispatch({ type: HIDE_MODAL });
    if (afterModalClose) {
      afterModalClose();
    }
    // dispatch({ type: RESET_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Alert</Bold18Font>
          </div>
        </div>
        {typeof errors === "string" || React.isValidElement(errors) ? (
          <div className="row mb-2">
            <div className="col">
              <Medium14Font as="p">{errors}</Medium14Font>
            </div>
          </div>
        ) : (
          errors.map((el, index) => (
            <div className="row mb-2" key={index}>
              <div className="col">
                <Medium14Font as="p">
                  {el.error}: {el.message}
                </Medium14Font>
              </div>
            </div>
          ))
        )}
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button primary clickHandler={onClickHandler}>
              Okay
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const modalBlockElement = (dispatch, message, beforeCloseHandler, active) => {
  const [isLoading, setIsLoading] = useState(false);
  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };
  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Are You Sure?</Bold18Font>
          </div>
        </div>
        <p>
          <Medium14Font style={{ color: theme.colors.darkOpacity }}>
            {message}
          </Medium14Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button className="mr-3" secondary clickHandler={close}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              danger
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              {active ? "Block" : "UN-Block"}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const modalAddCardElement = (dispatch, message, beforeCloseHandler) => {
  return message;
};

const modalConfirmElement = (dispatch, message, beforeCloseHandler) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };

  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Are You Sure?</Bold18Font>
          </div>
        </div>
        <p>
          <Medium14Font style={{ color: theme.colors.darkOpacity }}>
            {message}
          </Medium14Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button className="mr-3" secondary clickHandler={close}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              primary
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const modalSelectDocType = (dispatch, message, beforeCloseHandler) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };

  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Select Class for the extracted document</Bold18Font>
          </div>
        </div>
        <p>
          <Medium14Font style={{ color: theme.colors.darkOpacity }}>
            {message}
          </Medium14Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button className="mr-3" secondary clickHandler={close}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              primary
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const modalDeleteElement = (dispatch, message, beforeCloseHandler) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };

  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            <Bold18Font>Are You Sure?</Bold18Font>
          </div>
        </div>
        <p>
          <Medium14Font style={{ color: theme.colors.darkOpacity }}>
            {message}
          </Medium14Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button className="mr-3" secondary clickHandler={close}>
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              danger
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const keySectionElement = (
  dispatch,
  message,
  beforeCloseHandler,
  extraData
) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };

  const close = () => {
    if (extraData) {
      extraData();
    }
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <p className="text-center">
          <Bold18Font>{message}</Bold18Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <Button className="mr-3" secondary clickHandler={close}>
              No
            </Button>
            <Button
              disabled={isLoading}
              danger
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const createKeySectionElement = (dispatch, message, beforeCloseHandler) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = async () => {
    if (beforeCloseHandler) {
      setIsLoading(true);
      await beforeCloseHandler();
      setIsLoading(false);
    }
    dispatch({ type: HIDE_MODAL });
  };

  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <Fragment>
      <div className="container-fluid p-4">
        <p className="text-center">
          <Bold18Font>{message}</Bold18Font>
        </p>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <Button className="mr-3" secondary clickHandler={close}>
              No
            </Button>
            <Button
              disabled={isLoading}
              primary
              loading={isLoading}
              clickHandler={onClickHandler}
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const modalAdminGrantFreeLicense = (dispatch, beforeCloseHandler) => {
  const [isModalDataLoading, setIsModalDataLoading] = useState(true);
  const [isModalMakeRequest, setIsModalMakeRequest] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState({});
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionPlanChecked, setSubscriptionPlanChecked] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const periods = [
    {
      id: 1,
      period: 1,
      periodTime: "month",
    },
    {
      id: 2,
      period: 3,
      periodTime: "months",
    },
    {
      id: 3,
      period: 12,
      periodTime: "months",
    },
  ];
  const user = useSelector((state) => state.modal.extraData);

  useEffect(() => {
    const getSubscriptionPlans = async () => {
      const resp = await getSubscriptions();
      if (resp) {
        setSubscriptionPlans(resp.monthly);
        setIsModalDataLoading(false);
      }
    };
    getSubscriptionPlans();
  }, []);

  const closeModal = () => {
    dispatch({ type: RESET_MODAL });
  };

  const onClickSubmitHandler = async () => {
    setIsModalMakeRequest(true);
    const requestBody = {
      price_id: subscriptionPlanChecked.price_id,
      product_id: subscriptionPlanChecked.product_id,
      trial_months: selectedPeriod.period,
    };
    try {
      const resp = await adminSetSubscriptionToUser(user.id, requestBody);
      if (resp && resp.data) {
        if (beforeCloseHandler) {
          beforeCloseHandler();
        }
        closeModal();
      }
    } catch (e) {
      const er = modalErrorHandler(e, false);
      setErrorMessage(er);
    }
    setIsModalMakeRequest(false);
  };

  const onClickCancelHandler = () => {
    closeModal();
  };

  const buttons = [
    {
      type: "Cancel",
      clickHandler: onClickCancelHandler,
    },
    {
      type: "Submit",
      clickHandler: onClickSubmitHandler,
    },
  ];

  const description = (
    <div className="container-fluid">
      {errorMessage ? (
        <div className="row alert alert-danger">
          <div className="col">
            <ul>{errorMessage.map((e) => e.message)}</ul>
          </div>
        </div>
      ) : null}
      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col">
          <Bold14Font>Plans</Bold14Font>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "20px" }}>
        {subscriptionPlans.map((subscriptionPlanObject) => (
          <div className="col-4" key={subscriptionPlanObject.price_id}>
            <FreeLicensePlanCard
              subscriptionPlanObject={subscriptionPlanObject}
              checked={subscriptionPlanChecked}
              setChecked={setSubscriptionPlanChecked}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col">
          <Bold14Font>Period</Bold14Font>
        </div>
      </div>
      {periods.map((periodObject) => (
        <div
          className="row"
          key={periodObject.id}
          style={{ marginBottom: "17px" }}
        >
          <div className="col">
            {
              <Checkbox
                checked={periodObject.id === selectedPeriod.id}
                onChange={() => setSelectedPeriod(periodObject)}
                label={`${periodObject.period} ${periodObject.periodTime}`}
              />
            }
          </div>
        </div>
      ))}
    </div>
  );

  if (isModalDataLoading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <PuffLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <ModalWrapper
      header="Free License"
      description={description}
      buttons={buttons}
      buttonsLoading={isModalMakeRequest}
    />
  );
};

const Modal = ({ type, header, description }) => {
  const errors = useSelector((state) => state.modal.data);
  const authAction = useSelector((state) => state.auth.action);
  const extraData = useSelector((state) => state.modal.extraData);
  const afterCloseHandler = useSelector(
    (state) => state.modal.afterCloseHandler
  );
  const beforeCloseHandler = useSelector(
    (state) => state.modal.beforeCloseHandler
  );
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Fragment>
      <ModalBackdropStyled
      // onClick={() => dispatch({ type: HIDE_MODAL })}
      />
      <ModalStyled type={type}>
        {type === MODAL_ADMIN_GRANT_FREE_LICENSE
          ? modalAdminGrantFreeLicense(dispatch, beforeCloseHandler)
          : null}
        {type === MODAL_ERROR
          ? modalError(dispatch, afterCloseHandler, header, description)
          : null}
        {type === MODAL_SUCCESS
          ? modalSuccess(dispatch, afterCloseHandler, header, description)
          : null}
        {type === MODAL_SUCCESS_EMAIL_VERIFICATION
          ? modalSuccessEmailVerification(dispatch, history, authAction)
          : null}
        {type === MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION
          ? modalSuccessSubscriptionVerification(dispatch, history)
          : null}
        {type === MODAL_ERROR_SIGN_IN
          ? modalErrorSignIn(dispatch, errors, afterCloseHandler)
          : null}
        {type === MODAL_ALERT
          ? modalAlert(dispatch, errors, afterCloseHandler)
          : null}
        {type === MODAL_SUCCESS_PASSWORD_RESET
          ? modalSuccessPasswordReset(dispatch, history, false)
          : null}
        {type === MODAL_SUCCESS_PASSWORD_RESET_ADMIN
          ? modalSuccessPasswordReset(dispatch, history, true)
          : null}
        {type === MODAL_DELETE_ELEMENT
          ? modalDeleteElement(
              dispatch,
              errors,
              beforeCloseHandler,
              afterCloseHandler
            )
          : null}
        {type === MODAL_DELETE_KEY_SECTION
          ? keySectionElement(
              dispatch,
              errors,
              beforeCloseHandler,
              extraData,
              afterCloseHandler
            )
          : null}
        {type === MODAL_CREATE_KEY_SECTION
          ? createKeySectionElement(
              dispatch,
              errors,
              beforeCloseHandler,
              afterCloseHandler
            )
          : null}
        {type === MODAL_BLOCK_ELEMENT
          ? modalBlockElement(dispatch, errors, beforeCloseHandler, extraData)
          : null}
        {type === MODAL_CONFIRM_ELEMENT
          ? modalConfirmElement(
              dispatch,
              errors,
              beforeCloseHandler,
              afterCloseHandler
            )
          : null}
        {type === MODAL_DOC_TYPE
          ? modalSelectDocType(
              dispatch,
              errors,
              beforeCloseHandler,
              afterCloseHandler
            )
          : null}
        {type === MODAL_ADD_CARD_ELEMENT
          ? modalAddCardElement(
              dispatch,
              errors,
              beforeCloseHandler,
              afterCloseHandler
            )
          : null}
      </ModalStyled>
    </Fragment>
  );
};

export default Modal;
