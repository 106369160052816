import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  blockUnblockUser,
  deleteAdminUser,
  getAdminUsers,
} from "../../Auth/auth";
import { route } from "../../../routes";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../components/FontsNewComponent/Fonts";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import CasesSearchFilterBar from "../../../components/baseAppComponents/CasesSearchFilterBar/CasesSearchFilterBar";
import {
  createCountryOptionsList,
  roleOptions,
} from "../../../utils/constants";
import SelectNew from "../../../components/Select/SelectNew";
import useWindowSize from "../../../hooks/useWindowSize";
import Table from "../../../components/Table/Table";
import {
  MODAL_BLOCK_ELEMENT,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import Avatar from "react-avatar";
import Button from "../../../components/buttons/Button/Button";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import moment from "moment";
import { blockToggleUser } from "../../../defaultActions";
import { USER_LIST } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

const order = [
  {
    value: 1,
    label: "New",
  },
  {
    value: 2,
    label: "Alphabetical",
  },
];
const sortAlphabetical = (a, b) => {
  let item_a = Object.assign({}, a);
  let item_b = Object.assign({}, b);
  if (!item_a.name) {
    item_a.name = "";
  }
  if (!item_b.name) {
    item_b.name = "";
  }
  item_a.name =
    typeof item_a.name === "string"
      ? item_a.name.toLowerCase()
      : item_a.name.toString();
  item_b.name =
    typeof item_b.name === "string"
      ? item_b.name.toLowerCase()
      : item_b.name.toString();
  return item_a.name.localeCompare(item_b.name, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

const sortByDateTime = (item_a, item_b) => {
  return moment(item_b.created_at).diff(moment(item_a.created_at));
};
export default function Users() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [sorting, setSorting] = useState(1);
  const [role, setRole] = useState("");
  const [country, setCountry] = useState("");
  const { width } = useWindowSize();
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let resp = await getAdminUsers(dispatch);
      setUsers(resp);
      setIsLoading(false);
    };
    getData();
  }, []);
  const clearSearchBarHandler = () => {
    setSearchString("");
  };
  const pageConfig = [
    {
      path: route.admin.baseApp.userList,
      title: "User List",
      activeMenuItem: USER_LIST,
    },
  ];
  const addButtonFunction = (object) => {
    history.push(
      object.id
        ? route.admin.baseApp.user + "/" + object.id
        : route.admin.baseApp.user + "/create"
    );
  };
  const changeObj = (resp, type) => {
    if (resp) {
      let users_clone = [...users];
      if (type === "delete") {
        users_clone = users_clone.filter((v) => {
          return v.id !== resp.id;
        });
      } else if (type === "update") {
        users_clone.forEach((v, index) => {
          if (v.id === resp.id) {
            users_clone[index] = resp;
          }
        });
      }
      setUsers(users_clone);
    }
  };
  const sort = (a, b) => {
    if (sorting === 1) {
      return sortByDateTime(a, b);
    } else {
      return sortAlphabetical(a, b);
    }
  };
  const usersTableSettings = {
    collapsed: true,
    clickAction: (object) => {
      history.push(`/admin/user/${object.id}/details`);
    },
    actions: [
      {
        type: "edit",
        callback: (object) => {
          addButtonFunction(object);
        },
      },
      {
        type: "block",
        callback: (object, event) => {
          event.preventDefault();
          blockToggleUser(object, null, dispatch, changeObj);
          // if (object.active) {
          //   blockUnblockUser(object.id, { active: false }, dispatch).then(
          //     (resp) => {
          //       changeObj(resp, "update");
          //     }
          //   );
          // } else {
          //   blockUnblockUser(object.id, { active: true }, dispatch).then(
          //     (resp) => {
          //       changeObj(resp, "update");
          //     }
          //   );
          // }
        },
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              await deleteAdminUser(object.id, dispatch);
              changeObj(object, "delete");
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "NAME",
        renderer: (object) => {
          return (
            <Fragment>
              <div style={{ minWidth: "32px", minHeight: "32px" }}>
                <Avatar
                  size={32}
                  round={"32px"}
                  name={object.name}
                  src={`${object.photo}?v=${Math.random()}`}
                />
              </div>
              <Medium14Font
                className="ml-2"
                style={{
                  overflowWrap: "anywhere",
                }}
              >
                {object.name}
              </Medium14Font>
            </Fragment>
          );
        },
      },
      {
        name: "email",
        label: "EMAIL",
        renderer: (object) => {
          return (
            <Medium14Font
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {object.email}
            </Medium14Font>
          );
        },
      },
      {
        name: "country",
        label: "Location",
        renderer: (object) => {
          return <Medium14Font>{object.country}</Medium14Font>;
        },
      },
      {
        name: "role",
        label: "Role",
        renderer: (object) => {
          return <Medium14Font>{object.role}</Medium14Font>;
        },
      },
      {
        name: "cases",
        label: "Cases",
        renderer: (object) => {
          return <Medium14Font>{object.cases}</Medium14Font>;
        },
      },
      {
        name: "role",
        label: "Space usage",
        renderer: (object) => {
          // object.memory_used
          return <Medium14Font>{object.space}</Medium14Font>;
        },
      },
      {
        name: "role",
        label: "Last Login",
        renderer: (object) => {
          return (
            <Medium14Font style={{ color: theme.colors.darkOpacity }}>
              {object.last_login
                ? moment(object.last_login).format("DD-MM-YYYY")
                : "N/A"}
            </Medium14Font>
          );
        },
      },
    ],
  };
  const searchField = (field_value) => {
    return field_value.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
  };
  const filter = (value, index) => {
    let check = true;
    if (country) {
      check = value.country === country;
    }
    if (check && role) {
      check = value.role === role;
    }
    if (searchString && check) {
      check =
        (value.name && searchField(value.name)) ||
        (value.email && searchField(value.email));
    }
    return check;
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <p className="pt-2 pb-2 pl-3">
        <Bold30Font>User List</Bold30Font>
      </p>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <PulseLoader size={30} color={theme.colors.blue} />
        </div>
      ) : (
        <div className="p-3 pt-0">
          <div className="d-flex justify-content-between">
            <div style={{ width: "90%" }}>
              <CasesSearchFilterBar
                searchBarValue={searchString}
                onSearchBarChangeHandler={(e) => {
                  setSearchString(e.target.value);
                }}
                searchBarPlaceholder={"Search"}
                searchBarInputName={"search"}
                sortOptions={order}
                onFilterDatesAlphabetChangeHandler={(el) => {
                  setSorting(el.value);
                }}
                clearSearchBar={clearSearchBarHandler}
              />
            </div>
            <Button
              style={{ width: "150px", height: "42px" }}
              clickHandler={addButtonFunction}
              primary
            >
              <div className="d-flex align-items-center">
                <img src={plusIcon} alt="" className="img-fluid mr-2" />
                <Bold14Font style={{ color: "inherit" }}>Add User</Bold14Font>
              </div>
            </Button>
          </div>
          <div className="row">
            <div
              className={`d-flex col align-items-center ${
                width < 400 ? "flex-column" : null
              }`}
            >
              <Medium14Font
                style={{ whiteSpace: "nowrap" }}
                textColor={theme.colors.darkOpacity}
              >
                Filter by:
              </Medium14Font>
              <SelectNew
                placeholder={"Role"}
                className="ml-3"
                classNamePrefix="Select"
                backgroundColor={"transparent"}
                options={[{ value: "", label: "Any Role" }].concat(roleOptions)}
                onChangeHandler={(el) => {
                  setRole(el.value);
                }}
                styles={{
                  container: (state) => ({
                    ...state,
                    width: "200px",
                  }),
                }}
              />
              <SelectNew
                className="ml-3"
                backgroundColor={"transparent"}
                options={[{ value: null, label: "Any Country" }].concat(
                  createCountryOptionsList()
                )}
                onChangeHandler={(el) => {
                  setCountry(el.value);
                }}
                styles={{
                  container: (state) => ({
                    ...state,
                    width: "200px",
                  }),
                }}
                placeholder={"Country"}
                classNamePrefix="Select"
              />
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col">
              <Bold18Font textColor={theme.colors.dark}>Users</Bold18Font>
              <Medium18Font
                textColor={theme.colors.darkOpacity}
                className="ml-2"
              >
                {users.filter(filter).sort(sort).length}
              </Medium18Font>
            </div>
          </div>
          <div className="row">
            <div className="col ml-3 mr-3">
              <Table
                settings={usersTableSettings}
                data={users.filter(filter).sort(sort)}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
