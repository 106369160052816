import styled, { css } from "styled-components/macro";
import {
  BaseBoldFont,
  BaseMediumFont,
} from "../../../../components/FontsNewComponent/Fonts";
import rem from "../../../../utils/rem";

export const FormattedContentCSSStyled = css`
  ul {
    list-style: initial;
    padding-left: 25px;
  }

  h2.tp-header {
    ${BaseBoldFont};
    font-size: ${rem("24px")};
  }

  p.tp-paragraph,
  li::marker {
    ${BaseMediumFont};
    font-size: ${rem("18px")};
    color: ${({ theme }) => theme.colors.darkOpacity};
  }
`;

const FormattedContentDivStyled = styled.div`
  ${FormattedContentCSSStyled};
`;

export default FormattedContentDivStyled;
