import styled from "styled-components/macro";
import PrimaryButtonStyled from "../styled/PrimaryButtonStyled";

const LandingPageSubscribeButtonStyled = styled(PrimaryButtonStyled)`
  @media (max-width: 767.98px) {
    width: 65px;
    height: 40px;
  }

  @media (min-width: 768px) {
    height: 40px;
  }
`;

export default LandingPageSubscribeButtonStyled;
