import React, { Fragment } from "react";
import BaseAppLayout from "../../components/baseAppComponents/BaseAppLayout";

const BaseApp = () => {
  return (
    <Fragment>
      <BaseAppLayout />
    </Fragment>
  );
};

export default BaseApp;
