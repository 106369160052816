import styled from "styled-components/macro";

const UserBlockStyled = styled.div`
  border-radius: 0 0 4px 4px;
  border: 1px solid #dcdce6;
  margin-left: 0;
  margin-right: 0;
  background-color: #ffffff;
`;

export default UserBlockStyled;
