import React, {Fragment, useState} from "react";
import UserForm from "./UserForm";
import {route} from "../../../routes";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {Bold30Font} from "../../../components/FontsNewComponent/Fonts";
import SideBar from "../../../components/SideBar/SideBar";
import PasswordForm from "./PasswordForm";
import {SETTINGS} from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import SecurityForm from "./SecurityForm";

const pageConfig = [
  {
    path: route.baseApp.settings,
    title: 'Settings',
    activeMenuItem: SETTINGS,
  },
];
const UserSettings = () => {
  const items = ['Profile', 'Password', 'Security'];
  const [activeItem, setActiveItem] = useState(0);
  let content;
  switch (activeItem) {
    case 0:
      content = <UserForm/>;
      break;
    case 1:
      content = <PasswordForm/>;
      break;
    case 2:
      content = <SecurityForm/>;
      break;
    default:
      content = null;
      break;
  }
  return <Fragment>
    <PageConfiguration configArray={pageConfig}/>
    <Bold30Font>Settings</Bold30Font>
    <div className="row">
      <div className="col-sm-3 mt-3">
        <SideBar items={items} activeItem={activeItem} setActiveItem={setActiveItem}/>
      </div>
      <div className="col-sm-9 mt-3">
        {content}
      </div>
    </div>

  </Fragment>
};
export default UserSettings;
