import React, { Fragment, useState, useEffect } from "react";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { HIDE_RIGHT_SIDE_BAR, SET_EDITABLE_OBJECT } from "../../../redux/types";
import ButtonNew from "../../buttons/Button/ButtonNew";
import SeparatorStyled from "./styled/SeparatorStyled";
import {
  getBundles,
  updateApplicationPaperById,
  updateAuthorityById,
  updateEvidence,
  uploadDocumentFile,
} from "../../../containers/Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";

const AssignToBundleForm = ({ caseId, afterSaveHandler }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const dispatch = useDispatch();

  // data states
  const documentObject = useSelector(
    (state) => state.rightSideBar.editableObject
  );
  const [bundlesOptionsList, setBundlesOptionsList] = useState([]);
  const [selectedBundlesList, setSelectedBundlesList] = useState([]);

  // get data

  const saveData = (resp) => {
    setBundlesOptionsList(
      resp
        .filter((bundle) => bundle.class_name === documentObject.document_type)
        .map((bundle) => ({ label: bundle.label, value: bundle.id }))
    );
    setSelectedBundlesList(
      documentObject.bundles
        .filter((bundle) => bundle.class_name === documentObject.document_type)
        .map((bundle) => ({ label: bundle.label, value: bundle.id }))
    );
  };

  const getDataFromAPI = async () => {
    const resp = await getBundles(dispatch, caseId);
    if (resp) {
      saveData(resp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  // send data

  const onSubmitFormHandler = async (event) => {
    event.preventDefault();
    setIsSubmiting(true);
    let resp, data;
    switch (documentObject.document_type) {
      case "App\\Cases":
        const formData = new FormData();
        formData.append("bundles", JSON.stringify(documentObject.bundles));
        resp = await uploadDocumentFile(
          documentObject.id,
          caseId,
          dispatch,
          formData
        );
        break;

      case "App\\Evidence":
        data = { bundles: documentObject.bundles };
        resp = await updateEvidence(
          documentObject.classes.fake_id,
          caseId,
          dispatch,
          data
        );
        break;

      case "App\\Authority":
        data = { bundles: documentObject.bundles };
        resp = await updateAuthorityById(
          documentObject.classes.fake_id,
          caseId,
          dispatch,
          data
        );
        break;

      case "App\\Papers":
        data = { bundles: documentObject.bundles };
        resp = await updateApplicationPaperById(
          documentObject.classes.fake_id,
          documentObject.classes.fake_id_application,
          caseId,
          dispatch,
          data
        );
        break;

      default:
        return;
    }
    if (resp && afterSaveHandler) {
      await afterSaveHandler();
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
  };

  // button handlers

  // input handlers

  const updateObject = (resp) => {
    let obj = { ...documentObject, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };

  const onBundleChange = (select) => {
    setSelectedBundlesList(select);
    if (select) {
      updateObject({
        bundles: select.map((bundle) => ({
          id: bundle.value,
          label: bundle.label,
        })),
      });
    } else {
      updateObject({
        bundles: [],
      });
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group d-flex mb-4 pr-3">
          <SelectAnotherTry
            label="Assign to bundle"
            options={bundlesOptionsList}
            isClearable={false}
            isMulti
            placeholder={"Select bundle"}
            value={selectedBundlesList}
            defaultValue={
              selectedBundlesList && selectedBundlesList.length
                ? bundlesOptionsList.filter((v) =>
                    selectedBundlesList.find((b) => b.id === v.value)
                  )
                : []
            }
            reactSelectStyles={{
              control: (provided) => ({
                ...provided,
                height: "unset !important",
                minHeight: "42px !important",
                borderRadius: "21px !important",
              }),
            }}
            onChange={onBundleChange}
          />
        </div>
      </div>
      <div>
        <SeparatorStyled />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={onSubmitFormHandler} id={``}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isSubmiting}
                disabled={isSubmiting}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AssignToBundleForm;
