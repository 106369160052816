import React, { Fragment, useEffect, useRef, useState } from "react";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch } from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import InputNew from "../../InputNew/InputNew";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import { parseISO } from "date-fns";
import moment from "moment";

const MOTION = "Motion";
const AFFIDAVIT = "Affidavit";
const EXHIBIT = "Exhibit";
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const PapersBasicInfoForm = ({
  evidenceObject,
  setEvidenceObject,
  saveToAPIMethod,
  withOutObjectives,
}) => {
  const [object_, setObject] = useState(evidenceObject);
  let editableStringsRef = useRef([]);

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  // create first objective string if no objectives
  useEffect(() => {
    if (!withOutObjectives) {
      if (object_.objectives && !object_.objectives.length) {
        setObject((prevState) => ({
          ...prevState,
          objectives: [{ name: "" }],
        }));
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitObject = {
      label: object_.label,
      doc_type: object_.doc_type,
      probative_status: object_.probative_status,
      date: object_.date,
    };
    setEvidenceObject(submitObject);
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const dispatch = useDispatch();

  const onChangeInput = (event) => {
    event.persist();
    setObject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const changeDocType = (select) => {
    setObject((prevState) => ({
      ...prevState,
      doc_type: select.value,
    }));
  };
  const changePropStatus = (select) => {
    setObject((prevState) => ({
      ...prevState,
      probative_status: select.value,
    }));
  };
  const docTypeOptions = [
    { label: MOTION, value: MOTION },
    { label: AFFIDAVIT, value: AFFIDAVIT },
    { label: EXHIBIT, value: EXHIBIT },
  ];
  const probStatusOptions = [
    { label: SUPPORTING, value: SUPPORTING },
    { label: OPPOSING, value: OPPOSING },
  ];
  const setIssueDate = (date) => {
    setObject((prevState) => ({
      ...prevState,
      date: date && date !== "" ? moment(date).format("YYYY-MM-DD") : null,
    }));
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            name={"label"}
            placeholder={"Enter Label"}
            label={"label"}
            value={object_.label}
            onChangeHandler={onChangeInput}
          />
        </div>
        <div className="form-group">
          <SelectAnotherTry
            options={docTypeOptions}
            onChange={changeDocType}
            value={docTypeOptions.find((v) => v.value === object_.doc_type)}
            label="Doc Type"
          />
        </div>
        <div className="form-group">
          <SelectAnotherTry
            options={probStatusOptions}
            onChange={changePropStatus}
            value={probStatusOptions.find(
              (v) => v.value === object_.probative_status
            )}
            label="Probative Status"
          />
        </div>
        <div className="form-group">
          <CustomReactDatepicker
            selected={object_.date ? moment(object_.date).toDate() : null}
            onChange={setIssueDate}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            label="Issue Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <ButtonNew
              type="submit"
              // clickHandler={handleSubmit}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default PapersBasicInfoForm;
