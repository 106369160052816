import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import useWindowSize from "../../../hooks/useWindowSize";

import CasesSearchInput from "./CasesSearchInput";
import CasesDropdownFilter from "./CasesDropdownFilter";

import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";
import closeIcon from "../../../assets/img3/svg/close-black-icon.svg";
import CasesDropdownSwitcher from "./CasesDropdownSwitcher";

const order = [
  {
    value: 1,
    label: "Last updated",
    method: () => null,
  },
  {
    value: 2,
    label: "Alphabetical",
    method: () => null,
  },
];

const CasesSearchFilterBar = ({
  searchBarValue,
  hideSorting,
  onSearchBarChangeHandler,
  searchBarPlaceholder,
  searchBarInputName,
  clearSearchBar,
  filterTypesOptions,
  sortOptions,
  customInitialWidth,
  onSwitchTypeValueChangeHandler,
  switchTypeOptions,
  onFilterTypesValueChangeHandler,
  onFilterDatesAlphabetChangeHandler,
  theme,
  switchType,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  const { width, height } = useWindowSize();
  const [twoRowLayout, setTwoRowLayout] = useState(false);

  useEffect(() => {
    if (width < 1200) {
      setTwoRowLayout(true);
    } else {
      setTwoRowLayout(false);
    }
  }, [width]);

  return (
    <div className="row mb-4">
      <div className="col-12">
        <div className="input-group position-relative">
          {switchType ? (
            <CasesDropdownSwitcher
              positionStart={true}
              styles={{
                container: (state) => ({
                  ...state,
                  width: "3.99%",
                  minWidth: "36px",
                }),
              }}
              placeholder="Filter by type"
              options={switchTypeOptions}
              onChange={onSwitchTypeValueChangeHandler}
            />
          ) : null}
          <CasesSearchInput
            positionStart={!twoRowLayout && !switchType}
            positionMiddle={switchType}
            style={{
              width: twoRowLayout
                ? "100%"
                : switchType && hideSorting
                ? "96%"
                : switchType
                ? "62%"
                : filterTypesOptions
                ? "65.99%"
                : "80.99%",
              maxWidth: switchType && hideSorting ? "calc(100% - 36px)" : null,
              marginBottom: twoRowLayout ? "0.5rem" : "0",
              background: `url(${magnifierIcon}) no-repeat left 20px top 13px, ${theme.colors.white}`,
            }}
            type="text"
            value={searchBarValue}
            positionEnd={hideSorting ? true : false}
            onChange={onSearchBarChangeHandler}
            placeholder={searchBarPlaceholder}
            name={searchBarInputName}
          />
          {clearSearchBar ? (
            <button
              className={`btn${searchBarValue ? "" : " d-none"}`}
              onClick={clearSearchBar}
              style={{
                position: "absolute",
                right: twoRowLayout
                  ? "5px"
                  : filterTypesOptions
                  ? "34.2%"
                  : "20%",
                top: twoRowLayout && !filterTypesOptions ? "5px" : null,
                zIndex: 100,
              }}
            >
              <img src={closeIcon} alt="" className="img-fluid" />
            </button>
          ) : null}

          <div
            className="input-group-append"
            style={{
              width: twoRowLayout ? "100%" : filterTypesOptions ? "34%" : "17%",
              marginLeft: "0.1px",
            }}
          >
            {filterTypesOptions ? (
              <CasesDropdownFilter
                positionStart={twoRowLayout}
                positionMiddle={!twoRowLayout}
                styles={{
                  container: (state) => ({
                    ...state,
                    width: "50%",
                  }),
                }}
                placeholder="Filter by type"
                options={filterTypesOptions}
                onChange={onFilterTypesValueChangeHandler}
              />
            ) : null}
            {hideSorting ? null : (
              <CasesDropdownFilter
                positionEnd={filterTypesOptions || !twoRowLayout}
                styles={{
                  container: (state) => ({
                    ...state,
                    width: filterTypesOptions ? "50%" : "100%",
                  }),
                }}
                placeholder="Sort by"
                options={!sortOptions ? order : sortOptions}
                onChange={onFilterDatesAlphabetChangeHandler}
                defaultValue={!sortOptions ? order[0] : sortOptions[0]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTheme(CasesSearchFilterBar);
