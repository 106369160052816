import React, {Fragment, useEffect, useState} from "react";
import {getAdminDashboardData} from "../../Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import {DASHBOARD} from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {route} from "../../../routes";
import {useDispatch} from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {theme} from "../../../styled-components/Theme/Theme";
import {Bold24Font, Bold30Font, Medium14Font} from "../../../components/FontsNewComponent/Fonts";
import BaseWhiteCardStyled from "../../../components/baseAppComponents/BaseWhiteCard/styled/BaseWhiteCardStyled";
import styled from "styled-components/macro";
import {useHistory} from "react-router-dom";
import trialHubIcon from "../../../assets/img3/svg/trial-hub-icon.svg";
import subsIcon from "../../../assets/img/svg/subs_icon.png";
import caseIcon from "../../../assets/img/svg/case_icon.png";
import chartIcon from "../../../assets/img/svg/chart.svg";
import {TagElementTurquoise} from "../../../components/TagElementStyled/TagElementStyled";
import LineGraph from "../../../components/Chart/LineGraph";
import CasesMenu from "../../../components/baseAppComponents/CasesMenu";
import TrialHubFilterDropdownDashboard
  from "../../../components/baseAppComponents/TrialHubFilterDropdown/TrialHubFilterDropdownDashboard";

const ArrowStyled = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: rgba(203,207,238, 0.1);
`;
const NEW_CASES = "NEW_CASES";
const NEW_USERS = "NEW_USERS";
const options_months = [
  {value: 1, label: 1},
  {value: 2, label: 2},
  {value: 3, label: 3},
  {value: 4, label: 4},
  {value: 5, label: 5},
  {value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12},
];
const year = new Date().getFullYear();
export default function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [managerData, setManagerData] = useState([]);
  const [activeTab, setActiveTab] = useState(NEW_CASES);
  const [selectedMonth, setSelectedMonth] = useState(options_months.find((v) => { return v.value === new Date().getMonth()+1}));
  const [selectedYear, setSelectedYear] = useState({label: year, value: year});
  const [labels, setLabels] = useState([]);
  const [yearsOptions, setYearsOptions] = useState([{label: year, value: year}]);
  const [data, setData] = useState({});
  const [chartIsLoading, setChartIsLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const response = await getAdminDashboardData(dispatch, null);
      setData(response);
      if (response) {
        let opt_ = [];
        for (let i = response.min_date; i <= response.max_date; i++) {
          opt_.push({label: i, value: i});
        }
        setYearsOptions(opt_);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const updateChart = async () => {
    setChartIsLoading(true);
    let body = {
      type: activeTab === NEW_CASES ? 'cases' : 'users',
      year: selectedYear.value,
      month: selectedMonth.value,
    };
    const response = await getAdminDashboardData(dispatch, body);
    setLabels(Object.keys(response));
    setManagerData(Object.values(response));
    setChartIsLoading(false);
  };
  useEffect(() => {
    updateChart();
  }, [activeTab, selectedYear, selectedMonth]);
  const casesMenu = [
    {
      text: "New Cases",
      activeType: NEW_CASES,
    },
    {
      text: "New Users",
      activeType: NEW_USERS,
    },
  ];
  const history = useHistory();
  const pageConfig = [
    {
      path: `${route.admin.baseApp.dashboard}`,
      title: 'Dashboard',
      activeMenuItem: DASHBOARD,
    },
  ];

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue}/>
      </div>
    );
  }
  const hubsAndTrialProofs = [
    {
      id: 1,
      label: "Total Users",
      icon: trialHubIcon,
      all: data.all_users ? data.all_users : '0',
      total: data.total_users ? data.total_users : '0',
    },
    {
      id: 2,
      label: "Total Subscriptions",
      all: data.all_subs ? data.all_subs : '0',
      total: data.total_subs ? data.total_subs : '0',
      icon: subsIcon,
    },
    {
      id: 3,
      label: "Total Cases",
      icon: caseIcon,
      total: data.total_cases ? data.total_cases : '0',
      all: data.all_cases ? data.all_cases : '0',
    },
  ];
  return <Fragment>
    <PageConfiguration configArray={pageConfig}/>
    <Bold30Font className="mb-3">Dashboard</Bold30Font>
    <div className="row mb-4">
      {hubsAndTrialProofs.map((el) => (
        <div key={el.id} className="col-12 mb-4 mb-3 col-lg-6 col-xl-4 mb-xl-0">
          <BaseWhiteCardStyled className="h-100 p-4 d-flex flex-column justify-content-between position-relative">
            <div className="row flex-wrap">
              <div className="col-4 col-lg-12 col-xl-4">
                <img
                  src={el.icon}
                  alt=""
                  className="img-fluid"
                  style={{maxWidth: "70px"}}
                />
              </div>
              <div className="col-7 d-flex pl-3 flex-column col-lg-12 col-xl-8 justify-content-end">
                <Bold24Font>{el.all}</Bold24Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>{el.label}</Medium14Font>
              </div>
            </div>
            <div className="row">
              <div className="col-4 pl-3 col-lg-12 col-xl-4 d-flex align-items-end">
                <TagElementTurquoise className="mb-0">+{el.total}</TagElementTurquoise>
              </div>
              <div className="col-7 col-lg-12 col-xl-8">
                <img
                  src={chartIcon}
                  alt=""
                  className="img-fluid float-right"
                  style={{maxWidth: "143px"}}
                />
              </div>
            </div>
          </BaseWhiteCardStyled>
        </div>
      ))}
    </div>
    <div className="row">
      <div className="col-xl-7 col-lg-12">
        <CasesMenu
          className="mb-2 mt-2 pl-2 pr-2"
          active={activeTab}
          casesMenu={casesMenu}
          getType={setActiveTab}
          noBadges={true}
        />
      </div>
      <div className="col-xl-5 col-lg-12 d-flex align-items-center justify-content-xl-end justify-content-lg-center">
        <div className="row">
          <div className="col-6 d-flex align-items-center">
            <Medium14Font textColor={theme.colors.darkOpacity}>Year:</Medium14Font>
            <TrialHubFilterDropdownDashboard
              controlWidth={'120px'}
              className="ml-1"
              options={yearsOptions}
              value={selectedYear}
              onChange={setSelectedYear}
              placeholder="Select Year"
              isSearchable={false}
            />
          </div>
          <div className="col-6 d-flex align-items-center">
            <Medium14Font textColor={theme.colors.darkOpacity}>Month:</Medium14Font>
            <TrialHubFilterDropdownDashboard
              className="ml-1"
              controlWidth={'120px'}
              options={options_months}
              value={selectedMonth}
              onChange={setSelectedMonth}
              isSearchable={false}
            />
          </div>
        </div>
      </div>
    </div>
    {chartIsLoading ?  <div className="h-100 mt-5 justify-content-center align-items-center d-flex">
      <PulseLoader size={30} color={theme.colors.blue}/>
    </div> :  <div className="row">
      <div className="col-12">
        <div className="w-100" style={{height: '600px'}}>
          <LineGraph data_name={activeTab === NEW_USERS ? {single: 'user', plural: 'users'} :{single: 'case', plural: 'cases'}} data={managerData} labels={labels}/>
        </div>
      </div>
    </div>}
  </Fragment>

}
