import styled from "styled-components/macro";

const BulkPanelStyled = styled.div`
  border-radius: 4px;
  border: 1px solid #dcdce6;
  padding: 12px 30px;
  margin-left: -15px;
  margin-right: -15px;
`;

export default BulkPanelStyled;
