import React, { Fragment, useEffect, useState } from "react";
import FormHeader from "../forms/Form/styled/FormHeader";
import { Bold14Font, Bold18Font } from "../FontsNewComponent/Fonts";
import PropTypes from "prop-types";
import FormStyled from "../forms/Form/styled/FormStyled";
import ListAddButton from "../buttons/ListAddButton";
import { theme } from "../../styled-components/Theme/Theme";
import TableRowStyled from "./Styled/TableRowStyled";
import ListActionButton from "../buttons/ListActionButton/ListActionButton";
import collapseIcon from "../../assets/img/svg/collapse.svg";
import TableDivStyled from "./Styled/TableDivStyled";
import TablePopupPositionStyled from "./Styled/TablePopupPositionStyled";
import styled from "styled-components/macro";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import useOnClickOutside from "../../hooks/useOnClickOutside";
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const OverflowDiw = styled.td`
  padding: 10px 15px;
  border: none !important;
  //overflow-wrap: anywhere;
`;
const TableElement = styled.table`
  width: calc(100% - 1px);
`;
const ActionsTd = styled.td`
  padding: 10px 15px;
  border: none !important;
  display: -webkit-box;
  float: right;
`;
const CollapseTr = styled.tr`
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  outline: 0 !important;
  font-size: 0;
  line-height: 0;
`;
const sortableIcon = (
  <svg
    style={{ marginRight: "20px" }}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="59"
    viewBox="0 0 10 59"
  >
    <g>
      <g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 27a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 27a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 35a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 35a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 43a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 43a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 51a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 51a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 59a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 59a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
      </g>
    </g>
  </svg>
);
const CollapseTd = styled.td`
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  outline: 0 !important;
  font-size: 0;
  line-height: 0;
`;
export default function TableNoStateContent(props) {
  let {
    title,
    hideTitle,
    addButtonFunction,
    data,
    settings,
    addButtonLabel,
    object_id,
    customButton,
    selectedId,
    canDrag,
    dataKey
  } = props;
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [popupInRow, setPopupInRow] = useState(null);
  const content = [];
  data.forEach(function (object, index) {
    let objectClone = { ...object };
    let canCollapse = false;
    let row_array = settings.fields.map((value, i) => {
      if (
        settings.collapsed &&
        Array.isArray(object[value.name]) &&
        object[value.name].length > 1
      ) {
        canCollapse = true;
      }
      if (
        Array.isArray(object[value.name]) &&
        canCollapse &&
        collapsedItems.indexOf(object.id) === -1
      ) {
        objectClone[value.name] = object[value.name].filter((v_, i_) => {
          return i_ === 0;
        });
      }
      return (
        <TableDivStyled
          key={"row_" + i}
          {...(value.maxWidth
            ? { style: { maxWidth: value.maxWidth } }
            : null)}
          {...(value.width ? { style: { width: value.width } } : null)}
          clickAction={settings.clickAction}
          onClick={
            settings.clickAction
              ? () => {
                settings.clickAction(object);
              }
              : null
          }
        >
          {value.renderer(objectClone, dataKey+'_'+objectClone.id)}
        </TableDivStyled>
      );
    });
    if (settings.actions && settings.actions.length) {
      row_array.push(
        <ActionsTd
          key={index}
        >
          {settings.actions.map((action, i) => {
            return (
              <ListActionButton
                key={i}
                type={
                  action.type !== "block"
                    ? action.type
                    : object.active
                    ? "block"
                    : "unblock"
                }
                clickHandler={
                  action.type !== "options"
                    ? (event) => {
                      action.callback(object, event);
                    }
                    : (event) => {
                      action.callback(
                        object,
                        event,
                        popupInRow,
                        setPopupInRow,
                        index
                      );
                    }
                }
              />
            );
          })}
          {popupInRow === index ? (
            settings.actions.find((x) => x.type === "options") ? (
              <TablePopupPositionStyled>
                {React.cloneElement(
                  settings.actions.find((x) => x.type === "options")
                    .popupComponent,
                  {
                    idForHandler: object.id,
                    closeHandler: () => {
                      setPopupInRow(null);
                    },
                  }
                )}
              </TablePopupPositionStyled>
            ) : null
          ) : null}
        </ActionsTd>
      );
    }
    if (settings.sortable) {
      row_array.unshift(<TableDivStyled key={'drag_element'}>{canDrag ? sortableIcon : null}</TableDivStyled>);
    }
    content.push({ id: object.id, data: row_array, canCollapse });
  });
  const changeCollapsed = (id) => {
    if (collapsedItems.indexOf(id) === -1) {
      setCollapsedItems([...collapsedItems, id]);
    } else {
      setCollapsedItems(
        collapsedItems.filter((i) => {
          return i !== id;
        })
      );
    }
  };
  let TBody;
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    return settings.setNewOrder({object_id, items});
  };
  if (canDrag) {
    const Items = React.memo(function Items() {
      return content.map((v, i) => {
        return (
          <Draggable key={v.id} draggableId={v.id.toString()} index={i}>
            {(provided, snapshot) => (
              <TableRowStyled
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                selected={selectedId && selectedId === v.id}
                collapsed={collapsedItems.indexOf(v.id) !== -1}
                isDragging={snapshot.isDragging}
                key={"row_" + i}
              >
                {v.data}
              </TableRowStyled>)}
          </Draggable>
        );
      });
    });
    TBody = () => {
      return <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={String(object_id)}>
          {(provided) => (
            <tbody  {...provided.droppableProps}
                    ref={provided.innerRef}>
            <Items/>
            {provided.placeholder}
            </tbody>)}
        </Droppable>
      </DragDropContext>
    }
  } else {
    TBody = () => {
      return (
        <tbody>
        {content.map((v, i) => {
          return (
            <Fragment key={"row_" + i}>
              <TableRowStyled
                selected={selectedId && selectedId === v.id}
                collapsed={collapsedItems.indexOf(v.id) !== -1}
              >
                {v.data}
              </TableRowStyled>
              {v.canCollapse ? (
                <CollapseTr key={"div_" + i}>
                  <CollapseTd
                    colSpan={
                      settings.actions.length
                        ? settings.fields.length + 1
                        : settings.fields.length
                    }
                  >
                    <div
                      className="align-items-center d-flex justify-content-center"
                      style={{ height: 0, overFlow: "visible" }}
                    >
                      <img
                        style={{
                          cursor: "pointer",
                          transform:
                            collapsedItems.indexOf(v.id) !== -1
                              ? "rotate(180deg)"
                              : "unset",
                        }}
                        onClick={() => {
                          changeCollapsed(v.id);
                        }}
                        src={collapseIcon}
                        alt={"collapse"}
                      />
                    </div>
                  </CollapseTd>
                </CollapseTr>
              ) : null}
            </Fragment>
          );
        })}
        </tbody>
      );
    };
  }
  return (
    <Fragment>
      <div
        ref={(ref) => {
          if (props.getRef) {
            return props.getRef(ref);
          }
        }}
        className={props.divClass ?? "row"}
        style={{ marginBottom: "30px" }}
      >
        {title ? (
          <FormStyled
            title={title}
            style={{ paddingBottom: 0, marginBottom: 0 }}
          >
            <FormHeader
              style={{
                marginBottom: 0,
                paddingRight: "30px",
                paddingLeft: settings.sortable && !canDrag ? 0 : "30px",
              }}
              className="d-flex flex-wrap align-items-center justify-content-between"
            >
              <Bold18Font>
                {settings.sortable && !canDrag ? sortableIcon : null}
                {hideTitle ? null : title}
              </Bold18Font>
              <div className="d-flex">
                {addButtonFunction ? (
                  <ListAddButton
                    label={addButtonLabel}
                    clickHandler={addButtonFunction}
                    style={{ marginRight: customButton ? "24px" : "0" }}
                  />
                ) : null}
                {customButton ?? null}
              </div>
            </FormHeader>
          </FormStyled>
        ) : null}
        {data && data.length ? (
          <div className="table-responsive" style={{ overflowY: "hidden" }}>
            <TableElement className="table" style={{ ...props.style }}>
              <thead>
              <tr style={{ padding: "10px 15px" }}>
                {settings.sortable ? <td style={{width: '5%'}}/> : null}
                {settings.fields.map((value, i) => {
                  return (
                    <OverflowDiw
                      key={value.name}
                      {...(value.width
                        ? { style: { width: value.width } }
                        : null)}
                    >
                      <Bold14Font style={{ color: theme.colors.darkOpacity }}>
                        {value.label}
                      </Bold14Font>
                    </OverflowDiw>
                  );
                })}
                {settings.actions && settings.actions.length ? (
                  <OverflowDiw />
                ) : null}
              </tr>
              </thead>
              <TBody />
            </TableElement>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

TableNoStateContent.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  addButtonFunction: PropTypes.func,
  addButtonLabel: PropTypes.string,
  data: PropTypes.array.isRequired,
  customButton: PropTypes.object,
  settings: PropTypes.object.isRequired,
  selectedId: PropTypes.number,
};

TableNoStateContent.defaultProps = {
  hideTitle: false,
};
