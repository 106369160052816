import styled from "styled-components";

export const Container = styled.div`
  padding-top: 20px;
  margin-left: -15px;
  width: calc(100% + 41px);
  height: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
