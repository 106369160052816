import styled from "styled-components/macro";

const LiStyled = styled.li`
  list-style: none;
  :before {
    content: "\\2022";
    color: ${({theme}) => theme.colors.blue};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 14px;
    margin-left: -1em;
  }
`;

export default LiStyled;
