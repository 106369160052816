import styled from "styled-components/macro";

const FormHeader = styled.div`
    height: 70px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

export default FormHeader;
