import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  HIDE_RIGHT_SIDE_BAR,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../../redux/types";
import { updateTopicById } from "../../../../Auth/auth";
import {
  Bold18Font,
  Medium14Font,
} from "../../../../../components/FontsNewComponent/Fonts";
import Table from "../../../../../components/Table/Table";
import { useDispatch } from "react-redux";
import AssociateElementsThemesIssuesForm from "../../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import NoRecords from "../../../../../components/NoRecords";
import ListAddButton from "../../../../../components/buttons/ListAddButton/ListAddButton";

const FlagRelevance = ({ topicsList, setTopicsList, caseObject }) => {
  const [selectedTopic, setSelectedTopic] = useState({});
  const { id, xExamId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const sendData = async (submitObject) => {
    if (Object.keys(submitObject).length) {
      let index = topicsList.findIndex((v) => v.id === selectedTopic.id);
      let new_obj = [...topicsList];
      if (index !== -1) {
        new_obj[index] = { ...new_obj[index], ...submitObject };
      }
      setTopicsList(new_obj);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
  };

  const showAssociationPanel = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateElementsThemesIssuesForm
          key={selectedTopic.id}
          evidenceObject={selectedTopic}
          sendData={sendData}
        />
      ),
      title: "Flag Relevance",
    });
  };

  // show RSB on selecting topic:
  useEffect(() => {
    if (Object.keys(selectedTopic).length) {
      showAssociationPanel(selectedTopic);
    }
  }, [selectedTopic]);

  const tableSettings = {
    clickAction: (obj) => {
      setSelectedTopic(obj);
    },
    actions: [],
    fields: [
      {
        name: "fake_id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        width: "70px",
      },
      {
        name: "name",
        label: "TOPIC",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "tags",
        label: "RELEVANCE",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul className="d-flex flex-wrap">
                {object.tags.map((tag, index) => {
                  return tag.element;
                })}
              </ul>
            );
          } else {
            return (
              <ListAddButton
                className="d-flex"
                label="Flag Relevance"
                clickHandler={() => null}
              />
            );
          }
        },
      },
    ],
  };

  return (
    <div className="col">
      {topicsList && topicsList.length ? (
        <Table
          selectedId={selectedTopic ? selectedTopic.id : null}
          data={topicsList.map((el) => ({ ...el, case: { ...caseObject } }))}
          settings={tableSettings}
          style={{ tableLayout: "fixed" }}
        />
      ) : (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Add topics on previous tab
          </Bold18Font>
        </NoRecords>
      )}
    </div>
  );
};

export default FlagRelevance;
