import React from "react";
import BaseWhiteCardStyled from "./styled/BaseWhiteCardStyled";

const BaseWhiteCard = ({ className, children }) => {
  return (
    <BaseWhiteCardStyled className={`${className} p-4`}>
      {children}
    </BaseWhiteCardStyled>
  );
};

export default BaseWhiteCard;
