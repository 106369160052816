import { RESET_SUBSCRIPTION, SAVE_SUBSCRIPTION } from "../types";

const initialState = {
  selected: {},
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SUBSCRIPTION:
      return {
        ...state,
        selected: action.payload,
      };

    case RESET_SUBSCRIPTION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
