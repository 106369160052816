import React, { Fragment, useEffect, useRef, useState } from "react";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Label from "../AddCardForm/Label";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch } from "react-redux";
import TextAreaSideBar from "./TextAreaSideBar";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { updateEvidence } from "../../../containers/Auth/auth";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

const EvidenceObjectivesForm = ({
  evidenceObject,
  setEvidenceObject,
  saveToAPIMethod,
  withOutObjectives,
  ...props
}) => {
  const [object_, setObject] = useState(evidenceObject);
  let editableStringsRef = useRef([]);

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  // create first objective string if no objectives
  useEffect(() => {
    if (!withOutObjectives) {
      if (object_.objectives && !object_.objectives.length) {
        setObject((prevState) => ({
          ...prevState,
          objectives: [{ name: "" }],
        }));
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitObject = {
      preempt: object_.preempt,
      summary: object_.summary,
    };
    if (!withOutObjectives) {
      submitObject.objectives = object_.objectives.filter(
        (el) => el.name !== ""
      );
    }
    let resp = submitObject;
    if (saveToAPIMethod) {
      resp = await updateEvidence(
        evidenceObject.id,
        evidenceObject.case.id,
        dispatch,
        submitObject
      );
    }
    if (resp) {
      setEvidenceObject(resp);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
  };
  const dispatch = useDispatch();

  const deleteObjective = (index) => {
    setObject({
      ...object_,
      ...{
        objectives: object_.objectives.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };

  const addObjective = async (e) => {
    let objectives;
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      if (object_.objectives && !object_.objectives.length) {
        objectives = [{ name: "" }];
      } else {
        objectives = [...object_.objectives, { name: "" }];
      }
      setObject({
        ...object_,
        ...{ objectives },
      });
    }
  };

  const editObjective = (index, name) => {
    let objectives = object_.objectives;
    objectives[index].name = name;
    setObject({
      ...object_,
      ...{ objectives },
    });
  };

  const onChangeTextFieldHandler = (event) => {
    event.persist();
    setObject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <Fragment>
      {props && props.fromDocs ? null : (
        <div style={{ minHeight: "92px", height: "100%", overflowY: "auto" }}>
          <div className="form-group">
            <Label name={"Summary"} />
            <TextAreaSideBar
              withMargin
              placeholder={"Enter Summary"}
              value={object_.summary}
              name={"summary"}
              onChange={onChangeTextFieldHandler}
            />
          </div>
          <div className="form-group">
            <Label name={"Pre-empt"} />
            <TextAreaSideBar
              withMargin
              placeholder={"Enter Pre-empt"}
              value={object_.preempt}
              name={"preempt"}
              onChange={onChangeTextFieldHandler}
            />
          </div>
        </div>
      )}
      {!withOutObjectives ? (
        <div style={{ minHeight: "92px", height: "100%", overflowY: "auto" }}>
          <div className="form-group">
            {object_.objectives && object_.objectives.length ? (
              <Fragment>
                <AnimatedDiv className="form-group mb-0">
                  <Label className={"row"} name={"Objectives"} />
                </AnimatedDiv>
                {object_.objectives.map((sub_t, i) => (
                  <AnimatedDiv key={i} className="form-group pl-3">
                    <EditableStringClassComponent
                      editName={editObjective}
                      objective={true}
                      editState={sub_t.name === ""}
                      deleteAction={deleteObjective}
                      object={sub_t.name}
                      index={i}
                      ref={(ref) => assignRef(ref, i)}
                    />
                  </AnimatedDiv>
                ))}
              </Fragment>
            ) : null}
            <div className="form-group">
              <div className="container-fluid">
                <ListAddButton
                  className="d-flex"
                  label="Add Objective"
                  clickHandler={addObjective}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <ButtonNew
              type="submit"
              // clickHandler={handleSubmit}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EvidenceObjectivesForm;
