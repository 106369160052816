import React, {Fragment, useState} from "react";
import PropTypes from 'prop-types';
import {theme} from "../../../styled-components/Theme/Theme";
import {Medium14Font} from "../../Fonts/Fonts";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import InputStyled from "../../Input/styled/InputStyled";
import {handleErrorMessage} from "../../Modal/modalErrorHandler";
import {useDispatch} from "react-redux";

export default function EditableString(props) {
  const dispatch = useDispatch();
  const {object, editState, index, deleteAction, editName, objective} = props;
  const [edit, setEdit] = useState(editState);
  const doneEditing = () => {
    if (!objective && !object.name) {
      handleErrorMessage("Field shouldn't be empty", dispatch);
      return;
    }
    if (!objective && object.name.length > 255) {
      handleErrorMessage("Field value is to long", dispatch);
      return;
    }
    setEdit(false);
  };
  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      doneEditing();
    }
  };
  if (edit) {
    return <Fragment>
      <div className="d-flex align-items-center justify-content-between w-100">
        <InputStyled required maxLength={255} value={objective? object : object.name} onKeyDown={_handleKeyDown} onChange={(e) => {
          editName(index, e.target.value, object.id)
        }} autoFocus={true}/>
        <div className="d-flex">
          <ListActionButton clickHandler={() => {
            doneEditing();
          }} className={'ml-2'} type={'done'}/>
          <ListActionButton clickHandler={() => {
            deleteAction(index, object.id)
          }} type={'delete'}/>
        </div>
      </div>
    </Fragment>;
  } else {
    return <Fragment>
      <div className="d-flex align-items-center justify-content-between w-100">
          <Medium14Font style={{lineHeight: '28px'}} textColor={theme.colors.dark}>{objective ? object : object.name}</Medium14Font>
        <div className="d-flex">
          <ListActionButton clickHandler={() => {
            setEdit(true);
          }} className={'ml-2'} type={'edit'}/>
          <ListActionButton clickHandler={() => {
            deleteAction(index, object.id)
          }} type={'delete'}/>
        </div>
      </div>
    </Fragment>
  }
}

EditableString.propTypes = {
  object: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  editState: PropTypes.bool,
  deleteAction: PropTypes.func.isRequired,
  editName: PropTypes.func.isRequired
};
