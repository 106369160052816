import styled, { css } from "styled-components/macro";
import PropTypes from "prop-types";

const withCloseButtonCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
`;

const BaseAppLayoutRightSideBarHeaderStyled = styled.div`
  min-height: 70px;
  margin-bottom: 1rem;
  padding-left: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  ${({ withCloseButton }) => (withCloseButton ? withCloseButtonCSS : null)};
`;

BaseAppLayoutRightSideBarHeaderStyled.propTypes = {
  withCloseButton: PropTypes.bool,
};

export default BaseAppLayoutRightSideBarHeaderStyled;
