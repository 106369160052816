import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { NotebookContext } from "@/context";
import {
  Container,
  AvatarDiv,
  LockIcon,
  UnlockIcon,
  CollabContainer,
  NotebookType,
  Row,
  PopoverContainer,
  Column,
  PopoverText1,
  PopoverText2,
} from "./styled";
import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";
import { Popover, Switch } from "antd";

const NotebookBlock = () => {
  const { notebook, setNotebook } = useContext(NotebookContext);
  const [locked, setLocked] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const { id: caseId, notebook_id: notebookId } = useParams();

  const setNotebookStatus = async (status) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.put(
      `/api/cases/${caseId}/note-book/${notebookId}`,
      {
        private: status, // "private" || "public"
      },
      config
    );

    if (res.data.data) {
      setNotebook(res.data.data);
    }
  };

  if (!notebook) return null;

  const content = (
    <PopoverContainer>
      <Column style={{ marginRight: 24 }}>
        <PopoverText1>Share notebook</PopoverText1>
        <PopoverText2>Share with case team</PopoverText2>
      </Column>
      <Switch
        checked={!notebook.private}
        onChange={() => {
          setNotebookStatus(!notebook.private);
        }}
      />
    </PopoverContainer>
  );

  return (
    <Container className="d-flex flex-column">
      <div className="d-flex flex-row" style={{ alignItems: "center" }}>
        <Collaborators notebook={notebook} />
        <div className="d-flex flex-column">
          <div className="d-flex flex-row" style={{ alignItems: "center" }}>
            <span style={{ color: "#6A6C7D", fontWeight: 500 }}>NOTEBOOK</span>
            <div style={{ position: "relative" }}>
              <Popover content={content} trigger="click" placement="bottomLeft">
                {!!notebook.private ? (
                  <span
                    style={{
                      background: "#374AC2",
                      color: "white",
                      padding: "2px 8px",
                      borderRadius: "12px",
                      marginLeft: 8,
                      cursor: "pointer",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                    // onClick={() => setLocked(!locked)}
                    onClick={() => setShowChangeStatus(!showChangeStatus)}
                  >
                    Private
                    <LockIcon />
                  </span>
                ) : (
                  <span
                    style={{
                      background: "#374AC210",
                      color: "#374AC2",
                      padding: "2px 8px",
                      borderRadius: "12px",
                      marginLeft: 8,
                      cursor: "pointer",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                    // onClick={() => setLocked(!locked)}
                    onClick={() => setShowChangeStatus(!showChangeStatus)}
                  >
                    Shared
                    <UnlockIcon />
                  </span>
                )}
              </Popover>
            </div>
          </div>
          <span style={{ color: "#0F122F", fontWeight: 600, fontSize: 14 }}>
            {notebook.name}
          </span>
        </div>
      </div>
      <Row style={{ marginTop: 12 }}>
        <NotebookType
          style={{
            borderRadius: 4,
            padding: "0px 18px",
            width: "min-content",
          }}
          type={notebook.type}
        >
          {notebook.type}
        </NotebookType>
        {notebook.type === "Application" && (
          <>
            <span
              style={{
                marginLeft: 8,
                marginRight: 8,
                color: "#9fa0af",
              }}
            >
              •
            </span>
            <span style={{ color: "#9fa0af" }}>
              {notebook.application.label}
            </span>
          </>
        )}
      </Row>
    </Container>
  );
};

const Collaborators = ({ notebook }) => (
  <CollabContainer className="d-flex flex-row">
    {/*
    <Avatar color="#5D6CCD" borderColor="#374AC2" text="MK" />
    */}
    <Avatar
      color="#5D6CCD"
      borderColor="#374AC2"
      text={notebook.user.name
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase()}
    />

    {/*
    <Avatar color="#97D3D3" borderColor="#259B9A" text="NN" />
    */}
  </CollabContainer>
);

const Avatar = ({ color, borderColor, text }) => (
  <AvatarDiv color={color} borderColor={borderColor}>
    {text}
  </AvatarDiv>
);

export default NotebookBlock;
