import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CaseCardFirmNameStyled = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.cyanBorder};
  background-color: ${({ theme }) => theme.colors.cyanFill};
  color: ${({ theme }) => theme.colors.cyan};
  font-size: ${rem(`14px`)};
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default CaseCardFirmNameStyled;
