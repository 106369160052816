import { useEffect } from "react";

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      let isDrag = false;
      function moveListener(e) {
        isDrag = true;
      }
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        if (!isDrag) {
          handler(event);
        }
        isDrag = false;
      };

      document.addEventListener("mousedown", () => (isDrag = false));
      document.addEventListener("mousemove", moveListener);
      document.addEventListener("mouseup", listener);

      // document.addEventListener("touchstart", () => (isDrag = false));
      // document.addEventListener("touchmove", moveListener);
      // document.addEventListener("touchend", listener);

      return () => {
        document.removeEventListener("mousedown", () => (isDrag = false));
        document.removeEventListener("mousemove", moveListener);
        document.removeEventListener("mouseup", listener);
        // document.removeEventListener("touchstart", () => (isDrag = false));
        // document.removeEventListener("touchmove", moveListener);
        // document.removeEventListener("touchend", listener);
      };
    },
    [ref, handler]
  );
}

export default useOnClickOutside;
