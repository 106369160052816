import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PulseLoader from "react-spinners/PulseLoader";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import {
  createNewWitness,
  deleteWitnessById,
  getCaseById,
  getWitnessById,
  updateWitnessById,
} from "../../../Auth/auth";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import Button from "../../../../components/buttons/Button/Button";
import Panel from "../../../../components/Panel/Panel";
import Form from "../../../../components/forms/Form";
import InputNew from "../../../../components/InputNew/InputNew";
import SelectAnotherTry from "../../../../components/Select/SelectAnotherTry";
import Arrow from "../../../../components/buttons/Button/Arrow";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import TextArea from "../../../../components/inputs/TextArea/TextArea";
import CloseIcon from "../../../../components/svgIcons/CloseIcon";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_LAST_PAGE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import LinkEvidenceForm from "../../../../components/forms/WitnessForms/LinkEvidenceForm";
import Table from "../../../../components/Table";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import AssociateElementsThemesIssuesForm from "../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import ObjectivesForm from "../../../../components/forms/WitnessForms/ObjectivesForm";
import ButtonAnotherTry from "../../../../components/buttons/Button/ButtonAnotherTry";
import ExamLines from "../../../../components/ExamLines";
import ContextAndRelevance from "./witnessesForms/ContextAndRelevance";
import TopicsAndObjectives from "./xExamForms/TopicsAndObjectives";
import Objectives from "./witnessesForms/Objectives";
import AddTopicForm from "../../../../components/forms/WitnessForms/AddTopicForm";
import {
  handleFlatRelevanceElements,
  setTagField,
} from "../../../../utils/function";
import ListAddButton from "../../../../components/buttons/ListAddButton/ListAddButton";
import { closeBtnText } from "../../../../utils/constants";
import styled from "styled-components/macro";

const panelWitnessItems = [
  "Witness Info",
  "Context & Relevance",
  "Evidence",
  "Topics & Objectives",
  "Exam Lines",
];

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 884px) {
   display: grid;
   justify-content: center;
  }
`;

const NEW_OWN_WITNESS_LABEL = "New Own Witness";
const NEW_X_EXAM_WITNESS_LABEL = "New X-Exam Witness";

const AddEditWitness = ({ caseObject, theme }) => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [onDelete, setOnDelete] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const { path, url } = useRouteMatch();
  const [blockBlurEvent, setBlockBlurEvent] = useState(false);
  // Witness states
  const [witnessObject, setWitnessObject] = useState({});
  const [witnessName, setWitnessName] = useState("");
  const [partyOptions, setPartyOptions] = useState([]);
  const [witnessSelectedParty, setWitnessSelectedParty] = useState(null);
  const [witnessClassOptions, setWitnessClassOptions] = useState([
    {
      label: "Factual",
      value: "Factual",
    },
    {
      label: "Expert",
      value: "Expert",
    },
  ]);
  const [witnessSelectedClass, setSelectedWitnessClass] = useState("");
  const [witnessReasonForTestimony, setWitnessReasonForTestimony] = useState(
    ""
  );
  const [
    witnessContextAndBackground,
    setWitnessContextAndBackground,
  ] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);

  // X-Exam states
  const [xExamWitnessTopicsList, setXExamWitnessTopicsList] = useState([]);

  const { id, witnessId, xExamId } = useParams();
  const stateFromLocation = useLocation().state;
  const history = useHistory();
  const [addWitnessStage, setAddWitnessStage] = useState(
    stateFromLocation && stateFromLocation.openTab
      ? stateFromLocation.openTab
      : 0
  );
  const dispatch = useDispatch();

  const checkIfXExamFlowIsFalse = () => {
    return (
      !witnessObject.party ||
      !Object.keys(witnessObject.party).length ||
      !caseObject.parties ||
      !caseObject.parties.length ||
      !caseObject.parties.find(
        (v) => v.id === witnessObject.party.id && !v.client
      )
    );
  };
  const setWitnessFlagRelevanceElements = (causes_of_action) => {
    updateWitnessObject({ causes_of_action });
  };
  const setWitnessFlagRelevanceDefenceElements = (
    causes_of_action_defensive
  ) => {
    updateWitnessObject({ causes_of_action_defensive });
  };
  const setWitnessFlagRelevanceParentElements = (causes_of_action_parent) => {
    updateWitnessObject({ causes_of_action_parent });
  };
  const setWitnessFlagRelevanceThemes = (themes) => {
    updateWitnessObject({ themes });
  };
  const setWitnessFlagRelevanceParentThemes = (themes_parent) => {
    updateWitnessObject({ themes_parent });
  };
  const setWitnessFlagRelevanceIssues = (issues) => {
    updateWitnessObject({ issues });
  };
  const setWitnessFlagRelevanceParentIssues = (issues_parent) => {
    updateWitnessObject({ issues_parent });
  };
  const flagRelevanceSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        icon: plusIcon,
        callback: () => handleAssociateElementsThemesIssues(witnessObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(witnessObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          switch (index) {
            case 0:
              updateWitnessObject({
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              });
              break;

            case 1:
              updateWitnessObject({
                themes: [],
                themes_parent: [],
              });
              break;

            case 2:
              updateWitnessObject({
                issues: [],
                issues_parent: [],
              });
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      witnessObject.causes_of_action_parent ?? [],
      witnessObject.causes_of_action ?? [],
      witnessObject.causes_of_action_defensive ?? [],
      witnessObject.issues_parent ?? [],
      witnessObject.issues ?? [],
      witnessObject.themes_parent ?? [],
      witnessObject.themes ?? [],
      witnessObject.case
    ),
    setObjectsFunctions: {
      setElements: setWitnessFlagRelevanceElements,
      setThemes: setWitnessFlagRelevanceThemes,
      setIssues: setWitnessFlagRelevanceIssues,
    },
    setParentObjectsFunctions: {
      setElementsParent: setWitnessFlagRelevanceParentElements,
      setThemesParent: setWitnessFlagRelevanceParentThemes,
      setIssuesParent: setWitnessFlagRelevanceParentIssues,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setWitnessFlagRelevanceDefenceElements([
                          ...listDefElementsCopy,
                        ]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        updateWitnessObject({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        updateWitnessObject({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        updateWitnessObject({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const updateWitnessObject = (data) => {
    setDataToState({ ...witnessObject, ...data });
  };
  const setDataToState = (data) => {
    if (!data.case && caseObject) {
      data.case = caseObject;
    }
    setWitnessObject(data);
    setWitnessName(data.name);
    setWitnessSelectedParty(data.party ? data.party.id : data.id_party);
    setSelectedWitnessClass(data.class);
    setWitnessReasonForTestimony(data.reason_to_testimony);
    setWitnessContextAndBackground(data.background);
    // set party options
    const partyOptionsList = [];
    data.case.parties.forEach((party) => {
      partyOptionsList.push({
        label: `${party.name} - ${party.role}`,
        value: party.id,
      });
    });
    setPartyOptions([...partyOptionsList]);
    const tempTopics = data.topics ? [...data.topics] : [];
    const topicsWithTags = setTagField(data.case, tempTopics);
    setXExamWitnessTopicsList([...topicsWithTags]);
  };

  // get witnessObject or X-Exam witnessObject from api
  useEffect(() => {
    const getData = async () => {
      if (witnessId || witnessId) {
        const resp = await getWitnessById(id, witnessId, dispatch);
        if (resp) {
          setDataToState(resp);
          setIsLoading(false);
        }
      } else {
        let case_ = await getCaseById(id, dispatch);
        setDataToState({
          case: case_,
          name: "",
          party: {},
          class: "",
          exam_lines: [],
          reason_to_testimony: "",
          background: "",
          evidences: [],
          topics: [],
          causes_of_action: [],
          causes_of_action_defensive: [],
          causes_of_action_parent: [],
          themes: [],
          themes_parent: [],
          issues: [],
          issues_parent: [],
        });
        setIsLoading(false);
      }
    };
    // if no witnessObject - get it from API
    if (!Object.keys(witnessObject).length) {
      getData();
    } else {
      setDataToState(witnessObject);
    }
  }, [witnessObject]);

  useEffect(() => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  }, [addWitnessStage]);

  const handleSubmit = async (submitObject) => {
    if (!onSubmit) {
      setOnSubmit(true);
      let resp;
      if (witnessObject.id) {
        resp = await updateWitnessById(id, witnessId, dispatch, submitObject);
      } else {
        resp = await createNewWitness(id, dispatch, submitObject);
      }
      if (resp) {
        setWitnessObject(resp);
        // set breadcrumbs last page after update
        dispatch({
          type: SET_LAST_PAGE,
          payload: {
            path: !is_admin
              ? `/app/cases/${id}/trial-hub/witness/edit/${resp.id}`
              : `/admin/all-cases/${id}/trial-hub/witness/edit/${resp.id}`,
            title: resp.name,
            activeMenuItem: CASES,
          },
        });
        setOnSubmit(false);
        return true;
      }
      setOnSubmit(false);
    }
  };

  const handleDelete = async (event) => {
    event.persist();
    if (witnessObject.id) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "You won't be able to restore data",
        beforeCloseHandler: checkIfXExamFlowIsFalse()
          ? async () => {
              await deleteWitnessById(id, witnessId, dispatch);
              history.push(
                !is_admin
                  ? `/app/cases/${id}/trial-hub/witness`
                  : `/admin/all-cases/${id}/trial-hub/witness`
              );
            }
          : async () => {
              await deleteWitnessById(id, witnessId, dispatch);
              history.push(
                !is_admin
                  ? `/app/cases/${id}/trial-hub/x-exam`
                  : `/admin/all-cases/${id}/trial-hub/x-exam`
              );
            },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    } else {
      if (checkIfXExamFlowIsFalse()) {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/witness`
            : `/admin/all-cases/${id}/trial-hub/witness`
        );
      } else {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/x-exam`
            : `/admin/all-cases/${id}/trial-hub/x-exam`
        );
      }
    }
  };

  const saveAndCloseHandler = async () => {
    const resp = await handleSubmit(witnessObject);
    if (resp) {
      if (history.action === "PUSH") {
        return history.goBack();
      } else {
        if (checkIfXExamFlowIsFalse()) {
          history.push(
            !is_admin
              ? `/app/cases/${id}/trial-hub/witness`
              : `/admin/all-cases/${id}/trial-hub/witness`
          );
        } else {
          history.push(
            !is_admin
              ? `/app/cases/${id}/trial-hub/x-exam`
              : `/admin/all-cases/${id}/trial-hub/x-exam`
          );
        }
      }
    }
  };

  const onWitnessFieldsChange = (event) => {
    event.persist();
    switch (event.target.name) {
      case "name":
        if (event.target.value.length <= 125)
          updateWitnessObject({ name: event.target.value });
        break;
      case "reason_to_testimony":
        if (event.target.value.length <= 300)
          updateWitnessObject({ reason_to_testimony: event.target.value });
        break;
      case "background":
        if (event.target.value.length <= 300)
          updateWitnessObject({ background: event.target.value });
        break;
      default:
        break;
    }
  };

  const onPartyChange = (selectedOption) => {
    setWitnessSelectedParty(selectedOption);
    updateWitnessObject({
      id_party: selectedOption.value,
      party: {
        id: selectedOption.value,
        name: selectedOption.label,
      },
    });
  };

  const onClassChange = (selectedOption) => {
    setSelectedWitnessClass(selectedOption.value);
    updateWitnessObject({ class: selectedOption.value });
  };

  const sendFlagRelevanceData = async (submitObject) => {
    if (Object.keys(submitObject).length) {
      const resp = await updateWitnessById(
        id,
        witnessId,
        dispatch,
        submitObject
      );
      if (resp) {
        setWitnessObject(resp);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        // setIsLoading(false);
      }
    }
  };

  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateElementsThemesIssuesForm
          key={witnessObject.id}
          caseId={id}
          evidenceObject={witnessObject}
          setEvidenceObject={updateWitnessObject}
          selectedMenuTab={index}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const showRightSideBarWithContent = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <LinkEvidenceForm
          key={witnessObject.id}
          witnessObject={witnessObject}
          callback={updateWitnessObject}
          selectedMenuTab={index}
          returnData={true}
          caseId={id}
          setStage={setAddWitnessStage}
        />
      ),
      title: "Retrieve Evidence",
    });
  };

  const setEvidenceObject = (evidenceObject) => {
    // TODO: If API return fake_id - clear this string
    let clone = Object.assign(evidenceObject, {});
    if (clone.real_id) {
      evidenceObject.fake_id = clone.id;
      evidenceObject.id = clone.real_id;
    }
    const evidenceIndex = witnessObject.evidences.findIndex(
      (evidence) => evidence.fake_id === evidenceObject.fake_id
    );
    setWitnessObject((prevState) => {
      const tempObject = { ...prevState };
      if (evidenceIndex !== -1) {
        tempObject.evidences[evidenceIndex] = evidenceObject;
      }
      return tempObject;
    });
  };

  const updateTopicsListHandler = (topicsList) => {
    const topicsListWithTags = setTagField(witnessObject.case, [...topicsList]);
    updateWitnessObject({ topics: topicsListWithTags });
  };

  const deleteTopicFromListHandler = (topicId) => {
    const xExamWitnessTopicsListCopy = [...xExamWitnessTopicsList];
    const topicIndexForDelete = xExamWitnessTopicsListCopy.findIndex(
      (el) => el.id === topicId
    );
    xExamWitnessTopicsListCopy.splice(topicIndexForDelete, 1);
    updateWitnessObject({ topics: xExamWitnessTopicsListCopy });
  };

  const onClickAddTopicHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AddTopicForm
          caseId={id}
          xExamId={witnessId}
          witnessObject={witnessObject}
          afterSubmitHandler={updateTopicsListHandler}
        />
      ),
      title: "Add Topic",
    });
  };

  // Reset selected ID when changing addWitnessStage
  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length > 0) {
      setSelectedItem(null);
    }
  }, [addWitnessStage]);

  // Open RSB when selecting item in table
  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length !== 0) {
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: typeof selectedItem.evidences === 'undefined' ? (
          <ObjectivesForm
            key={selectedItem.id}
            evidenceObject={selectedItem}
            // setEvidenceObject NOT state setter function!
            setEvidenceObject={setEvidenceObject}
            caseId={id}
          />
        ) : (
          <AddTopicForm
            caseId={id}
            topicObject={selectedItem}
            key={selectedItem.id}
            xExamId={witnessId}
            witnessObject={witnessObject}
            afterSubmitHandler={updateTopicsListHandler}
            afterDeleteHandler={deleteTopicFromListHandler}
          />
        ),
        title: "Objectives",
      });
    }
  }, [selectedItem]);

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: witnessObject.case.label ? witnessObject.case.label : "New Case",
      activeMenuItem: CASES,
    },
    {
      path: checkIfXExamFlowIsFalse()
        ? !is_admin
          ? `/app/cases/${id}/trial-hub/witness`
          : `/admin/all-cases/${id}/trial-hub/witness`
        : !is_admin
        ? `/app/cases/${id}/trial-hub/x-exam`
        : `/admin/all-cases/${id}/trial-hub/x-exam`,
      title: checkIfXExamFlowIsFalse()
        ? "Trial Hub - Witnesses"
        : "Trial Hub - X-Exam",
      activeMenuItem: CASES,
    },
    witnessObject.id
      ? {
          path: !is_admin
            ? `/app/cases/${id}/trial-hub/witness/edit/${witnessObject.id}`
            : `/admin/all-cases/${id}/trial-hub/witness/edit/${witnessObject.id}`,
          title: witnessObject.name
            ? witnessObject.name
            : checkIfXExamFlowIsFalse()
            ? NEW_OWN_WITNESS_LABEL
            : NEW_X_EXAM_WITNESS_LABEL,
          activeMenuItem: CASES,
        }
      : {
          path: !is_admin
            ? `/app/cases/${id}/trial-hub/witness/create`
            : `/admin/all-cases/${id}/trial-hub/witness/create`,
          title: witnessObject.name
            ? witnessObject.name
            : checkIfXExamFlowIsFalse()
            ? "New Own Witness"
            : "New X-Exam Witness",
          activeMenuItem: CASES,
        },
  ];
  const getSettings = (is_topic = true) => {
    const fields = [
      {
        name: !is_topic ? "label" : "name",
        label: !is_topic ? "EVIDENCE" : "TOPIC",
        renderer: (object) => {
          return (
            <Medium14Font>
              {!is_topic ? object.label : object.name}
            </Medium14Font>
          );
        },
        [!is_topic ? "width" : null]: "700px",
      },
      {
        name: "objectives",
        label: "OBJECTIVES",
        width: "60%",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{objective.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          } else {
            return (
              <ListAddButton
                className="d-flex"
                label="Add Objectives"
                clickHandler={() => null}
              />
            );
          }
        },
      },
    ];
    if (!is_topic) {
      fields.unshift({
        name: "fake_id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        width: "70px",
      });
    }
    return {
      collapsed: false,
      clickAction: (object) => {
        setSelectedItem(object);
      },
      actions: [],
      fields: fields,
    };
  };

  const setLines = (resp) => {
    if (resp) {
      updateWitnessObject(resp);
    }
  };
  const setObj = (resp) => {
    setDataToState({ ...witnessObject, exam_lines: resp });
  };

  // const witnessInfoMarkup = <Fragment></Fragment>;

  const contextAndBackground = (
    <div className="form-group">
      <div className="container-fluid">
        <TextArea
          name="background"
          label="Context & Background"
          placeholder="Enter context to this witness’s involvement"
          onChange={onWitnessFieldsChange}
          value={witnessContextAndBackground}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-6">
          <Bold30Font>
            {witnessObject.name !== ""
              ? witnessObject.name
              : checkIfXExamFlowIsFalse()
              ? NEW_OWN_WITNESS_LABEL
              : NEW_X_EXAM_WITNESS_LABEL}
          </Bold30Font>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center mb-3">
          <Button
            clickHandler={handleDelete}
            loading={onDelete}
            disabled={onDelete}
            style={{ marginRight: "20px" }}
            secondary
            delete_
          >
            Delete
          </Button>
          <Button
            clickHandler={saveAndCloseHandler}
            disabled={onSubmit}
            loading={onSubmit}
            type={"submit"}
            primary
            onMouseEnter={() => setBlockBlurEvent(true)}
            onMouseLeave={() => setBlockBlurEvent(false)}
          >
            <Bold14Font textColor={theme.colors.white}>
              {closeBtnText}
            </Bold14Font>
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <Panel
          onChange={(index) => {
            setAddWitnessStage(index);
          }}
          activeItem={addWitnessStage}
          items={panelWitnessItems}
        />
      </div>

      {/* Witness Info */}
      {addWitnessStage === 0 ? (
        <Fragment>
          <div className="container-fluid">
            <div className="row">
              <Form>
                <div style={{ padding: "0 15px" }}>
                  <div className="form-group">
                    <InputNew
                      name="name"
                      type="text"
                      value={witnessName}
                      placeholder="Enter witness name"
                      label="Witness Name"
                      onChange={onWitnessFieldsChange}
                      maxLength={125}
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-sm-6">
                      <SelectAnotherTry
                        label="Party"
                        name="party"
                        placeholder="Select party"
                        options={partyOptions}
                        defaultValue={partyOptions.filter(
                          (option) => option.value === witnessSelectedParty
                        )}
                        onChange={onPartyChange}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-sm-6">
                      <SelectAnotherTry
                        label="Class"
                        name="class"
                        placeholder="Select Factual or Expert"
                        options={witnessClassOptions}
                        defaultValue={witnessClassOptions.filter(
                          (option) => option.value === witnessSelectedClass
                        )}
                        onChange={onClassChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="container-fluid">
                    <TextArea
                      name="reason_to_testimony"
                      label="Reason for Testimony"
                      placeholder="Enter concise rationale for calling this witness"
                      onChange={onWitnessFieldsChange}
                      value={witnessReasonForTestimony}
                    />
                  </div>
                </div>
                {/*{isXExamAddEditPage && isXExamAddEditPage ? (*/}
                {/*  <div style={{ marginTop: "-40px" }}>*/}
                {/*    {contextAndBackground}*/}
                {/*  </div>*/}
                {/*) : null}*/}
                <button style={{ display: "none" }} />
              </Form>
            </div>
          </div>
        </Fragment>
      ) : null}

      {/* Context & Relevance OR Topics & Objectives */}
      {addWitnessStage === 1 ? (
        <Fragment>
          <ContextAndRelevance
            contextAndBackground={contextAndBackground}
            flagRelevanceSettings={flagRelevanceSettings}
            witnessFlagRelevanceElements={witnessObject.causes_of_action}
            witnessFlagRelevanceIssues={witnessObject.issues}
            witnessFlagRelevanceThemes={witnessObject.themes}
          />
        </Fragment>
      ) : null}

      {/* Link Evidence OR Flag Relevance */}
      {addWitnessStage === 2 ? (
        <Fragment>
              <ButtonsDiv className={'mb-3'}>
                <ButtonNew
                  secondary
                  clickHandler={() =>
                    showRightSideBarWithContent(witnessObject, 0)
                  }
                  style={{ minWidth: "284px" }}
                >
                  Retrieve Evidence
                </ButtonNew>
                <ButtonNew
                  secondary
                  clickHandler={() =>
                    showRightSideBarWithContent(witnessObject, 1)
                  }
                  style={{ minWidth: "284px" }}
                >
                  Add New Testimony
                </ButtonNew>
                <ButtonNew
                  secondary
                  clickHandler={() =>
                    showRightSideBarWithContent(witnessObject, 2)
                  }
                  style={{ minWidth: "284px" }}
                >
                  Import New Doc Evidence
                </ButtonNew>
              </ButtonsDiv>
              {witnessObject.evidences && witnessObject.evidences.length ? <Table
                selectedId={selectedItem ? selectedItem.id : null}
                settings={getSettings(false)}
                data={[...witnessObject.evidences].sort(
                  (a, b) => a.fake_id - b.fake_id
                )}
              /> : null}
          {/*{checkIfXExamFlowIsFalse() ? (*/}
          {/*  <LinkEvidence*/}
          {/*    witnessObject={witnessObject}*/}
          {/*    showRightSideBarWithContent={showRightSideBarWithContent}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <FlagRelevance*/}
          {/*    witnessObject={witnessObject}*/}
          {/*    setWitnessObject={updateWitnessObject}*/}
          {/*    topicsList={xExamWitnessTopicsList}*/}
          {/*    setTopicsList={updateTopicsListHandler}*/}
          {/*    caseObject={witnessObject.case}*/}
          {/*  />*/}
          {/*)}*/}
        </Fragment>
      ) : null}

      {/* Objectives OR Evidence */}
      {addWitnessStage === 3 ? (
        <div className="container-fluid">
          <TopicsAndObjectives
            evidenceTableSettings={getSettings(true)}
            witnessObject={xExamWitnessTopicsList}
            selectedId={selectedItem ? selectedItem.id : null}
          />
        </div>
      ) : null}

      {/* Exam Lines */}
      {addWitnessStage === 4 ? (
        <ExamLines
          name="Topic"
          witnessId={witnessId}
          exam_lines={witnessObject.exam_lines}
          setEvidences={setLines}
          witnessObject={witnessObject}
          setObject={setObj}
          setSubmit={updateWitnessObject}
          objects={witnessObject.topics ?? []}
          evidencesList={witnessObject.evidences ?? []}
          isXExamFlowFalse={false}
        />
      ) : null}
      <div className="row mb-5" style={{ padding: "0 15px" }}>
        {addWitnessStage === 3 ? (
          <div className="col">
            <ButtonNew withIcon secondary clickHandler={onClickAddTopicHandler}>
              Add Topic
            </ButtonNew>
          </div>
        ) : null}
        <div className="col d-flex justify-content-end">
          {addWitnessStage ? (
            <ButtonNew
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddWitnessStage((prevState) => prevState - 1);
              }}
              style={{ marginRight: "20px" }}
            >
              <Fragment>
                <Arrow
                  className="ml-1"
                  style={{ transform: "rotate(180deg)" }}
                />
                <Bold14Font textColor={theme.colors.blue}>Back</Bold14Font>
              </Fragment>
            </ButtonNew>
          ) : null}
          {addWitnessStage === panelWitnessItems.length - 1 ? null : (
            <ButtonNew
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setAddWitnessStage((prevState) => prevState + 1);
              }}
            >
              <Fragment>
                <Bold14Font textColor={theme.colors.blue}>Continue</Bold14Font>
                <Arrow className="ml-1" />
              </Fragment>
            </ButtonNew>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(AddEditWitness);
