import styled, { css } from "styled-components/macro";

const overflowSnippet = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TagElementBaseStyled = css`
  line-height: normal;
  padding: 0 6px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: fit-content;
  height: fit-content;
  ${({ pointer }) => (pointer ? 'cursor: pointer' : null)};
  ${({ overflow }) => (overflow ? overflowSnippet : null)};
  margin-bottom: 4px;

  :not(:last-child) {
    margin-right: 4px;
  }
`;

export const TagElementPurple = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.purpleBorder};
  background: ${({ theme }) => theme.colors.purpleFill};
  color: ${({ theme }) => theme.colors.purple};
  stroke: ${({ theme }) => theme.colors.purple};
`;

export const TagElementPurpleSecond = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.purpleBorder_second};
  background: ${({ theme }) => theme.colors.purpleFill_second};
  color: ${({ theme }) => theme.colors.purple_second};
  stroke: ${({ theme }) => theme.colors.purple_second};
`;

export const TagElementOrange = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.orangeBorder};
  background: ${({ theme }) => theme.colors.orangeFill};
  color: ${({ theme }) => theme.colors.orange};
  stroke: ${({ theme }) => theme.colors.orange};
`;

export const TagElementOrangeSecond = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.orangeBorder_second};
  background: ${({ theme }) => theme.colors.orangeFill_second};
  color: ${({ theme }) => theme.colors.orange_second};
  stroke: ${({ theme }) => theme.colors.orange_second};
`;

export const TagElementTurquoiseSecond = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.turquoiseBorder_second};
  background: ${({ theme }) => theme.colors.turquoiseFill_second};
  color: ${({ theme }) => theme.colors.turquoise_second};
  stroke: ${({ theme }) => theme.colors.turquoise_second};
`;

export const TagElementTurquoise = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.turquoiseBorder};
  background: ${({ theme }) => theme.colors.turquoiseFill};
  color: ${({ theme }) => theme.colors.turquoise};
  stroke: ${({ theme }) => theme.colors.turquoise};
`;
export const TagElementCoa = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ index }) => index % 3 === 0 ? '#cee8e8' : index % 2 === 0 ? '#cedbe8' : '#cee8db'};
  background: ${({ index, theme }) => index % 3 === 0 ? theme.colors.turquoiseFill : index % 2 === 0 ? '#f0f8ff' : '#f3fff9'};
  color: ${({ index }) => index % 3 === 0 ? '#259b9a' : index % 2 === 0 ? '#25629b' : '#259b5e'};
  stroke: ${({ theme }) => theme.colors.turquoise};
`;

export const TagElementIssue = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({index}) => index % 3 === 0 ? '#ffe9d1' : index % 2 === 0 ? '#feffd1' : '#ffd2d1'};
  background: ${({ index }) => index % 3 === 0 ? '#fff9f3' : index % 2 === 0 ? '#fffcf2' : '#fff3f3'};
  color: ${({index}) => index % 3 === 0 ? '#ff9017' : index % 2 === 0 ? '#ffc909' : '#ff1b17'};
  stroke: ${({theme}) => theme.colors.turquoise};
`;

export const TagElementTheme = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ index }) => index % 3 === 0 ? '#cfd3f0' : index % 2 === 0 ? '#dccff0' : '#cfe3f0'};
  background: ${({ index }) => index % 3 === 0 ? '#f6f2ff' : index % 2 === 0 ? '#fdf4ff' : '#f0f3ff'};
  color: ${({ index }) => index % 3 === 0 ? '#7f48f4' : index % 2 === 0 ? '#d448f4' : '#4867f4'};
  stroke: ${({ theme }) => theme.colors.turquoise};
`;
export const TagElementCyanSecond = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.cyanBorder_second};
  background: ${({ theme }) => theme.colors.cyanFill_second};
  color: ${({ theme }) => theme.colors.cyan_second};
  stroke: ${({ theme }) => theme.colors.cyan_second};
`;

export const TagElementCyan = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.cyanBorder};
  background: ${({ theme }) => theme.colors.cyanFill};
  color: ${({ theme }) => theme.colors.cyan};
  stroke: ${({ theme }) => theme.colors.cyan};
`;
export const TagElementBlueSecond = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.purpleBorder_second};
  background: ${({ theme }) => theme.colors.purpleFill_second};
  color: ${({ theme }) => theme.colors.blue_second};
  stroke: ${({ theme }) => theme.colors.blue_second};
`;
export const TagElementBlue = styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.purpleBorder};
  background: ${({ theme }) => theme.colors.purpleFill};
  color: ${({ theme }) => theme.colors.blue};
  stroke: ${({ theme }) => theme.colors.blue};
`;
export const TagElementCaseType= styled.div`
  ${TagElementBaseStyled};
  border-color: ${({ theme }) => theme.colors.case_type_border};
  background: ${({ theme }) => theme.colors.grayOpacity};
  color: ${({ theme }) => theme.colors.darkOpacity};
  stroke: ${({ theme }) => theme.colors.darkOpacity};
`;
