import React, { Fragment } from "react";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import BreadCrumbs from "../../BreadCrumbs";
import BaseAppLayoutCentralPartStyled from "./styled/BaseAppLayoutCentralPartStyled";
import StubPage from "../../../StubPage/StubPage";
import Cases from "../../../../containers/BaseApp/Cases";
import EditCase from "../../../../containers/BaseApp/Cases/EditCase";
import BaseAppLayoutLeftSideBar from "../BaseAppLayoutLeftSideBar";
import { route } from "../../../../routes";
import Settings from "../../../../containers/Admin/Settings/Settings";
import AddEditUser from "../../../../containers/Admin/Users/AddEditUser";
import Users from "../../../../containers/Admin/Users/Users";
import CaseArena from "../../../../containers/BaseApp/Cases/CaseArena";
import UserSettings from "../../../../containers/BaseApp/Settings/UserSettings";
import Subscription from "../../Subscription/Subscription";
import UserPaymentHistory from "../../../../containers/Admin/Users/UserPaymentHistory";
import TrialHub from "../../../../containers/BaseApp/TrialHub";
import DocumentsRoute from "../../../../containers/BaseApp/Documents/Documents";
import HelpPage from "../../../../containers/BaseApp/HelpPage";
import ApplicationsHubRouter from "../../../../containers/BaseApp/ApplicationsHub";
import TrialProofsRouter from "../../../../containers/BaseApp/TrialProofs";
import Dashboard from "../../../../containers/BaseApp/Dashboard/Dashboard";
import EvidenceImport from "../../../../containers/BaseApp/TrialHub/Evidence/EvidenceImport";
import PagesAndTutorials from "../../../../containers/Admin/PagesAndTutorials";
import {
  FLOW_PAGES,
  FLOW_TUTORIALS,
} from "../../../../containers/Admin/PagesAndTutorials/PagesAndTutorials";
import AdminDashboard from "../../../../containers/Admin/Dashboard/AdminDashboard";
import CaseForbidden from "../../../../containers/BaseApp/Cases/CaseForbidden";
import CaseLog from "../../../../containers/BaseApp/Cases/CaseLog";
import NotebooksList from "../../../Notebook/NotebooksList";
import NewNotebook from "../../../Notebook/NotebooksList/NewNotebook";
import EditNotebook from "../../../Notebook/NotebooksList/EditNotebook";
import NotebookWorkspace from "../../../Notebook/Workspace";

const BaseAppLayoutCentralPart = ({ menuHamburgerBtn }) => {
  const showLeftSideBar = useSelector(
    (state) => state.leftSideBar.isSideBarPulled
  );
  const isAdmin = useSelector((state) => state.auth.user.is_admin);
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const needRedirectTo = useRouteMatch(`${path}`);
  const user = useSelector((state) => state.auth.user);

  if (needRedirectTo && needRedirectTo.isExact) {
    if (isAdmin) {
      history.push(`${url}/dashboard`);
    } else {
      history.push(`${url}/cases`);
    }
  }

  return (
    <Fragment>
      <div className="d-xl-none">
        {showLeftSideBar ? <BaseAppLayoutLeftSideBar /> : null}
      </div>
      <BaseAppLayoutCentralPartStyled>
        <div className="d-block d-sm-flex flex-wrap">
          <div className="d-xl-none pl-3 pt-3 mb-3 mr-5">
            {menuHamburgerBtn}
          </div>
          <BreadCrumbs />
        </div>

        {!isAdmin ? (
          <Switch>
            <Route exact={true} path={`${route.baseApp.app}`}>
              <StubPage>App</StubPage>
            </Route>
            <Route exact={true} path={`${route.baseApp.dashboard}`}>
              <Dashboard />
            </Route>
            <Route exact={true} path={`${route.baseApp.cases_edit}/:case_id`}>
              <EditCase>Edit</EditCase>
            </Route>
            <Route exact={true} path={`${route.baseApp.cases_create}`}>
              <EditCase>Create</EditCase>
            </Route>
            <Route exact path={`${route.baseApp.cases}/:id/notebooks`}>
              <NotebooksList />
            </Route>
            <Route exact path={`${route.baseApp.cases}/:id/new-notebook`}>
              <NewNotebook />
            </Route>
            <Route
              exact
              path={`${route.baseApp.cases}/:id/notebooks/edit/:notebook_id`}
            >
              <EditNotebook />
            </Route>
            <Route
              exact
              path={`${route.baseApp.cases}/:id/notebooks/:notebook_id`}
            >
              <NotebookWorkspace />
            </Route>
            <Route exact path={`${route.baseApp.cases}/:case_id`}>
              <CaseArena>View Case</CaseArena>
            </Route>
            <Route exact path={`${route.baseApp.cases}/forbidden/:case_id`}>
              <CaseForbidden>View Case</CaseForbidden>
            </Route>
            <Route exact={true} path={`${route.baseApp.cases}`}>
              <Cases>Cases</Cases>
            </Route>
            <Route exact path={`${route.baseApp.caseLog}`}>
              <CaseLog>Cases</CaseLog>
            </Route>
            <Route exact path={`${route.baseApp.trialHub.caseDocumentImport}`}>
              <EvidenceImport />
            </Route>
            <Route path={`${route.baseApp.trialHub.container}`}>
              <TrialHub />
            </Route>
            <Route path={`${route.baseApp.applicationsHub.container}`}>
              <ApplicationsHubRouter />
            </Route>
            <Route path={`${route.baseApp.trialProofs.container}`}>
              <TrialProofsRouter />
            </Route>
            <Route path={`${route.baseApp.documents.container}`}>
              <DocumentsRoute />
            </Route>
            {user.role !== "assistant" ? (
              <Route path={`${route.baseApp.subscription.subscription}`}>
                <Subscription />
              </Route>
            ) : null}
            <Route exact={true} path={`${route.baseApp.settings}`}>
              <UserSettings />
            </Route>
            <Route exact={true} path={`${route.baseApp.help}`}>
              <PagesAndTutorials flow={FLOW_TUTORIALS} />
            </Route>
            <Route path="*">
              <StubPage>404 Page Not Found</StubPage>
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route exact={true} path={`${route.admin.baseApp.app}`}>
              <StubPage>Admin</StubPage>
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.allCases}`}>
              <Cases />
            </Route>
            <Route exact path={`${route.baseApp.caseLog}`}>
              <CaseLog>Cases</CaseLog>
            </Route>
            <Route path={`${route.admin.baseApp.trialHub.container}`}>
              <TrialHub />
            </Route>
            <Route
              exact
              path={`${route.admin.baseApp.trialHub.caseDocumentImport}`}
            >
              <EvidenceImport />
            </Route>
            <Route path={`${route.admin.baseApp.applicationsHub.container}`}>
              <ApplicationsHubRouter />
            </Route>
            <Route path={`${route.admin.baseApp.documents.container}`}>
              <DocumentsRoute />
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.dashboard}`}>
              <AdminDashboard />
            </Route>
            <Route
              exact={true}
              path={`${route.admin.baseApp.cases_edit}/:case_id`}
            >
              <EditCase>Edit</EditCase>
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.userList}`}>
              <Users />
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.user}/create`}>
              <AddEditUser />
            </Route>
            <Route path={`${route.admin.baseApp.trialProofs.container}`}>
              <TrialProofsRouter />
            </Route>
            <Route
              exact={true}
              path={`${route.admin.baseApp.user}/:id([0-9]+)?`}
            >
              <AddEditUser />
            </Route>
            <Route
              exact={true}
              path={`${route.admin.baseApp.user}/:id([0-9]+)?/payment-history`}
            >
              <UserPaymentHistory />
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.userDetails}`}>
              <Cases />
            </Route>
            <Route exact path={`${route.admin.baseApp.allCases}/:case_id`}>
              <CaseArena>View Case</CaseArena>
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.pages}`}>
              <PagesAndTutorials key={1} flow={FLOW_PAGES} />
            </Route>
            <Route exact={true} path={`${route.admin.baseApp.tutorials}`}>
              <PagesAndTutorials key={2} flow={FLOW_TUTORIALS} />
            </Route>
            <Route path={`${route.admin.baseApp.settings.path}`}>
              <Settings />
            </Route>
            <Route path="*">
              <StubPage>404 Page Not Found</StubPage>
            </Route>
          </Switch>
        )}
      </BaseAppLayoutCentralPartStyled>
    </Fragment>
  );
};

export default BaseAppLayoutCentralPart;
