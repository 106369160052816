import { combineReducers } from "redux";
import appVariablesReducer from "./appVariablesReducer";
import authReducer from "./authReducer";
import leftSideBarReducer from "./leftSideBarReducer";
import rightSideBarReducer from "./rightSideBarReducer";
import modalReducer from "./modalReducer";
import subscriptionReducer from "./subscriptionReducer";
import breadcrumbsReducer from "./breadcrumbsReducer";
import { SIGN_OUT } from "../types";
import dataReducer from "./dataReucer";
import extractDataReducer from "./extractDataReducer";

const appReducer = combineReducers({
  app: appVariablesReducer,
  auth: authReducer,
  leftSideBar: leftSideBarReducer,
  rightSideBar: rightSideBarReducer,
  modal: modalReducer,
  subscription: subscriptionReducer,
  breadcrumbs: breadcrumbsReducer,
  data: dataReducer,
  extract: extractDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
