import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const SubscribeFormButtonPosition = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
`;

export default SubscribeFormButtonPosition;
