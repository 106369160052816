import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components/macro";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch } from "react-redux";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "@/redux/types";

import SearchFilterBar from "./SearchFilterBar";
import GridView from "./Grid";
import ListView from "./List";

import ButtonNew from "@/components/buttons/Button/ButtonNew";

import { CARD_VIEW, TABLE_VIEW } from "@/utils/constants";
import { Bold14Font, Bold18Font } from "@/components/FontsNewComponent/Fonts";
import plusIcon from "@/assets/img3/svg/button-plus-icon.svg";
import { loadAccessToken } from "@/containers/Auth/auth";

import { Container, ToolbarInputs, Toolbar } from "./styled";

import NoRecords from "@/components/NoRecords";

const defaultViewOption = { label: "View cards", value: CARD_VIEW };

const defaultNotebooks = [
  {
    id: 1,
    name: "yo",
    type: "application", // trial, appeal, custom
    owner: "Andrey",
    createdAt: "17/03/22",
    updatedAt: "21/07/22",
    content: "",
  },
];

const NotebooksList = ({ theme }) => {
  const [view, setView] = useState("TABLE_VIEW");
  const [notebooks, setNotebooks] = useState([]);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();

  const { id: caseId } = useParams();
  const dispatch = useDispatch();

  const fetchNotebooks = async () => {
    setFetching(true);
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .get(`/api/cases/${caseId}/note-book`, config)
      .catch((err) => {
        setFetching(false);
      });
    if (res && res.data && res.data.data) {
      setFetching(false);
      setNotebooks(res.data.data);
    }
    // setNotebooks(res.da)
  };

  useEffect(() => {
    fetchNotebooks();
  }, []);

  const [filterType, setFilterType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectViewOptions, setSelectViewOptions] = useState([
    { label: "View cards", value: CARD_VIEW },
    { label: "View table", value: TABLE_VIEW },
  ]);
  const [bundlesOptions, setBundlesOptions] = useState([
    { value: null, label: "All" },
    // { value: "all", label: "All types" },
    { value: "application", label: "Application" },
    // { value: "appeal", label: "Appeal" },
    { value: "trial", label: "Trial" },
    { value: "custom", label: "Custom" },
  ]);

  // const addNotebook = (newNotebook) => {
  const addNotebook = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let newId = Math.max(...notebooks.map((o) => o.id)) + 1;
    const res = await axios.post(
      `/api/cases/${caseId}/note-book`,
      {
        name: `notebook_${newId}`,
        type: "Application", // trial, application, custom
      },
      config
    );

    if (res && res.data && res.data.data) {
      setNotebooks((notebooks) => [...notebooks, res.data.data]);
    }
  };

  const editNotebook = async (notebookId) => {
    history.push(`/app/cases/${caseId}/notebooks/edit/${notebookId}`);

    //     const config = {
    //       headers: {
    //         Authorization: `Bearer ${await loadAccessToken()}`,
    //       },
    //     };

    //     const notebookIndex = notebooks.findIndex((n) => n.id === notebookId);
    //     const res = await axios.put(
    //       `/api/cases/${caseId}/note-book/${notebookId}`,
    //       {
    //         name: notebooks[notebookIndex].name + "_edited",
    //       },
    //       config
    //     );

    //     if (res && res.data && res.data.data) {
    //       setNotebooks([
    //         ...notebooks.slice(0, notebookIndex),
    //         res.data.data,
    //         ...notebooks.splice(notebookIndex + 1),
    //       ]);
    //     }
  };

  const onDelete = async (notebookId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.delete(
      `/api/cases/${caseId}/note-book/${notebookId}`,
      config
    );

    if (res && res.data && res.data.result === "success") {
      setNotebooks((notebooks) => notebooks.filter((n) => n.id !== notebookId));
    }
  };

  const deleteNotebook = (notebookId) => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        onDelete(notebookId);
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  if (fetching) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  let filteredNotebooks = notebooks.sort((a, b) =>
    moment(b.updated_at).diff(moment(a.updated_at))
  );

  if (filterType !== null) {
    filteredNotebooks = filteredNotebooks.filter(
      (notebook) => notebook.type.toLowerCase() === filterType.toLowerCase()
    );
  }

  if (searchQuery !== "") {
    filteredNotebooks = filteredNotebooks.filter(
      (notebook) =>
        notebook.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }

  return (
    <Container className="d-flex flex-column" style={{ paddingTop: 36 }}>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div style={{ width: "calc(100% - 195px)" }}>
          <SearchFilterBar
            view={view}
            setView={setView}
            filterType={filterType}
            setFilterType={setFilterType}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            //
            bundlesOptions={bundlesOptions}
            setBundlesOptions={setBundlesOptions}
            selectViewOptions={selectViewOptions}
            setSelectViewOptions={setSelectViewOptions}
          />
        </div>
        <ButtonNew
          className="ml-2"
          loading={false}
          disabled={false}
          clickHandler={() => {
            history.push(`/app/cases/${caseId}/new-notebook`);
          }}
          primary
        >
          <div className="d-flex align-items-center">
            <img src={plusIcon} alt="" className="img-fluid mr-2" />
            <Bold14Font style={{ color: "inherit" }}>
              Add new notebook
            </Bold14Font>
          </div>
        </ButtonNew>
      </div>

      {filteredNotebooks.length === 0 ? (
        <div style={{ height: "100%" }}>
          <NoRecords>
            <Bold18Font textColor={"#0f122f"}>
              Your Notebooks will appear here
            </Bold18Font>
          </NoRecords>
        </div>
      ) : (
        <>
          {view === "CARD_VIEW" && (
            <GridView
              notebooks={filteredNotebooks}
              editNotebook={editNotebook}
              deleteNotebook={deleteNotebook}
            />
          )}
          {view === "TABLE_VIEW" && (
            <ListView
              notebooks={filteredNotebooks}
              editNotebook={editNotebook}
              deleteNotebook={deleteNotebook}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default withTheme(NotebooksList);
