import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  RESET_BREADCRUMBS,
  SET_FIRST_PAGE,
  SET_LEFT_SIDE_BAR_ACTIVE_ITEM,
  UPDATE_BREADCRUMBS,
} from "../../../redux/types";
import { route } from "../../../routes";

const PageConfiguration = ({ configArray }) => {
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    // Set page title:
    window.document.title = configArray[configArray.length - 1].title;
    dispatch({
      type: SET_FIRST_PAGE,
      payload: [
        // {
        //   path: is_admin ? route.admin.baseApp.app : "/app/dashboard",
        //   title: "TrialProofer",
        // },
      ],
    });
    dispatch({ type: UPDATE_BREADCRUMBS, payload: configArray });

    // Set active left menu item:
    if (configArray[configArray.length - 1].activeMenuItem) {
      dispatch({
        type: SET_LEFT_SIDE_BAR_ACTIVE_ITEM,
        payload: configArray[configArray.length - 1].activeMenuItem,
      });
    }
    // TODO: Render set breadcrumbs (BC) component in loader! If last BC dynamic (user name, case name), show loader in place of BC
    return () => dispatch({ type: RESET_BREADCRUMBS });
  }, [configArray]);

  return null;
};

PageConfiguration.propTypes = {
  configArray: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PageConfiguration;
