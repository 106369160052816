import styled from "styled-components";

export const Container = styled.div`
  background: white;
  height: calc(100vh - 170px);
  border: 1px solid #dcdce6;
  border-radius: 4px;
`;

export const Tag = styled.span`
  background: gray;
`;
