import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const InputStyled = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border-radius: 50vh;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: ${rem("10px 20px")};
  background: ${({ theme }) => theme.colors.white};
  font-family: "PT Root UI", sans-serif;
  font-size: 1rem;
  font-weight: 500;

  :hover,
  :focus,
  :active {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.colors.gray};
    background: ${({ theme }) => theme.colors.gray};
  }

  @media (max-width: 575.98px) {
    height: 35px;
    border-radius: 50vh;
    font-size: 1rem;
  }

  ::placeholder {
    // TODO: Connect color to theme
    color: rgba(15, 18, 47, 0.6);
    font-family: "PT Root UI", sans-serif;
    font-size: ${rem(`14px`)};
    font-weight: 500;
  }
`;

export default InputStyled;
