import React, { Fragment } from "react";
import { Bold14Font } from "../FontsNewComponent/Fonts";
import Dropdown from "../../assets/img2/svg/dropdown.svg";
import styled from "styled-components/macro";

const ImageStyled = styled.img`
  transform: rotate(${({ down }) => (down ? "180deg" : "0")});
  margin-right: 12px;
`;
const DocPropertiesLabel = ({
  down,
  name,
  clickHandler,
  button,
  children,
  containerProps,
}) => {
  return (
    <Fragment>
      <label className="w-100">
        <div className="container-fluid" {...containerProps}>
          <div className="row mb-2">
            {button ? (
              <div className="col d-flex justify-content-between align-items-center">
                <Bold14Font
                  onClick={clickHandler}
                  as="div"
                  pinter
                >
                  <ImageStyled src={Dropdown} down={down} alt={"drop"} />
                  {name}
                </Bold14Font>
                {button}
              </div>
            ) : (
              <div className="col">
                <Bold14Font
                  onClick={clickHandler}
                  as="div"
                  pinter
                >
                  <ImageStyled src={Dropdown} down={down} alt={"drop"} />
                  {name}
                </Bold14Font>
              </div>
            )}
          </div>
        </div>
      </label>
      {down ? <div className="mb-3"> {children}</div> : null}
    </Fragment>
  );
};
export default DocPropertiesLabel;
