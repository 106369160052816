import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  getBundles,
  getDocument,
  updateAuthorityById,
} from "../../../containers/Auth/auth";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { theme } from "../../../styled-components/Theme/Theme";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import styled from "styled-components/macro";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useHistory } from "react-router-dom";
import AssociateElementsThemesIssuesForm from "../EvidenceForms/AssociateElementsThemesIssuesForm";
import AuthorityFurtherDetailsForm from "./AuthorityFurtherDetailsForm";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";
import AssociateProofs from "../ApplicationsHubForms/AssociateProofs";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;

const AuthorityDocProperties = ({ caseObject, callback, caseID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bundlesOptionsList, setBundlesOptionsList] = useState([]);
  const [selectedBundlesList, setSelectedBundlesList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const authority = useSelector((state) => state.rightSideBar.editableObject);
  const form = useRef(null);
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const authorityWithCase = () => {
    const authorityCopy = authority;
    authorityCopy.case = { ...caseObject };
    return authorityCopy;
  };

  const loadBundles = async () => {
    if (authority.bundles.length) {
      setSelectedBundlesList(
        authority.bundles.map((bundle) => ({
          label: bundle.label,
          value: bundle.id,
        }))
      );
    }
    const resp = await getBundles(dispatch, caseID);
    if (resp) {
      setBundlesOptionsList(
        resp
          .filter((bundle) => bundle.class_name === authority.document_type)
          .map((bundle) => ({ label: bundle.label, value: bundle.id }))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadBundles();
  }, []);

  const editButton = (type_) => {
    let title_;
    if (type_ === "causes_of_action") {
      title_ = "Elements";
    } else if (type_ === "issues") {
      title_ = "Issues";
    } else if (type_ === "themes") {
      title_ = "Themes";
    }
    return (
      <ListActionButton
        clickHandler={() => {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: authority,
            content: (
              <AssociateElementsThemesIssuesForm
                key={authority.id}
                type={type_}
                evidenceObject={authorityWithCase()}
                setEvidenceObject={updateObject}
                evidenceId={authority.id}
                caseObject={caseObject}
                caseId={caseID}
              />
            ),
            swapContent: {
              content: (
                <AuthorityDocProperties
                  callback={callback}
                  caseObject={caseObject}
                  caseID={caseID}
                />
              ),
              title: "Doc Properties",
            },
            title: title_,
          });
        }}
        type={"edit"}
      />
    );
  };
  const onClickEditOnTrialHubHandler = () => {
    if (authority.application_object) {
      history.push(
        !is_admin
          ? `/app/cases/${caseID}/applications-hub/edit/${authority.application_object.fake_id}`
          : `/admin/all-cases/${caseID}/applications-hub/edit/${authority.application_object.fake_id}`,
        {
          applicationWorkflowStage: 2,
          selectedObject: authority.classes,
        }
      );
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${caseID}/trial-hub/authorities/edit/${authority.classes.fake_id}`
          : `/admin/all-cases/${caseID}/trial-hub/authorities/edit/${authority.classes.fake_id}`
      );
    }
  };
  const ReliefAction = () => {
    return (
      <ListActionButton
        clickHandler={() => {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: authority,
            content: (
              <AssociateProofs
                applicationObject={authority.application_object}
                applicationPaperObject={authority}
                returnObject={true}
                isAuthority={true}
                setApplicationPaperObject={(data) => {
                  updateObject(data);
                }}
                savedReliefs={authority.reliefs}
                savedProofs={authority.proofs}
                routeParams={{
                  caseId: caseID,
                  applicationId: authority.application_object.fake_id,
                }}
              />
            ),
            title: "Reliefs and Proofs",
            swapContent: {
              content: (
                <AuthorityDocProperties
                  callback={callback}
                  caseObject={caseObject}
                  caseID={caseID}
                />
              ),
              title: "Doc Properties",
            },
          });
        }}
        type={"edit"}
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const data_ = {
        bundles: authority.bundles,
        issues: authority.issues,
        issues_parent: authority.issues_parent,
        themes: authority.themes,
        themes_parent: authority.themes_parent,
        causes_of_action: authority.causes_of_action,
        proofs: authority.proofs,
        reliefs: authority.reliefs,
        causes_of_action_defensive: authority.causes_of_action_defensive,
        causes_of_action_parent: authority.causes_of_action_parent,
        type: authority.type,
        jurisdiction: authority.jurisdiction,
        citation: authority.citation,
        key_sections: authority.key_sections,
        status: authority.status,
      };
      await updateAuthorityById(
        authority.classes.fake_id,
        caseID,
        dispatch,
        data_
      );
      let resp = await getDocument(caseID, authority.id, dispatch);
      callback(resp);
      setIsLoading(false);
      dispatch({
        type: HIDE_RIGHT_SIDE_BAR,
      });
    }
  };

  const updateObject = (resp) => {
    let obj = { ...authority, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };

  const onBundleChange = (select) => {
    setSelectedBundlesList(select);
    if (select) {
      updateObject({
        bundles: select.map((bundle) => ({
          id: bundle.value,
          label: bundle.label,
        })),
      });
    } else {
      updateObject({
        bundles: [],
      });
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group d-flex mb-4 pr-3">
          <SelectAnotherTry
            label="Assign to bundle"
            options={bundlesOptionsList}
            isClearable={false}
            isMulti
            placeholder={"Select bundle"}
            value={selectedBundlesList}
            defaultValue={
              selectedBundlesList && selectedBundlesList.length
                ? bundlesOptionsList.filter((v) =>
                    selectedBundlesList.find((b) => b.id === v.value)
                  )
                : []
            }
            reactSelectStyles={{
              control: (provided) => ({
                ...provided,
                height: "unset !important",
                minHeight: "42px !important",
                borderRadius: "21px !important",
              }),
            }}
            onChange={onBundleChange}
          />
        </div>
        <Separator />
        <Fragment>
          {authority.application_object ? (
            <div className="form-group">
              <Label name={"Reliefs and Proofs"} button={<ReliefAction />} />
              <div className="d-flex pl-2 pr-2 flex-wrap">
                {authority.application_object &&
                authority.application_object.reliefs
                  ? authority.application_object.reliefs
                      .filter((v) => {
                        if (!authority.reliefs || !authority.reliefs.length) {
                          return false;
                        } else {
                          return authority.reliefs.find((vv) => vv.id === v.id);
                        }
                      })
                      .map((obj) => {
                        return (
                          <div className="container-fluid">
                            <Bold14Font textColor={theme.colors.darkOpacity}>
                              {obj.name}
                            </Bold14Font>
                            <div className="container-fluid">
                              <ul>
                                {authority.application_object &&
                                authority.application_object.proofs
                                  ? authority.application_object.proofs
                                      .filter((g) => {
                                        if (!authority.proofs) {
                                          return false;
                                        } else {
                                          return authority.proofs.find(
                                            (c) => c.id === g.id
                                          );
                                        }
                                      })
                                      .map((obj) => {
                                        return (
                                          <li style={{ listStyle: "disc" }}>
                                            <Medium14Font
                                              textColor={
                                                theme.colors.darkOpacity
                                              }
                                            >
                                              {obj.name}
                                            </Medium14Font>
                                          </li>
                                        );
                                      })
                                  : null}
                              </ul>
                            </div>
                          </div>
                        );
                      })
                  : null}
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="form-group">
                <Label
                  name={"Elements"}
                  button={editButton("causes_of_action")}
                />
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowCoa object={authority} case_object={caseObject} />
                </div>
              </div>
              <Separator />
              <div className="form-group">
                <Label name={"Themes"} button={editButton("themes")} />
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowThemes object={authority} case_object={caseObject} />
                </div>
              </div>
              <Separator />
              <div className="form-group">
                <Label name={"Issues"} button={editButton("issues")} />
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowIssues object={authority} case_object={caseObject} />
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
        <Separator />
        <div className="form-group">
          <Label
            name={"Further Details"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: authority,
                    content: (
                      <AuthorityFurtherDetailsForm
                        key={authority.id}
                        authorityObject={authority}
                        setAuthorityObject={updateObject}
                        evidenceId={authority.id}
                        caseObject={caseObject}
                        caseId={caseID}
                      />
                    ),
                    swapContent: {
                      content: (
                        <AuthorityDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />
                      ),
                      title: "Doc Properties",
                    },
                    title: "Further Details",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {authority.type ? (
            <Fragment>
              <div className="container-fluid mb-2">
                <Bold14Font>Authority Type: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {authority.type}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {authority.jurisdiction ? (
            <Fragment>
              <div className="container-fluid mb-2">
                <Bold14Font>Jurisdiction: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {authority.jurisdiction}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {authority.citation ? (
            <Fragment>
              <div className="container-fluid mb-2">
                <Bold14Font>Citation: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {authority.citation}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {authority.key_sections ? (
            <Fragment>
              <div className="container-fluid mb-2">
                <Bold14Font>Sections: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {authority.key_sections}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {authority.status ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Status:</Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {authority.status}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      <div>
        <div className="form-group">
          <div className="container-fluid">
            <ButtonNew
              wide
              secondary
              loading={isLoading}
              disabled={isLoading}
              clickHandler={onClickEditOnTrialHubHandler}
            >
              Edit in {authority.application_object ? 'Application Hub' : 'Trial Hub'}
            </ButtonNew>
          </div>
        </div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id={``} ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AuthorityDocProperties;
