import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const InputStyled = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border-radius: 50vh;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: ${rem("10px 20px")};
  background: ${({ theme }) => theme.colors.white};
  font-family: "PT Root UI", sans-serif;
  font-size: 14px;
  font-weight: 500;

  :hover,
  :focus,
  :active {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }

  @media (max-width: 575.98px) {
    height: 35px;
    border-radius: 50vh;
    font-size: 2.5rem;
    padding: 0.625rem 2rem;
  }

  ::placeholder {
    // TODO: Connect color to theme
    color: rgba(15, 18, 47, 0.6);
    font-family: "PT Root UI", sans-serif;
    font-size: ${rem(`14px`)};
    font-weight: 500;

    @media (max-width: 1199.98px) {
      font-size: 1.3rem;
    }

    @media (max-width: 767.98px) {
      font-size: 1.7rem;
    }
  }
`;

export default InputStyled;
