import styled from "styled-components/macro";

const SignInAdminStyled = styled.form`
  width: 470px;


  @media (max-width: 600px) {
    width: 350px;
  }
 @media (max-width: 360px) {
    width: 100%;
  }
`;

export default SignInAdminStyled;
