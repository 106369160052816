import styled from "styled-components/macro";

const HeaderStyled = styled.div`
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 13px;
  padding-top: 12px;
`;

export default HeaderStyled;
