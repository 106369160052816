import React from "react";
import styled from "styled-components";

import underlineIcon from "@/assets/img/notebook/toolbar/underline.svg";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  ${(props) => props.active && ` background: rgba(0, 0, 0, 0.05);`}
`;

const Icon = styled.img`
  margin-top: 1px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const Underline = ({ editor }) => {
  return (
    <Container
      active={editor.isActive("underline")}
      onClick={() => editor.chain().focus().toggleUnderline().run()}
    >
      <Icon
        src={underlineIcon}
        alt=""
        className={editor.isActive("underline") ? "is-active" : ""}
      />
    </Container>
  );
};

export default Underline;
