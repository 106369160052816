import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid #dcdce6;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 8px 16px;
  justify-content: space-between;
`;
