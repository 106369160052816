import React, { useContext } from "react";
import PropTypes from "prop-types";
import CasesMenuStyled from "../CasesMenu/styled/CasesMenuStyled";
import {
  Bold18Font,
  Bold30Font,
  Medium30Font,
} from "../../FontsNewComponent/Fonts";
import Badge from "../../Badge";
import styled, { ThemeContext } from "styled-components/macro";
import rem from "../../../utils/rem";

const BadgeMenuStyled = styled.div`
  width: ${rem(`52px`)};
  border-radius: ${rem(`21px`)};
  border: 1px solid ${({ theme }) => theme.colors.gray};
`;

const Menu = (props) => {
  const theme = useContext(ThemeContext);
  return (
    <CasesMenuStyled className={props.className}>
      <div className="row justify-content-between align-items-center">
        <div className="col d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap justify-content-end justify-content-sm-center">
            {props.casesMenu.map((el, i) => (
              <div
                onClick={() => {
                  props.getType(el.activeType);
                }}
                className={i > 0 ? "d-flex ml-4 mb-1" : "d-flex mb-1"}
                key={i}
              >
                <div
                  className={`menu-item mr-2 ${
                    el.activeType === props.active ? "menu-item__active" : ""
                  }`}
                >
                  {el.activeType === props.active ? (
                    <props.activeFont
                      style={{
                        color: theme.colors.blue,
                      }}
                    >
                      {el.text}
                    </props.activeFont>
                  ) : (
                    <props.font
                      style={{
                        color: theme.colors.darkOpacity,
                      }}
                    >
                      {el.text}
                    </props.font>
                  )}
                </div>
                {props.noBadges ? null : (
                  <Badge as={BadgeMenuStyled}>
                    <Bold18Font textColor="#848695">{el.amount}</Bold18Font>
                  </Badge>
                )}
              </div>
            ))}
          </div>
          <div className="">{props.customButton ?? null}</div>
        </div>
      </div>
    </CasesMenuStyled>
  );
};

Menu.propTypes = {
  /**
   * Current active item
   */
  active: PropTypes.string.isRequired,
  /**
   * Array of objects to render. Must have text field and active type field.
   */
  casesMenu: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  /**
   * Type
   */
  getType: PropTypes.func.isRequired,
  /**
   * Hide badges after menu item
   */
  noBadges: PropTypes.bool,
  /**
   * Hide "Add New Case" button
   */
  noButton: PropTypes.bool,

  activeFont: PropTypes.object,
  font: PropTypes.object,
};

Menu.defaultProps = {
  noBadges: false,
  noButton: false,
  activeFont: Bold30Font,
  font: Medium30Font,
};

export default Menu;
