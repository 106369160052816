import React, {useRef, useState, Fragment} from "react";
import {Link, useHistory} from "react-router-dom";
import {Bold14Font, Bold30Font, Medium14Font} from "../Fonts/Fonts";
import Input from "../Input";
import Button from "../buttons/Button/Button";
import {withTheme} from "styled-components/macro";

import {signIn, signInAdmin} from "../../containers/Auth/auth";
import {useDispatch} from "react-redux";
import {SIGN_IN,} from "../../redux/types";
import {route} from "../../routes";
import modalErrorHandler from "../Modal/modalErrorHandler";
import logoAdmin from "../../assets/img/svg/logo_admin.svg"
import SignInAdminStyled from "./SignInAdminStyled";
import DivStyled from "../AdminSignInPage/DivStyled";

const AdminSighIn = ({theme}) => {
  let form = useRef(null);
  const [credentials, setCredentials] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onInputChangeHandler = (event) => {
    event.persist();
    setCredentials((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!isLoading) {
      try {
        setIsLoading(true);
        const response = await signInAdmin(credentials);
        setIsLoading(false);
        dispatch({type: SIGN_IN, payload: response.data});
        history.push(route.admin.baseApp.app);
      } catch (e) {
        modalErrorHandler(e, dispatch);
        setIsLoading(false);
      }
    }
  };

  return (
    <Fragment>
    <DivStyled className="d-flex flex-column h-100 justify-content-between" style={{ overflow: "hidden" }}>
      <div className="row m-4">
        <img src={logoAdmin}/> <Medium14Font style={{lineHeight: '28px'}}>/ Admin</Medium14Font>
      </div>
      <div className="d-flex flex-column align-items-center pb-5 mb-5">
        <div className="row pl-5 mr-5">
          <div className="col d-flex flex-column align-items-center">
            <Bold30Font as="p">Sign In</Bold30Font>
            <Medium14Font
              as="p"
              textColor={theme.colors.darkOpacity}
              className="text-center"
            >

            </Medium14Font>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex flex-column align-items-center">
            <SignInAdminStyled
              onSubmit={onSubmitHandler}
              ref={(ref) => {
                form = ref;
              }}
            >
              <div className="form-group">
                <Input
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  label="Email"
                  onChangeHandler={onInputChangeHandler}
                  required
                />
              </div>
              <div className="form-group">
                <Input
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  label="Password"
                  onChangeHandler={onInputChangeHandler}
                  minLength={8}
                  required
                />
              </div>
              <div className="form-group">
                <div className="col d-flex justify-content-center">
                  <Link to={route.auth.restorePassword}>
                    <Bold14Font textColor={"rgb(55, 74, 194)"}>
                      Forgot Password?
                    </Bold14Font>
                  </Link>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <Button
                      primary
                      wide
                      type="submit"
                      clickHandler={() => null}
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Sign In
                    </Button>
                  </div>
                </div>
              </div>
            </SignInAdminStyled>
          </div>
        </div>
      </div>
      <div className="row">&nbsp;</div>
    </DivStyled>
    </Fragment>
  );
};

export default withTheme(AdminSighIn);
