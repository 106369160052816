import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import { components } from "react-select";

import CasesDropdownFilterStyled from "./styled/CasesDropdownFilterStyled";

import arrowDownBlueIcon from "../../../assets/img3/svg/arrow-down-blue-icon.svg";

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownBlueIcon} alt="" className="pr-2" />
    </components.DropdownIndicator>
  );
};

const CasesDropdownFilter = ({ ...props }) => {
  return (
    <CasesDropdownFilterStyled
      {...props}
      isSearchable={false}
      components={{ DropdownIndicator }}
      // defaultMenuIsOpen
    />
  );
};

CasesDropdownFilter.propTypes = {
  /**
   * Rounded the corners of the border to the left
   * */
  positionStart: PropTypes.bool,

  /**
   * Disable round corners
   * */
  positionMiddle: PropTypes.bool,

  /**
   * Rounded the corners of the border to the right
   * */
  positionEnd: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

export default withTheme(CasesDropdownFilter);
