import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { useDispatch } from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {Bold14Font, Bold18Font} from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox/Checkbox";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import {
  createNewApplicationPaper,
  createNewAuthority,
  updateApplicationPaperById,
  updateAuthorityById,
} from "../../../containers/Auth/auth";
import NoRecords from "../../NoRecords";

// const groupReliefsWithProofs = () => {};

const AssociateProofs = ({
  applicationObject,
  setApplicationObject,
  updateApplicationAuthoritiesList,
  applicationPaperObject,
  setApplicationPaperObject,
  savedReliefs,
  savedProofs,
  routeParams,
  returnObject,
  isAuthority,
  theme,
}) => {
  // page states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitObject, setSubmitObject] = useState({});
  const { caseId, applicationId } = routeParams;
  const dispatch = useDispatch();

  // reliefs states
  const [selectedReliefsList, setSelectedReliefsList] = useState([]);
  const [selectedProofsList, setSelectedProofsList] = useState([]);
  // const [
  //   groupedReliefsWithProofsList,
  //   setGroupedReliefsWithProofsList,
  // ] = useState([]);

  // set data

  useEffect(() => {
    setSelectedReliefsList([...savedReliefs]);
    setSelectedProofsList([...savedProofs]);
  }, []);

  //submit data

  // button methods

  const onClickSaveButtonHandler = async () => {
    setIsSubmitting(true);
    const submitObject = {
      reliefs: selectedReliefsList,
      proofs: selectedProofsList,
    };
    if (!returnObject) {
      let resp;
      if (!isAuthority) {
        if (!applicationPaperObject) {
          resp = await createNewApplicationPaper(
            applicationId,
            caseId,
            dispatch,
            submitObject
          );
        } else {
          resp = await updateApplicationPaperById(
            applicationPaperObject.id,
            applicationId,
            caseId,
            dispatch,
            submitObject
          );
        }
      } else {
        submitObject.id_application = routeParams.applicationId;
        if (!applicationPaperObject) {
          resp = await createNewAuthority(caseId, dispatch, submitObject);
          if (updateApplicationAuthoritiesList) {
            updateApplicationAuthoritiesList(resp);
          }
        } else {
          resp = await updateAuthorityById(
            applicationPaperObject.fake_id ?? applicationPaperObject.id,
            caseId,
            dispatch,
            submitObject
          );
          if (setApplicationObject) {
            setApplicationObject((prevState) => {
              resp.fake_id = resp.id;
              const applicationObjectCopy = { ...prevState };
              const index = applicationObjectCopy.authorities.findIndex(
                (el) => el.id === resp.id
              );
              applicationObjectCopy.authorities.splice(index, 1, resp);
              return applicationObjectCopy;
            });
          }
          if (updateApplicationAuthoritiesList) {
            updateApplicationAuthoritiesList(resp);
          }
        }
      }

      if (resp) {
        setApplicationPaperObject(resp);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    } else {
      setApplicationPaperObject(submitObject);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
    setIsSubmitting(false);
  };

  // input methods

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <div className="container-fluid">
            {applicationObject.reliefs.length ? applicationObject.reliefs.map((relief, index) => (
              <div key={index} style={{ marginBottom: "30px" }}>
                <div style={{ marginBottom: "8px" }}>
                  <Checkbox
                    checked={selectedReliefsList.find(
                      (el) => el.id === relief.id
                    )}
                    label={<Bold14Font>{relief.name}</Bold14Font>}
                    onChange={() =>
                      onChangeCheckBoxHandler(
                        relief,
                        selectedReliefsList,
                        setSelectedReliefsList
                      )
                    }
                  />
                </div>
                {relief.proofs.map((proof, idx) => (
                  <Checkbox
                    key={idx}
                    checked={selectedProofsList.find(
                      (element) => element.id === proof.id
                    )}
                    label={proof.name}
                    onChange={() =>
                      onChangeCheckBoxHandler(
                        proof,
                        selectedProofsList,
                        setSelectedProofsList
                      )
                    }
                    style={{ marginLeft: "12px" }}
                  />
                ))}
              </div>
            )) : <NoRecords>
              <Bold18Font textColor={theme.colors.dark}>
                Add Reliefs and Proofs to associate them.
              </Bold18Font>
            </NoRecords>}
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                onClick={onClickSaveButtonHandler}
                loading={isSubmitting}
                disabled={isSubmitting}
                wide
                primary
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AssociateProofs.propTypes = {};

export default withTheme(AssociateProofs);
