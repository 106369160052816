import { escapeForRegEx } from "@tiptap/core";

export function findSuggestionMatch(config) {
  var _a;
  const { char, allowSpaces, allowedPrefixes, startOfLine, $position } = config;
  const escapedChar = escapeForRegEx(char);
  const suffix = new RegExp(`\\s${escapedChar}$`);
  const prefix = startOfLine ? "^" : "";
  const regexp = allowSpaces
    ? new RegExp(`${prefix}${escapedChar}.*?(?=\\s${escapedChar}|$)`, "gm")
    : new RegExp(`${prefix}(?:^)?${escapedChar}[^\\s${escapedChar}]*`, "gm");
  const text =
    ((_a = $position.nodeBefore) === null || _a === void 0
      ? void 0
      : _a.isText) && $position.nodeBefore.text;
  if (!text) {
    return null;
  }
  if (text === "" || text === " ") return null;
  const textFrom = $position.pos - text.length;
  // const match = Array.from(text.matchAll(regexp)).pop();
  const match = {
    0: "",
    groups: undefined,
    index: 0,
    input: text,
  };
  if (!match || match.input === undefined || match.index === undefined) {
    return null;
  }
  // JavaScript doesn't have lookbehinds. This hacks a check that first character
  // is a space or the start of the line
  const matchPrefix = match.input.slice(
    Math.max(0, match.index - 1),
    match.index
  );
  const matchPrefixIsAllowed = new RegExp(
    `^[${
      allowedPrefixes === null || allowedPrefixes === void 0
        ? void 0
        : allowedPrefixes.join("")
    }\0]?$`
  ).test(matchPrefix);
  // if (allowedPrefixes !== null && !matchPrefixIsAllowed) {
  //   return null;
  // }
  // The absolute position of the match in the document
  const from = textFrom + match.index;
  let to = from + match[0].length;
  // Edge case handling; if spaces are allowed and we're directly in between
  // two triggers
  if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
    match[0] += " ";
    to += 1;
  }
  // here we always trigger the match because autocomplete should listen for
  // every symbol change
  if (true) {
    return {
      range: {
        from: $position.pos - text.length,
        to: $position.pos,
      },
      text,
      query: text,
    };
  }
  return null;
}
