import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import Button from "../../buttons/Button/Button";
import { useDispatch } from "react-redux";
import {
  updateAuthorityById,
  updateEvidence,
} from "../../../containers/Auth/auth";
import Input from "../../Input";
import InputNew from "../../InputNew/InputNew";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { randomNumberRange } from "../../../utils";
import { useRouteMatch, useParams } from "react-router-dom";
import { route } from "../../../routes";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

const bundles = [{ label: "Some bundle", value: "Some bundle" }];
const validTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const DOMESTIC = "Domestic";
const EUROPEAN_UNION = "European Union";
const INTERNATIONAL = "International";

const jurisdictions = [
  { value: DOMESTIC, label: DOMESTIC },
  { value: INTERNATIONAL, label: INTERNATIONAL },
  { value: EUROPEAN_UNION, label: EUROPEAN_UNION },
];

const EditCitationForm = ({ caseId, callback, evidence }) => {
  const [bundle, setBundle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [jurisdiction, setJurisdiction] = useState(null);
  const [citation, setCitation] = useState("");
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  const dispatch = useDispatch();
  let form = useRef(null);

  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setJurisdiction(evidence.jurisdiction);
      setCitation(evidence.citation ?? "");
    }
  }, [evidence]);

  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ citation: citation } };
    });
  }, [citation]);

  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ jurisdiction: jurisdiction } };
    });
  }, [jurisdiction]);

  const setJurisdiction_ = (selectedOption) => {
    setJurisdiction(selectedOption.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      const resp = await updateAuthorityById(evidence.id, caseId, dispatch, {
        citation,
        jurisdiction: jurisdiction,
      });
      callback(resp);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Label name={"Jurisdiction"} />
          <SelectAnotherTry
            options={jurisdictions}
            value={jurisdictions.find((v) => v.value === jurisdiction)}
            onChange={setJurisdiction_}
            placeholder={"Select Jurisdiction"}
          />
        </div>
        <div className="form-group">
          <InputNew
            maxLength={125}
            label={"Citation"}
            onChangeHandler={(e) => {
              if (e.target.value.length <= 125) setCitation(e.target.value);
            }}
            value={citation}
            placeholder={"Enter Citation"}
            name={"citation"}
            form={`form-citation-${randomNumber}`}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            id={`form-citation-${randomNumber}`}
            ref={form}
          >
            <ButtonNew
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default EditCitationForm;
