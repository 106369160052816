import React from "react";
import BaseAppLayoutRightSideBarStyled from "./styled/BaseAppLayoutRightSideBarStyled";
import { useSelector } from "react-redux";
import { Bold18Font } from "../../../FontsNewComponent/Fonts";
import RectangleButton from "../../../buttons/RectangleButton";
import BaseAppLayoutRightSideBarHeaderStyled from "./styled/BaseAppLayoutRightSideBarHeaderStyled";

const BaseAppLayoutRightSideBar = ({ withCloseButton, handleCloseClick }) => {
  const rightSideBarTitle = useSelector((state) => state.rightSideBar.titleTwo);
  //must be wrap in Fragment, and have one of sections - height 100%
  const rightSideBarContent = useSelector(
    (state) => state.rightSideBar.contentTwo
  );
  const rightBarWidth = useSelector(
    (state) => state.rightSideBar.rightBarWidth
  );

  if (!rightSideBarContent) return null;

  return (
    <BaseAppLayoutRightSideBarStyled width={rightBarWidth}>
      {rightSideBarTitle ? (
        <BaseAppLayoutRightSideBarHeaderStyled
          withCloseButton={withCloseButton}
        >
          <Bold18Font
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              "white-space": "nowrap",
            }}
          >
            {rightSideBarTitle}
          </Bold18Font>
          {withCloseButton ? (
            <RectangleButton rsbCloseBtn onClick={handleCloseClick} />
          ) : null}
        </BaseAppLayoutRightSideBarHeaderStyled>
      ) : null}
      {rightSideBarContent}
    </BaseAppLayoutRightSideBarStyled>
  );
};

export default BaseAppLayoutRightSideBar;
