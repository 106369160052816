import { createGlobalStyle } from "styled-components/macro";

import PTRootUIBoldEOT from "../../assets/fonts/PT Root UI_Bold.eot";
import PTRootUIBoldTTF from "../../assets/fonts/PT Root UI_Bold.ttf";
import PTRootUIBoldWOFF from "../../assets/fonts/PT Root UI_Bold.woff";
import PTRootUIBoldWOFF2 from "../../assets/fonts/PT Root UI_Bold.woff2";
import PTRootUIMediumEOT from "../../assets/fonts/PT Root UI_Medium.eot";
import PTRootUIMediumTTF from "../../assets/fonts/PT Root UI_Medium.ttf";
import PTRootUIMediumWOFF from "../../assets/fonts/PT Root UI_Medium.woff";
import PTRootUIMediumWOFF2 from "../../assets/fonts/PT Root UI_Medium.woff2";
import iconFontTTF from "../../assets/fonts/trialProofer-iconPack.ttf";
import iconFontWOFF from "../../assets/fonts/trialProofer-iconPack.woff";
import iconFontSVG from "../../assets/fonts/trialProofer-iconPack.svg";

const GlobalStyle = createGlobalStyle`
  // Fonts
  @font-face {
    font-family: "PT Root UI";
    src: url("${PTRootUIBoldEOT}");
    src:
      url("${PTRootUIBoldEOT}?#iefix") format("embedded-opentype"),
      url("${PTRootUIBoldWOFF2}") format("woff2"),
      url("${PTRootUIBoldWOFF}") format("woff"),
      url("${PTRootUIBoldTTF}") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "PT Root UI";
    src: url("${PTRootUIMediumEOT}");
    src:
      url("${PTRootUIMediumEOT}?#iefix") format("embedded-opentype"),
      url("${PTRootUIMediumWOFF2}") format("woff2"),
      url("${PTRootUIMediumWOFF}") format("woff"),
      url("${PTRootUIMediumTTF}") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  // Icon Pack
  @font-face {
  font-family: 'trialProofer-iconPack';
  src:
    url('${iconFontTTF}?rzmeil') format('truetype'),
    url('${iconFontWOFF}?rzmeil') format('woff'),
    url('${iconFontSVG}?rzmeil#trialProofer-iconPack') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'trialProofer-iconPack' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-social-facebook-icon:before {
    content: "\\e907";
  }
  .icon-social-instagram-icon:before {
    content: "\\e908";
  }
  .icon-social-tweeter-icon:before {
    content: "\\e909";
  }


  // Base css
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  // Fix cutting avatars in owl-carousel
  .owl-carousel .owl-stage-outer {
    margin-top: -35px;
    padding-top: 35px;
  }

  html {
    font-size: 6px;
  }

  // Small devices (landscape phones, 576px and up) sm
  @media (min-width: 576px) {
    html {
      font-size: 8px;
    }
   }

  // Medium devices (tablets, 768px and up) md
  @media (min-width: 768px) {
    html {
      font-size: 12px;
    }
   }

  // Large devices (desktops, 992px and up) lg
  @media (min-width: 992px) {
    html {
      font-size: 14px;
    }
   }

  // Extra large devices (large desktops, 1200px and up) xl
  @media (min-width: 1200px) {
    html {
      font-size: 16px;
    }
   }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  // Make pages 100% height
  html, body, #app {
    height: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .btn {
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
      -webkit-box-shadow: none !important;
    }
  }
  `;

export default GlobalStyle;
