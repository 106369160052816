import React, { useState } from "react";

import LeftSideBarUserLayoutStyled from "./styled/LeftSideBarUserLayoutStyled";
import LeftSideBarAvatarButton from "../../buttons/LeftSideBarAvatarButton";

import LeftSideBarUserMenu from "./LeftSideBarUserMenu";

const LeftSideBarUserLayout = ({ theme }) => {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

  return (
    <LeftSideBarUserLayoutStyled>
      <LeftSideBarAvatarButton
        onClick={() => setIsUserMenuVisible((prevState) => !prevState)}
      />
      {isUserMenuVisible ? (
        <LeftSideBarUserMenu closeHandler={() => setIsUserMenuVisible(false)} />
      ) : null}
    </LeftSideBarUserLayoutStyled>
  );
};

export default LeftSideBarUserLayout;
