import React from "react";
import styled from "styled-components";

import highlightIcon from "@/assets/img/notebook/toolbar/highlight.svg";
import highlightIconActive from "@/assets/img/notebook/toolbar/highlight-active.svg";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  margin-top: 1px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const Highlight = ({ editor }) => {
  const isActive = editor.isActive("highlight");
  return (
    <Container>
      <Icon
        src={isActive ? highlightIconActive : highlightIcon}
        alt=""
        onClick={() =>
          editor.chain().focus().toggleHighlight({ color: "#FFF27B" }).run()
        }
        // className={editor.isActive("highlight") ? "is-active" : ""}
      />
    </Container>
  );
};

export default Highlight;
