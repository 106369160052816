import React from "react";
import ArrowStyled from "./styled/ArrowStyled";

export default function Arrow(props) {
  return <ArrowStyled {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g>
        <g transform={props.back ? 'rotate(0 12 12)' : 'rotate(-180 12 12)'}>
          <g>
            <g opacity=".1">
              <path fill="#cbcfee" d="M0 8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8z"/>
            </g>
            <g transform="rotate(-270 12 12)">
              <path fill="none" stroke="#374ac2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="20"
                    strokeWidth="1.5" d="M10 11v0l2 2v0l2-2v0"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </ArrowStyled>
}
