import React, { useState } from "react";
import PropTypes from "prop-types";
import checkboxChecked from "../../assets/img/svg/checkboxChecked.svg";
import checkboxUnchecked from "../../assets/img/svg/checkboxUnchecked.svg";
import { Medium14Font } from "../FontsNewComponent/Fonts";
import CheckboxStyled from "./styled/CheckboxStyled";

export default function CheckboxNew(props) {
  const { checked, label, labelColor } = props;
  const src = checked ? checkboxChecked : checkboxUnchecked;
  const handleChange = () => {
    if (props.onChange) {
      props.onChange(checked);
    }
  };
  return (
    <CheckboxStyled {...props} onClick={handleChange}>
      <img
        style={!label ? { paddingBottom: "5px" } : null}
        alt={checked ? "checked" : "unchecked"}
        src={src}
      />{" "}
      {label ? (
        <Medium14Font textColor={labelColor ?? null}>{label}</Medium14Font>
      ) : null}
    </CheckboxStyled>
  );
}
CheckboxNew.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};
