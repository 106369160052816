import styled from "styled-components/macro";
import rem from "../../../../utils/rem";
import { minButtonHeight } from "./ButtonStyled";

const DangerButtonStyled = styled.button`
  // Get min height from base button component to calc border radius
  padding: ${rem(`9px 20px`)};
  background: ${({ theme }) => theme.colors.burgundy};

  :hover {
    background-color: ${({ theme }) => theme.colors.burgundyHover};
  }
`;

export default DangerButtonStyled;
