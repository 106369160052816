import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateEvidence } from "../../../containers/Auth/auth";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { randomNumberRange } from "../../../utils";
import TextAreaSideBar from "../DocumentForms/TextAreaSideBar";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import {TagElementPurple} from "../../TagElementStyled/TagElementStyled";
import {HIDE_RIGHT_SIDE_BAR} from "../../../redux/types";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Label from "../AddCardForm/Label";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import ListAddButton from "../../buttons/ListAddButton";

const EvidenceEditNote = ({ caseId, callback, evidence }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [notes, setNotes] = useState("");
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  const dispatch = useDispatch();
  let form = useRef(null);

  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setNotes(evidence.notes ?? []);
    }
  }, [evidence]);
  let editableStringsRef = useRef([]);
  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ notes } };
    });
  }, [notes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      let resp;
      resp = await updateEvidence(evidence.id, caseId, dispatch, {
        notes,
      });
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      callback(resp);
      setIsLoading(false);
    }
  };

  const deleteNote = (index) => {
    setNotes(notes.filter((v, i) => {
      return i !== index;
    }));
  };

  const addNote = async (e) => {
    let notes_;
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      if (!notes || !notes.length) {
        notes_ = [{content: ""}];
      } else {
        notes_ = [...notes, {content: ""}];
      }
      setNotes(notes_);
    }
  };

  const editNote = (index, content) => {
    let notes_ = {...notes};
    notes_[index].content = content;
    setObject(notes_);
  };
  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <div className="mb-2">
            <div className="col">
              <Bold14Font>Witness</Bold14Font>
            </div>
          </div>
          <div className="col">
            {object.witnesses
              ? object.witnesses.map((v) => {
                  return <TagElementPurple key={v.id}>{v.name}</TagElementPurple>;
                })
              : null}
          </div>
        </div>
        <DividerHorizontal />
        <div className="form-group">
          {notes && notes.length ? (
            <Fragment>
              <AnimatedDiv className="form-group mb-0">
                <Label className={"row"} name={"notes"}/>
              </AnimatedDiv>
              {notes.map((sub_t, i) => (
                <AnimatedDiv key={i} className="form-group pl-3">
                  <EditableStringClassComponent
                    editName={editNote}
                    Note={true}
                    type={'textarea'}
                    editState={sub_t.content === ""}
                    deleteAction={deleteNote}
                    object={sub_t.content}
                    index={i}
                    ref={(ref) => assignRef(ref, i)}
                  />
                </AnimatedDiv>
              ))}
            </Fragment>
          ) : null}
          <div className="form-group">
            <div className="container-fluid">
              <ListAddButton
                className="d-flex"
                label="Add Note"
                clickHandler={addNote}
              />
            </div>
          </div>
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            id={`form-label-${randomNumber}`}
            ref={form}
          >
            <ButtonNew
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default EvidenceEditNote;
