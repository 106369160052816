import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const PlayButtonStyled = styled.button`
  width: ${rem(`52px`)};
  height: ${rem(`52px`)};
  border-radius: ${rem(`26px`)};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  :after {
    content: "Play Video";
    position: absolute;
    right: ${rem(`-104px`)};
    font-size: ${rem("18px")};
    font-style: normal;
    font-weight: bold;
    font-family: "PT Root UI", sans-serif;
    color: ${({ theme }) => theme.colors.whiteOpacity};
  }
`;

export default PlayButtonStyled;
