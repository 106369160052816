import styled, { css } from "styled-components/macro";

const disabledStyle = css`
  opacity: 0.4;
  cursor: default;
`;

const CheckboxStyled = styled.div`
  cursor: pointer;
  margin-right: 10px;
  ${({ disabled }) => (disabled ? disabledStyle : null)}
`;

export default CheckboxStyled;
