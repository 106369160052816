import React from 'react';
import {Document, Page, StyleSheet, Text, View, Font} from '@react-pdf/renderer';

// Create styles
const borderColor = 'gray';
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 25,
    left: 35,
    right: 0,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: 'grey',
  },
  field: {
    color: 'gray',
    fontSize: 12,
    fontFamily: 'Roboto',
    marginBottom: 2,
  },
  value: {
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 15,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: 'Roboto',
  },
  case: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: 'left',
  },
  flexRow: {
    flexDirection: 'row'
  },
  tableTitle: {
    fontSize: 14,
    fontFamily: 'Roboto',
    marginBottom: 10,
  },
  tableHeader: {
    fontSize: 12,
  },
  table_: {
    marginBottom: 15,
  },

  table: {
    display: "table",
    width: "auto",
    fontFamily: 'Roboto',
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: borderColor,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {margin: "auto", flexDirection: "row"},
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    fontFamily: 'Roboto',
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: borderColor
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    textAlign: 'left',
    marginLeft: 5,
    // flex: 1,
    // flexDirection: 'row',
    fontFamily: 'Roboto',
  }
});
Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});
let example = [
    {
      title: 'Title',
      type: 'themes',
      headers: [
        {
          name: 'id',
          label: 'ID',
          width: '10%',
        },
        {
          name: 'evidence',
          label: 'Evidence',
          width: '20%',
        },
        {
          name: 'tags',
          label: 'Tags',
          width: '30%',
        },
        {
          name: 'objectives',
          label: 'Objectives',
          width: '40%',
        },
      ],
      rows: [
        {
          id: '1',
          evidence: 'evidence',
          tags: ['hello', 'world', 'with', 'some', 'text'],
          objectives: [{name: 'objective', value: ['examline1', 'examline2', 'examline3']}],
        },
        {
          id: '1',
          evidence: 'evidence',
          tags: ['hello', 'world', 'with', 'some', 'text'],
          objectives: [{name: 'objective', value: ['examline1', 'examline2', 'examline3']}],
        },
        {
          id: '1',
          evidence: 'evidence',
          tags: ['hello', 'world', 'with', 'some', 'text'],
          objectives: [{name: 'objective', value: ['examline1', 'examline2', 'examline3']}],
        },
        {
          id: '1',
          evidence: 'evidence',
          tags: ['hello', 'world', 'with', 'some', 'text'],
          objectives: [{name: 'objective', value: ['examline1', 'examline2', 'examline3']}],
        },
      ]
    },
  ]
;
export const PdfAuthorities = ({caseName, data, document_type}) => {
  return <Document>
    <Page size="A4" style={styles.body} wrap>
      <View style={styles.flexRow}>
        <View>
          <Text style={styles.case}>{caseName}  <Text style={[styles.case, {color: 'gray'}]}>{document_type}</Text></Text>
        </View>
      </View>
      {data.filter((v) => {
        return v.rows.length;
      }).map((v) => {
          return <View style={styles.table_}>
            <View>
              <Text style={styles.tableTitle}>{v.title}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                {v.headers.map((v) => {
                  return <View style={[styles.tableCol, {width: v.width}]}><Text
                    style={[styles.tableCell, {textAlign: 'center', marginLeft: 0}]}>{v.label}</Text></View>
                })}
              </View>
              {v.rows.map((row) => {
                return <View style={styles.tableRow}>
                  {v.headers.map((header_) => {
                    let content;
                    if (!row[header_.name]) {
                      content = <Text style={styles.tableCell}/>
                    } else {
                      if (typeof row[header_.name] !== 'object') {
                        content = <Text style={styles.tableCell}>{row[header_.name]}</Text>
                      } else {
                        content = [];
                        let as_row = false;
                        row[header_.name].forEach((el, index) => {
                          if (typeof el === 'object') {
                            content.push(<View>
                              <View>
                                <Text style={styles.tableCell}>{el.name}</Text>
                              </View>
                              {el.value.map((lines, i) => {
                                return <View><Text
                                  style={[styles.tableCell, {marginLeft: 10}]}>{i + 1}.{lines}</Text></View>
                              })}
                            </View>)
                          } else {
                            as_row = true;
                            content.push(el);
                          }
                        });
                        if (as_row) {
                          content = <Text style={styles.tableCell}>{content.join(', ')}</Text>
                        }
                      }
                    }
                    return <View style={[styles.tableCol, {width: header_.width}]}>
                      {content}
                    </View>
                  })}
                </View>
              })}
            </View>
          </View>
        })}
      <Text
        style={styles.footer}
        render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
};
