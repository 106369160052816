import React, { useState, useEffect, useContext } from "react";
import { NotebookContext, EditorContext } from "@/context";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  ChaptersLabel,
  AddChapterLabel,
  ChaptersIcon,
  PlusIcon,
  CrossIcon,
  Column as FlexColumn,
} from "./styled";

import { Bold14Font } from "@/components/FontsNewComponent/Fonts";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
// import InputNew from "@/components/InputNew/InputNew";
import InputStyled from "@/components/InputNew/styled/InputStyled";

import { groupedChapters as gr } from "./data";
import Button from "@/components/buttons/Button/Button";
// import { groupedChapters } from "./data";
import reorder, { reorderQuoteMap } from "./reorder";

import Column from "./Column";

import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const formStructure = (obj) => {
  let data = {};
  for (const [key, value] of Object.entries(obj)) {
    data[key] = {
      label: capitalize(key),
      order: 0,
      freestyle: key === "freestyle",
      items: value,
    };
  }
  return data;
};

let groupedChapters = Object.entries(gr)
  .sort(([, a], [, b]) => a.order - b.order)
  .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

const Chapters = ({ setShowTags, showTags }) => {
  const { id: caseId, notebook_id: notebookId } = useParams();

  const { content } = useContext(EditorContext);
  const {
    fetchChapters,
    chaptersFetching,
    chapters,
    selectedChapter,
    setSelectedChapter,
    notebookNotFound,
    columns,
    setColumns,
  } = useContext(NotebookContext);

  useEffect(() => {
    let group;
    if (selectedChapter) {
      if (
        selectedChapter.type !== "issue" &&
        selectedChapter.type !== "theme" &&
        selectedChapter.type !== "witness"
      ) {
        group = selectedChapter.group || selectedChapter.name;
      } else {
        group = capitalize(selectedChapter.type);
      }

      if (!columns[group]) return;

      const itemIndex = columns[group].items.findIndex(
        (i) => i.id === selectedChapter.id
      );
      const newItems = [
        ...columns[group].items.slice(0, itemIndex),
        {
          ...columns[group].items[itemIndex],
          content,
        },
        ...columns[group].items.slice(itemIndex + 1),
      ];

      setColumns((columns) => ({
        ...columns,
        [group]: {
          ...columns[group],
          items: newItems,
        },
      }));
    }
  }, [content]);

  useEffect(() => {
    return () => {
      setSelectedChapter(null);
    };
  }, []);

  // const [columns, setColumns] = useState({});
  const [ordered, setOrdered] = useState([]);

  useEffect(() => {
    fetchChapters(caseId, notebookId, (ch) => {
      setSelectedChapter(
        ch.filter((c) => c.items.length > 0)[0]
          ? ch.filter((c) => c.items.length > 0)[0].items[0]
          : undefined
      );

      let data = {};

      ch.forEach((chapter) => {
        data[chapter.label] = chapter;
      });

      // setColumns(formStructure(groupBy(ch, "type")));
      // setOrdered(Object.keys(formStructure(groupBy(ch, "type"))));
      setColumns(data);
      // setOrdered(Object.keys(data));
      setOrdered(ch.map((i) => i.label));
    });
  }, []);

  if (chaptersFetching) return null;

  const addItem = async (type, label, fields) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .post(
        `/api/cases/${caseId}/note-book/${notebookId}/chapter`,
        {
          name: label,
          type: type.value,
          content: "",
          ...fields,
        },
        config
      )
      .catch((err) => {
        alert("error: " + err.message);
      });

    const newItem = res.data.data;

    if (newItem) {
      if (type.value !== "freestyle") {
        const existingItems =
          (columns[capitalize(type.value)] &&
            columns[capitalize(type.value)].items) ||
          [];
        setColumns((columns) => ({
          ...columns,
          [capitalize(type.value)]: {
            ...columns[capitalize(type.value)],
            items: [...existingItems, newItem],
          },
        }));

        if (ordered.findIndex((i) => i === capitalize(newItem.type)) === -1) {
          setOrdered((ordered) => [...ordered, capitalize(newItem.type)]);
        }
      } else {
        // find the biggest order in columns
        const maxOrder = 10;

        setColumns((columns) => ({
          ...columns,
          [label]: {
            label,
            order: maxOrder,
            freestyle: true,
            items: [newItem],
          },
        }));
        // new fristyle chapter

        if (ordered.findIndex((i) => i === newItem.name) === -1) {
          setOrdered((ordered) => [...ordered, newItem.name]);
        }
      }
      setSelectedChapter(newItem);
    }
  };

  if (notebookNotFound) {
    return null;
  }

  const editItem = async (id, label, group, isFreestyle) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .put(
        `/api/cases/${caseId}/note-book/${notebookId}/chapter/${id}`,
        {
          name: label,
        },
        config
      )
      .catch((err) => {
        alert("error: " + err.message);
      });

    const itemIndex = columns[group].items.findIndex((i) => i.id === id);
    const newItems = [
      ...columns[group].items.slice(0, itemIndex),
      {
        ...columns[group].items[itemIndex],
        name: label,
      },
      ...columns[group].items.slice(itemIndex + 1),
    ];

    if (!isFreestyle) {
      setColumns((columns) => ({
        ...columns,
        [group]: {
          ...columns[group],
          items: newItems,
        },
      }));
      //
    } else {
      // if freestyle
      setColumns((columns) => ({
        ...columns,
        [group]: {
          ...columns[group],
          label: label,
          items: [
            {
              ...columns[group].items[0],
              name: label,
            },
          ],
        },
      }));
    }
  };

  const removeItem = async (id, group, isFreestyle) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let er = false;

    const res = await axios
      .delete(
        `/api/cases/${caseId}/note-book/${notebookId}/chapter/${id}`,
        config
      )
      .catch((err) => {
        alert("error: " + err.message);
        er = true;
      });

    if (!er) {
      let newColumns = { ...columns };
      if (!isFreestyle) {
        const itemIndex = columns[group].items.findIndex((i) => i.id === id);
        const newItems = [...columns[group].items];
        newItems.splice(itemIndex, 1);

        newColumns = {
          ...columns,
          [group]: {
            ...columns[group],
            items: newItems,
          },
        };
        setColumns(newColumns);
      } else {
        delete newColumns[group];

        setOrdered((ordered) => ordered.filter((i) => i !== group));
        setColumns(newColumns);
        //
      }

      const nonNullColumns = Object.fromEntries(
        Object.entries(newColumns).filter(([key, value]) => {
          return value.items.length > 0;
        })
      );

      if (selectedChapter && selectedChapter.id === id) {
        if (newColumns[group] && newColumns[group].items.length > 0) {
          // 1. if there are other chapters in this group

          setSelectedChapter(newColumns[group].items[0]);
        } else if (nonNullColumns[Object.keys(nonNullColumns)[0]]) {
          // 2. if there are still other chapters
          setSelectedChapter(
            nonNullColumns[Object.keys(nonNullColumns)[0]].items[0]
          );
        } else {
          // 3. if it was the last chapter
          setSelectedChapter(undefined);
        }
      }
    }
  };

  const onDragEnd = async (result) => {
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }

      const column = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];
      withQuoteRemoved.splice(result.source.index, 1);
      const newColumns = {
        ...columns,
        [result.source.droppableId]: withQuoteRemoved,
      };
      setColumns(newColumns);
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    // reordering column
    if (result.type === "COLUMN") {
      const newOrdered = reorder(ordered, source.index, destination.index);

      let orderObj = {};
      for (var i = 0; i < newOrdered.length; ++i) {
        if (
          newOrdered[i] === "Issue" ||
          newOrdered[i] === "Theme" ||
          newOrdered[i] === "Witness"
        ) {
          orderObj[newOrdered[i].toLowerCase()] = i;
        } else {
          orderObj[columns[newOrdered[i]].items[0].id] = i;
        }
      }

      const res = axios.put(
        `/api/cases/${caseId}/note-book/${notebookId}`,
        {
          order: orderObj,
        },
        config
      );

      setOrdered(newOrdered);

      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const newOrderedItems = data.quoteMap[source.droppableId].items;

    let orderObj = {};
    for (var i = 0; i < newOrderedItems.length; ++i) {
      orderObj[newOrderedItems[i].id] = i;
    }

    axios.post(
      `/api/cases/${caseId}/note-book/${notebookId}/update-order`,
      {
        order: orderObj,
        type: source.droppableId.toLowerCase(),
      },
      config
    );

    // data.quoteMap[source.droppableId].items.map((item, index) => ({ [item.id]: index }))

    setColumns(data.quoteMap);
  };

  return (
    <Container show={ordered.length === 0 ? true : !showTags}>
      <Topbar addItem={addItem} />
      <div style={{ paddingLeft: 12 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="board"
            type="COLUMN"
            direction="vertical"
            // ignoreContainerClipping={Boolean(containerHeight)}
          >
            {(provided) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: 12,
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {ordered.map((key, index) => {
                  if (columns[key].items.length > 0) {
                    return (
                      <Column
                        key={key}
                        index={index}
                        title={key}
                        label={columns[key].label}
                        quotes={columns[key].items}
                        isFreestyle={columns[key].freestyle}
                        selectedChapter={selectedChapter}
                        setSelectedChapter={(chapter) => {
                          // setShowTags(true);
                          setSelectedChapter(chapter);
                        }}
                        editItem={editItem}
                        removeItem={removeItem}
                      />
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Container>
  );
};

const Topbar = ({ addItem }) => {
  const { id: caseId, notebook_id: notebookId } = useParams();
  const [adding, setAdding] = useState(false);
  const [newObjInput, setNewObjInput] = useState(false);
  const [newObjInputValue, setNewObjInputValue] = useState();

  const { caseObject } = useContext(NotebookContext);

  const [chapterName, setChapterName] = useState("");
  const groups = [
    { value: "witness", label: "Witness" },
    { value: "theme", label: "Theme" },
    { value: "issue", label: "Issue" },
    { value: "freestyle", label: "Blank" },
  ];
  // const [selectedGroup, setSelectedGroup] = useState(groups[0]);
  const [selectedGroup, setSelectedGroup] = useState(undefined);

  const [witnesses, setWitnesses] = useState([]);
  const [issues, setIssues] = useState([]);
  const [themes, setThemes] = useState([]);

  const fetchWitnesses = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .get(`/api/cases/${caseId}/witness`, config)
      .catch((err) => {
        //
      });
    if (res && res.data && res.data.data) {
      setWitnesses(res.data.data.map((i) => ({ label: i.name, value: i.id })));
    }
  };

  useEffect(() => {
    fetchWitnesses();
  }, []);

  useEffect(() => {
    if (caseObject && caseObject.issues) {
      setIssues(
        caseObject.issues.map((i) => ({
          value: i.id,
          label: i.name,
        }))
      );
    }
  }, [caseObject]);

  useEffect(() => {
    if (caseObject && caseObject.themes) {
      setThemes(
        caseObject.themes.map((i) => ({
          value: i.id,
          label: i.name,
        }))
      );
    }
  }, [caseObject]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row
        alignCenter
        justifyBetween
        style={{
          // paddingBottom: 12,
          borderBottom: "1px solid #CBCFEE",
          padding: "0 12px 12px 12px",
        }}
      >
        <Row alignCenter style={{ opacity: 0.6 }}>
          <ChaptersIcon />
          <ChaptersLabel>Chapters</ChaptersLabel>
        </Row>
        <Row
          alignCenter
          groupHover
          style={{ cursor: "pointer" }}
          onClick={() => setAdding(!adding)}
        >
          <AddChapterLabel>Add chapter</AddChapterLabel>
          {adding ? <CrossIcon /> : <PlusIcon />}
        </Row>
      </Row>
      {adding && (
        <FlexColumn
          style={{ background: "#E2E4F4", padding: "15px 16px" }}
          className="add-chapter-form"
        >
          <Bold14Font className="d-block mb-2">Chapter structure</Bold14Font>
          <SelectAnotherTry
            placeholder="Choose group"
            // defaultValue={selectedGroup}
            options={groups}
            onChange={(newValue) => {
              setSelectedGroup(newValue);
              setChapterName("");
              setNewObjInputValue(null);
            }}
          />
          {selectedGroup && selectedGroup.value === "freestyle" && (
            <>
              <Bold14Font>Name</Bold14Font>
              <InputStyled
                value={chapterName}
                type="text"
                placeholder="Enter name"
                onChange={(e) => setChapterName(e.target.value)}
                maxLength={125}
                style={{
                  background: "white",
                  borderRadius: "22px",
                  // marginLeft: "-15px",
                  // marginRight: "-15px",
                  marginBottom: "15px",
                }}
              />
            </>
          )}
          {selectedGroup && selectedGroup.value !== "freestyle" && (
            <>
              {newObjInputValue && newObjInputValue.value === "new" ? (
                <>
                  <Bold14Font className="d-block mb-2">
                    {selectedGroup.label}
                  </Bold14Font>
                  <InputStyled
                    value={chapterName}
                    type="text"
                    placeholder="Enter name"
                    onChange={(e) => setChapterName(e.target.value)}
                    maxLength={125}
                    style={{
                      background: "white",
                      borderRadius: "22px",
                      // marginLeft: "-15px",
                      // marginRight: "-15px",
                      marginBottom: "15px",
                    }}
                    autoFocus
                  />
                </>
              ) : (
                <>
                  <Bold14Font className="d-block mb-2">
                    {selectedGroup.label}
                  </Bold14Font>
                  <SelectAnotherTry
                    placeholder={`Choose ${selectedGroup.label.toLowerCase()}`}
                    // defaultValue={selectedGroup}
                    options={[
                      ...(selectedGroup.value === "witness"
                        ? witnesses
                        : selectedGroup.value === "issue"
                        ? issues
                        : selectedGroup.value === "theme"
                        ? themes
                        : []),
                      {
                        value: "new",
                        label: `Create new ${selectedGroup.label}`,
                      },
                    ]}
                    value={newObjInputValue}
                    onChange={(newValue) => {
                      setNewObjInputValue(newValue);
                      setChapterName("");
                    }}
                  />
                </>
              )}
            </>
          )}

          <Button
            type="submit"
            wide
            primary
            disabled={chapterName === "" && !newObjInputValue}
            clickHandler={async () => {
              let label;

              if (selectedGroup && selectedGroup.value === "freestyle") {
                label = chapterName;
              } else if (newObjInputValue && newObjInputValue.value === "new") {
                label = chapterName;
              } else {
                label = newObjInputValue.label;
              }

              let body = {};

              if (newObjInputValue && newObjInputValue.value !== "new") {
                if (selectedGroup.value === "issue") {
                  body = {
                    id_issue: newObjInputValue.value,
                  };
                }

                if (selectedGroup.value === "theme") {
                  body = {
                    id_theme: newObjInputValue.value,
                  };
                }

                if (selectedGroup.value === "witness") {
                  body = {
                    id_witness: newObjInputValue.value,
                  };
                }
              }

              if (newObjInputValue && newObjInputValue.value === "new") {
                const config = {
                  headers: {
                    Authorization: `Bearer ${await loadAccessToken()}`,
                  },
                };

                const res = await axios.post(
                  `/api/cases/${caseId}/notes/objects?type=${capitalize(
                    selectedGroup.value
                  )}&text=${chapterName}&id_case=${caseId}`,
                  {},
                  config
                );

                if (selectedGroup.value === "issue") {
                  // create issue
                  body = {
                    id_issue: res.data.data.id,
                  };
                }

                if (selectedGroup.value === "theme") {
                  // create theme
                  body = {
                    id_theme: res.data.data.id,
                  };
                }

                if (selectedGroup.value === "witness") {
                  // create witness
                  body = {
                    id_witness: res.data.data.id,
                  };
                }
              }

              addItem(selectedGroup, label, body);
              setAdding(false);
              setSelectedGroup(undefined);
              setNewObjInputValue(undefined);
              setChapterName("");
            }}
          >
            Save
          </Button>
        </FlexColumn>
      )}
    </div>
  );
};

export default Chapters;
