import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  BaseBoldFont,
  Bold14Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import NoRecords from "../../NoRecords";
import { theme } from "../../../styled-components/Theme/Theme";
import styled from "styled-components/macro";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import {
  deleteSubEvidenceApi,
  updateSubEvidence,
} from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SubEvidenceDocProperties from "../DocumentForms/SubEvidenceDocProperties";
import rem from "../../../utils/rem";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableLabelClassComponent from "../CasesForms/EditableLabelClassComponent";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;
const KeySectionLabelStyle = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("14px")};
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ width }) => `width: ${width ?? "70"}%;`};
  ${({ pinter, pointer }) => (pinter || pointer ? `cursor: pointer;` : null)};
`;
export default function SubEvidence({
  sub_evidence,
  callback,
  caseObject,
  caseId,
  documentId,
  id,
  annotManager,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  let editableStringsRef = useRef([]);
  const [editingItems, setEditingItems] = useState([]);
  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };
  const reg_test = /\/documents\/([\d]+)$/;
  const document_page = reg_test.test(history.location.pathname);
  if (window.doc_object && window.doc_object.sub_evidence && document_page) {
    sub_evidence = window.doc_object.sub_evidence;
  }
  if (!annotManager || typeof annotManager !== "function") {
    annotManager = window.annotManager;
  }
  const evidenceWithCase = () => {
    const evidenceCopy = sub_evidence;
    if (!evidenceCopy.case) {
      evidenceCopy.case = { ...caseObject };
    }
    return evidenceCopy;
  };
  const onEditingStart = (id) => {
    const new_list = [...editingItems];
    new_list.push(id);
    setEditingItems(new_list);
  };
  const editSubEvidenceLabel = async (id, label) => {
    const new_list = [...editingItems];
    let resp = await updateSubEvidence(
      id,
      caseObject.id,
      documentId,
      dispatch,
      {
        label,
      }
    );
    callback(resp);
    setEditingItems(new_list.filter((v) => v !== id));
  };
  const EditDeleteButtons = ({
    evidence,
    caseObject,
    callback,
    caseId,
    documentId,
  }) => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "80px" }}
      >
        <ListActionButton
          clickHandler={() => {
            let ann_;
            if (annotManager) {
              ann_ = annotManager.getAnnotationById(evidence.id);
              if (ann_) {
                annotManager.selectAnnotation(ann_);
                if (evidence.page && window.documentV) {
                  window.documentV.setCurrentPage(evidence.page);
                }
              }
            }
            dispatch({
              type: SHOW_RIGHT_SIDE_BAR,
              url: history.location.pathname,
              editableObject: evidence,
              afterCloseAction: () => {
                if (ann_) {
                  annotManager.deselectAnnotation(ann_);
                }
              },
              content: (
                <SubEvidenceDocProperties
                  caseObject={caseObject}
                  caseId={caseId}
                  annotManager={annotManager}
                  sub_evidence={evidenceWithCase()}
                  documentId={documentId}
                  callback={callback}
                />
              ),
              swapContent: {
                content: (
                  <SubEvidence
                    annotManager={annotManager}
                    sub_evidence={
                      window.doc_object && document_page
                        ? window.doc_object.sub_evidence
                        : sub_evidence
                    }
                    callback={callback}
                    caseObject={caseObject}
                    caseId={caseId}
                    documentId={documentId}
                  />
                ),
                title: "Key Sections",
              },
              title: `Edit Key Section`,
            });
          }}
          type={"edit"}
        />
        {document_page ? (
          <ListActionButton
            clickHandler={() => {
              dispatch({
                type: SAVE_MODAL_DATA,
                payload:
                  "You won't be able to restore data. This action will delete your annotation in document.",
                beforeCloseHandler: async () => {
                  let resp = await deleteSubEvidenceApi(
                    caseId,
                    dispatch,
                    documentId,
                    evidence.id
                  );
                  if (annotManager && annotManager.getAnnotationById) {
                    let ann_ = annotManager.getAnnotationById(evidence.id);
                    if (ann_) {
                      const read_only = annotManager.getReadOnly();
                      annotManager.setReadOnly(false);
                      annotManager.deleteAnnotations([ann_]);
                      annotManager.setReadOnly(read_only);
                    }
                  }
                  callback(resp);
                },
              });
              dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
            }}
            type={"delete"}
          />
        ) : null}
      </div>
    );
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {!sub_evidence || !sub_evidence.length ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: "305px",
            }}
          >
            <NoRecords>
              <Bold14Font textColor={"#0f122f"}>
                Create key sections by tagging subsections of the document.
                <br />
                Your key sections will appear here.
              </Bold14Font>
            </NoRecords>
          </div>
        ) : (
          <Fragment>
            {sub_evidence.map((s_evidence, index) => {
              let ann_;
              if (annotManager) {
                ann_ = annotManager.getAnnotationById(s_evidence.id);
              }
              let content = ann_ ? ann_.getContents() : null;
              if (typeof content === "string" && content.length > 50) {
                content = content.substring(0, 50) + "...";
              }
              return (
                <Fragment key={s_evidence.id}>
                  {index !== 0 ? <Separator /> : null}
                  <div className="form-group">
                    <label className="w-100" style={{ marginBottom: 0 }}>
                      <div className="container-fluid">
                        <div className={`row ${content ? "mb-2" : ""}`}>
                          <div className="col d-flex justify-content-between align-items-center">
                            <KeySectionLabelStyle
                              width={
                                editingItems.indexOf(s_evidence.id) === -1
                                  ? 70
                                  : 100
                              }
                              pointer={callback}
                            >
                              <AnimatedDiv key={s_evidence.id}>
                                {callback ? (
                                  <EditableLabelClassComponent
                                    doneEditingCallback={editSubEvidenceLabel}
                                    editState={false}
                                    startEditingCallback={onEditingStart}
                                    object={s_evidence.label}
                                    isObjectString={true}
                                    index={s_evidence.id}
                                    ref={(ref) => assignRef(ref, s_evidence.id)}
                                  />
                                ) : (
                                  s_evidence.label
                                )}
                              </AnimatedDiv>
                            </KeySectionLabelStyle>
                            {editingItems.indexOf(s_evidence.id) === -1 ? (
                              <Fragment>
                                <Medium14Font
                                  style={{
                                    width: "35%",
                                    textAlign: "right",
                                    marginRight: "10px",
                                  }}
                                  pointer={document_page}
                                  onClick={() => {
                                    if (annotManager && document_page) {
                                      if (s_evidence.page && window.documentV) {
                                        window.documentV.setCurrentPage(
                                          s_evidence.page
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {s_evidence.page
                                    ? `page: ${s_evidence.page}`
                                    : null}
                                </Medium14Font>
                                <EditDeleteButtons
                                  evidence={s_evidence}
                                  callback={callback}
                                  caseId={caseId}
                                  caseObject={caseObject}
                                  documentId={documentId}
                                />
                              </Fragment>
                            ) : null}
                          </div>
                        </div>
                        {content && document_page ? (
                          <div className={"row"}>
                            <div className={"col d-flex"}>
                              <Medium14Font>{content}</Medium14Font>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </label>
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
