import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateAuthorityById } from "../../../containers/Auth/auth";
import InputNew from "../../InputNew/InputNew";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { randomNumberRange } from "../../../utils";

const EditKeySectionsForm = ({ caseId, callback, evidence }) => {
  const [bundle, setBundle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [keySections, setKeySections] = useState("");
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  const dispatch = useDispatch();
  let form = useRef(null);

  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setKeySections(evidence.key_sections ?? "");
    }
  }, [evidence]);

  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ key_sections: keySections } };
    });
  }, [keySections]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      const resp = await updateAuthorityById(evidence.id, caseId, dispatch, {
        key_sections: keySections,
      });
      callback(resp);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            maxLength={125}
            label={"Sections"}
            onChangeHandler={(e) => {
              if (e.target.value.length <= 125) setKeySections(e.target.value);
            }}
            value={keySections}
            placeholder={"Enter Sections"}
            name={"key_sections"}
            form={`form-key-sections-${randomNumber}`}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            id={`form-key-sections-${randomNumber}`}
            ref={form}
          >
            <ButtonNew
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default EditKeySectionsForm;
