import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PulseLoader from "react-spinners/PulseLoader";

import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import WitnessCard from "../../../../components/baseAppComponents/CaseCard/WitnessCard";

import { getWitnessList } from "../../../Auth/auth";

import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { Bold18Font } from "../../../../components/FontsNewComponent/Fonts";
import NoRecords from "../../../../components/NoRecords";
import useWindowSize from "../../../../hooks/useWindowSize";

const Witnesses = ({
  setActiveItem,
  caseObject,
  witnessesList,
  setWitnessesList,
  theme,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [uniquePartyNamesRoles, setUniquePartyNamesRoles] = useState([]);
  const [witnessesByPartyNameRole, setWitnessesByPartyNameRole] = useState({});
  const { id } = useParams();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const isXExamWitnessListPage =
    useRouteMatch("/app/cases/:id/trial-hub/x-exam") ||
    useRouteMatch("/admin/all-cases/:id([0-9]+)?/trial-hub/x-exam");
  const location = useLocation();
  const dispatch = useDispatch();

  const { width } = useWindowSize();

  useEffect(() => {
    setIsLoading(true);
  }, [location.key]);

  // Get Witnesses list OR X-Exam witnesses list of current Case from API
  useEffect(() => {
    setActiveItem(
      !(isXExamWitnessListPage && isXExamWitnessListPage.isExact) ? 1 : 2
    );
    const getData = async () => {
      if (isLoading) {
        const resp = await getWitnessList(id, dispatch);
        if (resp) {
          setWitnessesList(
            resp.filter(
              (v) =>
                !v.party ||
                v.party.client ===
                  !(isXExamWitnessListPage && isXExamWitnessListPage.isExact)
            )
          );
          setIsLoading(false);
        }
      }
    };
    getData();
  }, [isLoading, location.key]);

  // Transform data from witnessList
  useEffect(() => {
    const wrappedWitnessesList = [];
    witnessesList.forEach((witness, idx) => {
      wrappedWitnessesList.push({
        id: witness.id,
        partyNameRole:
          witness.party !== null
            ? `${witness.party.name} - ${witness.party.role}`
            : "Unassigned",
        data: witness,
      });
    });
    if (wrappedWitnessesList && wrappedWitnessesList.length) {
      // Create unique party roles list:
      const uniquePartyNameRoleList = [
        ...new Set(wrappedWitnessesList.map((el) => el.partyNameRole)),
      ];
      const index = uniquePartyNameRoleList.findIndex(
        (el) => el === "Unassigned"
      );
      if (index !== -1) {
        uniquePartyNameRoleList.push(
          uniquePartyNameRoleList.splice(index, 1)[0]
        );
      }
      setUniquePartyNamesRoles([...uniquePartyNameRoleList]);

      // Filter witnesses by party role names:
      const tempObject = {};
      uniquePartyNameRoleList.forEach((role) => {
        tempObject[role] = wrappedWitnessesList.filter(
          (witnessObject) =>
            witnessObject.partyNameRole && witnessObject.partyNameRole === role
        );
      });
      setWitnessesByPartyNameRole(tempObject);
    }
  }, [witnessesList]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: "/app/cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !(isXExamWitnessListPage && isXExamWitnessListPage.isExact)
        ? `/app/cases/${id}/trial-hub/witness`
        : `/app/cases/${id}/trial-hub/x-exam`,
      title: !(isXExamWitnessListPage && isXExamWitnessListPage.isExact)
        ? "Trial Hub - Witness"
        : "Trial Hub - X-Exam",
      activeMenuItem: CASES,
    },
  ];

  return (
    <div className="container-fluid">
      <PageConfiguration configArray={pageConfig} />
      {witnessesList.length ? (
        uniquePartyNamesRoles.map((role, index) => {
          if (witnessesByPartyNameRole[role].length) {
            if (
              role === "Unassigned" &&
              isXExamWitnessListPage &&
              isXExamWitnessListPage.isExact
            )
              return "";
            return (
              <div key={index} style={{ marginBottom: "30px" }}>
                <div className="row" style={{ marginBottom: "20px" }}>
                  <div className="col">
                    <Bold18Font title="Party Role">{role}</Bold18Font>
                  </div>
                </div>
                <div className="row">
                  {witnessesByPartyNameRole[role].map((witnessObject) => (
                    <div
                      key={witnessObject.id}
                      className={
                        width < 1600
                          ? "col-12 col-md-6 col-lg-4 mb-3"
                          : "col-xl-3 mb-3"
                      }
                    >
                      <WitnessCard
                        witnessObject_={witnessObject.data}
                        caseObject={caseObject}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          }
        })
      ) : (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Your{" "}
            {isXExamWitnessListPage && isXExamWitnessListPage.isExact
              ? "X-Exam "
              : null}
            Witnesses will appear here
          </Bold18Font>
        </NoRecords>
      )}
    </div>
  );
};

Witnesses.propTypes = {
  setActiveItem: PropTypes.func.isRequired,
  witnessesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setWitnessesList: PropTypes.func.isRequired,
};

export default withTheme(Witnesses);
