import React, { Fragment, useEffect, useState } from "react";
import Label from "../AddCardForm/Label";
import Button from "../../buttons/Button/Button";
import { useDispatch } from "react-redux";
import {
  updateApplicationPaperById,
  updateEvidence,
} from "../../../containers/Auth/auth";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import moment from "moment";
import { parseISO } from "date-fns";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

const EditAllocateDateForm = ({
  caseId,
  callback,
  evidence,
  applicationId,
  isApplicationPapersBulkImportFlow,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [date, setDate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setDate(evidence.date ? parseISO(evidence.date) : null);
    }
  }, [evidence]);
  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ date: moment(date).format("YYYY-MM-DD") } };
    });
  }, [date]);
  const onChangeDateHandler = (date) => {
    setDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      let data;
      if (date) {
        data = { date: moment(date).format("YYYY-MM-DD") };
      } else {
        data = { date: null };
      }
      let resp;
      if (isApplicationPapersBulkImportFlow) {
        resp = await updateApplicationPaperById(
          evidence.id,
          applicationId,
          caseId,
          dispatch,
          data
        );
      } else {
        resp = await updateEvidence(evidence.id, caseId, dispatch, data);
      }

      callback(resp);
      setIsLoading(false);
    }
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Label name={"Label"} />
          <Medium14Font
            className="pl-3 pr-3"
            textColor={theme.colors.darkOpacity}
          >
            {object.label}
          </Medium14Font>
        </div>
        <div className="form-group">
          <CustomReactDatepicker
            selected={date}
            onChange={(date) => onChangeDateHandler(date)}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            label="Allocate Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <Button
            clickHandler={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            wide
            primary
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
export default EditAllocateDateForm;
