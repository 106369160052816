import React, {Fragment, useEffect, useState} from "react";
import BulkPanelStyled from "./styled/BulkPanelStyled";
import IndexStyled from "./styled/IndexStyled";
import {Bold18Font, Medium18Font} from "../FontsNewComponent/Fonts";
import {theme} from "../../styled-components/Theme/Theme";
import Dots from "../../assets/img/svg/dots.svg";
import useWindowSize from "../../hooks/useWindowSize";

const BulkPanel = ({items, active, setActiveItem, canSwitch}) => {
  const [flex, setFlex] = useState(true);
  const {width} = useWindowSize();

  useEffect(() => {
    if (width < 1200) {
      setFlex(false);
    } else {
      setFlex(true);
    }
  }, [width]);

  return <div className="container-fluid">
    <BulkPanelStyled className={`${flex ? 'd-flex' : ''} ${items.length > 2 ? 'justify-content-between' : 'justify-center-between'} align-items-center`}>{items.map((item, index) => {
      return <Fragment key={index}>
        <div style={{cursor: 'pointer'}} className="d-flex" onClick={() => {
          if (canSwitch) {
            setActiveItem(index)
          }
        }}>
          <IndexStyled active={active === index && canSwitch}>{index + 1}</IndexStyled>
          {active === index && canSwitch ? <Bold18Font textColor={theme.colors.blue}>{item}</Bold18Font> :
            <Medium18Font textColor={theme.colors.darkOpacity}>{item}</Medium18Font>}
        </div>
        {index !== items.length - 1 ? <img className={items.length > 2 ? null : 'ml-3 mr-3'} src={Dots}/> : null}</Fragment>
    })}</BulkPanelStyled>
  </div>
};
export default BulkPanel;
