import React, { useState } from "react";
import AddCardFormStyled from "./styled/AddCardFormStyled";
import { Bold18Font } from "../../Fonts/Fonts";
import FormHeader from "./styled/FormHeader";
import Label from "./Label";
import {
  CardNumberElement,
  Elements,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../../../assets/css/stripe.css";
import { theme } from "../../../styled-components/Theme/Theme";
import InputStyled from "../../Input/styled/InputStyled";
import PropTypes from "prop-types";
import {addSubscription, getActiveSubscription, swapSubscription} from "../../../containers/Auth/auth";
import {handleErrorMessage} from "../../Modal/modalErrorHandler";
import {MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION, SHOW_MODAL, UPDATE_USER} from "../../../redux/types";

const AddCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [doneElements, setDoneElements] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: false,
  });
  const [fistNameLastName, setFirstNameLastName] = useState("");
  const handleChange = (change) => {
    doneElements[change.elementType] = change.complete && !change.error;
    setDoneElements(doneElements);
    if (props.setCanSubmit) {
      props.setCanSubmit(canSubmit());
    }
  };
  const canSubmit = () => {
    return Object.values(doneElements).indexOf(false) === -1;
  };
  const changeNameHandler = (value) => {
    setFirstNameLastName(value);
    doneElements["name"] = value !== "";
    setDoneElements(doneElements);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !stripe ||
      !canSubmit() ||
      !props.accepted ||
      isLoading ||
      !props.product
    ) {
      return;
    }

    const CardNumber = elements.getElement(CardNumberElement);
    props.setIsLoading(true);
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: CardNumber,
      billing_details: {
        name: fistNameLastName,
      },
    });

    if (error) {
      alert(error);
      props.setIsLoading(false);
      setIsLoading(false);
    }
    try {
      let resp = await addSubscription({
        payment_method: paymentMethod,
        product_id: props.product.product_id,
        price_id: props.product.price_id,
      });
      if (resp.data.result === 'action_required') {
        stripe
          .confirmCardPayment(resp.data.secret, {
            payment_method: paymentMethod.id,
          })
          .then(function(result) {
            if (result.error) {
              handleErrorMessage(result.error.message, dispatch);
            } else {
              getActiveSubscription(dispatch).then((resp) => {

              }).catch((error) => {
                console.log(error);
              });
            }
          });
      }
    } catch (e) {}
  };
  const createOptions = {
    style: {
      base: {
        letterSpacing: "0.025em",
        top: "5px",
        fontFamily: "PT Root UI, sans-serif",
        "::placeholder": {
          color: theme.colors.darkOpacity,
          fontFamily: "PT Root UI, sans-serif",
          fontSize: "14px",
          fontWight: 500,
        },
      },
      invalid: {
        color: theme.colors.burgundy,
      },
    },
  };
  return (
    <AddCardFormStyled
      onSubmit={handleSubmit}
      className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 m-auto"
    >
      <FormHeader className="d-flex align-items-center">
        <Bold18Font>Add Card</Bold18Font>
      </FormHeader>
      <fieldset className={"form-vertical"}>
        <div className="form-group">
          <Label name={"First & Last Name"} />
          <div className="row">
            <div className="col">
              <InputStyled
                type={"text"}
                value={fistNameLastName}
                onChange={(e) => {
                  changeNameHandler(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <Label name={"Card Number"} />
          <CardNumberElement onChange={handleChange} {...createOptions} />
        </div>
        <div className="form-group row">
          <div className="col-6">
            <Label name={"Expiration Date"} />
            <CardExpiryElement onChange={handleChange} {...createOptions} />
          </div>
          <div className="col-6">
            <Label name={"Security Code"} />
            <CardCvcElement onChange={handleChange} {...createOptions} />
          </div>
        </div>
      </fieldset>
    </AddCardFormStyled>
  );
};
AddCardForm.propTypes = {
  setCanSubmit: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  accepted: PropTypes.bool.isRequired,
  product: PropTypes.any.isRequired,
};
export default AddCardForm;
