import React, {useEffect} from "react";
import SignPagesWrapper from "../../../components/SignPagesWrapper";
import SignInForm from "../../../components/forms/SignInForm";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {redirectUserToAppropriatePage} from "../utils";

const SignInPage = () => {

  const isUserAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const history = useHistory();

  // useEffect(() => {
  //   const tryRedirect = async () => {
  //     await redirectUserToAppropriatePage(isUserAuth, history, dispatch);
  //   };
  //   tryRedirect();
  // }, []);

  return (
    <SignPagesWrapper>
      <SignInForm />
    </SignPagesWrapper>
  );
};

export default SignInPage;
