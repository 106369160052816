import React from "react";
import PropTypes from "prop-types";

import RadioButtonGroupButtonDivStyled from "./styled/RadioButtonGroupButtonDivStyled";

import { Bold14Font } from "../../FontsNewComponent/Fonts";

const RadioButtonGroup = ({
  label,
  itemsObjects,
  checkedButton,
  setCheckedButton,
  disabled,
  w100,
  ...props
}) => {
  const onClickHandler = (itemType) => {
    setCheckedButton(itemType);
  };

  return (
    <div {...props} className="container-fluid">
      {label ? (
        <div className="row mb-2">
          <div className="col-12">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col">
          <div
            className={`d-md-flex${
              !w100 ? " flex-wrap" : " flex-wrap flex-sm-nowrap"
            }`}
          >
            {itemsObjects.map((item, index) => (
              <RadioButtonGroupButtonDivStyled
                disabled={disabled}
                key={index}
                onClick={() => onClickHandler(item.type)}
                first={index === 0}
                last={index === itemsObjects.length - 1}
                checked={item.type === checkedButton}
                w100={w100}
                className="mb-2 mb-md-0"
              >
                {item.title}
              </RadioButtonGroupButtonDivStyled>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  label: PropTypes.string,
  itemsObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkedButton: PropTypes.string.isRequired,
  setCheckedButton: PropTypes.func.isRequired,
  /**
   * Set width to 100%
   */
  w100: PropTypes.bool,
};

RadioButtonGroup.defaultProps = {
  w100: false,
};

export default RadioButtonGroup;
