import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { loadAccessToken } from "@/containers/Auth/auth";

const NotebookContext = createContext();

const NotebookProvider = ({ children }) => {
  const { id: caseId, notebook_id: notebookId } = useParams();
  const [caseObject, setCaseObject] = useState({});
  const [caseLibrary, setCaseLibrary] = useState(undefined);
  const [caseLoading, setCaseLoading] = useState(false);

  const [notebookNotFound, setNotebookNotFound] = useState(false);

  const [chapters, setChapters] = useState(undefined);
  const [columns, setColumns] = useState({});
  const [chaptersFetching, setChaptersFetching] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState(undefined);
  const [lastActionConnect, setLastActionConnect] = useState(undefined);

  useEffect(() => {
    if (!notebookId) {
      setSelectedChapter(undefined);
    }
  }, [notebookId]);

  const [notebookLoading, setNotebookLoading] = useState(false);
  const [notebook, setNotebook] = useState(undefined);

  const fetchNotebook = async (caseIdd, notebookId) => {
    setNotebookLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .get(`/api/cases/${caseIdd}/note-book/${notebookId}`, config)
      .catch((err) => {
        setNotebookLoading(false);
        // setChaptersFetching(false);
        // setNotebookNotFound(true);
      });

    setNotebookLoading(false);
    if (res.data.data) {
      setNotebook(res.data.data);
    }
  };

  const [evidenceItems, setEvidenceItems] = useState([]);

  const fetchCaseLibrary = async (caseIdd, notebookId, filters) => {
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(
        `/api/cases/${caseIdd}/note-book/${notebookId}/case-library${
          filters ? `?relevance[]=${filters}` : ""
        }`,
        config
      )
      .then((res) => {
        if (res && res.data && res.data.data) {
          setCaseLibrary(res.data.data);
          // console.log(res.data.data);
        }
      });
  };

  const getEvidenceItems = async () => {
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    axios.get(`/api/cases/${caseId}/evidence`, config).then((res) => {
      if (res && res.data && res.data.data) {
        setEvidenceItems(
          res.data.data.map((i) => ({
            tagId: i.id,
            id: i.label,
            tagType: "evidence",
          }))
        );
      }
    });
  };

  const fetchChapters = async (caseId, notebookId, callback) => {
    setChaptersFetching(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .get(`/api/cases/${caseId}/note-book/${notebookId}/chapter`, config)
      .catch((err) => {
        setChaptersFetching(false);
        setNotebookNotFound(true);
      });
    setChaptersFetching(false);
    if (res && res.data && res.data.data) {
      setChapters(res.data.data);
      if (callback) {
        callback(res.data.data);
      }
      // setNotebooks(res.data.data);
    }
  };

  return (
    <NotebookContext.Provider
      value={{
        evidenceItems,
        getEvidenceItems,
        setEvidenceItems,

        chapters,
        chaptersFetching,
        selectedChapter,

        setChapters,
        fetchChapters,
        setChaptersFetching,
        setSelectedChapter,

        notebookNotFound,
        setNotebookNotFound,

        columns,
        setColumns,

        caseObject,
        setCaseObject,

        fetchCaseLibrary,
        caseLibrary,
        setCaseLibrary,

        fetchNotebook,
        notebookLoading,
        setNotebookLoading,
        notebook,
        setNotebook,
        caseLoading,
        setCaseLoading,

        lastActionConnect,
        setLastActionConnect,
      }}
    >
      {children}
    </NotebookContext.Provider>
  );
};

export { NotebookProvider, NotebookContext };
