import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { useDispatch, useSelector } from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import React, { Fragment, useEffect, useState } from "react";
import { getCaseById } from "../../Auth/auth";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import {
  Bold18Font,
  Bold30Font,
} from "../../../components/FontsNewComponent/Fonts";
import Form from "../../../components/forms/Form";
import styled from "styled-components/macro";
import PageTitle from "../../../components/PageTitle";

const Element = ({ children, url }) => {
  const history = useHistory();
  return (
    <li>
      <Bold18Font
        onClick={() => {
          return url ? history.push(url) : null;
        }}
        pinter
        textColor={"rgba(15, 18, 47, 0.6)"}
      >
        {children}
      </Bold18Font>
    </li>
  );
};

const CustomUl = styled.ul`
  list-style: disc;
`;

export default function TrialProofs() {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [caseObject, setCaseObject] = useState({});
  const { id } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const resp = await getCaseById(id, dispatch);
      if (is_admin && !resp) {
        history.push("/admin/all-cases");
      }
      setCaseObject(resp);
      setIsLoading(false);
    };
    loadData();
  }, []);

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
  ];

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <PageTitle pageLabel="Trial Proofs" colClassName="col-12 mb-4" />
      <div className={"row"}>
        <div className="col-lg-6 col-md-12">
          <Form title="Witness Proofs">
            <CustomUl className="ml-5">
              {caseObject.witnesses.filter(v => !v.party || v.party.client).map((v) => {
                return (
                  <Element url={`${url}/witness-proofs/${v.fake_id}`}>
                    {v.name}
                  </Element>
                );
              })}
            </CustomUl>
          </Form>
        </div>
        <div className="col-lg-6 col-md-12">
          <Form title="X-Exam Proofs">
            <CustomUl className="ml-5">
              {caseObject.witnesses.filter(v => v.party && !v.party.client).map((v) => {
                return (
                  <Element url={`${url}/x-exam-witness-proofs/${v.fake_id}`}>
                    {v.name}
                  </Element>
                );
              })}
            </CustomUl>
          </Form>
        </div>
        <div className="col-lg-6 col-md-12">
          <Form title="Court Documents - Trial">
            <CustomUl className="ml-5">
              <Element url={`${url}/court-documents/trial/core-book`}>
                Core Book
              </Element>
              <Element url={`${url}/court-documents/trial/authorities`}>
                Authorities
              </Element>
              <Element url={`${url}/court-documents/trial/chronology`}>
                Chronology
              </Element>
            </CustomUl>
          </Form>
        </div>
        <div className="col-lg-6 col-md-12">
          <Form title="Trial Checklists">
            <CustomUl className="ml-5">
              <Element url={`${url}/trial-checklist/advice-on-proofs`}>
                Advice on Proofs
              </Element>
              <Element url={`${url}/trial-checklists/evidence-checklist`}>
                Evidence Checklist
              </Element>
            </CustomUl>
          </Form>
        </div>
        <div className="col-lg-6 col-md-12">
          <Form title="Court Documents - Applications">
            <CustomUl className="ml-5">
              <Element url={`${url}/court-documents/applications/authorities`}>
                Authorities
              </Element>
            </CustomUl>
          </Form>
        </div>
      </div>
    </Fragment>
  );
}
