import styled from "styled-components/macro";

const LogoutButtonStyled = styled.button`
  border-radius: unset;
  color: ${({ theme }) => theme.colors.burgundy};

  :hover {
    color: ${({ theme }) => theme.colors.burgundyHover};
  }
`;

export default LogoutButtonStyled;
