import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";

import CasesDropdownFilterStyled from "./styled/CasesDropdownFilterStyled";
import CasesDropdownSwitcherStyled from "./styled/CasesDropdownSwitcherStyled";

const CasesDropdownSwitcher = ({ ...props }) => {
  return (
    <CasesDropdownSwitcherStyled
      {...props}
      isSearchable={false}
    />
  );
};

CasesDropdownSwitcher.propTypes = {
  /**
   * Rounded the corners of the border to the left
   * */
  positionStart: PropTypes.bool,

  /**
   * Disable round corners
   * */
  positionMiddle: PropTypes.bool,

  /**
   * Rounded the corners of the border to the right
   * */
  positionEnd: PropTypes.bool,
};

export default withTheme(CasesDropdownSwitcher);
