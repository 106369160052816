import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
// import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import { loadAccessToken } from "@/containers/Auth/auth";

import { Container, ButtonContainer, Column } from "./styled";
import { HIDE_RIGHT_SIDE_BAR } from "@/redux/types";
import { Select } from "antd";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";
const { Option } = Select;
const children = [];

for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const EditExamLine = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];

  // This object fiels
  const [label, setLabel] = useState(props.id);
  const [objectives, setObjectives] = useState([]);

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  useEffect(() => {
    fetchObject();
  }, []);

  const fetchObject = async () => {
    setDataLoading(true);
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(`/api/cases/${caseId}/exam-lines/${props.tagId}`, config)
      .then((res) => {
        setDataLoading(false);
        if (res && res.data) {
          const object = res.data;

          setObjectives(
            object.objectives.map((i) => ({ label: i.name, value: i.id }))
          );
        }
      });
  };

  const createObject = async (text, type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    let res;

    return axios.post(
      `/api/cases/${caseId}/notes/objects?type=${type}&id_case=${caseId}`,
      {
        text,
      },
      config
    );

    //    if (res && res.data && res.data.data) {
    //      //
    //    }
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  // useEffect(() => {
  //   const onEnter = (e) => {
  //     if (e.key === "Enter") {
  //       saveResult(label);
  //     }
  //   };

  //   window.addEventListener("keydown", onEnter);
  //   return () => window.removeEventListener("keydown", onEnter);
  // }, [label]);

  const saveResult = async (name) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let allObjectives = objectives.filter((i) => !!i.label);

    for (let ev of objectives) {
      if (!ev.label && !caseLibrary.Objective.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Objective").then((res) => {
          allObjectives.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Objective: [...caseLibrary.Objective, res.data.data],
          }));
        });
      }
    }

    let body = {
      name: name,
      objectives: allObjectives.map((i) => ({ objective_id: i.value })),
    };

    let endpoint = `/api/cases/${caseId}/exam-lines/${props.tagId}`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: label },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.ExamLine.findIndex(
      (i) => i.id == props.tagId
    );
    const newExamLine = [
      ...caseLibrary.ExamLine.slice(0, itemIndex),
      {
        ...caseLibrary.ExamLine[itemIndex],
        label: label,
      },
      ...caseLibrary.ExamLine.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      ExamLine: newExamLine,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  if (dataLoading) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={label}
              onChangeHandler={(e) => setLabel(e.target.value)}
              // autoFocus
            />
          </div>
        </div>
        <MultiSelect
          label="Objectives"
          placeholder="Objectives"
          value={objectives}
          onChange={(value) => setObjectives(value)}
          options={caseLibrary.Objective.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="objective"
        />
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult(label);
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

const MultiSelect = ({
  onChange,
  placeholder = "Placeholder",
  label = "Label",
  options = [
    { label: "label1", value: "value1" },
    { label: "label2", value: "value2" },
    { label: "label3", value: "value3" },
  ],
  type = "objective",
  value,
}) => {
  return (
    <div className="form-group row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            labelInValue
            value={value}
            showSearch
            filterOption={(input, option) => {
              return ("" + option.label)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            // onSelect={(value) => {
            //   console.log("select");
            //   console.log(value);
            // }}
            tagRender={(props) => {
              return (
                <span
                  style={{ marginRight: 5, marginBottom: 2, marginTop: 2 }}
                  className={`tag tag-${type}`}
                >
                  {props.label}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditExamLine);
