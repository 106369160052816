import React, { useState, useEffect, useContext } from "react";
import { NotebookContext } from "@/context";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { loadAccessToken } from "@/containers/Auth/auth";
import { Bold18Font } from "@/components/FontsNewComponent/Fonts";
import NoRecords from "@/components/NoRecords";
import axios from "axios";

import { PulseLoader } from "react-spinners";

import Editor from "./Editor";

import { CASES } from "../../baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import { Container } from "./styled";

import { getCaseById } from "../../../containers/Auth/auth";
import Actions from "./Actions";
import PageConfiguration from "../../baseAppComponents/BreadCrumbs/PageConfiguration";

import StubPage from "@/components/StubPage";

const Workspace = ({ theme }) => {
  const {
    chapters,
    chaptersFetching,
    notebookNotFound,
    setNotebookNotFound,
    caseObject,
    setCaseObject,
    selectedChapter,
    fetchNotebook,
    notebookLoading,
    notebook,
    caseLoading,
    setCaseLoading,
  } = useContext(NotebookContext);

  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const history = useHistory();
  const dispatch = useDispatch();
  // Case ID
  const { id, notebook_id: notebookId } = useParams();

  useEffect(() => {
    fetchNotebook(id, notebookId);
  }, []);

  // Get data from API
  const getDataFromAPI = async () => {
    setCaseLoading(true);
    const caseResp = await getCaseById(id, dispatch);
    if (is_admin && !caseResp) {
      history.push("/admin/all-cases");
    }
    if (caseResp) {
      setCaseObject(caseResp);
      setCaseLoading(false);
    }
  };

  // Set data to state after component mount
  useEffect(() => {
    getDataFromAPI();
  }, []);

  if (caseLoading || chaptersFetching || notebookLoading || !notebook) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  if (notebookNotFound) {
    return (
      <StubPage
        onClick={() => {
          setNotebookNotFound(false);
          history.push(`/app/cases/${id}/notebooks`);
        }}
      >
        404 Page Not Found
      </StubPage>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject ? caseObject.label : "",
      activeMenuItem: CASES,
    },
    {
      // path: !is_admin
      //   ? `/app/cases/${id}/notebooks`
      //   : `/admin/all-cases/${id}/notebooks`,
      path: !is_admin
        ? `/app/cases/${id}/notebooks`
        : `/admin/all-cases/${id}/notebooks`,
      title: "Notebooks",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/notebooks/${notebookId}`
        : `/admin/all-cases/${id}/notebooks/${notebookId}`,
      title: notebook.name,
      activeMenuItem: CASES,
    },
  ];

  return (
    <Container className="d-flex flex-column">
      <PageConfiguration configArray={pageConfig} />
      {chapters && chapters.length > 0 && selectedChapter ? (
        <>
          <Actions />
          <Editor />
        </>
      ) : (
        <div style={{ height: "100%" }}>
          <NoRecords>
            <Bold18Font textColor={"#0f122f"}>
              Start by creating first chapter
            </Bold18Font>
          </NoRecords>
        </div>
      )}
    </Container>
  );
};

export default withTheme(Workspace);
