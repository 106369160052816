import React from "react";
import FormStyled from "./styled/FormStyled";
import FormHeader from "./styled/FormHeader";
import { Bold18Font } from "../../FontsNewComponent/Fonts";
import PropTypes from "prop-types";
import RectangleButton from "../../buttons/RectangleButton";

const Form = (props) => {
  return (
    <FormStyled {...props}>
      {props.title ? (
        <FormHeader
          withCloseButton={props.withCloseButton}
          style={props.headerStyle ?? null}
          className="d-flex align-items-center"
        >
          <Bold18Font>{props.title}</Bold18Font>
          {props.withCloseButton ? (
            <RectangleButton rsbCloseBtn onClick={props.handleCloseClick} />
          ) : null}
        </FormHeader>
      ) : null}
      {props.children}
      {/*{justifyContent: 'space-between', paddingRight: 30}*/}
    </FormStyled>
  );
};
Form.propTypes = {
  title: PropTypes.string,
  withCloseButton: PropTypes.bool,
  handleCloseClick: PropTypes.func,
  headerStyle: PropTypes.object,
};
export default Form;
