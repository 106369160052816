import {
  SHOW_LEFT_SIDE_BAR,
  HIDE_LEFT_SIDE_BAR,
  SET_LEFT_SIDE_BAR_ACTIVE_ITEM,
  TOGGLE_LEFT_SIDE_BAR,
  TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR,
} from "../types";

const initialState = {
  isSideBarPulled: false,
  canClose: true,
  activeItem: null,
};

const leftSideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LEFT_SIDE_BAR:
      return { ...state, isSideBarPulled: true, canClose: true };

    case HIDE_LEFT_SIDE_BAR:
      return { ...state, isSideBarPulled: false, canClose: true };

    case TOGGLE_LEFT_SIDE_BAR:
      return { ...state, isSideBarPulled: !state.isSideBarPulled };

    case TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR:
      return { ...state, canClose: !state.canClose };

    case SET_LEFT_SIDE_BAR_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: action.payload,
      };

    default:
      return state;
  }
};

export default leftSideBarReducer;
