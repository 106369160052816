import React, { useRef, useState } from "react";

import FeedbackFormStyled from "./styled/FeedbackFormStyled";
import Input from "../../Input";
import { Bold24Font } from "../../Fonts/Fonts";
import TextArea from "../../TextArea";
import Button from "../../buttons/Button";
import { contact_us } from "../../../containers/Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  MODAL_SUCCESS,
  SAVE_MODAL_DATA,
  SET_CONTACT_US,
  SHOW_MODAL,
} from "../../../redux/types";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";

const FeedbackForm = () => {
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  let form = useRef(null);
  const canSend = useSelector((state) => state.app.contactUsForm);

  const onChangeInputHandler = (event) => {
    event.persist();
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const dispatch = useDispatch();
  const onSubmitFormHandler = async (event) => {
    event.preventDefault();
    if (!sending) {
      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }
      setSending(true);
      if (canSend) {
        try {
          const resp = await contact_us(formData, dispatch);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          dispatch({ type: SET_CONTACT_US, payload: false });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload:
              "Thanks for your request. We will get back to you as soon as possible.",
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS });
        } catch (e) {}
      } else {
        setFormData({
          name: "",
          email: "",
          message: "",
        });
        dispatch({
          type: SAVE_MODAL_DATA,
          payload:
            "Thanks for your request. We will get back to you as soon as possible.",
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS });
      }

      setSending(false);
    }
  };

  return (
    <FeedbackFormStyled
      className="p-3"
      onSubmit={onSubmitFormHandler}
      ref={(ref) => (form = ref)}
    >
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col d-flex flex-column">
            <Bold24Font>Have a question?</Bold24Font>
            <Bold24Font>We would love to hear from you.</Bold24Font>
          </div>
        </div>
      </div>
      <div className="form-group">
        <Input
          name="name"
          placeholder="Enter your first & last name"
          label="First & Last Name"
          value={formData.name}
          onChangeHandler={onChangeInputHandler}
          required
        />
      </div>
      <div className="form-group">
        <Input
          name="email"
          placeholder="Enter your email"
          label="Email"
          type="email"
          value={formData.email}
          onChangeHandler={onChangeInputHandler}
          required
        />
      </div>
      <div className="form-group">
        <TextArea
          placeholder="Enter your message"
          name="message"
          label="Message"
          value={formData.message}
          onChange={onChangeInputHandler}
          required
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button primary type="submit" clickHandler={() => null}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </FeedbackFormStyled>
  );
};

export default FeedbackForm;
