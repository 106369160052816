import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import { route } from "../../../routes";
import ApplicationsCardView from "./ApplicationsCardView";
import StubPage from "../../../components/StubPage";
import AddEditApplication from "./AddEditApplication";
import ApplicationDetails from "./ApplicationDetails";
import { useSelector } from "react-redux";
import { formFlowType } from "../../../utils/constants";

/**
 * Component route in applications hub
 * @returns {*}
 * @constructor
 */
const ApplicationsHubRouter = () => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  return (
    <Fragment>
      <Switch>
        {/* Applications cards view */}
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.applicationsHub.container
              : route.admin.baseApp.applicationsHub.container
          }`}
        >
          <ApplicationsCardView />
        </Route>

        {/* Application Details view */}
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.applicationsHub.applicationDetails
              : route.admin.baseApp.applicationsHub.applicationDetails
          }`}
        >
          <ApplicationDetails />
        </Route>

        {/* Create application view */}
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.applicationsHub.createApplication
              : route.admin.baseApp.applicationsHub.createApplication
          }`}
        >
          <AddEditApplication flow={formFlowType.CREATE} />
        </Route>
        {/* Edit application view */}
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.applicationsHub.addEditApplication
              : route.admin.baseApp.applicationsHub.addEditApplication
          }`}
        >
          <AddEditApplication />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default ApplicationsHubRouter;
