import React, { useState } from "react";
import { BubbleMenu } from "@tiptap/react";
import { isTagNode } from "@editor/helpers";

import { Container, List, SecondaryList, Option } from "./styled";

import chevronRight from "@/assets/img/notebook/chevron-right.svg";

const ConvertBubble = ({ editor, onCreateTag }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <BubbleMenu
      className="bubble-menu"
      tippyOptions={{ duration: 100 }}
      editor={editor}
      shouldShow={(props) => {
        if (props.from === props.to) {
          setExpanded(false);
          return false;
        }

        // if tag is selected - don't show
        if (
          props.editor.view.state.selection.node &&
          isTagNode(props.editor.view.state.selection.node.type.name)
        ) {
          setExpanded(false);
          return false;
        }

        // if selection range contains tags -> don't allow
        let containsTagNodes = false;

        props.editor.view.state.doc.nodesBetween(
          props.from,
          props.to,
          (node) => {
            if (isTagNode(node.type.name)) {
              containsTagNodes = true;
            }
          }
        );

        if (containsTagNodes) {
          setExpanded(false);
          return false;
        }

        // else - show if selection is present
        return true;
      }}
    >
      <Container>
        <List>
          <Option
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={(e) => {
              e.preventDefault();
              setExpanded(true);
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>New</span>
            <img src={chevronRight} alt="expand" />
          </Option>
        </List>
        {expanded && (
          <SecondaryList>
            {tags.map((tag) => (
              <Option
                key={tag.type}
                onClick={() => {
                  const range = {
                    from: editor.state.selection.from,
                    to: editor.state.selection.to,
                  };
                  const text = editor.state.doc.textBetween(
                    range.from,
                    range.to
                  );
                  onCreateTag(text, tag.type.toLowerCase(), range);

                  document.getElementById("tippy-1").remove();
                  setExpanded(false);
                }}
              >
                {tag.label}
              </Option>
            ))}
          </SecondaryList>
        )}
      </Container>
    </BubbleMenu>
  );
};

export default ConvertBubble;

const tags = [
  {
    type: "evidence",
    label: "Evidence",
  },
  {
    type: "witness",
    label: "Witness",
  },
  {
    type: "authority",
    label: "Authority",
  },
  {
    type: "objective",
    label: "Objective",
  },
  {
    type: "topic",
    label: "Topic",
  },
  {
    type: "issue",
    label: "Issue",
  },
  {
    type: "theme",
    label: "Theme",
  },
  {
    type: "exam",
    label: "Exam Line",
  },
];
