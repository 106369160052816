import React from "react";
import PropTypes from "prop-types";
import { Medium30Font } from "../FontsNewComponent/Fonts";

/**
 * Show title block in page
 * @returns {*}
 * @constructor
 */
const PageTitle = ({ pageLabel, rowClassName, colClassName }) => {
  // return (
  //   <div className={rowClassName}>
  //     <div className={colClassName}>
  //       <Medium30Font style={{ fontSize: "1.375rem" }}>
  //         {pageLabel}
  //       </Medium30Font>
  //     </div>
  //   </div>
  // );
  return <></>;
};

PageTitle.propTypes = {
  pageLabel: PropTypes.string.isRequired,
  rowClassName: PropTypes.string,
  colClassName: PropTypes.string,
};

PageTitle.defaultProps = {
  rowClassName: "row",
  colClassName: "col",
};

export default PageTitle;
