import React, { Fragment, useEffect, useRef, useState } from "react";
import ButtonNew from "../../buttons/Button/ButtonNew";
import Input from "../../Input";
import Label from "../AddCardForm/Label";
import Select from "../../Select";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "../../../containers/BaseApp/Documents/Documents";
import { addBundle, updateBundle } from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import {
  HIDE_LEFT_SIDE_BAR,
  HIDE_RIGHT_SIDE_BAR,
  RESET_RIGHT_SIDE_BAR_TITLE,
  SET_AFTERCLOSE_ACTION,
  SET_RIGHT_SIDE_BAR_TITLE,
} from "../../../redux/types";
import { randomNumberRange } from "../../../utils";
import { Bold18Font } from "../../FontsNewComponent/Fonts";
import RectangleButton from "../../buttons/RectangleButton";
import BaseAppLayoutRightSideBarHeaderStyled from "../../baseAppComponents/BaseAppLayout/BaseAppLayoutRightSideBar/styled/BaseAppLayoutRightSideBarHeaderStyled";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

const NewBundleForm = ({
  object,
  reloadDocuments,
  idCase,
  addNewBundleToList,
  noHideRSB,
  noHeader,
  setShowNewBundlePanel,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState(object ? object.label : "");
  const [docClass, setDocClass] = useState(object ? object.class_name : "");
  const dispatch = useDispatch();
  const [randomNumberForId, setRandomNumberForId] = useState(
    randomNumberRange()
  );
  let form = useRef(null);

  useEffect(() => {
    if (noHideRSB && !noHeader) {
      dispatch({ type: RESET_RIGHT_SIDE_BAR_TITLE });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      if (object) {
        await updateBundle(
          object.id,
          dispatch,
          { label: label, class_name: docClass },
          () => setIsLoading(false)
        );
      } else {
        const resp = await addBundle(
          dispatch,
          { label: label, class_name: docClass, id_case: idCase },
          () => setIsLoading(false)
        );
        if (resp && addNewBundleToList) {
          addNewBundleToList(resp);
        }
      }
      if (reloadDocuments) {
        await reloadDocuments();
      }
      if (noHideRSB) {
        dispatch({ type: SET_RIGHT_SIDE_BAR_TITLE, payload: "Add in Bulk" });
      }
      setIsLoading(false);
      if (!noHideRSB) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    }
  };
  const classes = [
    // {label: 'Application Papers', value: ''},
    { label: "Evidence", value: EVIDENCE_DOCUMENTS },
    { label: "Case Document", value: CASE_DOCUMENTS },
    { label: "Authority", value: AUTHORITIES_DOCUMENTS },
    { label: "Application Papers", value: APPLICATION_DOCUMENTS },
    // {label: 'Other', value: OTHER},
  ];
  const onDocClassChange = (selectedOption) => {
    setDocClass(selectedOption.value);
  };

  const onClickCloseButtonHandler = () => {
    dispatch({ type: SET_RIGHT_SIDE_BAR_TITLE, payload: "Add in Bulk" });
    setShowNewBundlePanel(false);
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      {noHideRSB && !noHeader ? (
        <BaseAppLayoutRightSideBarHeaderStyled withCloseButton>
          <Bold18Font>Add New Bundle</Bold18Font>
          <RectangleButton rsbCloseBtn onClick={onClickCloseButtonHandler} />
        </BaseAppLayoutRightSideBarHeaderStyled>
      ) : null}
      <div style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}>
        <div className="form-group">
          <Input
            maxLength={255}
            type={"text"}
            required
            label={"Label Bundle"}
            onChangeHandler={(e) => {
              setLabel(e.target.value);
            }}
            value={label}
            placeholder={"Enter label"}
            name={"label"}
            form={`form-doc-bundle-${object ? object.id : randomNumberForId}`}
          />
        </div>
        <div className="form-group">
          <Label name={"Doc Class"} />
          <SelectAnotherTry
            // classNamePrefix="Select"
            // className="pl-3 pr-3"
            options={classes}
            onChange={onDocClassChange}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            ref={form}
            // className="mb-2"
            id={`form-doc-bundle-${object ? object.id : randomNumberForId}`}
            onSubmit={handleSubmit}
          >
            <ButtonNew
              wide
              primary
              loading={isLoading}
              disabled={isLoading || label === "" || docClass === ""}
              // clickHandler={handleSubmit}
            >
              Save
            </ButtonNew>
          </form>
          {/*<ButtonNew*/}
          {/*  wide*/}
          {/*  danger*/}
          {/*  // loading={isLoading}*/}
          {/*  disabled={isLoading}*/}
          {/*  clickHandler={onClickCloseButtonHandler}*/}
          {/*>*/}
          {/*  Cancel*/}
          {/*</ButtonNew>*/}
        </div>
      </div>
    </div>
  );
};
export default NewBundleForm;
