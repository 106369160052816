import PropTypes from "prop-types";

/**
 * Check unique props
 * @param props
 * @param propName
 * @param componentName
 * @param uniqueProps
 * @returns {Error|void}
 */
const uniquePropsCheck = (props, propName, componentName, uniqueProps) => {
  if (
    (props.primary &&
      !props.secondary &&
      !props.tertiary &&
      !props.danger &&
      !props.customStyle) ||
    (!props.primary &&
      props.secondary &&
      !props.tertiary &&
      !props.danger &&
      !props.customStyle) ||
    (!props.primary &&
      !props.secondary &&
      props.tertiary &&
      !props.danger &&
      !props.customStyle) ||
    (!props.primary &&
      !props.secondary &&
      !props.tertiary &&
      props.danger &&
      !props.customStyle) ||
    (!props.primary &&
      !props.secondary &&
      !props.tertiary &&
      !props.danger &&
      props.customStyle)
  ) {
    return PropTypes.checkPropTypes(
      uniqueProps,
      props,
      props[propName],
      componentName
    );
  } else if (
    !props.primary &&
    !props.secondary &&
    !props.tertiary &&
    !props.danger &&
    !props.customStyle
  ) {
    PropTypes.resetWarningCache();
    return new Error(
      `Must set one of props: primary, secondary, danger or customStyle in \`${componentName}\``
    );
  }
  // TODO: Does I need this here?
  PropTypes.resetWarningCache();
  return new Error(
    `\`${componentName}\` only accept one of props: primary, secondary, danger or customStyle.`
  );
};

export default uniquePropsCheck;
