import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const AddCardFormStyled = styled.form`
  border-radius: 4px;
  max-width: 570px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 25px;
`;

export default AddCardFormStyled;
