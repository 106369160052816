import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  getBundles,
  getDocument,
  updateDocument,
  uploadDocumentFile,
} from "../../../containers/Auth/auth";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { theme } from "../../../styled-components/Theme/Theme";
import styled from "styled-components/macro";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { format, parseISO } from "date-fns";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import Input from "../../Input";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import UploadSingleFileInput from "../../inputs/UploadSingleFileInput";
import { maxFileSize } from "../../../utils";
import PanelParties from "../CasesForms/PanelParties";
import {
  criminalPartyRoles,
  partyRoles,
} from "../../../containers/BaseApp/Cases/partyTypesAndRoles";
import { groupPartiesByRoles } from "../ApplicationsHubForms/RespondentSidebar";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import PulseLoader from "react-spinners/PulseLoader";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;

const CaseDocProperties = ({ caseObject, callback, caseID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBundles, setIsLoadingBundles] = useState(true);
  const [bundlesOptionsList, setBundlesOptionsList] = useState([]);
  const [selectedBundlesList, setSelectedBundlesList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const document = useSelector((state) => state.rightSideBar.editableObject);
  const [groupedPartiesByRoleObject, setGroupedPartiesByRoleObject] = useState(
    {}
  );
  const updateObject = (resp) => {
    let obj = { ...document, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };

  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const form = useRef(null);
  const onChangeDateHandler = (date) => {
    updateObject({ date });
  };
  useEffect(() => {
    let roles =
      caseObject.new_case_type === "Criminal" ? criminalPartyRoles : partyRoles;
    const respondentPartiesRolesList = roles.map((party) => party.value);
    const filteredRespondentPartiesFromCase = caseObject.parties.filter(
      (party) => respondentPartiesRolesList.indexOf(party.role) !== -1
    );
    const groupedObject = groupPartiesByRoles(
      filteredRespondentPartiesFromCase
    );
    setGroupedPartiesByRoleObject(groupedObject);
  }, [document.parties]);
  const loadBundles = async () => {
    if (document.bundles.length) {
      setSelectedBundlesList(
        document.bundles.map((bundle) => ({
          label: bundle.label,
          value: bundle.id,
        }))
      );
    }
    const resp = await getBundles(dispatch, caseID);
    if (resp) {
      setBundlesOptionsList(
        resp
          .filter((bundle) => bundle.class_name === document.document_type)
          .map((bundle) => ({ label: bundle.label, value: bundle.id }))
      );
    }
    setIsLoadingBundles(false);
    setIsLoading(false);
  };
  const onDocChange = (e) => {
    updateObject({ name: e.target.value });
  };
  const onClickAddHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: document,
      content: (
        <PanelParties
          caseRoles={true}
          returnData={updateObject}
          savedPartiesList={document.parties}
          applicationPaperObject={document}
          caseObject={caseObject}
          routeParams={{ caseId: caseID }}
        />
      ),
      swapContent: {
        content: (
          <CaseDocProperties
            callback={callback}
            caseObject={caseObject}
            caseID={caseID}
          />
        ),
        title: "Doc Properties",
      },
      title: "Select Parties",
    });
  };
  const onClickEditOnTrialHubHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${caseID}/applications-hub/edit/${document.classes.application.fake_id}`
        : `/admin/all-cases/${caseID}/applications-hub/edit/${document.classes.application.fake_id}`,
      {
        applicationWorkflowStage: 1,
        selectedObject: document.classes,
      }
    );
  };
  useEffect(() => {
    loadBundles();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      let data = {
        name: document.name,
        date: document.date ? moment(document.date).format("YYYY-MM-DD") : "",
        parties: JSON.stringify(document.parties),
        bundles: JSON.stringify(document.bundles),
      };
      let formData = new FormData();
      let reload = false;
      if (document.file.size > 0) {
        formData.append("file", document.file);
        reload = true;
      }
      Object.keys(data).forEach((v) => {
        formData.append(v, data[v]);
      });
      const resp = await uploadDocumentFile(
        document.id,
        caseID,
        dispatch,
        formData
      );
      callback(resp);
      if (reload) {
        window.location.reload();
      }
      setIsLoading(false);
      dispatch({
        type: HIDE_RIGHT_SIDE_BAR,
      });
    }
  };

  const onBundleChange = (select) => {
    setSelectedBundlesList(select);
    if (select) {
      updateObject({
        bundles: select.map((bundle) => ({
          id: bundle.value,
          label: bundle.label,
        })),
      });
    } else {
      updateObject({
        bundles: [],
      });
    }
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group d-flex mb-4 pr-3">
          {isLoadingBundles ? (
            <div className="d-flex justify-content-center w-100">
              <PulseLoader size={7} color={theme.colors.blue} />
            </div>
          ) : (
            <SelectAnotherTry
              label="Assign to bundle"
              options={bundlesOptionsList}
              isClearable={false}
              isMulti
              placeholder={"Select bundle"}
              value={selectedBundlesList}
              defaultValue={
                selectedBundlesList && selectedBundlesList.length
                  ? bundlesOptionsList.filter((v) =>
                      selectedBundlesList.find((b) => b.id === v.value)
                    )
                  : []
              }
              reactSelectStyles={{
                control: (provided) => ({
                  ...provided,
                  height: "unset !important",
                  minHeight: "42px !important",
                  borderRadius: "21px !important",
                }),
              }}
              onChange={onBundleChange}
            />
          )}
        </div>
        <Separator />
        <div className="form-group">
          <Input
            name="label"
            type="text"
            value={document.name}
            placeholder="Enter doc label"
            label="Doc Label"
            onChange={onDocChange}
            maxLength={125}
          />
        </div>
        <div className="form-group">
          <CustomReactDatepicker
            selected={
              document.date
                ? typeof document.date === "object"
                  ? document.date
                  : parseISO(document.date)
                : null
            }
            popperPlacement="bottom-end"
            onChange={(date) => onChangeDateHandler(date)}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            label="Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
        <div className="form-group">
          <UploadSingleFileInput
            label="Upload File"
            file={
              typeof document.file !== "object"
                ? new File([""], document.file ?? "")
                : document.file
            }
            setFile={(file) => {
              updateObject({ file });
            }}
            validTypes={[
              "image/jpeg",
              "image/jpg",
              "image/png",
              "application/pdf",
            ]}
            maxFileSize={maxFileSize}
          />
        </div>
        <Separator />
        <div className="form-group">
          <Label
            name={"Parties"}
            button={
              <ListActionButton
                clickHandler={onClickAddHandler}
                type={"edit"}
              />
            }
          />
          {Object.keys(groupedPartiesByRoleObject).length
            ? Object.keys(groupedPartiesByRoleObject)
                .filter((role) => {
                  return (
                    document.parties.filter((p) => {
                      return groupedPartiesByRoleObject[role].find(
                        (v) => p.id === v.id
                      );
                    }).length > 0
                  );
                })
                .map((role) => {
                  return (
                    <Fragment>
                      <div className="container-fluid">
                        <Bold14Font>{role}: </Bold14Font>
                        {document.parties
                          .filter((p) => {
                            return groupedPartiesByRoleObject[role].find(
                              (v) => p.id === v.id
                            );
                          })
                          .map((party, index) => {
                            return (
                              <Fragment>
                                <Medium14Font
                                  textColor={theme.colors.darkOpacity}
                                >
                                  {party.name}
                                </Medium14Font>
                                {document.parties.filter((p) => {
                                  return groupedPartiesByRoleObject[role].find(
                                    (v) => p.id === v.id
                                  );
                                }).length -
                                  1 !==
                                index
                                  ? ", "
                                  : null}
                              </Fragment>
                            );
                          })}
                      </div>
                    </Fragment>
                  );
                })
            : null}
        </div>
      </div>
      <div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id={``} ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CaseDocProperties;
