import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { route } from "../../routes";
import { redirectUserToAppropriatePage } from "../../containers/Auth/utils";
import FullScreenBarLoader from "../FullScreenBarLoader";

const PrivateRoute = ({ children, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((state) => state.auth);
  const requestedUrl = useSelector((state) => state.app.requestedUrl);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const tryRedirect = async () => {
      await redirectUserToAppropriatePage(
        auth.isAuth,
        history,
        dispatch,
        null,
        requestedUrl
      );
      setIsLoading(false);
    };
    tryRedirect();
  }, []);

  if (isLoading) {
    return <FullScreenBarLoader />;
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        auth.isAuth && auth.user
          ? children
          : history.push(route.auth.signIn, { from: location })
      }
    />
  );
};

export default PrivateRoute;
