import React, { useEffect, useRef, useState } from "react";
import ConfirmEmailFormWrapperStyled from "../ConfirmEmailForm/styled/ConfirmEmailFormWrapperStyled";
import { Bold30Font } from "../../Fonts/Fonts";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import RoundInput from "../../inputs/RoundInput";
import Button from "../../buttons/Button/Button";
import { useDispatch } from "react-redux";

export const TwoFAForm = ({
  count_,
  makeRequest,
  isSubmitting,
  cancelFunction,
  resendFunction,
  resendData,
}) => {
  const [digits, setDigits] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const dispatch = useDispatch();
  const [verificationCode, setVerificationCode] = useState({ code: "" });
  const [timer, setTimer] = useState(null);
  const [count, setCount] = useState(count_);
  let refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const onInputFocusHandler = (event) => {
    event.persist();
    let k = Object.keys(digits).indexOf(event.target.name);
    if (k !== 0 && refs[k - 1].value === "") {
      refs[0].focus();
    }
  };
  useEffect(() => {
    if (verificationCode.code.length === 4) {
      let code = verificationCode.code;
      makeRequest(code).then((resp) => {
        setDigits({
          first: "",
          second: "",
          third: "",
          fourth: "",
        });
        setVerificationCode({ code: "" });
      });
    }
  }, [verificationCode.code]);
  useEffect(() => {
    setVerificationCode({
      code: digits.first + digits.second + digits.third + digits.fourth,
    });
  }, [digits]);
  const onInputChangeHandler = (event) => {
    event.persist();
    // Can write\paste only numbers
    let reg = /[\d]{1,4}/;

    if (!reg.test(event.target.value)) {
      return;
    }
    if (event.target.value.length === 2) {
      event.target.value = event.target.value[1];
    }
    if (event.target.value.length === 1) {
      setDigits((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      let k = Object.keys(digits).indexOf(event.target.name) + 1;
      if (k < 4) {
        refs[Object.keys(digits).indexOf(event.target.name) + 1].focus();
      } else {
        refs[Object.keys(digits).indexOf(event.target.name)].blur();
      }
    } else if (event.target.value.length === 4) {
      setDigits((prevState) => ({
        first: event.target.value[0],
        second: event.target.value[1],
        third: event.target.value[2],
        fourth: event.target.value[3],
      }));
    }
  };
  const onKeyDownHandler = (event) => {
    event.persist();
    if (event.keyCode === 8) {
      let k = Object.keys(digits).indexOf(event.target.name);
      setDigits((prevState) => ({
        ...prevState,
        [event.target.name]: "",
      }));

      if (k !== 0 && refs[k - 1].value !== "") {
        refs[k - 1].focus();
      }
    }
  };
  const resetTimer = () => {
    setCount(count_);
    const int_ = setInterval(() => {
      setCount((prevState) => {
        if (prevState === 0) {
          clearInterval(int_);
          setTimer(null);
        } else {
          let st = prevState - 1;
          return st;
        }
      });
    }, 1000);
    setTimer(int_);
  };
  useEffect(() => {
    resetTimer();
  }, []);
  const resendHandler = async (event) => {
    event.preventDefault();
    resetTimer();
    let resp = await resendFunction(dispatch, resendData);
    if (!resp) {
      clearInterval(timer);
      setCount(0);
    }
  };
  return (
    <ConfirmEmailFormWrapperStyled>
      <div className="row my-5">
        <div className="col d-flex flex-column align-items-center">
          <Bold30Font as="p">Verification</Bold30Font>
          <Medium14Font
            as="p"
            textColor={theme.colors.darkOpacity}
            className="text-center"
          >
            Enter the code we just sent to your phone
          </Medium14Font>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column align-items-center">
          <div
            className="form-group d-flex justify-content-between mb-5"
            style={{ minWidth: "270px" }}
          >
            <RoundInput
              name="first"
              getRef={(ref) => {
                refs[0] = ref;
              }}
              value={digits.first}
              onFocus={onInputFocusHandler}
              onChange={onInputChangeHandler}
              required
              autoComplete="off"
              autoFocus
              onKeyDown={onKeyDownHandler}
            />
            <RoundInput
              name="second"
              getRef={(ref) => {
                refs[1] = ref;
              }}
              value={digits.second}
              onFocus={onInputFocusHandler}
              onChange={onInputChangeHandler}
              required
              autoComplete="off"
              onKeyDown={onKeyDownHandler}
            />
            <RoundInput
              name="third"
              getRef={(ref) => {
                refs[2] = ref;
              }}
              value={digits.third}
              onFocus={onInputFocusHandler}
              onChange={onInputChangeHandler}
              required
              autoComplete="off"
              onKeyDown={onKeyDownHandler}
            />
            <RoundInput
              name="fourth"
              id="fourth"
              getRef={(ref) => {
                refs[3] = ref;
              }}
              value={digits.fourth}
              onFocus={onInputFocusHandler}
              onChange={onInputChangeHandler}
              required
              autoComplete="off"
              onKeyDown={onKeyDownHandler}
            />
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col d-flex justify-content-between">
                <Button
                  danger
                  style={{ width: "45%" }}
                  type="submit"
                  clickHandler={(e) => {
                    if (e) {
                      e.preventDefault();
                    }
                    cancelFunction();
                    setDigits({
                      first: "",
                      second: "",
                      third: "",
                      fourth: "",
                    });
                    setVerificationCode({ code: "" });
                  }}
                >
                  Back
                </Button>
                <Button
                  primary
                  style={{ width: "45%" }}
                  type="submit"
                  clickHandler={(e) => {
                    if (e) {
                      e.preventDefault();
                    }
                    makeRequest(verificationCode.code);
                  }}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Confirm
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex flex-column align-items-center">
                <div className="d-flex align-items-center mt-3">
                  <Medium14Font>Didn’t receive code?</Medium14Font>
                  {timer ? (
                    <Bold14Font className="ml-2">{count}</Bold14Font>
                  ) : (
                    <Button
                      tertiary
                      clickHandler={resendHandler}
                      disabled={isSubmitting}
                    >
                      Resend
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConfirmEmailFormWrapperStyled>
  );
};
