import React from "react";

export const DragHandle = ({ fill, style = {}, className }) => (
  <svg
    width={8}
    height={16}
    viewBox="0 0 8 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      minWidth: 8,
      ...style,
    }}
  >
    <ellipse cx="1.2" cy="13.9275" rx="1.2" ry="1.11111" />
    <ellipse cx="6.79961" cy="13.9275" rx="1.2" ry="1.11111" />
    <ellipse cx="1.2" cy="8.00174" rx="1.2" ry="1.11111" />
    <ellipse cx="6.79961" cy="8.00174" rx="1.2" ry="1.11111" />
    <ellipse cx="1.2" cy="2.07595" rx="1.2" ry="1.11111" />
    <ellipse cx="6.79961" cy="2.07595" rx="1.2" ry="1.11111" />
  </svg>
);

export default DragHandle;
