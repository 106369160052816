import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageFooterEmblemWrapperStyled = styled.div`
  width: ${rem("36px")};

  @media (max-width: 575.98px) {
    width: 4.25rem;
  }
`;

export default LandingPageFooterEmblemWrapperStyled;
