import styled, { css } from "styled-components/macro";
import rem from "../../utils/rem";

const BaseFont = css`
  //margin: 0;
  font-family: "PT Root UI", sans-serif;
  ${({ lineHeight }) =>
    lineHeight ? `line-height: ${rem(`${lineHeight}px`)}` : null};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.dark};
`;

const BaseBoldFont = css`
  ${BaseFont};
  font-style: normal;
  font-weight: bold;
`;

const BaseMediumFont = css`
  ${BaseFont};
  font-style: normal;
  font-weight: 500;
  color: ${({ theme, textColor }) =>
    textColor ? textColor : theme.colors.darkOpacity};
`;

export const Bold90Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("90px")};
`;

export const Bold60Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("60px")};
`;

export const Bold30Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("30px")};

  @media (max-width: 575.98px) {
    font-size: 3.875rem;
  }
`;
export const Bold30FontHeader = styled.span`
  ${BaseBoldFont};
  font-size: 25px;

  @media (max-width: 575.98px) {
    font-size: 3.875rem;
  }
`;

export const Bold24Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("24px")};
  @media (max-width: 575.98px) {
    font-size: 4.125rem;
  }
`;

export const Bold18Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("18px")};

  @media (max-width: 575.98px) {
    font-size: 3.125rem;
  }
`;

export const Bold14Font = styled.span`
  ${BaseBoldFont};
  font-size: 14px;

  @media (max-width: 575.98px) {
    font-size: 2rem;
  }
`;

export const Bold10Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("10px")};
`;

export const Medium18Font = styled.span`
  ${BaseMediumFont};
  font-size: ${rem("18px")};
`;

export const Medium14Font = styled.span`
  ${BaseMediumFont};
  font-size: 14px;
  ${({color}) => {'color: ' + color}};
  @media (max-width: 575.98px) {
    font-size: 2.0rem;
  }
`;
