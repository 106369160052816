import React from "react";
import PropTypes from "prop-types";
import NoDocsIcon from "../../assets/img/svg/no_documents_icon.svg";
import { Bold18Font } from "../FontsNewComponent/Fonts";

const RestrictAccessScreen = ({ errorObject, props }) => {
  return (
    <div className="row">
      <div
        className="col d-flex flex-column justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div className="mb-4">
          <img src={NoDocsIcon} />
        </div>
        <div className="text-center" {...props}>
          <Bold18Font>
            {errorObject.error}
            {errorObject.email ? (
              <a href={`mailto:${errorObject.email}`}>{errorObject.email}</a>
            ) : null}
          </Bold18Font>
        </div>
      </div>
    </div>
  );
};

RestrictAccessScreen.propTypes = {
  errorObject: PropTypes.object.isRequired,
};

export default RestrictAccessScreen;
