import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LpHeaderStyled = styled.header`
  width: 100%;
  max-width: 1920px;
  height: ${rem(`82px`)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayOpacity};
  background: ${({ theme }) => theme.colors.dark};
  position: fixed;
  z-index: 80;

  @media (max-width: 575.98px) {
    height: 12rem;
  }
  @media (max-width: 767.98px) {
    height: 8rem;
  }
`;

export default LpHeaderStyled;
