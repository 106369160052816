import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";

import LeftSideBarAvatarButtonStyled from "./styled/LeftSideBarAvatarButtonStyled";
import LeftSideBarAvatarButtonArrowStyled from "./styled/LeftSideBarAvatarButtonArrowStyled";
import Avatar from "react-avatar";

import { Bold18Font } from "../../FontsNewComponent/Fonts";

const LeftSideBarAvatarButton = ({ avatar, theme, ...props }) => {
  const user = useSelector((state) => state.auth.user);
  const needUpdateAfterPhotoChanged = useSelector(
    (state) => state.auth.photoUpdated
  );
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, [needUpdateAfterPhotoChanged]);

  return (
    <LeftSideBarAvatarButtonStyled {...props}>
      <Bold18Font
        textColor={theme.colors.white}
        style={{ marginRight: "20px" }}
      >
        {user.name ? user.name : "NOT_AUTH"}
      </Bold18Font>
      <div>
        <Avatar
          size={46}
          round={"46px"}
          className="mr-2"
          name={user.name}
          src={user.photo}
        />
        <LeftSideBarAvatarButtonArrowStyled />
      </div>
    </LeftSideBarAvatarButtonStyled>
  );
};

export default withTheme(LeftSideBarAvatarButton);
