import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

/**
 * Minimal button height in px
 */
export const minButtonHeight = 42;

const ButtonStyled = styled.button.attrs({ className: "btn" })`
  /* Set min width 100% if prop wide true */
  ${({ wide }) => (wide ? "width: 100%" : null)};
  border-radius: 50vh;
  position: relative;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  //@media (max-width: 575.98px) {
  //  //height: 4.5rem;
  //  border-radius: 50vh;
  //}
`;

export default ButtonStyled;
