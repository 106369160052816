import styled, { css } from "styled-components/macro";
import rem from "../../utils/rem";

const BaseFont = css`
  //margin: 0;

  font-family: "PT Root UI", sans-serif;
  ${({ lineHeight }) =>
    lineHeight ? `line-height: ${rem(`${lineHeight}px`)}` : null};
  ${({ breakWord }) =>
  breakWord ? `overflow-wrap: break-word` : null};
    ${({ underline }) =>
  underline ? `text-decoration: underline` : null};
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.dark};
`;

export const BaseBoldFont = css`
  ${BaseFont};
  ${({ pinter, pointer }) => (pinter || pointer ? `cursor: pointer;` : null)};
  font-style: normal;
  font-weight: bold;
`;

export const BaseMediumFont = css`
  ${BaseFont};
  font-style: normal;
  font-weight: 500;
  ${({ pinter }) => (pinter ? `cursor: pointer;` : null)};
  color: ${({ theme, textColor }) =>
    textColor ? textColor : theme.colors.darkOpacity};
`;

export const Bold90Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("90px")};
`;

export const Bold60Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("60px")};
`;

export const Bold30Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("30px")};

  //@media (max-width: 575.98px) {
  //  font-size: 3.875rem;
  //}
`;

export const Bold24Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("24px")};
`;

export const Bold18Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("18px")};

  //@media (max-width: 575.98px) {
  //  font-size: 3.125rem;
  //}
`;

export const Bold16Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("16px")};

  //@media (max-width: 575.98px) {
  //  font-size: 3.125rem;
  //}
`;

export const Bold14Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("14px")};

  //@media (max-width: 575.98px) {
  //  font-size: 2rem;
  //}
`;

export const Bold10Font = styled.span`
  ${BaseBoldFont};
  font-size: ${rem("10px")};
`;

export const Medium30Font = styled.span`
  ${BaseMediumFont};
  font-size: ${rem("30px")};
`;

export const Medium18Font = styled.span`
  ${BaseMediumFont};
  font-size: ${rem("18px")};
`;

export const Medium18FontParagraph = styled.p`
  ${BaseMediumFont};
  font-size: ${rem("18px")};
`;

export const Medium14Font = styled.span`
  ${BaseMediumFont};
  ${({ pinter, pointer }) => (pinter || pointer  ? `cursor: pointer;` : null)};
  ${({ cross }) => (cross ? `text-decoration: line-through;` : null)};
  font-size: ${rem("14px")};

  //@media (max-width: 575.98px) {
  //  font-size: 2.5rem;
  //}
`;

