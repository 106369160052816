import React, { Fragment, useEffect, useMemo, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
  Medium30Font,
} from "../../../../components/FontsNewComponent/Fonts";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar/TrialHubSearchSortBar";
import { PulseLoader } from "react-spinners";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  generateDocChecklist,
  generateDocChronology,
  getCaseById,
  getEvidenceList,
} from "../../../Auth/auth";
import moment from "moment";
import CaseRowStyled from "../../../../components/baseAppComponents/AdminCaseTypes/styled/CaseRowStyled";
import Checkbox from "../../../../components/Checkbox";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "../../../../components/inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import { format } from "date-fns";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import NoRecords from "../../../../components/NoRecords";
import { pdf } from "@react-pdf/renderer";
import { PdfCoreBook } from "../../../../components/Pdf/PdfCoreBook";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import { setTagField, setTagFieldClean } from "../../../../utils/function";
import FlagRelevanceFilter from "../../../../components/FlagRelevanceFilter/FlagRelevanceFilter";
import flagRelevanceCheck from "../../../../components/FlagRelevanceFilter/flagRelevanceCheck";
import TrialHubFilterDropdown from "../../../../components/baseAppComponents/TrialHubFilterDropdown";
import { saveAs } from "file-saver";
import Sorting from "../../../../components/Sorting";

const dateFormatToCompare = "YYYYMMDD";

const dateFormat = "dd-MM-yyyy";

const dateToInt = (date) => {
  if (date !== null) {
    return parseInt(moment(date).format(dateFormatToCompare));
  }
  return 0;
};

const documentaryFilterOptionsList = [
  { label: "All", value: "All" },
  { label: "Documentary", value: true },
  { label: "Non-documentary", value: false },
];

const TableTh = styled.th`
  padding: 0 0 10px 12px;
`;

const EvidenceChecklist = ({ theme }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [dataToPdf, setDataToPdf] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // data states
  const [searchBarValue, setSearchBarValue] = useState("");
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [filterTypeOptionsList, setFilterTypeOptionsList] = useState([]);
  const [selectedFilterTypeOption, setSelectedFilterTypeOption] = useState(
    null
  );
  const [exporting, setExporting] = useState(false);
  const [sortCondition, setSortCondition] = useState(null);
  const [flagRelevance, setFlagRelevance] = useState([]);
  const [caseObject, setCaseObject] = useState(null);
  const [evidenceList, setEvidenceList] = useState([]);
  const [selectedObjectsToPDFList, setSelectedObjectsToPDFList] = useState([]);
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [selectedDocumentaryOption, setSelectedDocumentaryOption] = useState(
    null
  );

  // get data

  const saveData = (evidenceRespList, caseObject) => {
    const list = setTagFieldClean(setTagField(caseObject, evidenceRespList));
    setEvidenceList(list);
    setSelectedObjectsToPDFList(list);
    setIsSelectedAllRows(true);
  };

  const getDataFromAPI = async () => {
    const caseObjectResp = await getCaseById(id, dispatch);
    const evidenceRespList = await getEvidenceList(id, dispatch);
    if (caseObjectResp && evidenceRespList) {
      setCaseObject(caseObjectResp);
      saveData(evidenceRespList, caseObjectResp);
      // saveData(evidenceRespList);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  const filterTableRows = (value) => {
    let check = true;

    if (check && searchBarValue !== "") {
      check = value.label
        ? value.label.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
        : value.title.toLowerCase().indexOf(searchBarValue.toLowerCase()) !==
          -1;
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }

    // Filter flag relevance
    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }

    // filter by documentary
    if (
      check &&
      selectedDocumentaryOption &&
      selectedDocumentaryOption.value !== "All"
    ) {
      check = !!value.file === selectedDocumentaryOption.value;
    }
    return check;
  };
  const generatePdfData = (list) => {
    const data = list.filter(filterTableRows).sort(sort);
    return {
      headers: [
        {
          name: "id",
          label: "ID",
          width: "5%",
        },
        {
          name: "label",
          label: "Label",
          width: "10%",
        },
        {
          name: "type",
          label: "Type",
          width: "7.5%",
        },
        {
          name: "witnesses",
          label: "Witnesses",
          width: "20%",
        },
        {
          name: "date",
          label: "Date",
          width: "7.5%",
        },
        {
          name: "objectives",
          label: "Objectives",
          width: "20%",
        },
        {
          name: "reference",
          label: "REF",
          width: "10%",
        },
        {
          name: "tags",
          label: "TAGS",
          width: "20%",
        },
      ],
      rows: data.map((v) => {
        return {
          id: v.id,
          date: v.date ? moment(v.date).format("DD-MM-yyyy") : "",
          label: v.label ?? "",
          type: v.type ?? "",
          reference: v.reference ?? "",
          tags: v.tags_clean && v.tags_clean.length ? v.tags_clean : "",
          witnesses: v.witnesses.map((v_) => v_.name),
          objectives: v.objectives.map((v_, i) => `${i + 1}. ${v_.name}`),
        };
      }),
    };
  };
  // send data
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const blob = await pdf(
              <PdfCoreBook
                document_type={"Evidence Checklist"}
                caseName={caseObject.label}
                data={dataToPdf}
              />
            ).toBlob();
            saveAs(blob, `${caseObject.label} Evidence Checklist.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({ as_pdf }) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const data_to_doc = {
              data: dataToPdf,
              case_object: caseObject,
            };
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocChecklist(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            setExporting(false);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${caseObject.label} Evidence Checklist.${
                as_pdf ? "pdf" : "docx"
              }`
            );
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? "mr-2" : ""}`}
        primary
      >
        Export to {as_pdf ? "PDF" : "Docx"}
      </ButtonNew>
    );
  };
  useEffect(() => {
    setDataToPdf(generatePdfData(selectedObjectsToPDFList));
  }, [
    searchBarValue,
    sortCondition,
    flagRelevance,
    selectedObjectsToPDFList,
    selectedDocumentaryOption,
    datePickerStartDate,
    datePickerEndDate,
  ]);
  const ExportToPdf = useMemo(() => {
    return <DocExport as_pdf={true} />;
  }, [dataToPdf, caseObject, exporting]);
  const ExportToDoc = useMemo(() => {
    return <DocExport />;
  }, [dataToPdf, caseObject, exporting]);

  // button handlers

  const onClickClearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onClickClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  // input handlers

  const onChangeSearchBarValueHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [...prevState, item]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const onChangeHeaderCheckboxHandler = () => {
    if (isSelectedAllRows) {
      setIsSelectedAllRows(false);
      setSelectedObjectsToPDFList([]);
    } else {
      setIsSelectedAllRows(true);
      setSelectedObjectsToPDFList([...evidenceList]);
    }
  };

  const onChangeFlagRelevanceHandler = (options) => {
    setFlagRelevance(options);
  };

  const onChangeDocumentaryFilterHandler = (selectedOption) => {
    setSelectedDocumentaryOption(selectedOption);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  const changeSorting = (item) => {
    if (sortCondition && sortCondition.field === item) {
      if (sortCondition.direction === "desc") {
        setSortCondition(null);
      } else {
        setSortCondition({
          field: item,
          direction: "desc",
        });
      }
    } else {
      setSortCondition({
        field: item,
        direction: "asc",
      });
    }
  };
  const sortByDateTime = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      return moment(item_a[sortCondition.field] ?? "2000-01-01 00:00:00").diff(
        moment(item_b[sortCondition.field] ?? "2000-01-01 00:00:00")
      );
    } else {
      return 1;
    }
  };
  const sortDefault = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      return item_a[sortCondition.field] - item_b[sortCondition.field];
    } else {
      return 1;
    }
  };
  const sortAlphabetical = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      if (!item_a[sortCondition.field]) {
        item_a[sortCondition.field] = "";
      }
      if (!item_b[sortCondition.field]) {
        item_b[sortCondition.field] = "";
      }
      item_a[sortCondition.field] =
        typeof item_a[sortCondition.field] === "string"
          ? item_a[sortCondition.field].toLowerCase()
          : item_a[sortCondition.field].toString();
      item_b[sortCondition.field] =
        typeof item_b[sortCondition.field] === "string"
          ? item_b[sortCondition.field].toLowerCase()
          : item_b[sortCondition.field].toString();
      return item_a[sortCondition.field].localeCompare(
        item_b[sortCondition.field],
        undefined,
        {
          numeric: true,
          sensitivity: "base",
        }
      );
    }
    return 1;
  };
  const sort = (a, b) => {
    if (!sortCondition) {
      return 1;
    } else {
      if (
        [
          "label",
          "title",
          "name",
          "type",
          "document_type",
          "reference",
          "document_type_name",
          "status",
          "case_type",
          "doc_type",
          "new_case_type",
          "key_sections",
          "probative_status",
          "sections",
          "firm_name",
          "record_no",
          "jurisdiction",
          "citation",
        ].indexOf(sortCondition.field) !== -1
      ) {
        return sortAlphabetical(a, b);
      }
      if (
        ["date", "created_at", "updated_at"].indexOf(sortCondition.field) !== -1
      ) {
        return sortByDateTime(a, b);
      }
      return sortDefault(a, b);
    }
  };
  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs/trial-checklists/evidence-checklist`
        : `/admin/all-cases/${id}/trial-proofs/trial-checklists/evidence-checklist`,
      title: "Trial Checklists - Evidence Checklist",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-7 col-6">
          <Bold30Font style={{ marginRight: "16px" }}>
            {caseObject.label}
          </Bold30Font>
          <Medium30Font>Evidence Checklist</Medium30Font>
        </div>
        <div className="col-12 col-sm-5 col-6 d-flex justify-content-end align-items-center mb-3">
          {dataToPdf ? ExportToDoc : null}
          {dataToPdf ? ExportToPdf : null}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarInputName="searchbar"
            searchBarPlaceholder="Search"
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onChangeSearchBarValueHandler}
            clearSearchBarHandler={onClickClearSearchBarHandler}
          />
        </div>
      </div>
      <div className="row mb-1">
        <div className="col">Filter by:</div>
      </div>
      <div className="row mb-5">
        <FlagRelevanceFilter
          size={2}
          caseObject={caseObject}
          flagRelevance={flagRelevance}
          onFlagRelevanceChange={onChangeFlagRelevanceHandler}
        />
        <div className="col-lg-3 col-md-6 col-12 mb-1 mb-sm-0">
          <CustomReactDatepicker
            // selected={datePickerStartDate}
            customInput={
              <CustomDatepickerRangeDivInput
                startDate={
                  datePickerStartDate
                    ? format(datePickerStartDate, dateFormat)
                    : ""
                }
                endDate={
                  datePickerEndDate ? format(datePickerEndDate, dateFormat) : ""
                }
                clearDatesHandler={onClickClearDatesHandler}
              />
            }
            startDate={datePickerStartDate}
            endDate={datePickerEndDate}
            onChange={onChangeDatesHandler}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            selectsRange
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <TrialHubFilterDropdown
            controlWidth={"100%"}
            options={documentaryFilterOptionsList}
            value={selectedDocumentaryOption}
            onChange={onChangeDocumentaryFilterHandler}
            placeholder="Documentary filter"
            isSearchable={false}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {evidenceList.filter(filterTableRows).length ? (
            <div className="table-responsive">
              <table
                style={{ width: "100%" }}
                className="table table-borderless"
              >
                <thead>
                  <tr>
                    <TableTh style={{ width: "1%" }}>
                      <div className="d-flex align-items-center">
                        <Checkbox
                          checked={isSelectedAllRows}
                          onChange={onChangeHeaderCheckboxHandler}
                        />
                        <div
                          onClick={() => {
                            changeSorting("id");
                          }}
                          className="d-flex align-items-baseline"
                        >
                          <Bold14Font
                            pointer
                            style={{ color: theme.colors.darkOpacity }}
                          >
                            ID
                          </Bold14Font>
                          <Sorting
                            direction={
                              sortCondition && "id" === sortCondition.field
                                ? sortCondition.direction
                                : null
                            }
                          />
                        </div>
                      </div>
                    </TableTh>
                    <TableTh style={{ width: "1%" }}>
                      <div
                        onClick={() => {
                          changeSorting("label");
                        }}
                        className="d-flex align-items-baseline"
                      >
                        <Bold14Font
                          pointer
                          style={{ color: theme.colors.darkOpacity }}
                        >
                          LABEL
                        </Bold14Font>
                        <Sorting
                          direction={
                            sortCondition && "label" === sortCondition.field
                              ? sortCondition.direction
                              : null
                          }
                        />
                      </div>
                    </TableTh>
                    <TableTh style={{ width: "12.5%" }}>
                      <div
                        onClick={() => {
                          changeSorting("type");
                        }}
                        className="d-flex align-items-baseline"
                      >
                        <Bold14Font
                          pointer
                          style={{ color: theme.colors.darkOpacity }}
                        >
                          TYPE
                        </Bold14Font>
                        <Sorting
                          direction={
                            sortCondition && "type" === sortCondition.field
                              ? sortCondition.direction
                              : null
                          }
                        />
                      </div>
                    </TableTh>
                    <TableTh style={{ width: "24%" }}>
                      <Bold14Font style={{ color: theme.colors.darkOpacity }}>
                        WITNESSES
                      </Bold14Font>
                    </TableTh>
                    <TableTh style={{ width: "1%" }}>
                      <div
                        onClick={() => {
                          changeSorting("date");
                        }}
                        className="d-flex align-items-baseline"
                      >
                        <Bold14Font
                          pointer
                          style={{ color: theme.colors.darkOpacity }}
                        >
                          DATE
                        </Bold14Font>
                        <Sorting
                          direction={
                            sortCondition && "date" === sortCondition.field
                              ? sortCondition.direction
                              : null
                          }
                        />
                      </div>
                    </TableTh>
                    <TableTh style={{ width: "24%" }}>
                      <Bold14Font style={{ color: theme.colors.darkOpacity }}>
                        OBJECTIVES
                      </Bold14Font>
                    </TableTh>
                    <TableTh style={{ width: "12.5%" }}>
                      <div
                        onClick={() => {
                          changeSorting("reference");
                        }}
                        className="d-flex align-items-baseline 0"
                      >
                        <Bold14Font
                          pointer
                          style={{ color: theme.colors.darkOpacity }}
                        >
                          REF
                        </Bold14Font>
                        <Sorting
                          direction={
                            sortCondition && "reference" === sortCondition.field
                              ? sortCondition.direction
                              : null
                          }
                        />
                      </div>
                    </TableTh>
                    <TableTh
                      style={{ width: "24%" }}
                      // style={{ width: "470px" }}
                    >
                      <Bold14Font style={{ color: theme.colors.darkOpacity }}>
                        TAGS
                      </Bold14Font>
                    </TableTh>
                  </tr>
                </thead>
                <tbody>
                  {evidenceList
                    .filter(filterTableRows)
                    .sort(sort)
                    .map((el) => (
                      <Fragment key={el.id}>
                        <CaseRowStyled as="tr">
                          <td>
                            <div className="d-flex align-items-center">
                              <Checkbox
                                checked={
                                  selectedObjectsToPDFList.findIndex(
                                    (item) => item.id === el.id
                                  ) !== -1
                                }
                                onChange={() =>
                                  onChangeCheckBoxHandler(
                                    el,
                                    selectedObjectsToPDFList,
                                    setSelectedObjectsToPDFList
                                  )
                                }
                              />
                              <Medium14Font>{el.id}</Medium14Font>
                            </div>
                          </td>
                          <td>
                            <Medium14Font textColor={theme.colors.darkOpacity}>
                              {el.label}
                            </Medium14Font>
                          </td>
                          <td>
                            <div className="d-flex flex-column">
                              <Medium14Font>{el.type}</Medium14Font>
                              {el.subtype ? (
                                <LiStyled style={{ paddingLeft: "24px" }}>
                                  <Medium14Font>{el.subtype}</Medium14Font>
                                </LiStyled>
                              ) : null}
                            </div>
                          </td>
                          <td>
                            <ul>
                              {el.witnesses.map((witness, index) => (
                                <LiStyled key={index}>
                                  <Medium14Font>{witness.name}</Medium14Font>
                                </LiStyled>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <Medium14Font
                              style={{ whiteSpace: "nowrap" }}
                              textColor={theme.colors.darkOpacity}
                            >
                              {el.date
                                ? moment(el.date).format("DD-MM-yyyy")
                                : ""}
                            </Medium14Font>
                          </td>
                          <td>
                            <ul>
                              {el.objectives.map((objective, index) => (
                                <LiStyled key={index}>
                                  <Medium14Font>{objective.name}</Medium14Font>
                                </LiStyled>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <Medium14Font>{el.reference}</Medium14Font>
                          </td>
                          <td>
                            <ul
                            // className="d-flex flex-wrap"
                            >
                              {el.tags.map((tag, index) => (
                                <li key={index} style={{ marginRight: "4px" }}>
                                  {tag.element}
                                </li>
                              ))}
                            </ul>
                          </td>
                        </CaseRowStyled>
                        <tr style={{ height: "32px", border: "unset" }} />
                      </Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <NoRecords>Your Evidence and Events will appear here</NoRecords>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(EvidenceChecklist);
