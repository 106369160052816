import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageSignBtnsWrapperStyled = styled.div`
  max-width: ${rem("232px")};
  width: 100%;
  display: flex;
  justify-content: space-between;

  //@media (max-width: 767.98px) {
  //  max-width: 28rem;
  //}
  @media (max-width: 767.98px) {
    max-width: 26.5rem;
  }
`;

export default LandingPageSignBtnsWrapperStyled;
