import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import rem from "../../../utils/rem";

import { Route, Switch } from "react-router-dom";

import BaseAppGlobalStyles from "../../../styled-components/styles/BaseAppGlobalStyles";
import BaseAppLayoutStyled from "./styled/BaseAppLayoutStyled";

import BaseAppLayoutLeftSideBar from "./BaseAppLayoutLeftSideBar";
import BaseAppLayoutCentralPart from "./BaseAppLayoutCentralPart";
import RectangleButton from "../../buttons/RectangleButton";

import Logo from "../../Logo";

import {
  HIDE_RIGHT_SIDE_BAR,
  HIDE_RIGHT_SIDE_BAR_TWO,
  SET_FIRST_PAGE,
  SHOW_LEFT_SIDE_BAR,
  TOGGLE_LEFT_SIDE_BAR,
} from "../../../redux/types";
import Form from "../../forms/Form";
import RightSideStyled from "./styled/RightSideStyled";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import PageConfiguration from "../BreadCrumbs/PageConfiguration";
import { Medium14Font } from "../../Fonts/Fonts";
import useWindowSize from "../../../hooks/useWindowSize";
import BaseAppLayoutRightSideBar from "./BaseAppLayoutRightSideBar";
import BaseAppLayoutRightSideBarTwo from "./BaseAppLayoutRightSideBar/BaseAppLayoutRightSideBarTwo";

import NotebookSidebar from "@/components/Notebook/Sidebar";
import CaseLibrary from "@/components/Notebook/CaseLibrary";

/**
 * Menu hamburger button. Need send to central part as prop.
 * @param onClickHandler
 * @return {*}
 */
const menuHamburgerBtn = (onClickHandler) => (
  <div className="d-flex align-items-center">
    <RectangleButton
      lsbHamburgerBtn
      onClick={onClickHandler}
      style={{ marginRight: "20px" }}
    />
    <Logo dark className="d-xl-none" />
  </div>
);

const BaseAppLayout = () => {
  const dispatch = useDispatch();
  const showLeftSideBar = useSelector(
    (state) => state.leftSideBar.isSideBarPulled
  );
  const showRightSideBar = useSelector(
    (state) => state.rightSideBar.isSideBarPulled
  );
  const showRightSideBarTwo = useSelector(
    (state) => state.rightSideBar.isSideBarPulledTwo
  );

  const rightLeftSideBarAfterCloseAction = useSelector(
    (state) => state.rightSideBar.afterCloseAction
  );
  const rightSideBarCustomCloseAction = useSelector(
    (state) => state.rightSideBar.customCloseAction
  );
  const rightLeftSideBarUrl = useSelector((state) => state.rightSideBar.url);

  const toggleLeftSideBarHandler = () => {
    dispatch({ type: TOGGLE_LEFT_SIDE_BAR });
  };
  const hiderRightSideBarButton = () => {
    if (rightSideBarCustomCloseAction) {
      rightSideBarCustomCloseAction();
      if (rightLeftSideBarAfterCloseAction) {
        rightLeftSideBarAfterCloseAction();
      }
    } else {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      if (rightLeftSideBarAfterCloseAction) {
        rightLeftSideBarAfterCloseAction();
      }
    }
  };
  const hiderRightSideBarButtonTwo = () => {
    if (rightSideBarCustomCloseAction) {
      rightSideBarCustomCloseAction();
      if (rightLeftSideBarAfterCloseAction) {
        rightLeftSideBarAfterCloseAction();
      }
    } else {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR_TWO });
      if (rightLeftSideBarAfterCloseAction) {
        rightLeftSideBarAfterCloseAction();
      }
    }
  };

  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(
    () =>
      history.listen(() => {
        if (rightLeftSideBarUrl !== history.location.pathname) {
          if (!history.location.state || !history.location.state.unique_data) {
            hiderRightSideBarButton();
          }
        }
      }),
    [history]
  );

  useEffect(() => {
    dispatch({
      type: SET_FIRST_PAGE,
      payload: [
        {
          path: is_admin ? "/admin" : "/app",
          title: "TrialProofer",
        },
      ],
    });
  }, []);

  const pageConfig = [
    {
      path: is_admin ? "/admin" : "/app",
      title: "TrialProofer",
    },
  ];
  const { width } = useWindowSize();
  return (
    <Fragment>
      <BaseAppGlobalStyles
        sideBarOpened={showLeftSideBar || showRightSideBar}
      />
      {/*<PageConfiguration configArray={pageConfig}/>*/}
      <BaseAppLayoutStyled>
        <div className="container-fluid h-100">
          <div className="row h-100">
            {/* LSB */}
            <div className="col-md-3 col-xl-2 d-none d-xl-block">
              {showLeftSideBar ? <BaseAppLayoutLeftSideBar /> : null}
              <div
                className="d-none d-md-flex align-items-left m-3"
                style={{ cursor: "pointer" }}
              >
                {menuHamburgerBtn(toggleLeftSideBarHandler)}
                <div
                  onClick={() => {
                    history.push(
                      is_admin
                        ? route.admin.baseApp.app
                        : route.baseApp.dashboard
                    );
                  }}
                  style={width < 1435 ? { flexDirection: "column" } : null}
                  className={`align-items-center d-lg-flex d-md-none justify-content-center ${
                    is_admin ? "ml-1" : "ml-3"
                  }`}
                >
                  <Logo dark />{" "}
                  {is_admin ? (
                    <Medium14Font
                      className="d-block"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      / Admin
                    </Medium14Font>
                  ) : null}
                </div>
              </div>
              <Route
                exact
                path={`${route.baseApp.cases}/:id/notebooks/:notebook_id`}
              >
                <NotebookSidebar />
              </Route>
              {/*
              <Route exact path={`${route.baseApp.cases}/:id/notebook`}>
                <TagsPanel />
              </Route>
              */}
            </div>

            {/* Central */}
            <div className="col-12 col-xl-8">
              <BaseAppLayoutCentralPart
                menuHamburgerBtn={menuHamburgerBtn(toggleLeftSideBarHandler)}
              />
            </div>

            {/* RSB */}
            {/*{rightLeftSideBar ? (*/}
            {/*  <RightSideStyled className="col-md-3 col-xl-2 p-0 position-fixed">*/}
            {/*    <Form*/}
            {/*      headerStyle={{*/}
            {/*        paddingLeft: "15px",*/}
            {/*        paddingRight: "15px",*/}
            {/*        // overflow: "auto",*/}
            {/*      }}*/}
            {/*      withCloseButton={true}*/}
            {/*      handleCloseClick={hiderRightSideBarButton}*/}
            {/*      style={{*/}
            {/*        paddingBottom: 0,*/}
            {/*        marginBottom: 0,*/}
            {/*        maxHeight: "100vh",*/}
            {/*        // overflow: "auto",*/}
            {/*        overflowX: "hidden",*/}
            {/*        height: "100vh",*/}
            {/*      }}*/}
            {/*      title={rightLeftSideBarTitle}*/}
            {/*    >*/}
            {/*      {rightLeftSideBarContent}*/}
            {/*    </Form>*/}
            {/*  </RightSideStyled>*/}
            {/*) : null}*/}
            <div className="col-md-3 col-xl-2 d-none d-lg-block" />

            <Switch>
              <Route
                exact
                path={`${route.baseApp.cases}/:id/notebooks/:notebook_id`}
              >
                <CaseLibrary />
              </Route>
            </Switch>
            {showRightSideBar ? (
              <BaseAppLayoutRightSideBar
                withCloseButton
                handleCloseClick={hiderRightSideBarButton}
              />
            ) : null}
            {showRightSideBarTwo ? (
              <BaseAppLayoutRightSideBarTwo
                withCloseButton
                handleCloseClick={hiderRightSideBarButtonTwo}
              />
            ) : null}
          </div>
        </div>
      </BaseAppLayoutStyled>
    </Fragment>
  );
};

export default BaseAppLayout;
