import {
  AUTO_LOG_IN,
  RESET_AUTH_ACTION,
  RESET_SIGN_UP_FLOW,
  SAVE_USER_EMAIL,
  SET_AUTH_ACTION,
  SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET,
  SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  UPDATE_USER,
  UPDATE_USER_PHOTO,
  HIDE_COOKIE_MESSAGE,
} from "../types";
import {setCookie, getCookie} from "../../utils/function";

const initialState = {
  isAuth: false,
  action: null,
  photoUpdated: 0,
  user: {},
  cookie_message: !getCookie("acceptCookies"),
  permissions: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        isAuth: true,
        ...action.payload,
      };
    case HIDE_COOKIE_MESSAGE:
      setCookie("acceptCookies", true, 365);
      return {
        ...state,
        cookie_message: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case UPDATE_USER_PHOTO:
      return {
        ...state,
        photoUpdated: state.photoUpdated + 1,
        user: {
          ...state.user,
          photo: `${action.payload}?v=${Math.random()}`,
        },
      };

    case SIGN_IN:
      return {
        ...state,
        isAuth: true,
        ...action.payload,
      };

    case AUTO_LOG_IN:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
      };

    case SAVE_USER_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };

    case SET_AUTH_ACTION:
      return {
        ...state,
        action: action.payload,
      };
    case RESET_AUTH_ACTION:
      return {
        ...state,
        action: null,
      };

    case SIGN_OUT:
      return {
        ...initialState,
      };

    case RESET_SIGN_UP_FLOW:
      return {
        ...state,
        isAuth: false,
      };

    case SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET:
      return {
        ...state,
        isAuth: true,
      };

    case SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET:
      return {
        ...state,
        isAuth: false,
      };

    default:
      return state;
  }
};

export default authReducer;
