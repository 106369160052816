import React, { useContext, useState, useEffect } from "react";
import { NotebookContext, EditorContext } from "@/context";
import {
  Container,
  Heading,
  BackRow,
  BackButton,
  TagsContainer,
  TagsLabel,
  ChapterName,
} from "./styled";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";
import DragHandle from "@editor/components/DragHandle";

export default ({ showTags, setShowTags }) => {
  const { notebook } = useContext(NotebookContext);
  const { editor, content } = useContext(EditorContext);
  const { selectedChapter } = useContext(NotebookContext);
  const [tags, setTags] = useState([]);

  const scrollToTag = (pos) => {
    editor.commands.setTextSelection(pos + 1);
    editor.commands.focus();
    editor.commands.scrollIntoView({ behavior: "smooth" });

    // editor.chain().setTextSelection(pos).scrollIntoView().run();
  };

  useEffect(() => {
    if (editor && editor.view.state.doc) {
      let tags = [];
      editor.view.state.doc.nodesBetween(
        0,
        editor.view.state.doc.content.size,
        (node, pos) => {
          if (node.attrs.tagType) {
            tags.push({
              ...node.attrs,
              pos,
              active: node.type.name !== "creation",
            });
          }
        }
      );
      setTags(tags);
      if (tags.length > 0) {
        setShowTags(true);
      } else {
        setShowTags(false);
      }
    }
  }, [editor, content]);

  if (!notebook || !selectedChapter) return null;

  if (tags.length === 0) return null;
  if (!showTags) return null;

  return (
    <Container show={showTags}>
      <Heading
        onClick={() => setShowTags(false)}
        style={{ display: "flex", alignItems: "center" }}
      >
        <BackRow>
          <BackButton />
          <Bold14Font style={{ color: "#374AC2", cursor: "pointer" }}>
            Back to chapters
          </Bold14Font>
        </BackRow>
      </Heading>
      <ChapterName>{selectedChapter.name}</ChapterName>
      <TagsLabel>Tags</TagsLabel>
      <TagsContainer>
        {tags.map((tag, index) => {
          return (
            <Tag
              key={`${tag.tagType}-${tag.tagId}-index`}
              tag={tag}
              scrollToTag={scrollToTag}
              index={index}
            />
          );
        })}
      </TagsContainer>
    </Container>
  );
};

const Tag = ({ tag, scrollToTag, index }) => {
  return (
    <div
      className={`tag tag-${tag.tagType.toLowerCase()}`}
      onClick={() => {
        const offsetTop = document
          .getElementsByClassName("ProseMirror")[0]
          .getElementsByClassName("tag")[index].offsetTop;

        document
          .getElementsByClassName("ProseMirror")[0]
          .scrollTo({ top: offsetTop - 50, behavior: "smooth" });
        // document
        //   .getElementsByClassName("ProseMirror")[0]
        //   .getElementsByClassName("tag")
        //   [index].scrollIntoView({ behavior: "smooth" });
        // scrollToTag(tag.pos);
      }}
      style={{
        marginRight: 8,
        marginBottom: 12,
        opacity: tag.active ? 1 : 0.5,
      }}
    >
      <DragHandle
        className={`fill--${tag.tagType.toLowerCase()}`}
        // fill="blue"
        style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
      />
      <span>{tag.id}</span>
    </div>
  );
};
