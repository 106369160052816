import styled from "styled-components/macro";

const AssociationTableListElementStyled = styled.li`
  background: #f5f6fc;
  margin-right: 10px;
  margin-bottom: 4px;
  border: 1px solid #cfd3f0;
  border-radius: 4px;
  height: 24px;
  // height: auto;
  padding-left: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default AssociationTableListElementStyled;
