import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withTheme } from "styled-components/macro";

import { updatePassword } from "../../../containers/Auth/auth";
import modalErrorHandler from "../../Modal/modalErrorHandler";

import ConfirmEmailFormWrapperStyled from "../ConfirmEmailForm/styled/ConfirmEmailFormWrapperStyled";
import Input from "../../Input";
import Button from "../../buttons/Button/Button";

import { Bold30Font, Medium14Font } from "../../Fonts/Fonts";

import {
  MODAL_SUCCESS_PASSWORD_RESET,
  MODAL_SUCCESS_PASSWORD_RESET_ADMIN,
  SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET,
  SHOW_MODAL
} from "../../../redux/types";

const EnterNewPasswordForm = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordsObject, setPasswordsObject] = useState({});
  const dispatch = useDispatch();
  let form = useRef(null);

  const onInputChangeHandler = (event) => {
    event.persist();
    setPasswordsObject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!isLoading) {
      try {
        setIsLoading(true);
        let resp = await updatePassword(passwordsObject);
        if (resp.data && resp.data.is_admin) {
          dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS_PASSWORD_RESET_ADMIN });
        } else {
          dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS_PASSWORD_RESET });
        }
      } catch (e) {
        setIsLoading(false);
        modalErrorHandler(e, dispatch);
      }
    }
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <ConfirmEmailFormWrapperStyled>
          <div className="row my-5">
            <div className="col d-flex flex-column align-items-center">
              <Bold30Font as="p">Enter New Password</Bold30Font>
              <Medium14Font
                as="p"
                textColor={theme.colors.darkOpacity}
                className="text-center"
              >
                {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
              </Medium14Font>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <form
                className="w-100"
                onSubmit={onFormSubmitHandler}
                ref={(ref) => (form = ref)}
              >
                <div className="form-group d-flex justify-content-between">
                  <Input
                    name="password"
                    placeholder="Enter your new password"
                    label="New password"
                    type="password"
                    onChangeHandler={onInputChangeHandler}
                    minLength={8}
                    required
                  />
                </div>
                <div className="form-group d-flex justify-content-between mb-5">
                  <Input
                    name="password_confirmation"
                    placeholder="Confirm your new password"
                    label="Confirm password"
                    type="password"
                    onChangeHandler={onInputChangeHandler}
                    minLength={8}
                    required
                  />
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <Button
                        primary
                        wide
                        type="submit"
                        clickHandler={() => null}
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ConfirmEmailFormWrapperStyled>
      </div>
    </div>
  );
};

export default withTheme(EnterNewPasswordForm);
