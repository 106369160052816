import React, { useState, useContext, useRef, useEffect } from "react";
import { EditorContext } from "@/context";
import DragHandle from "@editor/components/DragHandle";
import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getTagType = (t) => {
  let tagType = t;

  // if (tagType === "application") tagType = "applications";
  // if (tagType === "issue") tagType = "issues";
  // if (tagType === "party") tagType = "parties";
  // if (tagType === "theme") tagType = "themes";
  // if (tagType === "topic") tagType = "topics";
  if (tagType === "causesofaction") tagType = "CauseOfAction";

  return tagType;
};

function useDragging(
  editor,
  tag,
  draggingItem,
  setDraggingItem,
  hoveredItem,
  setHoveredItem,

  setNewConnectionPos,
  setNewConnectionElement
) {
  const [isDragging, setIsDragging] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const ref = useRef(null);

  function onMouseMove(e) {
    if (!isDragging) return;
    setPos({
      x: e.x - ref.current.offsetWidth / 2,
      y: e.y - ref.current.offsetHeight / 2,
    });
    e.stopPropagation();
    e.preventDefault();

    const coordinates = editor.view.posAtCoords({
      left: e.x - ref.current.offsetWidth / 2,
      top: e.y - ref.current.offsetHeight / 2,
    });

    const nodeAtPos = editor.view.state.doc.nodeAt(
      editor.view.posAtCoords({
        left: e.clientX,
        top: e.clientY,
      }).pos
    );

    if (hoveredItem) {
      if (!nodeAtPos) {
        setHoveredItem(undefined);
      }

      if (
        nodeAtPos &&
        nodeAtPos.attrs.id !== tag.label &&
        nodeAtPos.attrs.tagType !== tag.type.toLowerCase()
      ) {
        setHoveredItem(undefined);
      }
    }

    if (
      nodeAtPos &&
      (nodeAtPos.type.name === "tag" ||
        nodeAtPos.type.name === "authority" ||
        nodeAtPos.type.name === "comment" ||
        nodeAtPos.type.name === "evidence" ||
        nodeAtPos.type.name === "exam" ||
        nodeAtPos.type.name === "objective" ||
        nodeAtPos.type.name === "party" ||
        nodeAtPos.type.name === "section" ||
        nodeAtPos.type.name === "topic" ||
        nodeAtPos.type.name === "witness")
    ) {
      const isSameElement =
        nodeAtPos.attrs.id === tag.label &&
        nodeAtPos.attrs.tagType === tag.type.toLowerCase();

      if (!isSameElement) {
        setHoveredItem(nodeAtPos.attrs);
        setNewConnectionPos(
          editor.view.posAtCoords({
            left: e.clientX,
            top: e.clientY,
          }).pos
        );
      }

      editor.chain().deleteSelection.run();
      // editor.chain().setTextSelection(undefined).run();
    } else {
      editor.chain().setTextSelection(coordinates.pos).run();
    }

    // .focus()
  }

  const setConnection = async (draggingItem) => {
    // if (hoveredItem.id == tag.id && hoveredItem.tagType === tag.type) {
    if (draggingItem) {
      const caseId = window.location.pathname.split("/")[3];
      const config = {
        headers: {
          Authorization: `Bearer ${await loadAccessToken()}`,
        },
      };

      let err;
      const canConnectRes = await axios
        .get(
          `/api/cases/${caseId}/canConnect?fromType=${capitalize(
            getTagType(draggingItem.type.toLowerCase())
          )}&fromId=${draggingItem.id}&toType=${capitalize(
            getTagType(hoveredItem.tagType)
          )}&toId=${hoveredItem.tagId}&id_case=${caseId}`,
          config
        )
        .catch((e) => {
          err = e;
        });

      if (err) {
        alert(`internal server error: ${err.message}`);
        setDraggingItem(undefined);
        setHoveredItem(undefined);
        return;
      }

      // console.log("canConnect");
      // console.log(canConnectRes);
      // console.log(canConnectRes.data.canConnect);

      if (canConnectRes && canConnectRes.data.canConnect) {
        const connectionRes = await axios
          .get(
            `/api/cases/${caseId}/connect?fromType=${capitalize(
              getTagType(draggingItem.type.toLowerCase())
            )}&fromId=${draggingItem.id}&toType=${capitalize(
              getTagType(hoveredItem.tagType)
            )}&toId=${hoveredItem.tagId}&id_case=${caseId}`,
            config
          )
          .catch((e) => {
            alert(
              `Server error when executing /api/cases/${caseId}/connect?fromType=${capitalize(
                getTagType(draggingItem.type.toLowerCase())
              )}&fromId=${draggingItem.id}&toType=${capitalize(
                getTagType(hoveredItem.tagType)
              )}&toId=${hoveredItem.tagId}`
            );
          });

        if (connectionRes.data.result === "success") {
          setNewConnectionElement(draggingItem);
          // setDraggingItem(undefined);
          // setHoveredItem(undefined);
          // setShowConnection(true);
        }
      } else {
        alert(
          `Sorry you can't connect [${capitalize(
            draggingItem.type.toLowerCase()
          )}] to [${capitalize(hoveredItem.tagType)}]`
        );
        // setDraggingItem(undefined);
        // setHoveredItem(undefined);
      }
    }
    setDraggingItem(undefined);
    setHoveredItem(undefined);
    // } else {
    // alert("else");
    // }
  };

  function onMouseUp(e) {
    setIsDragging(false);
    e.stopPropagation();
    e.preventDefault();

    if (hoveredItem && draggingItem) {
      setConnection(draggingItem);

      setDraggingItem(undefined);
      setHoveredItem(undefined);
      return;
    }

    const editorRect = document
      .getElementsByClassName("ProseMirror")[0]
      .getBoundingClientRect();

    const clickCoords = {
      x: e.x,
      y: e.y,
    };

    const isOverEditor =
      clickCoords.x > editorRect.left &&
      clickCoords.y > editorRect.top &&
      clickCoords.x < editorRect.right &&
      clickCoords.y < editorRect.bottom;

    if (isOverEditor) {
      // also if not dropping over hovered other tag
      //
      // else: make a connection

      editor.commands.insertContent(
        ` <tag data-id="${
          tag.label
        }" data-tagType=${tag.type.toLowerCase()} data-tagId=${
          tag.id
        } className={tag tag-${tag.type.toLowerCase()}}>${tag.label}</tag> `,
        {
          updateSelection: true,
          parseOptions: {
            preserveWhitespace: true,
          },
        }
      );

      editor.commands.focus();
    }
    setDraggingItem(undefined);
    setHoveredItem(undefined);
  }

  function onMouseDown(e) {
    if (e.button !== 0) return;
    setIsDragging(true);
    setDraggingItem(tag);

    setPos({
      x: e.x - ref.current.offsetWidth / 2,
      y: e.y - ref.current.offsetHeight / 2,
    });

    // setConnectedElement(undefined);

    e.stopPropagation();
    e.preventDefault();
  }

  // When the element mounts, attach an mousedown listener
  useEffect(() => {
    ref.current.addEventListener("mousedown", onMouseDown);

    return () => {
      ref.current.removeEventListener("mousedown", onMouseDown);
    };
  }, [ref.current]);

  // Everytime the isDragging state changes, assign or remove
  // the corresponding mousemove and mouseup handlers
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mouseup", onMouseUp);
      document.addEventListener("mousemove", onMouseMove);
    } else {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    }
    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [isDragging, hoveredItem]);

  return [ref, pos.x, pos.y, isDragging];
}

const CaseLibraryTag = ({ tag }) => {
  const {
    editor,
    draggingItem,
    setDraggingItem,
    hoveredItem,
    setHoveredItem,
    setNewConnectionPos,
    setNewConnectionElement,
  } = useContext(EditorContext);
  const [ref, x, y, isDragging] = useDragging(
    editor,
    tag,
    draggingItem,
    setDraggingItem,
    hoveredItem,
    setHoveredItem,
    setNewConnectionPos,
    setNewConnectionElement
  );

  return (
    <>
      {/* here is just a copy when we are dragging */}
      {isDragging && (
        <div
          className={`tag tag-${tag.type && tag.type.toLowerCase()}`}
          style={{
            marginRight: 8,
            marginBottom: 12,
          }}
        >
          <DragHandle
            className={`fill--${tag.type && tag.type.toLowerCase()}`}
            // fill="blue"
            style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
          />
          {tag.label}
        </div>
      )}
      <div
        className={`tag tag-${tag.type && tag.type.toLowerCase()}`}
        draggable="true"
        ref={ref}
        onDrop={() => {}}
        onDragEnd={() => {}}
        style={
          isDragging
            ? {
                position: "fixed",
                // width: 50,
                // height: 50,
                // background: isDragging ? "blue" : "gray",
                left: x,
                top: y,
              }
            : {
                marginRight: 8,
                marginBottom: 12,
              }
        }
        onMouseDown={() => {
          if (editor.view.focused) {
            // if focused - insert at the focus position
            editor.commands.insertContent(
              `<tag data-id="${tag.label}" data-tagType=${
                tag.type && tag.type.toLowerCase()
              } data-tagId=${tag.id} className={tag tag-${
                tag.type && tag.type.toLowerCase()
              }}>${tag.label}</tag> `,
              {
                parseOptions: {
                  preserveWhitespace: true,
                },
              }
            );
            editor.commands.focus();
          } else {
            // else - insert at the end
            editor.commands.focus("end");
            editor.commands.insertContent(
              ` <tag data-id="${tag.label}" data-tagType=${
                tag.type && tag.type.toLowerCase()
              } data-tagId=${tag.id} className={tag tag-${
                tag.type && tag.type.toLowerCase()
              }}>${tag.label}</tag> `,
              {
                parseOptions: {
                  preserveWhitespace: true,
                },
              }
            );
          }
        }}
        onClick={() => {
          // set focused again
          editor.commands.focus();
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <DragHandle
            className={`fill--${tag.type && tag.type.toLowerCase()}`}
            // fill="blue"
            style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
          />
          <span>{tag.label}</span>
        </div>
      </div>
    </>
  );
};

export default CaseLibraryTag;
