import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Select, { components } from "react-select";

import arrowDownBlueIcon from "../../../assets/img3/svg/arrow-down-blue-icon.svg";

const TrialHubFilterDropdownStyled = styled(Select).attrs({
  className: "TrialHubDropdownFilter",
  classNamePrefix: "TrialHubDropdownFilter",
})`
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  .TrialHubDropdownFilter {
    &__control {
      height: 36px;
      width: ${({ controlWidth }) => controlWidth ? controlWidth : '200px'};
      border-radius: 50vh;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background: transparent;
      cursor: pointer;

      :hover,
      :focus,
      :active {
        border: 1px solid ${({ theme }) => theme.colors.blue};

        // Make indicators container change color
        .TrialHubDropdownFilter__indicator-separator {
        }
      }
    }

    &__value-container {
      padding-left: 20px;
    }

    &__indicator {
      width: 44px;
      height: 100%;
      border-radius: 0 50vh 50vh 0;
      background: ${({ theme }) => theme.colors.light};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__indicator-separator {
      margin: 0;
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.darkOpacity};
    }

    &__menu {
      border-radius: 21px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.light};
    }

    &__menu-list {
      max-height: 252px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 21px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.gray};
      }
    }

    &__option {
      cursor: pointer;

      :not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.gray};
      }

      :hover,
      :focus,
      :active {
        // TODO: add to theme?
        background: #f0f0fa;
      }
    }
  }
`;

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownBlueIcon} alt="" className="pr-1" />
    </components.DropdownIndicator>
  );
};

const TrialHubFilterDropdown = ({ ...props }) => {
  return (
    <TrialHubFilterDropdownStyled
      {...props}
      components={{ DropdownIndicator }}
    />
  );
};

TrialHubFilterDropdown.propTypes = {};

export default TrialHubFilterDropdown;
