import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CornerButtonStyled = styled.button.attrs({ className: "btn" })`
  min-width: ${rem(`126px`)};
  height: ${rem(`52px`)};
  border-radius: 20px 0 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.blueHover};
  }
`;

export default CornerButtonStyled;
