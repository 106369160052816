import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../../styled-components/Theme/Theme";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { useDispatch, useSelector } from "react-redux";
import {
  generateDocAuthorities,
  generateDocChecklist,
  getAllAuthoritiesList,
  getCaseById,
  updateCase,
} from "../../../Auth/auth";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium30Font,
} from "../../../../components/FontsNewComponent/Fonts";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar/TrialHubSearchSortBar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Table from "../../../../components/Table";
import NoRecords from "../../../../components/NoRecords";
import { pdf } from "@react-pdf/renderer";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import { setTagField, setTagFieldClean } from "../../../../utils/function";
import { PdfAuthorities } from "../../../../components/Pdf/PdfAuthorities";
import { saveAs } from "file-saver";
import LiStyled from "../../../../components/Table/Styled/LiStyled";

export const TRIAL_AUTHORITIES_FLOW = "TRIAL_PROOFS/TRIAL_AUTHORITIES_FLOW";
export const APPLICATION_AUTHORITIES_FLOW =
  "TRIAL_PROOFS/APPLICATION_AUTHORITIES_FLOW";

/**
 * Filter authorities based on flow
 * @param value
 * @param flow
 * @returns {boolean}
 */
const filter = (value, flow) => {
  let check = true;

  if (flow === TRIAL_AUTHORITIES_FLOW) {
    check = !value.application.length;
  } else if (flow === APPLICATION_AUTHORITIES_FLOW) {
    check = value.application.length;
  }

  return check;
};

/**
 * From authorities list create and return filtered grouped object,
 * based on flow and authorities and Jurisdictions.
 * @param list
 * @param flow
 * @returns {{}}
 */
const filterAndGroupAuthorities = (list, flow) => {
  // filter authorities based on flow and remove that don't have authority type AND Jurisdiction
  const filteredList = list.filter((el) => filter(el, flow));

  const uniqueAuthoritiesTypesList = [
    ...new Set(
      filteredList.map((el) => {
        if (el.type === null) {
          el.type = "Type not assigned";
        }
        return el.type;
      })
    ),
  ];

  const uniqueJurisdictionsList = [
    ...new Set(
      filteredList.map((el) => {
        if (el.jurisdiction === null) {
          el.jurisdiction = "Jurisdiction not assigned";
        }
        return el.jurisdiction;
      })
    ),
  ];

  const groupObject = {};
  // group by Authorities Type
  uniqueAuthoritiesTypesList.forEach((authorityType) => {
    if (!(authorityType in groupObject)) {
      groupObject[authorityType] = {};
    }
    uniqueJurisdictionsList.forEach((jurisdiction) => {
      if (!(jurisdiction in groupObject[authorityType])) {
        groupObject[authorityType][jurisdiction] = filteredList.filter(
          (authority) =>
            authority.type === authorityType &&
            authority.jurisdiction === jurisdiction
        );
      }
    });
  });
  return groupObject;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const createDraggableTablesList = (authoritiesGroupedObject, caseObject) => {
  const content = [];

  Object.keys(authoritiesGroupedObject).forEach((authorityType, index) =>
    Object.keys(authoritiesGroupedObject[authorityType]).forEach(
      (jurisdiction, idx) => {
        if (authoritiesGroupedObject[authorityType][jurisdiction].length) {
          content.push({
            name: `${authorityType} - ${jurisdiction}`,
            authorities: setTagField(caseObject,[
              ...authoritiesGroupedObject[authorityType][jurisdiction],
            ]),
          });
        }
      }
    )
  );

  return content;
};

const Authorities = ({ flow }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [dataToPdf, setDataToPdf] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // data states
  const [caseObject, setCaseObject] = useState(null);
  const [
    authoritiesOrderedTablesList,
    setAuthoritiesOrderedTablesList,
  ] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");

  const generatePdfData = (authorities) => {
    const data_ = [];
    authorities.map((v) => {
      let temp_obj = {
        headers: [
          {
            name: "id",
            label: "ID",
            width: "5%",
          },
          {
            name: "title",
            label: "Title",
            width: "15%",
          },
          {
            name: "citation",
            label: "Citation",
            width: "15%",
          },
          {
            name: "key_sections",
            label: "Sections",
            width: "15%",
          },
          {
            name: "status",
            label: "Status",
            width: "13%",
          },
        ],
        rows: [],
        title: v.name,
      };
      if (flow === APPLICATION_AUTHORITIES_FLOW) {
        temp_obj.headers.push({
          name: "application",
          label: "Application",
          width: "13%",
        });
        temp_obj.headers.push({
          name: "proofs",
          label: "Proofs and reliefs",
          width: "25%",
        });
      } else {
        temp_obj.headers.push({
          name: "relevance",
          label: "Relevance",
          width: "38%",
        });
      }
      setTagFieldClean(v.authorities)
        .filter(filter)
        .map((v) => {
          let row = {
            id: v.id,
            title: v.title,
            citation: v.citation,
            application:
              v.application && v.application.length
                ? v.application[0].label
                : "",
            status: v.status,
            key_sections: v.key_sections,
            relevance: v.tags_clean,
            proofs: [...v.reliefs, ...v.proofs].map((d) => d.name),
          };
          temp_obj.rows.push(row);
        });
      data_.push(temp_obj);
    });
    return data_;
  };
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const blob = await pdf(
              <PdfAuthorities
                document_type={`${
                  flow === TRIAL_AUTHORITIES_FLOW ? "Trial" : "Application"
                } Authorities`}
                caseName={caseObject.label}
                data={dataToPdf}
              />
            ).toBlob();
            saveAs(blob, `${caseObject.label} authorities.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({as_pdf}) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const data_to_doc = {
              data: dataToPdf,
              document_type: `${
                flow === TRIAL_AUTHORITIES_FLOW ? "Trial" : "Application"
              } Authorities`,
              case_object: caseObject,
            };
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocAuthorities(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            setExporting(false);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${caseObject.label} authorities.${as_pdf ? 'pdf' : 'docx'}`);
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? 'mr-2' : ''}`}
        primary
      >
        Export to {as_pdf ? 'PDF' : 'Docx'}
      </ButtonNew>
    );
  };
  useEffect(() => {
    setDataToPdf(generatePdfData(authoritiesOrderedTablesList));
  }, [
    authoritiesOrderedTablesList,
    searchBarValue,
    caseObject,
    searchBarValue,
  ]);
  const ExportToPdf = useMemo(() => {
    return <DocExport as_pdf={true} />;
  }, [dataToPdf, caseObject, searchBarValue, exporting]);
  const ExportToDoc = useMemo(() => {
    return <DocExport />;
  }, [dataToPdf, caseObject, searchBarValue, exporting]);

  const saveData = (resp, caseResp) => {
    const groupObject = filterAndGroupAuthorities(resp, flow);
    const list = createDraggableTablesList(groupObject, caseResp);
    setAuthoritiesOrderedTablesList([
      ...list.sort((a, b) => {
        if (caseResp && caseResp.ordering && caseResp.ordering.authorities) {
          return caseResp.ordering.authorities.indexOf(a.name) >
            caseResp.ordering.authorities.indexOf(b.name)
            ? 1
            : -1;
        } else {
          return 1;
        }
      }),
    ]);
  };

  const filter = (value) => {
    let check = true;

    if (searchBarValue !== "") {
      check =
        value.title.toLowerCase().indexOf(searchBarValue.toLowerCase()) !==
          -1 ||
        value.type.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1 ||
        value.jurisdiction
          .toLowerCase()
          .indexOf(searchBarValue.toLowerCase()) !== -1;
    }

    return check;
  };

  const getDataFromAPI = async () => {
    const caseObjectResp = await getCaseById(id, dispatch);
    const authoritiesListResp = await getAllAuthoritiesList(id, dispatch);
    if (caseObjectResp && authoritiesListResp) {
      setCaseObject(caseObjectResp);
      saveData(authoritiesListResp.filter(filter), caseObjectResp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      getDataFromAPI();
    }
  }, []);

  // send data

  // button handlers

  const onClickExportToPDFHandler = () => {};

  const onClickClearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  // input handlers

  const onChangeSearchBarValueHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  // DnD methods

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsReordered = reorder(
      authoritiesOrderedTablesList,
      result.source.index,
      result.destination.index
    );
    let ord = caseObject.ordering;
    let authorities = { authorities: itemsReordered.map((v) => v.name) };
    updateCase(id, dispatch, { ordering: { ...ord, ...authorities } });
    setAuthoritiesOrderedTablesList(itemsReordered);
  };

  // settings before loading

  const authoritiesTableSettings = {
    collapsed: false,
    sortable: true,
    canSort: true,
    actions: [],
    fields: [
      {
        name: "id",
        label: "ID",
        width: "5%",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id ?? object.id}</Medium14Font>;
        },
      },
      {
        name: "title",
        label: "TITLE",
        width: "15%",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.title}</Medium14Font>;
        },
      },
      {
        name: "citation",
        label: "CITATION",
        width: "15%",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.citation}</Medium14Font>;
        },
      },
      {
        name: "key_sections",
        label: "SECTIONS",
        width: "15%",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.key_sections}</Medium14Font>;
        },
      },
      {
        name: "status",
        label: "STATUS",
        width: "13%",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.status}</Medium14Font>;
        },
      },
    ],
  };
  if (flow === APPLICATION_AUTHORITIES_FLOW) {
    authoritiesTableSettings.fields.push({
      name: "application",
      label: "APPLICATION",
      width: "13%",
      renderer: (object) => {
        if (object.application && object.application.length) {
          return <Medium14Font>{object.application[0].label}</Medium14Font>;
        }
      },
    });
    authoritiesTableSettings.fields.push({
      name: "reliefs",
      label: "RELIEFS",
      renderer: (object) => {
        if (
          (object.reliefs && object.reliefs.length) ||
          (object.proofs && object.proofs.length)
        ) {
          return (
            <ul>
              {[...object.reliefs, ...object.proofs].map((el, index) => (
                <LiStyled key={index}>
                  <Medium14Font style={{ color: theme.colors.darkOpacity }}>
                    {el.name}
                  </Medium14Font>
                </LiStyled>
              ))}
            </ul>
          );
        }
      },
    });
  } else {
    authoritiesTableSettings.fields.push({
      name: "tags",
      label: "RELEVANCE",
      width: APPLICATION_AUTHORITIES_FLOW ? "25%" : "38%",
      renderer: (object) => {
        if (object.tags && object.tags.length) {
          return (
            <ul>
              {object.tags.map((tag, index) => {
                return <li key={index}>{tag.element}</li>;
              })}
            </ul>
          );
        }
      },
    });
  }
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  // settings after loading

  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs/court-documents/trial/authorities`
        : `/admin/all-cases/${id}/trial-proofs/court-documents/trial/authorities`,
      title: APPLICATION_AUTHORITIES_FLOW
        ? "Court Documents - Applications"
        : "Court Documents - Trial - Authorities",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-7">
          <Bold30Font style={{ marginRight: "16px" }}>
            {caseObject.label}
          </Bold30Font>
          <Medium30Font>
            {flow === APPLICATION_AUTHORITIES_FLOW ? "Application" : "Trial"}{" "}
            Authorities
          </Medium30Font>
        </div>
        <div className="col-12 col-sm-5 d-flex justify-content-end align-items-center mb-3">
          {ExportToDoc}
          {ExportToPdf}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarInputName="searchbar"
            searchBarPlaceholder="Search"
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onChangeSearchBarValueHandler}
            clearSearchBarHandler={onClickClearSearchBarHandler}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="container-fluid">
            {authoritiesOrderedTablesList.length ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="tables">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {authoritiesOrderedTablesList.map((el, index) =>
                        el.authorities.filter(filter).length ? (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Table
                                  settings={authoritiesTableSettings}
                                  data={el.authorities.filter(filter)}
                                  title={el.name}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : null
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <NoRecords>
                <Bold18Font textColor={"#0f122f"}>
                  Your Authorities will appear here
                </Bold18Font>
              </NoRecords>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Authorities.propTypes = {
  flow: PropTypes.oneOf([TRIAL_AUTHORITIES_FLOW, APPLICATION_AUTHORITIES_FLOW]),
};

export default Authorities;
