import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";

import TextAreaStyled from "./styled/TextAreaStyled";

import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";

// TODO: Change to this component in all places?

const TextArea = ({ label, without_max_len, customStyle, theme, ...props }) => {
  return (
    <label className="w-100">
      <div className="container-fluid">
        {label ? (
          <div className="row">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col">
            {without_max_len ? null : <div style={{height: 0}}><Medium14Font
              style={{
                color: theme.colors.darkOpacity,
                position: "relative",
                left: "calc(100% - 75px)",
                top: "5px",
              }}
            >
              Optional
            </Medium14Font></div>}
            <TextAreaStyled as={customStyle} maxLength={without_max_len? null : 300} {...props} />
            {without_max_len ? null :  <div style={{height: 0}}><Medium14Font
              style={{
                color: theme.colors.darkOpacity,
                position: "relative",
                left: "calc(100% - 70px)",
                bottom: "35px",
              }}
            >
              {props.value ? props.value.length : 0}/300
            </Medium14Font></div>}
          </div>
        </div>
      </div>
    </label>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  customStyle: PropTypes.object,
};

export default withTheme(TextArea);
