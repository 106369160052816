import { mergeAttributes, Node } from "@tiptap/core";
import { PluginKey } from "prosemirror-state";
import RegexSuggestion from "@editor/extensions/regex-suggestion";
import { ReactNodeViewRenderer } from "@tiptap/react";
import Tag from "@editor/components/Tag";

export const MentionPluginKey = new PluginKey("regex-mention");

export const Mention = Node.create({
  name: "tag",

  group: "inline",
  // selectable: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        var _a;
        return `${options.suggestion.char}${
          (_a = node.attrs.label) !== null && _a !== void 0 ? _a : node.attrs.id
        }`;
      },
      suggestion: {
        char: "",
        regexChar: "",
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props, state }) => {
          const match = props.id.toLowerCase();
          let replaceRange;

          let isMatching = true;
          let arrayOfWords = editor.state.selection.$from.nodeBefore.text
            .split(" ")
            .filter((i) => i !== "");
          let stringToReplace = "";

          let withSpace =
            editor.state.selection.$from.nodeBefore.text[
              editor.state.selection.$from.nodeBefore.text.length - 1
            ] === " ";

          arrayOfWords.reverse().forEach((word, index) => {
            if (isMatching) {
              // initial word

              // if match contains this phrase
              if (
                match.indexOf(
                  stringToReplace === ""
                    ? word.toLowerCase()
                    : `${word.toLowerCase()} ${stringToReplace}`
                ) > -1
              ) {
                // add word to this phrase

                if (stringToReplace === "") {
                  stringToReplace = word.toLowerCase();
                } else {
                  stringToReplace = `${word.toLowerCase()} ${stringToReplace}`;
                }
              } else {
                isMatching = false;
              }
            }
          });

          replaceRange = {
            to: range.to,
            from: range.to - stringToReplace.length - (withSpace ? 1 : 0),
          };

          var _a, _b;
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace =
            (_a =
              nodeAfter === null || nodeAfter === void 0
                ? void 0
                : nodeAfter.text) === null || _a === void 0
              ? void 0
              : _a.startsWith(" ");
          if (overrideSpace) {
            range.to += 1;
          }
          editor
            .chain()
            .focus()
            .insertContentAt(replaceRange, [
              {
                type: this.name,
                attrs: {
                  ...props,
                  tagType: props.type,
                  // tagId: props.id,
                },
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run();
          (_b = window.getSelection()) === null || _b === void 0
            ? void 0
            : _b.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);
          return allow;
        },
      },
    };
  },
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            "data-id": attributes.id,
          };
        },
      },
      tagType: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagType"),
        renderHTML: (attributes) => {
          if (!attributes.tagType) {
            return {};
          }
          return {
            "data-tagType": attributes.tagType,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }
          return {
            "data-label": attributes.label,
          };
        },
      },
      tagId: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagId"),
        renderHTML: (attributes) => {
          if (!attributes.tagId) {
            return {};
          }
          return {
            "data-tagId": attributes.tagId,
          };
        },
      },
    };
  },
  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;
          if (!empty) {
            return false;
          }
          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText(
                this.options.suggestion.char || "",
                pos,
                pos + node.nodeSize
              );
              return false;
            }
          });
          return isMention;
        }),
    };
  },
  addProseMirrorPlugins() {
    return [
      RegexSuggestion(
        Object.assign({ editor: this.editor }, this.options.suggestion)
      ),
    ];
  },

  parseHTML() {
    return [
      {
        tag: "tag",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["tag", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Tag);
  },
});
