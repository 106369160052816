import React from "react";

import EditObjectPanel from "./EditObjectPanel";
import EditTopic from "./EditTopic";
import EditExamLine from "./EditExamLine";
import EditObjective from "./EditObjective";
import EditWitness from "./EditWitness";
import EditEvidence from "./EditEvidence";
import EditAuthority from "./EditAuthority";
import EditPaper from "./EditPaper";
import EditParty from "./EditParty";
import EditKeySection from "./EditKeySection";

export default (props) => {
  let tagType = props.tagType.toLowerCase();

  if (tagType === "topic") {
    return <EditTopic {...props} />;
  }

  if (tagType === "examline") {
    return <EditExamLine {...props} />;
  }

  if (tagType === "objective") {
    return <EditObjective {...props} />;
  }

  if (tagType === "witness") {
    return <EditWitness {...props} />;
  }

  if (tagType === "evidence") {
    return <EditEvidence {...props} />;
  }

  if (tagType === "authority") {
    return <EditAuthority {...props} />;
  }

  if (tagType === "paper") {
    return <EditPaper {...props} />;
  }

  if (tagType === "party") {
    return <EditParty {...props} />;
  }

  if (tagType === "keysection") {
    return <EditKeySection {...props} />;
  }
  console.log(tagType);

  return <EditObjectPanel {...props} />;
};
