import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { listenForOutsideClick } from "@/hooks/onClickOutside";

import { useDispatch } from "react-redux";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "@/redux/types";

import { Bold14Font } from "@/components/FontsNewComponent/Fonts";
import Input from "@/components/InputNew/styled/InputStyled";
import Button from "@/components/buttons/Button/Button";

// import popupTriangle from "@/assets/img/notebook/popup-triangle.svg";
import popupTriangle from "@/assets/img/notebook/triangle-arrow.svg";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const DropDownMenu = ({
  close,
  item,
  editItem,
  removeItem,
  freestyle = false,

  listId,
}) => {
  const [showMenu, setShowMenu] = useState(true);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const [editValue, setEditValue] = useState(item.name);

  return (
    <>
      {showMenu && (
        <Menu
          close={close}
          item={item}
          editItem={editItem}
          removeItem={removeItem}
          freestyle={freestyle}
          showEditMenu={showEditMenu}
          setShowEditMenu={setShowEditMenu}
          setShowMenu={setShowMenu}
        />
      )}
      {showEditMenu && (
        <Overlay>
          <Modal>
            <Bold14Font>Chapter name</Bold14Font>
            <Input
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              style={{ marginBottom: 15, marginTop: 3 }}
              autoFocus
            />
            <Button
              type="submit"
              wide
              primary
              onClick={() => {
                let group;

                if (freestyle) {
                  // group = item.name;
                  group = listId;
                } else {
                  group = capitalize(item.type);
                }

                editItem(item.id, editValue, group, freestyle);
                close();
              }}
            >
              Save
            </Button>
          </Modal>
        </Overlay>
      )}
    </>
  );
};

const Menu = ({
  close,
  item,
  editItem,
  removeItem,
  freestyle = false,
  showEditMenu,
  setShowEditMenu,
  setShowMenu,
}) => {
  const [listening, setListening] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  useEffect(
    listenForOutsideClick(listening, setListening, menuRef, (e) => {
      close();
    })
  );

  const onDelete = () => {
    let group;

    if (freestyle) {
      group = item.name;
    } else {
      group = capitalize(item.type);
    }

    removeItem(item.id, group, freestyle);
  };

  const dispatch = useDispatch();

  return (
    <Container ref={menuRef} style={showEditMenu ? { display: "none" } : {}}>
      <List>
        <Option
          onClick={(e) => {
            setShowEditMenu(true);
            setShowMenu(false);
          }}
        >
          <span>Rename</span>
        </Option>
        <Option
          onClick={(e) => {
            setShowDeleteModal(true);

            dispatch({
              type: SAVE_MODAL_DATA,
              payload: "You won't be able to restore data",
              beforeCloseHandler: async () => {
                onDelete();
              },
            });
            dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
          }}
        >
          <span>Delete</span>
        </Option>
      </List>
      <img
        src={popupTriangle}
        alt=""
        style={{
          bottom: "-7.7px",
          position: "absolute",
          zIndex: 1,
          right: "7px",
          width: "29px",
        }}
      />
    </Container>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  padding: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

// style={{
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// }}

export default DropDownMenu;

export const Container = styled.div.attrs({
  className: "notebook-popup",
})`
  z-index: 10;
  position: absolute;
  display: flex;
  color: #374ac2;
  font-weight: 500;
  bottom: 38px;
  right: -8px;
`;

export const List = styled.div`
  width: 100px;
  background: white;
  border: 1px solid #dcdce6;
  border-radius: 4px;
  padding-bottom: 2px;
`;

export const Option = styled.div`
  padding: 16px 22px;
  cursor: pointer;
  min-width: 100%;
  :hover {
    background: rgba(55, 74, 194, 0.08);
  }
`;

export const SecondaryList = styled.div`
  position: absolute;
  width: 100px;
  min-width: 100px;
  right: -100px;

  background: white;
  border: 1px solid #dcdce6;
  border-radius: 4px;
`;
