import React from "react";
import { useHistory } from "react-router-dom";

import SubscriptionPageStyled from "./styled/SubscriptionPageStyled";
import SubscriptionPageBodyStyled from "./styled/SubscriptionPageBodyStyled";
import Button from "../buttons/Button/Button";
import LogoutButtonStyled from "../buttons/Button/customStyled/LogoutButtonStyled";

import logoDark from "../../assets/img3/svg/logo-green-dark-beta.svg";

import { Bold18Font } from "../Fonts/Fonts";
import { route } from "../../routes";

const SubscriptionPagesWrapper = ({ children }) => {
  const history = useHistory();

  return (
    <SubscriptionPageStyled className="d-flex justify-content-center p-2">
      <SubscriptionPageBodyStyled />
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <Button tertiary clickHandler={() => history.push(route.index)}>
              <img src={logoDark} alt="" className="img-fluid" />
            </Button>
            <Button
              customStyle={LogoutButtonStyled}
              clickHandler={() => history.push(route.auth.logOut)}
            >
              <Bold18Font style={{ color: "inherit" }}>Logout</Bold18Font>
            </Button>
          </div>
        </div>
        {children}
      </div>
    </SubscriptionPageStyled>
  );
};

export default SubscriptionPagesWrapper;
