import styled from "styled-components/macro";

const TertiaryButtonStyled = styled.button`
  // Get min height from base button component to calc border radius
  border-radius: unset;
  color: ${({theme})=>theme.colors.blue};

  :hover {
      color: ${({theme})=>theme.colors.blueHover};
  }
`;

export default TertiaryButtonStyled;
