import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";

import useWindowSize from "../../../hooks/useWindowSize";

import CasesSearchInput from "../CasesSearchFilterBar/CasesSearchInput";
import CasesDropdownSwitcher from "../CasesSearchFilterBar/CasesDropdownSwitcher";
import CasesDropdownFilter from "../CasesSearchFilterBar/CasesDropdownFilter";

import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";
import closeIcon from "../../../assets/img3/svg/close-black-icon.svg";

const order = [
  {
    value: 1,
    label: "ID - Asc.",
  },
  {
    value: 2,
    label: "ID - Desc.",
  },
  {
    value: 3,
    label: "Label -  a-Z",
  },
  {
    value: 4,
    label: "Label -  Z-a",
  },
];

const TrialHubSearchSortBar = ({
  searchBarValue,
  onSearchBarChangeHandler,
  searchBarPlaceholder,
  searchBarInputName,
  clearSearchBarHandler,
  filterTypesOptions,
  sortOptions,
  buttons,
  customInitialWidth,
  onSwitchTypeValueChangeHandler,
  switchTypeOptions,
  onFilterTypesValueChangeHandler,
  onFilterDatesAlphabetChangeHandler,
  theme,
  switchType,
  selectedSortOption,
}) => {
  const { width, height } = useWindowSize();
  const [twoRowLayout, setTwoRowLayout] = useState(false);
  const { path, url } = useRouteMatch();
  const isEvidencesPage = useRouteMatch(`${path}/evidence`);
  const isAuthoritiesPage = useRouteMatch(`${path}/authorities`);
  const isApplicationsCardViewPage =
    useRouteMatch("/app/cases/:id/applications-hub") ||
    useRouteMatch("/admin/all-cases/:id/applications-hub");
  const isChronologyPage =
    useRouteMatch(
      "/app/cases/:id/trial-proofs/court-documents/trial/chronology"
    ) ||
    useRouteMatch(
      "/admin/all-cases/:id/trial-proofs/court-documents/trial/chronology"
    );

  useEffect(() => {
    // TODO: Refactor this
    if (isAuthoritiesPage && isAuthoritiesPage.isExact) {
      if (width < 619) {
        setTwoRowLayout(true);
      } else {
        setTwoRowLayout(false);
      }
    } else if (isEvidencesPage && isEvidencesPage.isExact) {
      if (width < 619) {
        setTwoRowLayout(true);
      } else {
        setTwoRowLayout(false);
      }
    } else if (isChronologyPage && isChronologyPage.isExact) {
      if (width < 619) {
        setTwoRowLayout(true);
      } else {
        setTwoRowLayout(false);
      }
    }
  }, [width]);

  return (
    <div className={`row mb-4 w-100 ${width <= 768 ? 'pl-3' : null}`}>
      <div className={buttons ? `col-12 col-xl-7 col-lg-7 col-md-8 mb-2 pr-0 ${width <= 768 ? 'pl-0': null}` : "col-12"}>
        <div className="input-group position-relative">
          {switchType ? (
            <CasesDropdownSwitcher
              positionStart={true}
              styles={{
                container: (state) => ({
                  ...state,
                  width: "3.99%",
                }),
              }}
              placeholder="Sort by type"
              options={switchTypeOptions}
              onChange={onSwitchTypeValueChangeHandler}
            />
          ) : null}
          <CasesSearchInput
            positionStart={
              (!twoRowLayout &&
                !switchType &&
                ((isChronologyPage && isChronologyPage.isExact))) ||
              (isApplicationsCardViewPage && isApplicationsCardViewPage.isExact)
            }
            positionMiddle={switchType}
            style={{
              width: twoRowLayout
                ? "100%"
                : switchType
                ? "62%"
                : filterTypesOptions
                ? "65.99%"
                : "75.99%",
              marginBottom: twoRowLayout ? "0.5rem" : "0",
              background: `url(${magnifierIcon}) no-repeat left 20px top 13px, ${theme.colors.white}`,
            }}
            type="text"
            value={searchBarValue}
            onChange={onSearchBarChangeHandler}
            placeholder={searchBarPlaceholder}
            name={searchBarInputName}
          />
          <button
            className={`btn${searchBarValue ? "" : " d-none"}`}
            onClick={clearSearchBarHandler}
            style={{
              position: "absolute",
              right: twoRowLayout
                ? "5px"
                : filterTypesOptions
                ? "34.2%"
                : "24.43%",
              // top: twoRowLayout && !filterTypesOptions ? "5px" : null,
              top: width > 767.98 && width < 992 ? "5px" : "unset",
              zIndex: 100,
            }}
          >
            <img src={closeIcon} alt="" className="img-fluid" />
          </button>
          {(isApplicationsCardViewPage && isApplicationsCardViewPage.isExact) ||
          (isChronologyPage && isChronologyPage.isExact) ? (
            <div
              className="input-group-append"
              style={{
                width: twoRowLayout
                  ? "100%"
                  : filterTypesOptions
                  ? "34%"
                  : "22%",
                marginLeft: "0.1px",
              }}
            >
              {filterTypesOptions ? (
                <CasesDropdownFilter
                  positionStart={twoRowLayout}
                  positionMiddle={!twoRowLayout}
                  styles={{
                    container: (state) => ({
                      ...state,
                      width: "50%",
                    }),
                  }}
                  placeholder="Sort by type"
                  options={filterTypesOptions}
                  onChange={onFilterTypesValueChangeHandler}
                />
              ) : null}
              <CasesDropdownFilter
                positionEnd={filterTypesOptions || !twoRowLayout}
                styles={{
                  container: (state) => ({
                    ...state,
                    width: filterTypesOptions ? "50%" : "100%",
                  }),
                }}
                placeholder="Sort by"
                options={!sortOptions ? order : sortOptions}
                onChange={onFilterDatesAlphabetChangeHandler}
                // defaultValue={!sortOptions ? order[0] : sortOptions[0]}
                defaultValue={
                  !sortOptions
                    ? order[selectedSortOption]
                    : sortOptions[selectedSortOption]
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      {buttons ? (
        <div
          className={`${
            width < 368 ? "flex-column align-items-end " : "align-items-start "
          }col-12 col-xl-5 col-lg-5 col-md-4 d-flex justify-content-end flex-wrap ${
            twoRowLayout ? "" : "pr-0 pl-0"
          }`}
        >
          {buttons}
        </div>
      ) : null}
    </div>
  );
};
TrialHubSearchSortBar.propTypes = {
  searchBarValue: PropTypes.string.isRequired,
  onSearchBarChangeHandler: PropTypes.func.isRequired,
  searchBarPlaceholder: PropTypes.string,
  searchBarInputName: PropTypes.string.isRequired,
  clearSearchBarHandler: PropTypes.func,
  onFilterDatesAlphabetChangeHandler: PropTypes.func.isRequired,
};

export default withTheme(TrialHubSearchSortBar);
