import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import { route } from "../../../routes";
import { useSelector } from "react-redux";
import StubPage from "../../../components/StubPage/StubPage";
import TrialProofs from "./TrialProofs";
import CoreBook from "./CoreBook";
import WitnessProofs from "./WitnessProofs";
import Chronology from "./Chronology";
import Authorities from "./Authorities";
import {
  APPLICATION_AUTHORITIES_FLOW,
  TRIAL_AUTHORITIES_FLOW,
} from "./Authorities/Authorities";
import AdviceOnProofs from "./AdviceOnProofs";
import EvidenceChecklist from "./EvidenceChecklist";

/**
 * Component route in trial proofs
 * @returns {*}
 * @constructor
 */
const TrialProofsRouter = () => {
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  return (
    <Fragment>
      <Switch>
        {/*<Route exact path={'/app/cases/:id/trial-proofs/pdf'}>*/}
        {/*  <Pdf witnessName={'New X-Exam Witness(1)'}/>*/}
        {/*</Route>*/}
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.container
              : route.admin.baseApp.trialProofs.container
          }`}
        >
          <TrialProofs />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.witnessProofs
              : route.admin.baseApp.trialProofs.witnessProofs
          }`}
        >
          <WitnessProofs />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.exWitnessProofs
              : route.admin.baseApp.trialProofs.exWitnessProofs
          }`}
        >
          <WitnessProofs />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.coreBook
              : route.admin.baseApp.trialProofs.coreBook
          }`}
        >
          <CoreBook />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.trialAuthorities
              : route.admin.baseApp.trialProofs.trialAuthorities
          }`}
        >
          <Authorities flow={TRIAL_AUTHORITIES_FLOW} />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.applicationAuthorities
              : route.admin.baseApp.trialProofs.applicationAuthorities
          }`}
        >
          <Authorities flow={APPLICATION_AUTHORITIES_FLOW} />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.trialAdviceOnProofs
              : route.admin.baseApp.trialProofs.trialAdviceOnProofs
          }`}
        >
          <AdviceOnProofs />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.chronology
              : route.admin.baseApp.trialProofs.chronology
          }`}
        >
          <Chronology />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialProofs.evidenceChecklist
              : route.admin.baseApp.trialProofs.evidenceChecklist
          }`}
        >
          <EvidenceChecklist />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default TrialProofsRouter;
