import React from "react";
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from "@react-pdf/renderer";

// Create styles
const borderColor = "gray";
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    left: 35,
    right: 0,
    fontFamily: "Roboto",
    textAlign: "center",
    color: "grey",
  },
  field: {
    color: "gray",
    fontSize: 12,
    fontFamily: "Roboto",
    marginBottom: 2,
  },
  value: {
    fontSize: 14,
    fontFamily: "Roboto",
    marginBottom: 15,
  },
  title: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  case: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "left",
  },
  flexRow: {
    flexDirection: "row",
  },
  tableTitle: {
    fontSize: 14,
    fontFamily: "Roboto",
    marginBottom: 10,
  },
  tableHeader: {
    fontSize: 12,
  },
  table_: {
    marginBottom: 15,
  },

  table: {
    display: "table",
    width: "auto",
    fontFamily: "Roboto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: borderColor,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    fontFamily: "Roboto",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: borderColor,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    // flex: 1,
    // flexDirection: 'row',
    overflowWrap: "break-word",
    textAlign: "left",
    marginLeft: 5,
    fontFamily: "Roboto",
  },
});
Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});
let example = [
  {
    title: "Title",
    type: "themes",
    headers: [
      {
        name: "id",
        label: "ID",
        width: "10%",
      },
      {
        name: "evidence",
        label: "Evidence",
        width: "20%",
      },
      {
        name: "tags",
        label: "Tags",
        width: "30%",
      },
      {
        name: "objectives",
        label: "Objectives",
        width: "40%",
      },
    ],
    rows: [
      {
        id: "1",
        evidence: "evidence",
        tags: ["hello", "world", "with", "some", "text"],
        objectives: [
          { name: "objective", value: ["examline1", "examline2", "examline3"] },
        ],
      },
      {
        id: "1",
        evidence: "evidence",
        tags: ["hello", "world", "with", "some", "text"],
        objectives: [
          { name: "objective", value: ["examline1", "examline2", "examline3"] },
        ],
      },
      {
        id: "1",
        evidence: "evidence",
        tags: ["hello", "world", "with", "some", "text"],
        objectives: [
          { name: "objective", value: ["examline1", "examline2", "examline3"] },
        ],
      },
      {
        id: "1",
        evidence: "evidence",
        tags: ["hello", "world", "with", "some", "text"],
        objectives: [
          { name: "objective", value: ["examline1", "examline2", "examline3"] },
        ],
      },
    ],
  },
];
export const PdfWitness = ({
  witness,
  caseName,
  type,
  data,
  document_type,
  isBackground,
}) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body} wrap>
        <View style={styles.flexRow}>
          <View>
            <Text style={styles.case}>
              {caseName}{" "}
              <Text style={[styles.case, { color: "gray" }]}>
                {document_type}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.flexRow}>
          <View>
            <Text style={styles.title}>
              {witness.name} ({type})
            </Text>
          </View>
        </View>
        {isBackground ? (
          <View>
            <Text style={styles.field}>Party</Text>
            <Text style={styles.value}>
              {witness.party && witness.party.role ? witness.party.role : "N/A"}
            </Text>

            <Text style={styles.field}>Class</Text>
            <Text style={styles.value}>{witness.class ?? "N/A"}</Text>

            <Text style={styles.field}>Reason for Testimony</Text>
            <Text style={styles.value}>
              {witness.reason_to_testimony ?? "N/A"}
            </Text>

            <Text style={styles.field}>Context & Background</Text>
            <Text style={styles.value}>{witness.background ?? "N/A"}</Text>

            <Text style={styles.field}>Elements</Text>
            <Text style={styles.value}>
              {(witness.causes_of_action && witness.causes_of_action.length) ||
              (witness.causes_of_action_parent &&
                witness.causes_of_action_parent.length) ||
              (witness.causes_of_action_defensive &&
                witness.causes_of_action_defensive.length)
                ? [
                    ...witness.causes_of_action_parent.map((el) =>
                      el.type ? el.type : el
                    ),
                    ...witness.causes_of_action,
                    ...witness.causes_of_action_defensive,
                  ]
                    .map((v) => v.name)
                    .join(", ")
                : "N/A"}
            </Text>

            <Text style={styles.field}>Themes</Text>
            <Text style={styles.value}>
              {(witness.themes && witness.themes.length) ||
              (witness.themes_parent && witness.themes_parent.length)
                ? [...witness.themes_parent, ...witness.themes]
                    .map((v) => v.name)
                    .join(", ")
                : "N/A"}
            </Text>

            <Text style={styles.field}>Issues</Text>
            <Text style={styles.value}>
              {(witness.issues && witness.issues.length) ||
              (witness.issues_parent && witness.issues_parent.length)
                ? [...witness.issues_parent, ...witness.issues]
                    .map((v) => v.name)
                    .join(", ")
                : "N/A"}
            </Text>
          </View>
        ) : (
          data.map((v) => {
            return (
              <View style={styles.table_}>
                <View>
                  <Text style={styles.tableTitle}>{v.title}</Text>
                </View>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    {v.headers.map((v) => {
                      return (
                        <View style={[styles.tableCol, { width: v.width }]}>
                          <Text
                            style={[
                              styles.tableCell,
                              { textAlign: "center", marginLeft: 0 },
                            ]}
                          >
                            {v.label}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                  {v.rows.map((row) => {
                    return (
                      <View style={styles.tableRow}>
                        {v.headers.map((header_) => {
                          let content;
                          if (!row[header_.name]) {
                            content = <Text style={styles.tableCell} />;
                          } else {
                            if (typeof row[header_.name] !== "object") {
                              content = (
                                <Text style={styles.tableCell}>
                                  {row[header_.name]}
                                </Text>
                              );
                            } else {
                              content = [];
                              let as_row = false;
                              row[header_.name].forEach((el, index) => {
                                if (typeof el === "object") {
                                  content.push(
                                    <>
                                      <Text style={styles.tableCell}>
                                        {el.name}
                                        {"\n"}
                                      </Text>
                                      {el.value.map((lines, i) => {
                                        return (
                                          <Text
                                            style={[
                                              styles.tableCell,
                                              { marginLeft: 10 },
                                            ]}
                                          >
                                            {i + 1}.{lines}
                                            {"\n"}
                                          </Text>
                                        );
                                      })}
                                    </>
                                  );
                                } else {
                                  as_row = true;
                                  content.push(el);
                                }
                              });
                              if (as_row) {
                                content = (
                                  <Text style={styles.tableCell}>
                                    {content.join(", ")}
                                  </Text>
                                );
                              }
                            }
                          }
                          return (
                            <View
                              style={[
                                styles.tableCol,
                                { width: header_.width },
                              ]}
                            >
                              {content}
                            </View>
                          );
                        })}
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })
        )}
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
