import React from "react";
import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Box = styled.div`
  width: 26px;
  height: 26px;
  border: 2px solid #dcdce6;
  background: black;
  cursor: pointer;
`;
// <Box />

const Color = ({ editor }) => {
  // TODO
  // if not selecting colored text - show black color

  return (
    <Container>
      <input
        type="color"
        onInput={(event) =>
          editor.chain().focus().setColor(event.target.value).run()
        }
        value={editor.getAttributes("textStyle").color || "#000"}
      />
    </Container>
  );
};

export default Color;
