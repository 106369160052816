import React from "react";
import PropTypes from "prop-types";

import InputStyled from "./styled/InputStyled";

import { Bold14Font } from "../Fonts/Fonts";

// TODO: Re-implement all props with ...props pattern
const Input = ({
  type,
  name,
  label,
  placeholder,
  customStyle,
  onChangeHandler,
  value,
  ...props
}) => {
  return (
    <label className="w-100">
      <div className="container-fluid">
        {label ? (
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col">
            <InputStyled
              type={type}
              name={name}
              placeholder={placeholder}
              as={customStyle}
              onChange={onChangeHandler}
              value={value}
              {...props}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
    </label>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(["text", "password", "textarea", "email"]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};
Input.defaultProps = {
  type: "text",
};

export default Input;
