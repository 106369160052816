/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
import { NotebookContext, EditorContext } from "@/context";
import { FloatingMenu } from "./styled";

export default ({ setShowMenu, propsNode }) => {
  const { notebook, notebookLoading, caseObject } = useContext(NotebookContext);
  const {
    editor,
    setContent,
    // saveContent,
  } = useContext(EditorContext);

  const updateEditorState = (tagId, tagType, updatedLabel) => {
    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                // console.log("subnode");
                // console.log(subNode.type);
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  // console.log("---");
                  // console.log(subNode);
                  // console.log(tagId);
                  // console.log(tagType);
                  if (
                    subNode.attrs.tagId == tagId &&
                    subNode.attrs.tagType.toLowerCase() == tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: updatedLabel },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);
  };

  if (notebookLoading || !notebook || !caseObject) return null;

  return (
    <FloatingMenu>
      <span
        className="tag-action"
        onClick={() => {
          console.log("apply");
          const oldJson = editor.getJSON();
          const newJson = {
            ...oldJson,
            content: oldJson.content.map((node) => {
              return {
                ...node,
                content: node.content
                  ? node.content.map((subNode) => {
                      console.log("subnode");
                      console.log(subNode);
                      if (subNode.type === "creation") {
                        if (
                          subNode.attrs.tagId == propsNode.attrs.tagId &&
                          subNode.attrs.tagType.toLowerCase() ==
                            propsNode.attrs.tagType.toLowerCase()
                        ) {
                          return {
                            ...subNode,
                            type: "tag",
                          };
                        }
                        return subNode;
                      }
                      return subNode;
                    })
                  : [],
              };
            }),
          };
          editor.commands.setContent(newJson);
          const newHtml = editor.getHTML();
          setContent(newHtml);
          //
        }}
      >
        Apply
      </span>
      <span
        className="tag-action"
        onClick={() => {
          console.log("apply");
          const oldJson = editor.getJSON();
          const newJson = {
            ...oldJson,
            content: oldJson.content.map((node) => {
              return {
                ...node,
                content: node.content
                  ? node.content.map((subNode) => {
                      console.log("subnode");
                      console.log(subNode);
                      if (subNode.type === "creation") {
                        if (
                          subNode.attrs.tagId == propsNode.attrs.tagId &&
                          subNode.attrs.tagType.toLowerCase() ==
                            propsNode.attrs.tagType.toLowerCase()
                        ) {
                          return {
                            type: "text",
                            text: propsNode.attrs.id,
                          };
                        }
                        return subNode;
                      }
                      return subNode;
                    })
                  : [],
              };
            }),
          };
          editor.commands.setContent(newJson);
          const newHtml = editor.getHTML();
          setContent(newHtml);
          //
        }}
      >
        Decline
      </span>
    </FloatingMenu>
  );
};
