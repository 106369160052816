import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const ConfirmEmailFormWrapperStyled = styled.div.attrs({
  className: "container-fluid",
})`
  max-width: ${rem(`470px`)};

  @media (max-width: 767.98px) {
    max-width: unset;
  }
`;

export default ConfirmEmailFormWrapperStyled;
