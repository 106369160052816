import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import { updateProfile } from "../../../containers/Auth/auth";
import Button from "../../buttons/Button";
import SignUpGoogleAuthFormStyled from "./styled/SignUpGoogleAuthFormStyled";

import { Bold14Font, Bold30Font, Medium14Font } from "../../Fonts/Fonts";
import Select from "../../Select";
import { SIGN_IN, SIGN_UP } from "../../../redux/types";
import { route } from "../../../routes";
import modalErrorHandler, {
  handleErrorMessage,
} from "../../Modal/modalErrorHandler";
import {
  createCountryOptionsList,
  roleOptions,
} from "../../../utils/constants";
import PhoneInput from "react-phone-number-input";
import Checkbox from "../../Checkbox/Checkbox";
import { theme } from "../../../styled-components/Theme/Theme";

const SignUpGoogleAuthForm = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [terms, setTerms] = useState(false);
  const userDataRedux = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  let form = useRef(null);

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      name: userDataRedux.name,
      email: userDataRedux.email,
    }));
  }, [userDataRedux]);

  const onRoleChangeHandler = (selectedOption) => {
    setUserData((prevState) => ({ ...prevState, role: selectedOption.value }));
  };

  const onCountryChangeHandler = (selectedOption) => {
    setUserData((prevState) => ({
      ...prevState,
      country: selectedOption.value,
    }));
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!terms) {
      handleErrorMessage(
        "You must agree to the TrialProofer Terms of Use and our Privacy Policy",
        dispatch
      );
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      try {
        const response = await updateProfile(userData, dispatch);
        if (response.result === "success") {
          dispatch({ type: SIGN_UP, payload: response.data });
          history.push(route.baseApp.app);
        }
        setIsLoading(false);
      } catch (e) {
        modalErrorHandler(e, dispatch);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="row p-4">
        <div className="col d-flex flex-column align-items-center">
          <Bold30Font as="p">Set Role and Country</Bold30Font>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column align-items-center">
          <SignUpGoogleAuthFormStyled
            onSubmit={onFormSubmitHandler}
            ref={(ref) => (form = ref)}
          >
            <div className="form-group">
              <div className="container-fluid">
                <Bold14Font className="d-block mb-2">Role</Bold14Font>
                <Select
                  isSearchable={false}
                  name="role"
                  classNamePrefix="Select"
                  defaultValue={
                    history.location.state && history.location.state.role
                      ? roleOptions.find(
                          (v) => v.value === history.location.state.role
                        )
                      : null
                  }
                  options={roleOptions}
                  styles={{
                    input: () => ({
                      padding: 0,
                      margin: 0,
                    }),
                  }}
                  onChangeHandler={onRoleChangeHandler}
                />
              </div>
            </div>
            {/*<div className="form-group">*/}
            {/*  <div className="container-fluid">*/}
            {/*    <Bold14Font className="d-block mb-2">Phone</Bold14Font>*/}
            {/*    <PhoneInput*/}
            {/*      required*/}
            {/*      international*/}
            {/*      placeholder="Enter your phone"*/}
            {/*      value={userData.phone ?? ""}*/}
            {/*      onChange={(phone) => {*/}
            {/*        setUserData((prevState) => ({*/}
            {/*          ...prevState,*/}
            {/*          phone: phone,*/}
            {/*        }));*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="form-group mb-4">
              <div className="container-fluid">
                <Bold14Font className="d-block mb-2">Country</Bold14Font>
                <Select
                  isSearchable={false}
                  defaultValue={
                    history.location.state && history.location.state.country
                      ? createCountryOptionsList().find(
                          (v) => v.value === history.location.state.country
                        )
                      : null
                  }
                  options={createCountryOptionsList()}
                  styles={{
                    input: () => ({
                      padding: 0,
                      margin: 0,
                    }),
                  }}
                  classNamePrefix="Select"
                  onChangeHandler={onCountryChangeHandler}
                />
              </div>
            </div>
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
              <div
                onClick={() => {
                  setTerms((prevState) => !prevState);
                }}
                className="row align-items-baseline"
                style={{ marginBottom: "1rem", marginTop: "30px" }}
              >
                <Checkbox checked={terms} onChange={setTerms} />
                <Medium14Font
                  style={{
                    color: theme.colors.darkOpacity,
                    marginRight: "10px",
                  }}
                >
                  By checking the checkbox below, you agree to our
                </Medium14Font>
                <Link to={route.termsAndConditions}>
                  <Bold14Font style={styles.change}>Terms of Use</Bold14Font>
                </Link>
                {", "}
                <Link to={route.privacyPolicy} style={{ marginLeft: "3px" }}>
                  <Bold14Font style={styles.change}>Privacy Policy</Bold14Font>
                </Link>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Button
                    primary
                    wide
                    type="submit"
                    clickHandler={() => null}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </SignUpGoogleAuthFormStyled>
        </div>
      </div>
    </div>
  );
};

const styles = {
  change: {
    color: theme.colors.blue,
    cursor: "pointer",
  },
};

export default withTheme(SignUpGoogleAuthForm);
