import React, { useCallback, useRef } from "react";
import RoundInputStyled from "./styled/RoundInputStyled";

const RoundInput = ({ ...props }) => {
  const reference = useRef();

  return (
    <RoundInputStyled
      {...props}
      ref={(ref) => {
        if (props.getRef) {
          return props.getRef(ref);
        }
      }}
    />
  );
};

export default RoundInput;
