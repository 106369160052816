import { loadAccessToken, makeRequest } from "./auth";
import { AUTO_LOG_IN, GET, RESET_REQUESTED_URL } from "../../redux/types";
import { route } from "../../routes";
import { history } from "../../index";
import { store } from "../../index";
import modalErrorHandler from "../../components/Modal/modalErrorHandler";

/**
 * List of routes that user cannot access when he/she complete sign in / sign up and subscription flows
 */
const blockedRoutesWhenUserInApp = [
  route.auth.signUp,
  route.auth.signUpWithGoogle,
  route.auth.signIn,
  route.auth.confirmEmail,
  route.auth.restorePassword,
  route.auth.enterNewPassword,
  route.subscription.step2,
  route.subscription.step3,
];

export const redirectUserToAppropriatePage = async (
  isUserAuth,
  history,
  dispatch,
  subscriptionSelected,
  requestedUrl,
  path
) => {
  if (await loadAccessToken()) {
    try {
      const response = await makeRequest(GET, route.api.user.get, {}, true);
      dispatch({ type: AUTO_LOG_IN, payload: response.data });
      if (!response.data.is_admin) {
        if (response.data["email_verified_at"] === null) {
          history.replace(route.auth.confirmEmail);
        } else if (
          response.data["email_verified_at"] &&
          (!response.data["role"] || !response.data["country"])
        ) {
          history.replace(route.auth.signUpWithGoogle);
          // } else if (
          //   response.data["email_verified_at"] &&
          //   response.data["role"] &&
          //   response.data["country"] &&
          //   (!response.data.features || !response.data.features.length)
          // ) {
          // if (!subscriptionSelected) {
          //   history.replace(route.subscription.step2);
          // } else {
          // TODO: Does I need that here?
          // history.replace(route.baseApp.app);
          // }
        } else {
          if (requestedUrl) {
            const copyUrl = requestedUrl;
            dispatch({ type: RESET_REQUESTED_URL });
            if (blockedRoutesWhenUserInApp.includes(copyUrl)) {
              history.replace(route.baseApp.app);
            } else {
              history.replace(copyUrl);
            }
          }
        }
      }
    } catch (e) {
      // modalErrorHandler(e, dispatch);
    }
  }
};

/**
 * Check if user have subscription. If user don't have subscription - redirect him to subscription screen
 * @returns {boolean|*}
 */
export const checkIfUserHaveSubscription = () => {
  const user = store.getState().auth.user;
  if (user && !user.features.length) {
    return history.replace(route.subscription.step2);
  }
  return true;
};

export const checkIfUserIsOwnerOfTheCase = (caseObject) => {
  const user = store.getState().auth.user;
  return (
    caseObject.id_user === user.id ||
    (caseObject.user && caseObject.user.id === user.id)
  );
};

/**
 * Check specific errors and redirect to case access forbidden page, or use default modalErrorHandler
 * @param errorObject
 * @param caseId
 * @param dispatch
 * @returns {undefined|void}
 */
export const checkRestrictCaseError = (errorObject, caseId, dispatch) => {
  if (
    errorObject.response.data.messages[0].includes(
      "Case Owner's subscription expired. Please contact your case owner on "
    )
  ) {
    return history.replace(`/app/cases/forbidden/${caseId}`, {
      error: errorObject.response.data.messages[0],
      email: errorObject.response.data.messages[1],
      caseName: errorObject.response.data.messages[2],
    });
  } else if (
    errorObject.response.data.messages[0].includes(
      "You don't have active subscription."
    )
  ) {
    return history.replace(`/app/cases/forbidden/${caseId}`, {
      error: errorObject.response.data.messages[0],
      caseName: errorObject.response.data.messages[1],
    });
  }

  return modalErrorHandler(errorObject, dispatch);
};
