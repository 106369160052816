import React, {Fragment, useRef, useState} from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import {useDispatch} from "react-redux";
import {theme} from "../../../styled-components/Theme/Theme";
import {HIDE_RIGHT_SIDE_BAR, MODAL_ERROR_SIGN_IN, SAVE_MODAL_DATA, SHOW_MODAL,} from "../../../redux/types";
import {createTestimonial, updateTestimonial} from "../../../containers/Auth/auth";
import {DividerHorizontal} from "../../dividers/DividerHorizontal";
import Avatar from "react-avatar";
import addImage from "../../../assets/img/svg/addImage.svg";
import TextAreaSideBar from "../../forms/DocumentForms/TextAreaSideBar";
import {maxFileSize} from "../../../utils";

export default function TestimonyForm(prop) {
  let {object} = prop;
  const dispatch = useDispatch();
  const [object_, setObject] = useState(object);
  const [name, setName] = useState(object.name ?? "");
  const [text, setText] = useState(object.text ?? "");
  const [company, setCompany] = useState(object.company ?? "");
  const [isLoading, setIsLoading] = useState(false);
  let form = React.createRef();
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      let resp;
      let formData = new FormData();
      formData.append('name', name);
      formData.append('text', text);
      formData.append('company', company);
      if (file) {
        formData.append('image', file);
      }
      if (object.id) {
        resp = await updateTestimonial(object.id, dispatch, formData);
      } else {
        resp = await createTestimonial(dispatch, formData);
      }
      setIsLoading(false);
      if (resp) {
        dispatch({type: HIDE_RIGHT_SIDE_BAR});
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update"
        );
      }
    }
  };
  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    return validTypes.indexOf(file.type) !== -1;
  };
  const file_input = React.createRef();
  const addFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const handleFiles = (files) => {
    if (files.length) {
      let file = files[0];
      if (validateFile(file)) {
        if (file.size < maxFileSize) {
          setFile(file);
          toBase64(file).then((resp) => {
            setObject((prevState) => {
              return {...prevState, ...{image: resp}};
            });
          });
        } else {
          dispatch({type: SAVE_MODAL_DATA, payload: "File is to big"});
          dispatch({type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN});
        }
      } else {
        dispatch({type: SAVE_MODAL_DATA, payload: "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format."});
        dispatch({type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN});
      }
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  return (
    <Fragment>
      <div style={{height: "100%", overflowY: "auto"}}>
        <div className="form-group" onDragOver={dragOver}
             onDragEnter={dragEnter}
             onDragLeave={dragLeave}
             onDrop={addFile}>
          <label className="d-flex justify-content-center">
            <Avatar
              style={{
                cursor: "pointer",
                height: "122px",
                border: `1px solid ${theme.colors.gray}`,
              }}
              size={120}
              round={"120px"}
              src={object_.image && object_.image !== '' ? object_.image : addImage}
            />
            <input
              ref={file_input}
              type="file"
              name="file"
              id="file"
              style={{display: "none"}}
              onChange={() => {
                if (file_input.current) {
                  handleFiles(file_input.current.files);
                }
              }}
            />
          </label>
        </div>
        <div className="form-group">
          <Input
            maxLength={255}
            type={"text"}
            required
            label={"Name"}
            onChangeHandler={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder={"Enter name"}
            name={"name"}
            form="form-Reliefs"
          />
        </div>
        <div className="form-group">
          <Input
            maxLength={255}
            type={"text"}
            required
            label={"Company"}
            onChangeHandler={(e) => {
              setCompany(e.target.value);
            }}
            value={company}
            placeholder={"Enter company"}
            name={"company"}
            form="form-Reliefs"
          />
        </div>
        <div className="form-group">
          <TextAreaSideBar
            withMargin
            withoutLimit
            placeholder={"Enter Text"}
            value={text}
            name={"text"}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </div>
      </div>
      <div style={{height: '100', overflow:'auto'}}/>
      <DividerHorizontal/>

      <div className="form-group">
        <div className="container-fluid">
          <form ref={form} onSubmit={handleSubmit} id="form-Reliefs">
            <Button
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
TestimonyForm.propTypes = {
  object: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};
