export const listenForOutsideClick = (
  listening,
  setListening,
  menuRef,
  onClickOutside,
  event = "click"
) => {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    [event, `touchstart`].forEach((type) => {
      document.addEventListener(event, (evt) => {
        if (!menuRef.current) return;
        if (menuRef.current.contains(evt.target)) {
          return;
        }

        onClickOutside(evt);
      });
    });
  };
};
