import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import moment from "moment";

import PulseLoader from "react-spinners/PulseLoader";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import Form from "../../../../components/forms/Form";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import EvidenceDetailsMarginStyled from "../Evidence/styled/EvidenceDetailsMarginStyled";
import EvidenceDetailsColumnWithBorderStyled from "../Evidence/styled/EvidenceDetailsColumnWithBorderStyled";

import {
  getAuthorityById,
  getEvidenceById,
  previewAuthorityFile,
  previewEvidenceFile,
} from "../../../Auth/auth";

import fileDocIcon from "../../../../assets/img3/svg/file-doc-icon.svg";
import fileOdtIcon from "../../../../assets/img3/svg/file-odt-icon.svg";

import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../../components/FontsNewComponent/Fonts";

import filePreviewIcon from "../../../../assets/img3/svg/file-preview-icon.svg";
import { TagElementBlue } from "../../../../components/TagElementStyled/TagElementStyled";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../../utils/function";

const extensionsWithoutPreview = [
  {
    extension: "doc",
    previewImage: fileDocIcon,
  },
  {
    extension: "docx",
    previewImage: fileDocIcon,
  },
  {
    extension: "odt",
    previewImage: fileOdtIcon,
  },
];

const NotAvailable = <Medium14Font>N/A</Medium14Font>;

const AuthoritiesDetails = ({
  applicationAuthorityObject,
  noEdit,
  withTitle,
  withReliefs,
  caseObject,
  theme,
}) => {
  const [isLoading, setIsLoading] = useState(!applicationAuthorityObject);
  const [authoritiesObject, setAuthoritiesObject] = useState(
    applicationAuthorityObject ?? {}
  );
  const [fileType, setFileType] = useState({});
  const [filePreview, setFilePreview] = useState(null);
  const { id, authorityId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const saveDataFile = async (resp) => {
    if (resp.file) {
      const fileNameArray = resp.file.split(".");
      const fileExtension = fileNameArray[fileNameArray.length - 1];
      const checkExtension = extensionsWithoutPreview.findIndex(
        (object) => object.extension === fileExtension
      );
      if (checkExtension === -1) {
        const filePreviewResp = await previewAuthorityFile(
          authorityId ?? authoritiesObject.fake_id,
          id,
          dispatch
        );
        if (filePreviewResp) {
          setFilePreview(filePreviewResp);
        }
      } else {
        setFileType(extensionsWithoutPreview[checkExtension]);
      }
    }
  };

  // Get evidence data from api
  useEffect(() => {
    const getData = async () => {
      const authorityResp = await getAuthorityById(authorityId, id, dispatch);
      if (authorityResp) {
        setAuthoritiesObject(authorityResp);
        await saveDataFile(authorityResp);
      }
      setIsLoading(false);
    };
    if (!Object.keys(authoritiesObject).length) {
      getData();
    } else {
      saveDataFile(authoritiesObject);
    }
  }, [authoritiesObject]);

  const editAuthoritiesHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${id}/trial-hub/authorities/edit/${authorityId}`
        : `/admin/all-cases/${id}/trial-hub/authorities/edit/${authorityId}`
    );
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: authoritiesObject.case.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-hub/authorities`
        : `/admin/all-cases/${id}/trial-hub/authorities`,
      title: `Trial Hub - Authorities`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-hub/authorities/${authorityId}`
        : `/admin/all-cases/${id}/trial-hub/authorities/${authorityId}`,
      title: authoritiesObject.title ?? "New Authority",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      {!noEdit ? (
        <Fragment>
          <PageConfiguration configArray={pageConfig} />
          <div className="row">
            <div className="col-6">
              <Bold30Font>
                {authoritiesObject.title ?? "New Authority"}
              </Bold30Font>
            </div>
            <div className="col-6 d-flex justify-content-end align-items-center mb-3">
              <ButtonNew
                clickHandler={editAuthoritiesHandler}
                loading={isLoading}
                disabled={isLoading}
                // style={{ marginRight: "20px" }}
                primary
                // delete_
              >
                Edit
              </ButtonNew>
            </div>
          </div>
        </Fragment>
      ) : null}
      <div className="row">
        <div className="col">
          <Form>
            <div className="container-fluid" style={{ padding: "35px 60px" }}>
              <div
                className={`row ${authoritiesObject.file ? "pb-5 mb-5" : null}`}
                style={{
                  borderBottom: authoritiesObject.file
                    ? `1px solid ${theme.colors.gray}`
                    : "unset",
                }}
              >
                <div className="col-12 col-sm d-flex flex-column">
                  {withTitle ? (
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Title</Medium14Font>
                      <Bold14Font>
                        {authoritiesObject.title ?? NotAvailable}
                      </Bold14Font>
                    </EvidenceDetailsMarginStyled>
                  ) : null}
                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>File</Medium14Font>
                    <Bold14Font>
                      {authoritiesObject.file ?? NotAvailable}
                    </Bold14Font>
                  </EvidenceDetailsMarginStyled>

                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>Jurisdiction</Medium14Font>
                    <Bold18Font>
                      {authoritiesObject.jurisdiction ?? NotAvailable}
                    </Bold18Font>
                  </EvidenceDetailsMarginStyled>
                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>Citation</Medium14Font>
                    <Bold18Font>
                      {authoritiesObject.citation ?? NotAvailable}
                    </Bold18Font>
                  </EvidenceDetailsMarginStyled>
                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>Sections</Medium14Font>
                    <Bold14Font>
                      {authoritiesObject.key_sections ?? NotAvailable}
                    </Bold14Font>
                  </EvidenceDetailsMarginStyled>
                </div>
                <EvidenceDetailsColumnWithBorderStyled className="col-12 col-sm d-flex flex-column" />

                <div className="col-12 col-sm d-flex flex-column mt-4 mt-sm-0 pl-sm-5">
                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>Authority Type</Medium14Font>
                    {authoritiesObject.type ? (
                      <TagElementBlue theme={theme}>
                        <Bold14Font style={{ color: "inherit" }}>
                          {authoritiesObject.type}
                        </Bold14Font>
                      </TagElementBlue>
                    ) : (
                      NotAvailable
                    )}
                  </EvidenceDetailsMarginStyled>
                  {!withReliefs ? (
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Elements</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(authoritiesObject.causes_of_action &&
                          authoritiesObject.causes_of_action.length) ||
                        (authoritiesObject.causes_of_action_parent &&
                          authoritiesObject.causes_of_action_parent.length) ||
                        (authoritiesObject.causes_of_action_defensive &&
                          authoritiesObject.causes_of_action_defensive
                            .length) ? (
                          <Fragment>
                            <ShowCoa
                              object={authoritiesObject}
                              case_object={caseObject}
                            />
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  ) : null}
                  {!withReliefs ? (
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Issues</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(authoritiesObject.issues &&
                          authoritiesObject.issues.length) ||
                        (authoritiesObject.issues_parent &&
                          authoritiesObject.issues_parent.length) ? (
                          <Fragment>
                            <ShowIssues
                              object={authoritiesObject}
                              case_object={caseObject}
                            />
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  ) : null}
                </div>
                <div className="col-12 col-sm d-flex flex-column">
                  <EvidenceDetailsMarginStyled>
                    <Medium14Font>Status</Medium14Font>
                    <Bold18Font>
                      {authoritiesObject.status ? (
                        <TagElementBlue theme={theme}>
                          <Bold14Font style={{ color: "inherit" }}>
                            {authoritiesObject.status}
                          </Bold14Font>
                        </TagElementBlue>
                      ) : (
                        NotAvailable
                      )}
                    </Bold18Font>
                  </EvidenceDetailsMarginStyled>
                  {!withReliefs ? (
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Themes</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {(authoritiesObject.themes &&
                          authoritiesObject.themes.length) ||
                        (authoritiesObject.themes_parent &&
                          authoritiesObject.themes_parent.length) ? (
                          <Fragment>
                            <ShowThemes
                              object={authoritiesObject}
                              case_object={caseObject}
                            />
                          </Fragment>
                        ) : (
                          NotAvailable
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  ) : null}
                  {withReliefs ? (
                    <EvidenceDetailsMarginStyled>
                      <Medium14Font>Reliefs and Proofs</Medium14Font>
                      <div className="d-flex flex-wrap">
                        {authoritiesObject.reliefs.length ||
                        authoritiesObject.proofs.length ? (
                          <ul className="d-flex flex-wrap">
                            {authoritiesObject.reliefs.map((relief) => (
                              <TagElementBlue key={relief.id} as="li">
                                <Bold14Font style={{ color: "inherit" }}>
                                  {relief.name}
                                </Bold14Font>
                              </TagElementBlue>
                            ))}
                            {authoritiesObject.proofs.map((proof) => (
                              <TagElementBlue key={proof.id} as="li">
                                <Bold14Font style={{ color: "inherit" }}>
                                  {proof.name}
                                </Bold14Font>
                              </TagElementBlue>
                            ))}
                          </ul>
                        ) : (
                          <Medium18Font>N/A</Medium18Font>
                        )}
                      </div>
                    </EvidenceDetailsMarginStyled>
                  ) : null}
                </div>
              </div>
              <div className="row">
                {authoritiesObject.file ? (
                  <div className="col-12 d-flex justify-content-center">
                    <ButtonNew
                      tertiary
                      clickHandler={() => {
                        history.push(
                          !is_admin
                            ? `/app/cases/${id}/documents/${authoritiesObject.file_id}`
                            : `/admin/all-cases/${id}/documents/${authoritiesObject.file_id}`
                        );
                      }}
                      style={{
                        borderRadius: "4px",
                        border: `1px solid ${theme.colors.gray}`,
                      }}
                    >
                      <img
                        src={filePreview ? filePreview : fileType.previewImage}
                        alt=""
                        className="img-fluid"
                      />
                      <img
                        src={filePreviewIcon}
                        alt=""
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </ButtonNew>
                  </div>
                ) : null}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(AuthoritiesDetails);
