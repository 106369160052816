import React from "react";
import PropTypes from "prop-types";

import "./assets/custom-react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import us from "date-fns/locale/en-US";
import InputNew from "../../InputNew/InputNew";
registerLocale("us", us);
const CustomReactDatepicker = ({ label, name, customInput, ...props }) => {
  const ref = React.useRef();
  return (
    <DatePicker
      {...props}
      locale="us"
      showYearDropdown
      dropdownMode="select"
      disabledKeyboardNavigation={true}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          if (ref.current) {
            ref.current.blur();
          }
        }
      }}
      showPopperArrow={false}
      customInput={
        customInput ?? <InputNew forwardRef={ref} label={label} name={name} placeholder={""} />
      }
    />
  );
};

CustomReactDatepicker.propTypes = {
  /**
   * Date that showed in input
   */
  selected: PropTypes.string.isRequired,
  /**
   * Function, that update date
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Set date format
   */
  dateFormat: PropTypes.string,
  /**
   * Set label of input
   */
  label: PropTypes.string,
  /**
   * Set placeholder to input
   */
  placeholderText: PropTypes.string,
  /**
   * Set name of input
   */
  name: PropTypes.string.isRequired,
  /**
   * Custom input to show
   */
  customInput: PropTypes.element,
};

export default CustomReactDatepicker;
