import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TestimonialCard from "../TestimonialCard";
import RoundButton from "../buttons/RoundButton";

import arrowLeftIcon from "../../assets/img3/svg/arrow-left-icon.svg";

const LandingPageCarousel = ({testimonials}) => {
  const responsive = {
    0: {
      items: 1,
    },
    576: {
      items: testimonials.length >= 4 ? 4 : testimonials.length,
    },
  };
  return (
    <Fragment>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={9}
        responsive={responsive}
        nav
      >
        {testimonials.map((el) => (
          <TestimonialCard
            key={el.id}
            company={el.company}
            avatar={el.image}
            text={el.text}
            name={el.name}
          />
        ))}
      </OwlCarousel>
    </Fragment>
  );
};

export default LandingPageCarousel;
