import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MODAL_SUCCESS_EMAIL_VERIFICATION,
  RESET_AUTH_ACTION,
  RESET_PASSWORD,
  SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET,
  SHOW_MODAL,
} from "../../../redux/types";
import { withTheme } from "styled-components/macro";

import ConfirmEmailFormWrapperStyled from "./styled/ConfirmEmailFormWrapperStyled";
import {
  resendVerifyEmail,
  restorePassword,
  verifyEmail,
  verifyEmailOnPasswordReset,
} from "../../../containers/Auth/auth";
import RoundInput from "../../inputs/RoundInput";

import Button from "../../buttons/Button/Button";
import { Bold30Font, Bold14Font, Medium14Font } from "../../Fonts/Fonts";
import modalErrorHandler from "../../Modal/modalErrorHandler";
import useKeyPress from "../../../hooks/useKeyPress";
import { useHistory, useLocation } from "react-router-dom";
import { route } from "../../../routes";
import rem from "../../../utils/rem";
import ButtonNew from "../../buttons/Button/ButtonNew";

const ConfirmEmailForm = ({ theme }) => {
  const [digits, setDigits] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const [verificationCode, setVerificationCode] = useState({ code: "" });
  const authAction = useSelector((state) => state.auth.action);
  const userEmail = useSelector((state) => state.auth.user.email);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(null);
  const backspacePress = useKeyPress("Backspace");
  const history = useHistory();
  const location = useLocation();
  let refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  let form = useRef(null);

  // useEffect(() => {
  //   if (location.state && location.state.from === route.auth.restorePassword) {
  //   }
  // }, []);

  useEffect(() => {}, [digits]);

  useEffect(() => {
    setVerificationCode({
      // TODO: re-write this
      code: digits.first + digits.second + digits.third + digits.fourth,
    });
    if (verificationCode.code.length === 4) {
      makeRequest();
    }
  }, [digits, verificationCode.code]);

  const resetTimer = () => {
    setCount(60);
    const int_ = setInterval(() => {
      setCount((prevState) => {
        if (prevState === 0) {
          clearInterval(int_);
          setTimer(null);
        } else {
          let st = prevState - 1;
          return st;
        }
      });
    }, 1000);
    setTimer(int_);
  };
  useEffect(() => {
    resetTimer();
  }, []);

  const onKeyDownHandler = (event) => {
    event.persist();
    if (event.keyCode === 8) {
      let objectKeys = Object.keys(digits);
      let k = objectKeys.indexOf(event.target.name);
      // console.log(objectKeys);
      setDigits((prevState) => ({
        ...prevState,
        // [event.target.name]: "",
        [objectKeys[k - 1]]: "",
      }));
      if (k !== 0 && refs[k - 1].value !== "") {
        refs[k - 1].focus();
      }
    }
  };

  const onInputChangeHandler = (event) => {
    event.persist();
    // Can write\paste only numbers
    let reg = /[\d]{1,4}/;

    if (!reg.test(event.target.value)) {
      return;
    }
    if (event.target.value.length === 2) {
      event.target.value = event.target.value[1];
    }
    if (event.target.value.length === 1) {
      setDigits((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
      let k = Object.keys(digits).indexOf(event.target.name) + 1;
      if (k < 4) {
        refs[Object.keys(digits).indexOf(event.target.name) + 1].focus();
      } else {
        refs[Object.keys(digits).indexOf(event.target.name)].blur();
      }
    } else if (event.target.value.length === 4) {
      setDigits((prevState) => ({
        first: event.target.value[0],
        second: event.target.value[1],
        third: event.target.value[2],
        fourth: event.target.value[3],
      }));
    }
  };

  // TODO: Delete ?
  // const checkAllEmptyDigits = () => {
  //   return Object.values(digits).every((x) => x === "");
  // };

  const onInputFocusHandler = (event) => {
    event.persist();
    // Clear input on focus
    // if (refs[3] !== window.document.activeElement) {
    //   if (event.target.name) {
    //     setDigits((prevState) => ({
    //       ...prevState,
    //       [event.target.name]: "",
    //     }));
    //   }
    // }
    // If pressed on an any verification fields, each time must focus on the first
    let k = Object.keys(digits).indexOf(event.target.name);
    if (k !== 0 && refs[k - 1].value === "") {
      // if (k !== 0 && checkAllEmptyDigits()) {
      refs[0].focus();
    }
  };

  const focusFourthInput = () => {
    window.document.getElementById("fourth").focus();
  };

  const makeRequest = async () => {
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    try {
      if (authAction === RESET_PASSWORD) {
        setIsLoading(true);
        await verifyEmailOnPasswordReset(userEmail, verificationCode);
        // dispatch({ type: RESET_AUTH_ACTION });
        dispatch({
          type: SHOW_MODAL,
          payload: MODAL_SUCCESS_EMAIL_VERIFICATION,
        });
      } else {
        setIsLoading(true);
        await verifyEmail(verificationCode);
        // refs[3].focus();
        dispatch({
          type: SHOW_MODAL,
          payload: MODAL_SUCCESS_EMAIL_VERIFICATION,
        });
      }
    } catch (e) {
      modalErrorHandler(e, dispatch, focusFourthInput);
      setDigits({
        first: "",
        second: "",
        third: "",
        fourth: "",
      });
      setIsLoading(false);
      // TODO: Find way how to use refs in catch(e){...}
      // refs[3].focus();
      // setDigits({ first: "", second: "", third: "", fourth: "" });
      // setVerificationCode({ code: "" });
    }
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();
    await makeRequest();
  };

  const resendHandler = async (event) => {
    event.preventDefault();
    resetTimer();
    if (!isLoading) {
      try {
        setIsLoading(true);
        if (authAction === RESET_PASSWORD) {
          await restorePassword(userEmail);
        } else {
          await resendVerifyEmail(verificationCode);
        }
        setIsLoading(false);
      } catch (e) {
        clearInterval(timer);
        setCount(0);
        modalErrorHandler(e, dispatch);
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <ConfirmEmailFormWrapperStyled>
          <div className="row my-5">
            <div className="col d-flex flex-column align-items-center">
              <Bold30Font as="p">Verification</Bold30Font>
              <Medium14Font
                as="p"
                textColor={theme.colors.darkOpacity}
                className="text-center"
              >
                Enter the code we just sent to your email address
              </Medium14Font>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <form onSubmit={onFormSubmitHandler} ref={(ref) => (form = ref)}>
                <div
                  className="form-group d-flex justify-content-between mb-5"
                  style={{ minWidth: "270px" }}
                >
                  <RoundInput
                    name="first"
                    getRef={(ref) => {
                      refs[0] = ref;
                    }}
                    value={digits.first}
                    onFocus={onInputFocusHandler}
                    onChange={onInputChangeHandler}
                    required
                    autoComplete="off"
                    autoFocus
                    onKeyDown={onKeyDownHandler}
                  />
                  <RoundInput
                    name="second"
                    getRef={(ref) => {
                      refs[1] = ref;
                    }}
                    value={digits.second}
                    onFocus={onInputFocusHandler}
                    onChange={onInputChangeHandler}
                    required
                    autoComplete="off"
                    onKeyDown={onKeyDownHandler}
                  />
                  <RoundInput
                    name="third"
                    getRef={(ref) => {
                      refs[2] = ref;
                    }}
                    value={digits.third}
                    onFocus={onInputFocusHandler}
                    onChange={onInputChangeHandler}
                    required
                    autoComplete="off"
                    onKeyDown={onKeyDownHandler}
                  />
                  <RoundInput
                    name="fourth"
                    id="fourth"
                    getRef={(ref) => {
                      refs[3] = ref;
                    }}
                    value={digits.fourth}
                    onFocus={onInputFocusHandler}
                    onChange={onInputChangeHandler}
                    required
                    autoComplete="off"
                    onKeyDown={onKeyDownHandler}
                  />
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <Button
                        primary
                        wide
                        type="submit"
                        clickHandler={() => null}
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex flex-column align-items-center">
                      <div
                        className="text-center mt-3"
                        style={{ minWidth: `${rem("313")}` }}
                      >
                        <Medium14Font>Didn’t receive code?</Medium14Font>
                        <br />
                        {timer ? (
                          <Fragment>
                            <Medium14Font
                              style={{
                                marginRight: "4px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              You can request another code in
                            </Medium14Font>
                            <Bold14Font style={{ margin: "0 4px" }}>
                              {count}
                            </Bold14Font>
                            <Medium14Font>seconds</Medium14Font>
                          </Fragment>
                        ) : (
                          <Button
                            tertiary
                            clickHandler={resendHandler}
                            disabled={isSubmitting}
                            style={{ padding: "0" }}
                          >
                            Resend
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ConfirmEmailFormWrapperStyled>
      </div>
    </div>
  );
};

export default withTheme(ConfirmEmailForm);
