import styled from "styled-components/macro";

const CaseRowStyled = styled.div`
  border: 1px solid #dcdce6;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
`;

export default CaseRowStyled;
