import React, { Fragment, useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import ListAddButton from "../../buttons/ListAddButton";
import Label from "../AddCardForm/Label";
import { theme } from "../../../styled-components/Theme/Theme";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableString from "./EditableString";
import { HIDE_RIGHT_SIDE_BAR, SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import {
  addMemberToCaseTeam,
  addThemes,
  updateThemes,
} from "../../../containers/Auth/auth";
import EditableStringClassComponent from "./EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import InputNew from "../../InputNew/InputNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import PapersBasicInfoForm from "../DocumentForms/PapersBasicInfoForm";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import { format, parseISO } from "date-fns";
import AssociateProofs from "../ApplicationsHubForms/AssociateProofs";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { assistantRoleOption, roleOptions } from "../../../utils/constants";

const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;

const CaseTeamForm = ({ object, id_case, afterSubmit, ...props }) => {
  const dispatch = useDispatch();
  // const [object_, setObject] = useState(object);
  const [submitObject, setSubmitObject] = useState({});
  const [selectRoleOptions, setSelectRoleOptions] = useState([
    ...roleOptions,
    assistantRoleOption,
  ]);
  const [selectedRoleOption, setSelectedRoleOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let form = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      //  TODO:  Implement...
      const resp = await addMemberToCaseTeam(id_case, submitObject, dispatch);
      if (resp) {
        if (afterSubmit) {
          afterSubmit(resp[resp.length - 1]);
        }
      }
      setIsLoading(false);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
  };

  const onChangeInputHandler = (event) => {
    event.persist();
    setSubmitObject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeRoleHandler = (selectedOption) => {
    setSelectedRoleOption(selectedOption);
    setSubmitObject((prevState) => ({
      ...prevState,
      role: selectedOption.value,
    }));
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            placeholder="Enter name"
            name="name"
            value={submitObject.name ?? ""}
            onChangeHandler={onChangeInputHandler}
            label="Name"
            autoFocus
            type="text"
            required
            maxLength={125}
            form="case-team-form"
          />
        </div>
        <div className="form-group">
          <SelectAnotherTry
            options={selectRoleOptions}
            onChange={onChangeRoleHandler}
            value={selectedRoleOption}
            label="Role"
            required
            form="case-team-form"
          />
        </div>
        <div className="form-group">
          <InputNew
            placeholder="Enter email"
            name="email"
            value={submitObject.email ?? ""}
            onChangeHandler={onChangeInputHandler}
            label="Email"
            type="email"
            required
            maxLength={125}
            form="case-team-form"
          />
        </div>
      </div>
      <div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id="case-team-form" ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Invite
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CaseTeamForm.propTypes = {
  object: PropTypes.object,
  id_case: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};

export default CaseTeamForm;
