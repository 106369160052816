import styled from "styled-components/macro";

const EvidenceDetailsMarginStyled = styled.div`
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;

  :not(:last-child) {
    margin-bottom: 30px;
  }

  & > :first-child {
    margin-bottom: 10px;
  }
`;

export default EvidenceDetailsMarginStyled;
