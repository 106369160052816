import React from "react";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
// onClick={() => editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()}
//   editor.isActive('textStyle', { fontFamily: 'Comic Sans MS, Comic Sans' })
//
const sizes = [
  {
    label: "9",
    value: "9px",
  },
  {
    label: "10",
    value: "10px",
  },
  {
    label: "11",
    value: "11px",
  },
  {
    label: "12",
    value: "12px",
  },
  {
    label: "13",
    value: "13px",
  },
  {
    label: "14",
    value: "14px",
  },
  {
    label: "15",
    value: "15px",
  },
  {
    label: "18",
    value: "18px",
  },
  {
    label: "21",
    value: "21px",
  },
  {
    label: "24",
    value: "24px",
  },
  {
    label: "32",
    value: "32px",
  },
];

const FontSize = ({ editor }) => {
  return (
    <div style={{ width: 80, marginLeft: 8 }}>
      {/* https://tiptap.dev/api/extensions/font-family */}
      <SelectAnotherTry
        defaultValue={"15px"}
        value={sizes.find(
          (i) =>
            i.value === (editor.getAttributes("textStyle").fontSize || "15px")
        )}
        options={sizes}
        onChange={(v) => {
          editor.chain().focus().setFontSize(v.value).run();
        }}
        compact
      />
    </div>
  );
};

export default FontSize;
