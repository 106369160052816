import styled from "styled-components/macro";

const RsbTabsMenuItemStyled = styled.div`
  cursor: pointer;
  color: ${({ checked, theme }) =>
    checked ? theme.colors.blue : theme.colors.darkOpacity};
  position: relative;
  :not(:last-child) {
    margin-right: 30px;
  }

  :after {
    display: ${({ checked }) => (checked ? "initial" : "none")};
    content: "";
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.colors.blue};
    position: absolute;
    left: 0;
    bottom: -17px;
    border-top-left-radius: 50vh;
    border-top-right-radius: 50vh;
  }

  :hover {
    color: ${({ theme }) => theme.colors.blueHover};
    :after {
      background: ${({ theme }) => theme.colors.blueHover};
    }
  }
`;

export default RsbTabsMenuItemStyled;
