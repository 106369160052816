import React from "react";
import { useHistory } from "react-router-dom";
import Table from "../../../../../components/Table/Table";
import NoRecords from "../../../../../components/NoRecords";
import {
  Bold18Font,
  Medium14Font,
} from "../../../../../components/FontsNewComponent/Fonts";
import { SHOW_RIGHT_SIDE_BAR } from "../../../../../redux/types";
import ObjectivesForm from "../../../../../components/forms/WitnessForms/ObjectivesForm";
import LiStyled from "../../../../../components/Table/Styled/LiStyled";
import { useDispatch } from "react-redux";

const Objectives = ({
  witnessObject,
  isXExamAddEditPage,
  setEvidenceObject,
  id,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const evidenceTableSettings = {
    collapsed: true,
    clickAction: (object) => {
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <ObjectivesForm
            isXExamAddEditPage={isXExamAddEditPage}
            key={object.id}
            evidenceObject={object}
            // setEvidenceObject NOT state setter function!
            setEvidenceObject={setEvidenceObject}
            caseId={id}
          />
        ),
        title: "Objectives",
      });
    },
    actions: [],
    fields: [
      {
        name: "id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
        maxWidth: "70px",
      },
      {
        name: "label",
        label: !(isXExamAddEditPage && isXExamAddEditPage.isExact)
          ? "EVIDENCE"
          : "TOPIC",
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "objectives",
        label: "OBJECTIVES",
        renderer: (object) => {
          if (object.objectives && object.objectives.length) {
            return (
              <ul>
                {object.objectives.map((objective, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{objective.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  return (
    <div className="container-fluid">
      {witnessObject.evidences && witnessObject.evidences.length ? (
        <Table
          settings={evidenceTableSettings}
          data={[...witnessObject.evidences].sort(
            (a, b) => a.fake_id - b.fake_id
          )}
          hideTitle
        />
      ) : (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Your Linked Evidence will appear here
          </Bold18Font>
        </NoRecords>
      )}
    </div>
  );
};

export default Objectives;
