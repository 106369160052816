import React, { useState } from "react";
import { Container, Column } from "./styled";

import Chapters from "./Chapters";
import NotebookBlock from "./NotebookBlock";
import TagsPanel from "./TagsPanel";

const Sidebar = () => {
  const [showTags, setShowTags] = useState(false);

  return (
    <Container>
      <Column>
        <NotebookBlock />
        <TagsPanel showTags={showTags} setShowTags={setShowTags} />
        <Chapters showTags={showTags} setShowTags={setShowTags} />
      </Column>
      <Column style={{ paddingBottom: 20 }}></Column>
    </Container>
  );
};

export default Sidebar;
