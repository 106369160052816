import {
  TagElementCoa,
  TagElementIssue,
  TagElementTheme,
} from "../components/TagElementStyled/TagElementStyled";
import { Bold14Font } from "../components/FontsNewComponent/Fonts";
import React, { Fragment } from "react";
import {
  APPLICATION,
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  DOCUMENT,
  EVIDENCE_DOCUMENTS,
  EXAMWITNESS,
  SUB_EVIDENCE,
  TOPIC,
  WITNESSES,
} from "../containers/BaseApp/Documents/Documents";
import moment from "moment";

export const EditCoaStyles = (
  elementsParentList,
  elementsList,
  defenceElementsList,
  case_object
) => {
  const list = [];
  if (case_object) {
    // console.log(case_object);
    case_object.causes_of_action.forEach((action, case_index) => {
      // console.log("coa one");
      // console.log(action);
      const parent = elementsParentList.find(
        (e) =>
          e.id_type === action.id_type && action.counterclaim === e.counterclaim
      );
      if (parent) {
        // console.log("parent");
        list.push({
          ...parent,
          index: case_index,
          name: action.counterclaim
            ? `(C-CLAIM) ${parent.type.name}`
            : parent.type.name,
          typeCOA: "parent",
          tagColor: TagElementCoa,
        });
      }
      action.elements.forEach((element) => {
        const kf = elementsList.find(
          (e) => e.id === element.id && e.counterclaim === action.counterclaim
        );
        if (kf) {
          // console.log("kf");
          list.push({
            ...kf,
            name: action.counterclaim ? `(C-CLAIM) ${kf.name}` : kf.name,
            typeCOA: "children",
            index: case_index,
            tagColor: TagElementCoa,
          });
        }
      });
      action.defence_elements.forEach((element) => {
        const def = defenceElementsList.find(
          (p) => p.id === element.id && p.counterclaim === action.counterclaim
        );
        if (def) {
          // console.log("def");
          list.push({
            ...def,
            name: action.counterclaim ? `(C-CLAIM) ${def.name}` : def.name,
            typeCOA: "children_def",
            index: case_index,
            tagColor: TagElementCoa,
          });
        }
      });
    });
  }
  // console.log("list");
  // console.log(list);
  return list;
};
export const EditIssuesStyles = (issuesParentList, issuesList, case_object) => {
  const list = [];
  if (case_object) {
    case_object.issues.forEach((issue, case_index) => {
      const parent = issuesParentList.find((i) => i.id === issue.id);
      if (parent) {
        list.push({
          ...parent,
          index: case_index,
          typeCOA: "parent",
          tagColor: TagElementIssue,
        });
      }
      issue.key_facts.forEach((kf) => {
        const item = issuesList.find((i) => i.id === kf.id);
        if (item) {
          list.push({
            ...item,
            index: case_index,
            typeCOA: "children",
            tagColor: TagElementIssue,
          });
        }
      });
    });
  }
  return list;
};
export const EditThemesStyles = (themesParentList, themesList, case_object) => {
  const list = [];
  if (case_object) {
    case_object.themes.forEach((theme, case_index) => {
      const parent = themesParentList.find((i) => i.id === theme.id);
      if (parent) {
        list.push({
          ...parent,
          index: case_index,
          typeCOA: "parent",
          tagColor: TagElementTheme,
        });
      }
      theme.sub_themes.forEach((kf) => {
        const item = themesList.find((i) => i.id === kf.id);
        if (item) {
          list.push({
            ...item,
            index: case_index,
            typeCOA: "children",
            tagColor: TagElementTheme,
          });
        }
      });
    });
  }
  return list;
};
export const handleFlatRelevanceElements = (
  elementsParentList,
  elementsList,
  defenceElementsList,
  issuesParentList,
  issuesList,
  themesParentList,
  themesList,
  caseObject
) => {
  // console.log("STARTTT");
  // console.log(elementsParentList);
  // console.log(
  //   EditCoaStyles(
  //     elementsParentList,
  //     elementsList,
  //     defenceElementsList,
  //     caseObject
  //   )
  // );
  return {
    elements: EditCoaStyles(
      elementsParentList,
      elementsList,
      defenceElementsList,
      caseObject
    ),
    themes: EditThemesStyles(themesParentList, themesList, caseObject),
    issues: EditIssuesStyles(issuesParentList, issuesList, caseObject),
  };
};
export const ShowIssues = ({ object, as_list, case_object }) => {
  let index_ = 1;
  let other = object.issues.filter((kf) => {
    return !object.issues_parent.find((p) => p.id === kf.issue.id);
  });
  let otherDict = {};
  if (other.length) {
    other.forEach((v) => {
      let id = "other";
      if (v.issue && v.issue.id) {
        id = v.issue.id;
      }
      id =
        case_object && v.issue && v.issue.id
          ? case_object.issues.findIndex((e) => e.id === v.issue.id)
          : id;
      if (!otherDict[id]) {
        otherDict[id] = [];
      }
      otherDict[id].push(v);
    });
  }
  if (!as_list) {
    return (
      <Fragment>
        {object.issues_parent.map((e, index) => {
          const case_index = case_object
            ? case_object.issues.findIndex((v) => v.id === e.id)
            : index;
          index_ += index;
          return (
            <Fragment>
              <StyledIssueParent index={case_index} key={index}>
                {e.name}
              </StyledIssueParent>
              {object.issues
                .filter((kf) => kf.issue.id === e.id)
                .map((kf, i) => {
                  return (
                    <StyledIssueParent index={case_index} key={i}>
                      {kf.name}
                    </StyledIssueParent>
                  );
                })}
            </Fragment>
          );
        })}
        {Object.keys(otherDict).map((e, index) => {
          return (
            <Fragment>
              {otherDict[e].map((value, i) => (
                <StyledIssue index={e} key={value.id}>
                  {value.name}
                </StyledIssue>
              ))}
            </Fragment>
          );
        })}
      </Fragment>
    );
  } else {
    const list = [];
    let index_ = 1;
    object.issues_parent.forEach((e, index) => {
      index_ += index;
      const case_index = case_object
        ? case_object.issues.findIndex((v) => v.id === e.id)
        : index;
      list.push(
        <StyledIssueParent index={case_index} key={index}>
          {e.name}
        </StyledIssueParent>
      );
      object.issues
        .filter((kf) => kf.issue.id === e.id)
        .forEach((kf, i) => {
          list.push(
            <StyledIssueParent index={case_index} key={i}>
              {kf.name}
            </StyledIssueParent>
          );
        });
    });
    Object.keys(otherDict).forEach((e, index) => {
      otherDict[e].forEach((value, i) => {
        list.push(
          <StyledIssueParent index={e} key={value.id}>
            {value.name}
          </StyledIssueParent>
        );
      });
    });
    return list;
  }
};
export const ShowThemes = ({ object, as_list, case_object }) => {
  let index_ = 1;
  let other = object.themes.filter((kf) => {
    return !object.themes_parent.find((p) => p.id === kf.theme.id);
  });
  let otherDict = {};
  if (other.length) {
    other.forEach((v) => {
      let id = "other";
      if (v.theme && v.theme.id) {
        id = v.theme.id;
      }
      id =
        case_object && v.theme && v.theme.id
          ? case_object.themes.findIndex((e) => e.id === v.theme.id)
          : id;
      if (!otherDict[id]) {
        otherDict[id] = [];
      }
      otherDict[id].push(v);
    });
  }
  if (!as_list) {
    return (
      <Fragment>
        {object.themes_parent.map((e, index) => {
          index_ += index;
          const case_index = case_object
            ? case_object.themes.findIndex((v) => v.id === e.id)
            : index;
          return (
            <Fragment>
              <StyledTheme index={case_index} key={index}>
                {e.name}
              </StyledTheme>
              {object.themes
                .filter((kf) => kf.theme.id === e.id)
                .map((kf, i) => {
                  return (
                    <StyledTheme index={case_index} key={i}>
                      {kf.name}
                    </StyledTheme>
                  );
                })}
            </Fragment>
          );
        })}
        {Object.keys(otherDict).map((e, index) => {
          return (
            <Fragment>
              {otherDict[e].map((value, i) => (
                <StyledTheme index={e} key={value.id}>
                  {value.name}
                </StyledTheme>
              ))}
            </Fragment>
          );
        })}
      </Fragment>
    );
  } else {
    const list = [];
    let index_ = 1;
    object.themes_parent.forEach((e, index) => {
      index_ += index;
      const case_index = case_object
        ? case_object.themes.findIndex((v) => v.id === e.id)
        : index;
      list.push(
        <StyledTheme index={case_index} key={index}>
          {e.name}
        </StyledTheme>
      );
      object.themes
        .filter((kf) => kf.theme.id === e.id)
        .forEach((kf, i) => {
          list.push(
            <StyledTheme index={case_index} key={i}>
              {kf.name}
            </StyledTheme>
          );
        });
    });
    Object.keys(otherDict).forEach((e, index) => {
      otherDict[e].forEach((value, i) => {
        list.push(
          <StyledTheme index={e} key={value.id}>
            {value.name}
          </StyledTheme>
        );
      });
    });
    return list;
  }
};
export const ShowCoa = ({ object, as_list, case_object }) => {
  let index_ = 1;
  const l = [];
  if (case_object) {
    {
      case_object.causes_of_action.forEach((action, case_index) => {
        index_ += case_index;
        const parent = object.causes_of_action_parent.find((e) =>
          e.id_type
            ? e.id === action.id
            : e.id === action.id_type && action.counterclaim === e.counterclaim
        );
        if (parent) {
          let name;
          if (parent.type) {
            if (action.counterclaim) {
              name = `(C-CLAIM) ${parent.type.name}`;
            } else {
              name = parent.type.name;
            }
          } else {
            name = parent.name;
          }
          l.push(
            <StyledCoa index={case_index} key={index_}>
              {name}
            </StyledCoa>
          );
          index_++;
        }
        action.elements.forEach((element) => {
          const kf = object.causes_of_action.find(
            (e) => e.id === element.id && e.counterclaim === action.counterclaim
          );
          if (kf) {
            l.push(
              <StyledCoa index={case_index} key={index_}>
                {action.counterclaim ? "(C-CLAIM) " + kf.name : kf.name}
              </StyledCoa>
            );
          }
        });
        action.defence_elements.forEach((element) => {
          const def = object.causes_of_action_defensive.find(
            (p) => p.id === element.id && p.counterclaim === action.counterclaim
          );
          if (def) {
            l.push(
              <StyledCoa index={case_index} key={index_}>
                {action.counterclaim ? "(C-CLAIM) " + def.name : def.name}
              </StyledCoa>
            );
          }
        });
      });
    }
    if (as_list) {
      return l;
    }
    return <Fragment>{l}</Fragment>;
  }
};
export const StyledCoaParent = ({ children, index }) => {
  return (
    <TagElementCoa index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementCoa>
  );
};
export const StyledCoa = ({ children, index }) => {
  return (
    <TagElementCoa index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementCoa>
  );
};
export const StyledIssueParent = ({ children, index }) => {
  return (
    <TagElementIssue index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementIssue>
  );
};
export const StyledIssue = ({ children, index }) => {
  return (
    <TagElementIssue index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementIssue>
  );
};
export const StyledTheme = ({ children, index }) => {
  return (
    <TagElementTheme index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementTheme>
  );
};
export const StyledThemeParent = ({ children, index }) => {
  return (
    <TagElementTheme index={index}>
      <Bold14Font
        as="div"
        style={{
          color: "inherit",
        }}
      >
        {children}
      </Bold14Font>
    </TagElementTheme>
  );
};

export const setTagFieldToObject = (
  item,
  case_object,
  tags = [],
  withProofs
) => {
  if (
    (item.causes_of_action_parent && item.causes_of_action_parent.length) ||
    (item.causes_of_action && item.causes_of_action.length) ||
    (item.causes_of_action_defensive && item.causes_of_action_defensive.length)
  ) {
    ShowCoa({ object: item, as_list: true, case_object }).forEach((v) =>
      tags.push({ type: "causes_of_action", element: v })
    );
  }

  if (
    (item.issues_parent && item.issues_parent.length) ||
    (item.issues && item.issues.length)
  ) {
    ShowIssues({
      object: item,
      as_list: true,
      case_object,
    }).forEach((v) => tags.push({ type: "issues", element: v }));
  }
  if (
    (item.themes_parent && item.themes_parent.length) ||
    (item.themes && item.themes.length)
  ) {
    ShowThemes({
      object: item,
      as_list: true,
      case_object,
    }).forEach((v) => tags.push({ type: "themes", element: v }));
  }
  if (withProofs && item.proofs) {
    item.proofs.forEach((el, index) => {
      tags.push({
        type: "proofs",
        element: (
          <StyledIssue index={index} key={index}>
            {el.name}
          </StyledIssue>
        ),
      });
    });
  }
  if (withProofs && item.reliefs) {
    item.reliefs.forEach((el, index) => {
      tags.push({
        type: "reliefs",
        element: (
          <StyledTheme index={index} key={index}>
            {el.name}
          </StyledTheme>
        ),
      });
    });
  }
  return { ...item, tags };
};

export const setTagField = (case_object, evidenceList, withProofs) => {
  const temp = [];
  if (evidenceList) {
    evidenceList.forEach((item, index) => {
      temp.push({
        ...item,
        tags: [],
      });
      if (
        (item.causes_of_action_parent && item.causes_of_action_parent.length) ||
        (item.causes_of_action && item.causes_of_action.length) ||
        (item.causes_of_action_defensive &&
          item.causes_of_action_defensive.length) ||
        (item.issues_parent && item.issues_parent.length) ||
        (item.issues && item.issues.length) ||
        (item.themes_parent && item.themes_parent.length) ||
        (item.themes && item.themes.length)
      ) {
        const tags = [];
        if (
          (item.causes_of_action_parent &&
            item.causes_of_action_parent.length) ||
          (item.causes_of_action && item.causes_of_action.length) ||
          (item.causes_of_action_defensive &&
            item.causes_of_action_defensive.length)
        ) {
          ShowCoa({ object: item, as_list: true, case_object }).forEach((v) =>
            tags.push({ type: "causes_of_action", element: v })
          );
        }

        if (
          (item.issues_parent && item.issues_parent.length) ||
          (item.issues && item.issues.length)
        ) {
          ShowIssues({
            object: item,
            as_list: true,
            case_object,
          }).forEach((v) => tags.push({ type: "issues", element: v }));
        }
        if (
          (item.themes_parent && item.themes_parent.length) ||
          (item.themes && item.themes.length)
        ) {
          ShowThemes({
            object: item,
            as_list: true,
            case_object,
          }).forEach((v) => tags.push({ type: "themes", element: v }));
        }
        if (withProofs && item.proofs) {
          item.proofs.forEach((el, index) => {
            tags.push({
              type: "proofs",
              element: (
                <StyledIssue index={index} key={index}>
                  {el.name}
                </StyledIssue>
              ),
            });
          });
        }
        if (withProofs && item.reliefs) {
          item.reliefs.forEach((el, index) => {
            tags.push({
              type: "reliefs",
              element: (
                <StyledTheme index={index} key={index}>
                  {el.name}
                </StyledTheme>
              ),
            });
          });
        }
        temp[index] = {
          ...temp[index],
          tags: [...tags],
        };
      }
    });
  }
  return temp;
};

export const setTagFieldClean = (evidenceList) => {
  const temp = [];
  evidenceList.forEach((item, index) => {
    temp.push({
      ...item,
    });

    // Create tag field
    if (
      (item.causes_of_action_parent && item.causes_of_action_parent.length) ||
      (item.causes_of_action && item.causes_of_action.length) ||
      (item.causes_of_action_defensive &&
        item.causes_of_action_defensive.length) ||
      (item.issues_parent && item.issues_parent.length) ||
      (item.issues && item.issues.length) ||
      (item.themes_parent && item.themes_parent.length) ||
      (item.themes && item.themes.length)
    ) {
      const tags = [];

      item.causes_of_action_parent.forEach((el) => {
        tags.push(el.type.name);
      });
      item.causes_of_action.forEach((el) => {
        tags.push(el.name);
      });
      item.causes_of_action_defensive.forEach((el) => {
        tags.push(el.name);
      });

      item.issues_parent.forEach((el) => {
        tags.push(el.name);
      });
      item.issues.forEach((el) => {
        tags.push(el.name);
      });

      item.themes_parent.forEach((el) => {
        tags.push(el.name);
      });
      item.themes.forEach((el) => {
        tags.push(el.name);
      });

      temp[index] = {
        ...temp[index],
        tags_clean: [...tags],
      };
    }
  });
  return temp;
};

/**
 * Re-structure list of cases objects to fit setTagField function
 * @param casesList
 * @returns {*}
 */
export const reStructureCasesList = (casesList) => {
  return casesList.map((el) => {
    const caseObjectCopy = JSON.parse(JSON.stringify(el));
    delete caseObjectCopy.causes_of_action;
    delete caseObjectCopy.themes;
    delete caseObjectCopy.issues;

    // re-structure COA
    caseObjectCopy.causes_of_action_parent = [...el.causes_of_action];
    el.causes_of_action.forEach((coa, index) => {
      if (coa.elements) {
        caseObjectCopy.causes_of_action = coa.elements.map((item) => {
          item.type = el.causes_of_action[index].type;
          return item;
        });
      }
      if (coa.defence_elements) {
        caseObjectCopy.causes_of_action_defensive = [...coa.defence_elements];
      }
    });

    // re-structure themes
    caseObjectCopy.themes_parent = [...el.themes];
    el.themes.forEach((theme, index) => {
      if (theme.sub_themes) {
        caseObjectCopy.themes = [...theme.sub_themes];
      }
    });

    // re-structure issues
    caseObjectCopy.issues_parent = [...el.issues];
    el.issues.forEach((issue, index) => {
      if (issue.key_facts) {
        caseObjectCopy.issues = [...issue.key_facts];
      }
    });

    return caseObjectCopy;
  });
};

/**
 * Re-structure case object to fit setTagField function
 * @param caseObject
 * @returns {*}
 */
export const reStructureCaseObject = (caseObject) => {
  const caseObjectCopy = JSON.parse(JSON.stringify(caseObject));
  delete caseObjectCopy.causes_of_action;
  delete caseObjectCopy.themes;
  delete caseObjectCopy.issues;

  caseObjectCopy.causes_of_action_original = JSON.parse(
    JSON.stringify(caseObject.causes_of_action)
  );
  caseObjectCopy.themes_original = JSON.parse(
    JSON.stringify(caseObject.themes)
  );
  caseObjectCopy.issues_original = JSON.parse(
    JSON.stringify(caseObject.issues)
  );

  // re-structure COA
  caseObjectCopy.causes_of_action_parent = [...caseObject.causes_of_action];
  caseObject.causes_of_action.forEach((coa, index) => {
    if (coa.elements) {
      caseObjectCopy.causes_of_action = coa.elements.map((item) => {
        item.type = caseObject.causes_of_action[index].type;
        return item;
      });
    }
    if (coa.defence_elements) {
      caseObjectCopy.causes_of_action_defensive = [...coa.defence_elements];
    }
  });

  // re-structure themes
  caseObjectCopy.themes_parent = [...caseObject.themes];
  caseObject.themes.forEach((theme, index) => {
    if (theme.sub_themes) {
      caseObjectCopy.themes = [...theme.sub_themes];
    }
  });

  // re-structure issues
  caseObjectCopy.issues_parent = [...caseObject.issues];
  caseObject.issues.forEach((issue, index) => {
    if (issue.key_facts) {
      caseObjectCopy.issues = [...issue.key_facts];
    }
  });

  return caseObjectCopy;
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string === "string") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const getLogFieldsOrder = (type) => {
  let order;
  switch (type) {
    case EVIDENCE_DOCUMENTS:
    case SUB_EVIDENCE:
      order = [
        "label",
        "type",
        "subtype",
        "date",
        "associated witness",
        "file",
        "witnesses",
        "related evidence",
        "causes of action",
        "offence",
        "elements",
        "defence elements",
        "themes",
        "sub themes",
        "issues",
        "key facts",
        "objectives",
        "summary",
        "notes",
        "preempt",
        "status",
        "from",
        "to",
        "priority",
        "core book",
        "reference",
      ];
      break;
    case CASE_DOCUMENTS:
      order = [
        "type",
        "label",
        "firm name",
        "case type",
        "record no",
        "legal aid no",
        "parties",
        "case theory",
        "causes of action",
        "offence",
        "elements",
        "defence elements",
        "themes",
        "sub themes",
        "issues",
        "key facts",
      ];
      break;
    case TOPIC:
      order = [
        "name",
        "evidences",
        "evidence",
        "causes of action",
        "offence",
        "elements",
        "defence elements",
        "themes",
        "sub themes",
        "issues",
        "key facts",
        "objectives",
        "exam lines",
      ];
      break;
    case APPLICATION:
      order = [
        "label",
        "moving parties",
        "respondent parties",
        "date",
        "reliefs",
        "proofs",
      ];
      break;
    case APPLICATION_DOCUMENTS:
      order = [
        "label",
        "type",
        "doc type",
        "date",
        "probative status",
        "file",
        "parties",
        "reliefs",
        "proofs",
        "application",
      ];
      break;
    case AUTHORITIES_DOCUMENTS:
      order = [
        "title",
        "type",
        "jurisdiction",
        "key sections",
        "citation",
        "file",
        "status",
        "causes of action",
        "offence",
        "reliefs",
        "proofs",
        "elements",
        "defence elements",
        "themes",
        "sub themes",
        "issues",
        "key facts",
        "application",
      ];
      break;
    case WITNESSES:
      order = [
        "name",
        "party.name",
        "party",
        "class",
        "reason for testimony",
        "background",
        "causes of action",
        "offence",
        "elements",
        "defence elements",
        "themes",
        "sub themes",
        "issues",
        "key facts",
        "evidence",
        "objectives",
        "exam lines",
      ];
      break;
    case EXAMWITNESS:
      order = [
        "name",
        "party.name",
        "class",
        "reason for testimony",
        "background",
        "topics",
        "evidence",
        "objectives",
        "exam lines",
      ];
      break;
    case DOCUMENT:
      order = [
        "label",
        "classes.label",
        "date",
        "file",
        "bundles",
        "parties",
        "file size",
        "document type",
      ];
      break;
    default:
      order = [];
      break;
  }
  return order;
};

export const reorderFields = (type, a, b) => {
  const order = getLogFieldsOrder(type);
  const search1 = order.indexOf(a);
  const search2 = order.indexOf(b);
  if (search1 !== false && search2 !== false) {
    return search1 > search2 ? 1 : -1;
  }
  if (search1 !== false && search2 === false) {
    return -1;
  }
  if (search2 !== false && search1 === false) {
    return 1;
  }
  if (search1 === false && search2 === false) {
    return 100;
  }
  return 0;
};
export const changeSorting = (item, sortCondition, setSortCondition) => {
  if (sortCondition && sortCondition.field === item) {
    if (sortCondition.direction === "desc") {
      setSortCondition(null);
    } else {
      setSortCondition({
        field: item,
        direction: "desc",
      });
    }
  } else {
    setSortCondition({
      field: item,
      direction: "asc",
    });
  }
};
export const sortByDateTime = (a, b, sortCondition) => {
  if (sortCondition) {
    let item_a = Object.assign({}, sortCondition.direction === "desc" ? b : a);
    let item_b = Object.assign({}, sortCondition.direction === "desc" ? a : b);
    return moment(item_a[sortCondition.field] ?? "2000-01-01 00:00:00").diff(
      moment(item_b[sortCondition.field] ?? "2000-01-01 00:00:00")
    );
  } else {
    return 1;
  }
};
export const sortDefault = (a, b, sortCondition) => {
  if (sortCondition) {
    let item_a = Object.assign({}, sortCondition.direction === "desc" ? b : a);
    let item_b = Object.assign({}, sortCondition.direction === "desc" ? a : b);
    return item_a[sortCondition.field] - item_b[sortCondition.field];
  } else {
    return 1;
  }
};
export const sortAlphabetical = (a, b, sortCondition) => {
  if (sortCondition) {
    let item_a = Object.assign({}, sortCondition.direction === "desc" ? b : a);
    let item_b = Object.assign({}, sortCondition.direction === "desc" ? a : b);
    if (!item_a[sortCondition.field]) {
      item_a[sortCondition.field] = "";
    }
    if (!item_b[sortCondition.field]) {
      item_b[sortCondition.field] = "";
    }
    item_a[sortCondition.field] =
      typeof item_a[sortCondition.field] === "string"
        ? item_a[sortCondition.field].toLowerCase()
        : item_a[sortCondition.field].toString();
    item_b[sortCondition.field] =
      typeof item_b[sortCondition.field] === "string"
        ? item_b[sortCondition.field].toLowerCase()
        : item_b[sortCondition.field].toString();
    return item_a[sortCondition.field].localeCompare(
      item_b[sortCondition.field],
      undefined,
      {
        numeric: true,
        sensitivity: "base",
      }
    );
  }
  return 1;
};
export const sort = (a, b, sortCondition) => {
  if (!sortCondition) {
    return 1;
  } else {
    if (
      [
        "label",
        "title",
        "name",
        "type",
        "document_type",
        "reference",
        "document_type_name",
        "status",
        "case_type",
        "doc_type",
        "new_case_type",
        "key_sections",
        "probative_status",
        "sections",
        "firm_name",
        "record_no",
        "jurisdiction",
        "citation",
      ].indexOf(sortCondition.field) !== -1
    ) {
      return sortAlphabetical(a, b);
    }
    if (
      ["date", "created_at", "updated_at"].indexOf(sortCondition.field) !== -1
    ) {
      return sortByDateTime(a, b);
    }
    return sortDefault(a, b);
  }
};
