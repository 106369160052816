import React, { useEffect, useState } from "react";
import SignPagesWrapper from "../../../components/SignPagesWrapper";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import SignUpGoogleAuthForm from "../../../components/forms/SignUpGoogleAuthForm";
import FullScreenBarLoader from "../../../components/FullScreenBarLoader";

const SignUpGoogleAuthPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  useEffect(() => {
    if (user && user.role && user.country && !user.features.length) {
      history.replace(route.baseApp.app);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <FullScreenBarLoader />;
  }

  return (
    <SignPagesWrapper>
      <SignUpGoogleAuthForm />
    </SignPagesWrapper>
  );
};

export default SignUpGoogleAuthPage;
