import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const RoundInputStyled = styled.input`
  width: ${rem(`42px`)};
  height: ${rem(`42px`)};
  outline: none;
  border-radius: 50%;
  caret-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.light};
  padding: ${rem(`10px 15px`)};

  :hover,
  :focus,
  :active {
    border-color: ${({ theme }) => theme.colors.blue};
  }

  @media (max-width: 575.98px) {
    width: 9rem;
    height: 9rem;
    padding: 0 3.2rem;
    font-size: 4rem;
  }

  @media (max-width: 767.98px) {
    width: 7rem;
    height: 7rem;
    padding: 0 2.4rem;
    font-size: 3rem;
  }
`;

export default RoundInputStyled;
