import styled from "styled-components/macro";

const TableDivStyled = styled.td`
  ${({ clickAction }) => (clickAction ? `cursor: pointer` : null)};
  padding: 10px 15px;
  border: none;
  vertical-align: middle !important;
  //overflow-wrap: anywhere;
`;

export default TableDivStyled;
