import React from "react";
import PropTypes from "prop-types";

import TestimonialCardStyled from "./styled/TestimonialCardStyled";
import TestimonialCardAvatarPosition from "./styled/TestimonialCardAvatarPosition";

import { Bold24Font, Medium14Font, Medium18Font } from "../Fonts/Fonts";
import rem from "../../utils/rem";

const TestimonialCard = ({ avatar, name, company, text }) => {
  return (
    <TestimonialCardStyled>
      <TestimonialCardAvatarPosition>
        <img src={avatar} alt="" className="img-fluid" style={{borderRadius: '41px'}}/>
      </TestimonialCardAvatarPosition>
      <div className="container-fluid p-4 mt-4">
        <div className="row">
          <div className="col">
            <Bold24Font>{name}</Bold24Font>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <Medium14Font style={{ textTransform: "uppercase" }}>
              {company}
            </Medium14Font>
          </div>
        </div>
        <div className="row">
          <div className="col"><Medium18Font>{text}</Medium18Font></div>
        </div>
      </div>
    </TestimonialCardStyled>
  );
};

TestimonialCard.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default TestimonialCard;
