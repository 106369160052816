import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logOut } from "../auth";
import { RESET_REQUESTED_URL, SIGN_OUT } from "../../../redux/types";
import { route } from "../../../routes";

const Logout = () => {
  const dispatch = useDispatch();
  const isUserAuth = useSelector((state) => state.auth.isAuth);
  const isAdmin = useSelector((state) => state.auth.user.is_admin);
  const history = useHistory();

  const aftersSignOut = (isAdmin) => {
    if (isAdmin) {
      history.push("/admin");
    } else {
      history.push(route.index);
    }
  };

  useEffect(() => {
    if (isUserAuth) {
      const isAdminStatus = isAdmin;
      logOut();
      dispatch({ type: SIGN_OUT });
      dispatch({ type: RESET_REQUESTED_URL });
      document.title = "TrialProofer";
      aftersSignOut(isAdminStatus);
      // history.push(route.index);
    } else {
      history.push(route.index);
    }
  }, []);

  return null;
};

export default Logout;
