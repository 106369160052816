import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import { getBundles } from "../../Auth/auth";
import SelectAnotherTry from "../../../components/Select/SelectAnotherTry";

const BundlesSelectForm = ({ bundles, callback, class_name, saving, idCase }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(true);
  const [selectedElement, setSelectedElements] = useState(bundles);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const resp = await getBundles(dispatch, idCase);
      setData(resp.filter((v) => v.class_name === class_name));
      setIsLoading(false);
    };
    loadData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const handleTypeChange = (select) => {
    setSelectedElements(
      select ? data.filter((v) => select.find((b) => v.id === b.value)) : []
    );
  };

  let selects = data.map((e) => {
    return { label: e.label, value: e.id };
  });

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <SelectAnotherTry
            options={selects}
            isClearable={false}
            isMulti
            placeholder={"Select bundle"}
            defaultValue={
              selectedElement && selectedElement.length
                ? selects.filter((v) => {
                    return selectedElement.find((b) => b.id === v.value);
                  })
                : []
            }
            reactSelectStyles={{
              control: (provided) => ({
                ...provided,
                height: "unset !important",
                minHeight: "42px !important",
                borderRadius: "21px !important",
              }),
            }}
            onChange={handleTypeChange}
          />
        </div>
      </div>
      <div className="form-group px-3">
        <ButtonNew
          clickHandler={() => {
            callback(selectedElement);
          }}
          primary
          wide
          loading={saving}
          disabled={saving}
        >
          Save
        </ButtonNew>
      </div>
    </Fragment>
  );
};

export default BundlesSelectForm;
