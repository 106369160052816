import styled, { css } from "styled-components/macro";
import rem from "../../../../utils/rem";
import { fadeIn } from "react-animations";
import { keyframes } from "styled-components";
import { Bold18Font } from "../../../FontsNewComponent/Fonts";
import personImg from "../../../../assets/img3/svg/person-icon.svg";

const bounceAnimation = keyframes`${fadeIn}`;

const withPersonBackground = css`
  background: ${({ theme }) => theme.colors.white} url(${personImg}) no-repeat
    right bottom;
  background-size: 54.5px 75px;
`;

const CaseCardStyled = styled.div`
  border-radius: 4px;
  cursor: pointer;
  animation: 1s ${bounceAnimation};
  //width: 23%;
  width: 100%;
  min-height: 256px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-top: 4px solid;
  ${({ archived, theme }) =>
    archived
      ? `border-top-color: ${theme.colors.gray}`
      : `border-top-color: ${theme.colors.turquoise}`};
  ${({ invited, theme }) =>
    invited ? `border-top-color: ${theme.colors.blue}` : ``};
  ${({ noArchivedTopBorder, theme }) =>
    noArchivedTopBorder ? `border: 1px solid ${theme.colors.gray};` : null};

  padding: ${({ admin }) =>
    admin ? rem(`30px 20px 0 20px`) : rem(`30px 20px 20px 20px`)};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  ${({ withPerson }) => (withPerson ? withPersonBackground : null)};

  @media (max-width: 575.98px) {
    padding: ${({ admin }) =>
      admin ? "0.65rem 0.65rem 0 0.65rem" : "0.65rem 0.65rem 0.65rem 0.65rem"};
    ${Bold18Font} {
      font-size: 1.725rem;
    }
  }
`;

export default CaseCardStyled;
