import styled from "styled-components";
import filterIcon from "@/assets/img/notebook/filters.svg";
import activeFilterIcon from "@/assets/img/notebook/filters_active.svg";

export const Container = styled.div`
  width: 18%;
  min-width: 18%;
  border-left: 1px solid rgba(55, 74, 194, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  background: white;
  overflow-y: auto;
`;

export const Heading = styled.div`
  border-bottom: 0.75px solid #dcdce6;
  padding: 15px;
  padding-bottom: 15px;
  //
`;

export const Title = styled.h2`
  color: rgba(15, 18, 47, 0.6);
  font-size: 18px;
  font-weight: 600;
  //
`;

export const ActiveFilterButton = styled.img.attrs({
  src: activeFilterIcon,
})`
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const FilterButton = styled.img.attrs({
  src: filterIcon,
})`
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const SearchBar = styled.div`
  //
`;

export const Section = styled.div`
  //
`;

export const Label = styled.div`
  color: #0f122f;
  font-weight: 600;
  font-size: 14px;
  margin-left: 12px;
  cursor: pointer;
  //
`;

export const Tags = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  //
`;

export const CloseIcon = styled.img`
  position: absolute;
  right: 15px;
  top: 12px;
  width: 18px;
  padding: 2px;
  border-radius: 100%;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;
