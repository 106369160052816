import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import InputNew from "../../InputNew/InputNew";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { useDispatch } from "react-redux";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import Label from "../AddCardForm/Label";
import { randomNumberRange } from "../../../utils";
import ListAddButton from "../../buttons/ListAddButton";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox";

const AddTopicForm = ({
  topicObject,
  witnessObject,
  afterSubmitHandler,
  afterDeleteHandler,
}) => {
  const [topicLabel, setTopicLabel] = useState(
    topicObject && topicObject.name ? topicObject.name : ""
  );
  const [evidenceLinkedList, setEvidenceLinkedList] = useState(
    topicObject && topicObject.evidences ? topicObject.evidences : []
  );
  const [evidenceFilterOptions, setEvidenceFilterOptions] = useState([
    { label: "All", value: "All" },
  ]);
  const [
    evidenceSelectedFilterOption,
    setEvidenceSelectedFilterOption,
  ] = useState({});
  const [objectivesList, setObjectivesList] = useState(
    topicObject && topicObject.objectives
      ? [...topicObject.objectives]
      : [{ id: "new_" + Math.random(), name: "" }]
  );
  const [onSubmit, setOnSubmit] = useState(false);
  const dispatch = useDispatch();
  let editableStringsRef = useRef([]);
  const [randomNumberForId, setRandomNumberForId] = useState(
    randomNumberRange()
  );
  let form = useRef(null);

  const onChangeLabelHandler = (event) => {
    setTopicLabel(event.target.value);
  };

  // add new objective input
  const addObjectiveHandler = (event) => {
    event.preventDefault();
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      setObjectivesList((prevState) => [
        ...prevState,
        { id: "new_" + Math.random(), name: "" },
      ]);
    }
  };
  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    item.fake_id = item.id;
    const findItem = (element) => element.fake_id === item.fake_id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };
  const filter = (element) => {
    let check = true;

    if (
      Object.keys(evidenceSelectedFilterOption).length &&
      evidenceSelectedFilterOption.value !== "All"
    ) {
      check = element.type === evidenceSelectedFilterOption.value;
    }

    return check;
  };
  const handleDelete = async () => {
    if (afterDeleteHandler) {
      afterDeleteHandler(topicObject.id);
    }
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const deleteTopicModal = () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      beforeCloseHandler: async () => {
        await handleDelete();
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  const onChangeFilterHandler = (selectedOption) => {
    setEvidenceSelectedFilterOption(selectedOption);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!onSubmit) {
      setOnSubmit(true);
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      const submitObject = {
        id:
          topicObject && topicObject.id
            ? topicObject.id
            : "new_" + Math.random(),
        name: topicLabel,
        evidences: evidenceLinkedList ?? [],
        causes_of_action_defensive:
          topicObject && topicObject.causes_of_action_defensive
            ? topicObject.causes_of_action_defensive
            : [],
        causes_of_action_parent:
          topicObject && topicObject.causes_of_action_parent
            ? topicObject.causes_of_action_parent
            : [],
        causes_of_action:
          topicObject && topicObject.causes_of_action
            ? topicObject.causes_of_action
            : [],
        issues: topicObject && topicObject.issues ? topicObject.issues : [],
        issues_parent:
          topicObject && topicObject.issues_parent
            ? topicObject.issues_parent
            : [],
        themes: topicObject && topicObject.themes ? topicObject.themes : [],
        themes_parent:
          topicObject && topicObject.themes_parent
            ? topicObject.themes_parent
            : [],
        fake_id:
          topicObject && topicObject.fake_id ? topicObject.fake_id : "new",
        objectives: objectivesList.filter((objective) => objective.name !== ""),
      };
      if (topicObject && topicObject.id) {
        let index = witnessObject.topics.findIndex(
          (v) => v.id === submitObject.id
        );
        let new_obj = [...witnessObject.topics];
        if (index !== -1) {
          new_obj[index] = { ...new_obj[index], ...submitObject };
        }
        afterSubmitHandler(new_obj);
      } else {
        let old_topics = witnessObject.topics ?? [];
        afterSubmitHandler([...old_topics, ...[submitObject]]);
      }
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      setOnSubmit(false);
    }
  };
  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  const editObjective = (index, objectiveText) => {
    const objectivesListCopy = [...objectivesList];
    objectivesListCopy[index].name = objectiveText;
    setObjectivesList(objectivesListCopy);
  };
  const deleteObjective = (index) => {
    setObjectivesList(objectivesList.filter((objective, idx) => index !== idx));
  };
  return (
    <Fragment>
      <div className="form-group" style={{ marginBottom: "8px" }}>
        <InputNew
          placeholder="Topic name"
          name="label"
          label="Topic"
          value={topicLabel}
          onChange={onChangeLabelHandler}
          maxLength={255}
          autoFocus={true}
          type="text"
          required
          form={`form-add-topic-${
            topicObject ? topicObject.id : randomNumberForId
          }`}
        />
      </div>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <Label name="Objectives" />
        {objectivesList.map((objective, index) => {
          return (
            <AnimatedDiv key={index} className="form-group pl-3">
              <EditableStringClassComponent
                editName={editObjective}
                dontFocus={index === 0}
                editState={objective.name === ""}
                deleteAction={deleteObjective}
                object={objective.name}
                index={index}
                ref={(ref) => assignRef(ref, index)}
              />
            </AnimatedDiv>
          );
        })}
        <div className="d-flex justify-content-center align-items-center mb-2">
          <ListAddButton
            label="Add Objective"
            clickHandler={addObjectiveHandler}
          />
        </div>
      </div>

      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <form
                ref={form}
                onSubmit={handleSubmit}
                id={`form-add-topic-${
                  topicObject ? topicObject.id : randomNumberForId
                }`}
              >
                <ButtonNew
                  type="submit"
                  // loading={isLoading}
                  // disabled={isLoading}
                  wide
                  primary
                  // clickHandler={handleSubmit}
                >
                  Save
                </ButtonNew>
              </form>
            </div>
            {topicObject && topicObject.id ? (
              <div className="col">
                <ButtonNew wide danger clickHandler={deleteTopicModal}>
                  Delete
                </ButtonNew>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AddTopicForm.propTypes = {
  /**
   * Add object to list
   */
  afterSubmitHandler: PropTypes.func.isRequired,
};

export default AddTopicForm;
