import React, { Fragment, useEffect, useState } from "react";
import InputNew from "../../InputNew/InputNew";
import Button from "../../buttons/Button/Button";
import styled from "styled-components/macro";

import { theme } from "../../../styled-components/Theme/Theme";
import Label from "../AddCardForm/Label";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import {
  EXTRACT_DATA_INITIAL,
  EXTRACT_DATA_SET,
  HIDE_RIGHT_SIDE_BAR,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import AssociateWitnessForm from "../EvidenceForms/AssociateWitnessForm";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "../../../containers/BaseApp/Documents/Documents";
import RsbTabsMenu from "../../RSBTabsMenu";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";
import AssociateElementsThemesIssuesForm from "../EvidenceForms/AssociateElementsThemesIssuesForm";
import {
  authoritiesBulkImportRequest,
  bulkImportApplicationPaperFiles,
  bulkImportEvidence,
  casesBulkImportRequest,
  getApplicationsList,
  updateApplicationPaperById,
  updateAuthorityById,
  updateEvidence,
} from "../../../containers/Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import Select from "../../Select";
import SelectNew from "../../Select/SelectNew";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import AssociateProofs from "../ApplicationsHubForms/AssociateProofs";
import { closeBtnText } from "../../../utils/constants";
const Separator = styled.div`
  border-top: 1px solid ${theme.colors.gray};
  margin-bottom: 15px;
`;
const menuTabs = [
  { title: "Elements" },
  { title: "Themes" },
  { title: "Issues" },
];
export default function ExtractForm({
  blob,
  selectedClass,
  caseId,
  copyTags,
  parentObject,
  caseObject,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [witnesses, setWitnesses] = useState({});
  const [applications, setApplications] = useState([]);
  const [activeMenuTab, setActiveMenuTab] = useState(0);
  const [applicationsLoading, setApplicationsLoading] = useState(true);
  const extractData = useSelector((state) => state.extract.data);
  const changeLabel = (event) => {
    dispatch({
      type: EXTRACT_DATA_SET,
      payload: { ...extractData, label: event.target.value },
    });
  };
  const getAuthorities = async () => {
    if (selectedClass === APPLICATION_DOCUMENTS && !applications.length) {
      setApplicationsLoading(true);
      const resp_applications = await getApplicationsList(caseId, dispatch);
      setApplications(
        resp_applications.map((v) => {
          return { value: v.id, label: v.label, object: v };
        })
      );
      setApplicationsLoading(false);
    }
  };
  useEffect(() => {
    if (copyTags && selectedClass !== CASE_DOCUMENTS) {
      updateObject({
        causes_of_action: parentObject.causes_of_action ?? [],
        causes_of_action_parent: parentObject.causes_of_action_parent ?? [],
        causes_of_action_defensive: parentObject.causes_of_action_defensive ?? [],
        themes: parentObject.themes ?? [],
        themes_parent: parentObject.themes_parent ?? [],
        issues: parentObject.issues ?? [],
        issue_parent: parentObject.issue_parent ?? [],
        proofs: parentObject.proofs ?? [],
        reliefs: parentObject.reliefs ?? [],
      })
    }
  }, [])
  useEffect(() => {
    if (selectedClass === APPLICATION_DOCUMENTS) {
      getAuthorities();
    }
  }, [selectedClass]);
  const onApplicationChange = (selectedOption) => {
    dispatch({
      type: EXTRACT_DATA_SET,
      payload: {
        ...extractData,
        applicationId: selectedOption.value,
        application_object: selectedOption.object,
      },
    });
  };
  const updateObject = (data) => {
    dispatch({ type: EXTRACT_DATA_SET, payload: { ...extractData, ...data } });
  };
  const editButton = (type_) => {
    let title_;
    if (type_ === "causes_of_action") {
      title_ = "Elements";
    } else if (type_ === "issues") {
      title_ = "Issues";
    } else if (type_ === "themes") {
      title_ = "Themes";
    }
    const evidenceWithCase = () => {
      const evidenceCopy = extractData;
      evidenceCopy.case = { ...caseObject };
      return evidenceCopy;
    };
    return (
      <ListActionButton
        clickHandler={() => {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: extractData,
            content: (
              <AssociateElementsThemesIssuesForm
                key={extractData.id}
                type={type_}
                evidenceObject={evidenceWithCase()}
                setEvidenceObject={updateObject}
                evidenceId={extractData.id}
                caseObject={caseObject}
                caseId={caseId}
              />
            ),
            swapContent: {
              content: (
                <ExtractForm
                  selectedClass={selectedClass}
                  blob={blob}
                  caseId={caseId}
                  caseObject={caseObject}
                />
              ),
              title: "Extract Doc",
            },
            title: title_,
          });
        }}
        type={"edit"}
      />
    );
  };
  const setEditBtnType = (activeTab) => {
    switch (activeTab) {
      case 0:
        return "causes_of_action";
      case 1:
        return "themes";
      case 2:
        return "issues";

      default:
        throw new Error("Wrong tab index");
    }
  };
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  useEffect(() => {
    let data = {};
    extractData.witnesses.forEach((v) => {
      if (!data[v.type]) {
        data[v.type] = [];
      }
      data[v.type].push(v);
      setWitnesses(data);
    });
  }, [extractData.witnesses]);
  const handleSubmitMove = async (event) => {
    await submit(event, true);
  };
  const submit = async (event, move) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("file", blob);
    formData.append(
      "bundles",
      // JSON.stringify(window.doc_object.bundles.map((v) => v.id))
      JSON.stringify([])
    );
    formData.append("label", extractData.label);
    setIsLoading(true);
    if (selectedClass === EVIDENCE_DOCUMENTS) {
      const resp = await bulkImportEvidence(caseId, dispatch, formData);
      let ev = resp[0];
      await updateEvidence(ev.id, caseId, dispatch, { ...ev, ...extractData });
      if (move) {
        history.push({
          pathname: `/${
            is_admin ? "admin/all-cases" : "app/cases"
          }/${caseId}/documents/${ev.file_id}`,
        });
      }
    } else if (selectedClass === CASE_DOCUMENTS) {
      let resp = await casesBulkImportRequest(caseId, dispatch, formData);
      if (move) {
        history.push({
          pathname: `/${
            is_admin ? "admin/all-cases" : "app/cases"
          }/${caseId}/documents/${resp[0].id}`,
        });
      }
    } else if (selectedClass === AUTHORITIES_DOCUMENTS) {
      const resp = await authoritiesBulkImportRequest(
        caseId,
        dispatch,
        formData
      );
      let auth = resp[0];
      await updateAuthorityById(auth.id, caseId, dispatch, {
        ...auth,
        ...extractData,
      });
      if (move) {
        history.push({
          pathname: `/${
            is_admin ? "admin/all-cases" : "app/cases"
          }/${caseId}/documents/${auth.file_id}`,
        });
      }
    } else if (selectedClass === APPLICATION_DOCUMENTS) {
      if (!extractData.applicationId) {
        handleErrorMessage("Select Application", dispatch);
        setIsLoading(false);
        return false;
      }
      formData.append("id_application", extractData.applicationId);
      const resp = await bulkImportApplicationPaperFiles(
        extractData.applicationId,
        caseId,
        dispatch,
        formData
      );
      const r = await updateApplicationPaperById(
        resp[0].id,
        extractData.applicationId,
        caseId,
        dispatch,
        extractData
      );
      if (move) {
        history.push({
          pathname: `/${
            is_admin ? "admin/all-cases" : "app/cases"
          }/${caseId}/documents/${r.file_id}`,
        });
      }
    }
    setIsLoading(false);
    dispatch({ type: EXTRACT_DATA_INITIAL });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const handleSubmit = async (event) => {
    await submit(event, false);
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            name={"label"}
            placeholder={"Label"}
            label={"Label"}
            value={extractData.label}
            onChangeHandler={changeLabel}
          />
        </div>
        {selectedClass !== CASE_DOCUMENTS &&
        selectedClass !== APPLICATION_DOCUMENTS ? (
          <Fragment>
            <Separator />
            <div className="form-group d-flex mb-4 pr-3">
              <RsbTabsMenu
                tabs={menuTabs}
                activeMenuTab={activeMenuTab}
                setActiveMenuTab={setActiveMenuTab}
              />
              {editButton(setEditBtnType(activeMenuTab))}
            </div>
            {activeMenuTab === 0 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowCoa object={extractData} case_object={caseObject} />
                </div>
              </div>
            ) : null}
            {activeMenuTab === 1 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowThemes object={extractData} case_object={caseObject} />
                </div>
              </div>
            ) : null}
            {activeMenuTab === 2 ? (
              <div className="form-group">
                <div className="d-flex pl-2 pr-2 flex-wrap">
                  <ShowIssues object={extractData} case_object={caseObject} />
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : null}
        {selectedClass === APPLICATION_DOCUMENTS ? (
          <Fragment>
            <Separator />
            {applicationsLoading ? (
              <div
                className="form-group d-flex justify-content-center align-items-center"
                style={{ marginBottom: "60px" }}
              >
                <PulseLoader size={15} color={theme.colors.blue} />
              </div>
            ) : (
              <div className="form-group" style={{ marginBottom: "60px" }}>
                <Label name={"Application"} />
                <SelectNew
                  classNamePrefix="Select"
                  className="pl-3 pr-3"
                  placeholder="Select Application"
                  options={applications}
                  value={
                    extractData.applicationId
                      ? applications.find(
                          (v) => v.value === extractData.applicationId
                        )
                      : null
                  }
                  onChangeHandler={onApplicationChange}
                />
              </div>
            )}
          </Fragment>
        ) : null}
        {selectedClass === APPLICATION_DOCUMENTS &&
        extractData.application_object ? (
          <Fragment>
            <Separator />
            <div className="form-group">
              <Label
                name={"Reliefs and Proofs"}
                button={
                  <ListActionButton
                    clickHandler={() => {
                      dispatch({
                        type: SHOW_RIGHT_SIDE_BAR,
                        url: history.location.pathname,
                        content: (
                          <AssociateProofs
                            applicationObject={extractData.application_object}
                            applicationPaperObject={extractData}
                            returnObject={true}
                            savedReliefs={extractData.reliefs}
                            savedProofs={extractData.proofs}
                            setApplicationPaperObject={(data) => {
                              updateObject(data);
                            }}
                            routeParams={{
                              caseId: caseId,
                              applicationId:
                                extractData.application_object.fake_id,
                            }}
                          />
                        ),
                        title: "Reliefs and Proofs",
                        swapContent: {
                          content: (
                            <ExtractForm
                              selectedClass={selectedClass}
                              blob={blob}
                              caseId={caseId}
                              caseObject={caseObject}
                            />
                          ),
                          title: "Doc Properties",
                        },
                      });
                    }}
                    type={"edit"}
                  />
                }
              />
              {extractData.application_object &&
              extractData.application_object.reliefs
                ? extractData.application_object.reliefs
                    .filter((v) => {
                      if (!extractData.reliefs || !extractData.reliefs.length) {
                        return false;
                      } else {
                        return extractData.reliefs.find((vv) => vv.id === v.id);
                      }
                    })
                    .map((obj) => {
                      return (
                        <div className="container-fluid">
                          <Bold14Font textColor={theme.colors.darkOpacity}>
                            {obj.name}
                          </Bold14Font>
                          <div className="container-fluid">
                            <ul>
                              {extractData.application_object &&
                              extractData.application_object.proofs
                                ? extractData.application_object.proofs
                                    .filter((g) => {
                                      if (!extractData.proofs) {
                                        return false;
                                      } else {
                                        return extractData.proofs.find(
                                          (c) => c.id === g.id
                                        );
                                      }
                                    })
                                    .map((obj) => {
                                      return (
                                        <li style={{ listStyle: "disc" }}>
                                          <Medium14Font
                                            textColor={theme.colors.darkOpacity}
                                          >
                                            {obj.name}
                                          </Medium14Font>
                                        </li>
                                      );
                                    })
                                : null}
                            </ul>
                          </div>
                        </div>
                      );
                    })
                : null}
            </div>
          </Fragment>
        ) : null}
        {selectedClass === EVIDENCE_DOCUMENTS ? (
          <Fragment>
            <Separator />
            <div className="form-group">
              <Label
                name={"Witness"}
                button={
                  <ListActionButton
                    clickHandler={() => {
                      dispatch({
                        type: SHOW_RIGHT_SIDE_BAR,
                        url: history.location.pathname,
                        content: (
                          <AssociateWitnessForm
                            evidenceObject={extractData}
                            setEvidenceObject={updateObject}
                            evidenceId={extractData.id}
                            caseObject={caseObject}
                            caseId={caseId}
                            returnFunction={updateObject}
                          />
                        ),
                        swapContent: {
                          content: (
                            <ExtractForm
                              selectedClass={selectedClass}
                              blob={blob}
                              caseId={caseId}
                              caseObject={caseObject}
                            />
                          ),
                          title: "Extract Doc",
                        },
                        title: "Witness",
                      });
                    }}
                    type={"edit"}
                  />
                }
              />
              {Object.keys(witnesses).map((w_k) => {
                return !witnesses[w_k].length ? null : (
                  <div className="container-fluid">
                    <div className="mb-2">
                      <Medium14Font textColor={theme.colors.darkOpacity}>
                        {w_k}
                      </Medium14Font>
                    </div>
                    <div className="d-flex flex-column">
                      {witnesses[w_k].map((witness_, index) => {
                        return (
                          <Medium14Font
                            className={index > 0 ? "ml-2" : ""}
                            textColor={theme.colors.dark}
                          >
                            {witness_.name}
                          </Medium14Font>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>{" "}
          </Fragment>
        ) : null}
        <Separator />
      </div>
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmitMove}
            style={{ height: "86%", overflowY: "auto" }}
          >
            <Button
              type="submit"
              wide
              primary
              disabled={isLoading}
              loading={isLoading}
            >
              Work further with extracted document
            </Button>
          </form>
        </div>
      </div>
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            style={{ height: "86%", overflowY: "auto" }}
          >
            <Button
              disabled={isLoading}
              loading={isLoading}
              type="submit"
              wide
              primary
            >
              {closeBtnText}
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
