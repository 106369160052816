import React, { Fragment, useRef, useState } from "react";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { updateObjective } from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import Label from "../AddCardForm/Label";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import ListAddButton from "../../buttons/ListAddButton";
import { useParams } from "react-router-dom";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

const ExamLinesFOrm = ({
  objectiveObject,
  setObject,
  witnessId,
  caseId,
  item,
  witnessObject,
  isXExamFlowFalse,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [examLinesList, setExamLinesList] = useState(
    objectiveObject.exam_lines ?? [{ name: "" }]
  );
  const dispatch = useDispatch();
  let editableStringsRef = useRef([]);

  // add new objective input
  const addObjectiveHandler = (event) => {
    event.preventDefault();
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      setExamLinesList((prevState) => [...prevState, { name: "" }]);
    }
  };

  const handleSubmit = async () => {
    if (!isLoading) {
      const submitObject = {
        witness_id: witnessId,
        id_case: caseId,
        exam_lines: examLinesList.filter((objective) => objective.name !== ""),
      };
      let resp;
      const isEvidence = !objectiveObject.model_type || objectiveObject.model_type.indexOf("Evidence") === -1;
      if (isEvidence) {
        resp = item;
        let index = resp.objectives.findIndex(objective => objective.id === objectiveObject.id);
        if (index !== -1) {
          resp.objectives[index].exam_lines = submitObject.exam_lines;
        }
      } else {
        setIsLoading(true);
        resp = await updateObjective(
          objectiveObject.id,
          dispatch,
          submitObject
        );
      }
      setIsLoading(false);
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        setObject(resp, isEvidence);
      }
    }
  };

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  const editObjective = (index, objectiveText) => {
    const examLinesListCopy = [...examLinesList];
    examLinesListCopy[index].name = objectiveText;
    setExamLinesList(examLinesListCopy);
  };

  const deleteObjective = (index) => {
    setExamLinesList(examLinesList.filter((objective, idx) => index !== idx));
  };

  return (
    <Fragment>
      <div className="form-group" style={{ marginBottom: "20px" }}>
        <Label name="Objective" />
        <Medium14Font className="ml-3">{objectiveObject.name}</Medium14Font>
      </div>
      {examLinesList && examLinesList.length ? (
        <Label name="X-Exam Lines" />
      ) : null}
      <div style={{ height: "100%", overflowY: "auto" }}>
        {examLinesList.map((objective, index) => {
          return (
            <AnimatedDiv key={index} className="form-group pl-3">
              <EditableStringClassComponent
                editName={editObjective}
                editState={objective.name === ""}
                deleteAction={deleteObjective}
                object={objective.name}
                index={index}
                ref={(ref) => assignRef(ref, index)}
              />
            </AnimatedDiv>
          );
        })}
        <div className="d-flex justify-content-center align-items-center mb-2">
          <ListAddButton
            label={"X-Exam Lines"}
            clickHandler={addObjectiveHandler}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                wide
                primary
                clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ExamLinesFOrm;
