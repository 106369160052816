import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@atlaskit/theme";

import { Row, ChapterLabel, GrabIcon, MoreIcon, ChapterItem } from "../styled";

import Menu from "./Menu";

const imageSize = 40;

const CloneBadge = styled.div`
  background: ${colors.G100};
  bottom: 2px;

  border: 2px solid ${colors.G200};
  border-radius: 50%;
  box-sizing: border-box;
  font-size: 10px;
  position: absolute;
  right: -${imageSize / 3}px;
  top: -${imageSize / 3}px;
  transform: rotate(40deg);

  height: ${imageSize}px;
  width: ${imageSize}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

// padding: 4px;
// margin-bottom: 4px;
const Container = styled.a`
  box-sizing: border-box;
  min-height: ${imageSize}px;
  user-select: none;

  color: ${colors.N900};
  &:hover,
  &:active {
    color: ${colors.N900};
    text-decoration: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;
// &:focus {
// border-color: ${(props) => props.colors.hard};

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function Chapter(props) {
  const [menu, toggleMenu] = useState(false);
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    selectedChapter,
    setSelectedChapter,

    editItem,
    removeItem,
  } = props;

  // console.log(index);
  // console.log(props);

  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote.type} quote ${quote.name}`}
    >
      {isClone ? <CloneBadge>Clone</CloneBadge> : null}
      <ChapterItem
        alignCenter
        justifyBetween
        style={{ marginBottom: 8, width: "100%" }}
        selected={selectedChapter && quote.id === selectedChapter.id}
      >
        <Row
          alignCenter
          onClick={() => {
            console.log("setSelectedChapter");
            console.log(quote);
            setSelectedChapter(quote);
          }}
          style={{ maxWidth: "85%" }}
        >
          <GrabIcon />
          <ChapterLabel
            selected={selectedChapter && quote.id === selectedChapter.id}
          >
            {quote.name}
          </ChapterLabel>
        </Row>
        <MoreIcon show={menu} onClick={() => toggleMenu(!menu)} />
        {menu && (
          <Menu
            close={() => toggleMenu(false)}
            item={quote}
            editItem={editItem}
            removeItem={removeItem}
          />
        )}
      </ChapterItem>
    </Container>
  );
  // return (
  //   <Row alignCenter style={{ marginBottom: 8 }} onClick={onClick}>
  //     <GrabIcon />
  //     <ChapterLabel selected={selected}>{children}</ChapterLabel>
  //   </Row>
  // );
}

// const Chapter = ({ children, selected = false, onClick = () => {} }) => {
//   return (
//     <Row alignCenter style={{ marginBottom: 8 }} onClick={onClick}>
//       <GrabIcon />
//       <ChapterLabel selected={selected}>{children}</ChapterLabel>
//     </Row>
//   );
// };

export default React.memo(Chapter);
