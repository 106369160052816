import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import {
  addCasesTypes,
  addThemes,
  updateCasesTypes,
  updateThemes,
} from "../../../containers/Auth/auth";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

export default function CaseTypesForm(prop) {
  let { object } = prop;
  const dispatch = useDispatch();
  const [name, setName] = useState(object.name ?? "");
  const [isLoading, setIsLoading] = useState(false);
  let form = React.createRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      let resp;
      let data = {
        name: name,
      };
      if (object.id) {
        resp = await updateCasesTypes(object.id, dispatch, data);
      } else {
        resp = await addCasesTypes(dispatch, data);
      }
      setIsLoading(false);
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update"
        );
      }
    }
  };
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Input
            maxLength={255}
            type={"text"}
            required
            label={"Name"}
            onChangeHandler={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder={"Enter type name"}
            name={"name"}
            form="form-CaseTypesForm"
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form ref={form} onSubmit={handleSubmit} id="form-CaseTypesForm">
            <Button
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
CaseTypesForm.propTypes = {
  object: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};
