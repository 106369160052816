import styled from "styled-components/macro";

const LandingPageNavBarStyled = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 367.98px) {
    width: unset;
  }
`;

export default LandingPageNavBarStyled;
