import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: space-between;
  border-top: 1px solid #dcdce6;
  display: flex;
  flex-direction: column;
`;
