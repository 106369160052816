import styled from "styled-components/macro";
import { fadeIn } from 'react-animations';
import {keyframes} from "styled-components";
const bounceAnimation = keyframes`${fadeIn}`;

const PlanCardStyled = styled.div`
 // TODO: Попробовать убрать
  border-radius: 4px;
  border: 1px solid ${({ theme, checked }) => !checked ? theme.colors.gray : theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
  animation: 1s ${bounceAnimation};
  height: 100%;
`;

export default PlanCardStyled;
