import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageHeaderImgWrapperStyled = styled.div`
  width: ${rem(`128px`)};

  @media (max-width: 767.98px) {
    width: 16rem;
  }

`;

export default LandingPageHeaderImgWrapperStyled;
