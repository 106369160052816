import styled from "styled-components";

import chaptersIcon from "@/assets/img/notebook/chapters.svg";
import plusIcon from "@/assets/img/notebook/chapters-add.svg";
import crossIcon from "@/assets/img/notebook/cross.svg";
import grabIcon from "@/assets/img/notebook/chapters/grab.svg";
import expandIcon from "@/assets/img/notebook/chapters/expand.svg";
import moreIcon from "@/assets/img/notebook/more.svg";
//trigger build

// padding-top: 40px;
// margin-left: -15px;
// width: calc(100% + 41px);
export const Container = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  padding-top: 20px;

  overflow: auto;
  height: calc(100vh - 220px);
`;

export const Row = styled.div`
  display: flex;
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyBetween && `justify-content: space-between;`}
${(props) =>
    props.groupHover &&
    `
:hover {
opacity: 0.8;
}
`}
`;

export const ChapterItem = styled(Row)`
  position: relative;
  ${(props) => !props.selected && `cursor: pointer;`}
`;

export const GroupRow = styled(Row)`
  position: relative;
  height: 22px;
  ${(props) => !props.selected && `cursor: pointer;`}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChaptersLabel = styled.span`
  color: #0f122f;
  font-weight: 600;
  margin-left: 4px;
  font-size: 13px;
`;

export const AddChapterLabel = styled.span`
  margin-right: 5px;
  color: #374ac2;
  font-weight: 600;
  font-size: 13px;
  user-select: none;
  //
`;

export const GroupLabel = styled.span`
  color: #0f122f;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    font-weight: 700;
    color: #374AC2;
  `}
`;

export const ChapterLabel = styled.span`
  color: #0f122f80;
  margin-left: 16px;
  font-size: 15px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `
    font-weight: 600;
    color: #374AC2;
  `}
`;

export const ChaptersIcon = styled.img.attrs({
  src: chaptersIcon,
})`
  width: 22px;
`;

export const PlusIcon = styled.img.attrs({
  src: plusIcon,
})`
  width: 22px;
`;

export const CrossIcon = styled.img.attrs({
  src: crossIcon,
})`
  width: 22px;
`;

export const GrabIcon = styled.img.attrs({
  src: grabIcon,
})`
  width: 8px;
  user-select: none;
  cursor: grab;
`;

export const ExpandIcon = styled.img.attrs({
  src: expandIcon,
})`
  width: 26px;
`;

export const MoreIcon = styled.img.attrs({
  src: moreIcon,
})`
  width: 26px;
  background: #374ac210;
  border-radius: 4px;
  display: none;
  ${(props) => props.show && `display: block;`}
  ${ChapterItem}:hover & {
    display: block;
  }
  ${GroupRow}:hover & {
    display: block;
  }
`;
