export default function flagRelevanceCheck(value, flagRelevance) {
  let flag_check = false;
  flagRelevance.forEach((item) => {
    if (!flag_check) {
      if (item === "themes") {
        flag_check =
          value.themes.length > 0 || value.themes_parent.length > 0;
      } else if (item === "cause_of_action") {
        flag_check =
          value.causes_of_action.length > 0 ||
          value.causes_of_action_defensive.length > 0 ||
          value.causes_of_action_parent.length > 0;
      } else if (item === "issues") {
        flag_check = value.issues.length > 0 || value.issues.length > 0;
      }
      if (item.indexOf("issue_") !== -1) {
        flag_check =
          value.issues_parent.filter((v) => item.split("issue_")[1] == v.id)
            .length > 0;
      }
      if (item.indexOf("coa_") !== -1) {
        flag_check =
          value.causes_of_action_parent.filter(
            (v) => item.split("coa_")[1] == v.id
          ).length > 0;
      }
      if (item.indexOf("element_") !== -1) {
        flag_check =
          value.causes_of_action.filter(
            (v) => item.split("element_")[1] == v.id
          ).length > 0;
      }
      if (item.indexOf("defensive_") !== -1) {
        flag_check =
          value.causes_of_action_defensive.filter(
            (v) => item.split("defensive_")[1] == v.id
          ).length > 0;
      }
      if (item.indexOf("theme_") !== -1) {
        flag_check =
          value.themes_parent.filter((v) => item.split("theme_")[1] == v.id)
            .length > 0;
      }
      if (item.indexOf("key_fact_") !== -1) {
        flag_check =
          value.issues.filter((v) => item.split("key_fact_")[1] == v.id)
            .length > 0;
      }
      if (item.indexOf("subtheme_") !== -1) {
        flag_check =
          value.themes.filter((v) => item.split("subtheme_")[1] == v.id)
            .length > 0;
      }
    }
    if (flag_check) {
      return true;
    }
  });
  return flag_check;
};

