import React from "react";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import { route } from "../../../routes";

import SignPagesWrapper from "../../../components/SignPagesWrapper";
import ConfirmEmailForm from "../../../components/forms/ConfirmEmailForm";
import Button from "../../../components/buttons/Button";
import { Bold18Font } from "../../../components/Fonts/Fonts";

const ConfirmEmailPage = ({ theme }) => {
  const history = useHistory();

  return (
    <SignPagesWrapper>
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex flex-column justify-content-between">
            {/*<div className="row p-5">*/}
            {/*  <div className="col">*/}
            {/*    */}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row">
              <div className="col">
                <ConfirmEmailForm/>
              </div>
            </div>
            <div className="row"/>
            <div className="row">&nbsp;</div>
          </div>
        </div>
      </div>
    </SignPagesWrapper>
  );
};

export default withTheme(ConfirmEmailPage);
