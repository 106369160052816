import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CRUDButtonStyled = styled.button.attrs({ className: "btn" })`
  width: ${rem(`80px`)};
  height: ${rem(`80px`)};
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  border-radius: ${rem(`21px`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`;

export default CRUDButtonStyled;
