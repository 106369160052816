import React, { Fragment, useEffect, useState } from "react";
import CaseCard from "../../../components/baseAppComponents/CaseCard";
import {
  canAdd,
  deleteCase,
  getAdminCases,
  getAdminUserById,
  getCases,
  getCasesTypes,
  getInvitesList,
  toggleArchiveCaseStatus,
  updateUserSettings,
} from "../../Auth/auth";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import { useDispatch, useSelector } from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import CasesSearchFilterBar from "../../../components/baseAppComponents/CasesSearchFilterBar";
import moment from "moment";
import { route } from "../../../routes";
import { useRouteMatch } from "react-router-dom";
import UserDetails from "../../../components/baseAppComponents/UserDetails/UserDetails";
import {
  CASES,
  USER_LIST,
} from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import NoRecords from "../../../components/NoRecords";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  CARD_VIEW,
  TABLE_VIEW,
  teamLimitRequestStatus,
} from "../../../utils/constants";
import LeftSideBarUserMenu from "../../../components/baseAppComponents/LeftSideBarUserLayout/LeftSideBarUserMenu";
import LiStyled from "../../../components/Table/Styled/LiStyled";
import { useHistory } from "react-router-dom";
import Table from "../../../components/Table";
import AnimatedDiv from "../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
  UPDATE_USER,
} from "../../../redux/types";
import RSBCaseCardPreview from "../../../components/rightSideBarViews/RSBCaseCardPreview";
import SearchFilterBar from "../../../components/baseAppComponents/SearchFilterBar";
import { searchFilterBarTypes } from "../../../components/baseAppComponents/SearchFilterBar/SearchFilterBar";
import {
  reStructureCaseObject,
  setTagFieldToObject,
} from "../../../utils/function";
import RSBCaseCardAdminTeamRequestPreview from "../../../components/rightSideBarViews/RSBCaseCardAdminTeamRequestPreview";
import Menu from "../../../components/baseAppComponents/Menu/Menu";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import {history} from "../../../index";
import {handleErrorMessage} from "../../../components/Modal/modalErrorHandler";

const order = [
  {
    value: 1,
    label: "Last updated",
    method: () => null,
  },
  {
    value: 2,
    label: "Alphabetical",
    method: () => null,
  },
];

const ALL_CASES = "CASES/FILTER_ALL_CASES";
const ACTIVE_CASES = "CASES/FILTER_ACTIVE_CASES";
const ARCHIVED_CASES = "CASES/FILTER_ARCHIVED_CASES";
const INVITES_CASES = "CASES/FILTER_INVITES_CASES";
const REQUEST_CASES = "CASES/FILTER_REQUEST_CASES";

const defaultCasesTypes = [
  {
    value: 0,
    label: "All",
  },
];

const defaultViewOption = { label: "View cards", value: CARD_VIEW };

const sortAlphabetical = (a, b) => {
  let item_a = Object.assign({}, a);
  let item_b = Object.assign({}, b);
  if (!item_a.label) {
    item_a.label = "New Case";
  }
  if (!item_b.label) {
    item_b.label = "New Case";
  }
  item_a.label =
    typeof item_a.label === "string"
      ? item_a.label.toLowerCase()
      : item_a.label.toString();
  item_b.label =
    typeof item_b.label === "string"
      ? item_b.label.toLowerCase()
      : item_b.label.toString();
  return item_a.label.localeCompare(item_b.label, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

const sortByDateTime = (item_a, item_b) => {
  return moment(item_b.updated_at).diff(moment(item_a.updated_at));
};

const sortByUser = () => {};

const switchTypeOptions = [
  { value: "case", label: "Search Case" },
  { value: "user", label: "Search User" },
];

const noRecords = (
  <div className="d-flex justify-content-center align-items-center w-100 h-100">
    <NoRecords>
      <Bold18Font textColor={"#0f122f"}>Your Cases will appear here</Bold18Font>
    </NoRecords>
  </div>
);

const Cases = () => {
  const match = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [prevParams, setPrevParams] = useState(match.params);
  const [type, setType] = useState(
    window.location.href.indexOf("?invites") !== -1
      ? INVITES_CASES
      : ACTIVE_CASES
  );
  const [searchString, setSearchString] = useState("");
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [
    selectedFilterDatesAlphabet,
    setSelectedFilterDatesAlphabet,
  ] = useState(1); // 1 - Last updated, 2 - Alphabetical
  const [cases, setCases] = useState([]);
  const [casesRestructuredList, setCasesRestructuredList] = useState([]);
  // const [inviteCasesList, setInviteCasesList] = useState([]);
  const [user, setUser] = useState({});
  const [searchType, setSearchType] = useState("case");
  const [casesTypes, setCasesTypes] = useState(defaultCasesTypes);
  const defaultFilterByFirmOptionValue = {
    label: "All",
    value: "all",
  };
  const [filterByFirmOptions, setFilterByFirmOptions] = useState([
    defaultFilterByFirmOptionValue,
  ]);
  const [selectViewOptions, setSelectViewOptions] = useState([
    defaultViewOption,
    { label: "View table", value: TABLE_VIEW },
  ]);

  const userSettings = useSelector((state) => state.auth.user.settings);
  const authUser = useSelector((state) => state.auth.user);
  const [selectViewOption, setSelectViewOption] = useState(
    userSettings &&
      userSettings.default_case_view &&
      selectViewOptions &&
      selectViewOptions.length
      ? selectViewOptions.find((el) => {
          return el.value === userSettings.default_case_view;
        })
      : defaultViewOption
  );
  const [selectedRowInTable, setSelectedRowInTable] = useState(0);

  const [selectedFilterByFirmOption, setSelectedFilterByFirmOption] = useState(
    "all"
  );
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const history = useHistory();

  const { width } = useWindowSize();

  const filter = (value, index) => {
    let check = true;

    if (type && type === ACTIVE_CASES) {
      check = value.archived == 0;
    } else if (type && type === ARCHIVED_CASES) {
      check = value.archived == 1;
    }

    if (type && type === INVITES_CASES) {
      check = value.invited === true;
    }

    if (type && type === REQUEST_CASES) {
      check =
        value.team_limit_request === teamLimitRequestStatus.REQUEST_SENDED;
    }

    if (searchString && check) {
      if (!is_admin) {
        check =
          (value.firm_name && searchField(value.firm_name)) ||
          (value.label && searchField(value.label)) ||
          (value.case_type && searchField(value.case_type.name));
      } else {
        if (searchType === "case") {
          check =
            (value.firm_name && searchField(value.firm_name)) ||
            (value.label && searchField(value.label)) ||
            (value.case_type && searchField(value.case_type.name));
        } else {
          check = value.user && value.user.name && searchField(value.user.name);
        }
      }
    }

    if (selectedFilterType && value.case_type && check) {
      check = value.case_type.id === selectedFilterType;
    } else if (selectedFilterType && !value.case_type) {
      check = false;
    }

    // filter by firm name
    if (selectedFilterByFirmOption !== "all" && check) {
      if (value.firm_name === null) {
        check = value.firm_name === selectedFilterByFirmOption;
      } else {
        check = value.firm_name.toLowerCase() === selectedFilterByFirmOption;
      }
    }

    return check;
  };

  const getData = async () => {
    setIsLoading(true);
    let id_user = false;
    if (match.params && match.params.id) {
      id_user = match.params.id;
      let resp = await getAdminUserById(id_user, dispatch);
      setUser(resp);
    }
    let data = is_admin
      ? await getAdminCases(id_user, dispatch)
      : await getCases(dispatch);
    const casesTypesData = await getCasesTypes(dispatch);
    let invitedCasesListResp = !is_admin ? await getInvitesList(dispatch) : [];
    invitedCasesListResp = invitedCasesListResp.map((el) => ({
      ...el.case,
      invited: true,
      invite_token: el.token,
    }));
    setCases([...data, ...invitedCasesListResp]);
    setCasesTypes((prevState) => prevState.concat(casesTypesData));

    // set filters options by firm name
    const uniqueFirmNamesOptions = [];
    data.forEach((case_) => {
      if (
        case_.firm_name !== null &&
        uniqueFirmNamesOptions.findIndex(
          (el) => el.value === case_.firm_name.toLowerCase()
        ) === -1
      ) {
        uniqueFirmNamesOptions.push({
          label: case_.firm_name,
          value: case_.firm_name.toLowerCase(),
        });
      } else if (
        case_.firm_name === null &&
        uniqueFirmNamesOptions.findIndex((el) => el.value === null) === -1
      ) {
        uniqueFirmNamesOptions.splice(0, 0, {
          label: "No firm",
          value: null,
        });
      }
    });
    setFilterByFirmOptions((prevState) => [
      ...prevState,
      ...uniqueFirmNamesOptions,
    ]);

    setIsLoading(false);
  };

  const onChangeFilterByFirmOption = (selectedOption) => {
    setSelectedFilterByFirmOption(selectedOption.value);
  };

  const onChangeSelectViewOption = async (selectedOption) => {
    setSelectViewOption(selectedOption);
    const resp = await updateUserSettings({
      settings: {
        ...userSettings,
        default_case_view: selectedOption.value,
      },
    });
    if (resp) {
      dispatch({ type: UPDATE_USER, payload: resp });
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (prevParams.id !== match.params.id) {
      setPrevParams(match.params);
      getData();
    }
  }, [match.params]);

  const deleteCaseFromView = (caseId) => {
    const casesCopy = [...cases];
    const index = casesCopy.findIndex((el) => el.id === caseId);
    casesCopy.splice(index, 1);
    setCases(casesCopy);
  };

  const moveFromInvitedList = (caseId) => {
    const casesCopy = [...cases];
    const index = casesCopy.findIndex((el) => el.id === caseId);
    casesCopy[index].invited = false;
    setCases(casesCopy);
  };
  const moveFromTeamLimit = (caseId, type) => {
    const casesCopy = [...cases];
    const index = casesCopy.findIndex((el) => el.id === caseId);
    casesCopy[index].team_limit_request = type;
    setCases(casesCopy);
  };

  const archiveCaseInView = (caseId) => {
    const casesCopy = [...cases];
    const index = casesCopy.findIndex((el) => el.id === caseId);
    casesCopy[index].archived = !casesCopy[index].archived;
    setCases(casesCopy);
  };

  const sendRequestCaseInView = (caseId) => {
    const casesCopy = [...cases];
    const index = casesCopy.findIndex((el) => el.id === caseId);
    casesCopy[index].request_access = "request_sanded";
    setCases(casesCopy);
  };

  const popupMenuCases = [
    {
      id: 1,
      title: "View",
      onClickHandler: (_, id) => {
        history.push(`${route.baseApp.cases}/${id}`);
      },
    },
    {
      id: 2,
      title: "Archive",
      onClickHandler: async (_, id) => {
        const status = cases.find((el) => el.id === id).archived;
        const resp = await toggleArchiveCaseStatus(id, status, dispatch);
        if (resp) {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          archiveCaseInView(id);
        }
      },
      data: cases,
    },
    {
      id: 3,
      title: "Edit",
      onClickHandler: (_, id) => {
        history.push(`${route.baseApp.cases}/edit/${id}`);
      },
    },
    {
      id: 4,
      title: "Delete",
      onClickHandler: (_, id) => {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload: "You won't be able to restore data",
          beforeCloseHandler: async () => {
            await deleteCase(id, dispatch);
            await deleteCaseFromView(id);
            dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          },
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      },
    },
  ];

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const searchField = (field_value) => {
    return field_value.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;
  };

  const getType = (type) => {
    setType(type);
  };

  const casesMenu = !is_admin
    ? [
        {
          text: "Active Cases",
          amount: cases.filter((v) => v.archived === false).length,
          activeType: ACTIVE_CASES,
        },
        {
          text: "Archived Cases",
          amount: cases.filter((v) => v.archived === true).length,
          activeType: ARCHIVED_CASES,
        },
        {
          text: "All Cases",
          amount: cases.length,
          activeType: ALL_CASES,
        },
        // TODO: Hide invites tab if user don't have invites
        {
          text: "Invites",
          amount: cases.filter((v) => v.invited === true).length,
          activeType: INVITES_CASES,
        },
      ]
    : [
        {
          text: "Active Cases",
          amount: cases.filter((v) => v.archived === false).length,
          activeType: ACTIVE_CASES,
        },
        {
          text: "Archived Cases",
          amount: cases.filter((v) => v.archived === true).length,
          activeType: ARCHIVED_CASES,
        },
        {
          text: "All Cases",
          amount: cases.length,
          activeType: ALL_CASES,
        },
        // TODO: Hide invites tab if user don't have invites
        {
          text: "Increase Team Size",
          amount: cases.filter(
            (v) =>
              v.team_limit_request === teamLimitRequestStatus.REQUEST_SENDED
          ).length,
          activeType: REQUEST_CASES,
        },
      ];

  const pageConfig = !is_admin
    ? [
        {
          path: "/app/cases",
          title: `${is_admin ? "All Cases" : "Cases"}`,
          activeMenuItem: CASES,
        },
      ]
    : match.params && match.params.id
    ? [
        {
          path: route.admin.baseApp.userList,
          title: "User List",
          activeMenuItem: USER_LIST,
        },
        {
          path: `${route.admin.baseApp.user}/${user.id}/details`,
          title: user.name,
          activeMenuItem: USER_LIST,
        },
      ]
    : [
        {
          path: route.admin.baseApp.userList,
          title: "All Cases",
          activeMenuItem: USER_LIST,
        },
      ];
  const clearSearchBarHandler = () => {
    setSearchString("");
  };
  const casesTableSettings = {
    collapsed: true,
    canSort: true,
    clickAction: (object) => {
      setSelectedRowInTable(object.id);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <RSBCaseCardPreview
            key={object.id}
            caseObject={object}
            caseId={object.id}
            deleteCaseFromView={deleteCaseFromView}
            archiveCaseInView={archiveCaseInView}
            afterCloseCallback={() => setSelectedRowInTable(0)}
          />
        ),
        title: "Case Preview",
      });
    },
    actions: [
      {
        type: "options",
        popupComponent: (
          <LeftSideBarUserMenu
            menuItems={popupMenuCases}
            style={{
              width: "150px",
              height: "220px",
              left: "133px",
              bottom: "14px",
            }}
          />
        ),
        callback: (object, event, state, setState, rowIndex) => {
          // show or hide popup menu on appropriate row
          if (state === rowIndex) {
            setState(null);
          } else {
            setState(rowIndex);
          }
        },
      },
    ],
    fields: [
      {
        name: "label",
        label: "NAME",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "firm_name",
        label: "FIRM",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.firm_name}</Medium14Font>;
        },
      },
      {
        name: "new_case_type",
        label: "CASE TYPE",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.new_case_type}</Medium14Font>;
        },
      },
      {
        name: "case_type",
        label: "COURT TYPE",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.case_type ? object.case_type.name : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "record_no",
        label: "RECORD №",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.record_no}</Medium14Font>;
        },
      },
      {
        name: "parties",
        label: "PARTIES",
        renderer: (object) => {
          if (object.parties && object.parties.length) {
            return (
              <ul>
                {object.parties.map((party, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{party.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "tags",
        label: "TAGS",
        renderer: (object) => {
          if (object.tags && object.tags.length) {
            return (
              <ul>
                {object.tags.map((tag, index) => {
                  return <div>{tag.element}</div>;
                })}
              </ul>
            );
          }
        },
      },
    ],
  };

  const casesRequestTableSettings = {
    collapsed: true,
    actionShowCondition: (object) => {
      return object.request_access === "got_access";
    },
    clickAction: (object) => {
      setSelectedRowInTable(object.id);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <RSBCaseCardAdminTeamRequestPreview
            caseObject={object}
            caseId={object.id}
            deleteCaseFromView={deleteCaseFromView}
            archiveCaseInView={archiveCaseInView}
            moveFromTeamLimit={moveFromTeamLimit}
            afterCloseCallback={() => setSelectedRowInTable(0)}
          />
        ),
        title: "Case Preview",
      });
    },
    actions: [
      {
        type: "options",
        popupComponent: (
          <LeftSideBarUserMenu
            menuItems={popupMenuCases}
            style={{
              width: "150px",
              height: "220px",
              left: "133px",
              bottom: "14px",
            }}
          />
        ),
        callback: (object, event, state, setState, rowIndex) => {
          // show or hide popup menu on appropriate row
          if (state === rowIndex) {
            setState(null);
          } else {
            setState(rowIndex);
          }
        },
      },
    ],
    fields: [
      // {
      //   name: "id",
      //   label: "ID",
      //   renderer: (object) => {
      //     return <Medium14Font>{object.id}</Medium14Font>;
      //   },
      // },
      {
        name: "user",
        label: "USER",
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.user.email} ({object.user.name})
            </Medium14Font>
          );
        },
      },
      {
        name: "label",
        label: "CASE NAME",
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "max_users",
        label: "MAX TEAM SIZE",
        renderer: (object) => {
          return <Medium14Font>{object.max_users}</Medium14Font>;
        },
      },
    ],
  };

  const searchFilterBarSettingsObject = {
    fields: [
      {
        type: searchFilterBarTypes.SEARCH,
        fieldName: "search",
        placeholder: "Search...",
        connectedData: {
          state: searchString,
          setState: setSearchString,
        },
        style: { width: "50%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "view",
        placeholder: "View",
        connectedData: {
          state: selectViewOptions,
          setState: onChangeSelectViewOption,
          defaultValue: selectViewOption,
        },
        style: { width: "16.5%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "firm",
        placeholder: "Firm",
        connectedData: {
          state: filterByFirmOptions,
          setState: onChangeFilterByFirmOption,
        },
        style: { width: "16.5%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "type",
        placeholder: "Type",
        connectedData: {
          state: casesTypes,
          setState: (option) => setSelectedFilterType(option.value),
        },
        style: { width: "17%" },
      },
      // {
      //   type: searchFilterBarTypes.FILTER,
      //   fieldName: "sort",
      //   placeholder: "Sort",
      //   connectedData: {
      //     state: order,
      //     setState: (option) => setSelectedFilterDatesAlphabet(option.value),
      //     defaultValue: order.find(
      //       (el) => el.value === selectedFilterDatesAlphabet
      //     ),
      //   },
      //   style: { width: "12.5%" },
      // },
    ],
  };

  const addNewCase = async () => {
    setCreating(true);
    if (!authUser.features.length) {
      return history.replace(route.subscription.step2);
    }
    try {
      history.push(
        `${
          is_admin
            ? route.admin.baseApp.cases_create
            : route.baseApp.cases_create
        }`
      );
    } catch (e) {}
    setCreating(false);
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      {user.id ? <UserDetails user={user} /> : null}
      <div className="w-100 d-flex justify-content-end justify-content-md-between align-items-center flex-wrap mb-2">
        <Menu
          activeFont={Bold18Font}
          font={Bold18Font}
          className="mb-3"
          active={type}
          casesMenu={casesMenu}
          getType={getType}
        />

        {is_admin || user.role === "assistant" ? null : (
          <ButtonNew
            loading={creating}
            disabled={creating}
            clickHandler={addNewCase}
            primary
          >
            <div className="d-flex justify-content-between align-items-center">
              <img src={plusIcon} alt="" className="img-fluid mr-2" />
              <Bold14Font style={{ color: "inherit" }}>Add New Case</Bold14Font>
            </div>
          </ButtonNew>
        )}
      </div>
      {!is_admin ? (
        <SearchFilterBar
          className="mb-5"
          settingsObject={searchFilterBarSettingsObject}
        />
      ) : (
        <CasesSearchFilterBar
          switchType={is_admin}
          searchBarValue={searchString}
          onSearchBarChangeHandler={(e) => {
            setSearchString(e.target.value);
          }}
          searchBarPlaceholder={
            switchTypeOptions.find((v) => v.value === searchType).label
          }
          searchBarInputName={"search_case"}
          clearSearchBar={clearSearchBarHandler}
          filterTypesOptions={casesTypes}
          onFilterTypesValueChangeHandler={(el) =>
            setSelectedFilterType(el.value)
          }
          onSwitchTypeValueChangeHandler={(el) => setSearchType(el.value)}
          switchTypeOptions={switchTypeOptions}
          onFilterDatesAlphabetChangeHandler={(el) =>
            setSelectedFilterDatesAlphabet(el.value)
          }
        />
      )}
      <div className="row pb-5">
        {/* TODO: Change to reduce and make temp array for sorting with converted dates to moment obj */}
        {/*(selectedView && selectedView === CARD_VIEW) ||*/}
        {/*(defaultView && defaultView === CARD_VIEW)*/}

        {type !== REQUEST_CASES ? (
          cases.length ? (
            selectViewOption && selectViewOption.value === CARD_VIEW ? (
              cases
                .filter(filter)
                .sort(
                  selectedFilterDatesAlphabet === 1
                    ? sortByDateTime
                    : sortAlphabetical
                )

                .map((value, index) => {
                  return (
                    <div
                      key={value.id}
                      className={
                        width < 1600
                          ? "col-12 col-md-6 col-lg-4 mb-3"
                          : "col-xl-3 mb-3"
                      }
                    >
                      <CaseCard
                        caseObject={value}
                        deleteCaseFromView={deleteCaseFromView}
                        archiveCaseInView={archiveCaseInView}
                        moveFromInvitedList={moveFromInvitedList}
                        sendRequestCaseInView={sendRequestCaseInView}
                        archived
                        setSelectedRowInTable={setSelectedRowInTable}
                      />
                    </div>
                  );
                })
            ) : (
              <AnimatedDiv className="col-12">
                <Table
                  // data={setTagField(
                  //   reStructureCasesList(
                  //     cases
                  //       .filter(filter)
                  //       .sort(
                  //         selectedFilterDatesAlphabet === 1
                  //           ? sortByDateTime
                  //           : sortAlphabetical
                  //       )
                  //   )
                  // )}
                  data={cases
                    .filter(filter)
                    .map((caseObject) =>
                      setTagFieldToObject(
                        reStructureCaseObject(caseObject),
                        caseObject
                      )
                    )}
                  settings={casesTableSettings}
                  selectedId={selectedRowInTable}
                />
              </AnimatedDiv>
            )
          ) : (
            noRecords
          )
        ) : null}
        {is_admin && type === REQUEST_CASES && cases.filter(filter).length ? (
          <AnimatedDiv className="col-12">
            <Table
              data={cases
                .filter(filter)
                .sort(
                  selectedFilterDatesAlphabet === 1
                    ? sortByDateTime
                    : sortAlphabetical
                )}
              settings={casesRequestTableSettings}
              selectedId={selectedRowInTable}
            />
          </AnimatedDiv>
        ) : null}
      </div>
    </Fragment>
  );
};
export default Cases;
