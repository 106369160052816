import styled from "styled-components/macro";
import Select from "react-select";

// import rem from "../../../utils/rem";

const CasesDropdownFilterStyled = styled(Select).attrs({
  className: "CasesDropdownFilter",
  classNamePrefix: "CasesDropdownFilter",
})`
  // TODO: Need move to else class?
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  .CasesDropdownFilter {
    &__control {
      height: 42px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      ${({ positionStart, positionMiddle, positionEnd }) => {
        if (positionStart) {
          return `
            border-top-left-radius: 50vh;
            border-bottom-left-radius: 50vh;
            border-right-width: 0.5px;
          `;
        }

        if (positionMiddle) {
          return `
            border-radius: unset;
            border-right-width: 0.5px;
            border-left-width: 0.5px;
          `;
        }

        if (positionEnd) {
          return `
            border-top-right-radius: 50vh;
            border-bottom-right-radius: 50vh;
            border-left-width: 0.5px;
          `;
        }

        return `
        border-radius: 50vh;
      `;
      }};

      :hover,
      :focus,
      :active {
        border: 1px solid ${({ theme }) => theme.colors.blue};

        // Make indicators container change color
        .CasesDropdownFilter__indicator-separator {
        }
      }
    }

    &__value-container {
    }

    &__indicator-separator {
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.darkOpacity};
    }

    &__menu {
      border-radius: 21px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.light};
    }

    &__menu-list {
      max-height: 252px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 21px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.gray};
      }
    }

    &__option {
      cursor: pointer;

      :not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.gray};
      }

      :hover,
      :focus,
      :active {
        // TODO: add to theme?
        background: #f0f0fa;
      }
    }
  }
`;

export default CasesDropdownFilterStyled;
