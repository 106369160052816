import React, { useState } from "react";
import styled from "styled-components";
import { Popover } from "antd";

import alignIconRight from "@/assets/img/notebook/toolbar/text-align-right.svg";
import alignIconCenter from "@/assets/img/notebook/toolbar/text-align-center.svg";
import alignIconLeft from "@/assets/img/notebook/toolbar/text-align-left.svg";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  margin-top: 1px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const IconPopover = styled.img`
  margin-bottom: 8px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Align = ({ editor }) => {
  const [popupOpened, setPopupOpened] = useState(false);

  const content = (
    <Column>
      <IconPopover
        src={alignIconLeft}
        onClick={() => {
          editor.chain().focus().setTextAlign("left").run();
          setPopupOpened(false);
        }}
      />
      <IconPopover
        src={alignIconCenter}
        onClick={() => {
          editor.chain().focus().setTextAlign("center").run();
          setPopupOpened(false);
        }}
      />
      <IconPopover
        src={alignIconRight}
        onClick={() => {
          editor.chain().focus().setTextAlign("right").run();
          setPopupOpened(false);
        }}
      />
    </Column>
  );

  return (
    <Container>
      <Popover
        content={content}
        trigger="click"
        placement="bottom"
        open={popupOpened}
        onOpenChange={(v) => setPopupOpened(v)}
      >
        <Icon
          src={
            editor.isActive({ textAlign: "right" })
              ? alignIconRight
              : editor.isActive({ textAlign: "center" })
              ? alignIconCenter
              : alignIconLeft
          }
          alt=""
          // onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        />
      </Popover>
    </Container>
  );
};

export default Align;
