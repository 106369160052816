import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import { route } from "../../routes";

import SignPagesStyled from "./styled/SignPagesStyled";
import Button from "../buttons/Button";
import ButtonWithImage from "../buttons/Button/customStyled/ButtonWithImage";
import Logo from "../Logo";

import trialProoferAppImageX1 from "../../assets/img3/signInPage/trialproofer-app-image.png";
import trialProoferAppImage2x from "../../assets/img3/signInPage/trialproofer-app-image@2x.png";
import trialProoferAppImage3x from "../../assets/img3/signInPage/trialproofer-app-image@3x.png";

import { Bold18Font, Bold30Font, Medium14Font } from "../Fonts/Fonts";
import { useDispatch, useSelector } from "react-redux";
import { RESET_SIGN_UP_FLOW, SIGN_OUT } from "../../redux/types";
import { logOut } from "../../containers/Auth/auth";

const SignUpPage = ({ children, theme }) => {
  const history = useHistory();
  const currentLocation = useLocation();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();

  return (
    <SignPagesStyled>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div
            className="col-md-6 p-5 d-none d-md-flex flex-md-column justify-content-md-between"
            style={{ background: theme.colors.dark }}
          >
            {/* Header */}
            <div className="row">
              <div className="col d-flex justify-content-between">
                <Button
                  customStyle={ButtonWithImage}
                  clickHandler={() => history.push(route.index)}
                >
                  <Logo />
                </Button>
                <Medium14Font textColor={theme.colors.whiteOpacity}>
                  2021
                </Medium14Font>
              </div>
            </div>
            {/* Img / Carousel part */}
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <picture>
                  <source
                    media={"(min-width: 1200px)"}
                    srcSet={trialProoferAppImage3x}
                  />
                  <source
                    media={"(min-width: 768px)"}
                    srcSet={trialProoferAppImage2x}
                  />
                  <img
                    src={trialProoferAppImage2x}
                    alt="TrialProofer App"
                    className="img-fluid"
                    // loading="lazy"
                  />
                </picture>
              </div>
              <div className="col-12 d-flex flex-column align-items-center">
                <Bold30Font as="p" textColor={theme.colors.white}>
                  TrialProofer
                </Bold30Font>
                <Medium14Font as="p" textColor={theme.colors.whiteOpacity}>
                  Revolutionise your trial preparation
                </Medium14Font>
              </div>
            </div>
            {/* Footer */}
            <div className="row">
              <div className="col d-flex justify-content-between">
                <Button as="a" href={`${route.privacyPolicy}`} tertiary>
                  <Medium14Font textColor={theme.colors.whiteOpacity}>
                    Privacy Policy
                  </Medium14Font>
                </Button>
                <Button as="a" href={`${route.termsAndConditions}`} tertiary>
                  <Medium14Font textColor={theme.colors.whiteOpacity}>
                    Terms & Conditions
                  </Medium14Font>
                </Button>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 d-flex flex-column justify-content-between p-5"
            style={{ background: theme.colors.lightGray }}
          >
            <div className="row">
              {currentLocation.pathname === route.auth.confirmEmail ||
              currentLocation.pathname === route.auth.restorePassword ||
              currentLocation.pathname === route.auth.enterNewPassword ? (
                <div className="col">
                  <Button
                    tertiary
                    clickHandler={() => {
                      if (isAuth) {
                        logOut();
                        dispatch({ type: RESET_SIGN_UP_FLOW });
                      }
                      history.goBack();
                    }}
                  >
                    <Bold18Font textColor={theme.colors.dark}>Back</Bold18Font>
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col">{children}</div>
            </div>
            <div className="row" />
          </div>
        </div>
      </div>
    </SignPagesStyled>
  );
};

export default withTheme(SignUpPage);
