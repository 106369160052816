import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const ExpandableButtonStyled = styled.button`
  background: ${({ theme }) => theme.colors.light};
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  width: 24px;
  height: 24px;
  //display: flex;
  //justify-content: center;
  //align-items: center;
`;

export default ExpandableButtonStyled;
