import styled, { keyframes } from "styled-components/macro";
import { bounceIn } from "react-animations";

const bounceAnimation = keyframes`${bounceIn}`;

const LeftSideBarUserMenuStyled = styled.div`
  width: 120px;
  //height: 174px;
  height: ${({ isAdmin,items }) => (isAdmin || items !== 3 ? "dit-content" : "174px")};
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding-left: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 60px;
  //left: 41px;
  left: 122px;
  animation: 0.5s ${bounceAnimation};

  @media (max-width: 991.98px) {
    left: 64px;
  }

  @media (max-width: 767.98px) {
    left: 47%;
  }

  @media (max-width: 575.98px) {
    left: 45%;
  }

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.white};
    transform: rotate(45deg);
    position: absolute;
    bottom: -5px;
    left: 98px;
  }
`;

export default LeftSideBarUserMenuStyled;
