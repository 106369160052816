import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";

import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import SideBar from "../../../components/SideBar/SideBar";

import { HELP } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import {
  Bold24Font,
  Bold30Font,
} from "../../../components/FontsNewComponent/Fonts";
import { getPagesList } from "../../Auth/auth";
import { useDispatch } from "react-redux";

const loremText =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit," +
  "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
  "Ut enim ad minim veniam, quis nostrud exercitation.Lorem ipsum dolor" +
  "sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut" +
  "labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation." +
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt" +
  "ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet.";

const menuItemsStatic = [
  {
    id: 1,
    title: "First title",
    content: loremText,
  },
  {
    id: 2,
    title: "Second title",
    content: loremText,
  },
  {
    id: 3,
    title: "Third title",
    content: loremText,
  },
];

const HelpPage = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pagesList, setPagesList] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  const dispatch = useDispatch();

  const saveData = (resp) => {
    setPagesList(resp.filter((el) => el.link === null));
  };

  const getDataFromAPI = async () => {
    const resp = await getPagesList(dispatch);
    if (resp) {
      saveData(resp);
      setIsLoading(false);
    }
  };

  // Get data from API
  useEffect(() => {
    getDataFromAPI();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: "/app/help",
      title: "Help",
      activeMenuItem: HELP,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row mb-4">
        <div className="col">
          <Bold30Font>Tutorials</Bold30Font>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-3">
          <SideBar
            items={pagesList.map((item) => item.title)}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col">
              <Bold24Font>{pagesList[activeItem].title}</Bold24Font>
            </div>
          </div>
          <div className="row">
            <div className="col">{pagesList[activeItem].body}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(HelpPage);
