import React, { Fragment } from "react";
import Form from "../../../../components/forms/Form";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import {
  TagElementOrange,
  TagElementPurple,
  TagElementTurquoise,
} from "../../../../components/TagElementStyled/TagElementStyled";
import { theme } from "../../../../styled-components/Theme/Theme";
import { Medium18Font } from "../../../../components/Fonts/Fonts";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../../utils/function";
import {useSelector} from "react-redux";

export default function WitnessBasicInfo({
  witnessObject,
  checkIsXExamFlowIsFalse,
  hideBorders
}) {
  const caseObject = useSelector((state) => state.data.store ? state.data.store.case : {});
  return (
    <div className="row">
      <div className="col">
        <Form hideBorders>
          <div style={hideBorders ? null : { borderBottom: `1px solid ${theme.colors.gray}` }}>
            <div className="container-fluid" style={hideBorders ? { padding: "30px 30px" } : { padding: "35px 60px" }}>
              <div
                className="row mb-5"
              >
                <div className="col-12 d-flex flex-column">
                  <Medium14Font>Party</Medium14Font>
                  {witnessObject.party && witnessObject.party.role ? (
                    <Bold18Font>
                      {witnessObject.party.name}
                      {" - "}
                      {witnessObject.party.role}
                    </Bold18Font>
                  ) : (
                    <Medium18Font>N/A</Medium18Font>
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 d-flex flex-column">
                  <Medium14Font>Class</Medium14Font>
                  {witnessObject.class ? (
                    <Bold18Font>{witnessObject.class}</Bold18Font>
                  ) : (
                    <Medium18Font>N/A</Medium18Font>
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 d-flex flex-column">
                  <Medium14Font>Reason for Testimony</Medium14Font>
                  {witnessObject.reason_to_testimony ? (
                    <Bold18Font>{witnessObject.reason_to_testimony}</Bold18Font>
                  ) : (
                    <Medium18Font>N/A</Medium18Font>
                  )}
                </div>
              </div>
              {!checkIsXExamFlowIsFalse ? (
                <div className="row">
                  <div className="col-12 d-flex flex-column">
                    <Medium14Font>Context & Background</Medium14Font>
                    {witnessObject.background ? (
                      <Bold18Font>{witnessObject.background}</Bold18Font>
                    ) : (
                      <Medium18Font>N/A</Medium18Font>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="container-fluid" style={hideBorders ? { padding: "30px 30px" } : { padding: "35px 60px" }}>
            <div className="row mb-5">
              <div className="col">
                <Medium14Font as="div" className="mb-2">
                  Elements
                </Medium14Font>
                <div className="d-flex flex-wrap">
                  {(witnessObject.causes_of_action &&
                    witnessObject.causes_of_action.length) ||
                  (witnessObject.causes_of_action_parent &&
                    witnessObject.causes_of_action_parent.length) ||
                  (witnessObject.causes_of_action_defensive &&
                    witnessObject.causes_of_action_defensive.length) ? (
                    <Fragment>
                      <ShowCoa object={witnessObject} case_object={caseObject}/>
                    </Fragment>
                  ) : (
                    <Medium18Font>None assigned</Medium18Font>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <Medium14Font as="div" className="mb-2">
                  Themes
                </Medium14Font>
                <div className="d-flex flex-wrap">
                  {(witnessObject.themes && witnessObject.themes.length) ||
                  (witnessObject.themes_parent &&
                    witnessObject.themes_parent.length) ? (
                    <Fragment>
                      <ShowThemes object={witnessObject} case_object={caseObject}/>
                    </Fragment>
                  ) : (
                    <Medium18Font>None assigned</Medium18Font>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Medium14Font as="div" className="mb-2">
                  Issues
                </Medium14Font>
                <div className="d-flex flex-wrap">
                  {(witnessObject.issues && witnessObject.issues.length) ||
                  (witnessObject.issues_parent &&
                    witnessObject.issues_parent.length) ? (
                    <Fragment>
                      <ShowIssues object={witnessObject} case_object={caseObject}/>
                    </Fragment>
                  ) : (
                    <Medium18Font>None assigned</Medium18Font>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
