import styled, { keyframes } from "styled-components/macro";
import { bounceInRight } from "react-animations";

const bounceAnimation = keyframes`${bounceInRight}`;

const BaseAppLayoutRightSideBarStyled = styled.div`
  min-height: 440px;
  // width: 320px;
  width: ${(props) => props.width || "320px"};
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  animation: 0.5s ${bounceAnimation};
  -webkit-box-shadow: 10px 0 9px 6px #333;
  -moz-box-shadow: 10px 0 9px 6px #333;
  box-shadow: 10px 0 9px 6px #333;
  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export default BaseAppLayoutRightSideBarStyled;
