export const isTagNode = (typeName) => {
  if (typeName === "tag") return true;
  if (typeName === "authority") return true;
  if (typeName === "comment") return true;
  if (typeName === "evidence") return true;
  if (typeName === "exam") return true;
  if (typeName === "objective") return true;
  if (typeName === "party") return true;
  if (typeName === "section") return true;
  if (typeName === "topic") return true;
  if (typeName === "witness") return true;
  return false;
};
