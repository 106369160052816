import React from "react";

const CloseIcon = ({...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="7"
      viewBox="0 0 14 14"
      // stroke={color}
      style={{ marginBottom: "2px" }}
      {...props}
    >
      <g>
        <g>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="2"
            d="M13 1L1 13"
          />
        </g>
        <g>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="2"
            d="M13 13L1 1"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
