import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import Button from "../../../../components/buttons/Button/Button";
import {
  deleteAuthorityById,
  deleteDocument,
  deleteEvidence,
  getCaseById,
  updateAuthorityById,
  updateEvidence,
} from "../../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import HeaderStyled from "./styled/HeaderStyled";
import BulkPanel from "../../../../components/Panel/BulkPanel";
import NoDocsIcon from "../../../../assets/img/svg/no_documents_icon.svg";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AddBundleForm from "../../../../components/forms/EvidenceForms/AddBundleForm";
import Table from "../../../../components/Table";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import { format, parseISO } from "date-fns";
import EditLabelForm from "../../../../components/forms/EvidenceForms/EditLabelForm";
import Arrow from "../../../../components/buttons/Button/Arrow";
import EditEvidenceTypeForm from "../../../../components/forms/EvidenceForms/EditEvidenceTypeForm";
import EditAllocateDateForm from "../../../../components/forms/EvidenceForms/EditAllocateDateForm";
import EvidencePreview from "../../../../components/forms/EvidenceForms/EvidencePreview";
import AssociateWitnessForm from "../../../../components/forms/EvidenceForms/AssociateWitnessForm";
import AssociateElementsThemesIssuesForm from "../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import { route } from "../../../../routes";
import EditCitationForm from "../../../../components/forms/EvidenceForms/EditCitationForm";
import EditKeySectionsForm from "../../../../components/forms/EvidenceForms/EditKeySectionsForm";
import { PulseLoader } from "react-spinners";
import AllocateParties from "../../../../components/forms/ApplicationsHubForms/AllocateParties";
import AssociateProofs from "../../../../components/forms/ApplicationsHubForms/AssociateProofs";
import PanelParties from "../../../../components/forms/CasesForms/PanelParties";
import { setTagField } from "../../../../utils/function";

const FLOW_EVIDENCE = "BULK_IMPORT/FLOW_EVIDENCE";
const FLOW_AUTHORITIES = "BULK_IMPORT/FLOW_AUTHORITIES";
const FLOW_APPLICATION_PAPERS = "BULK_IMPORT/FLOW_APPLICATION_PAPERS";
const FLOW_APPLICATION_AUTHORITIES = "BULK_IMPORT/APPLICATION_AUTHORITIES";
const FLOW_CASE = "BULK_IMPORT/CASE";

const EvidenceImport = ({
  noTopLabelAndButtons,
  createSingleElementHandler,
  applicationObject,
  changeApplicationPapersView,
  isApplicationPapersBulkImportFlow,
  isApplicationAuthoritiesBulkImportFlow,
  theme,
}) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [onSubmit, setOnSubmit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [creating, setCreating] = useState(false);
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const authoritiesBulkImport =
    useRouteMatch(`${route.baseApp.trialHub.authoritiesBulkImport}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.authoritiesBulkImport}`);
  const caseBulkImport =
    useRouteMatch(`${route.baseApp.trialHub.caseDocumentImport}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.caseDocumentImport}`);
  const isAuthoritiesBulkImportFlow = !!(
    authoritiesBulkImport && authoritiesBulkImport.isExact
  );
  const isCasesBulkImportFlow = !!(caseBulkImport && caseBulkImport.isExact);
  let { id, applicationId } = useParams();
  const afterCloseBundlePanelHandler = useSelector(
    (state) => state.rightSideBar.afterCloseAction
  );
  const dispatch = useDispatch();
  const [bulkImportFlow, setBulkImportFlow] = useState(null);
  const [pageSettingsObject, setPageSettingsObject] = useState(null);

  // data states
  const [caseObject, setCaseObject] = useState(null);
  const [activeItem, setActiveItem] = useState(0);
  const [selectedEvidence, setSelectedEvidence] = useState(null);
  const [evidences, setEvidences] = useState([]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const location = useLocation();

  const isDocumentReturn = () => {
    return (
      location &&
      location.state &&
      location.state.unique_data &&
      location.state.unique_data.length
    );
  };
  // set page settings flow
  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (bulkImportFlow) {
      case FLOW_CASE:
        setPageSettingsObject({
          pageConfig: () => {
            if (caseObject) {
              return [
                {
                  path: !is_admin ? `/app/cases` : `/admin/all-cases`,
                  title: `${is_admin ? "All Cases" : "Cases"}`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}`
                    : `/admin/all-cases/${id}`,
                  title: caseObject.label,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/documents`
                    : `/admin/all-cases/${id}/documents`,
                  title: `Documents`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/documents/import`
                    : `/admin/all-cases/${id}/documents/import`,
                  title: `New Case Document Item`,
                  activeMenuItem: CASES,
                },
              ];
            }
          },
          pageTopTitle: "New Case Document Item",
          addBundlePanelTitle: "Add Document",
          saveAndCloseButtonHandler: () => {
            if (history.action === "PUSH") {
              return history.goBack();
            } else {
              history.push(
                !is_admin
                  ? `/app/cases/${id}/documents`
                  : `/admin/all-cases/${id}/documents`
              );
            }
          },
          stagePanelLabels: ["Name", "Parties"],
          tableSettings: {
            clickAction: (obj) => {
              setSelectedEvidence(obj);
            },
            actions: [
              {
                type: "preview",
                callback: (object) => {
                  handlePreview(object);
                },
              },
              {
                type: "delete",
                callback: (object) => {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You won't be able to restore data",
                    beforeCloseHandler: async () => {
                      await deleteDocument(id, object.id, dispatch);
                      if (window.evidences.length === 1) {
                        setList([]);
                      } else {
                        setList(
                          window.evidences.filter(
                            (item) => item.id !== object.id
                          )
                        );
                      }
                    },
                  });
                  setSelectedEvidence(null);
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                },
              },
            ],
            fields: [
              {
                name: "id",
                label: "ID",
                renderer: (object) => {
                  return <Medium14Font>{object.id}</Medium14Font>;
                },
              },
              {
                name: "name",
                label: "NAME",
                renderer: (object) => {
                  return <Medium14Font>{object.name}</Medium14Font>;
                },
              },
              {
                name: "parties",
                label: "PARTIES",
                renderer: (object) => {
                  if (object.parties && object.parties.length) {
                    return (
                      <ul>
                        {object.parties.map((party, index) => (
                          <LiStyled key={index}>
                            <Medium14Font>{party.name}</Medium14Font>
                          </LiStyled>
                        ))}
                      </ul>
                    );
                  }
                },
              },
            ],
          },
          returnButton: {
            text: "Return to Docs Hub",
            clickHandler: () => {
              history.push(
                !is_admin
                  ? `/app/cases/${id}/documents`
                  : `/admin/all-cases/${id}/documents`
              );
            },
          },
        });
        break;
      case FLOW_EVIDENCE:
        setPageSettingsObject({
          pageConfig: () => {
            if (caseObject) {
              return [
                {
                  path: !is_admin ? `/app/cases` : `/admin/all-cases`,
                  title: `${is_admin ? "All Cases" : "Cases"}`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}`
                    : `/admin/all-cases/${id}`,
                  title: caseObject.label,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/trial-hub/evidence`
                    : `/admin/all-cases/${id}/trial-hub/evidence`,
                  title: `Trial Hub - Evidence`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/trial-hub/evidence/import`
                    : `/admin/all-cases/${id}/trial-hub/evidence/import`,
                  title: `New Evidence Item`,
                  activeMenuItem: CASES,
                },
              ];
            }
          },
          pageTopTitle: "New Evidence Item",
          addBundlePanelTitle: "Add Evidence",
          saveAndCloseButtonHandler: () => {
            if (history.action === "PUSH") {
              return history.goBack();
            } else {
              if (isDocumentReturn()) {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/documents`
                    : `/admin/all-cases/${id}/documents`
                );
              } else {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/trial-hub/evidence`
                    : `/admin/all-cases/${id}/trial-hub/evidence`
                );
              }
            }
          },
          stagePanelLabels: [
            "Label",
            "Evidence Type",
            "Allocate Date",
            "Associate Witness",
            "Flag Relevance",
          ],
          tableSettings: {
            clickAction: (obj) => {
              setSelectedEvidence(obj);
            },
            actions: [
              {
                type: "preview",
                callback: (object) => {
                  handlePreview(object);
                },
              },
              {
                type: "delete",
                callback: (object) => {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You won't be able to restore data",
                    beforeCloseHandler: async () => {
                      await deleteEvidence(object.id, id, dispatch);
                      if (window.evidences.length === 1) {
                        setList([]);
                      } else {
                        setList(
                          window.evidences.filter(
                            (item) => item.id !== object.id
                          )
                        );
                      }
                    },
                  });
                  setSelectedEvidence(null);
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                },
              },
            ],
            fields: [
              {
                name: "id",
                label: "ID",
                renderer: (object) => {
                  return <Medium14Font>{object.id}</Medium14Font>;
                },
              },
              {
                name: "label",
                label: "LABEL",
                renderer: (object) => {
                  return <Medium14Font>{object.label}</Medium14Font>;
                },
              },
              {
                name: "type",
                label: "TYPE",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.type}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "date",
                label: "DATE",
                renderer: (object) => {
                  return (
                    <Medium14Font>
                      {object.date
                        ? format(parseISO(object.date), "dd-MM-yyyy")
                        : ""}
                    </Medium14Font>
                  );
                },
              },
              {
                name: "witnesses",
                label: "WITNESSES",
                renderer: (object) => {
                  if (object.witnesses && object.witnesses.length) {
                    return (
                      <ul>
                        {object.witnesses.map((witness, index) => (
                          <LiStyled key={index}>
                            <Medium14Font>{witness.name}</Medium14Font>
                          </LiStyled>
                        ))}
                      </ul>
                    );
                  }
                },
              },
              {
                name: "tags",
                label: "RELEVANCE",
                renderer: (object) => {
                  if (object.tags && object.tags.length) {
                    return (
                      <ul>
                        {object.tags.map((tag, index) => {
                          return <div>{tag.element}</div>;
                        })}
                      </ul>
                    );
                  }
                },
              },
            ],
          },
          returnButton: isDocumentReturn()
            ? {
                text: "Return to Docs Hub",
                clickHandler: () => {
                  history.push(
                    !is_admin
                      ? `/app/cases/${id}/documents`
                      : `/admin/all-cases/${id}/documents`
                  );
                },
              }
            : {
                text: "Return to Trial Hub",
                clickHandler: () => {
                  history.push(
                    !is_admin
                      ? `/app/cases/${id}/trial-hub/evidence`
                      : `/admin/all-cases/${id}/trial-hub/evidence`
                  );
                },
              },
        });
        break;
      case FLOW_AUTHORITIES:
        setPageSettingsObject({
          pageConfig: () => {
            if (caseObject) {
              return [
                {
                  path: !is_admin ? `/app/cases` : `/admin/all-cases`,
                  title: `${is_admin ? "All Cases" : "Cases"}`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}`
                    : `/admin/all-cases/${id}`,
                  title: caseObject.label,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/trial-hub/authorities`
                    : `/admin/all-cases/${id}/trial-hub/authorities`,
                  title: `Trial Hub - Authorities`,
                  activeMenuItem: CASES,
                },
                {
                  path: !is_admin
                    ? `/app/cases/${id}/trial-hub/authorities/import`
                    : `/admin/all-cases/${id}/trial-hub/authorities/import`,
                  title: `New Authorities Item`,
                  activeMenuItem: CASES,
                },
              ];
            }
          },
          pageTopTitle: "New Authorities Item",
          addBundlePanelTitle: "Add Authorities",
          saveAndCloseButtonHandler: () => {
            if (history.action === "PUSH") {
              return history.goBack();
            } else {
              if (isDocumentReturn()) {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/documents`
                    : `/admin/all-cases/${id}/documents`
                );
              } else {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/trial-hub/authorities`
                    : `/admin/all-cases/${id}/trial-hub/authorities`
                );
              }
            }
          },
          stagePanelLabels: [
            "Label",
            "Define Type",
            "Citation",
            "Identify Sections",
            "Flag Relevance",
          ],
          tableSettings: {
            clickAction: (obj) => {
              setSelectedEvidence(obj);
            },
            actions: [
              {
                type: "preview",
                callback: (object) => {
                  handlePreview(object);
                },
              },
              {
                type: "delete",
                callback: (object) => {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You won't be able to restore data",
                    beforeCloseHandler: async () => {
                      let resp;
                      if (isAuthoritiesBulkImportFlow) {
                        resp = await deleteAuthorityById(
                          object.id,
                          id,
                          dispatch
                        );
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      } else if (isCasesBulkImportFlow) {
                        resp = await deleteDocument(id, object.id, dispatch);
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      } else {
                        resp = await deleteEvidence(object.id, id, dispatch);
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      }
                    },
                  });
                  setSelectedEvidence(null);
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                },
              },
            ],
            fields: [
              {
                name: "id",
                label: "ID",
                renderer: (object) => {
                  return <Medium14Font>{object.id}</Medium14Font>;
                },
              },
              {
                name: "title",
                label: "LABEL",
                renderer: (object) => {
                  return <Medium14Font>{object.title}</Medium14Font>;
                },
              },
              {
                name: "type",
                label: "TYPE",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.type}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "jurisdiction",
                label: "JURISDICTION",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.jurisdiction}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "citation",
                label: "CITATION",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.citation}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "key_sections",
                label: "SECTIONS",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.key_sections}</Medium14Font>
                    </div>
                  );
                },
              },

              {
                name: "tags",
                label: "RELEVANCE",
                renderer: (object) => {
                  if (object.tags && object.tags.length) {
                    return (
                      <ul>
                        {object.tags.map((tag, index) => {
                          return <div>{tag.element}</div>;
                        })}
                      </ul>
                    );
                  }
                },
              },
            ],
          },
          returnButton: isDocumentReturn()
            ? {
                text: "Return to Docs Hub",
                clickHandler: () => {
                  history.push(
                    !is_admin
                      ? `/app/cases/${id}/documents`
                      : `/admin/all-cases/${id}/documents`
                  );
                },
              }
            : {
                text: "Return to Trial Hub",
                clickHandler: () => {
                  history.push(
                    !is_admin
                      ? `/app/cases/${id}/trial-hub/authorities`
                      : `/admin/all-cases/${id}/trial-hub/authorities`
                  );
                },
              },
        });
        break;
      case FLOW_APPLICATION_PAPERS:
        setPageSettingsObject({
          pageConfig: () => null,
          pageTopTitle: null,
          addBundlePanelTitle: "Add Application Papers",
          saveAndCloseButtonHandler: isDocumentReturn()
            ? () => {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/documents`
                    : `/admin/all-cases/${id}/documents`
                );
              }
            : null,
          stagePanelLabels: [
            "Label",
            "Define Type",
            "Allocate Date",
            "Allocate Parties",
            "Associate Proofs",
          ],
          tableSettings: {
            clickAction: (obj) => {
              setSelectedEvidence(obj);
            },
            actions: [
              {
                type: "preview",
                callback: (object) => {
                  handlePreview(object);
                },
              },
              {
                type: "delete",
                callback: (object) => {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You won't be able to restore data",
                    beforeCloseHandler: async () => {
                      let resp;
                      if (isAuthoritiesBulkImportFlow) {
                        resp = await deleteAuthorityById(
                          object.id,
                          id,
                          dispatch
                        );
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      } else if (isApplicationPapersBulkImportFlow) {
                      } else if (isCasesBulkImportFlow) {
                        resp = await deleteDocument(id, object.id, dispatch);
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      } else {
                        resp = await deleteEvidence(object.id, id, dispatch);
                        if (resp) {
                          setList(
                            window.evidences.filter(
                              (item) => item.id !== object.id
                            )
                          );
                        }
                      }
                    },
                  });
                  setSelectedEvidence(null);
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                },
              },
            ],
            fields: [
              {
                name: "id",
                label: "ID",
                renderer: (object) => {
                  return <Medium14Font>{object.id}</Medium14Font>;
                },
              },
              {
                name: "label",
                label: "LABEL",
                renderer: (object) => {
                  return <Medium14Font>{object.label}</Medium14Font>;
                },
              },
              {
                name: "doc_type",
                label: "TYPE",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.doc_type}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "date",
                label: "DATE",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>
                        {object.date
                          ? format(parseISO(object.date), "dd-MM-yyyy")
                          : ""}
                      </Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "parties",
                label: "PARTIES",
                renderer: (object) => {
                  if (object.parties && object.parties.length) {
                    return (
                      <ul>
                        {object.parties.map((party, index) => {
                          return (
                            <li key={index}>
                              <Medium14Font>{party.name}</Medium14Font>
                            </li>
                          );
                        })}
                      </ul>
                    );
                  }
                },
              },
              {
                name: "reliefs",
                label: "RELIEFS",
                renderer: (object) => {
                  if (
                    (object.reliefs && object.reliefs.length) ||
                    (object.proofs && object.proofs.length)
                  ) {
                    return (
                      <ul>
                        {[...object.reliefs, ...object.proofs].map(
                          (el, index) => (
                            <li key={index}>
                              <Medium14Font>{el.name}</Medium14Font>
                            </li>
                          )
                        )}
                      </ul>
                    );
                  }
                },
              },
            ],
          },
          returnButton: isDocumentReturn()
            ? {
                text: "Return to Docs Hub",
                clickHandler: () => {
                  history.push(
                    !is_admin
                      ? `/app/cases/${id}/documents`
                      : `/admin/all-cases/${id}/documents`
                  );
                },
              }
            : {
                text: "Return to Application Papers",
                clickHandler: (list) => {
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  changeApplicationPapersView(list);
                },
              },
        });
        break;
      case FLOW_APPLICATION_AUTHORITIES:
        setPageSettingsObject({
          pageConfig: () => null,
          pageTopTitle: null,
          addBundlePanelTitle: "Add Application Authorities",
          saveAndCloseButtonHandler: isDocumentReturn()
            ? () => {
                history.push(
                  !is_admin
                    ? `/app/cases/${id}/documents`
                    : `/admin/all-cases/${id}/documents`
                );
              }
            : null,
          stagePanelLabels: [
            "Label",
            "Define Type",
            "Citation",
            "Identify Sections",
            "Associate Proofs",
          ],
          tableSettings: {
            clickAction: (obj) => {
              setSelectedEvidence(obj);
            },
            actions: [
              {
                type: "preview",
                callback: (object) => {
                  handlePreview(object);
                },
              },
              {
                type: "delete",
                callback: (object) => {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You won't be able to restore data",
                    beforeCloseHandler: async () => {
                      const resp = await deleteAuthorityById(
                        object.id,
                        id,
                        dispatch
                      );
                      if (resp) {
                        setList(
                          window.evidences.filter(
                            (item) => item.id !== object.id
                          )
                        );
                      }
                    },
                  });
                  setSelectedEvidence(null);
                  dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                },
              },
            ],
            fields: [
              {
                name: "id",
                label: "ID",
                renderer: (object) => {
                  return <Medium14Font>{object.id}</Medium14Font>;
                },
              },
              {
                name: "title",
                label: "LABEL",
                renderer: (object) => {
                  return <Medium14Font>{object.title}</Medium14Font>;
                },
              },
              {
                name: "type",
                label: "TYPE",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.type}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "jurisdiction",
                label: "JURISDICTION",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.jurisdiction}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "citation",
                label: "CITATION",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.citation}</Medium14Font>
                    </div>
                  );
                },
              },
              {
                name: "key_sections",
                label: "SECTIONS",
                renderer: (object) => {
                  return (
                    <div className="d-flex flex-column">
                      <Medium14Font>{object.key_sections}</Medium14Font>
                    </div>
                  );
                },
              },

              {
                name: "reliefs",
                label: "RELIEFS",
                renderer: (object) => {
                  if (
                    (object.reliefs && object.reliefs.length) ||
                    (object.proofs && object.proofs.length)
                  ) {
                    return (
                      <ul>
                        {[...object.reliefs, ...object.proofs].map(
                          (el, index) => (
                            <li key={index}>
                              <Medium14Font>{el.name}</Medium14Font>
                            </li>
                          )
                        )}
                      </ul>
                    );
                  }
                },
              },
            ],
          },
          returnButton: {
            text: "Return to Application Authorities",
            clickHandler: (list) => {
              dispatch({ type: HIDE_RIGHT_SIDE_BAR });
              changeApplicationPapersView(list);
            },
          },
        });
        break;
    }
  }, [bulkImportFlow, selectedEvidence, evidences]);

  useEffect(() => {
    window.evidences = evidences;
    return () => {
      window.evidences = [];
    };
  }, [evidences]);
  const setList = (evidenceResp) => {
    if (evidenceResp) {
      setEvidences(setTagField(caseObject, evidenceResp));
    }
  };

  useEffect(() => {
    const getData = async () => {
      if (
        !location ||
        !location.state ||
        !location.state.unique_data ||
        !location.state.unique_data.length
      ) {
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content: (
            <AddBundleForm
              callback={setList}
              caseId={id}
              applicationId={applicationId}
              isApplicationPapersBulkImportFlow={
                isApplicationPapersBulkImportFlow
              }
              isCasesBulkImportFlow={isCasesBulkImportFlow}
              isApplicationAuthoritiesBulkImportFlow={
                isApplicationAuthoritiesBulkImportFlow
              }
              applicationObject={applicationObject}
            />
          ),
          afterCloseAction: () => {
            // history.push(!is_admin ? `/app/cases/${id}/trial-hub/evidence` : `/admin/all-cases/${id}/trial-hub/evidence`);
            // switch (bulkImportFlow) {
            //   case FLOW_EVIDENCE:
            //     history.push(
            //       !is_admin
            //         ? `/app/cases/${id}/trial-hub/evidence`
            //         : `/admin/all-cases/${id}/trial-hub/evidence`
            //     );
            //     break;
            //
            //   case FLOW_AUTHORITIES:
            //     history.push(
            //       !is_admin
            //         ? `/app/cases/${id}/trial-hub/authorities`
            //         : `/admin/all-cases/${id}/trial-hub/authorities`
            //     );
            //     break;
            //
            //   default:
            //     return;
            // }
            if (afterCloseBundlePanelHandler) {
              afterCloseBundlePanelHandler();
            }
          },
          title: isCasesBulkImportFlow
            ? "Add Case Document"
            : isAuthoritiesBulkImportFlow ||
              isApplicationAuthoritiesBulkImportFlow
            ? "Add Authorities"
            : isApplicationPapersBulkImportFlow
            ? "Add Application Papers"
            : "Add Evidence",
        });
      }
      const caseResp = await getCaseById(id, dispatch);
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
      if (caseResp) {
        setCaseObject(caseResp);
      }

      if (isAuthoritiesBulkImportFlow) {
        setBulkImportFlow(FLOW_AUTHORITIES);
      } else if (isApplicationPapersBulkImportFlow) {
        setBulkImportFlow(FLOW_APPLICATION_PAPERS);
      } else if (isApplicationAuthoritiesBulkImportFlow) {
        setBulkImportFlow(FLOW_APPLICATION_AUTHORITIES);
      } else if (isCasesBulkImportFlow) {
        setBulkImportFlow(FLOW_CASE);
      } else {
        setBulkImportFlow(FLOW_EVIDENCE);
      }
      if (
        location &&
        location.state &&
        location.state.unique_data &&
        location.state.unique_data.length
      ) {
        setEvidences(location.state.unique_data);
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content: (
            <EditLabelForm
              callback={updateEvidenceList}
              applicationId={applicationId}
              caseId={id}
              evidence={selectedEvidence}
              isApplicationPapersBulkImportFlow={
                isApplicationPapersBulkImportFlow
              }
              isCasesBulkImportFlow={isCasesBulkImportFlow}
              isApplicationAuthoritiesBulkImportFlow={
                isApplicationAuthoritiesBulkImportFlow
              }
            />
          ),
          title: "Label",
        });
        setSelectedEvidence(location.state.unique_data[0]);
        const state = { ...history.location.state };
        delete state.data;
        history.replace({ ...history.location, state });
      }
      setIsLoading(false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!selectedEvidence && evidences.length) {
      setSelectedEvidence(evidences[0]);
    }
  }, [evidences]);

  const updateEvidenceList = (evidence) => {
    let list = [].concat(evidences);
    let index_;
    list.forEach((item_, index) => {
      if (item_.id === evidence.id) {
        list[index] = evidence;
        index_ = index;
      }
    });

    setList(list);
    if (typeof index_ !== "undefined" && evidences.length - 1 > index_) {
      setSelectedEvidence(evidences[index_ + 1]);
    } else {
      if (activeItem < maxItems) {
        setActiveItem(activeItem + 1);
        setSelectedEvidence(evidences[0]);
      } else {
        dispatch({
          type: HIDE_RIGHT_SIDE_BAR,
        });
      }
    }
  };

  const sendData = async (submitObject) => {
    if (Object.keys(submitObject).length) {
      let resp;
      if (
        isAuthoritiesBulkImportFlow ||
        isApplicationAuthoritiesBulkImportFlow
      ) {
        resp = await updateAuthorityById(
          selectedEvidence.id,
          id,
          dispatch,
          submitObject
        );
      } else {
        resp = await updateEvidence(
          selectedEvidence.id,
          id,
          dispatch,
          submitObject
        );
      }
      if (resp) {
        updateEvidenceList(resp);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        // setIsSubmitting(false);
      }
    }
  };

  // TODO: Refactor THIS:
  useEffect(() => {
    if (selectedEvidence && selectedEvidence.id && pageSettingsObject) {
      if (activeItem === 0) {
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content: (
            <EditLabelForm
              callback={updateEvidenceList}
              applicationId={applicationId}
              caseId={id}
              evidence={selectedEvidence}
              isApplicationPapersBulkImportFlow={
                isApplicationPapersBulkImportFlow
              }
              isCasesBulkImportFlow={isCasesBulkImportFlow}
              isApplicationAuthoritiesBulkImportFlow={
                isApplicationAuthoritiesBulkImportFlow
              }
            />
          ),
          title: pageSettingsObject.stagePanelLabels[activeItem],
        });
      } else if (activeItem === 1) {
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content: isCasesBulkImportFlow ? (
            <PanelParties
              caseRoles={true}
              key={selectedEvidence.id}
              savedPartiesList={selectedEvidence.parties}
              applicationPaperObject={selectedEvidence}
              setApplicationPaperObject={updateEvidenceList}
              caseObject={caseObject}
              routeParams={{ caseId: id, applicationId }}
            />
          ) : (
            <EditEvidenceTypeForm
              callback={updateEvidenceList}
              caseId={id}
              evidence={selectedEvidence}
              applicationId={applicationId}
              isApplicationPapersBulkImportFlow={
                isApplicationPapersBulkImportFlow
              }
              isApplicationAuthoritiesBulkImportFlow={
                isApplicationAuthoritiesBulkImportFlow
              }
            />
          ),
          title: pageSettingsObject.stagePanelLabels[activeItem],
        });
      } else if (activeItem === 2) {
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content:
            isAuthoritiesBulkImportFlow ||
            isApplicationAuthoritiesBulkImportFlow ? (
              <EditCitationForm
                key={selectedEvidence.id}
                callback={updateEvidenceList}
                caseId={id}
                evidence={selectedEvidence}
              />
            ) : (
              <EditAllocateDateForm
                callback={updateEvidenceList}
                caseId={id}
                evidence={selectedEvidence}
                applicationId={applicationId}
                isApplicationPapersBulkImportFlow={
                  isApplicationPapersBulkImportFlow
                }
              />
            ),
          title: pageSettingsObject.stagePanelLabels[activeItem],
        });
      } else if (activeItem === 3) {
        if (isApplicationPapersBulkImportFlow) {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            content: (
              <AllocateParties
                caseObject={caseObject}
                key={selectedEvidence.id}
                savedPartiesList={selectedEvidence.parties}
                applicationPaperObject={selectedEvidence}
                setApplicationPaperObject={updateEvidenceList}
                applicationObject={applicationObject}
                routeParams={{ caseId: id, applicationId }}
              />
            ),
            title: pageSettingsObject.stagePanelLabels[activeItem],
          });
        } else {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            content:
              isAuthoritiesBulkImportFlow ||
              isApplicationAuthoritiesBulkImportFlow ? (
                <EditKeySectionsForm
                  callback={updateEvidenceList}
                  caseId={id}
                  evidence={selectedEvidence}
                />
              ) : (
                <AssociateWitnessForm
                  key={selectedEvidence.id}
                  evidenceObject={selectedEvidence}
                  setEvidenceObject={updateEvidenceList}
                  caseObject={caseObject}
                  caseId={id}
                  noCloseAfterSave
                  // afterSubmit={associateWitnessChange}
                />
              ),
            title: pageSettingsObject.stagePanelLabels[activeItem],
          });
        }
      } else if (activeItem === 4) {
        if (
          isApplicationPapersBulkImportFlow ||
          isApplicationAuthoritiesBulkImportFlow
        ) {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            content: (
              <AssociateProofs
                isAuthority={isApplicationAuthoritiesBulkImportFlow}
                key={selectedEvidence.id}
                applicationObject={applicationObject}
                applicationPaperObject={selectedEvidence}
                setApplicationPaperObject={updateEvidenceList}
                savedReliefs={selectedEvidence.reliefs}
                savedProofs={selectedEvidence.proofs}
                routeParams={{ caseId: id, applicationId: applicationId }}
              />
            ),
            title: pageSettingsObject.stagePanelLabels[activeItem],
          });
        } else {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            content: (
              <AssociateElementsThemesIssuesForm
                key={selectedEvidence.id}
                evidenceObject={selectedEvidence}
                evidenceId={selectedEvidence.id}
                // setEvidenceObject={updateEvidenceList}
                caseObject={caseObject}
                caseId={id}
                sendData={sendData}
              />
            ),
            title: pageSettingsObject.stagePanelLabels[activeItem],
          });
        }
      }
    }
  }, [selectedEvidence, activeItem]);

  const handleDelete = async (e) => {
    e.preventDefault();
    setOnDelete(true);
    for (let i = 0; i < evidences.length; i++) {
      await deleteEvidence(evidences[i].id, id, dispatch);
    }
    setEvidences([]);
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: <AddBundleForm callback={setList} caseId={id} />,
      title: "Add Evidence",
    });
    setOnDelete(false);
  };

  const handlePreview = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <EvidencePreview key={object.id} caseId={id} evidence={object} />
      ),
      title: "Preview",
    });
  };
  const maxItems = isCasesBulkImportFlow ? 1 : 4;
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  return (
    <Fragment>
      {!isApplicationPapersBulkImportFlow &&
      !isApplicationAuthoritiesBulkImportFlow ? (
        <PageConfiguration configArray={pageSettingsObject.pageConfig()} />
      ) : null}
      {!noTopLabelAndButtons ? (
        <div className="row">
          <div className="col-6">
            <Bold30Font>{pageSettingsObject.pageTopTitle}</Bold30Font>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center mb-3">
            {evidences.length ? (
              <Button
                clickHandler={handleDelete}
                loading={onDelete}
                disabled={onDelete}
                style={{ marginRight: "20px" }}
                secondary
                delete_
              >
                Delete
              </Button>
            ) : null}
            <Button
              clickHandler={pageSettingsObject.saveAndCloseButtonHandler}
              disabled={onSubmit}
              loading={onSubmit}
              type={"submit"}
              primary
            >
              <Bold14Font textColor={theme.colors.white}>Close</Bold14Font>
            </Button>
          </div>
        </div>
      ) : null}
      <HeaderStyled>
        <div className="container-fluid">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Bold24Font>Bulk Import</Bold24Font>
            </div>
          </div>
        </div>
      </HeaderStyled>
      <BulkPanel
        items={pageSettingsObject.stagePanelLabels}
        canSwitch={evidences.length > 0}
        setActiveItem={setActiveItem}
        active={activeItem}
      />
      {!evidences.length ? (
        <div
          className="align-items-center d-flex justify-content-center"
          style={{ height: "50vh" }}
        >
          <div style={{ width: "230px" }}>
            <div className="d-flex justify-content-center">
              <img src={NoDocsIcon} alt="" />
            </div>
            <div className="text-center">
              <Bold18Font textColor={"#0f122f"}>
                Your Documents will appear here
              </Bold18Font>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <Table
            selectedId={selectedEvidence ? selectedEvidence.id : null}
            data={evidences}
            settings={pageSettingsObject.tableSettings}
          />
          <div className="d-flex justify-content-end align-items-center">
            {activeItem > 0 ? (
              <Button
                withIcon
                secondary
                className="mr-2"
                clickHandler={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setActiveItem(activeItem - 1);
                }}
              >
                <Arrow back={true} className="mr-1" /> Back
              </Button>
            ) : null}
            {activeItem < maxItems ? (
              <Button
                withIcon
                secondary
                clickHandler={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setActiveItem(activeItem + 1);
                }}
              >
                Continue <Arrow className="ml-1" />
              </Button>
            ) : (
              <Button
                secondary
                clickHandler={() =>
                  pageSettingsObject.returnButton.clickHandler(evidences)
                }
              >
                {pageSettingsObject.returnButton.text}
              </Button>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default withTheme(EvidenceImport);
