import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageSocialLinkStyled = styled.a.attrs(({ href }) => ({
  href: href,
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  ${({ footer, theme }) =>
    footer
      ? `
          width: ${rem(`42px`)};
          height: ${rem(`42px`)};
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.2);

          display: flex;
          justify-content: center;
          align-items: center;

          font-size: ${rem("24px")};
          color: ${theme.colors.white};
          text-decoration: none;

          :hover,
          :active {
            color: ${theme.colors.white};
            text-decoration: none;
          }
          @media (max-width: 767.98px) {
            width: 5.625rem;
            height: 5.625rem;
            font-size: 3.5rem;
          }
      `
      : `
          width: ${rem(`77px`)};
          height: ${rem(`77px`)};

          display: flex;
          justify-content: center;
          align-items: center;

          font-size: ${rem("24px")};
          color: ${theme.colors.white};
          text-decoration: none;

          :hover,
          :active {
            color: ${theme.colors.blue};
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.gray};
            border-radius: 50%;
            text-decoration: none;
          }

          @media (max-width: 767.98px) {
            width: 10rem;
            height: 10rem;
            font-size: 3.5rem;
          }

    `};
`;

export default LandingPageSocialLinkStyled;
