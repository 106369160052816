import styled, { keyframes } from "styled-components/macro";
import rem from "../../../../../utils/rem";
import { bounceInLeft } from "react-animations";

const bounceAnimation = keyframes`${bounceInLeft}`;

const BaseAppLayoutLeftSideBarStyled = styled.div`
  min-height: 320px;
  height: 100vh;
  background: ${({ theme }) => theme.colors.blue};
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem 1.6rem 1.2rem 1.6rem;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  animation: 0.5s ${bounceAnimation};
  -webkit-box-shadow: -5px 0 9px 6px #333;
  -moz-box-shadow: -5px 0 9px 6px #333;
  box-shadow: -5px 0 9px 6px #333;

  @media (max-width: 767.98px) {
    width: 100%;
  }
`;

export default BaseAppLayoutLeftSideBarStyled;
