import styled, { css } from "styled-components/macro";
// import { Medium14Font } from "../../FontsNewComponent/Fonts";

const TableRowStyled = styled.tr`
  border: 1px solid ${({ theme, isDragging }) => theme.colors.gray};
  background-color: ${({ theme, collapsed, selected }) =>
    !collapsed && !selected ? theme.colors.white : "#f3fafd"};
  padding: ${({ isDragging }) => (isDragging ? "none" : "15px 15px")};
  cursor: ${({ clickAction }) => (clickAction ? "pointer" : "default")};
`;

export default TableRowStyled;

// ${Medium14Font} {
//   color: ${({ theme, collapsed, selected }) =>
// !collapsed && !selected ? `rgba(15, 18, 47, 0.6);` : theme.colors.white};
// }
// :hover {
//   ${({ theme }) => `
//     ${Medium14Font} {
//       color: ${theme.colors.white};
//     }
//     background-color: #f0f0fa;
//   `}
