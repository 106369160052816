import styled from "styled-components/macro";
import {
  Bold18Font,
  Bold30Font,
  Medium30Font,
} from "../../../FontsNewComponent/Fonts";
import rem from "../../../../utils/rem";

const CasesMenuStyled = styled.div`
  display: flex;
  //width: 100%;
  .menu-item {
    color: ${({ theme }) => theme.colors.dark};
    cursor: pointer;
    :active {
      color: ${({ theme }) => theme.colors.blue};
    }

    &__active {
      ${Bold30Font} {
        position: relative;

        &:after {
          content: "";
          width: 8px;
          height: 3px;
          border-radius: 2px;
          background-color: ${({ theme }) => theme.colors.blue};
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }

      ${Bold18Font} {
        position: relative;

        &:after {
          content: "";
          width: 8px;
          height: 3px;
          border-radius: 2px;
          background-color: ${({ theme }) => theme.colors.blue};
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
    }
  }

  ${Bold30Font} {
    @media (max-width: 575.98px) {
      font-size: 1.675rem;
    }
  }

  ${Medium30Font} {
    @media (max-width: 575.98px) {
      font-size: 1.675rem;
    }
  }
`;

export default CasesMenuStyled;
