import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import RsbTabsMenu from "../../RSBTabsMenu";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import PulseLoader from "react-spinners/PulseLoader";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox";
import InputNew from "../../InputNew/InputNew";
import AddBundleForm from "../EvidenceForms/AddBundleForm";
import {
  createNewEvidence,
  getEvidenceList,
  updateWitnessById,
} from "../../../containers/Auth/auth";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_CUSTOM_CLOSE_ACTION,
} from "../../../redux/types";
import TextAreaCases from "../../TextArea/TextAreaCases";

const menuTabs = [
  { title: "Retrieve" },
  { title: "New Testimony" },
  { title: "Import" },
];

const COAFieldsList = [
  "causes_of_action",
  "causes_of_action_parent",
  "causes_of_action_defensive",
  "issues",
  "issues_parent",
  "themes",
  "themes_parent",
];

const createUniqueCOAListsObject = (
  evidence,
  witnessObject,
  uniqueRelevanceObject
) => {
  COAFieldsList.forEach((field) => {
    // add all existing relevance in witnessObject to uniqueRelevanceObject
    if (witnessObject[field].length) {
      uniqueRelevanceObject[field] = [...witnessObject[field]];
    }
    if (evidence[field].length) {
      // if the field is not in uniqueRelevanceObject - add it and make it an empty array
      if (!(field in uniqueRelevanceObject)) {
        uniqueRelevanceObject[field] = [];
      }
      evidence[field].forEach((el) => {
        if (
          uniqueRelevanceObject[field].findIndex(
            (item) => item.id === el.id
          ) === -1
        ) {
          uniqueRelevanceObject[field].push(el);
        }
      });
    }
  });
};

const setWitnessTypeBasedOnEvidenceWitnesses = (evidenceObject) => {
  if (
    evidenceObject &&
    evidenceObject.witnesses &&
    evidenceObject.witnesses.length
  ) {
    if (
      evidenceObject.witnesses.filter((witness) => witness.type === "Primary")
        .length
    ) {
      return "Secondary";
    }
  }
  return "Primary";
};

const LinkEvidenceForm = ({
  witnessObject,
  callback,
  selectedMenuTab,
  setStage,
  caseId,
  isXExamAddEditPage,
  theme,
  returnData,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenuTab, setActiveMenuTab] = useState(selectedMenuTab);
  const [submitObject, setSubmitObject] = useState({});
  const [evidenceList, setEvidenceList] = useState([]);
  const [evidenceUniqueTypesList, setEvidenceUniqueTypesList] = useState([]);
  const [evidenceFilterOptions, setEvidenceFilterOptions] = useState([
    { label: "All", value: "All" },
  ]);
  const [
    evidenceSelectedFilterOption,
    setEvidenceSelectedFilterOption,
  ] = useState({});
  // new testimony
  const [evidenceLabel, setEvidenceLabel] = useState("");
  const [evidenceSummary, setEvidenceSummary] = useState("");

  const [evidenceLinkedList, setEvidenceLinkedList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const rightSideBarCustomCloseAction = useSelector(
    (state) => state.rightSideBar.customCloseAction
  );

  let form = React.createRef();

  const checkIfXExamAddEditWitnessFlowIsFalse = () =>
    !(isXExamAddEditPage && isXExamAddEditPage.isExact);

  useEffect(() => {
    if (activeMenuTab < 2 && rightSideBarCustomCloseAction) {
      dispatch({ type: SET_CUSTOM_CLOSE_ACTION, payload: false });
    }
  }, [activeMenuTab]);

  const showSuccessModalHandler = () => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const handleSubmit = async (event, redirect = false) => {
    event.preventDefault();
    if (!isLoading) {
      if (activeMenuTab === 0) {
        let resp;
        let submitObjectEditedWithFlagRelevance = {
          evidences: [],
        };
        const uniqueRelevanceObject = {};
        if (checkIfXExamAddEditWitnessFlowIsFalse()) {
          // TODO: This fix change type field from evidence type to witness type secondary!
          submitObject.evidences.forEach((evidenceObject) => {
            evidenceObject.type = setWitnessTypeBasedOnEvidenceWitnesses(
              evidenceObject
            );
            createUniqueCOAListsObject(
              evidenceObject,
              witnessObject,
              uniqueRelevanceObject
            );
            submitObjectEditedWithFlagRelevance.evidences.push(evidenceObject);
            submitObjectEditedWithFlagRelevance = {
              ...submitObjectEditedWithFlagRelevance,
              ...uniqueRelevanceObject,
            };
          });
          if (returnData) {
            resp = { ...witnessObject, ...submitObjectEditedWithFlagRelevance };
          } else {
            resp = await updateWitnessById(
              caseId,
              witnessObject.id,
              dispatch,
              submitObjectEditedWithFlagRelevance
            );
          }
        } else {
          submitObject.evidences.forEach((evidence) => {
            createUniqueCOAListsObject(
              evidence,
              witnessObject,
              uniqueRelevanceObject
            );
          });
          submitObjectEditedWithFlagRelevance = {
            ...submitObject,
            ...uniqueRelevanceObject,
          };
          resp = { ...witnessObject, ...submitObjectEditedWithFlagRelevance };
        }
        if (resp) {
          if (callback) {
            if (!checkIfXExamAddEditWitnessFlowIsFalse()) {
              let topics = witnessObject.witness.topics;
              let index = topics.findIndex((v) => v.id === witnessObject.id);
              topics[index] = resp;
              delete resp.witness;
              callback({ topics: topics });
            } else {
              callback(resp);
            }
          }
          if (checkIfXExamAddEditWitnessFlowIsFalse()) {
            showSuccessModalHandler();
          } else {
            dispatch({ type: HIDE_RIGHT_SIDE_BAR });
            if (setStage) {
              setStage(3);
            }
          }
        }
      } else if (activeMenuTab === 1) {
        const submitObject = {
          label: evidenceLabel,
          summary: evidenceSummary,
          type: "Testimony",
        };
        if (witnessObject.id) {
          submitObject[
            checkIfXExamAddEditWitnessFlowIsFalse() ? "witnesses" : "topics"
          ] = [
            checkIfXExamAddEditWitnessFlowIsFalse()
              ? { real_id: witnessObject.real_id, type: "Primary" }
              : { real_id: witnessObject.id },
          ];
        }
        const resp = await createNewEvidence(caseId, dispatch, submitObject);
        if (resp) {
          resp.fake_id = resp.id;
          if (callback) {
            if (checkIfXExamAddEditWitnessFlowIsFalse()) {
              const witnessObjectCopy = { ...witnessObject };
              resp.fake_id = resp.id;
              witnessObjectCopy.evidences.push(resp);
              if (callback) {
                callback(witnessObjectCopy);
              }
            } else {
              // here witnessObject is topicObject
              const topicObject = { ...witnessObject };
              // Add new linked evidence to topic
              topicObject.evidences.push(resp);
              // Copy witness
              const witnessObjectCopy = { ...topicObject.witness };
              // find linked topicObject index in array
              const topicObjectIndex = witnessObjectCopy.topics.findIndex(
                (el) => el.id === topicObject.id
              );
              delete topicObject.witness;
              // replace topic with new
              witnessObjectCopy.topics.splice(topicObjectIndex, 1, topicObject);
              callback(witnessObjectCopy);
            }

            showSuccessModalHandler();
          }
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          if (redirect) {
            history.push(
              `/app/cases/${caseId}/trial-hub/evidence/edit/${resp.id}`
            );
          }
        }
      }
    }
  };

  // get evidences list from API
  useEffect(() => {
    const getData = async () => {
      if (isLoading) {
        const resp = await getEvidenceList(caseId, dispatch);
        if (resp) {
          setEvidenceList(resp);

          const uniqueTypesList = [];
          // set unique list of evidence types
          resp.forEach((evidenceObject) => {
            if (uniqueTypesList.indexOf(evidenceObject.type) === -1) {
              uniqueTypesList.push(evidenceObject.type);
            }
          });
          const filterOptions = [];
          uniqueTypesList.forEach((option) => {
            if (!option) {
              filterOptions.push({ label: "No type", value: null });
            } else {
              filterOptions.push({ label: option, value: option });
            }
          });
          setEvidenceUniqueTypesList([...uniqueTypesList]);
          setEvidenceFilterOptions((prevState) => [
            ...prevState,
            ...filterOptions,
          ]);
          setEvidenceLinkedList(witnessObject.evidences);
          setIsLoading(false);
        }
      }
    };
    getData();
  }, []);

  // set submit object with linked evidences
  useEffect(() => {
    setSubmitObject((prevState) => ({
      ...prevState,
      evidences: evidenceLinkedList,
    }));
  }, [evidenceLinkedList]);

  const filter = (element) => {
    let check = true;

    if (
      Object.keys(evidenceSelectedFilterOption).length &&
      evidenceSelectedFilterOption.value !== "All"
    ) {
      check = element.type === evidenceSelectedFilterOption.value;
    }

    return check;
  };

  const onChangeFilterHandler = (selectedOption) => {
    setEvidenceSelectedFilterOption(selectedOption);
  };

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    item.fake_id = item.id;
    const findItem = (element) => element.fake_id === item.fake_id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="form-group" style={{ marginBottom: "41px" }}>
        <RsbTabsMenu
          tabs={menuTabs}
          activeMenuTab={activeMenuTab}
          setActiveMenuTab={setActiveMenuTab}
        />
      </div>

      <div style={{ height: "100%", overflowY: "auto" }}>
        {/* Retrieve Evidence */}
        {activeMenuTab === 0 ? (
          <div className="form-group">
            <SelectAnotherTry
              options={evidenceFilterOptions}
              onChange={onChangeFilterHandler}
              label="Filter"
              style={{ marginBottom: "20px" }}
            />
            <div style={{ padding: "0 15px" }}>
              <Bold14Font as="div" style={{ marginBottom: "17px" }}>
                Evidence list
              </Bold14Font>
              {evidenceList
                .filter(filter)
                .reverse()
                .map((evidenceObject) => (
                  <Checkbox
                    checked={evidenceLinkedList.find(
                      (item) => item.fake_id === evidenceObject.id
                    )}
                    label={evidenceObject.label}
                    onChange={() =>
                      onChangeCheckBoxHandler(
                        evidenceObject,
                        evidenceLinkedList,
                        setEvidenceLinkedList
                      )
                    }
                  />
                ))}
            </div>
          </div>
        ) : null}

        {/* New Testimony */}
        {activeMenuTab === 1 ? (
          <div className="form-group">
            <InputNew
              placeholder="Enter Evidence Label"
              name="label"
              label="Evidence Label"
              style={{ marginBottom: "20px" }}
              value={evidenceLabel}
              onChange={(event) => setEvidenceLabel(event.target.value)}
            />
            <TextAreaCases
              placeholder="Enter Summary"
              name="summary"
              label="Summary"
              value={evidenceSummary}
              onChange={(event) => setEvidenceSummary(event.target.value)}
            />
          </div>
        ) : null}

        {/* Import */}
        {activeMenuTab === 2 ? (
          <AddBundleForm
            // hideBundle
            noSaveButton
            callback={(resp) => {
              if (callback) {
                if (checkIfXExamAddEditWitnessFlowIsFalse()) {
                  callback({ evidences: resp });
                } else {
                  let topics = witnessObject.witness.topics;
                  let index = topics.findIndex(
                    (v) => v.id === witnessObject.id
                  );
                  topics[index].evidences = resp;
                  callback({ topics: topics });
                }
              }
              showSuccessModalHandler();
            }}
            caseId={caseId}
            witnessObject={witnessObject}
            isXExamFlowIsFalse={checkIfXExamAddEditWitnessFlowIsFalse()}
          />
        ) : null}
      </div>

      {/* Buttons part */}
      <div
        style={{
          paddingTop: "30px",
          borderTop:
            activeMenuTab < 2 ? `1px solid ${theme.colors.gray}` : null,
        }}
      >
        {activeMenuTab === 1 ? (
          <div className="form-group">
            <div className="container-fluid">
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-12">
                  <ButtonNew
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    wide
                    secondary
                    onClick={(event) => handleSubmit(event, true)}
                  >
                    Work Further with Evidence
                  </ButtonNew>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col d-flex justify-content-center">
                  <Medium14Font>Or</Medium14Font>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {activeMenuTab !== 2 ? (
          <div className="form-group">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <ButtonNew
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    wide
                    primary
                    clickHandler={handleSubmit}
                  >
                    Save
                  </ButtonNew>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

LinkEvidenceForm.propTypes = {
  witnessObject: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  selectedMenuTab: PropTypes.number,
  /**
   * Set or change stage of create/edit flow
   */
  setStage: PropTypes.func,
};

export default withTheme(LinkEvidenceForm);
