import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const signButtonStyled = styled.a`
  // TODO: Add color to theme
  border: 1px solid #282a44;
  padding: ${rem("10px 20px")};
  color: ${({ theme }) => theme.colors.white};
  //white-space: nowrap;

  :hover,
  :focus,
  :active {
    background: #262943;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 767.98px) {
    font-size: 2.4rem;
  }
`;

export default signButtonStyled;
