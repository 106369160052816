import React from "react";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
// Create styles
const borderColor = "gray";
const styles = StyleSheet.create({
  body: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    left: 35,
    right: 0,
    fontFamily: "Roboto",
    textAlign: "center",
    color: "grey",
  },
  header: {
    position: "absolute",
    fontSize: 12,
    top: 25,
    left: 0,
    right: 35,
    fontFamily: "Roboto",
    textAlign: "right",
    color: "grey",
  },
  field: {
    color: "gray",
    fontSize: 12,
    fontFamily: "Roboto",
    marginBottom: 2,
  },
  value: {
    fontSize: 10,
    fontFamily: "Roboto-Light",
    marginBottom: 10,
  },
  valueBold: {
    fontSize: 10,
    fontFamily: "Roboto",
    marginBottom: 10,
  },
  valueLi: {
    fontSize: 10,
    fontFamily: "Roboto-Light",
    marginBottom: 10,
    marginLeft: 10,
  },
  title: {
    fontSize: 13,
    marginBottom: 15,
    marginTop: 15,
    fontFamily: "Roboto",
  },
  subTitle: {
    fontSize: 12,
    marginBottom: 10,
    fontFamily: "Roboto",
  },
  table: {
    display: "table",
    width: "auto",
    fontFamily: "Roboto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: borderColor,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table_: {
    marginBottom: 15,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    fontFamily: "Roboto",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: borderColor,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    textAlign: "left",
    marginLeft: 5,
    fontFamily: "Roboto",
  },
  case: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "center",
  },
  flexRow: {
    flexDirection: "row",
  },
  tableTitle: {
    fontSize: 14,
    fontFamily: "Roboto",
    marginBottom: 10,
  },
});
Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});
Font.register({
  family: "Roboto-Light",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});
Font.register({
  family: "Roboto-Italic",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
});
const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const PdfAdviceOnProofs = ({
  weeks,
  copies,
  copiesSecond,
  caseObject,
  coreEvidence,
  authorities,
  documentsList,
  user,
  data,
}) => {
  const clients = caseObject.parties.filter((v) => {
    return v.client;
  });
  const addS = (val) => {
    if (!val) {
      return "s";
    } else if (val > 10 && val < 21) {
      return "s";
    } else if (val % 10 === 1) {
      return "";
    }
    return "s";
  };
  let total_index = 0;
  let documents_index = 0;
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <Text style={styles.header} fixed>
          Advice On Proofs
        </Text>
        <View>
          <Text style={styles.case}>{caseObject.label}</Text>
        </View>
        <View>
          <Text style={[styles.case, { color: "gray" }]}>Advice On Proofs</Text>
        </View>
        <View>
          <Text style={styles.title}>Summary</Text>
          <Text style={styles.value}>
            These are{" "}
            <Text style={styles.valueBold}>
              {caseObject.case_type
                ? caseObject.case_type.name
                : "[#Case Type]"}
            </Text>{" "}
            proceedings in which the client is{" "}
            <Text style={styles.valueBold}>
              {!clients.length
                ? "[#Plaintiff]"
                : clients.map((v) => v.name).join(", ")}
            </Text>
            .
          </Text>
        </View>
        <View>
          <Text style={styles.value}>The claim involves a claim for</Text>
        </View>
        <View>
          {caseObject.causes_of_action
            .filter((v) => !v.counterclaim)
            .map((v, i) => {
              return (
                <View>
                  <Text style={styles.valueLi}>
                    {i + 1}. {v.type.name}
                  </Text>
                </View>
              );
            })}
        </View>
        {caseObject.causes_of_action.filter((v) => v.counterclaim).length ? (
          <View>
            <Text style={styles.value}>Counterclaim</Text>
            <View>
              {caseObject.causes_of_action
                .filter((v) => v.counterclaim)
                .map((v, i) => {
                  return (
                    <View>
                      <Text style={styles.valueLi}>
                        {i + 1}. {v.type.name}
                      </Text>
                    </View>
                  );
                })}
            </View>
          </View>
        ) : null}
        <View>
          <Text style={styles.title}>Background</Text>
        </View>
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.background
              ? caseObject.advice.background
              : ""}
          </Text>
        </View>
        <View>
          <Text style={styles.title}>Counsel’s View of the Case</Text>
        </View>
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.case_theory
              ? caseObject.advice.case_theory
              : caseObject.case_theory}
          </Text>
        </View>

        <View>
          <Text style={styles.subTitle}>Elements of the Cause/s of Action</Text>
        </View>
        {caseObject.causes_of_action.filter((v) => !v.counterclaim).length
          ? caseObject.causes_of_action
              .filter((v) => !v.counterclaim)
              .map((v) => {
                return (
                  <View>
                    <Text style={styles.value}>
                      In order to establish the claim for{" "}
                      <Text style={styles.valueBold}>{v.type.name}</Text>, the
                      following must be established:
                    </Text>
                    {v.elements.map((e, i) => {
                      return (
                        <View>
                          <Text style={styles.valueLi}>
                            {i + 1}. {e.name};
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })
          : null}
        {caseObject.causes_of_action.filter((v) => v.counterclaim).length
          ? caseObject.causes_of_action
              .filter((v) => v.counterclaim)
              .map((v) => {
                return (
                  <View style={styles.value}>
                    <Text style={styles.value}>
                      The Counterclaim for{" "}
                      <Text style={styles.valueBold}>{v.type.name}</Text>{" "}
                      depends on the following established:
                    </Text>
                    {v.elements.map((e, i) => {
                      return (
                        <View>
                          <Text style={styles.valueLi}>
                            {i + 1}. {e.name};
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })
          : null}
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.causes_of_action
              ? caseObject.advice.causes_of_action
              : ""}
          </Text>
        </View>

        <View>
          <Text style={styles.title}>Themes in the Case</Text>
        </View>
        <View>
          <Text style={styles.value}>
            The case can also be considered from the perspective of the
            following themes:
          </Text>
        </View>
        <View>
          {caseObject.themes.map((v, i) => {
            return (
              <View>
                <Text style={styles.valueLi}>
                  {i + 1}. {v.name}
                </Text>
              </View>
            );
          })}
        </View>
        <View>
          <Text style={styles.title}>Analysis</Text>
        </View>
        <View>
          <Text style={styles.valueLi}>
            {caseObject.advice && caseObject.advice.analysis
              ? caseObject.advice.analysis
              : ""}
          </Text>
        </View>
        <View>
          <Text style={styles.title}>Witnesses</Text>
        </View>
        <View>
          <Text style={styles.value}>
            The following witnesses should attend if available on the hearing
            date:
          </Text>
        </View>
        {caseObject.witnesses.map((v, i) => {
          return (
            <View>
              <View>
                <Text style={styles.valueLi}>
                  {i + 1}. {v.name}
                </Text>
              </View>
              <View>
                <Text style={styles.valueLi}>
                  {v.class ? `${v.class},` : ""}
                  {v.party && v.party.role ? v.party.role : ""}
                </Text>
              </View>
            </View>
          );
        })}
        <View>
          <Text style={styles.value}>
            {" "}
            Please advise Counsel at the earliest opportunity if witnesses are
            unwilling or unavailable to attend at the hearing.
          </Text>
        </View>

        <View>
          <Text style={styles.title}>Documents</Text>
        </View>
        <View>
          <Text style={styles.value}>
            The following booklets, indexed and paginated, should be available
            for Counsel <Text style={styles.valueBold}>{weeks}</Text> week
            {addS(weeks)} in advance of the trial, with a further{" "}
            <Text style={styles.valueBold}>{copies}</Text> copie{addS(copies)}{" "}
            available for the Court and other parties.
          </Text>
        </View>
        <View>
          <Text style={styles.subTitle}>A. Case Documents Booklet</Text>
        </View>
        {Object.keys(documentsList).map((doc_key, index) => {
          let k = index;
          if (coreEvidence && coreEvidence.length) {
            k += 1;
          }
          documents_index = k;
          return (
            <View key={k}>
              <View>
                <Text style={styles.subTitle}>{doc_key}</Text>
              </View>
              {documentsList[doc_key].length
                ? documentsList[doc_key].map((doc, i) => {
                    return (
                      <View>
                        <Text style={styles.valueLi}>
                          {i + 1}) {doc.name}
                        </Text>
                      </View>
                    );
                  })
                : null}
            </View>
          );
        })}
        <View>
          <Text style={styles.subTitle}>B. Core Booklet</Text>
        </View>

        {coreEvidence && coreEvidence.length
          ? coreEvidence.map((v, i) => {
              return (
                <View>
                  <Text style={styles.valueLi}>
                    {i + 1}) {v.label}
                  </Text>
                </View>
              );
            })
          : null}

        <View>
          <Text style={styles.subTitle}>
            {abc[documents_index + 1]}. Inter Partes Correspondence
          </Text>
        </View>
        <View>
          <Text style={styles.valueLi}>
            Separate booklets of without prejudice and open correspondence
            should be prepared.
          </Text>
        </View>

        <View>
          <Text style={styles.title}>Booklet of Authorities</Text>
        </View>
        <View>
          <Text style={styles.value}>
            <Text style={styles.valueBold}>{copiesSecond}</Text> copie
            {addS(copiesSecond)} of the Booklets of Authorities should be
            available 7 days in advance of trial arranged in the following
            format:
          </Text>
        </View>

        {Object.keys(authorities).length
          ? Object.keys(authorities).map((v, i) => {
              return (
                <View>
                  <Text style={styles.valueLi}>
                    {abc[i]}. {v ?? "Without type"}
                  </Text>
                  {authorities[v].map((aut) => {
                    total_index++;
                    return (
                      <View>
                        <Text style={styles.valueLi}>
                          {total_index}. {aut}
                        </Text>
                      </View>
                    );
                  })}
                </View>
              );
            })
          : null}

        <View>
          <Text style={styles.title}>Pre-Trial Steps</Text>
        </View>
        <View>
          <Text style={styles.value}>
            The following steps should be taken as soon as possible in advance
            of trial:
          </Text>
        </View>
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.pre_trial
              ? caseObject.advice.pre_trial
              : ""}
          </Text>
        </View>

        <View>
          <Text style={styles.title}>Consultation</Text>
        </View>
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.consultation
              ? caseObject.advice.consultation
              : ""}
          </Text>
        </View>

        <View>
          <Text style={styles.title}>Brief</Text>
        </View>
        <View>
          <Text style={styles.value}>
            {caseObject.advice && caseObject.advice.brief
              ? caseObject.advice.brief
              : "Brief one Junior Counsel with Documents referred to in this Advice on Proofs, and a copy of this Advice on Proofs and prior advices of Counsel."}
          </Text>
        </View>
        <View>
          <Text style={[styles.value, { textAlign: "right" }]}>
            Date: {moment(new Date()).format("DD-MM-yyyy")}
          </Text>
        </View>
        <View>
          <Text style={[styles.value, { textAlign: "right" }]}>
            {user.name}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.body} wrap>
        <View>
          <Text style={[styles.value, { textAlign: "center" }]}>
            Appendix: Chronology
          </Text>
        </View>
        <View style={styles.table_}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {data.headers.map((v) => {
                return (
                  <View style={[styles.tableCol, { width: v.width }]}>
                    <Text
                      style={[
                        styles.tableCell,
                        { textAlign: "center", marginLeft: 0 },
                      ]}
                    >
                      {v.label}
                    </Text>
                  </View>
                );
              })}
            </View>
            {data.rows.map((row) => {
              return (
                <View style={styles.tableRow}>
                  {data.headers.map((header_) => {
                    let content;
                    if (!row[header_.name]) {
                      content = <Text style={styles.tableCell} />;
                    } else {
                      if (typeof row[header_.name] !== "object") {
                        content = (
                          <Text style={styles.tableCell}>
                            {row[header_.name]}
                          </Text>
                        );
                      } else {
                        content = [];
                        let as_row = false;
                        row[header_.name].forEach((el, index) => {
                          if (typeof el === "object") {
                            content.push(
                              <View>
                                <View>
                                  <Text style={styles.tableCell}>
                                    {el.name}
                                  </Text>
                                </View>
                                {el.value.map((lines, i) => {
                                  return (
                                    <View>
                                      <Text
                                        style={[
                                          styles.tableCell,
                                          { marginLeft: 10 },
                                        ]}
                                      >
                                        {i + 1}.{lines}
                                      </Text>
                                    </View>
                                  );
                                })}
                              </View>
                            );
                          } else {
                            as_row = true;
                            content.push(el);
                          }
                        });
                        if (as_row) {
                          content = (
                            <Text style={styles.tableCell}>
                              {content.join(", ")}
                            </Text>
                          );
                        }
                      }
                    }
                    return (
                      <View style={[styles.tableCol, { width: header_.width }]}>
                        {content}
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
