import { Extension, PasteRule } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { markPasteRule } from "@tiptap/core";

const pasteRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))/g;

const SubstituteTextExtension = () =>
  Extension.create({
    name: "substituteExtension",

    addProseMirrorPlugins() {
      return [
        new Plugin({
          key: new PluginKey("substituteExtension"),

          filterTransaction(tr, state) {
            // console.log(this);
            // console.log(tr);
            // console.log(tr.meta);
            // if (tr.meta["replace-paste-tag"]) {
            //   console.log(tr.meta["replace-paste-tag"]);
            //   return;
            // }
            // return true;
          },
          appendTransaction(tr, oldState, newState) {
            // console.log("tr");
            // console.log(tr);
            // console.log(oldState);
            // console.log(newState);
            // console.log(newState.isActive("tag-creation"));
          },
          props: {
            // handlePaste(view, e) {
            //   console.log("PPPPPPPPASSSTEEE");
            //   console.log(e);
            //   console.log(view);
            //   const { state, dispatch } = view;
            //   const transaction = view.state.tr.setMeta(
            //     "replace-paste-tag",
            //     `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
            //     // state.selection.from,
            //     // state.selection.to
            //   );
            //   view.dispatch(transaction);
            //   return true;
            //   return false;
            // },
          },
        }),
      ];
    },

    // addPasteRules() {
    //   return [
    //     new PasteRule({
    //       find: "yo",
    //       handler: ({ state, range, match }) => {
    //         console.log("here maybe");
    //         const { tr } = state;

    //         tr.replaceWith(range.from, range.to, "ey");
    //       },
    //     }),
    //     new PasteRule({
    //       find: "abc",
    //       handler: ({ state, range, match }) => {
    //         console.log("abs");
    //         const { tr } = state;

    //         tr.replaceWith(range.from, range.to, "zxc");
    //       },
    //     }),
    //   ];
    // },

    addStorage() {
      return {
        awesomeness: 100,
      };
    },

    onUpdate() {
      this.storage.awesomeness += 1;
    },
  });

export default SubstituteTextExtension;
