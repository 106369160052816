import styled from "styled-components/macro";

const SideBarItemStyled = styled.div`
  border-radius: ${({first, last}) => first ? '4px 4px 0 0' : last ? '0 0 4px 4px' : 0};
  border: 1px solid #dcdce6;
  background-color: #ffffff;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  border-left: ${({active, theme}) => active ? `3px solid ${theme.colors.blue}` : 'none'};
  cursor: pointer;
`;

export default SideBarItemStyled;
