import { mergeAttributes, Node } from "@tiptap/core";
import { PluginKey } from "prosemirror-state";
import { ReactNodeViewRenderer } from "@tiptap/react";
import TagCreationComponent from "@editor/components/TagCreation";

export const TagCreationPluginKey = new PluginKey("tag-creation");

export const TagCreation = Node.create({
  name: "creation",

  group: "inline",
  // selectable: true,
  draggable: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            "data-id": attributes.id,
          };
        },
      },
      tagType: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagType"),
        renderHTML: (attributes) => {
          if (!attributes.tagType) {
            return {};
          }
          return {
            "data-tagType": attributes.tagType,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }
          return {
            "data-label": attributes.label,
          };
        },
      },
      tagId: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagId"),
        renderHTML: (attributes) => {
          if (!attributes.tagId) {
            return {};
          }
          return {
            "data-tagId": attributes.tagId,
          };
        },
      },
    };
  },
  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  parseHTML() {
    return [
      {
        tag: "creation",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["creation", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TagCreationComponent);
  },
});

export default TagCreation;
