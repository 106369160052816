import React from "react";
import Item from "./Item";

import moment from "moment";
import { Bold14Font, Medium14Font } from "@/components/FontsNewComponent/Fonts";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import Table from "@/components/Table/Table";

import LeftSideBarUserMenu from "@/components/baseAppComponents/LeftSideBarUserLayout/LeftSideBarUserMenu";

import { TagElementCaseType } from "@/components/TagElementStyled/TagElementStyled";

const ListView = ({ notebooks, editNotebook, deleteNotebook }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <Table
      // selectedId={selectedTopic ? selectedTopic.id : null}
      data={notebooks}
      settings={{
        actions: [
          {
            type: "options",
            popupComponent: (
              <LeftSideBarUserMenu
                menuItems={[
                  {
                    id: 1,
                    title: "View",
                    onClickHandler: (_, id) => {
                      history.push({
                        pathname: `${url}/${id}`,
                      });
                    },
                  },
                  {
                    id: 2,
                    title: "Edit",
                    onClickHandler: (_, id) => {
                      editNotebook(id);
                    },
                  },
                  {
                    id: 3,
                    title: "Delete",
                    onClickHandler: (_, id) => {
                      deleteNotebook(id);
                    },
                  },
                ]}
                style={{
                  width: "150px",
                  height: "147px",
                  left: "133px",
                  bottom: "14px",
                }}
              />
            ),
            callback: (object, event, state, setState, rowIndex) => {
              // show or hide popup menu on appropriate row
              if (state === rowIndex) {
                setState(null);
              } else {
                setState(rowIndex);
              }
            },
          },
        ],
        fields: [
          {
            name: "name",
            label: "Name",
            renderer: (object) => {
              return <Medium14Font>{object.name}</Medium14Font>;
            },
          },
          {
            name: "type",
            label: "Notebook type",
            renderer: (object) => {
              return (
                <TagElementCaseType
                  overflow
                  style={
                    object.type === "Application"
                      ? {
                          background: "#FF901710",
                          color: "#FF9017",
                          borderColor: "#FF901720",
                        }
                      : object.type === "Custom"
                      ? {
                          background: "#374AC210",
                          color: "#374AC2",
                          borderColor: "#374AC220",
                        }
                      : object.type === "Trial"
                      ? {
                          background: "#259B9A10",
                          color: "#259B9A",
                          borderColor: "#259B9A20",
                        }
                      : {}
                  }
                >
                  <Bold14Font style={{ color: "inherit" }}>
                    {object.type}
                  </Bold14Font>
                </TagElementCaseType>
              );
            },
          },
          {
            name: "owner",
            label: "Notebook owner",
            renderer: (object) => {
              return (
                <Medium14Font>
                  {object.user && object.user.name ? object.user.name : "N/A"}
                </Medium14Font>
              );
              // return <Medium14Font>{object.user_id}</Medium14Font>;
            },
          },
          {
            name: "date_of_creation",
            label: "Date of creation",
            renderer: (object) => {
              return (
                <Medium14Font>
                  {moment(object.created_at).format("DD/MM/YY")}
                </Medium14Font>
              );
            },
          },
          {
            name: "date_of_last_update",
            label: "Date of last update",
            renderer: (object) => {
              return (
                <Medium14Font>
                  {moment(object.updated_at).format("DD/MM/YY")}
                </Medium14Font>
              );
            },
          },
        ],
        clickAction: (object) => {
          history.push({
            pathname: `${url}/${object.id}`,
          });
        },
        sortable: false,
      }}
      style={{ tableLayout: "fixed" }}
    />
  );
};

export default ListView;

// <div className="form-group">
//   <div className="container-fluid">
//     <Bold14Font>
//       {coa === 0
//         ? obj__.new_case_type === "Criminal"
//           ? "Offence"
//           : "Cause of Action"
//         : "Counterclaim"}
//     </Bold14Font>
//     {evidenceObject || obj__
//       ? obj__.causes_of_action
//         .filter((item) => item.counterclaim === coa)
//         .map((element, index) => (
//           <Fragment>
//             <div key={index} style={{marginBottom: "30px"}}>
//               <div style={{marginBottom: "8px"}}>
//                 <Checkbox
//                   checked={elementsParent.find(
//                     (el) => el.id === element.id && element.counterclaim === coa
//                   )}
//                   label={
//                     <Bold14Font>{element.type.name}</Bold14Font>
//                   }
//                   onChange={() =>
//                     onChangeCheckBoxHandler(
//                       element,
//                       elementsParent,
//                       setElementsParent
//                     )
//                   }
//                 />
//               </div>
//               {element.elements && element.elements.length ? (
//                 <div style={{marginLeft: "12px"}}>
//                   {element.elements.length ? (
//                     <Medium14Font>Elements</Medium14Font>
//                   ) : null}
//                   {element.elements.map((item, idx) => {
//                     return <Checkbox
//                       key={idx}
//                       checked={elements.find(
//                         (e) => e.id === item.id && e.counterclaim === coa
//                       )}
//                       label={item.name}
//                       onChange={() =>
//                         onChangeCheckBoxHandler(
//                           item,
//                           elements,
//                           setElements,
//                           element,
//                           elementsParent,
//                           setElementsParent,
//                           "coa",
//                           coa,
//                         )
//                       }
//                       style={{marginLeft: "12px"}}
//                     />
//                   })}
//                   {element.defence_elements.length ? (
//                     <Medium14Font>
//                       Defence Elements
//                     </Medium14Font>
//                   ) : null}
//                   {element.defence_elements.map((item, idx) => (
//                     <Checkbox
//                       key={idx}
//                       checked={defenceElements.find(
//                         (element) => element.id === item.id && element.counterclaim === coa
//                       )}
//                       label={item.name}
//                       onChange={() =>
//                         onChangeCheckBoxHandler(
//                           item,
//                           defenceElements,
//                           setDefenceElements,
//                           element,
//                           elementsParent,
//                           setElementsParent,
//                           "coa",
//                           coa,
//                         )
//                       }
//                       style={{marginLeft: "12px"}}
//                     />
//                   ))}
//                 </div>
//               ) : null}
//             </div>
//           </Fragment>
//         ))
//       : null}
//     <div className="d-flex justify-content-center">
//       <ButtonNew
//         tertiary
//         clickHandler={() =>
//           onClickAddCOAHandler(
//             coa === 0 ? {counterclaim: 0} : {counterclaim: 1}
//           )
//         }
//       >
//         Add{" "}
//         {coa === 0
//           ? obj__.new_case_type === "Criminal"
//             ? "Offence"
//             : "Cause of Action"
//           : "Counterclaim"}
//       </ButtonNew>
//     </div>
//   </div>
// </div>
