import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

//TODO: Add Icon separator and remove it from button

const BreadCrumbsStyled = styled.div`

`;

export default BreadCrumbsStyled;
