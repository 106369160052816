import styled from "styled-components/macro";
import backgroundImage from "../../../../assets/img/svg/cardButton.svg"

const DivCardStyled = styled.div`
  background: url(${backgroundImage});
  width: 100%;
  border-radius: 4px;
  padding: 15px 15px 15px 15px;
`;

export default DivCardStyled;
