import styled from "styled-components/macro";

const PlusStyled = styled.div`
  background: ${({theme}) => theme.colors.blue};
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 10px;
`;

export default PlusStyled;
