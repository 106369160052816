import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../components/FontsNewComponent/Fonts";
import Button from "../../../components/buttons/Button";
import { useHistory, useParams } from "react-router-dom";
import {
  addCase,
  deleteCase,
  deleteCaseMemberById,
  getCaseById,
  getCaseMembersById,
  getCasesTypes,
  sendTeamSizeRequest,
  updateCase,
} from "../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import modalErrorHandler, {
  handleErrorMessage,
} from "../../../components/Modal/modalErrorHandler";
import planFeaturesMarkIcon from "../../../assets/img3/svg/plan-features-mark-icon.svg";
import LiStyled from "../../../components/Table/Styled/LiStyled";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  MODAL_ERROR,
  SAVE_MODAL_DATA,
  SET_LAST_PAGE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import PartiesForm from "../../../components/forms/CasesForms/Parites";
import ThemesForm from "../../../components/forms/CasesForms/ThemesForm";
import IssuesForm from "../../../components/forms/CasesForms/IssuesForm";
import CauseOfActionForm from "../../../components/forms/CasesForms/CauseOfActionForm";
import { route } from "../../../routes";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import Form from "../../../components/forms/Form";
import InputNew from "../../../components/InputNew/InputNew";
import SelectAnotherTry from "../../../components/Select/SelectAnotherTry";
import Table from "../../../components/Table";
import TextAreaCases from "../../../components/TextArea/TextAreaCases";
import Arrow from "../../../components/buttons/Button/Arrow";
import Panel from "../../../components/Panel";
import ListAddButton from "../../../components/buttons/ListAddButton";
import { capitalizeFirstLetter } from "../../../utils/function";
import CaseTeamForm from "../../../components/forms/CasesForms/CaseTeamForm";
import { caseTeamStatus, closeBtnText } from "../../../utils/constants";
import { checkIfUserIsOwnerOfTheCase } from "../../Auth/utils";
import RadioButtonGroup from "../../../components/buttons/RadioButtonGroup";
const CRIMINAL = "Criminal";
const CIVIL = "Civil";
const caseTypesButtonsObjects = [
  {
    title: <Medium14Font>{CIVIL.toUpperCase()}</Medium14Font>,
    type: CIVIL,
  },
  {
    title: <Medium14Font>{CRIMINAL.toUpperCase()}</Medium14Font>,
    type: CRIMINAL,
  },
];
const EditCase = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [caseObject, setCaseObject] = useState({});
  const [label, setLabel] = useState("");
  const [firm_name, setFirmName] = useState("");
  const [record_no, setRecordNo] = useState("");
  const [legal_aid_no, setLegalAidNo] = useState("");
  const [case_theory, setCaseTheory] = useState("");
  const [casesTypes, setCasesTypes] = useState([]);
  const [emptyObject, setEmptyObject] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [selectedCaseType, setSelectedCaseType] = useState(null);
  const [submitObject, setSubmitObject] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [causesOfAction, setCausesOfAction] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [sendingR, setSendingR] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [teamMembersList, setTeamMembersList] = useState([]);
  let partiesTable = useRef(null);
  let { case_id } = useParams();
  const partiesSettings = {
    actions: [
      {
        type: "edit",
        callback: (object) => {
          handleParties(object);
        },
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              partiesChange(object, "delete");
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "PARTY NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "role",
        label: "ROLE",
        renderer: (object) => {
          return <Medium14Font>{object.role}</Medium14Font>;
        },
      },
      {
        name: "client",
        label: "CLIENT",
        renderer: (object) => {
          if (object.client) {
            return <img src={planFeaturesMarkIcon} alt={"yes"} />;
          } else {
            return null;
          }
        },
      },
    ],
  };
  const issuesSettings = {
    collapsed: true,
    actions: [
      {
        type: "edit",
        callback: (object) => {
          handleIssues(object);
        },
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              issuesChange(object, "delete");
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "ISSUES NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "key_facts",
        label: "KEY FACTS",
        renderer: (object) => {
          if (object.key_facts && object.key_facts.length) {
            return (
              <ul>
                {object.key_facts.map((fact) => {
                  return (
                    <LiStyled key={fact.id}>
                      <Medium14Font>{fact.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };
  const themesSettings = {
    collapsed: true,
    actions: [
      {
        type: "edit",
        callback: (object) => {
          handleTheme(object);
        },
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              themesChange(object, "delete");
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label: "THEME NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "sub_themes",
        label: "SUBTHEMES",
        renderer: (object) => {
          if (object.sub_themes && object.sub_themes.length) {
            return (
              <ul>
                {object.sub_themes.map((theme_) => {
                  return (
                    <LiStyled key={theme_.id}>
                      <Medium14Font>{theme_.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };
  const causeOfActionSettings = {
    collapsed: true,
    actions: [
      {
        type: "edit",
        callback: (object) => {
          handleCauseOfAction(object);
        },
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              causeOfActionChange(object, "delete");
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "name",
        label:
          caseObject.new_case_type === "Criminal"
            ? "OFFENCE"
            : "CAUSES OF ACTION",
        renderer: (object) => {
          return <Medium14Font>{object.type.name}</Medium14Font>;
        },
      },
      {
        name: "elements",
        label: "ELEMENTS",
        renderer: (object) => {
          if (object.elements && object.elements.length) {
            return (
              <ul>
                {object.elements.map((element) => {
                  return (
                    <LiStyled key={element.id}>
                      <Medium14Font>{element.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
      {
        name: "defence_elements",
        label: "DEFENCE ELEMENTS",
        renderer: (object) => {
          if (object.defence_elements && object.defence_elements.length) {
            return (
              <ul>
                {object.defence_elements.map((element) => {
                  return (
                    <LiStyled key={element.id}>
                      <Medium14Font>{element.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };
  const handleSubmit = async () => {
    if (!onSubmit) {
      setOnSubmit(true);
      let resp;
      try {
        if (!caseObject.id) {
          if (!caseObject.new_case_type) {
            handleErrorMessage("Select Case Type first", dispatch);
            setOnSubmit(false);
            return false;
          }
          resp = await addCase(dispatch, caseObject);
        } else {
          resp = await updateCase(caseObject.id, dispatch, caseObject);
        }
        setCaseObject(resp);
        dispatch({
          type: SET_LAST_PAGE,
          payload: {
            path: !is_admin
              ? `/app/cases/edit/${resp.id}`
              : `/admin/all-cases/edit/${resp.id}`,
            title: resp.label,
            activeMenuItem: CASES,
          },
        });
      } catch (e) {
        setOnSubmit(false);
        modalErrorHandler(e, dispatch);
      }
      setOnSubmit(false);
      return true;
    }
  };
  const submitOnEnter = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (caseObject.case_type && caseObject.case_type.id) {
      setSelectedCaseType(
        casesTypes.filter((val) => {
          return val.value === caseObject.case_type.id;
        })
      );
    }
    setLabel(caseObject.label);
    setRecordNo(caseObject.record_no);
    setLegalAidNo(caseObject.legal_aid_no);
    setCaseTheory(caseObject.case_theory);
    setFirmName(caseObject.firm_name);
    setCausesOfAction(caseObject.causes_of_action ?? []);
  }, [caseObject]);

  useEffect(() => {
    let setData = async () => {
      let types = await getCasesTypes(dispatch);
      setCasesTypes(types);
      if (case_id) {
        let caseResp = await getCaseById(case_id, dispatch);
        let caseMembersListResp = await getCaseMembersById(case_id);
        if (is_admin && !caseResp) {
          history.push("/admin/all-cases");
        }
        if (caseResp && caseMembersListResp) {
          setCaseObject(caseResp);
          caseResp.user.name = `${caseResp.user.name} (case owner)`;
          setTeamMembersList([
            caseResp.user,
            ...caseMembersListResp.map((el) => ({
              ...el,
              name: `${el.name}${
                el.status === caseTeamStatus.INVITED ? " (invited)" : ""
              }`,
            })),
          ]);
        } else {
          setEmptyObject(true);
        }
      } else {
        setCaseObject({
          issues: [],
          themes: [],
          causes_of_action: [],
          parties: [],
        });
      }
      setIsLoading(false);
    };
    setData();
  }, []);
  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  if (emptyObject) {
    return (
      <div className="h-100 flex-column justify-content-center align-items-center d-flex">
        <Bold30Font>You don't have access to that case</Bold30Font>
        <Button
          secondary
          clickHandler={() => {
            history.goBack();
          }}
        >
          Go back
        </Button>
      </div>
    );
  }

  const changeObj = (resp, type, obj) => {
    if (resp) {
      let caseObject_clone = JSON.parse(JSON.stringify(caseObject));
      if (type === "add") {
        caseObject_clone[obj].push(resp);
      } else if (type === "update") {
        caseObject_clone[obj].forEach((v, index) => {
          if (v.id === resp.id) {
            caseObject_clone[obj][index] = resp;
          }
        });
      } else if (type === "delete") {
        caseObject_clone[obj] = caseObject[obj].filter((v) => {
          return v.id !== resp.id;
        });
      }
      setCaseObject(caseObject_clone);
    }
  };

  const partiesChange = (resp, type) => {
    changeObj(resp, type, "parties");
  };

  const themesChange = (resp, type) => {
    changeObj(resp, type, "themes");
  };

  const issuesChange = (resp, type) => {
    changeObj(resp, type, "issues");
  };

  const causeOfActionChange = (resp, type) => {
    changeObj(resp, type, "causes_of_action");
  };

  const handleParties = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <PartiesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          caseObject={caseObject}
          afterSubmit={partiesChange}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Party",
    });
  };

  const handleIssues = (object) => {
    console.log(object);
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <IssuesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          afterSubmit={issuesChange}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add Issue",
    });
  };

  const handleTheme = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ThemesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          afterSubmit={themesChange}
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Theme",
    });
  };

  const handleCauseOfAction = (object, counterclaim) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <CauseOfActionForm
          caseObject={caseObject}
          key={object.id}
          id_case={caseObject.id}
          counterclaim={counterclaim}
          object={object}
          isCriminal={caseObject.new_case_type === "Criminal"}
          afterSubmit={causeOfActionChange}
          alreadySavedCOAList={causesOfAction}
        />
      ),
      title:
        object.type && object.type.name
          ? "Edit: " + object.type.name
          : counterclaim
          ? "Add Counterclaim"
          : caseObject.new_case_type === "Criminal"
          ? "Add Offence"
          : "Add Cause of action",
    });
  };

  const onCaseFieldChange = (event) => {
    let _c = caseObject;
    switch (event.target.name) {
      case "label":
        if (event.target.value.length <= 125) setLabel(event.target.value);
        break;
      case "firm_name":
        if (event.target.value.length <= 125) setFirmName(event.target.value);
        break;
      case "case_theory":
        if (event.target.value.length <= 300) setCaseTheory(event.target.value);
        break;
      case "record_no":
        if (event.target.value.length <= 125) setRecordNo(event.target.value);
        break;
      case "legal_aid_no":
        if (event.target.value.length <= 125) setLegalAidNo(event.target.value);
        break;
    }
    _c[event.target.name] = event.target.value;
    setCaseObject(_c);
  };

  const onCaseTypeChanged = (selectedOption) => {
    let _c = caseObject;
    _c.id_case_type = selectedOption.value;
    setCaseObject(_c);
  };

  const handleDelete = async (event) => {
    event.persist();
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteCase(case_id, dispatch);
        history.push(
          !is_admin ? route.baseApp.cases : route.admin.baseApp.cases
        );
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  let filtered_c_of_a = causesOfAction.filter((elem_) => {
    return elem_.counterclaim == false;
  });

  let filtered_c_of_a_counterclaim = causesOfAction.filter((elem_) => {
    return elem_.counterclaim == true;
  });

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: caseObject.id
        ? !is_admin
          ? `/app/cases/edit/${caseObject.id}`
          : `/admin/all-cases/edit/${caseObject.id}`
        : !is_admin
        ? `/app/cases/create/`
        : `/admin/all-cases/create`,
      title: `${caseObject.label || "New Case"}`,
      activeMenuItem: CASES,
    },
  ];

  const usersChange = (resp) => {
    setTeamMembersList((prevState) => [
      ...prevState,
      { ...resp, name: `${resp.name} (invited)` },
    ]);
  };

  const onClickAddTeamMemberHandler = async () => {
    const caseResp = await getCaseById(caseObject.id, dispatch);
    if (caseResp.max_users > teamMembersList.length) {
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <CaseTeamForm
            key={caseResp.id}
            id_case={caseResp.id}
            object={caseResp}
            afterSubmit={usersChange}
          />
        ),
        title: "Add New Team Member",
      });
    } else {
      if (!sendingR) {
        setSendingR(true);
        const resp = await sendTeamSizeRequest(case_id);
        dispatch({
          type: SAVE_MODAL_DATA,
          payload: `You have reached the maximum allowed team size of ${caseResp.max_users} users. Admin was notified and will review your request to increase team size limit.`,
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR });
        setSendingR(false);
      }
    }
  };

  /**
   * Delete user from case team table view
   * @param object: case team object
   */
  const onClickDeleteTeamMemberHandler = async (object) => {
    const resp = await deleteCaseMemberById(object.id);
    if (resp) {
      setTeamMembersList((prevState) =>
        prevState.filter((el) => el.id !== object.id)
      );
    }
  };

  const caseTeamSettings = {
    actionShowCondition: (object, index) => {
      return index !== 0;
    },
    actions:
      caseObject.user && caseObject.user.id === user.id
        ? [
            {
              type: "delete",
              callback: (object) => {
                dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                if (object.id === user.id) {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "You can't delete yourself from case team",
                  });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR });
                } else {
                  dispatch({
                    type: SAVE_MODAL_DATA,
                    payload: "The team member will lose access to this case.",
                    beforeCloseHandler: async () => {
                      onClickDeleteTeamMemberHandler(object);
                    },
                  });
                  dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
                }
              },
            },
          ]
        : [],
    fields: [
      {
        name: "name",
        label: "NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "role",
        label: "ROLE",
        renderer: (object) => {
          return (
            <Medium14Font>{capitalizeFirstLetter(object.role)}</Medium14Font>
          );
        },
      },
      {
        name: "email",
        label: "EMAIL",
        renderer: (object) => {
          return <Medium14Font>{object.email}</Medium14Font>;
        },
      },
    ],
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <Bold30Font>{caseObject.label ?? "New Case"}</Bold30Font>
        </div>
        <div className="col-12 col-sm-6 d-flex justify-content-sm-end align-items-center">
          {is_admin || !checkIfUserIsOwnerOfTheCase(caseObject) ? null : (
            <Button
              clickHandler={handleDelete}
              loading={onDelete}
              disabled={onDelete}
              style={{ marginRight: "20px" }}
              secondary
              delete_
            >
              Delete
            </Button>
          )}
          <ButtonNew
            clickHandler={() => {
              handleSubmit().then((resp) => {
                if (resp) {
                  if (history.action === "PUSH") {
                    return history.goBack();
                  } else {
                    history.push(
                      !is_admin
                        ? route.baseApp.cases
                        : route.admin.baseApp.allCases
                    );
                  }
                }
              });
            }}
            disabled={onSubmit}
            loading={onSubmit}
            type={"submit"}
            primary
          >
            {closeBtnText}
          </ButtonNew>
        </div>
      </div>
      <Panel
        onChange={(index) => {
          setActiveItem(index);
        }}
        activeItem={activeItem}
        items={
          !caseObject.new_case_type
            ? ["Case Info"]
            : case_id
            ? ["Case Info", "Case Components", "Case Team"]
            : ["Case Info", "Case Components"]
        }
      />
      {activeItem === 0 ? (
        <Fragment>
          <form onSubmit={submitOnEnter}>
            <div className="row">
              <Form title={"Case Info"} style={{ overflow: "unset" }}>
                <div style={{ padding: "0 15px" }}>
                  <div className="form-group">
                    <RadioButtonGroup
                      label="Case Type"
                      disabled={caseObject.id}
                      itemsObjects={caseTypesButtonsObjects}
                      checkedButton={caseObject.new_case_type}
                      setCheckedButton={(type) => {
                        if (!caseObject.id) {
                          setCaseObject({
                            ...caseObject,
                            new_case_type: type,
                            causes_of_action: [],
                            parties: [],
                          });
                        }
                      }}
                    />
                  </div>
                  {caseObject.new_case_type ? (
                    <Fragment>
                      <div className="form-group">
                        <InputNew
                          name="label"
                          type="text"
                          value={label}
                          placeholder="Enter case name"
                          label="Case Name"
                          onChange={onCaseFieldChange}
                          maxLength={125}
                        />
                      </div>
                      <div className="form-group">
                        <InputNew
                          name="firm_name"
                          value={firm_name}
                          type="text"
                          placeholder="Enter firm name"
                          label="Firm name"
                          onChangeHandler={onCaseFieldChange}
                          maxLength={125}
                        />
                      </div>
                      <div className="form-group">
                        <div
                          // style={{ marginLeft: "15px", width: "calc(50% - 15px)" }}
                          style={{ marginLeft: "15px" }}
                        >
                          <Bold14Font className="d-block mb-2">
                            Court type
                          </Bold14Font>
                          <SelectAnotherTry
                            defaultValue={selectedCaseType}
                            options={casesTypes}
                            onChange={onCaseTypeChanged}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-lg-6">
                          <InputNew
                            name="record_no"
                            // className={"w-50"}
                            value={record_no}
                            type="text"
                            placeholder="Enter record №"
                            label="Record №"
                            onChangeHandler={onCaseFieldChange}
                            maxLength={125}
                          />
                        </div>
                      </div>
                      {caseObject.new_case_type === "Criminal" ? (
                        <div className="form-group row">
                          <div className="col-12 col-lg-6">
                            <InputNew
                              name="legal_aid_no"
                              value={legal_aid_no}
                              type="text"
                              placeholder="Enter legal aid №"
                              label="Legal Aid №"
                              onChangeHandler={onCaseFieldChange}
                              maxLength={125}
                            />
                          </div>
                        </div>
                      ) : null}
                    </Fragment>
                  ) : null}
                </div>
                <button style={{ display: "none" }} />
              </Form>
            </div>
            {caseObject.new_case_type ? (
              <Fragment>
                <Table
                  getRef={(ref) => {
                    partiesTable = ref;
                  }}
                  settings={partiesSettings}
                  data={caseObject.parties}
                  addButtonLabel={"Add New Party"}
                  title={"Parties"}
                  addButtonFunction={() => {
                    handleParties({});
                  }}
                />
                <div className="row mb-3">
                  <Form
                    style={{ marginBottom: 0, paddingBottom: 0 }}
                    headerStyle={{ marginBottom: 0 }}
                    title={"Case Summary"}
                  >
                    <TextAreaCases
                      value={case_theory}
                      onChange={onCaseFieldChange}
                      placeholder={
                        "Insert overview of how you will present your client’s perspective on the case"
                      }
                      name={"case_theory"}
                    />
                  </Form>
                </div>
              </Fragment>
            ) : null}
          </form>
          {caseObject.new_case_type ? (
            <div className="row mb-5 justify-content-end">
              <Button
                withIcon
                secondary
                clickHandler={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setActiveItem(1);
                }}
              >
                Case Components <Arrow className="ml-1" />
              </Button>
            </div>
          ) : null}
        </Fragment>
      ) : null}
      {activeItem === 1 ? (
        <Fragment>
          <Medium18Font as="p">
            Choose how to plan your case from the following options. Choose one
            or more{" "}
            {caseObject.new_case_type === "Criminal"
              ? "offence"
              : "causes of action"}
            , issues or create your own custom themes:
          </Medium18Font>

          <Table
            settings={causeOfActionSettings}
            data={filtered_c_of_a}
            addButtonLabel={
              caseObject.new_case_type === "Criminal"
                ? "Add Offence"
                : "Add Cause of Action"
            }
            title={
              caseObject.new_case_type === "Criminal"
                ? "Offences"
                : "Cause of Action"
            }
            customButton={
              <ListAddButton
                label={"Add Counterclaim"}
                clickHandler={() => {
                  handleCauseOfAction({ counterclaim: true }, true);
                }}
              />
            }
            addButtonFunction={() => {
              handleCauseOfAction({ counterclaim: false }, false);
            }}
          />
          {filtered_c_of_a_counterclaim &&
          filtered_c_of_a_counterclaim.length ? (
            <Table
              settings={causeOfActionSettings}
              data={filtered_c_of_a_counterclaim}
              addButtonLabel="Add Counterclaim"
              title="Counterclaim"
              hideTitle={!filtered_c_of_a_counterclaim.length}
              // addButtonFunction={() => {
              //   handleCauseOfAction({ counterclaim: true }, true);
              // }}
            />
          ) : null}
          <Table
            settings={themesSettings}
            data={caseObject.themes}
            addButtonLabel="Add Theme"
            title={"Custom Themes"}
            addButtonFunction={() => {
              handleTheme({});
            }}
          />
          <Table
            settings={issuesSettings}
            data={caseObject.issues}
            addButtonLabel="Add Issue"
            title={"Issues"}
            addButtonFunction={() => {
              handleIssues({});
            }}
          />
          <div className="row mb-5 justify-content-end">
            <Button
              className="mr-3"
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setActiveItem(0);
              }}
            >
              <Arrow back={true} className="mr-1" />
              Case Info
            </Button>
            {caseObject.id ? (
              <Button
                secondary
                clickHandler={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setActiveItem(2);
                }}
              >
                Case Team
                <Arrow className="ml-1" />
              </Button>
            ) : null}
          </div>
        </Fragment>
      ) : null}
      {activeItem === 2 ? (
        <Fragment>
          <Table
            settings={caseTeamSettings}
            data={teamMembersList}
            title={"Team"}
            addButtonLabel="Add New Member"
            // if user owner of case - show add button - else - hide it
            addButtonFunction={
              caseObject.user.id === user.id
                ? onClickAddTeamMemberHandler
                : false
            }
          />
          <div className="row mb-5 justify-content-end">
            <Button
              className="mr-3"
              withIcon
              secondary
              clickHandler={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setActiveItem(1);
              }}
            >
              <Arrow back={true} className="mr-1" />
              Case Components
            </Button>
            <Button
              secondary
              clickHandler={() => {
                history.push(
                  !is_admin ? route.baseApp.cases : route.admin.baseApp.allCases
                );
              }}
            >
              Return to cases
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
export default EditCase;
