import React, { Fragment, useRef, useState } from "react";
import InputNew from "../../InputNew/InputNew";
import ButtonNew from "../../buttons/Button/ButtonNew";
import deleteObjectiveIcon from "../../../assets/img3/svg/delete-objective-input-icon.png";
import Button from "../../buttons/Button/Button";
import { updateEvidence } from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import Label from "../AddCardForm/Label";
import ListAddButton from "../../buttons/ListAddButton";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

const ObjectivesForm = ({
  evidenceObject,
  caseId,
  setEvidenceObject,
}) => {
  const [evidenceLabel, setEvidenceLabel] = useState(evidenceObject.label);
  const [objectivesList, setObjectivesList] = useState(
    evidenceObject.objectives ?? [{ name: "" }]
  );
  // const [submitObject, setSubmitObject] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const dispatch = useDispatch();
  let editableStringsRef = useRef([]);

  const onChangeLabelHandler = (event) => {
    setEvidenceLabel(event.target.value);
  };

  // add new objective input
  const addObjectiveHandler = (event) => {
    event.preventDefault();
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      setObjectivesList((prevState) => [...prevState, { name: "" }]);
    }
  };

  // delete objective input
  const deleteObjectiveHandler = (event, index) => {
    event.preventDefault();
    const objectivesListCopy = [...objectivesList];
    objectivesListCopy.splice(index, 1);

    setObjectivesList([...objectivesListCopy]);
  };

  const onObjectiveChangeHandler = (event, index) => {
    event.preventDefault();
    const objectiveListCopy = [...objectivesList];
    objectiveListCopy[index].name = event.target.value;
    setObjectivesList(objectiveListCopy);
  };

  const onBlurObjectiveFieldsHandler = (event, index) => {
    if (index !== 0 && event.target.value === "") {
      setObjectivesList((prevState) => {
        const tempArray = [...prevState];
        tempArray.splice(index, 1);
        return [...tempArray];
      });
    }
  };

  const handleSubmit = async () => {
    if (!onSubmit) {
      setOnSubmit(true);
      const submitObject = {
        label: evidenceLabel,
        objectives: objectivesList.filter((objective) => objective.name !== ""),
      };
      const resp = await updateEvidence(
        evidenceObject.fake_id ?? evidenceObject.id,
        caseId,
        dispatch,
        submitObject
      );
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        setEvidenceObject(resp);
      }
    }
  };

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  const editObjective = (index, objectiveText) => {
    const objectivesListCopy = [...objectivesList];
    objectivesListCopy[index].name = objectiveText;
    setObjectivesList(objectivesListCopy);
  };

  const deleteObjective = (index) => {
    setObjectivesList(objectivesList.filter((objective, idx) => index !== idx));
  };

  return (
    <Fragment>
      <div className="form-group" style={{ marginBottom: "8px" }}>
        <InputNew
          placeholder="Enter Label"
          name="label"
          label={"Evidence"}
          value={evidenceLabel}
          onChange={onChangeLabelHandler}
        />
      </div>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {objectivesList && objectivesList.length ? (
          <Label name="Objectives" />
        ) : null}
        {objectivesList.map((objective, index) => {
          return (
            <AnimatedDiv key={index} className="form-group pl-3">
              <EditableStringClassComponent
                editName={editObjective}
                editState={objective.name === ""}
                deleteAction={deleteObjective}
                object={objective.name}
                index={index}
                ref={(ref) => assignRef(ref, index)}
              />
            </AnimatedDiv>
          );
        })}
        <div className="d-flex justify-content-center align-items-center mb-2">
          <ListAddButton
            label="Add Objectives"
            clickHandler={addObjectiveHandler}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                // type="submit"
                // loading={isLoading}
                // disabled={isLoading}
                wide
                primary
                clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ObjectivesForm;
