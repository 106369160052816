import React from "react";
import styled from "styled-components/macro";
const IconDiv = styled.div`
  width: 18px;
  height: 18px;
`;
const Sorting = (props) => (
  <IconDiv>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={{ marginLeft: "5px", cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          opacity={!props.direction || props.direction === "desc" ? 0.6 : 1}
          d="M3.27283 7.36356H14.7272C14.9488 7.36356 15.1406 7.28265 15.3025 7.12069C15.4642 6.95878 15.5455 6.76705 15.5455 6.54545C15.5455 6.32385 15.4643 6.13221 15.3025 5.97007L9.57527 0.242867C9.41345 0.0811347 9.22176 0 9.00003 0C8.7783 0 8.58661 0.0811347 8.42465 0.242867L2.69746 5.97007C2.5355 6.13203 2.45459 6.32385 2.45459 6.54545C2.45459 6.767 2.5355 6.95878 2.69746 7.12069C2.85959 7.28265 3.05128 7.36356 3.27283 7.36356Z"
          fill={
            !props.direction
              ? "rgba(15, 18, 47, 0.6)"
              : props.direction && props.direction === "desc"
              ? "#CBCFEE"
              : "#374AC2"
          }
        />
        <path
          opacity={!props.direction || props.direction === "asc" ? 0.6 : 1}
          d="M14.7272 10.6367H3.27283C3.0511 10.6367 2.85941 10.7177 2.69746 10.8795C2.5355 11.0414 2.45459 11.2331 2.45459 11.4547C2.45459 11.6763 2.5355 11.8681 2.69746 12.03L8.42465 17.7571C8.58679 17.9191 8.77847 18.0001 9.00003 18.0001C9.22158 18.0001 9.41345 17.9191 9.57527 17.7571L15.3025 12.0299C15.4642 11.8681 15.5455 11.6763 15.5455 11.4546C15.5455 11.2331 15.4643 11.0414 15.3025 10.8794C15.1406 10.7175 14.9488 10.6367 14.7272 10.6367Z"
          fill={
            !props.direction
              ? "rgba(15, 18, 47, 0.6)"
              : props.direction && props.direction === "asc"
              ? "#CBCFEE"
              : "#374AC2"
          }
        />
      </g>
    </svg>
  </IconDiv>
);
export default Sorting;
