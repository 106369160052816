import React, { useEffect, useState, useRef, useContext } from "react";
import { EditorContext } from "@/context";
import { listenForOutsideClick } from "@/hooks/onClickOutside";
import {
  ConnectionMenu,
  ConnectionObject,
  ConnectTagType,
  ConnectTagName,
  ConnectedToLabel,
} from "./styled";
import { Tag } from "antd";

import ctl from "@/assets/img/notebook/connection-top-left.svg";
import cbl from "@/assets/img/notebook/connection-bottom-left.svg";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default ({ connectedElement, tag, hide }) => {
  const [listening, setListening] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    function checkUserData() {
      if (
        window.localStorage.getItem("lastConnectTag") &&
        JSON.parse(window.localStorage.getItem("lastConnectTag")) === null
      ) {
        setDisconnected(true);
        // hide();
      }
    }

    window.addEventListener("storage", checkUserData);

    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  const { draggingItem } = useContext(EditorContext);

  // useEffect(() => {
  //   if (draggingItem) {
  //     hide();
  //     // console.log("hide");
  //   }
  // }, [draggingItem]);
  // console.log(connectedElement);

  useEffect(
    listenForOutsideClick(
      listening,
      setListening,
      menuRef,
      (e) => {
        hide();
      },
      "mousedown"
    )
  );

  return (
    <ConnectionMenu ref={menuRef}>
      {disconnected ? (
        <Tag
          color="red"
          style={{
            alignSelf: "center",
            marginBottom: 8,
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          Disconnected
        </Tag>
      ) : (
        <Tag
          color="blue"
          style={{
            alignSelf: "center",
            marginBottom: 8,
            marginLeft: 8,
            marginRight: 8,
          }}
        >
          Succesfully connected
        </Tag>
      )}
      <ConnectionObject>
        <img src={ctl} alt="" style={{ maxWidth: 24, marginRight: 4 }} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ConnectTagType>
            {objects[connectedElement.type || connectedElement.tagType] ||
              capitalize(connectedElement.type || connectedElement.tagType)}
          </ConnectTagType>
          <ConnectTagName
            className={`color--${(
              connectedElement.type || connectedElement.tagType
            ).toLowerCase()}`}
          >
            {connectedElement.label || connectedElement.id}
          </ConnectTagName>
        </div>
      </ConnectionObject>
      <ConnectedToLabel>Connected to:</ConnectedToLabel>
      <ConnectionObject>
        <img src={cbl} alt="" style={{ maxWidth: 24, marginRight: 4 }} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ConnectTagType>
            {objects[tag.type || tag.tagType] ||
              capitalize(tag.type || tag.tagType)}
          </ConnectTagType>
          <ConnectTagName
            className={`color--${(tag.type || tag.tagType).toLowerCase()}`}
          >
            {tag.id}
          </ConnectTagName>
        </div>
      </ConnectionObject>
    </ConnectionMenu>
  );
};

const objects = {
  party: "Party",
  evidence: "Evidence",
  witness: "Witness",
  authority: "Authority",
  topic: "Topic",
  theme: "Theme",
  subtheme: "Subtheme",
  issue: "Issue",
  keyfact: "Keyfact",
  causesofaction: "Cause of action",
  causesofactionelement: "Element",
  causesofactiondefenseelement: "Defence element",
  CausesOfAction: "Cause of action",
  CausesOfActionElement: "Element",
  CausesOfActionDefenseElement: "Defence element",
};
