import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox/Checkbox";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { PulseLoader } from "react-spinners";
import {
  createNewRelief,
  deleteCauseOfAction,
  deleteReliefById,
  getReliefsList,
  updateApplicationById,
  updateReliefById,
} from "../../../containers/Auth/auth";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import InputNew from "../../InputNew/InputNew";
import ButtonAnotherTry from "../../buttons/Button/ButtonAnotherTry";
import LiStyled from "../../Table/Styled/LiStyled";

const ReliefsAndProofsCustom = ({
  object,
  setObject,
  sendData,
  routeParams,
  alreadySavedReliefs,
  alreadySavedProofs,
  actionType,
  theme,
}) => {
  //
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { caseId, applicationId } = routeParams;
  const currentUserId = useSelector((state) => state.auth.user.id);
  const caseObject = useSelector((state) => state.data.store.case);
  const dispatch = useDispatch();
  const form = useRef(null);
  const editableStringsRef = useRef([]);
  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  // panel elements states

  const [proofsCustomList, setProofsCustomList] = useState([]);
  const [customReliefName, setCustomReliefName] = useState("");

  // save data from response object to state
  const saveData = (reliefsList) => {};

  const saveProofs = (list) => {};

  // If true - than this proof created by current user and its custom
  const checkIfProofIsCustom = (proof) => proof.id_user === caseObject.user.id;

  const onChangeCustomProofNameHandler = (idx, value) => {
    setProofsCustomList((prevState) => {
      const proofsCustomListCopy = [...prevState];
      const newProofObject = {
        ...prevState[idx],
        name: value,
      };
      proofsCustomListCopy.splice(idx, 1, newProofObject);
      return proofsCustomListCopy;
    });
  };

  const onChangeCustomReliefNameHandler = (event) => {
    if (event.target.value.length <= 125)
      setCustomReliefName(event.target.value);
  };

  const onClickAddCustomProofHandler = () => {
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      setProofsCustomList((prevState) => [
        ...prevState,
        { id: `new_${Math.random()}`, name: "", id_user: caseObject.user.id },
      ]);
    }
  };

  const onClickDeleteCustomProofHandler = async (index) => {
    const proofsCustomListCopy = [...proofsCustomList];
    proofsCustomListCopy.splice(index, 1);
    setProofsCustomList(proofsCustomListCopy);
  };

  const onClickCreateReliefAndSaveToApplicationHandler = async (event) => {
    event.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      const submitObjectToRelief = {
        id: `new_${Math.random()}`,
        name: customReliefName,
        id_user: caseObject.user.id,
        proofs: [...proofsCustomList],
      };
      const submitObjectToApplication = {
        reliefs: [...alreadySavedReliefs, submitObjectToRelief],
        proofs: [...alreadySavedProofs, ...submitObjectToRelief.proofs],
      };
      setObject(submitObjectToApplication);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <AnimatedDiv style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            placeholder="Enter Custom Relief name"
            label="Custom Relief"
            name="name"
            value={customReliefName}
            onChangeHandler={onChangeCustomReliefNameHandler}
            form="form-reliefs-and-proofs"
            maxLength={125}
            required
          />
        </div>
        {proofsCustomList.length ? (
          <AnimatedDiv className="form-group">
            <div className="container-fluid">
              <Bold14Font>Custom Proofs</Bold14Font>
              {proofsCustomList.map((el, index) => (
                <AnimatedDiv key={el.id} style={{ marginBottom: "4px" }}>
                  <EditableStringClassComponent
                    index={index}
                    object={proofsCustomList[index].name}
                    editState={el.name === ""}
                    editName={(idx, value) =>
                      onChangeCustomProofNameHandler(idx, value)
                    }
                    deleteAction={() => onClickDeleteCustomProofHandler(index)}
                    // doneEditingCallback={onClickDoneEditingStringHandler}
                    ref={(ref) => assignRef(ref, index)}
                  />
                </AnimatedDiv>
              ))}
            </div>
          </AnimatedDiv>
        ) : null}
        {customReliefName !== "" ? (
          <div
            className="form-group d-flex justify-content-center"
            style={{ marginTop: "4px" }}
          >
            <ListAddButton
              className="d-flex"
              label="Add Custom Proof"
              clickHandler={onClickAddCustomProofHandler}
            />
          </div>
        ) : null}
      </AnimatedDiv>

      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <ButtonAnotherTry
                wide
                onClick={onClickCreateReliefAndSaveToApplicationHandler}
                loading={isSubmitting}
                disabled={isSubmitting || customReliefName === ""}
                primary
                type="submit"
                form="form-reliefs-and-proofs"
              >
                <Bold14Font style={{ color: theme.colors.white }}>
                  Save
                </Bold14Font>
              </ButtonAnotherTry>
            </div>
          </div>
        </div>
      </div>
      <form ref={form} id="form-reliefs-and-proofs" />
    </Fragment>
  );
};

ReliefsAndProofsCustom.propTypes = {
  object: PropTypes.object,
  setObject: PropTypes.func,
  sendData: PropTypes.func,
  routeParams: PropTypes.object,
  alreadySavedReliefs: PropTypes.array,
  alreadySavedProofs: PropTypes.array,
  actionType: PropTypes.oneOf(["create", "edit"]),
};

ReliefsAndProofsCustom.defaultProps = {
  actionType: "create",
};

export default withTheme(ReliefsAndProofsCustom);
