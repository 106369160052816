import React from "react";
import { withTheme } from "styled-components/macro";
import { BarLoader } from "react-spinners";

const FullScreenBarLoader = ({ theme }) => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <BarLoader color={theme.colors.blue} size={30} />
    </div>
  );
};

export default withTheme(FullScreenBarLoader);
