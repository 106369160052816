import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import {
  criminalPartyRoles,
  partyRoles,
  partyTypes,
} from "../../../containers/BaseApp/Cases/partyTypesAndRoles";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { updateApplicationById } from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";

export const groupPartiesByRoles = (partiesList = []) => {
  const uniquePartiesRoles = [];
  const groupedObject = {};
  partiesList.forEach((party) => {
    if (uniquePartiesRoles.indexOf(party.role) === -1) {
      uniquePartiesRoles.push(party.role);
    }
  });
  uniquePartiesRoles.forEach((role) => {
    groupedObject[role] = partiesList.filter((party) => party.role === role);
  });
  return groupedObject;
};

const RespondentSidebar = ({
  applicationObject,
  setApplicationObject,
  caseObject,
  routeParams,
  movingPartiesSelectedObject,
  theme,
}) => {
  // page states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { caseId, applicationId } = routeParams;
  const dispatch = useDispatch();

  // parties states
  const [respondentPartiesList, setRespondentPartiesList] = useState([]);
  const [
    groupedRespondentPartiesByRoleObject,
    setGroupedRespondentPartiesByRoleObject,
  ] = useState({});
  // const [selectedPartiesList, setSelectedPartiesList] = useState(
  //   applicationObject.respondent_parties
  // );
  const [selectedPartiesList, setSelectedPartiesList] = useState(
    applicationObject && applicationObject.respondent_parties
      ? applicationObject.respondent_parties
      : []
  );

  useEffect(() => {
    const roles = caseObject.new_case_type === 'Criminal' ? criminalPartyRoles : partyRoles;
    const respondentPartiesRolesList = roles
      .map((party) => party.value);
    const filteredRespondentPartiesFromCase = caseObject.parties.filter(
      (party) => {
        return (
          party.id !== movingPartiesSelectedObject.value &&
          respondentPartiesRolesList.indexOf(party.role) !== -1
        );
      }
    );
    setRespondentPartiesList(filteredRespondentPartiesFromCase);
    const groupedObject = groupPartiesByRoles(
      filteredRespondentPartiesFromCase
    );
    setGroupedRespondentPartiesByRoleObject(groupedObject);
  }, []);

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const onClickSaveRespondentPartiesHandler = async () => {
    setIsSubmitting(true);
    const submitObject = {
      respondent_parties: selectedPartiesList,
    };
    setApplicationObject(submitObject);
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    setIsSubmitting(false);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="container-fluid">
          {Object.keys(groupedRespondentPartiesByRoleObject).map(
            (role, index) => (
              <Fragment key={index}>
                <Bold14Font as="div" style={{ marginBottom: "8px" }}>
                  {role}
                </Bold14Font>
                <div style={{ marginBottom: "30px" }}>
                  {groupedRespondentPartiesByRoleObject[role].map((party) => (
                    <Checkbox
                      key={party.id}
                      checked={selectedPartiesList.find(
                        (el) => el.id === party.id
                      )}
                      label={party.name}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          party,
                          selectedPartiesList,
                          setSelectedPartiesList
                        )
                      }
                      labelColor={theme.colors.dark}
                    />
                  ))}
                </div>
              </Fragment>
            )
          )}
        </div>
      </div>

      <DividerHorizontal />
      <div className="form-group mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                loading={isSubmitting}
                disabled={isSubmitting}
                wide
                primary
                onClick={onClickSaveRespondentPartiesHandler}
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(RespondentSidebar);
