import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import RsbTabsMenu from "../../RSBTabsMenu";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox/Checkbox";
import {
  copyRelevanceFromEvidenceToWitnessesList,
  createNewWitness,
  getWitnessList,
  updateEvidence,
} from "../../../containers/Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_ERROR,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import InputNew from "../../InputNew/InputNew";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import moment from "moment";

const menuTabs = [{ title: "Primary" }, { title: "Secondary" }];

export const createWitnessListByRoles = (witnessList) => {
  const witnessListByRoles = {
    Unassigned: [],
  };
  const addToList = (witnessListByRoles, role, element) => {
    if (element.witness_type === "E-Exam") {
      element.topics.forEach((t) => {
        let el = { ...element };
        el.name = el.name + " - " + t.name;
        el.topic_id = t.id;
        el.real_id = `${el.real_id}_${t.id}`;
        witnessListByRoles[role].push(el);
      });
    } else {
      witnessListByRoles[role].push(element);
    }
  };
  witnessList.forEach((element) => {
    if (element.party) {
      if (Object.keys(witnessListByRoles).indexOf(element.party.role) === -1) {
        witnessListByRoles[element.party.role] = [];
        addToList(witnessListByRoles, element.party.role, element);
      } else {
        addToList(witnessListByRoles, element.party.role, element);
      }
    } else {
      addToList(witnessListByRoles, "Unassigned", element);
    }
  });

  return witnessListByRoles;
};

const setActiveMenuTabBasedOnWitnesses = (evidenceObject) => {
  if (
    evidenceObject &&
    evidenceObject.witnesses &&
    evidenceObject.witnesses.length
  ) {
    if (
      evidenceObject.witnesses.filter((witness) => witness.type === "Primary")
        .length
    ) {
      return 1;
    }
  }
  return 0;
};

const AssociateWitnessForm = ({
  evidenceObject,
  setEvidenceObject,
  selectedMenuTab,
  theme,
  caseId,
  caseObject,
  isDocPropertiesFlow,
  noCloseAfterSave,
  returnFunction,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  // const [activeMenuTab, setActiveMenuTab] = useState(1);
  const [activeMenuTab, setActiveMenuTab] = useState(
    setActiveMenuTabBasedOnWitnesses(evidenceObject)
  );
  const [witnessListByRoles, setWitnessListByRoles] = useState({});
  const [witnessSelectedPrimary, setWitnessSelectedPrimary] = useState([]);
  const [witnessSelectedSecondary, setWitnessSelectedSecondary] = useState([]);
  const [submitObject, setSubmitObject] = useState({});
  const [showWitnessForm, setShowWitnessForm] = useState(false);
  const [witnessName, setWitnessName] = useState("");
  const [witnessParty, setWitnessParty] = useState("");
  const [witnessSubmitLoading, setWitnessSubmitLoading] = useState(false);
  const [partiesOption, setPartiesOptions] = useState([]);
  const [canConvertWitness, setCanConvertWitness] = useState(true);
  const dispatch = useDispatch();
  // console.log(evidenceObject);
  // set witness objects from witness api data
  useEffect(() => {
    const getData = async () => {
      const witnessListResp = await getWitnessList(caseId, dispatch);
      if (witnessListResp) {
        const witnessListByRoles_ = createWitnessListByRoles(witnessListResp);
        setWitnessListByRoles(witnessListByRoles_);
        setIsLoading(false);
      }
      if (caseObject) {
        setPartiesOptions(
          caseObject.parties
            .map((party) => {
              return {
                value: party.id,
                label: `${party.name} - ${party.role}`,
              };
            })
        );
      }
    };
    getData();
  }, []);

  // set selected witnesses from evidenceObject data and unique party roles list
  useEffect(() => {
    if (
      (evidenceObject.witnesses && evidenceObject.witnesses.length) ||
      (evidenceObject.topics && evidenceObject.topics.length)
    ) {
      const primary = [];
      const secondary = [];
      evidenceObject.witnesses.forEach((x) => {
        if (x.type === "Primary") {
          primary.push(x);
        } else if (x.type === "Secondary") {
          secondary.push(x);
        }
      });
      setWitnessSelectedPrimary(primary);
      setWitnessSelectedSecondary(secondary);
    }
  }, [evidenceObject]);

  // set active tab from prop
  useEffect(() => {
    if (selectedMenuTab) {
      setActiveMenuTab(selectedMenuTab);
    }
  }, []);

  // send data to server if submitObject changed
  useEffect(() => {
    const sendData = async () => {
      const resp = await updateEvidence(
        isDocPropertiesFlow
          ? evidenceObject.classes.fake_id
          : evidenceObject.id,
        caseId,
        dispatch,
        submitObject
      );
      if (resp) {
        setEvidenceObject(resp);
        const submitObj = {
          witnesses: submitObject.witnesses.map((witness) => witness.real_id),
        };
        await copyRelevanceFromEvidenceToWitnessesList(
          isDocPropertiesFlow
            ? evidenceObject.classes.fake_id
            : evidenceObject.id,
          caseId,
          dispatch,
          submitObj
        );

        if (!noCloseAfterSave) {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        }

        setIsLoading(false);
      }
    };
    if (Object.keys(submitObject).length) {
      sendData();
    }
  }, [submitObject]);

  const onChangeCheckBoxHandler = (item, items, setFunction, type) => {
    const findItem = (element) => element.real_id === item.real_id;
    const foundItemIndex = items.findIndex(findItem);
    if (foundItemIndex === -1) {
      if (type === "Primary") {
        if (items.length && canConvertWitness) {
          items[0].type = "Secondary";
          setWitnessSelectedSecondary((prevState) => [...prevState, items[0]]);
          setCanConvertWitness(false);
        }
        setFunction([
          {
            ...item,
            type: type,
          },
        ]);
      } else {
        setFunction((prevState) => [
          ...prevState,
          {
            ...item,
            type: type,
          },
        ]);
      }
    } else {
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const witnessAssociateSubmitHandler = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const tempSubmitObject = {
        witnesses:
          witnessSelectedPrimary.length || witnessSelectedSecondary.length
            ? [...witnessSelectedPrimary, ...witnessSelectedSecondary]
            : [],
      };
      if (!returnFunction) {
        setSubmitObject({ ...tempSubmitObject });
      } else {
        const clone = { ...evidenceObject };
        if (clone.date) {
          clone.date = moment(clone.date).format("YYYY-MM-DD");
        }
        clone.witnesses = tempSubmitObject.witnesses;
        returnFunction(clone);
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
      setIsLoading(false);
    }
  };
  const updateWitnessList = (witnessObject) => {
    const witnessPartyRole =
      witnessObject.party && witnessObject.party.role
        ? witnessObject.party.role
        : "Unassigned";
    const witnessListByRolesCopy = { ...witnessListByRoles };
    if (witnessListByRolesCopy[witnessPartyRole]) {
      witnessListByRolesCopy[witnessPartyRole].push(witnessObject);
    } else {
      witnessListByRolesCopy[witnessPartyRole] = [witnessObject];
    }
    setWitnessListByRoles(witnessListByRolesCopy);
    if (activeMenuTab === 0) {
      witnessObject.type = "Primary";
      setWitnessSelectedPrimary((prevState) => [...prevState, witnessObject]);
    } else {
      witnessObject.type = "Secondary";
      setWitnessSelectedSecondary((prevState) => [...prevState, witnessObject]);
    }
  };

  const createNewWitnessSubmitHandler = async (event) => {
    event.preventDefault();
    setWitnessSubmitLoading(true);
    // if (!witnessParty) {
    //   handleErrorMessage("Select Witness party", dispatch);
    //   setWitnessSubmitLoading(false);
    //   return;
    // }
    // if (!witnessName) {
    //   handleErrorMessage("Enter Witness Name", dispatch);
    //   setWitnessSubmitLoading(false);
    //   return;
    // }
    // await
    const post_data = {
      name: witnessName.length ? witnessName : "New Witness",
      // TODO: Does I need there checks for null or length?
      causes_of_action: evidenceObject.causes_of_action,
      themes: evidenceObject.themes,
      issues: evidenceObject.issues,
      // type: activeMenuTab === 0 ? "Primary" : "Secondary",
      //evidences: [{ ...evidenceObject }],
    };
    if (witnessParty) {
      post_data["id_party"] = witnessParty;
    }
    if (activeMenuTab === 0 && witnessSelectedPrimary.length > 0) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload:
          "There can only be one primary witness. Deselect current primary witness, or go to secondary witness tab ",
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR });
      setWitnessSubmitLoading(false);
    } else {
      const witnessResp = await createNewWitness(caseId, dispatch, post_data);
      if (witnessResp) {
        updateWitnessList(witnessResp);
        setWitnessName("");
        setWitnessParty("");
      }
      setWitnessSubmitLoading(false);
      setShowWitnessForm(false);
    }
  };

  const cancelCreateNewWitnessSubmitHandler = (event) => {
    event.preventDefault();
    setWitnessName("");
    setWitnessParty("");
    setShowWitnessForm(false);
  };

  const addWitness = () => {
    setShowWitnessForm(true);
  };

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const renderSection = () => {
    return Object.keys(witnessListByRoles).map(
      (role, index) =>
        !!witnessListByRoles[role].length && (
          <div key={index} style={{ marginBottom: "30px" }}>
            <div style={{ marginBottom: "17px" }}>
              <Bold14Font>{role}</Bold14Font>
            </div>
            {witnessListByRoles[role].map((object, idx) => (
              <Checkbox
                key={idx}
                checked={
                  activeMenuTab === 0
                    ? !witnessSelectedSecondary.find(
                        (element) => element.real_id === object.real_id
                      ) &&
                      witnessSelectedPrimary.find(
                        (element) => element.real_id === object.real_id
                      )
                    : !witnessSelectedPrimary.find(
                        (element) => element.real_id === object.real_id
                      ) &&
                      witnessSelectedSecondary.find(
                        (element) => element.real_id === object.real_id
                      )
                }
                label={object.name}
                onChange={() =>
                  activeMenuTab === 0
                    ? !witnessSelectedSecondary.find(
                        (element) => element.real_id === object.real_id
                      )
                      ? onChangeCheckBoxHandler(
                          object,
                          witnessSelectedPrimary,
                          setWitnessSelectedPrimary,
                          "Primary"
                        )
                      : null
                    : !witnessSelectedPrimary.find(
                        (element) => element.real_id === object.real_id
                      )
                    ? onChangeCheckBoxHandler(
                        object,
                        witnessSelectedSecondary,
                        setWitnessSelectedSecondary,
                        "Secondary"
                      )
                    : null
                }
                disabled={
                  activeMenuTab === 0
                    ? witnessSelectedSecondary.find(
                        (element) => element.real_id === object.real_id
                      )
                    : witnessSelectedPrimary.find(
                        (element) => element.real_id === object.real_id
                      )
                }
              />
            ))}
          </div>
        )
    );
  };

  return (
    <Fragment>
      <div className="form-group mb-4">
        <RsbTabsMenu
          tabs={menuTabs}
          activeMenuTab={activeMenuTab}
          setActiveMenuTab={setActiveMenuTab}
        />
      </div>
      {/* Central part of RSB WITH overflow*/}
      <div style={{ height: "100%", overflowY: "auto" }}>
        {/* Primary */}
        {activeMenuTab === 0 ? (
          <div className="form-group">
            <div className="container-fluid">{renderSection()}</div>
          </div>
        ) : null}

        {/* Secondary */}
        {activeMenuTab === 1 ? (
          <div className="form-group">
            <div className="container-fluid">{renderSection()}</div>
          </div>
        ) : null}
        {showWitnessForm ? (
          <div>
            <div className="form-group">
              <InputNew
                placeholder={"Enter Witness name"}
                value={witnessName}
                name={"witness_name"}
                onChangeHandler={(e) => {
                  setWitnessName(e.target.value);
                }}
                label={"Witness Name"}
              />
            </div>
            <div className="form-group">
              <SelectAnotherTry
                label="Party"
                options={partiesOption}
                value={partiesOption.find((v) => v.value === witnessParty)}
                onChange={(selected_value) => {
                  setWitnessParty(selected_value.value);
                }}
                placeholder={"Select Party"}
              />
            </div>
            <div className="form-group">
              <div className="container-fluid d-flex justify-content-around flex-wrap">
                <ButtonNew
                  // wide
                  style={{ width: "33%" }}
                  loading={witnessSubmitLoading}
                  disabled={witnessSubmitLoading}
                  primary
                  clickHandler={createNewWitnessSubmitHandler}
                >
                  Add
                </ButtonNew>
                <ButtonNew
                  // wide
                  style={{ width: "33%" }}
                  loading={witnessSubmitLoading}
                  disabled={witnessSubmitLoading}
                  danger
                  clickHandler={cancelCreateNewWitnessSubmitHandler}
                >
                  Cancel
                </ButtonNew>
              </div>
            </div>
          </div>
        ) : null}

        {!showWitnessForm ? (
          <div className="form-group">
            <div className="container-fluid">
              <ListAddButton
                className="d-flex"
                label="Add Witness"
                clickHandler={addWitness}
              />
            </div>
          </div>
        ) : null}
      </div>

      {/* Always visible bottom part */}
      <div>
        <DividerHorizontal />
        <div className="form-group mt-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ButtonNew
                  loading={isLoading}
                  disabled={isLoading}
                  wide
                  primary
                  onClick={witnessAssociateSubmitHandler}
                >
                  Save
                </ButtonNew>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AssociateWitnessForm.propTypes = {
  object: PropTypes.object,
  evidenceId: PropTypes.number.isRequired,
  /**
   * Need to set parties list
   */
  caseObject: PropTypes.object.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  isDocPropertiesFlow: PropTypes.bool,
};

export default withTheme(AssociateWitnessForm);
