import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import ListAddButton from "../../buttons/ListAddButton";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { createCOA, updateCOA } from "../../../containers/Auth/auth";
import Label from "../../forms/AddCardForm/Label";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import EditableStringClassComponent from "../../forms/CasesForms/EditableStringClassComponent";

export default function OffencesForm(prop) {
  let { object } = prop;
  const dispatch = useDispatch();
  const [object_, setObject] = useState(object);
  const [name, setName] = useState(object.name ?? "");
  const [isLoading, setIsLoading] = useState(false);
  let form = React.createRef();
  const editableStringsRef = useRef([]);

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  const addElement = async (e) => {
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      let elements;
      if (!object_.elements) {
        elements = [{ name: "" }];
      } else {
        elements = [...object_.elements, { name: "" }];
      }
      setObject({
        ...object_,
        ...{ elements },
      });
    }
  };
  const deleteElement = (index) => {
    setObject({
      ...object_,
      ...{
        elements: object_.elements.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };
  const editElement = (index, name) => {
    let elements = object_.elements;
    elements[index].name = name;
    setObject({
      ...object_,
      ...{ elements },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      let resp;
      if (!object_.elements || !object_.elements.length) {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload: "You should add at least one element",
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
        setIsLoading(false);
        return;
      }
      let data = {
        name: name,
        elements: object_.elements,
        is_criminal: true,
      };
      if (object.id) {
        resp = await updateCOA(object.id, dispatch, data);
      } else {
        resp = await createCOA(dispatch, data);
      }
      setIsLoading(false);
      if (resp) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update"
        );
      }
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Input
            maxLength={255}
            type={"text"}
            required
            label={"Offence"}
            onChangeHandler={(e) => {
              setName(e.target.value);
            }}
            value={name}
            placeholder={"Enter name"}
            name={"name"}
            form="form-COAForm"
          />
        </div>
        {object_.elements && object_.elements.length ? (
          <Fragment>
            <AnimatedDiv className="form-group mb-0">
              <Label className={"row"} name={"Elements"} />
            </AnimatedDiv>
            {object_.elements.map((sub_t, i) => {
              return (
                <AnimatedDiv key={sub_t.id ?? i} className="form-group pl-3">
                  <EditableStringClassComponent
                    editName={editElement}
                    editState={typeof sub_t.id === "undefined"}
                    deleteAction={deleteElement}
                    object={sub_t}
                    index={i}
                    ref={(ref) => assignRef(ref, i)}
                  />
                </AnimatedDiv>
              );
            })}
          </Fragment>
        ) : null}
        <div className="form-group">
          <div className="container-fluid">
            <ListAddButton
              className="d-flex"
              label="Add Element"
              clickHandler={addElement}
            />
          </div>
        </div>
      </div>

      <DividerHorizontal />

      <div className="form-group">
        <div className="container-fluid">
          <form ref={form} onSubmit={handleSubmit} id="form-COAForm">
            <Button
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
OffencesForm.propTypes = {
  object: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,
};
