import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";

import Form from "@/components/forms/Form";
import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import RadioButtonGroup from "@/components/buttons/RadioButtonGroup";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
import PageConfiguration from "@/components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "@/components/FontsNewComponent/Fonts";
import Button from "@/components/buttons/Button";

import { CASES } from "@/components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

import { getCaseById } from "@/containers/Auth/auth";

import { loadAccessToken } from "@/containers/Auth/auth";

import { Row, AddChapterLabel, PlusIcon, NewApplicationButton } from "./styled";

const TRIAL = "Trial";
const APPLICATION = "Application";
// const APPEAL = "Appeal";
const CUSTOM = "Custom";

const notebookTypesButtonsObjects = [
  {
    title: <Medium14Font>{TRIAL}</Medium14Font>,
    type: TRIAL,
  },
  {
    title: <Medium14Font>{APPLICATION}</Medium14Font>,
    type: APPLICATION,
  },
  // {
  //   title: <Medium14Font>{APPEAL}</Medium14Font>,
  //   type: APPEAL,
  // },
  {
    title: <Medium14Font>{CUSTOM}</Medium14Font>,
    type: CUSTOM,
  },
];

const shareOptions = [
  {
    title: <Medium14Font>Yes</Medium14Font>,
    type: 1,
  },
  {
    title: <Medium14Font>No</Medium14Font>,
    type: 0,
  },
];

const EditNotebook = ({ theme }) => {
  const [name, setName] = useState("");
  const history = useHistory();

  const [shareWithTeam, setShareWithTeam] = useState(null);
  const [notebookType, setNotebookType] = useState(null);
  console.log(notebookType);

  const [loading, setLoading] = useState(false);

  const { id: caseId, notebook_id: notebookId } = useParams();

  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const dispatch = useDispatch();

  const [caseObject, setCaseObject] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [notebookLoading, setNotebookLoading] = useState(false);

  const [notebook, setNotebook] = useState(undefined);

  const fetchNotebook = async () => {
    setNotebookLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios
      .get(`/api/cases/${caseId}/note-book/${notebookId}`, config)
      .catch((err) => {
        setNotebookLoading(false);
        // setChaptersFetching(false);
        // setNotebookNotFound(true);
      });

    setNotebookLoading(false);
    if (res.data.data) {
      setNotebook(res.data.data);
      setName(res.data.data.name);

      if (res.data.data.type === "Application") {
        setNotebookType(APPLICATION);
        // setSelectedApplication(
        //   applications.find((i) => i.value === res.data.data.application.id)
        // );
      }

      if (res.data.data.type === "Custom") {
        setNotebookType(CUSTOM);
      }

      if (res.data.data.type === "Trial") {
        setNotebookType(TRIAL);
      }
      // setApplication
    }
  };

  useEffect(() => {
    fetchNotebook();
  }, []);

  const editNotebook = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    const res = await axios.put(
      `/api/cases/${caseId}/note-book/${notebookId}`,
      {
        name: "_edited",
      },
      config
    );
    if (res && res.data && res.data.data) {
      // setNotebooks([
      //   ...notebooks.slice(0, notebookIndex),
      //   res.data.data,
      //   ...notebooks.splice(notebookIndex + 1),
      // ]);
    }
  };

  // Get data from API
  const getDataFromAPI = async () => {
    const caseResp = await getCaseById(caseId, dispatch);
    if (is_admin && !caseResp) {
      history.push("/admin/all-cases");
    }
    if (caseResp) {
      setCaseObject(caseResp);
      setIsLoading(false);
    }
  };

  // Set data to state after component mount
  useEffect(() => {
    getDataFromAPI();
  }, []);

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [newAppName, setNewAppName] = useState("");

  const fetchApplications = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.get(`/api/cases/${caseId}/applications`, config);

    if (res && res.data && res.data.data) {
      setApplications(
        res.data.data.map((app) => ({
          label: app.label,
          value: app.id,
          real_id: app.real_id,
        }))
      );
    }
  };

  const [initialSet, setInitialSet] = useState(false);

  useEffect(() => {
    fetchApplications();
  }, []);

  useEffect(() => {
    if (notebook && applications.length > 0 && !initialSet) {
      setInitialSet(true);
      if (notebook.type === "Application") {
        setSelectedApplication(
          applications.find((i) => i.real_id === notebook.application.id)
        );
      }
    }
  }, [applications, notebook, initialSet]);

  const [creatingNewApplication, setCreatingNewApplication] = useState(false);

  const addNotebook = async (applicationId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let res;
    if (selectedApplication) {
      res = await axios.put(
        `/api/cases/${caseId}/note-book/${notebookId}`,
        {
          name: name,
          type: notebookType, // trial, application, custom
          id_application: selectedApplication.value,
          // id_application: '',
        },
        config
      );
      // 2. if just selected -> include selected
    } else {
      res = await axios.put(
        `/api/cases/${caseId}/note-book/${notebookId}`,
        {
          name: name,
          type: notebookType, // trial, application, custom
          // id_application: '',
        },
        config
      );
      // 3. if not application -> don't include application_id
    }

    if (res && res.data && res.data.data) {
      history.push(`/app/cases/${caseId}/notebooks`);
    }
  };

  const onSetCheckedNotebookTypeButton = (type) => {
    setNotebookType(type);
  };

  if (isLoading || notebookLoading || !notebook) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${caseId}` : `/admin/all-cases/${caseId}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      // path: !is_admin
      //   ? `/app/cases/${caseId}/notebooks`
      //   : `/admin/all-cases/${caseId}/notebooks`,
      path: !is_admin
        ? `/app/cases/${caseId}/notebooks`
        : `/admin/all-cases/${caseId}/notebooks`,
      title: "Notebooks",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${caseId}/notebooks/edit/${notebookId}`
        : `/admin/all-cases/${caseId}/notebooks/edit/${notebookId}`,
      title: `Edit ${notebook.name}`,
      activeMenuItem: CASES,
    },
  ];

  return (
    <div className="d-flex flex-column">
      <PageConfiguration configArray={pageConfig} />
      {/*
      <PageConfiguration configArray={pageConfig} />
      */}
      <div className="row">
        <div className="col-12 col-sm-8">
          <Bold30Font>Edit Notebook</Bold30Font>
        </div>
        <div className="col-12 col-sm-4 d-flex justify-content-end align-items-center mb-3">
          <Button
            clickHandler={addNotebook}
            disabled={name === "" || notebookType === null || loading}
            loading={loading}
            type={"submit"}
            primary
          >
            <Bold14Font textColor={theme.colors.white}>Save</Bold14Font>
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        {/*<form*/}
        {/*// onSubmit={submitOnEnter}*/}
        {/*>*/}
        <div className="row">
          <Form style={{ overflowY: "visible" }}>
            <div style={{ padding: "0 15px" }}>
              <div className="form-group">
                <InputNew
                  // onBlur={onBlurHandler}
                  name="label"
                  type="text"
                  value={name}
                  placeholder="Enter notebook title"
                  label="Notebook title"
                  onChange={(e) => setName(e.target.value)}
                  maxLength={125}
                />
              </div>
              <div className="form-group">
                <RadioButtonGroup
                  label="Notebook Type"
                  itemsObjects={notebookTypesButtonsObjects}
                  checkedButton={notebookType}
                  setCheckedButton={(type) => {}}
                  w100
                  disabled
                />
              </div>
              {notebookType === "Application" && (
                <div>
                  {creatingNewApplication ? (
                    <div className="d-flex flex-column">
                      <div className="form-group row">
                        <div className="col-12 col-sm-6">
                          <InputNew
                            name="Application_name"
                            type="text"
                            value={newAppName}
                            placeholder="Application name"
                            label="Application name"
                            onChange={(e) => setNewAppName(e.target.value)}
                            maxLength={125}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-sm-6">
                          <ButtonNew
                            clickHandler={async () => {
                              const config = {
                                headers: {
                                  Authorization: `Bearer ${await loadAccessToken()}`,
                                },
                              };

                              const applicationRes = await axios.post(
                                `/api/cases/${caseId}/applications`,
                                {
                                  label: newAppName,
                                },
                                config
                              );

                              if (applicationRes.data.data) {
                                const newApp = applicationRes.data.data;
                                console.log(newApp);
                                console.log(newApp);
                                console.log(newApp);
                                console.log(newApp);
                                const newAppOption = {
                                  label: newApp.label,
                                  value: newApp.id,
                                };
                                setApplications((applications) => [
                                  ...applications,
                                  newAppOption,
                                ]);
                                setSelectedApplication(newAppOption);
                                setCreatingNewApplication(false);
                              }

                              //
                            }}
                            primary
                            style={{
                              width: "calc(100% - 30px)",
                              margin: "0 15px 0 15px",
                            }}
                          >
                            Submit
                          </ButtonNew>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="form-group row">
                        <div className="col-12 col-sm-6">
                          <SelectAnotherTry
                            label="Application"
                            name="application"
                            placeholder="Select application"
                            options={applications}
                            // defaultValue={partyOptions.filter(
                            //   (option) => option.value === witnessSelectedParty
                            // )}
                            onChange={(newV) => {
                              setSelectedApplication(newV);
                            }}
                            value={selectedApplication}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-12 col-sm-6">
                          <NewApplicationButton
                            onClick={() => setCreatingNewApplication(true)}
                          >
                            <Row
                              alignCenter
                              groupHover
                              style={{ cursor: "pointer" }}
                            >
                              <PlusIcon />
                              <AddChapterLabel>New application</AddChapterLabel>
                            </Row>
                          </NewApplicationButton>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {/*
              <div className="form-group">
                <RadioButtonGroup
                  label="Share with Case Team"
                  itemsObjects={shareOptions}
                  checkedButton={shareWithTeam}
                  setCheckedButton={setShareWithTeam}
                />
              </div>
              */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditNotebook);
