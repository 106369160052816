import React, { Fragment, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Bold14Font, Bold30Font, Medium14Font } from "../../Fonts/Fonts";
import Input from "../../Input";
import Button from "../../buttons/Button/Button";
import { withTheme } from "styled-components/macro";
import SignUpFormStyled from "../SignUpForm/styled/SignUpFormStyled";
import {
  resendVerifySms,
  signIn,
  signUpWithGoogle,
} from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { SIGN_IN } from "../../../redux/types";
import { route } from "../../../routes";
import modalErrorHandler from "../../Modal/modalErrorHandler";
import GoogleLogin from "react-google-login";
import { TwoFAForm } from "../2FAForm/TwoFAForm";

const SignInForm = ({ theme }) => {
  let form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [twoFA, setTwoFa] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [credentials, setCredentials] = useState(
    location.state && location.state.data
      ? { email: location.state.data.email }
      : {}
  );
  const [googleLoading, setGoogleLoading] = useState(false);

  const onInputChangeHandler = (event) => {
    event.persist();
    setCredentials((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const makeRequest = async (code) => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        let data_ = { ...credentials };
        if (code) {
          data_.code = code;
        }
        const response = await signIn(data_);
        setIsLoading(false);
        if (response.data.message && response.data.message === "2fa") {
          setTwoFa(true);
        } else {
          dispatch({ type: SIGN_IN, payload: response.data });
          if (response.data.user["email_verified_at"] === null) {
            history.push(route.auth.confirmEmail);
          } else if (response.data.user["email_verified_at"] !== null) {
            history.push(route.baseApp.app);
          }
        }
      } catch (e) {
        modalErrorHandler(e, dispatch);
        setIsLoading(false);
      }
    }
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    await makeRequest();
  };
  const responseGoogle = async (resp) => {
    if (resp.accessToken) {
      setGoogleLoading(true);
      let response = await signUpWithGoogle(resp.accessToken, dispatch);
      if (response.status === 200) {
        dispatch({ type: SIGN_IN, payload: response.data });
        history.push(route.auth.signUpWithGoogle);
      } else {
        modalErrorHandler(response, dispatch);
      }
      setGoogleLoading(false);
    }
  };
  return (
    <div className="d-flex flex-column align-items-center">
      {twoFA ? (
        <TwoFAForm
          cancelFunction={(e) => {
            if (e) {
              e.preventDefault();
            }
            setTwoFa(false);
            setCredentials({});
          }}
          count_={60}
          makeRequest={makeRequest}
          resendFunction={resendVerifySms}
          resendData={credentials}
          isSubmitting={isLoading}
        />
      ) : (
        <Fragment>
          <div className="row p-5">
            <div className="col d-flex flex-column align-items-center">
              <Bold30Font as="p">Sign In</Bold30Font>
              <Medium14Font
                as="p"
                textColor={theme.colors.darkOpacity}
                className="text-center"
              >
                Welcome back, Sign In to access your Case Arena.
              </Medium14Font>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <SignUpFormStyled
                onSubmit={onSubmitHandler}
                ref={(ref) => {
                  form = ref;
                }}
              >
                <div className="form-group">
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    label="Email"
                    onChangeHandler={onInputChangeHandler}
                    required
                    value={credentials.email ?? ""}
                  />
                </div>
                <div className="form-group">
                  <Input
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    label="Password"
                    onChangeHandler={onInputChangeHandler}
                    minLength={8}
                    required
                    value={credentials.password ?? ""}
                  />
                </div>
                <div className="container-fluid">
                  <div className="row mb-4">
                    <div className="col d-flex justify-content-center">
                      <Link to={route.auth.restorePassword}>
                        <Bold14Font textColor={"rgb(55, 74, 194)"}>
                          Forgot Password?
                        </Bold14Font>
                      </Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Button
                        primary
                        wide
                        type="submit"
                        clickHandler={() => null}
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Sign In
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col d-flex flex-column align-items-center">
                      <Medium14Font
                        className="my-3"
                        textColor={theme.colors.darkOpacity}
                      >
                        Or
                      </Medium14Font>
                      <GoogleLogin
                        clientId={process.env.MIX_GOOGLE_CLIENT_ID}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        disabled={googleLoading}
                        render={(renderProps) => (
                          <Button
                            secondary
                            wide
                            loading={googleLoading}
                            clickHandler={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <Bold14Font textColor={theme.colors.blue}>
                              Sign In with Google
                            </Bold14Font>
                          </Button>
                        )}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                      <div className="d-flex align-items-center mt-3">
                        <Medium14Font>Don’t have an account ?</Medium14Font>
                        <Button
                          tertiary
                          clickHandler={() => history.push(route.auth.signUp)}
                          style={{ marginBottom: "2px" }}
                        >
                          Sign Up
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </SignUpFormStyled>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default withTheme(SignInForm);
