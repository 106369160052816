import React, { Fragment, useEffect, useState } from "react";
import RsbCaseCardPreviewDotStyled from "./styled/RSBCaseCardPreviewDotStyled";
import {
  Bold14Font,
  Bold24Font,
  Medium14Font,
} from "../FontsNewComponent/Fonts";
import { route } from "../../routes";
import { useHistory } from "react-router-dom";
import {
  deleteCase,
  makeRequest,
  toggleArchiveCaseStatus,
} from "../../containers/Auth/auth";
import {
  GET,
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../redux/types";
import modalErrorHandler from "../Modal/modalErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../styled-components/Theme/Theme";
import { withTheme } from "styled-components/macro";
import CRUDButton from "../buttons/CRUDButton";
import Button from "../buttons/Button";
import eyeIcon from "../../assets/img3/svg/eye-icon.svg";
import personIcon from "../../assets/img3/svg/person.svg";
import pencilIcon from "../../assets/img3/svg/pancil_icon.svg";
import moment from "moment";
import ExpandableBlock from "../baseAppComponents/ExpandableBlock";
import {
  TagElementCaseType,
  TagElementCyan,
  TagElementPurple,
} from "../TagElementStyled/TagElementStyled";
import {
  checkIfUserHaveSubscription,
  checkIfUserIsOwnerOfTheCase,
} from "../../containers/Auth/utils";

const RSBCaseCardPreview = ({
  caseObject,
  // caseId,
  deleteCaseFromView,
  archiveCaseInView,
  afterCloseCallback,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [archived, setArchived] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    setArchived(caseObject.archived);
  }, [caseObject]);

  const viewCase = () => {
    if (is_admin) {
      history.push(`${route.admin.baseApp.allCases}/${caseObject.id}`);
    } else {
      history.push(`${route.baseApp.cases}/${caseObject.id}`);
    }
  };

  const editCard = () => {
    history.push(
      `${
        is_admin ? route.admin.baseApp.cases_edit : route.baseApp.cases_edit
      }` +
        "/" +
        caseObject.id
    );
  };

  const deleteCard = () => {
    if (checkIfUserHaveSubscription()) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "You won't be able to restore data",
        beforeCloseHandler: async () => {
          await deleteCase(caseObject.id, dispatch);
          await deleteCaseFromView(caseObject.id);
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    }
  };

  const archiveCard = async () => {
    setButtonIsLoading(true);
    await toggleArchiveCaseStatus(caseObject.id, archived);
    archiveCaseInView(caseObject.id);
    setArchived((prevState) => !prevState);
    setButtonIsLoading(false);
  };

  const sectionsToRender = [
    {
      id: 1,
      title:
        caseObject.new_case_type === "Criminal"
          ? "Offence"
          : "Causes of Action",
      field: caseObject.causes_of_action_original
        ? "causes_of_action_original"
        : "causes_of_action",
      render: (el) => {
        if (el.counterclaim) {
          return `(C-CLAIM) ${el.type.name}`
        }
        return el.type.name
      },
    },
    {
      id: 2,
      title: "Themes",
      field: caseObject.themes_original ? "themes_original" : "themes",
      render: (el) => el.name,
    },
    {
      id: 3,
      title: "Issues",
      field: caseObject.issues_original ? "issues_original" : "issues",
      render: (el) => el.name,
    },
  ];


  return (
    <Fragment>
      <div className="row px-3 mb-3">
        <div className="col d-flex justify-content-between">
          <RsbCaseCardPreviewDotStyled isArchive={archived} />
          <TagElementPurple overflow style={{ marginRight: "10px" }}>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject && caseObject.case_type
                ? caseObject.case_type.name
                : "no type"}
            </Bold14Font>
          </TagElementPurple>
          <TagElementCyan overflow>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject && caseObject.firm_name
                ? caseObject.firm_name
                : "no firm"}
            </Bold14Font>
          </TagElementCyan>
        </div>
      </div>
      {/* Case label */}
      <div className="row px-3 mb-4">
        <div className="col">
          <Bold24Font as="p">
            {caseObject && caseObject.label ? caseObject.label : ""}
          </Bold24Font>
        </div>
      </div>
      <div className="row px-3 mb-2">
        <div className="col">
          <TagElementCaseType>{caseObject.new_case_type}</TagElementCaseType>
        </div>
      </div>

      <div
        className="px-3"
        style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        {caseObject
          ? sectionsToRender.map((el) => (
              <ExpandableBlock
                key={el.id}
                config={el}
                dataObject={caseObject}
              />
            ))
          : null}
      </div>
      {/* Buttons */}
      <div
        className="container-fluid pt-3 px-3"
        style={{ borderTop: `1px solid ${theme.colors.gray}` }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-between mb-4">
            <div className="d-flex" title="Client role">
              <img src={personIcon} alt="" className="img-fluid mr-2" />
              {/* TODO: Is this right field? */}
              {caseObject && caseObject.parties && caseObject.parties.length ? (
                <Medium14Font>{caseObject.parties[0].role}</Medium14Font>
              ) : (
                <Medium14Font>N/A</Medium14Font>
              )}
            </div>
            <div className="d-flex" title="last updated">
              <img src={pencilIcon} alt="" className="img-fluid mr-2" />
              <Medium14Font>
                {moment(caseObject && caseObject.updated_at).format("DD-MM-YYYY")}
              </Medium14Font>
            </div>
          </div>
          <div className="col-12 mb-4">
            <Button primary wide clickHandler={viewCase}>
              <img
                src={eyeIcon}
                alt=""
                className="img-fluid mr-2 align-bottom"
              />
              <Bold14Font textColor={theme.colors.white}>View Case</Bold14Font>
            </Button>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-around">
            {!is_admin ? (
              <CRUDButton
                archiveButton
                archivedStatus={archived}
                onClick={archiveCard}
                disabled={buttonIsLoading}
                className="mr-2 mb-2"
              />
            ) : null}
            <CRUDButton className="mr-2 mb-2" editButton onClick={editCard} />
            {!is_admin && checkIfUserIsOwnerOfTheCase(caseObject) ? (
              <CRUDButton deleteButton onClick={deleteCard} />
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(RSBCaseCardPreview);
