import styled from "styled-components";

export const FloatingMenu = styled.div`
  background: white;
  position: fixed;
  margin-top: -111px;
  margin-left: -7px;
  display: flex;
  flex-direction: column;
  // right: 0;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 8px 0px;
  > span {
    height: 42px;
    width: 200px;
    color: #374ac2;
    font-weight: 500;
    padding-left: 30px;
    display: flex;
    align-items: center;
  }
  > span:hover {
    cursor: pointer;
    background: rgba(55, 74, 194, 0.1);
  }
`;

export const ConnectionMenu = styled.div`
  background: white;
  position: fixed;
  margin-top: -139px;
  margin-left: -7px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 10px 5px;
`;

export const ConnectionObject = styled.div`
  display: flex;
  // flex-direction: column;
`;

export const ConnectTagType = styled.span`
  font-size: 10px;
  font-weight: 500;
  color: #6a6c7d;
`;

export const ConnectTagName = styled.span`
  font-size: 13px;
  font-weight: 500;
  // color: #374ac2;
`;

export const ConnectedToLabel = styled.span`
  color: #6a6c7d;
  font-size: 10px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 4px;
`;
