import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { useParams, useHistory } from "react-router-dom";
import { getApplicationById, getCaseById } from "../../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import NoRecords from "../../../../components/NoRecords";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../../components/FontsNewComponent/Fonts";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import CasesMenu from "../../../../components/baseAppComponents/CasesMenu";
import Form from "../../../../components/forms/Form";
import {
  TagElementBlue,
  TagElementOrange,
  TagElementPurple,
  TagElementTurquoise,
} from "../../../../components/TagElementStyled/TagElementStyled";
import moment from "moment";
import { AssociationTableListElement } from "../../../../components/AssociationTable";
import AssociationTableListElementStyled from "../../../../components/AssociationTable/styled/AssociationTableListElementStyled";
import Table from "../../../../components/Table/Table";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../../redux/types";
import { format, parseISO } from "date-fns";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import AuthoritiesDetails from "../../TrialHub/Authorities/AuthoritiesDetails";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar";
import FlagRelevance from "../../TrialHub/Witnesses/xExamForms/FlagRelevance";
import FlagRelevanceFilter from "../../../../components/FlagRelevanceFilter/FlagRelevanceFilter";
import flagRelevanceCheck from "../../../../components/FlagRelevanceFilter/flagRelevanceCheck";
import { setTagField } from "../../../../utils/function";

const tabsPanelItems = [
  {
    id: 1,
    text: "Basic Info",
    activeType: 0,
  },
  {
    id: 2,
    text: "Application Papers",
    activeType: 1,
  },
  {
    id: 3,
    text: "Application Authorities",
    activeType: 2,
  },
];

const ApplicationDetails = ({ theme }) => {
  // page states
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [
    showApplicationAuthorityDetails,
    setShowApplicationAuthorityDetails,
  ] = useState(false);
  const { id, applicationId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchBarValue, setSearchBarValue] = useState("");
  const [flagRelevanceList, setFlagRelevanceList] = useState([]);
  // TODO: Filter states and functionality

  // data states
  const [caseObject, setCaseObject] = useState(null);
  const [applicationObject, setApplicationObject] = useState(null);
  const [
    applicationAuthoritySelectedObject,
    setApplicationAuthoritySelectedObject,
  ] = useState(null);

  // save data
  const saveData = (caseResp, applicationResp) => {
    setCaseObject(caseResp);
    setApplicationObject(applicationResp);
  };

  // get data
  const getDataFromAPI = async () => {
    const caseResp = await getCaseById(id, dispatch);
    if (is_admin && !caseResp) {
      history.push("/admin/all-cases");
    }
    const applicationResp = await getApplicationById(
      applicationId,
      id,
      dispatch
    );
    if (caseResp && applicationResp) {
      saveData(caseResp, applicationResp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading && !applicationObject) {
      getDataFromAPI();
    }
  }, []);

  // button methods

  const onClickTabPanelHandler = (activeType) => {
    setSearchBarValue("");
    setShowApplicationAuthorityDetails(false);
    setActiveTab(activeType);
  };

  const onClickEditButtonHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${id}/applications-hub/edit/${applicationId}`
        : `/admin/all-cases/${id}/applications-hub/edit/${applicationId}`,
      {
        applicationWorkflowStage: activeTab,
      }
    );
  };

  const onClickPapersEditTableRowHandler = (object) => {
    const objectCopy = { ...object };
    objectCopy.id = object.fake_id;
    history.push(
      !is_admin
        ? `/app/cases/${id}/applications-hub/edit/${applicationId}`
        : `/admin/all-cases/${id}/applications-hub/edit/${applicationId}`,
      {
        applicationWorkflowStage: 1,
        selectedObject: objectCopy,
      }
    );
  };

  const onClickAuthoritiesEditTableRowHandler = (object) => {
    const objectCopy = { ...object };
    delete objectCopy.tags;
    // objectCopy.id = object.fake_id;
    history.push(
      !is_admin
        ? `/app/cases/${id}/applications-hub/edit/${applicationId}`
        : `/admin/all-cases/${id}/applications-hub/edit/${applicationId}`,
      {
        applicationWorkflowStage: 2,
        selectedObject: objectCopy,
      }
    );
  };

  const onClickAuthoritiesTableRowHandler = (object) => {
    const objectClone = { ...object, case: caseObject };
    setApplicationAuthoritySelectedObject(objectClone);
    setShowApplicationAuthorityDetails(true);
  };

  const onClickBackToApplicationAuthoritiesTableHandler = () => {
    setShowApplicationAuthorityDetails(false);
  };

  const onClickClearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  // input methods

  const onChangeSearchBarValueHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const onChangeFlagRelevanceHandler = (value) => {
    setFlagRelevanceList([...value]);
  };

  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };

  const filterFunction = (value) => {
    let check = true;

    if (searchBarValue && check) {
      // search in evidence label and objectives
      if (value.label) {
        check =
          (value.label && searchField(value.label)) ||
          (value.objectives &&
            value.objectives.length &&
            searchField(value.objectives));
        // search in authorities title
      } else if (value.title) {
        check = value.title && searchField(value.title);
      }
    }

    // Filter application authorities by flag relevance
    if (activeTab === 2) {
      if (check && flagRelevanceList.length) {
        check = flagRelevanceCheck(value, flagRelevanceList);
      }
    }

    return check;
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/applications-hub`
        : `/admin/all-cases/${id}/applications-hub`,
      title: "Application Hub",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/applications-hub/${applicationId}`
        : `/admin/all-cases/${id}/applications-hub/${applicationId}`,
      title: applicationObject.label,
      activeMenuItem: CASES,
    },
  ];

  const applicationPapersTable = {
    collapsed: false,
    canSort: true,
    actions: [
      {
        type: "edit",
        callback: (object) => onClickPapersEditTableRowHandler(object),
      },
      // {
      //   type: "delete",
      //   callback: (object) => {
      //     dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      //     dispatch({
      //       type: SAVE_MODAL_DATA,
      //       payload: "You won't be able to restore data",
      //       beforeCloseHandler: async () => {
      //         await onClickDeleteTableRowHandler(object);
      //       },
      //     });
      //     dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      //   },
      // },
    ],
    fields: [
      {
        name: "id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "DOC LABEL",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "doc_type",
        label: "DOC TYPE",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.doc_type}</Medium14Font>;
        },
      },
      {
        name: "date",
        label: "DATE",
        canSort: true,
        sortCondition: 'date',
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd-MM-yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        canSort: true,
        sortCondition: 'string',
        name: "probative_status",
        label: "PROBATIVE STATUS",
        renderer: (object) => {
          return <Medium14Font>{object.probative_status}</Medium14Font>;
        },
      },
      {
        name: "parties",
        label: "PARTIES",
        renderer: (object) => {
          if (object.parties && object.parties.length) {
            return (
              <ul>
                {object.parties.map((party) => (
                  <LiStyled key={party.id}>
                    <Medium14Font>{party.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "reliefs",
        label: "RELIEFS",
        renderer: (object) => {
          if (
            (object.reliefs && object.reliefs.length) ||
            (object.proofs && object.proofs.length)
          ) {
            return (
              <ul>
                {[...object.reliefs, ...object.proofs].map((el, index) => (
                  <LiStyled key={index}>
                    <Medium14Font style={{ color: theme.colors.darkOpacity }}>
                      {el.name}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  const applicationAuthoritiesTable = {
    collapsed: false,
    canSort: true,
    clickAction: (object) => {
      onClickAuthoritiesTableRowHandler(object);
    },
    actions: [
      {
        type: "edit",
        callback: (object) => onClickAuthoritiesEditTableRowHandler(object),
      },
      // {
      //   type: "delete",
      //   callback: (object) => {
      //     dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      //     dispatch({
      //       type: SAVE_MODAL_DATA,
      //       payload: "You won't be able to restore data",
      //       beforeCloseHandler: async () => {
      //         await onClickDeleteTableRowHandler(object);
      //       },
      //     });
      //     dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      //   },
      // },
    ],
    fields: [
      {
        name: "fake_id",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        name: "title",
        label: "TITLE",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.title}</Medium14Font>;
        },
      },
      {
        name: "type",
        label: "TYPE",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.type}</Medium14Font>;
        },
      },
      {
        name: "jurisdiction",
        label: "JURISDICTION",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.jurisdiction}</Medium14Font>;
        },
      },
      {
        name: "citation",
        label: "CITATION",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.citation}</Medium14Font>;
        },
      },
      {
        name: "key_sections",
        label: "SECTIONS",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.key_sections}</Medium14Font>;
        },
      },
      {
        name: "status",
        label: "STATUS",
        canSort: true,
        sortCondition: 'string',
        renderer: (object) => {
          return <Medium14Font>{object.status}</Medium14Font>;
        },
      },
      {
        name: "reliefs",
        label: "RELIEFS",
        renderer: (object) => {
          if (
            (object.reliefs && object.reliefs.length) ||
            (object.proofs && object.proofs.length)
          ) {
            return (
              <ul>
                {[...object.reliefs, ...object.proofs].map((el, index) => (
                  <LiStyled key={index}>
                    <Medium14Font style={{ color: theme.colors.darkOpacity }}>
                      {el.name}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  const noRecordsApplicationPapers = (
    <NoRecords>
      <Bold18Font textColor={theme.colors.dark}>
        Your Application Papers will appear here
      </Bold18Font>
    </NoRecords>
  );

  const noRecordsApplicationAuthorities = (
    <NoRecords>
      <Bold18Font textColor={theme.colors.dark}>
        Your Application Authorities will appear here
      </Bold18Font>
    </NoRecords>
  );

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-6">
          <Bold30Font>{applicationObject.label}</Bold30Font>
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center mb-3">
          <ButtonNew
            clickHandler={onClickEditButtonHandler}
            primary
            // style={{ marginBottom: "4px" }}
          >
            Edit
          </ButtonNew>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <CasesMenu
            casesMenu={tabsPanelItems}
            noBadges
            noButton
            active={activeTab}
            activeFont={Bold18Font}
            font={Medium18Font}
            getType={onClickTabPanelHandler}
          />
        </div>
      </div>
      {activeTab !== 0 && !showApplicationAuthorityDetails ? (
        <div className="row mb-3">
          <div className="col">
            <TrialHubSearchSortBar
              searchBarPlaceholder="Search"
              searchBarValue={searchBarValue}
              onSearchBarChangeHandler={onChangeSearchBarValueHandler}
              clearSearchBarHandler={onClickClearSearchBarHandler}
            />
          </div>
        </div>
      ) : null}
      {/*{activeTab === 2 && !showApplicationAuthorityDetails ? (*/}
      {/*  <div className="row mb-3">*/}
      {/*    <div className="col-1 d-flex align-items-center">Filter by:</div>*/}
      {/*    <div className="col-11">*/}
      {/*      <div className="row">*/}
      {/*        <FlagRelevanceFilter*/}
      {/*          size={4}*/}
      {/*          caseObject={caseObject}*/}
      {/*          flagRelevance={flagRelevanceList}*/}
      {/*          onFlagRelevanceChange={onChangeFlagRelevanceHandler}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : null}*/}

      {/* Basic Info */}
      {activeTab === 0 ? (
        <div className="row">
          <div className="col">
            <Form>
              <div className="container-fluid" style={{ padding: "35px 60px" }}>
                <div className="row" style={{ marginBottom: "30px" }}>
                  <div className="col-12 col-lg-4 d-flex flex-column">
                    <Medium14Font>Title</Medium14Font>
                    <Bold18Font>{applicationObject.label}</Bold18Font>
                  </div>
                  <div className="col-12 col-lg-4 d-flex flex-column">
                    <Medium14Font>Date</Medium14Font>
                    {applicationObject.date ? (
                      <Bold18Font>
                        {moment(applicationObject.date).format("DD-MM-yyyy")}
                      </Bold18Font>
                    ) : (
                      <Medium18Font>N/A</Medium18Font>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "30px" }}>
                  <div className="col-12 col-lg-8 d-flex flex-column">
                    <Medium14Font>Reliefs and Proofs</Medium14Font>
                    {applicationObject.reliefs.length ||
                    applicationObject.proofs.length ? (
                      <ul className="d-flex flex-wrap">
                        {applicationObject.reliefs.map((relief) => (
                          <TagElementBlue key={relief.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {relief.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))}
                        {applicationObject.proofs.map((proof) => (
                          <TagElementBlue key={proof.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {proof.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))}
                      </ul>
                    ) : (
                      <Medium18Font>N/A</Medium18Font>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-4 d-flex flex-column">
                    <Medium14Font>Moving Party</Medium14Font>
                    <ul className="d-flex flex-wrap">
                      {applicationObject.moving_parties.length ? (
                        applicationObject.moving_parties.map((party) => (
                          <TagElementBlue key={party.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {party.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))
                      ) : (
                        <Medium18Font>N/A</Medium18Font>
                      )}
                    </ul>
                  </div>
                  <div className="col-12 col-lg-4 d-flex flex-column">
                    <Medium14Font>Respondent Parties</Medium14Font>
                    <ul className="d-flex flex-wrap">
                      {applicationObject.respondent_parties.length ? (
                        applicationObject.respondent_parties.map((party) => (
                          <TagElementBlue key={party.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {party.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))
                      ) : (
                        <Medium18Font>N/A</Medium18Font>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      ) : null}

      {/* Application Papers */}
      {activeTab === 1 ? (
        applicationObject.papers.length ? (
          <AnimatedDiv className="container-fluid">
            <Table
              settings={applicationPapersTable}
              data={applicationObject.papers.filter(filterFunction)}
            />
          </AnimatedDiv>
        ) : (
          noRecordsApplicationPapers
        )
      ) : null}

      {/* Application Authorities */}
      {activeTab === 2 ? (
        !showApplicationAuthorityDetails ? (
          applicationObject.authorities.length ? (
            <AnimatedDiv className="container-fluid">
              <Table
                settings={applicationAuthoritiesTable}
                data={setTagField(caseObject, applicationObject.authorities).filter(
                  filterFunction
                )}
              />
            </AnimatedDiv>
          ) : (
            noRecordsApplicationAuthorities
          )
        ) : (
          <Fragment>
            <AuthoritiesDetails
              applicationAuthorityObject={applicationAuthoritySelectedObject}
              noEdit
              withReliefs
              caseObject={caseObject}
              withTitle
            />
            <div className="row mb-4">
              <div className="col d-flex justify-content-end">
                <ButtonNew
                  primary
                  clickHandler={onClickBackToApplicationAuthoritiesTableHandler}
                >
                  Back
                </ButtonNew>
              </div>
            </div>
          </Fragment>
        )
      ) : null}
    </Fragment>
  );
};

export default withTheme(ApplicationDetails);
