import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Bold14Font, Medium14Font} from "../../FontsNewComponent/Fonts";
import {theme} from "../../../styled-components/Theme/Theme";
import {MODAL_DELETE_ELEMENT, SAVE_MODAL_DATA, SHOW_MODAL, SHOW_RIGHT_SIDE_BAR} from "../../../redux/types";
import {useDispatch} from "react-redux";
import Button from "../../buttons/Button";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";
import CasesSearchInput from "../CasesSearchFilterBar/CasesSearchInput";
import {useHistory} from "react-router-dom";
import OffencesForm from "./OffencesForm";
import useWindowSize from "../../../hooks/useWindowSize";
import LiStyled from "../../Table/Styled/LiStyled";
import {deleteCOA} from "../../../containers/Auth/auth";
import Table from "../../Table/Table";
import PageConfiguration from "../BreadCrumbs/PageConfiguration";
import {route} from "../../../routes";
import {SETTINGS} from "../BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

export default function AdminOffences(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {width} = useWindowSize();
  const {cOA} = props;
  const [searchBarValue, setSearchBarValue] = useState('');
  const [twoRowLayout, setTwoRowLayout] = useState(false);

  useEffect(() => {
    if (width < 1200) {
      setTwoRowLayout(true);
    } else {
      setTwoRowLayout(false);
    }
  }, [width]);
  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const filter = (v) => {
    if (searchBarValue) {
      return v.name.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
    }
    return true;
  };
  const causeOfActionSettings = {
    collapsed: true,
    actions: [
      {
        type: 'edit',
        callback: (object) => {
          addButtonFunction(object);
        }
      },
      {
        type: 'delete',
        callback: (object) => {
          dispatch({
            type: SAVE_MODAL_DATA, payload: "You won't be able to restore data", beforeCloseHandler: async () => {
              await deleteCOA(object.id, dispatch);
              changeObj(object, 'delete');
            }
          });
          dispatch({type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT});
        }
      },
    ],
    fields: [
      {
        name: 'name',
        label: 'OFFENCE',
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>
        },
      },
      {
        name: 'elements',
        label: 'ELEMENTS',
        renderer: (object) => {
          if (object.elements && object.elements.length) {
            return <ul>{object.elements.map((element) => {
              return <LiStyled key={element.id}><Medium14Font>{element.name}</Medium14Font></LiStyled>
            })}</ul>
          }
          return null;
        },
      },
    ],
  };
  const changeObj = (resp, type) => {
    if (resp) {
      let cOA_clone = [...cOA];
      if (type === 'add') {
        cOA_clone.push(resp);
      } else if (type === 'update') {
        cOA_clone.forEach((v, index) => {
          if (v.id === resp.id) {
            cOA_clone[index] = resp;
          }
        });
      } else if (type === 'delete') {
        cOA_clone = cOA_clone.filter((v) => {
          return v.id !== resp.id;
        })
      }
      props.updateCOA(cOA_clone);
    }
  };
  const addButtonFunction = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: <OffencesForm key={object.id} object={object} afterSubmit={changeObj}/>,
      title: object.name ? 'Edit: ' + object.name : 'Add New Offence',
    });
  };
  const pageConfig = [
    {
      path: route.admin.baseApp.settings.causesOfAction,
      title: "Settings",
      activeMenuItem: SETTINGS
    },
    {
      path: route.admin.baseApp.settings.offences,
      title: "Offences & Elements",
      activeMenuItem: SETTINGS
    },
  ];
  return <div className="p-3">
    <PageConfiguration configArray={pageConfig}/>
    <div className={`mb-4 ${twoRowLayout ? null : 'd-flex'} justify-content-between`}>
      <CasesSearchInput
        style={{
          width: twoRowLayout ? '100%'  : 'calc(100% - 220px)',
          paddingLeft: '20px',
          marginBottom: twoRowLayout ? "0.5rem" : "0",
          background: `url(${magnifierIcon}) no-repeat right 20px top 13px, ${theme.colors.white}`,
        }}
        type="text"
        value={searchBarValue}
        onChange={onSearchBarChangeHandler}
        placeholder={'Search'}
        name={'search_coa'}
      />
      <Button
        clickHandler={addButtonFunction}
        primary
      >
        <div className="d-flex align-items-center">
          <img src={plusIcon} alt="" className="img-fluid mr-2"/>
          <Bold14Font style={{color: "inherit"}}>
            Add Offence
          </Bold14Font>
        </div>
      </Button>
    </div>
    <div className="pl-3 pr-3">
    <Table settings={causeOfActionSettings} data={cOA.filter(filter)} addButtonLabel='Add Offence'
           addButtonFunction={addButtonFunction}/>
    </div>
  </div>
}
AdminOffences.propTypes = {
  cOA: PropTypes.array.isRequired,
  updateCOA: PropTypes.func.isRequired
};
