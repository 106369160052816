import styled, { css } from "styled-components/macro";
import rem from "../../../../utils/rem";
import BaseAppLayoutLeftSidebarMenuIconStyled from "../../../baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/styled/BaseAppLayoutLeftSidebarMenuIconStyled";
import { Bold18Font } from "../../../FontsNewComponent/Fonts";

const hoverAndActiveState = css`
  border: 1px solid rgba(220, 220, 230, 0.2);
  background-color: rgba(250, 250, 252, 0.1);

  ${BaseAppLayoutLeftSidebarMenuIconStyled} {
    color: ${({ theme }) => theme.colors.white};
  }

  ${Bold18Font} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const LeftSideBarMenuButtonStyled = styled.button.attrs({ className: "btn" })`
  width: ${rem(`245px`)};
  height: ${rem(`60px`)};
  border-radius: ${rem(`20px`)};
  padding: ${rem(`14px 20px`)};
  display: flex;
  justify-content: space-between;

  ${({ isActive }) => (isActive ? hoverAndActiveState : null)};

  :hover {
    ${hoverAndActiveState};
`;

export default LeftSideBarMenuButtonStyled;
