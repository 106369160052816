import React, { Fragment, useEffect, useState } from "react";
import {
  Switch,
  Route,
  useParams,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";

import { route } from "../../../routes";
import { getCaseById } from "../../Auth/auth";

import Evidence from "./Evidence";
import Authorities from "./Evidence";
import Panel from "../../../components/Panel";
import TrialHubSearchSortBar from "../../../components/baseAppComponents/TrialHubSearchSortBar";
import TrialHubFilters from "../../../components/baseAppComponents/TrialHubFilters";

import evidenceIcon from "../../../assets/img3/svg/evidence-icon.svg";
import witnessIcon from "../../../assets/img3/svg/witness-icon.svg";
import xExamIcon from "../../../assets/img3/svg/x-exam-icon.svg";
import authoritiesIcon from "../../../assets/img3/svg/authorities-icon.svg";

import {
  Bold14Font,
  Bold30Font,
  Medium30Font,
} from "../../../components/FontsNewComponent/Fonts";

import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import EvidenceDetails from "./Evidence/EvidenceDetails";
import AddEditEvidence from "./Evidence/AddEditEvidence";
import moment from "moment";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import bulkIcon from "../../../assets/img3/svg/bulk-icon.svg";
import EvidenceImport from "./Evidence/EvidenceImport";
import Witnesses from "./Witnesses";
import AddEditWitness from "./Witnesses/AddEditWitness";
import WitnessDetails from "./Witnesses/WitnessDetails";
import AddEditAuthorities from "./Authorities/AddEditAuthorities";
import flagRelevanceCheck from "../../../components/FlagRelevanceFilter/flagRelevanceCheck";
import AuthoritiesDetails from "./Authorities/AuthoritiesDetails";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  DATA_ADD_TO_STORE,
  DATA_REMOVE_FROM_STORE,
} from "../../../redux/types";
import { formFlowType } from "../../../utils/constants";
import PageTitle from "../../../components/PageTitle";

const dateFormatToCompare = "YYYYMMDD";

const dateToInt = (date) => {
  if (date !== null) {
    return parseInt(moment(date).format(dateFormatToCompare));
  }
  return 0;
};

const TrialHub = ({ theme }) => {
  const [isTrialHubLoading, setIsTrialHubLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [caseObject, setCaseObject] = useState({});
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectedSortFunction, setSelectedSortFunction] = useState(2);
  const { width } = useWindowSize();

  // DatePicker
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [evidence, setEvidence] = useState([]);
  const [authoritiesList, setAuthoritiesList] = useState([]);
  const [flagRelevance, setFlagRelevance] = useState([]);
  const [witnessFilterOptions, setWitnessFilterOptions] = useState([
    {
      value: 0,
      label: "All Witnesses",
    },
  ]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [
    witnessFilterSelectedOption,
    setWitnessFilterSelectedOption,
  ] = useState(witnessFilterOptions[0]);
  const [themesFilterOptions, setThemesFilterOptions] = useState([
    {
      value: 0,
      label: "Themes",
    },
  ]);
  const [themesFilterSelectedOption, setThemesFilterSelectOption] = useState(
    themesFilterOptions[0]
  );
  const [subThemesFilterOptions, setSubThemesFilterOptions] = useState([
    {
      value: 0,
      label: "Subtheme",
    },
  ]);
  const [
    subThemesFilterSelectedOption,
    setSubThemesFilterSelectOption,
  ] = useState(subThemesFilterOptions[0]);

  // Witnesses states
  const [witnessesList, setWitnessesList] = useState([]);

  const { id } = useParams();
  const { path, url } = useRouteMatch();
  // show "header" if routes match
  const showTopPart = useRouteMatch(`${path}/:dynamicPart`);
  // redirect to evidence if user try access .../trialhub
  const needRedirectToEvidence = useRouteMatch(`${path}`).isExact;

  const history = useHistory();
  const dispatch = useDispatch();
  const isEvidencesPage = useRouteMatch(`${path}/evidence`);
  const isWitnessesPage = useRouteMatch(`${path}/witness`);
  const isXExamPage = useRouteMatch(`${path}/x-exam`);
  const isAuthoritiesPage = useRouteMatch(`${path}/authorities`);

  // Reset filters on panel change
  useEffect(() => {
    setSearchBarValue("");
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
    setFlagRelevance([]);
    setWitnessFilterSelectedOption(witnessFilterOptions[0]);
  }, [activeItem]);

  const sortAlphabetical = (a, b, order = "ASC") => {
    let item_a = Object.assign({}, order === "DESC" ? b : a);
    let item_b = Object.assign({}, order === "DESC" ? a : b);
    // if (!item_a.label) {
    //   item_a.label = "New Evidence";
    // }
    // if (!item_b.label) {
    //   item_b.label = "New Evidence";
    // }
    if (item_a.label && item_b.label) {
      item_a.label =
        typeof item_a.label === "string"
          ? item_a.label.toLowerCase()
          : item_a.label.toString();
      item_b.label =
        typeof item_b.label === "string"
          ? item_b.label.toLowerCase()
          : item_b.label.toString();
    } else if (item_a.title && item_b.title) {
      item_a.title =
        typeof item_a.title === "string"
          ? item_a.title.toLowerCase()
          : item_a.title.toString();
      item_b.title =
        typeof item_b.title === "string"
          ? item_b.title.toLowerCase()
          : item_b.title.toString();
    }

    return item_b?.label
      ? item_a.label.localeCompare(item_b.label, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      : item_a.title.localeCompare(item_b.title, undefined, {
          numeric: true,
          sensitivity: "base",
        });
  };

  const sortByIdAscending = (itemA, itemB) => {
    return itemA.id - itemB.id;
  };

  const sortByIdDescending = (itemA, itemB) => {
    return itemB.id - itemA.id;
  };

  const setSortFunction = (a, b) => {
    switch (selectedSortFunction) {
      // from newest to oldest id
      case 1:
        return sortByIdAscending(a, b);
      // from oldest to newest id
      case 2:
        return sortByIdDescending(a, b);
      //alphabetically a-Z
      case 3:
        return sortAlphabetical(a, b);
      // alphabetically from Z to A
      case 4:
        return sortAlphabetical(a, b, "DESC");
      default:
        break;
    }
  };

  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };

  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };
  const searchWitnessField = (fieldValue) => {
    return (
      fieldValue.filter((item) => {
        if (witnessFilterSelectedOption.value === 0) {
          return true;
        }
        return item.name === witnessFilterSelectedOption.label;
      }).length > 0
    );
  };

  const searchThemeField = (themeField) => {
    return (
      themeField.filter((item) => {
        return item.theme.id === themesFilterSelectedOption.value;
      }).length > 0
    );
  };

  // Filter by sub-theme id
  const searchSubThemeField = (fieldValue) => {
    return fieldValue.name === subThemesFilterSelectedOption.label;
  };

  const filterEvidences = (value) => {
    let check = true;

    if (searchBarValue && check) {
      // search in evidence label and objectives
      if (value.label) {
        check =
          (value.label && searchField(value.label)) ||
          (value.objectives &&
            value.objectives.length &&
            searchField(value.objectives));
        // search in authorities title
      } else if (value.title) {
        check = value.title && searchField(value.title);
      }
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }
    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }

    // Filter by witness name
    if (witnessFilterSelectedOption.value !== 0 && check) {
      check =
        value.witnesses &&
        value.witnesses.length &&
        searchWitnessField(value.witnesses);
    }

    return check;
  };

  const filterWitnesses = (value) => {
    let check = true;

    if (searchBarValue && check) {
      check = value.name && searchField(value.name);
    }

    if (check && flagRelevance.length) {
      check = flagRelevanceCheck(value, flagRelevance);
    }

    return check;
  };

  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const clearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onThemesFilterChangeHandler = (selectedOption) => {
    setThemesFilterSelectOption(selectedOption);
  };
  const onFlagRelevanceChange = (options) => {
    setFlagRelevance(options);
  };

  const onSubThemesFilterChangeHandler = (selectedOption) => {
    setSubThemesFilterSelectOption(selectedOption);
  };

  const onWitnessFilterChangeHandler = (selectedOption) => {
    setWitnessFilterSelectedOption(selectedOption);
  };

  const panelItems = [
    {
      title: "Evidence",
      icon: evidenceIcon,
      action: () => history.push(`${url}/evidence`),
    },
    {
      title: "Witness",
      icon: witnessIcon,
      action: () => history.push(`${url}/witness`),
    },
    {
      title: "X-Exam",
      icon: xExamIcon,
      action: () => history.push(`${url}/x-exam`),
    },
    {
      title: "Authorities",
      icon: authoritiesIcon,
      action: () => history.push(`${url}/authorities`),
    },
  ];

  useEffect(() => {
    // Redirect to Evidence if get to /trialhub
    const getData = async () => {
      const caseResp = await getCaseById(id, dispatch);
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
      // const evidenceResp = await getEvidenceList(id, dispatch)
      if (caseResp) {
        setCaseObject(caseResp);
        // setEvidence(evidenceResp)
        setIsTrialHubLoading(false);
        return true;
      }
      return false;
    };
    const resp = getData();
    if (resp && needRedirectToEvidence) {
      history.replace(`${url}/evidence`);
    }
  }, []);

  const onChangePanelItemHandler = (index) => {
    setActiveItem(index);
    panelItems[index].action();
  };

  const addNewEvidence = async () => {
    history.push(`${url}/evidence/create`);
  };

  const addNewWitness = async () => {
    history.push(`${url}/witness/create`);
  };

  const addNewXExamWitness = async () => {
    history.push(`${url}/witness/create`);
  };

  const addNewAuthority = async () => {
    history.push(`${url}/authorities/create`);
  };

  const addNewElementHandler = async () => {
    if (isEvidencesPage && isEvidencesPage.isExact) {
      return await addNewEvidence();
    }
    if (isWitnessesPage && isWitnessesPage.isExact) {
      return await addNewWitness();
    }
    if (isXExamPage && isXExamPage.isExact) {
      return await addNewXExamWitness();
    }
    if (isAuthoritiesPage && isAuthoritiesPage.isExact) {
      return await addNewAuthority();
    }
  };

  if (isTrialHubLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  return (
    <Fragment>
      {showTopPart.isExact ? (
        <Fragment>
          <PageTitle pageLabel="Trial hub" />
          <div className="container-fluid">
            <Panel
              items={panelItems}
              onChange={onChangePanelItemHandler}
              activeItem={activeItem}
            />
          </div>
          <div className="row">
            <div className="col d-flex flex-wrap justify-content-between">
              <TrialHubSearchSortBar
                searchBarValue={searchBarValue}
                onSearchBarChangeHandler={onSearchBarChangeHandler}
                clearSearchBarHandler={clearSearchBarHandler}
                searchBarInputName="search"
                searchBarPlaceholder="Search"
                hideSorting
                onFilterDatesAlphabetChangeHandler={
                  onFilterDatesAlphabetChangeHandler
                }
                selectedSortOption={selectedSortFunction - 1}
                buttons={
                  <Fragment>
                    <ButtonNew
                      className={
                        width < 368
                          ? "mb-2"
                          : (isEvidencesPage && isEvidencesPage.isExact) ||
                            (isAuthoritiesPage && isAuthoritiesPage.isExact)
                          ? "mr-2"
                          : ""
                      }
                      loading={creating}
                      disabled={creating}
                      clickHandler={addNewElementHandler}
                      primary
                    >
                      <div className="d-flex align-items-center">
                        <img src={plusIcon} alt="" className="img-fluid mr-2" />
                        <Bold14Font style={{ color: "inherit" }}>
                          {isEvidencesPage && isEvidencesPage.isExact
                            ? "Add Evidence"
                            : null}
                          {isWitnessesPage && isWitnessesPage.isExact
                            ? "Add Witness"
                            : null}
                          {isXExamPage && isXExamPage.isExact
                            ? "Add Witness"
                            : null}
                          {isAuthoritiesPage && isAuthoritiesPage.isExact
                            ? "Add Authority"
                            : null}
                        </Bold14Font>
                      </div>
                    </ButtonNew>
                    {(isEvidencesPage && isEvidencesPage.isExact) ||
                    (isAuthoritiesPage && isAuthoritiesPage.isExact) ? (
                      <ButtonNew
                        secondary
                        clickHandler={() => {
                          history.push(
                            isEvidencesPage && isEvidencesPage.isExact
                              ? `${url}/evidence/import`
                              : `${url}/authorities/import`
                          );
                        }}
                        style={{ marginRight: width < 368 ? "0" : "-15px" }}
                      >
                        <Fragment>
                          <img
                            src={bulkIcon}
                            alt=""
                            style={{ marginRight: "10px" }}
                          />
                          <Bold14Font textColor={theme.colors.blue}>
                            Bulk Import
                          </Bold14Font>
                        </Fragment>
                      </ButtonNew>
                    ) : null}
                  </Fragment>
                }
              />
            </div>
          </div>
          <TrialHubFilters
            evidence={evidence}
            caseObject={caseObject}
            style={{ marginBottom: "60px" }}
            // themes filter settings
            themesFilterOptions={themesFilterOptions}
            setThemesFilterOptions={setThemesFilterOptions}
            onThemesFilterChange={onThemesFilterChangeHandler}
            themesFilterSelectOption={themesFilterSelectedOption}
            onFlagRelevanceChange={onFlagRelevanceChange}
            flagRelevance={flagRelevance}
            // subTheme filter settings
            subThemesFilterOptions={subThemesFilterOptions}
            setSubThemesFilterOptions={setSubThemesFilterOptions}
            onSubThemesFilterChange={onSubThemesFilterChangeHandler}
            subThemesFilterSelectOption={subThemesFilterSelectedOption}
            // Date picker settings
            datePickerStartDate={datePickerStartDate}
            setDatePickerStartDate={setDatePickerStartDate}
            datePickerEndDate={datePickerEndDate}
            setDatePickerEndDate={setDatePickerEndDate}
            // Witness filter settings
            witnessFilterOptions={witnessFilterOptions}
            setWitnessFilterOptions={setWitnessFilterOptions}
            onWitnessFilterChange={onWitnessFilterChangeHandler}
            witnessFilterSelectedOption={witnessFilterSelectedOption}
          />
        </Fragment>
      ) : null}

      <Switch>
        {/* Evidence routes */}
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.evidence
              : route.admin.baseApp.trialHub.evidence
          }`}
        >
          <Evidence
            key={1}
            // caseId={id}
            caseObject={caseObject}
            evidence={evidence.filter(filterEvidences)}
            setEvidence={setEvidence}
            setIsTrialHubLoading={setIsTrialHubLoading}
          />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialHub.evidenceDetails
              : route.admin.baseApp.trialHub.evidenceDetails
          }`}
        >
          <EvidenceDetails />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialHub.evidenceImport
              : route.admin.baseApp.trialHub.evidenceImport
          }`}
        >
          <EvidenceImport />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialHub.addEditEvidence
              : route.admin.baseApp.trialHub.addEditEvidence
          }`}
        >
          <AddEditEvidence setActiveItem={setActiveItem} />
        </Route>
        <Route
          exact
          path={`${
            !is_admin
              ? route.baseApp.trialHub.createEvidence
              : route.admin.baseApp.trialHub.createEvidence
          }`}
        >
          <AddEditEvidence
            setActiveItem={setActiveItem}
            flow={formFlowType.CREATE}
          />
        </Route>

        {/* Witness routes */}
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.witness
              : route.admin.baseApp.trialHub.witness
          }`}
        >
          <Witnesses
            setActiveItem={setActiveItem}
            caseObject={caseObject}
            witnessesList={witnessesList.filter(filterWitnesses)}
            setWitnessesList={setWitnessesList}
          />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.witnessDetails
              : route.admin.baseApp.trialHub.witnessDetails
          }`}
        >
          <WitnessDetails />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.addEditWitness
              : route.admin.baseApp.trialHub.addEditWitness
          }`}
        >
          <AddEditWitness caseObject={caseObject} />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.witnessCreate
              : route.admin.baseApp.trialHub.witnessCreate
          }`}
        >
          <AddEditWitness caseObject={caseObject} />
        </Route>

        {/* X-Exam routes */}
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.xExam
              : route.admin.baseApp.trialHub.xExam
          }`}
        >
          <Witnesses
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            caseObject={caseObject}
            witnessesList={witnessesList.filter(filterWitnesses)}
            setWitnessesList={setWitnessesList}
          />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.xExamDetails
              : route.admin.baseApp.trialHub.xExamDetails
          }`}
        >
          <WitnessDetails />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.addEditXExam
              : route.admin.baseApp.trialHub.addEditXExam
          }`}
        >
          <AddEditWitness caseObject={caseObject} />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.xExamCreate
              : route.admin.baseApp.trialHub.xExamCreate
          }`}
        >
          <AddEditWitness caseObject={caseObject} />
        </Route>
        {/* Authorities routes */}
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.authorities
              : route.admin.baseApp.trialHub.authorities
          }`}
        >
          <Authorities
            key={2}
            caseObject={caseObject}
            evidence={authoritiesList
              .filter(filterEvidences)}
            setEvidence={setAuthoritiesList}
            setIsTrialHubLoading={setIsTrialHubLoading}
            isAuthoritiesFlowFalse={
              !(isAuthoritiesPage && isAuthoritiesPage.isExact)
            }
            setActiveItem={setActiveItem}
          />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.createAuthorities
              : route.admin.baseApp.trialHub.createAuthorities
          }`}
        >
          <AddEditAuthorities
            caseObject={caseObject}
            flow={formFlowType.CREATE}
          />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.addEditAuthorities
              : route.admin.baseApp.trialHub.addEditAuthorities
          }`}
        >
          <AddEditAuthorities caseObject={caseObject} />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.authoritiesDetails
              : route.admin.baseApp.trialHub.authoritiesDetails
          }`}
        >
          <AuthoritiesDetails caseObject={caseObject} />
        </Route>
        <Route
          exact={true}
          path={`${
            !is_admin
              ? route.baseApp.trialHub.authoritiesBulkImport
              : route.admin.baseApp.trialHub.authoritiesBulkImport
          }`}
        >
          <EvidenceImport />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default withTheme(TrialHub);
