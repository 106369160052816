import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageFooterStyled = styled.footer`
  width: 100%;
  position: relative;
  margin-top: ${rem(`-296px`)};
  padding: ${rem(`450px 0 10px 0`)};
  background: ${({ theme }) => theme.colors.dark};

  .footer__border-divider {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayOpacity};
  }
`;

export default LandingPageFooterStyled;
