import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_RIGHT_SIDE_BAR } from "../../../../redux/types";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../../styled-components/Theme/Theme";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  generateDocCoreBook,
  getCaseById,
  getEvidenceList,
} from "../../../Auth/auth";
import NoRecords from "../../../../components/NoRecords";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
  Medium30Font,
} from "../../../../components/FontsNewComponent/Fonts";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import { format, parseISO } from "date-fns";
import Table from "../../../../components/Table";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "../../../../components/inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import SelectAnotherTry from "../../../../components/Select/SelectAnotherTry";
import moment from "moment";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import addPlusIcon from "../../../../assets/img3/svg/add-plus-icon.svg";
import AddToCoreForm from "../../../../components/forms/TrialProofsForm/AddToCoreForm";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import { pdf } from "@react-pdf/renderer";
import { PdfCoreBook } from "../../../../components/Pdf/PdfCoreBook";
import { saveAs } from "file-saver";
import EvidencePreview from "../../../../components/forms/EvidenceForms/EvidencePreview";

const dateFormat = "dd-MM-yyyy";

const dateFormatToCompare = "YYYYMMDD";

const dateToInt = (date) => {
  if (date !== null) {
    return parseInt(moment(date).format(dateFormatToCompare));
  }
  return 0;
};

const CoreBook = () => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [dataToPdf, setDataToPdf] = useState({});
  const [exporting, setExporting] = useState(false);
  const [blockBlurEvent, setBlockBlurEvent] = useState(false);
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const { id } = useParams();
  const history = useHistory();

  // data states
  const [searchBarValue, setSearchBarValue] = useState("");
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [filterTypeOptionsList, setFilterTypeOptionsList] = useState([]);
  const [selectedFilterTypeOption, setSelectedFilterTypeOption] = useState(
    null
  );
  const [caseObject, setCaseObject] = useState(null);
  const [allEvidenceList, setAllEvidenceList] = useState([]);
  const [evidenceList, setEvidenceList] = useState([]);

  // get data

  const createFilterTypeOptionsList = (list) => {
    const uniqueTypeOptionsList = [];
    list.forEach((el) => {
      if (
        el.type &&
        uniqueTypeOptionsList.findIndex((item) => item.value === el.type) === -1
      ) {
        uniqueTypeOptionsList.push({ label: el.type, value: el.type });
      }
    });
    setFilterTypeOptionsList([
      { label: "All", value: null },
      ...uniqueTypeOptionsList,
    ]);
  };

  const saveData = (resp) => {
    setAllEvidenceList(resp);
    const filteredResp = resp
      .filter(filterCoreBookEvidence)
      .map((el, idx) => ({ ...el, numId: idx + 1 }));
    setEvidenceList(filteredResp);
    createFilterTypeOptionsList(filteredResp);
  };

  const getDataFromAPI = async () => {
    const respCaseObject = await getCaseById(id, dispatch);
    if (is_admin && !respCaseObject) {
      history.push("/admin/all-cases");
    }
    const respEvidenceList = await getEvidenceList(id, dispatch);
    if (respCaseObject && respEvidenceList) {
      setCaseObject(respCaseObject);
      saveData(respEvidenceList);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      getDataFromAPI();
    }
  }, []);
  const filterCoreBookEvidence = (el) => el.core_book === 1;

  const filterTableRows = (value) => {
    let check = true;

    if (check && searchBarValue !== "") {
      check =
        value.label.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }

    // Filter by type
    if (check && selectedFilterTypeOption && selectedFilterTypeOption.value) {
      check = value.type === selectedFilterTypeOption.value;
    }

    return check;
  };

  // button handlers

  const generatePdfData = (evidenceList) => {
    const data = evidenceList.filter(filterTableRows);
    return {
      headers: [
        {
          name: "id",
          label: "ID",
          width: "5%",
        },
        {
          name: "label",
          label: "Label",
          width: "50%",
        },
        {
          name: "type",
          label: "Type",
          width: "15%",
        },
        {
          name: "date",
          label: "Date",
          width: "15%",
        },
        {
          name: "reference",
          label: "Reference",
          width: "15%",
        },
      ],
      rows: data.map((v) => {
        return {
          id: v.numId,
          reference: v.reference,
          label: v.label,
          type: v.subtype ? [{ name: v.type, value: [v.subtype] }] : v.type,
          date: v.date ? format(parseISO(v.date), "dd-MM-yyyy") : "",
        };
      }),
    };
  };
  const onClickClearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onClickClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  const onClickAddToCoreHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AddToCoreForm
          key={caseObject.id}
          routePaths={{ caseId: id }}
          allEvidenceList={allEvidenceList}
          setAllEvidenceList={updateEvidenceTableData}
          filterFunction={filterCoreBookEvidence}
        />
      ),
      title: "Add to Core",
    });
  };

  // input handlers

  const onChangeSearchBarValueHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onChangeFilterTypeHandler = (selectedOption) => {
    setSelectedFilterTypeOption(selectedOption);
  };

  const updateEvidenceTableData = (resp) => {
    setAllEvidenceList(resp);
    saveData(resp);
  };
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const blob = await pdf(
              <PdfCoreBook
                document_type={"Core Book"}
                caseName={caseObject.label}
                data={dataToPdf}
              />
            ).toBlob();
            saveAs(blob, `${caseObject.label} core book.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({ as_pdf }) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const data_to_doc = {
              data: dataToPdf,
              case_object: caseObject,
            };
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocCoreBook(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            setExporting(false);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${caseObject.label} core book.${as_pdf ? "pdf" : "docx"}`
            );
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? "mr-2" : ""}`}
        primary
      >
        Export to {as_pdf ? "PDF" : "Docx"}
      </ButtonNew>
    );
  };
  useEffect(() => {
    setDataToPdf(generatePdfData(evidenceList));
  }, [
    evidenceList,
    searchBarValue,
    selectedFilterTypeOption,
    datePickerStartDate,
    datePickerEndDate,
  ]);
  const ExportToPdf = useMemo(() => {
    return <DocExport as_pdf={true} />;
  }, [dataToPdf, caseObject, exporting]);
  const ExportToDoc = useMemo(() => {
    return <DocExport as_pdf={false} />;
  }, [dataToPdf, caseObject, exporting]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  // settings

  const evidenceTableSettings = {
    collapsed: false,
    canSort: true,
    actions: [],
    clickAction: (object) => {
      if (object.file_id) {
        dispatch({
          type: SHOW_RIGHT_SIDE_BAR,
          url: history.location.pathname,
          content: (
            <EvidencePreview
              key={object.id}
              caseId={id}
              evidence={object}
              goToDetails={true}
              detailsLink={
                !is_admin
                  ? `/app/cases/${id}/documents/${object.file_id}`
                  : `/admin/all-cases/${id}/documents/${object.file_id}`
              }
            />
          ),
          title: "Preview",
        });
      } else {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/evidence/${object.id}`
            : `/admin/all-cases/${id}/trial-hub/evidence/${object.id}`
        );
      }
    },
    fields: [
      {
        name: "numId",
        label: "ID",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.numId}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "LABEL",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "type",
        label: "TYPE",
        canSort: true,
        renderer: (object) => {
          return (
            <div className="d-flex flex-column">
              <Medium14Font>{object.type}</Medium14Font>
              {object.subtype ? (
                <LiStyled style={{ paddingLeft: "24px" }}>
                  <Medium14Font>{object.subtype}</Medium14Font>
                </LiStyled>
              ) : null}
            </div>
          );
        },
      },
      {
        name: "date",
        label: "DATE",
        canSort: true,
        renderer: (object) => {
          return (
            <Medium14Font as="div" style={{ whiteSpace: "nowrap" }}>
              {object.date ? format(parseISO(object.date), "dd-MM-yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "reference",
        label: "REF",
        canSort: true,
        renderer: (object) => {
          return <Medium14Font>{object.reference}</Medium14Font>;
        },
      },
    ],
  };

  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs/court-documents/trial/core-book`
        : `/admin/all-cases/${id}/trial-proofs/court-documents/trial/core-book`,
      title: "Court Documents - Trial - Core Book",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-7">
          <Bold30Font style={{ marginRight: "16px" }}>
            {caseObject.label}
          </Bold30Font>
          <Medium30Font>Core Book</Medium30Font>
        </div>
        <div className="col-12 col-sm-5 d-flex justify-content-end align-items-center mb-3">
          {ExportToDoc}
          {ExportToPdf}
          {/*<Button*/}
          {/*  clickHandler={onClickExportToPDFHandler}*/}
          {/*  disabled={isSubmitting}*/}
          {/*  loading={isSubmitting}*/}
          {/*  primary*/}
          {/*>*/}
          {/*  <Bold14Font textColor={theme.colors.white}>*/}
          {/*    Export to PDF*/}
          {/*  </Bold14Font>*/}
          {/*</Button>*/}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarInputName="searchbar"
            searchBarPlaceholder="Search"
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onChangeSearchBarValueHandler}
            clearSearchBarHandler={onClickClearSearchBarHandler}
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">Filter by:</div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 mb-2">
          <CustomReactDatepicker
            // selected={datePickerStartDate}
            customInput={
              <CustomDatepickerRangeDivInput
                startDate={
                  datePickerStartDate
                    ? format(datePickerStartDate, dateFormat)
                    : ""
                }
                endDate={
                  datePickerEndDate ? format(datePickerEndDate, dateFormat) : ""
                }
                clearDatesHandler={onClickClearDatesHandler}
              />
            }
            startDate={datePickerStartDate}
            endDate={datePickerEndDate}
            onChange={onChangeDatesHandler}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            selectsRange
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-2">
          <SelectAnotherTry
            style={{ padding: "0" }}
            placeholder="Type"
            options={filterTypeOptionsList}
            onChange={onChangeFilterTypeHandler}
          />
        </div>
        <div className="col col-md-4 mb-2 d-flex justify-content-end">
          <ButtonNew secondary clickHandler={onClickAddToCoreHandler}>
            <div className="d-flex align-items-center">
              {/* TODO: Add appropriate img */}
              <img src={addPlusIcon} alt="" className="img-fluid mr-2" />
              <Bold14Font style={{ color: "inherit" }}>Add to Core</Bold14Font>
            </div>
          </ButtonNew>
        </div>
      </div>
      {evidenceList.length ? (
        evidenceList.filter(filterTableRows).length ? (
          <AnimatedDiv className="container-fluid">
            <Table
              data={evidenceList.filter(filterTableRows)}
              settings={evidenceTableSettings}
            />
          </AnimatedDiv>
        ) : (
          <NoRecords>
            <Bold14Font>Nothing found.</Bold14Font>
          </NoRecords>
        )
      ) : (
        <NoRecords>
          <Bold14Font>Your Core Evidence will appear here</Bold14Font>
        </NoRecords>
      )}
    </Fragment>
  );
};

export default CoreBook;
