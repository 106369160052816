import React, { Fragment, useState } from "react";
import Button from "../../buttons/Button/Button";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch } from "react-redux";
import InputNew from "../../InputNew/InputNew";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import RadioButtonGroupRounded from "../../buttons/RadioButtonGroupRounded/RadioButtonGroupRounded";
import SelectNew from "../../Select/SelectNew";
import Label from "../AddCardForm/Label";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

// authority types constants:
const LEGISLATION = "Legislation";
const CASE = "Case";
const COURT_RULE = "Court Rule";
const ACADEMIC_TEXT = "Academic Text";
const OTHER = "Other";

const types = [
  { value: LEGISLATION, label: LEGISLATION },
  { value: CASE, label: CASE },
  { value: COURT_RULE, label: COURT_RULE },
  { value: ACADEMIC_TEXT, label: ACADEMIC_TEXT },
  { value: OTHER, label: OTHER },
];

const DOMESTIC = "Domestic";
const EUROPEAN_UNION = "European Union";
const INTERNATIONAL = "International";

const jurisdictions = [
  { value: DOMESTIC, label: DOMESTIC },
  { value: INTERNATIONAL, label: INTERNATIONAL },
  { value: EUROPEAN_UNION, label: EUROPEAN_UNION },
];

const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const MIXED = "Mixed";

const status = [
  { value: SUPPORTING, label: SUPPORTING },
  { value: OPPOSING, label: OPPOSING },
  { value: MIXED, label: MIXED },
];

const AuthorityFurtherDetailsForm = ({
  authorityObject,
  setAuthorityObject,
}) => {
  const [object_, setObject] = useState(authorityObject);
  const handleSubmit = () => {
    setAuthorityObject({
      type: object_.type,
      jurisdiction: object_.jurisdiction,
      citation: object_.citation,
      key_sections: object_.key_sections,
      status: object_.status,
    });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const dispatch = useDispatch();

  const onChangeInput = (event) => {
    event.persist();
    setObject((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const setAuthorityType = (select) => {
    setObject((prevState) => ({
      ...prevState,
      type: select.value,
    }));
  };

  const setJurisdiction = (select) => {
    setObject((prevState) => ({
      ...prevState,
      jurisdiction: select.value,
    }));
  };

  const setAuthorityStatus = (select) => {
    setObject((prevState) => ({
      ...prevState,
      status: select.value,
    }));
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <Label name={"Authority Type"} />
          <SelectAnotherTry
            options={types}
            value={types.find((v) => v.value === object_.type)}
            onChange={setAuthorityType}
            placeholder={"Select Type"}
          />
        </div>
        <div className="form-group">
          <Label name={"Jurisdiction"} />
          <SelectAnotherTry
            options={jurisdictions}
            value={jurisdictions.find((v) => v.value === object_.jurisdiction)}
            onChange={setJurisdiction}
            placeholder={"Select Jurisdiction"}
          />
        </div>
        <div className="form-group">
          <InputNew
            name={"citation"}
            placeholder={"Citation"}
            label={"Citation"}
            value={object_.citation}
            onChangeHandler={onChangeInput}
          />
        </div>
        <div className="form-group">
          <InputNew
            name={"key_sections"}
            placeholder={"Enter Sections"}
            label={"Sections"}
            value={object_.key_sections}
            onChangeHandler={onChangeInput}
          />
        </div>
        <div className="form-group">
          <Label name={"Status"} />
          <SelectAnotherTry
            options={status}
            value={status.find((v) => v.value === object_.status)}
            onChange={setAuthorityStatus}
            placeholder={"Select Type"}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <Button clickHandler={handleSubmit} wide primary>
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AuthorityFurtherDetailsForm;
