import React from "react";
import PropTypes from "prop-types";

import ButtonNew from "../../buttons/Button/ButtonNew";
import CustomDatepickerRangeDivInputStyled from "./styled/CustomDatePickerDivInputStyled";

import { Medium14Font } from "../../FontsNewComponent/Fonts";

import deleteObjectiveIcon from "../../../assets/img3/svg/delete-objective-input-icon.png";

const CustomDatepickerRangeDivInput = ({
  startDate,
  endDate,
  clearDatesHandler,
  ...props
}) => {
  return (
    <CustomDatepickerRangeDivInputStyled {...props}>
      <Medium14Font as="div">
        {startDate ? startDate : "Start date"}
      </Medium14Font>
      <div className="mx-1">-</div>
      <Medium14Font as="div">{endDate ? endDate : "End date"}</Medium14Font>
      {clearDatesHandler && (startDate || endDate) ? (
        <ButtonNew
          tertiary
          clickHandler={clearDatesHandler}
          style={{
            padding: 0,
            position: "absolute",
            right: "0",
            bottom: "8px",
          }}
        >
          <img src={deleteObjectiveIcon} alt="" />
        </ButtonNew>
      ) : null}
    </CustomDatepickerRangeDivInputStyled>
  );
};

CustomDatepickerRangeDivInput.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  clearDatesHandler: PropTypes.func,
};

export default CustomDatepickerRangeDivInput;
