import styled from "styled-components/macro";
import { Bold90Font } from "../../FontsNewComponent/Fonts";

const StubPageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.dark};

  @media (max-width: 575.98px) {
    ${Bold90Font} {
      font-size: 4.025rem;
    }
  }
`;

export default StubPageStyled;
