import styled from "styled-components/macro";

const BaseAppLayoutStyled = styled.div`
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  //display: flex;
  height: 100%;
  //position: relative;
  @media (max-width: 1199.98px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export default BaseAppLayoutStyled;
