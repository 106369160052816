import React from "react";
import ListAddButtonStyled from "./Styled/ListAddButtonStyled";
import PropTypes from "prop-types";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import PlusStyled from "./Styled/PlusStyled";

export default function ListAddButton(props) {
  let { label } = props;
  return (
    <ListAddButtonStyled {...props} onClick={props.clickHandler}>
      <PlusStyled className="list-add-button d-flex justify-content-center align-items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
        >
          <g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="2"
                  d="M5.997 1v10"
                />
              </g>
              <g>
                <path
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="2"
                  d="M11 6.25H1"
                />
              </g>
            </g>
          </g>
        </svg>
      </PlusStyled>
      <Bold14Font style={{ color: "inherit", lineHeight: '24px' }}>{label}</Bold14Font>
    </ListAddButtonStyled>
  );
}
ListAddButton.propTypes = {
  label: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
