import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const FeedbackFormStyled = styled.form`
  // TODO: Need re-implement this?
  width: ${rem("570px")};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
`;

export default FeedbackFormStyled;
