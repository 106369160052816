import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import { useParams, useHistory } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {
  createNewAuthority,
  deleteAuthorityById,
  deleteEvidence,
  getAuthorityById,
  updateApplicationById,
  updateApplicationPaperById,
  updateAuthorityById,
  uploadAuthorityFile,
} from "../../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import Button from "../../../../components/buttons/Button/Button";
import Form from "../../../../components/forms/Form";
import InputNew from "../../../../components/InputNew/InputNew";
import RadioButtonGroup from "../../../../components/buttons/RadioButtonGroup";
import UploadSingleFileInput from "../../../../components/inputs/UploadSingleFileInput";
import AssociationTable, {
  AssociationTableListElement,
} from "../../../../components/AssociationTable";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import ButtonAnotherTry from "../../../../components/buttons/Button/ButtonAnotherTry";
import CloseIcon from "../../../../components/svgIcons/CloseIcon";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AssociateElementsThemesIssuesForm from "../../../../components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import AssociateProofs from "../../../../components/forms/ApplicationsHubForms/AssociateProofs";
import { handleFlatRelevanceElements } from "../../../../utils/function";
import { maxFileSize } from "../../../../utils";
import PropTypes from "prop-types";
import { closeBtnText, formFlowType } from "../../../../utils/constants";

// authority types constants:
const LEGISLATION = "Legislation";
const CASE = "Case";
const COURT_RULE = "Court Rule";
const ACADEMIC_TEXT = "Academic Text";
const OTHER = "Other";

const NEW_AUTHORITY_LABEL = "New Authority";

const authorityTypesButtonsList = [
  {
    title: <Medium14Font>{LEGISLATION}</Medium14Font>,
    type: LEGISLATION,
  },
  {
    title: <Medium14Font>{CASE}</Medium14Font>,
    type: CASE,
  },
  {
    title: <Medium14Font>{COURT_RULE}</Medium14Font>,
    type: COURT_RULE,
  },
  {
    title: <Medium14Font>{ACADEMIC_TEXT}</Medium14Font>,
    type: ACADEMIC_TEXT,
  },
  {
    title: <Medium14Font>{OTHER}</Medium14Font>,
    type: OTHER,
  },
];

// authority status constants:
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const MIXED = "Mixed";

const authorityStatusButtonsList = [
  {
    title: <Medium14Font>{SUPPORTING}</Medium14Font>,
    type: SUPPORTING,
  },
  {
    title: <Medium14Font>{OPPOSING}</Medium14Font>,
    type: OPPOSING,
  },
  {
    title: <Medium14Font>{MIXED}</Medium14Font>,
    type: MIXED,
  },
];

// authority Jurisdictions:
const DOMESTIC = "Domestic";
const EUROPEAN_UNION = "European Union";
const INTERNATIONAL = "International";

const authorityJurisdictionsList = [
  {
    title: <Medium14Font>{DOMESTIC}</Medium14Font>,
    type: DOMESTIC,
  },
  {
    title: <Medium14Font>{EUROPEAN_UNION}</Medium14Font>,
    type: EUROPEAN_UNION,
  },
  {
    title: <Medium14Font>{INTERNATIONAL}</Medium14Font>,
    type: INTERNATIONAL,
  },
];

const AddEditAuthorities = ({
  isApplicationAuthoritiesFlow,
  applicationAuthoritiesSelectedObject,
  setApplicationAuthoritiesSelectedObject,
  caseObject,
  applicationObject,
  setApplicationObject,
  blockBlurEventParent,
  setApplicationAuthoritySubmitObject,
  flow,
  theme,
}) => {
  // Page states
  const [isLoading, setIsLoading] = useState(!isApplicationAuthoritiesFlow);
  // const [isSubmit, setIsSubmit] = useState(false);
  const isSubmit = useSelector((state) => state.app.isSubmitting);
  const [blockBlurEvent, setBlockBlurEvent] = useState(false);
  const { applicationId } = useParams();

  // Input states
  const [authorityObject, setAuthorityObject] = useState(
    isApplicationAuthoritiesFlow
      ? { ...applicationAuthoritiesSelectedObject, case: caseObject }
      : {}
  );
  const [authorityTitle, setAuthorityTitle] = useState("");
  const [authorityType, setAuthorityType] = useState(null);
  const [authorityJurisdiction, setAuthorityJurisdiction] = useState(null);
  const [authorityCitation, setAuthorityCitation] = useState(null);
  const [authorityKeySections, setAuthorityKeySections] = useState(null);
  // const [file, setFile] = useState(new File([""], ""));
  const [file, setFile] = useState(null);
  const [authorityStatus, setAuthorityStatus] = useState(null);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [submitObject, setSubmitObject] = useState(null);

  const [reliefsList, setReliefsList] = useState([]);
  const [proofsList, setProofsList] = useState([]);
  // other states and hooks
  const { id, authorityId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // save data from object to state
  const saveData = (object) => {
    if (object) {
      setAuthorityObject(object);
      setAuthorityTitle(object.title ?? "");
      setAuthorityType(object.type ?? null);
      setAuthorityJurisdiction(object.jurisdiction ?? null);
      setAuthorityCitation(object.citation ?? null);
      setAuthorityKeySections(object.key_sections ?? null);
      // setFile(new File([""], object.file));
      if (typeof object.file === "object") {
        setFile(object.file);
      } else {
        setFile(new File([""], object.file));
      }
      setAuthorityStatus(object.status ?? null);
      setReliefsList(object.reliefs ?? []);
      setProofsList(object.proofs ?? []);
    }
  };

  const updateApplicationAuthoritiesList = (resp) => {
    if (isApplicationAuthoritiesFlow) {
      if (!("fake_id" in resp)) {
        resp.fake_id = resp.id;
      }
      const applicationAuthoritiesListCopy = [...applicationObject.authorities];
      const index = applicationAuthoritiesListCopy.findIndex(
        (el) => el.real_id === resp.real_id
      );
      if (index === -1) {
        applicationAuthoritiesListCopy.push(resp);
      } else {
        applicationAuthoritiesListCopy.splice(index, 1, resp);
      }
      setApplicationObject((prevState) => ({
        ...prevState,
        authorities: applicationAuthoritiesListCopy,
      }));
      setApplicationAuthoritiesSelectedObject(resp);
    }
  };
  // send data to API
  const handleSubmit = async (submitObject) => {
    if (!isLoading && !isSubmit) {
      // setIsSubmit(true);
      dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
      // setIsSubmittingParent(true);
      let resp;
      if (isApplicationAuthoritiesFlow && applicationObject) {
        submitObject.id_application = applicationId;
      }
      if (!(authorityObject && authorityObject.id)) {
        resp = await createNewAuthority(id, dispatch, submitObject);
      } else {
        const authorityObjectCopy = { ...authorityObject };
        resp = await updateAuthorityById(
          isApplicationAuthoritiesFlow
            ? authorityObjectCopy.fake_id
            : authorityId,
          id,
          dispatch,
          submitObject
        );
      }
      if (resp) {
        updateApplicationAuthoritiesList(resp);
        setAuthorityObject(resp);
      }
      // setIsSubmit(false);
      dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
      return resp;
    }
  };

  const submitDataHandler = async (submitObj = submitObject) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    const resp = await updateAuthorityById(
      applicationAuthoritiesSelectedObject.fake_id ??
        applicationAuthoritiesSelectedObject.id,
      id,
      dispatch,
      submitObj
    );
    if (resp) {
      setApplicationAuthoritiesSelectedObject(resp);
      setApplicationObject((prevState) => {
        resp.fake_id = resp.id;
        const applicationObjectCopy = { ...prevState };
        const index = applicationObjectCopy.authorities.findIndex(
          (el) => el.id === resp.id
        );
        applicationObjectCopy.authorities.splice(index, 1, resp);
        return applicationObjectCopy;
      });
    }
    // setIsSubmitting(false);
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  const setSubmitAndAuthorityObjects = (object) => {
    setAuthorityObject((prevState) => ({
      ...prevState,
      ...object,
    }));
    setSubmitObject((prevState) => ({
      ...prevState,
      ...object,
    }));
    if (setApplicationAuthoritySubmitObject) {
      setApplicationAuthoritySubmitObject((prevState) => {
        if (!prevState) {
          return {
            ...{
              proofs: [],
              reliefs: [],
            },
            ...object,
          };
        } else {
          return {
            ...prevState,
            ...object,
          };
        }
      });
      setApplicationAuthoritiesSelectedObject((prevState) => {
        if (!prevState) {
          return {
            ...{
              proofs: [],
              reliefs: [],
            },
            ...object,
          };
        } else {
          return {
            ...prevState,
            ...object,
          };
        }
      });
    }
  };

  // get data from API or update data from existing authorities object
  useEffect(() => {
    const getDataFromAPI = async () => {
      const resp = await getAuthorityById(authorityId, id, dispatch);
      if (resp) {
        saveData(resp);
        if (setApplicationAuthoritySubmitObject) {
          setApplicationAuthoritySubmitObject(resp);
        }
        setIsLoading(false);
      }
    };
    if (!authorityObject || !Object.keys(authorityObject).length) {
      if (!isApplicationAuthoritiesFlow) {
        if (flow === formFlowType.EDIT) {
          getDataFromAPI();
        } else {
          setSubmitAndAuthorityObjects({
            causes_of_action: [],
            causes_of_action_defensive: [],
            causes_of_action_parent: [],
            themes: [],
            themes_parent: [],
            issues: [],
            issues_parent: [],
          });
          setIsLoading(false);
        }
      } else {
        // saveData(applicationAuthorityObject)
        setIsLoading(false);
      }
    } else {
      // saveData(authorityObject);
      saveData(
        isApplicationAuthoritiesFlow
          ? applicationAuthoritiesSelectedObject
          : authorityObject
      );
      if (setApplicationAuthoritySubmitObject) {
        setApplicationAuthoritySubmitObject(
          isApplicationAuthoritiesFlow
            ? applicationAuthoritiesSelectedObject
            : authorityObject
        );
      }
      setIsLoading(false);
    }
    // setIsLoading(false);
  }, [authorityObject, applicationAuthoritiesSelectedObject]);

  const sendFlagRelevanceData = async (submitObject) => {
    if (Object.keys(submitObject).length) {
      setSubmitAndAuthorityObjects(submitObject);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
  };

  // Button methods
  const onClickAuthorityDeleteHandler = (event) => {
    event.persist();
    if (authorityId) {
      dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "You won't be able to restore data",
        beforeCloseHandler: async () => {
          const resp = await deleteAuthorityById(authorityId, id, dispatch);
          if (resp) {
            history.push(
              !is_admin
                ? `/app/cases/${id}/trial-hub/authorities`
                : `/admin/all-cases/${id}/trial-hub/authorities`
            );
          }
        },
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
      dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/authorities`
          : `/admin/all-cases/${id}/trial-hub/authorities`
      );
    }
  };

  const onClickSaveAndCloseHandler = async () => {
    // setIsSubmit(true);
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    // setIsSubmittingParent(true);
    const authorityResp = await handleSubmit(authorityObject);
    if (authorityResp) {
      if (
        authorityObject.file &&
        typeof authorityObject.file.size !== "undefined" &&
        authorityObject.file.size !== 0
      ) {
        let formData = new FormData();
        formData.append("file", authorityObject.file);
        const authorityFileResp = await uploadAuthorityFile(
          // authorityObject.id ? authorityObject.id : respAuthority.id,
          authorityResp && authorityResp.id
            ? authorityResp.id
            : authorityObject.fake_id ?? authorityObject.id,
          id,
          dispatch,
          formData
        );
        if (authorityFileResp) {
          saveData(authorityFileResp);
          if (setApplicationAuthoritySubmitObject) {
            setApplicationAuthoritySubmitObject(authorityFileResp);
          }
          updateApplicationAuthoritiesList(authorityFileResp);
        }
      }

      if (history.action === "PUSH") {
        return history.goBack();
      } else {
        history.push(
          !is_admin
            ? `/app/cases/${id}/trial-hub/authorities`
            : `/admin/all-cases/${id}/trial-hub/authorities`
        );
      }
    }
  };

  // Show Flag Relevance form in right side bar
  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateElementsThemesIssuesForm
          // key={authorityObject.id}
          evidenceObject={authorityObject}
          selectedMenuTab={index}
          sendData={sendFlagRelevanceData}
        />
      ),
      title: object && object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const onChangeAuthorityTypeHandler = (type) => {
    setSubmitAndAuthorityObjects({ type });
  };

  useEffect(() => {}, [submitObject]);

  const onChangeAuthorityJurisdictionHandler = (jurisdiction) => {
    setSubmitAndAuthorityObjects({ jurisdiction });
  };

  const onChangeAuthorityStatusHandler = (status) => {
    setSubmitAndAuthorityObjects({ status });
  };

  // Input methods
  const onChangeInputValueHandler = (event) => {
    event.persist();
    if (isApplicationAuthoritiesFlow) {
      setApplicationAuthoritiesSelectedObject((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
        reliefs: prevState && prevState.reliefs ? prevState.reliefs : [],
        proofs: prevState && prevState.proofs ? prevState.proofs : [],
      }));
    } else {
      setSubmitAndAuthorityObjects({ [event.target.name]: event.target.value });
    }
  };

  // const onBlurHandler = async (event) => {
  //   event.persist();
  //   event.preventDefault();
  //   if (!blockBlurEvent && !blockBlurEventParent) {
  //     const submitObject = {
  //       [event.target.name]: event.target.value,
  //     };
  //     await handleSubmit(submitObject);
  //   }
  // };

  const onClickAssociateReliefAndProofsHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateProofs
          isAuthority
          applicationObject={applicationObject}
          setApplicationObject={setApplicationObject}
          returnObject={true}
          updateApplicationAuthoritiesList={updateApplicationAuthoritiesList}
          applicationPaperObject={applicationAuthoritiesSelectedObject}
          setApplicationPaperObject={setSubmitAndAuthorityObjects}
          savedReliefs={reliefsList}
          savedProofs={proofsList}
          routeParams={{ caseId: id, applicationId: applicationObject.id }}
        />
      ),
      title: "Reliefs and Proofs",
    });
  };
  const allocateProofsSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Reliefs and Proofs",
        icon: plusIcon,
        callback: () => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const submitObj = {
            reliefs: [],
            proofs: [],
          };
          setSubmitAndAuthorityObjects(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      "Reliefs and Proofs": applicationAuthoritiesSelectedObject
        ? [
            ...applicationAuthoritiesSelectedObject.reliefs,
            ...applicationAuthoritiesSelectedObject.proofs,
          ]
        : [],
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      // setApplicationPaperSelectedObject: (data) =>
      //   setApplicationPaperSelectedObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element, index) => (
              <AssociationTableListElement key={index}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const role = "Reliefs and Proofs";
                      const groupedReliefObjectCopy = {
                        "Reliefs and Proofs": [
                          ...applicationAuthoritiesSelectedObject.reliefs,
                          ...applicationAuthoritiesSelectedObject.proofs,
                        ],
                      };
                      const findElementIndex = index;
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedReliefObjectCopy,
                          [role]: listCopy,
                        };
                        // setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = {
                          reliefs: plainArray.filter(
                            (relief) => "pivot" in relief
                          ),
                          proofs: plainArray.filter(
                            (proof) => !("pivot" in proof)
                          ),
                        };
                        setSubmitAndAuthorityObjects(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            // return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const setElementsList = (causes_of_action) => {
    setSubmitAndAuthorityObjects({ causes_of_action });
  };
  const setThemesList = (themes) => {
    setSubmitAndAuthorityObjects({ themes });
  };
  const setThemesParentList = (themes_parent) => {
    setSubmitAndAuthorityObjects({ themes_parent });
  };
  const setIssuesList = (issues) => {
    setSubmitAndAuthorityObjects({ issues });
  };
  const setIssuesParentList = (issues_parent) => {
    setSubmitAndAuthorityObjects({ issues_parent });
  };
  const setElementsParentList = (causes_of_action_parent) => {
    setSubmitAndAuthorityObjects({ causes_of_action_parent });
  };
  const setAuthorityFlagRelevanceDefenceElements = (
    causes_of_action_defensive
  ) => {
    setSubmitAndAuthorityObjects({ causes_of_action_defensive });
  };
  // Settings objects
  const flagRelevanceSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        icon: plusIcon,
        callback: () => handleAssociateElementsThemesIssues(authorityObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(authorityObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          let submitObj;
          switch (index) {
            case 0:
              setSubmitAndAuthorityObjects({
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              });
              break;

            case 1:
              setSubmitAndAuthorityObjects({
                themes: [],
                themes_parent: [],
              });
              break;

            case 2:
              setSubmitAndAuthorityObjects({
                issues: [],
                issues_parent: [],
              });
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      authorityObject.causes_of_action_parent ?? [],
      authorityObject.causes_of_action ?? [],
      authorityObject.causes_of_action_defensive ?? [],
      authorityObject.issues_parent ?? [],
      authorityObject.issues ?? [],
      authorityObject.themes_parent ?? [],
      authorityObject.themes ?? [],
      caseObject
    ),
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      setElements: setElementsList,
      setThemes: setThemesList,
      setIssues: setIssuesList,
    },
    setParentObjectsFunctions: {
      setElementsParent: setElementsParentList,
      setThemesParent: setThemesParentList,
      setIssuesParent: setIssuesParentList,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setAuthorityFlagRelevanceDefenceElements([
                          ...listDefElementsCopy,
                        ]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        setSubmitAndAuthorityObjects({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        setSubmitAndAuthorityObjects({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        setSubmitAndAuthorityObjects({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  let pageConfig;
  if (!isApplicationAuthoritiesFlow) {
    pageConfig = [
      {
        path: !is_admin ? `/app/cases` : `/admin/all-cases`,
        title: `${is_admin ? "All Cases" : "Cases"}`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
        title: caseObject && caseObject.label ? caseObject.label : "New Case",
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? `/app/cases/${id}/trial-hub/authorities`
          : `/admin/all-cases/${id}/trial-hub/authorities`,
        title: `Trial Hub - Authorities`,
        activeMenuItem: CASES,
      },
      {
        path: !is_admin
          ? flow === formFlowType.EDIT
            ? `/app/cases/${id}/trial-hub/authorities/edit/${authorityObject.id}`
            : `/app/cases/${id}/trial-hub/authorities/create`
          : flow === formFlowType.EDIT
          ? `/admin/all-cases/${id}/trial-hub/authorities/edit/${authorityObject.id}`
          : `/admin/all-cases/${id}/trial-hub/authorities/create`,
        title:
          authorityObject && authorityObject.title
            ? authorityObject.title
            : NEW_AUTHORITY_LABEL,
        activeMenuItem: CASES,
      },
    ];
  }

  return (
    <Fragment>
      {!isApplicationAuthoritiesFlow ? (
        <PageConfiguration configArray={pageConfig} />
      ) : null}
      {!isApplicationAuthoritiesFlow ? (
        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-12 d-flex justify-content-center mb-3 col-md-6 justify-content-md-start mb-md-0">
            <Bold30Font>
              {authorityTitle !== "" ? authorityTitle : NEW_AUTHORITY_LABEL}
            </Bold30Font>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center mb-3 col-md-6 justify-content-md-end">
            <Button
              clickHandler={onClickAuthorityDeleteHandler}
              loading={isSubmit}
              disabled={isSubmit}
              style={{ marginRight: "20px" }}
              secondary
              delete_
            >
              Delete
            </Button>
            <ButtonNew
              clickHandler={onClickSaveAndCloseHandler}
              disabled={isSubmit}
              loading={isSubmit}
              type="submit"
              primary
              onMouseEnter={() => setBlockBlurEvent(true)}
              onMouseLeave={() => setBlockBlurEvent(false)}
            >
              <Bold14Font textColor={theme.colors.white}>
                {closeBtnText}
              </Bold14Font>
            </ButtonNew>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col">
          <Form>
            <div style={{ padding: "0 15px" }}>
              <div className="form-group row">
                <div className="col-12">
                  <InputNew
                    // onBlur={onBlurHandler}
                    name="title"
                    type="text"
                    value={authorityTitle}
                    placeholder="Enter authority title"
                    label="Authority Title"
                    onChange={onChangeInputValueHandler}
                    maxLength={125}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div
                  className="col-12"
                  onMouseEnter={() => setBlockBlurEvent(true)}
                  onMouseLeave={() => setBlockBlurEvent(false)}
                >
                  <RadioButtonGroup
                    label="Authority Type"
                    name="type"
                    itemsObjects={authorityTypesButtonsList}
                    checkedButton={authorityType}
                    setCheckedButton={onChangeAuthorityTypeHandler}
                    w100
                  />
                </div>
              </div>
              <div className="form-group row">
                <div
                  className="col-12"
                  onMouseEnter={() => setBlockBlurEvent(true)}
                  onMouseLeave={() => setBlockBlurEvent(false)}
                >
                  <RadioButtonGroup
                    label="Jurisdiction"
                    name="jurisdiction"
                    itemsObjects={authorityJurisdictionsList}
                    checkedButton={authorityJurisdiction}
                    setCheckedButton={onChangeAuthorityJurisdictionHandler}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 col-md-6">
                  <InputNew
                    // onBlur={onBlurHandler}
                    name="citation"
                    type="text"
                    value={authorityCitation}
                    placeholder="Enter citation"
                    label="Citation"
                    onChange={onChangeInputValueHandler}
                    maxLength={125}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 col-md-6">
                  <InputNew
                    // onBlur={onBlurHandler}
                    name="key_sections"
                    type="text"
                    value={authorityKeySections}
                    placeholder="Enter Sections"
                    label="Sections"
                    onChange={onChangeInputValueHandler}
                    maxLength={125}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 col-sm-6">
                  <UploadSingleFileInput
                    label="Upload File"
                    file={authorityObject.file}
                    setFile={(file) => {
                      setSubmitAndAuthorityObjects({ file });
                    }}
                    validTypes={[
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                      "application/pdf",
                    ]}
                    maxFileSize={maxFileSize}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div
                  className="col-12"
                  onMouseEnter={() => setBlockBlurEvent(true)}
                  onMouseLeave={() => setBlockBlurEvent(false)}
                >
                  <RadioButtonGroup
                    checkedButton={authorityStatus}
                    setCheckedButton={onChangeAuthorityStatusHandler}
                    itemsObjects={authorityStatusButtonsList}
                    label="Status"
                    name="status"
                  />
                </div>
              </div>
              <div className="form-group row">
                {isApplicationAuthoritiesFlow ? (
                  <div
                    className={
                      reliefsList.length || proofsList.length
                        ? "col-12"
                        : "col-12 col-sm-6"
                    }
                  >
                    <AssociationTable
                      settings={allocateProofsSettings}
                      label="Reliefs and Proofs"
                    />
                  </div>
                ) : (
                  <div
                    className={
                      authorityObject.causes_of_action.length ||
                      authorityObject.causes_of_action_defensive.length ||
                      authorityObject.causes_of_action_parent.length ||
                      authorityObject.themes.length ||
                      authorityObject.themes_parent.length ||
                      authorityObject.issues.length ||
                      authorityObject.issues_parent.length
                        ? "col-12"
                        : "col-12 col-sm-6"
                    }
                  >
                    <AssociationTable
                      settings={flagRelevanceSettings}
                      label="Flag Relevance"
                    />
                  </div>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

AddEditAuthorities.propTypes = {
  flow: PropTypes.oneOf([formFlowType.CREATE, formFlowType.EDIT]),
};

AddEditAuthorities.defaultProps = {
  flow: formFlowType.EDIT,
};

export default withTheme(AddEditAuthorities);
