import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import { Bold14Font } from "../../FontsNewComponent/Fonts";
import UploadSingleFileDivStyled from "./styled/UploadSingleFileDivStyled";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { useDispatch } from "react-redux";
import {maxFileSize} from "../../../utils";

const UploadSingleFileInput = ({
  label,
  validTypes,
  maxFileSize,
  file,
  setFile,
  ...props
}) => {
  const dispatch = useDispatch();
  let fileInput = useRef(null);
  const [hoover, setHoover] = useState(false);
  const onClickHandler = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const dragOver = (e) => {
    e.preventDefault();
    setHoover(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHoover(false);
  };
  const onChangeFileInputHandler = () => {
    if (fileInput.current) {
      handleFiles(fileInput.current.files);
    }
  };

  const handleFiles = async (files) => {
    if (files.length) {
      const file_ = files[0];
      if (validateFile(file_, validTypes)) {
        if (file_.size < maxFileSize) {
          setFile(file_);
        } else {
          dispatch({ type: SAVE_MODAL_DATA, payload: "File is to big" });
          dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
        }
      } else {
        dispatch({ type: SAVE_MODAL_DATA, payload: "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format." });
        dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
      }
    }
  };
  const addFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  const validateFile = (file, validTypes) =>
    validTypes.indexOf(file.type) !== -1;

  return (
    <div {...props} className="container-fluid">
      {label ? (
        <div className="row mb-2">
          <div className="col-12">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
      ) : null}
      <UploadSingleFileDivStyled onClick={onClickHandler} hoover={hoover}
                                 onDragOver={dragOver}
                                 onDragEnter={dragEnter}
                                 onDragLeave={dragLeave}
                                 onDrop={addFile}>
        <ButtonNew tertiary clickHandler={() => null}>
          {!file || file.name === "null" ? "Select File" : typeof file === "string" ? file : file.name}
        </ButtonNew>
      </UploadSingleFileDivStyled>
      <input
        ref={fileInput}
        type="file"
        accept={validTypes.join(",")}
        // onChange={onChangeFileInputHandler}
        onChange={() => {
          if (fileInput.current) {
            handleFiles(fileInput.current.files);
          }
        }}
        style={{ display: "none" }}
        {...props}
      />
    </div>
  );
};

UploadSingleFileInput.propTypes = {
  label: PropTypes.string,
  validTypes: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
};

UploadSingleFileInput.defaultProps = {
  validTypes: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ],
  maxFileSize: maxFileSize,
};

export default UploadSingleFileInput;
