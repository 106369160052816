import {
  HIDE_TECH_DATA,
  RESET_REQUESTED_URL,
  SAVE_REQUESTED_URL,
  SET_CONTACT_US,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS,
} from "../types";

const initialState = {
  requestedUrl: "",
  // userFlow: "",
  isTechDataShow: false,
  contactUsForm: true,
  isSubmitting: false,
  showWitness: false,
  showRelevance: false,
  showFurtherDetails: false,
  showNotes: false,
  showObjectives: false,
};

const appVariablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REQUESTED_URL:
      return {
        ...state,
        requestedUrl: action.payload,
      };
    case SET_CONTACT_US:
      return {
        ...state,
        contactUsForm: action.payload,
      };

    case RESET_REQUESTED_URL:
      return {
        ...state,
        requestedUrl: "",
      };

    case HIDE_TECH_DATA:
      return {
        ...state,
        isTechDataShow: false,
      };

    case SET_IS_SUBMITTING_DATA_TRUE:
      return {
        ...state,
        isSubmitting: true,
      };

    case SET_IS_SUBMITTING_DATA_FALSE:
      return {
        ...state,
        isSubmitting: false,
      };

    case SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default appVariablesReducer;
