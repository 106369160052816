import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import AssociationTableStyled from "./styled/AssociationTableStyled";

import ButtonNew from "../buttons/Button/ButtonNew";
import plusIcon from "../../assets/img3/svg/button-plus-icon.svg";
import { Bold14Font } from "../FontsNewComponent/Fonts";
import AssociationTableRowStyled from "./styled/AssociationTableRowStyled";
import ListActionButton from "../buttons/ListActionButton/ListActionButton";
import AssociationTableUlistStyled from "./styled/AssociationTableUlistStyled";

const AssociationTable = ({ settings, label, compact = false, ...props }) => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   setIsLoading(false);
  // }, []);
  //
  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  const settingsSetObjectsFunctionsKeyArray = Object.keys(
    settings.setObjectsFunctions
  );

  let settingsSetParentObjectsFunctionsKeyArray;
  if (settings.setParentObjectsFunctions) {
    settingsSetParentObjectsFunctionsKeyArray = Object.keys(
      settings.setParentObjectsFunctions
    );
  }

  return (
    <div {...props} className="container-fluid">
      {label ? (
        <div className="row mb-2">
          <div className="col-12">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
      ) : null}
      <AssociationTableStyled>
        {Object.keys(settings.objects).map((objectName, indexOfRow) => {
          if (!settings.objects[objectName].length) {
            return null;
          }
          return (
            <AssociationTableRowStyled
              key={indexOfRow}
              style={compact ? { padding: 10 } : {}}
            >
              {settings.fields.map((field, indexOfField) => (
                <div
                  className="w-100 d-flex justify-content-between flex-wrap flex-xl-nowrap"
                  key={indexOfField}
                  style={
                    compact
                      ? {
                          flexDirection: "column",
                          // alignItems: 'center',
                        }
                      : {}
                  }
                >
                  <div
                    className="d-flex flex-wrap flex-xl-nowrap"
                    style={compact ? { flexDirection: "column" } : {}}
                  >
                    <div
                      style={{
                        minWidth: "104px",
                        marginRight: "31px",
                        marginBottom: compact ? 8 : 0,
                      }}
                    >
                      {field.rowName(
                        objectName[0].toUpperCase() + objectName.slice(1)
                      )}
                    </div>
                    <AssociationTableUlistStyled>
                      {field.rowElements(
                        settings.objects[objectName],
                        // settings.setObjectsFunctions[
                        //   settingsSetObjectsFunctionsKeyArray[indexOfRow]
                        // ],
                        settingsSetObjectsFunctionsKeyArray.length > 1
                          ? settings.setObjectsFunctions[
                              settingsSetObjectsFunctionsKeyArray[indexOfRow]
                            ]
                          : settings.setObjectsFunctions[
                              settingsSetObjectsFunctionsKeyArray[0]
                            ],
                        indexOfRow,
                        settingsSetParentObjectsFunctionsKeyArray
                          ? settingsSetParentObjectsFunctionsKeyArray.length > 1
                            ? settings.setParentObjectsFunctions[
                                settingsSetParentObjectsFunctionsKeyArray[
                                  indexOfRow
                                ]
                              ]
                            : settings.setParentObjectsFunctions[
                                settingsSetParentObjectsFunctionsKeyArray[0]
                              ]
                          : null
                      )}
                    </AssociationTableUlistStyled>
                  </div>
                  <div
                    className="d-flex"
                    style={compact ? { marginTop: 8 } : {}}
                  >
                    {settings.actions.map((action, index) => {
                      if (action.type === "addButton") {
                        return null;
                      }
                      return (
                        <ListActionButton
                          key={index}
                          type={action.type}
                          clickHandler={() => action.clickHandler(indexOfRow)}
                        />
                      );
                    })}
                  </div>
                </div>
              ))}
            </AssociationTableRowStyled>
          );
        })}
        {settings.actions[0].type === "addButton" ? (
          <div className="d-flex justify-content-center">
            <ButtonNew tertiary clickHandler={settings.actions[0].callback}>
              <div className="d-flex align-items-center">
                {settings.actions[0].icon ? (
                  <img
                    src={settings.actions[0].icon}
                    alt=""
                    className="img-fluid mr-2"
                  />
                ) : null}
                <Bold14Font
                  style={{
                    color: "inherit",
                    marginTop: "23px",
                    marginBottom: "23px",
                  }}
                >
                  {settings.actions[0].buttonText}
                </Bold14Font>
              </div>
            </ButtonNew>
          </div>
        ) : null}
      </AssociationTableStyled>
    </div>
  );
};

AssociationTable.propTypes = {
  settings: PropTypes.object.isRequired,
  label: PropTypes.string,
};

export default AssociationTable;
