import styled from "styled-components/macro";
import ToggleButtonSliderStyled from "./ToggleButtonSliderStyled";
import rem from "../../../../utils/rem";

const ToggleButtonLabelStyled = styled.label`
  position: relative;
  display: inline-block;
  width: 23px;
  height: 9px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + ${ToggleButtonSliderStyled}:before {
    transform: translateX(14px);
  }
`;

export default ToggleButtonLabelStyled;
