import React from "react";
import PropTypes from "prop-types";

import FeatureCardStyled from "./styled/FeatureCardStyled";

import { Bold24Font, Medium18Font } from "../Fonts/Fonts";
import ImgWidthWrapperStyled from "../helpWrappers/ImgWidthWrapperStyled";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <FeatureCardStyled>
      <div className="row mb-3">
        <div className="col">
          <ImgWidthWrapperStyled width={70}>
            <img src={icon} alt="" className="img-fluid" />
          </ImgWidthWrapperStyled>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <Bold24Font as="p" className="text-center">
            {title}
          </Bold24Font>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Medium18Font as="p" className="text-center">
            {description}
          </Medium18Font>
        </div>
      </div>
    </FeatureCardStyled>
  );
};

FeatureCard.propTypes = {
  /**
   * Feature card top icon
   */
  icon: PropTypes.string.isRequired,

  /**
   * Feature card title
   */
  title: PropTypes.string.isRequired,

  /**
   * Feature card description
   */
  description: PropTypes.string.isRequired,
};

export default FeatureCard;
