import React, {useState} from "react";
import PropTypes from "prop-types";
import CaseRowStyled from "./styled/CaseRowStyled";
import {Bold14Font, Medium14Font} from "../../FontsNewComponent/Fonts";
import {theme} from "../../../styled-components/Theme/Theme";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import {MODAL_DELETE_ELEMENT, SAVE_MODAL_DATA, SHOW_MODAL, SHOW_RIGHT_SIDE_BAR} from "../../../redux/types";
import {deleteCaseType} from "../../../containers/Auth/auth";
import {useDispatch} from "react-redux";
import Button from "../../buttons/Button";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";
import CasesSearchInput from "../CasesSearchFilterBar/CasesSearchInput";
import CaseTypesForm from "./CaseTypesForm";
import {useHistory} from "react-router-dom";
import {route} from "../../../routes";
import PageConfiguration from "../BreadCrumbs/PageConfiguration";
import {SETTINGS} from "../BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

export default function AdminCaseTypes(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {caseTypes} = props;
  const [searchBarValue, setSearchBarValue] = useState('');
  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };
  const filter = (v) => {
    if (searchBarValue) {
      return v.name.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1;
    }
    return true;
  };
  const deleteAction = async (id) => {
    dispatch({
      type: SAVE_MODAL_DATA, payload: "You won't be able to restore data", beforeCloseHandler: async () => {
        await deleteCaseType(id, dispatch);
        if (props.updateCases) {
          props.updateCases(caseTypes.filter((v) => {
            return v.id !== id;
          }))
        }
      }
    });
    dispatch({type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT});
  };
  const changeObj = (resp, type) => {
    if (resp) {
      let caseTypes_clone = [...caseTypes];
      if (type === 'add') {
        caseTypes_clone.push(resp);
      } else if (type === 'update') {
        caseTypes_clone.forEach((v, index) => {
          if (v.id === resp.id) {
            caseTypes_clone[index] = resp;
          }
        });
      }
      props.updateCases(caseTypes_clone);
    }
  };
  const addButtonFunction = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: <CaseTypesForm key={object.id} object={object} afterSubmit={changeObj}/>,
      title: object.name ? 'Edit: ' + object.name : 'Add New Type',
    });
  };
  const pageConfig = [
    {
      path: route.admin.baseApp.settings.causesOfAction,
      title: "Settings",
      activeMenuItem: SETTINGS
    },
    {
      path: route.admin.baseApp.settings.cateTypes,
      title: "Case Types",
      activeMenuItem: SETTINGS
    },
  ];
  return <div className="p-3">
    <PageConfiguration configArray={pageConfig}/>
    <div className="mb-4 d-flex justify-content-between">
      <CasesSearchInput
        style={{
          width: 'calc(100% - 200px)',
          paddingLeft: '20px',
          background: `url(${magnifierIcon}) no-repeat right 20px top 13px, ${theme.colors.white}`,
        }}
        type="text"
        value={searchBarValue}
        onChange={onSearchBarChangeHandler}
        placeholder={'Search'}
        name={'search_type'}
      />
      <Button
        clickHandler={addButtonFunction}
        primary
      >
        <div className="d-flex align-items-center">
          <img src={plusIcon} alt="" className="img-fluid mr-2"/>
          <Bold14Font style={{color: "inherit"}}>
            Add Case Type
          </Bold14Font>
        </div>
      </Button>
    </div>
    {caseTypes.filter(filter).map((caseType) => {
      return <CaseRowStyled className="d-flex align-items-center justify-content-between" key={caseType.id}>
        <Medium14Font textColor={theme.colors.darkOpacity}>{caseType.name}</Medium14Font>
        <div className="d-flex">
          <ListActionButton type={'edit'} clickHandler={() => {
            addButtonFunction(caseType);
          }}/>
          <ListActionButton type={'delete'} clickHandler={() => {
            deleteAction(caseType.id);
          }}/>
        </div>
      </CaseRowStyled>;
    })}
  </div>
}
AdminCaseTypes.propTypes = {
  caseTypes: PropTypes.array.isRequired,
  updateCases: PropTypes.func.isRequired
};
