import React, { useRef, useState } from "react";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import InputNew from "../../../components/InputNew/InputNew";
import { updateProfile, updateUserPassword } from "../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import AnimatedDiv from "../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Form from "../../../components/forms/Form";
import {
  MODAL_SUCCESS,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { Bold14Font } from "../../../components/FontsNewComponent/Fonts";
import PasswordFormInputLabelStyled from "./styled/PasswordFormInputLabelStyled";

const PasswordForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");
  let form = useRef(null);
  const submitForm = async (e) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!isSubmitting) {
      setIsSubmitting(true);
      const resp = await updateUserPassword(
        {
          old_password: oldPassword,
          password,
          password_confirmation: passwordConfirmed,
        },
        dispatch
      );
      setPassword("");
      setOldPassword("");
      setPasswordConfirmed("");
      if (resp.result === "success") {
        dispatch({ type: SAVE_MODAL_DATA, payload: "Password updated!" });
        dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS });
      }
      setIsSubmitting(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitForm(event);
    }
  };
  return (
    <form onSubmit={submitForm} ref={(ref) => (form = ref)}>
      <Form title={"Password"} style={{ overflow: "hidden" }}>
        <AnimatedDiv className="row pl-4 pr-4 pb-4 pt-0">
          {user.has_password ? (
            <div className="col-xl-4 col-sm-12 mb-sm-3 mb-xl-0">
              <PasswordFormInputLabelStyled>
                <Bold14Font>Old Password</Bold14Font>
                <InputNew
                  // onKeyPress={handleKeyPress}
                  required
                  minLength={8}
                  // label={"Old Password"}
                  type="password"
                  onChangeHandler={(e) => {
                    setOldPassword(e.target.value);
                  }}
                  value={oldPassword}
                  placeholder={"Enter your old Password"}
                  name={"old_password"}
                />
              </PasswordFormInputLabelStyled>
            </div>
          ) : null}
          <div
            className={
              user.has_password
                ? "col-xl-4 col-sm-12 mb-sm-3 mb-xl-0"
                : "col-xl-6 col-sm-12 mb-sm-3 mb-xl-0"
            }
          >
            <PasswordFormInputLabelStyled>
              <Bold14Font>New Password</Bold14Font>
              <InputNew
                onKeyPress={handleKeyPress}
                required
                minLength={8}
                // label={"New Password"}
                type="password"
                onChangeHandler={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                placeholder={"Enter your new password"}
                name={"new_password"}
              />
            </PasswordFormInputLabelStyled>
          </div>
          <div
            className={
              user.has_password
                ? "col-xl-4 col-sm-12 mb-sm-3 mb-xl-0"
                : "col-xl-6 col-sm-12 mb-sm-3 mb-xl-0"
            }
          >
            <PasswordFormInputLabelStyled>
              <Bold14Font>Confirm New Password</Bold14Font>
              <InputNew
                onKeyPress={handleKeyPress}
                required
                minLength={8}
                // label={"Confirm New Password"}
                type="password"
                onChangeHandler={(e) => {
                  setPasswordConfirmed(e.target.value);
                }}
                value={passwordConfirmed}
                placeholder={"Confirm your new password"}
                name={"new_password"}
              />
            </PasswordFormInputLabelStyled>
          </div>
          <div className="col-sm-12 d-flex justify-content-end mt-4">
            <ButtonNew
              primary
              className="ml-2 mr-3"
              loading={isSubmitting}
              disabled={isSubmitting}
              clickHandler={() => null}
            >
              Save
            </ButtonNew>
          </div>
        </AnimatedDiv>
      </Form>
    </form>
  );
};

export default PasswordForm;
