import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  HIDE_MODAL,
  HIDE_RIGHT_SIDE_BAR,
  MODAL_ALERT,
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../redux/types";
import ButtonNew from "../buttons/Button/ButtonNew";

/**
 * Error message value, when the data cannot be loaded due to a subscription plan limitation
 * @type {string}
 */
const STORAGE_LIMIT = "storage-limit";
const CASES_LIMIT = "Max number of cases reached";

/**
 * Show errors from catch(e) {...}
 * @param message object obtained from catch(e) {...}.
 * @param dispatch function obtained from react useDispatch() hook.
 * @param afterCloseHandler action after close.
 */
export const handleErrorMessage = (
  message,
  dispatch,
  afterCloseHandler,
  alert
) => {
  dispatch({
    type: SAVE_MODAL_DATA,
    payload: message,
    afterCloseHandler: afterCloseHandler,
  });
  if (alert) {
    dispatch({ type: SHOW_MODAL, payload: MODAL_ALERT });
    return;
  }
  dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
};

const modalErrorHandler = (error, dispatch, afterCloseHandler) => {
  if (error.response.data.message) {
    const errorMessage = error.response.data.message;
    if (dispatch) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: errorMessage,
        afterCloseHandler: afterCloseHandler,
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
    }
    return errorMessage;
  } else if (error.response.data.messages) {
    if (error.response.data.messages[0] === STORAGE_LIMIT) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: (
          <Fragment>
            You have reached your upload space limit. You need to upgrade your
            subscription plan to upload files further.
            <br />
            <div className="w-100 d-flex justify-content-center mt-3">
              <Link
                to="/app/subscription/plans"
                onClick={() => {
                  dispatch({ type: HIDE_MODAL });
                }}
              >
                Go to Subscription Plans
              </Link>
            </div>
          </Fragment>
        ),
        afterCloseHandler: afterCloseHandler,
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
      return;
    } else if (error.response.data.messages[0] === CASES_LIMIT) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: (
          <Fragment>
            You have reached your cases amount limit. You need to upgrade your
            subscription plan to add new cases further.
            <br />
            <div className="w-100 d-flex justify-content-center mt-3">
              <Link
                to="/app/subscription/plans"
                onClick={() => {
                  dispatch({ type: HIDE_MODAL });
                }}
              >
                Go to Subscription Plans
              </Link>
            </div>
          </Fragment>
        ),
        afterCloseHandler: afterCloseHandler,
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
      return;
    }
    const errors = error.response.data.messages;
    let result = [];
    errors.map((el, index) =>
      Object.keys(el).forEach((key) => {
        let obj;
        try {
          obj = {
            error: `${key[0].toUpperCase()}${key.slice(1)}`,
            message: errors[index][key].map(function (value, i) {
              return <li key={i}>{value}</li>;
            }),
          };
          result.push(obj);
        } catch (e) {
          result = errors.join(", ");
        }
      })
    );
    if (dispatch) {
      dispatch({ type: SAVE_MODAL_DATA, payload: result });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
    }
    return result;
  } else if (error.message) {
    const errorMessage = error.message;
    if (dispatch) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: errorMessage,
        afterCloseHandler: afterCloseHandler,
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
    }
    return errorMessage;
  }
};

export default modalErrorHandler;
