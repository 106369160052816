import React from "react";
import styled from "styled-components";

import redoIcon from "@/assets/img/notebook/toolbar/redo.svg";

const RedoIcon = ({ opacity = "0.25" }) => (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M15.8667 9.4C17.85 9.7 19.6 10.3 21.2333 11.4L24.5 8.5V15.5H16.3333L19.4833 12.8C15.1667 10.2 9.21667 11 6.06667 14.7C5.83333 15 5.6 15.2 5.48334 15.5L3.38333 14.6C5.95 10.8 10.85 8.7 15.8667 9.4Z"
        fill="black"
      />
    </g>
  </svg>
);

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  ${(props) => props.active && ` background: rgba(0, 0, 0, 0.05);`}
`;

const Text = styled.span`
  font-size: 14px;
  color: ${(props) => props.color};
  text-select: none;
`;

const Redo = ({ editor }) => {
  const active = editor.can().redo();

  return (
    <Container
      onClick={() => {
        editor.chain().focus().redo().run();
      }}
    >
      <RedoIcon opacity={active ? "0.7" : "0.25"} />
      <Text color={active ? "#0F122F" : "#6a6c7d"}>Redo</Text>
    </Container>
  );
};

export default Redo;
