import React, { Fragment, useEffect, useRef, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch } from "react-redux";
import {
  updateApplicationPaperById,
  updateAuthorityById, updateDocument,
  updateEvidence,
} from "../../../containers/Auth/auth";
import Input from "../../Input";
import InputNew from "../../InputNew/InputNew";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { randomNumberRange } from "../../../utils";
import { useRouteMatch, useParams } from "react-router-dom";
import { route } from "../../../routes";

const validTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const EditLabelForm = ({
  caseId,
  applicationId,
  callback,
  evidence,
  isCasesBulkImportFlow,
  isApplicationPapersBulkImportFlow,
  isApplicationAuthoritiesBulkImportFlow,
}) => {
  const [bundle, setBundle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [label, setLabel] = useState("");
  const [randomNumber, setRandomNumber] = useState(randomNumberRange());
  const dispatch = useDispatch();
  let form = useRef(null);
  const authoritiesBulkImport = useRouteMatch(
    `${route.baseApp.trialHub.authoritiesBulkImport}`
  ) || useRouteMatch(
    `${route.admin.baseApp.trialHub.authoritiesBulkImport}`
  );
  const isAuthoritiesBulkImportFlow = !!(
    authoritiesBulkImport && authoritiesBulkImport.isExact
  );

  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      if (
        isAuthoritiesBulkImportFlow ||
        isApplicationAuthoritiesBulkImportFlow
      ) {
        setLabel(evidence.title ?? "");
      } else if (isCasesBulkImportFlow) {
        setLabel(evidence.name);
      } else {
        setLabel(evidence.label ?? "");
      }
    }
  }, [evidence]);

  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ label } };
    });
  }, [label]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      let resp;
      if (
        isAuthoritiesBulkImportFlow ||
        isApplicationAuthoritiesBulkImportFlow
      ) {
        resp = await updateAuthorityById(evidence.id, caseId, dispatch, {
          title: label,
        });
      } else if (isApplicationPapersBulkImportFlow) {
        resp = await updateApplicationPaperById(
          evidence.id,
          applicationId,
          caseId,
          dispatch,
          {
            label: label,
          }
        );
      } else if (isCasesBulkImportFlow) {
        resp = await updateDocument(evidence.id, caseId, dispatch, {name: label})
      } else {
        resp = await updateEvidence(evidence.id, caseId, dispatch, {
          label,
        });
      }

      callback(resp);
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          <InputNew
            maxLength={125}
            required
            label={isCasesBulkImportFlow ? 'Name' : "Label"}
            onChangeHandler={(e) => {
              if (e.target.value.length <= 125) setLabel(e.target.value);
            }}
            value={label}
            placeholder={isCasesBulkImportFlow ? 'Enter Name' : "Enter Label"}
            name={"label"}
            form={`form-label-${randomNumber}`}
          />
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            id={`form-label-${randomNumber}`}
            ref={form}
          >
            <ButtonNew
              clickHandler={handleSubmit}
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default EditLabelForm;
