import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../buttons/Button/Button";
import { HIDE_RIGHT_SIDE_BAR, SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import InputNew from "../../InputNew/InputNew";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import RadioButtonGroupRounded from "../../buttons/RadioButtonGroupRounded/RadioButtonGroupRounded";
import SelectNew from "../../Select/SelectNew";
import Label from "../AddCardForm/Label";
import {
  subtypeCommunicationList,
  subtypeDocumentList,
  subtypeImageMediaList,
} from "../../../containers/BaseApp/TrialHub/Evidence/AddEditEvidence";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import moment from "moment";
import ToggleButtonGray from "../../buttons/ToggleButton/ToggleButtonGray";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import EvidenceObjectivesForm from "./EvidenceObjectivesForm";
import { theme } from "../../../styled-components/Theme/Theme";
import EvidenceDocProperties from "./EvidenceDocProperties";
import RelatedEvidenceForm from "./RelatedEvidenceForm";
import TextAreaSideBar from "./TextAreaSideBar";

const TESTIMONY = "Testimony";
const COMMUNICATION = "Communication";
const DOCUMENT = "Document";
const IMAGE_MEDIA = "Image/Media";
const AFFIDAVIT = "Affidavit";
const OTHER = "Other";
const types = [
  { value: TESTIMONY, label: TESTIMONY },
  { value: COMMUNICATION, label: COMMUNICATION },
  { value: DOCUMENT, label: DOCUMENT },
  { value: IMAGE_MEDIA, label: IMAGE_MEDIA },
  { value: AFFIDAVIT, label: AFFIDAVIT },
  { value: OTHER, label: OTHER },
];

const EvidenceFurtherDetailsForm = ({
  // evidenceObject,
  setEvidenceObject,
  withLabel,
  caseObject,
  caseId,
  callback,
  inSwap,
}) => {
  const evidenceObject = useSelector(
    (state) => state.rightSideBar.editableObject
  );
  const [subtypeOptions, setSubtypeOptions] = useState(null);
  const history = useHistory();

  const handleSubmit = () => {
    if (withLabel) {
      setEvidenceObject({
        priority: evidenceObject.priority,
        type: evidenceObject.type,
        date: evidenceObject.date
          ? moment(evidenceObject.date).format("YYYY-MM-DD")
          : "",
        subtype: evidenceObject.subtype,
        from: evidenceObject.from,
        to: evidenceObject.to,
        classes: {
          ...evidenceObject.classes,
          core_book: evidenceObject.classes.core_book,
        },
        objectives: evidenceObject.objectives,
        summary: evidenceObject.summary,
        preempt: evidenceObject.preempt,
        related_evidence: evidenceObject.related_evidence,
        label: evidenceObject.label,
      });
    } else {
      setEvidenceObject({
        priority: evidenceObject.priority,
        type: evidenceObject.type,
        date: evidenceObject.date
          ? moment(evidenceObject.date).format("YYYY-MM-DD")
          : "",
        subtype: evidenceObject.subtype,
        from: evidenceObject.from,
        to: evidenceObject.to,
        classes: {
          ...evidenceObject.classes,
          core_book: evidenceObject.classes.core_book,
        },
        objectives: evidenceObject.objectives,
        summary: evidenceObject.summary,
        preempt: evidenceObject.preempt,
        related_evidence: evidenceObject.related_evidence,
      });
    }
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const dispatch = useDispatch();
  const changeFrom = (e) => {
    setEvidenceObject({ ...evidenceObject, ...{ from: e.target.value } });
  };
  const onChangeTextFieldHandler = (e) => {
    setEvidenceObject({
      ...evidenceObject,
      ...{ [e.target.name]: e.target.value },
    });
  };
  const changeTo = (e) => {
    setEvidenceObject({ ...evidenceObject, ...{ to: e.target.value } });
  };
  const setPriority = (priority) => {
    setEvidenceObject({ ...evidenceObject, ...{ priority } });
  };
  const setDate = (date) => {
    setEvidenceObject({
      ...evidenceObject,
      ...{
        date: date && date !== "" ? moment(date).format("YYYY-MM-DD") : null,
      },
    });
  };
  const changeLabel = (e) => {
    setEvidenceObject({ ...evidenceObject, ...{ label: e.target.value } });
  };
  const setEvidenceType = (select) => {
    setEvidenceObject({ ...evidenceObject, ...{ type: select.value } });
  };
  const setEvidenceSubType = (select) => {
    setEvidenceObject({ ...evidenceObject, ...{ subtype: select.value } });
  };
  useEffect(() => {
    if (evidenceObject.type) {
      switch (evidenceObject.type) {
        case "Testimony":
        case "Affidavit":
        case "Other":
          setSubtypeOptions(null);
          break;

        case "Communication":
          setSubtypeOptions(subtypeCommunicationList);
          break;

        case "Document":
          setSubtypeOptions(subtypeDocumentList);
          break;

        case "Image/Media":
          setSubtypeOptions(subtypeImageMediaList);
          break;

        default:
          setSubtypeOptions(null);
      }
    }
  }, [evidenceObject]);
  const priorityButtonsObjects = [
    {
      title: <Medium14Font>1</Medium14Font>,
      type: 1,
    },
    {
      title: <Medium14Font>2</Medium14Font>,
      type: 2,
    },
    {
      title: <Medium14Font>3</Medium14Font>,
      type: 3,
    },
    {
      title: <Medium14Font>4</Medium14Font>,
      type: 4,
    },
  ];

  const updateCore = (core_book) => {
    const copy = {...evidenceObject};
    copy.classes.core_book = core_book ? 1 : 0;
    setEvidenceObject(copy);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {withLabel ? (
          <div className="form-group">
            <InputNew
              name={"label"}
              placeholder={"Label"}
              label={"Label"}
              value={evidenceObject.label}
              onChangeHandler={changeLabel}
            />
          </div>
        ) : null}
        <div className="form-group">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <Bold14Font>Core</Bold14Font>
            <ToggleButtonGray
              button_props={{ style: { top: "5px" } }}
              checked={evidenceObject.classes.core_book === 1}
              onChange={(e) => {
                updateCore(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <CustomReactDatepicker
            selected={
              evidenceObject.date ? moment(evidenceObject.date).toDate() : null
            }
            onChange={(date) => setDate(date)}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            label="Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
        <div className="form-group">
          <div className="container-fluid">
            <RadioButtonGroupRounded
              checkedButton={evidenceObject.priority}
              setCheckedButton={setPriority}
              itemsObjects={priorityButtonsObjects}
              label="Priority"
            />
          </div>
        </div>
        <div className="form-group">
          <Label name={"Evidence Type"} />
          <div className="container-fluid">
            <SelectNew
              options={types}
              value={types.find((v) => v.value === evidenceObject.type)}
              onChangeHandler={setEvidenceType}
              placeholder={"Select Type"}
              classNamePrefix="Select"
            />
          </div>
        </div>
        {subtypeOptions ? (
          <div className="form-group">
            <Label name={"Sub-Type"} />
            <div className="container-fluid">
              <SelectNew
                options={subtypeOptions}
                value={
                  subtypeOptions !== null
                    ? subtypeOptions.filter(
                        (option) => option.value === evidenceObject.subtype
                      )
                    : null
                }
                onChangeHandler={setEvidenceSubType}
                placeholder={"Select"}
                classNamePrefix="Select"
              />
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <InputNew
            name={"from"}
            placeholder={"From"}
            label={"From"}
            value={evidenceObject.from}
            onChangeHandler={changeFrom}
          />
        </div>
        {evidenceObject.type === "Communication" ? (
          <div className="form-group">
            <InputNew
              name={"to"}
              placeholder={"To"}
              label={"To"}
              value={evidenceObject.to}
              onChangeHandler={changeTo}
            />
          </div>
        ) : null}

        <div className="form-group">
          <Label
            name={"Related evidence"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidenceObject,
                    content: (
                      <RelatedEvidenceForm
                        key={evidenceObject.id}
                        evidenceObject={evidenceObject}
                        setEvidenceObject={setEvidenceObject}
                        evidenceId={evidenceObject.id}
                        caseObject={caseObject}
                        caseId={caseId}
                      />
                    ),
                    swapContent: {
                      content: [
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseId}
                        />,
                        <EvidenceFurtherDetailsForm
                          key={evidenceObject.id}
                          evidenceObject={evidenceObject}
                          setEvidenceObject={setEvidenceObject}
                          evidenceId={evidenceObject.id}
                          caseObject={caseObject}
                          caseId={caseId}
                          callback={callback}
                          inSwap
                        />,
                      ],
                      title: ["Doc Properties", "Further Details"],
                    },
                    title: "Related Evidence",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {evidenceObject.related_evidence.length
            ? evidenceObject.related_evidence.map((obj, index) => {
                return (
                  <div className="container-fluid">
                    <Medium14Font textColor={theme.colors.darkOpacity}>
                      {index + 1}. {obj.label}
                    </Medium14Font>
                  </div>
                );
              })
            : null}
        </div>
        <div className="form-group">
          <Label name={"Summary"} />
          <TextAreaSideBar
            withMargin
            placeholder={"Enter Summary"}
            value={evidenceObject.summary}
            name={"summary"}
            onChange={onChangeTextFieldHandler}
          />
        </div>
        <div className="form-group">
          <Label name={"Pre-empt"} />
          <TextAreaSideBar
            withMargin
            placeholder={"Enter Pre-empt"}
            value={evidenceObject.preempt}
            name={"preempt"}
            onChange={onChangeTextFieldHandler}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            style={{ height: "86%", overflowY: "auto" }}
          >
            <Button type="submit" wide primary>
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EvidenceFurtherDetailsForm;
