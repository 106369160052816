import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { colors } from "@atlaskit/theme";

import Chapter from "../Chapter";

import { Row, Column, GroupLabel, ExpandIcon } from "../styled";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return colors.R50;
  }
  if (isDraggingFrom) {
    return colors.T50;
  }
  return colors.N30;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;

const scrollContainerHeight = 40;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: ${scrollContainerHeight}px;

  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 4px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

const Container = styled.div``;

const InnerQuoteList = React.memo(function InnerQuoteList(props) {
  return props.quotes.map((quote, index) => (
    <Draggable key={quote.id} draggableId={`${quote.id}`} index={index}>
      {(dragProvided, dragSnapshot) => (
        <Chapter
          listId={props.listId}
          key={quote.id}
          quote={quote}
          isDragging={dragSnapshot.isDragging}
          isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
          provided={dragProvided}
          selectedChapter={props.selectedChapter}
          setSelectedChapter={props.setSelectedChapter}
          editItem={props.editItem}
          removeItem={props.removeItem}
        />
      )}
    </Draggable>
  ));
});

function InnerList(props) {
  const { quotes, dropProvided } = props;
  // const title = props.title ? <Title>{props.title}</Title> : null;
  // {title}

  return (
    <Container>
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList
          listId={props.listId}
          quotes={quotes}
          selectedChapter={props.selectedChapter}
          setSelectedChapter={props.setSelectedChapter}
          editItem={props.editItem}
          removeItem={props.removeItem}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

const Group = ({
  label = "group",
  defaultExpanded = false,
  children,

  listId = "LIST",
  listType,
  quotes,

  useClone,

  selectedChapter,
  setSelectedChapter,
  editItem,
  removeItem,
  ...props
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const isDropDisabled = false;

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      isDropDisabled={isDropDisabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <Chapter
                quote={quotes[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
                selectedChapter={selectedChapter}
                setSelectedChapter={setSelectedChapter}
                editItem={editItem}
                removeItem={removeItem}
                listId={listId}
              />
            )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          <InnerList
            quotes={quotes}
            listId={listId}
            selectedChapter={selectedChapter}
            setSelectedChapter={setSelectedChapter}
            // title={title}
            dropProvided={dropProvided}
            editItem={editItem}
            removeItem={removeItem}
          />
        </Wrapper>
      )}
    </Droppable>
  );

  // return (
  //   <Column style={{ marginTop: 15 }}>
  //     <Row
  //       alignCenter
  //       justifyBetween
  //       onClick={() => setExpanded(!expanded)}
  //       style={{
  //         marginBottom: "8px",
  //         paddingBottom: 4,
  //         // borderBottom: expanded
  //         //   ? "1px solid #374AC220"
  //         //   : "1px solid transparent",
  //         cursor: "pointer",
  //       }}
  //     >
  //       <GroupLabel>{label}</GroupLabel>
  //       <ExpandIcon
  //         style={{
  //           transform: expanded ? "rotate(0)" : "rotate(180deg)",
  //           cursor: "pointer",
  //         }}
  //       />
  //     </Row>
  //     {expanded && <Column>{children}</Column>}
  //   </Column>
  // );
};

export default Group;
