import React, { Fragment, useState } from "react";
import CaseCardStyled, { CaseCardLabelOverflowStyled } from "./styled";
import moment from "moment";

import { useHistory, useRouteMatch } from "react-router-dom";

import { Bold18Font, Medium14Font } from "@/components/FontsNewComponent/Fonts";
import LeftSideBarUserMenu from "@/components/baseAppComponents/LeftSideBarUserLayout/LeftSideBarUserMenu";
import ListActionButton from "@/components/buttons/ListActionButton/ListActionButton";

import pancilIcon from "@/assets/img/svg/pancil_icon.svg";
import personIcon from "@/assets/img/svg/person.svg";

const Item = ({ notebook, editNotebook, deleteNotebook }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [showPopup, setShowPopup] = useState(false);

  return (
    <CaseCardStyled
      onClick={() => {
        history.push({
          pathname: `${url}/${notebook.id}`,
        });
      }}
      style={{
        borderTopColor:
          notebook.type === "Application"
            ? "#FF9017"
            : notebook.type === "Custom"
            ? "#374AC2"
            : notebook.type === "Trial"
            ? "#259B9A"
            : "#259B9A",
      }}
      // className="m-1"
    >
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="row mb-2">
          <div className="col d-flex justify-content-between">
            {/* Case Type */}
            {notebook.user && notebook.user.name ? (
              <Medium14Font title="last updated">
                {notebook.user.name}
              </Medium14Font>
            ) : (
              <Medium14Font as="div" title="Case Type">
                N/A
              </Medium14Font>
            )}
            <div style={{ position: "relative" }}>
              <ListActionButton
                type="options"
                clickHandler={(e) => {
                  e.stopPropagation();
                  setShowPopup(!showPopup);
                }}
              />

              {showPopup && (
                <LeftSideBarUserMenu
                  menuItems={[
                    {
                      id: 1,
                      title: "View",
                      onClickHandler: (_, id, e) => {
                        e.stopPropagation();
                        history.push({
                          pathname: `${url}/${notebook.id}`,
                        });
                      },
                    },
                    {
                      id: 2,
                      title: "Edit",
                      onClickHandler: (_, id, e) => {
                        e.stopPropagation();
                        editNotebook(notebook.id);
                      },
                    },
                    {
                      id: 3,
                      title: "Delete",
                      onClickHandler: (_, id, e) => {
                        e.stopPropagation();
                        deleteNotebook(notebook.id);
                      },
                    },
                  ]}
                  style={{
                    width: "150px",
                    height: "144px",
                    left: "-90px",
                    bottom: "40px",
                    zIndex: 10,
                  }}
                  closeHandler={() => {
                    setShowPopup(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <Bold18Font className="mb-2 text-left" as={CaseCardLabelOverflowStyled}>
          {notebook.name}
        </Bold18Font>
        <div className="row mb-2">
          <div className="col">
            <Medium14Font title="last updated">{notebook.type}</Medium14Font>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex flex-wrap justify-content-between justify-content-xl-between align-items-center">
            {/* Clients role in case */}
            <Medium14Font title="Client role">
              <Fragment>
                {moment(notebook.created_at).format("DD/MM/YY")}
              </Fragment>
            </Medium14Font>
            {/* Last updated */}
            <Medium14Font title="last updated">
              <img className="mr-2" src={pancilIcon} alt={"date"} />
              {moment(notebook.updated_at).format("DD/MM/YY")}
            </Medium14Font>
          </div>
        </div>
      </div>
    </CaseCardStyled>
  );
  // return (
  //   <div>
  //     <span />
  //     {notebook.title}
  //   </div>
  // );
};

export default Item;
