import { createGlobalStyle } from "styled-components/macro";
import trialProoferAppIconsTTF from "../../assets/fonts/Trial-Proofer.ttf";
import trialProoferAppIconsWOFF from "../../assets/fonts/Trial-Proofer.woff";
import trialProoferAppIconsSVG from "../../assets/fonts/Trial-Proofer.svg";

const BaseAppGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Trial-Proofer';
    src:
      url("${trialProoferAppIconsTTF}?us6tzu") format('truetype'),
      url("${trialProoferAppIconsWOFF}?us6tzu") format('woff'),
      url("${trialProoferAppIconsSVG}?us6tzu#Trial-Proofer") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^="app-"], [class*=" app-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Trial-Proofer' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .app-cases-menu-icon:before {
    content: "\\e900";
  }
  .app-help-menu-icon:before {
    content: "\\e901";
  }
  .app-dashboard-menu-icon:before {
    content: "\\e902";
  }
  .app-arrow-down-icon:before {
    content: "\\e903";
  }
  .app-menu-hamburger-icon:before {
    content: "\\e904";
  }

  @media (max-width: 575.98px) {
    html {
      font-size: 15px;
    }
  }

  @media (min-width: 576px) {
    html {
      font-size: 14px;
    }
   }

  //Medium devices (tablets, 768px and up) md
  @media (max-width: 767.98px) {
    body {
      overflow-y: ${({ sideBarOpened }) =>
        sideBarOpened ? "hidden" : "visible"}
    }
   }

  //Medium devices (tablets, 768px and up) md
  @media (min-width: 768px) {
    html {
      font-size: 12px;
    }
   }

  // Large devices (desktops, 992px and up) lg
  @media (min-width: 992px) {
    html {
      font-size: 15px;
    }
   }

  // Extra large devices (large desktops, 1200px and up) xl
  //@media (min-width: 1200px) {
  //  html {
  //    font-size: 16px;
  //  }
  // }
  .cursor-pointer {
    cursor: pointer;
  }
  body {
    background: ${({ theme }) => theme.colors.lightGray};
  }


`;

export default BaseAppGlobalStyles;

// Change scrollbar on all elements
// * {
// &::-webkit-scrollbar {
//     width: 4px;
//   }
//
// &::-webkit-scrollbar-thumb {
//     border-radius: 3px;
//     background-color: ${({ theme }) => theme.colors.gray};
// }
// }
