import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import Select, { components } from "react-select";

import arrowDownBlueIcon from "../../assets/img3/svg/arrow-down-blue-icon.svg";
import { Bold14Font } from "../FontsNewComponent/Fonts";

const SelectAnotherTryStyled = styled(Select).attrs(
  ({ styles, classNamePrefix = "TrialHubDropdownFilter" }) => ({
    className: "TrialHubDropdownFilter",
    classNamePrefix,
    styles: styles,
  })
)`
  font-family: "PT Root UI", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  .TrialHubDropdownFilter {
    &__control {
      height: ${(props) => (props.compact ? "36px" : "42px")};
      width: 100%;
      border-radius: 50vh;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background: ${({ isDisabled }) =>
        isDisabled ? "#dcdce6" : "transparent"};
      cursor: pointer;

      :hover,
      :focus,
      :active {
        border: 1px solid ${({ theme }) => theme.colors.blue};

        // Make indicators container change color
        .TrialHubDropdownFilter__indicator-separator {
        }
      }
    }

    &__value-container {
      padding-left: ${(props) => (props.compact ? "12px" : "20px")};
    }

    &__indicator {
      padding-left: ;
      width: ${(props) => (props.compact ? "30px" : "44px")};
      height: 100%;
      border-radius: 0 50vh 50vh 0;
      background: ${({ theme }) => theme.colors.light};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__indicator-separator {
      margin: 0;
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.darkOpacity};
    }

    &__menu {
      border-radius: 21px;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      background-color: ${({ theme }) => theme.colors.light};
    }

    &__menu-list {
      max-height: 252px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 21px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.gray};
      }
    }

    &__option {
      cursor: pointer;

      :not(:first-child) {
        border-top: 1px solid ${({ theme }) => theme.colors.gray};
      }

      :hover,
      :focus,
      :active {
        // TODO: add to theme?
        color: hsl(0, 0%, 20%);
        background: #f0f0fa;
      }
    }
  }
`;

const DropdownIndicator = ({ ...props }) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrowDownBlueIcon} alt="" className="pr-1" />
    </components.DropdownIndicator>
  );
};

const SelectAnotherTry = ({
  label,
  style,
  reactSelectStyles,
  required,
  form,
  compact = false,
  ...props
}) => {
  return (
    <div className="container-fluid" style={style}>
      {label ? (
        <div className="row mb-2">
          <div className="col-12">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-12">
          <SelectAnotherTryStyled
            {...props}
            components={{ DropdownIndicator }}
            isSearchable={false}
            styles={reactSelectStyles}
            compact
          />
        </div>
      </div>
      {required && form ? (
        <div className="row">
          <div className="col">
            <input
              style={{
                width: "100%",
                height: "0px",
                opacity: "0",
                cursor: "default",
                position: "absolute",
                left: "0",
                bottom: "0",
              }}
              value={props.value && props.value.value ? props.value.value : ""}
              required={required}
              form={form}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

SelectAnotherTry.propTypes = {
  /**
   * Show label from above select component
   */
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,

  /**
   * Used in forms to check if element is required
   */
  required: PropTypes.bool,

  /**
   * Add this select to form
   */
  form: PropTypes.string,

  /**
   * React Select styles object. Need to add !important to styles to override existing styles
   */
  reactSelectStyles: PropTypes.object,
};

export default SelectAnotherTry;
