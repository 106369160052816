import React, { useEffect, useState } from "react";

import Button from "../../../components/buttons/Button";

import SubscriptionPagesWrapper from "../../../components/SubscriptionPagesWrapper";
import ToggleButton from "../../../components/buttons/ToggleButton/ToggleButton";
import PlanCard from "../../../components/PlanCard";
import planStarterIcon from "../../../assets/img3/svg/plan-starter-icon.svg";
import planMediumIcon from "../../../assets/img3/svg/plan-medium-icon.svg";
import planProIcon from "../../../assets/img3/svg/plan-pro-icon.svg";

import { useStripe } from "@stripe/react-stripe-js";

import { Bold30Font, Medium14Font } from "../../../components/Fonts/Fonts";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import {addSubscription, getSubscriptions, swapSubscription} from "../../Auth/auth";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { redirectUserToAppropriatePage } from "../../Auth/utils";
import {
  MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
  SHOW_MODAL,
  UPDATE_USER,
} from "../../../redux/types";
import modalErrorHandler, {
  handleErrorMessage,
} from "../../../components/Modal/modalErrorHandler";

const SubscriptionStep2Page = () => {
  const selectedSubscription = useSelector(
    (state) => state.subscription.selected
  );
  const location = useLocation();
  let product;
  if (location.state) {
    product = location.state.product;
  } else if (Object.keys(selectedSubscription).length) {
    product = selectedSubscription;
  } else {
    product = null;
  }
  const [plans, setPlans] = useState({});
  const [loading, setLoading] = useState(true);
  const [checkedPrice, setCheckedPrice] = useState(!product ? "" : product);
  const [is_annually, setIsAnnually] = useState(
    !product ? false : product.type !== "monthly"
  );
  const [isLoading, setIsLoading] = useState(false);
  const Icons = [planStarterIcon, planMediumIcon, planProIcon];
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const stripe = useStripe();

  // useEffect(() => {
  //   const tryRedirect = async () => {
  //     await redirectUserToAppropriatePage(isUserAuth, history, dispatch);
  //   };
  //   tryRedirect();
  // }, []);

  useEffect(() => {
    getSubscriptions()
      .then((resp) => {
        setPlans(resp);
        if (resp && resp.monthly && resp.monthly.length && !product) {
          setCheckedPrice({ ...resp.monthly[1], icon: Icons[1] });
        }
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  const handleClick = (price, icon) => {
    price.icon = icon;
    setCheckedPrice(price);
  };

  const handleChange = (event) => {
    setIsAnnually(event.target.checked);
    let index;
    // Check if we have data in checkedPrice and plans object have data:
    if (checkedPrice && Object.keys(plans).length) {
      // get type of checkedPrice and find it index in appropriate array of data:
      if (checkedPrice.type === "monthly") {
        index = plans.monthly.findIndex(
          (el) => el.price_id === checkedPrice.price_id
        );
        // than handleClick with opposite type of plan and appropriate icon:
        handleClick(plans.annually[index], Icons[index]);
        // and same with another group:
      } else if (checkedPrice.type === "annually") {
        index = plans.annually.findIndex(
          (el) => el.price_id === checkedPrice.price_id
        );
        handleClick(plans.monthly[index], Icons[index]);
      }
    }
  };

  const onClickContinueOrSubscribeHandler = async () => {
    setIsLoading(true);
    if (user.card_last_four) {
      const subscriptionObject = {
        product_id: checkedPrice.product_id,
        price_id: checkedPrice.price_id,
      };
      try {
        const resp = await swapSubscription(subscriptionObject);
        if (resp.result === "success") {
          if (product && typeof window.gtag !== "undefined") {
            window.gtag("event", "purchase", {
              currency: product.currency.toUpperCase(),
              transaction_id: resp.payment_method,
              value: parseFloat(product.price),
              items: [
                {
                  item_id: product.price_id,
                  item_name: product.name,
                  price: parseFloat(product.price),
                  quantity: 1
                },
              ],
            });
          }
          dispatch({ type: UPDATE_USER, payload: resp.user });
          dispatch({
            type: SHOW_MODAL,
            payload: MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
          });
        } else {
          if (resp.result === "action_required") {
            stripe
              .confirmCardPayment(resp.secret, {
                payment_method: resp.payment_method,
              })
              .then(function (result) {
                if (result.error) {
                  handleErrorMessage(result.error.message, dispatch);
                } else {
                  if (checkedPrice && typeof window.gtag !== "undefined") {
                    window.gtag("event", "purchase", {
                      currency: checkedPrice.currency.toUpperCase(),
                      transaction_id: resp.payment_method,
                      value: parseFloat(checkedPrice.price),
                      items: [
                        {
                          item_id: checkedPrice.price_id,
                          item_name: checkedPrice.name,
                          price: parseFloat(checkedPrice.price),
                          quantity: 1
                        },
                      ],
                    });
                  }
                  dispatch({ type: UPDATE_USER, payload: resp.user });
                  dispatch({
                    type: SHOW_MODAL,
                    payload: MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
                  });
                }
              });
          }
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        modalErrorHandler(e, dispatch);
      }
    } else {
      history.push({
        state: { product: checkedPrice },
        pathname: "/subscription/step3",
      });
    }
    setIsLoading(false);
  };

  let plans_to_show, cost_type;

  if (is_annually) {
    plans_to_show = plans ? plans["annually"] : [];
    cost_type = "Year";
  } else {
    plans_to_show = plans ? plans["monthly"] : [];
    cost_type = "Month";
  }

  // TODO: If something going wrong - check above if statement.
  if (user.features && user.features.length) {
    history.replace("/app");
  }

  return (
    <SubscriptionPagesWrapper>
      <div className="row">
        <div className="col d-flex flex-column justify-content-between align-items-center">
          <Medium14Font>Step 2 of 3</Medium14Font>
          <Bold30Font className="text-center mb-3">
            Choose the plan that’s right for you
          </Bold30Font>
          <Medium14Font className="text-center mb-2">
            Analyse at a glance how all the strands of the case piece together
          </Medium14Font>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Medium14Font
              style={{ lineHeight: "25px" }}
              className="d-block mr-5 mr-sm-5 mr-md-2"
            >
              Monthly
            </Medium14Font>
            <div className="mt-3">
              <ToggleButton
                checked={is_annually}
                onChange={handleChange}
                className="d-block mx-2"
              />
            </div>
            <Medium14Font
              style={{ lineHeight: "25px" }}
              className="d-block ml-5 ml-sm-5 ml-md-2"
            >
              Annually
            </Medium14Font>
          </div>
        </div>
      </div>
      <div
        className={loading ? "container-fluid h-75" : "container-fluid"}
        style={{ maxWidth: "1170px" }}
      >
        {loading ? (
          <div className="h-100 justify-content-center align-items-center d-flex">
            <PulseLoader size={30} color={theme.colors.blue} />
          </div>
        ) : (
          <div className="row mb-5">
            {plans_to_show.map((el, index) => (
              <div className="col-12 mb-3 col-sm-4 mb-sm-0" key={el.price_id}>
                <PlanCard
                  onClick={() => {
                    handleClick(el, Icons[index]);
                  }}
                  checked={el.product_id === checkedPrice.product_id}
                  features={el.features}
                  icon={Icons[index]}
                  cost={el.price}
                  title={el.name}
                  cost_type={cost_type}
                  noButton
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {loading ? null : (
        <div className="row mb-4">
          <div className="col d-flex justify-content-center">
            {user.card_last_four ? (
              <Button
                style={{ marginRight: "20px" }}
                secondary
                onClick={() =>
                  history.push({
                    state: { product: checkedPrice },
                    pathname: "/subscription/step3",
                  })
                }
              >
                Change Card
              </Button>
            ) : null}
            <Button
              disabled={checkedPrice === "" || isLoading}
              primary
              clickHandler={onClickContinueOrSubscribeHandler}
              loading={isLoading}
            >
              {user.card_last_four ? "Start Membership" : "Continue"}
            </Button>
          </div>
        </div>
      )}
    </SubscriptionPagesWrapper>
  );
};

export default SubscriptionStep2Page;
