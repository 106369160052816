import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import { restorePassword } from "../../../containers/Auth/auth";
import { route } from "../../../routes";
import modalErrorHandler from "../../Modal/modalErrorHandler";

import ConfirmEmailFormWrapperStyled from "../ConfirmEmailForm/styled/ConfirmEmailFormWrapperStyled";
import Button from "../../buttons/Button/Button";
import Input from "../../Input";

import { Bold30Font, Medium14Font } from "../../Fonts/Fonts";
import {
  RESET_PASSWORD,
  SAVE_USER_EMAIL,
  SET_AUTH_ACTION,
  SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET,
} from "../../../redux/types";

const ForgotPasswordForm = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let form = useRef(null);

  const onInputChangeHandler = (event) => {
    setEmail(event.target.value);
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!isLoading) {
      try {
        setIsLoading(true);
        await restorePassword(email);
        saveUserEmailToRedux(email);
        dispatch({ type: SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET });
        history.push(route.auth.confirmEmail, { from: location.pathname });
        //setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        modalErrorHandler(e, dispatch);
      }
    }
  };

  const saveUserEmailToRedux = (email) => {
    dispatch({ type: SAVE_USER_EMAIL, payload: email });
    dispatch({ type: SET_AUTH_ACTION, payload: RESET_PASSWORD });
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <ConfirmEmailFormWrapperStyled>
          <div className="row my-5">
            <div className="col d-flex flex-column align-items-center">
              <Bold30Font as="p">Forgot Password?</Bold30Font>
              <Medium14Font
                as="p"
                textColor={theme.colors.darkOpacity}
                className="text-center"
              >
                Please enter your email address below and we will send you a
                link to reset your password.
              </Medium14Font>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex flex-column align-items-center">
              <form
                className="w-100"
                onSubmit={onFormSubmitHandler}
                ref={(ref) => (form = ref)}
              >
                <div className="form-group d-flex justify-content-between mb-5">
                  <Input
                    name="email"
                    placeholder="Enter your email"
                    label="Email"
                    type="email"
                    onChangeHandler={onInputChangeHandler}
                  />
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <Button
                        primary
                        wide
                        type="submit"
                        clickHandler={() => null}
                        loading={isLoading}
                        disabled={isLoading}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ConfirmEmailFormWrapperStyled>
      </div>
    </div>
  );
};

export default withTheme(ForgotPasswordForm);
