import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import RsbCaseCardPreviewDotStyled from "./styled/RSBCaseCardPreviewDotStyled";
import {
  Bold14Font,
  Bold24Font,
  Medium14Font,
} from "../FontsNewComponent/Fonts";
import { route } from "../../routes";
import { useHistory } from "react-router-dom";
import {
  acceptInvite,
  deleteCase,
  deleteParties,
  makeRequest,
  rejectInvite,
  toggleArchiveCaseStatus,
} from "../../containers/Auth/auth";
import {
  GET,
  HIDE_RIGHT_SIDE_BAR,
  MODAL_CONFIRM_ELEMENT,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../redux/types";
import modalErrorHandler from "../Modal/modalErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../styled-components/Theme/Theme";
import { withTheme } from "styled-components/macro";
import CRUDButton from "../buttons/CRUDButton";
import Button from "../buttons/Button";
import eyeIcon from "../../assets/img3/svg/eye-icon.svg";
import personIcon from "../../assets/img3/svg/person.svg";
import pencilIcon from "../../assets/img3/svg/pancil_icon.svg";
import moment from "moment";
import ExpandableBlock from "../baseAppComponents/ExpandableBlock";
import {
  TagElementCyan,
  TagElementPurple,
} from "../TagElementStyled/TagElementStyled";
import { checkIfUserHaveSubscription } from "../../containers/Auth/utils";
import ButtonNew from "../buttons/Button/ButtonNew";

const RSBCaseCardInvitePreview = ({
  caseObject,
  deleteCaseFromView,
  showPreview,
  moveFromInvitedList,
  afterCloseCallback,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(true);
  const [archived, setArchived] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    if (afterCloseCallback) {
      return () => afterCloseCallback();
    }
  }, []);

  const sectionsToRender = [
    {
      id: 1,
      title: caseObject.new_case_type === 'Criminal' ? 'Offence' : 'Causes of Action',
      field: "causes_of_action",
      render: (el) => el.type.name,
    },
    {
      id: 2,
      title: "Themes",
      field: "themes",
      render: (el) => el.name,
    },
    {
      id: 3,
      title: "Issues",
      field: "issues",
      render: (el) => el.name,
    },
  ];

  const onClickAcceptInviteHandler = async () => {
    setButtonIsLoading(true);
    const submitObject = {
      token: caseObject.invite_token,
    };
    const resp = await acceptInvite(submitObject, dispatch);
    if (resp) {
      moveFromInvitedList(caseObject.id);
      showPreview();
    }
    setButtonIsLoading(false);
  };

  const onClickRejectInviteHandler = async () => {
    setButtonIsLoading(true);
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "Are you sure you want to reject invite?",
      beforeCloseHandler: async () => {
        const submitObject = {
          token: caseObject.invite_token,
        };
        const resp = await rejectInvite(submitObject, dispatch);
        if (resp) {
          deleteCaseFromView(caseObject.id);
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        }
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_CONFIRM_ELEMENT });

    setButtonIsLoading(false);
  };

  return (
    <Fragment>
      {/*<div*/}
      {/*  className="d-flex flex-column justify-content-between"*/}
      {/*  // style={{ height: "85vh" }}*/}
      {/*  style={{ maxWidth: "320px" }}*/}
      {/*>*/}

      {/* Case Type and Firm */}
      <div className="row px-3 mb-3">
        <div className="col d-flex justify-content-between">
          <RsbCaseCardPreviewDotStyled isArchive={archived} />
          <TagElementPurple overflow style={{ marginRight: "10px" }}>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.case_type ? caseObject.case_type.name : "no type"}
            </Bold14Font>
          </TagElementPurple>
          <TagElementCyan overflow>
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.firm_name || "no firm"}
            </Bold14Font>
          </TagElementCyan>
        </div>
      </div>
      {/* Case label */}
      <div className="row px-3 mb-4">
        <div className="col">
          <Bold24Font as="p">{caseObject.label}</Bold24Font>
        </div>
      </div>

      <div
        className="px-3"
        style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        {caseObject
          ? sectionsToRender.map((el) => (
              <ExpandableBlock
                key={el.id}
                config={el}
                dataObject={caseObject}
              />
            ))
          : null}
      </div>
      {/* Buttons */}
      <div
        className="container-fluid pt-3 px-3"
        style={{ borderTop: `1px solid ${theme.colors.gray}` }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-between mb-4">
            <div className="d-flex" title="Client role">
              <img src={personIcon} alt="" className="img-fluid mr-2" />
              {/* TODO: Is this right field? */}
              {caseObject.parties.length ? (
                <Medium14Font>{caseObject.parties[0].role}</Medium14Font>
              ) : (
                <Medium14Font>N/A</Medium14Font>
              )}
            </div>
            <div className="d-flex" title="last updated">
              <img src={pencilIcon} alt="" className="img-fluid mr-2" />
              <Medium14Font>
                {moment(caseObject.updated_at).format("DD-MM-YYYY")}
              </Medium14Font>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <ButtonNew
              primary
              wide
              clickHandler={onClickAcceptInviteHandler}
              loading={buttonIsLoading}
              disabled={buttonIsLoading}
            >
              Accept
            </ButtonNew>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <ButtonNew
              danger
              wide
              clickHandler={onClickRejectInviteHandler}
              loading={buttonIsLoading}
              disabled={buttonIsLoading}
            >
              Reject
            </ButtonNew>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RSBCaseCardInvitePreview.propTypes = {
  caseObject: PropTypes.object.isRequired,
};

export default withTheme(RSBCaseCardInvitePreview);
