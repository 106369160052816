import React, {Component} from 'react'
import Chart from "chart.js";

let myLineChart;

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "PT Root UI - Medium";
Chart.defaults.global.legend.display = false;
Chart.defaults.global.tooltips.titleFontSize = 16;
Chart.defaults.global.tooltips.bodyFontSize = 20;
Chart.defaults.global.layout.padding = {
  left: 0,
  right: 0,
  top: 20,
  bottom: 0
};
//--Chart Style Options--//

export default class LineGraph extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const {data, labels, data_name} = this.props;

    if (typeof myLineChart !== "undefined") myLineChart.destroy();

    myLineChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            fill: true,
            pointRadius: 5,
            pointBorderWidth: 3,
            backgroundColor: 'rgba(255,255,255, 0.5)',
            borderColor: "#374ac2",
            pointBorderColor: "#374ac2",
            pointBackgroundColor: "#ffffff",
            lineTension: 0,
            borderJoinStyle: 'miter',
            borderCapStyle: 'butt',
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {display: false},
            gridLines: {
              display: false,
              drawBorder: false
            }
          }],
          xAxes: [{
            ticks: {
              display: true,
              maxTicksLimit: 31,
              fontSize: 16,
            },
          }],
        },
        tooltips: {
          backgroundColor: "#374ac2",
          bodyFontSize: 14,
          bodyFontStyle: 'bold',
          bodyAlign: 'center',
          displayColors: false,
          titleFontSize: 16,
          xPadding: 10,
          yPadding: 10,
          callbacks: {
            label: (tooltipItem, data) => {
              return `${tooltipItem.value} ${parseInt(tooltipItem.value)%10 === 1 ?  data_name.single : data_name.plural}`
            },
            title: (tooltipItem, data) => {
              return null
            }
          },
        }
      }
    });

  };

  render() {

    return (
      <div className={'graphContainer'} style={{height: '300px', maxHeight: '50vh'}}>
        <canvas
          id="myChart"
          ref={this.chartRef}
        />
      </div>
    )
  }
}
