import React from "react";
import ToggleButtonLabelStyled from "./styled/ToggleButtonLabelStyled";
import ToggleButtonSliderStyled from "./styled/ToggleButtonSliderStyled";

const ToggleButton = (props) => {
  return (
    <ToggleButtonLabelStyled className={props.labelclass ?? null}>
      <input type="checkbox" {...props}/>
      <ToggleButtonSliderStyled />
    </ToggleButtonLabelStyled>
  );
};

export default ToggleButton;
