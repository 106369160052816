import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import BaseWhiteCard from "../BaseWhiteCard";
import RsbCaseCardPreviewDotStyled from "../../rightSideBarViews/styled/RSBCaseCardPreviewDotStyled";
import archiveIcon from "../../../assets/img3/svg/archive-icon.svg";
import editIcon from "../../../assets/img3/svg/edit-icon.svg";
import deleteIcon from "../../../assets/img3/svg/delete-icon.svg";
import Button from "../../buttons/Button";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import { withTheme } from "styled-components/macro";
import personIcon from "../../../assets/img3/svg/person.svg";
import pencilIcon from "../../../assets/img3/svg/pancil_icon.svg";
import moment from "moment";
import { route } from "../../../routes";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import {
  deleteCase,
  toggleArchiveCaseStatus,
} from "../../../containers/Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  TagElementCaseType,
  TagElementCoa,
  TagElementCyan,
  TagElementPurple,
} from "../../TagElementStyled/TagElementStyled";
import NA from "../../NA";
import useWindowSize from "../../../hooks/useWindowSize";
import CRUDButton from "../../buttons/CRUDButton/CRUDButton";
import { checkIfUserIsOwnerOfTheCase } from "../../../containers/Auth/utils";

const CaseArenaViewCard = ({ caseObject, theme, ...props }) => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [archived, setArchived] = useState(caseObject.archived);
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const { width } = useWindowSize();
  const editCard = () => {
    history.push(
      `${
        is_admin ? route.admin.baseApp.cases_edit : route.baseApp.cases_edit
      }` +
        "/" +
        caseObject.id
    );
  };

  const deleteCard = () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      beforeCloseHandler: async () => {
        await deleteCase(caseObject.id, dispatch);
        // await deleteCaseFromView(caseId);
        history.push(route.baseApp.cases);
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  const archiveCard = async () => {
    setIsBtnLoading(true);
    await toggleArchiveCaseStatus(caseObject.id, caseObject.archived, dispatch);
    // archiveCaseInView(caseId);
    setArchived((prevState) => !prevState);
    setIsBtnLoading(false);
  };

  const crudCardButtons = is_admin
    ? [
        {
          id: 3,
          label: "Edit",
          icon: editIcon,
          action: () => editCard(),
        },
      ]
    : checkIfUserIsOwnerOfTheCase(caseObject)
    ? [
        {
          id: 1,
          label: "Archive",
          icon: archiveIcon,
          action: () => archiveCard(),
        },
        {
          id: 2,
          label: "Delete",
          icon: deleteIcon,
          action: () => deleteCard(caseObject.id, dispatch),
        },
        {
          id: 3,
          label: "Edit",
          icon: editIcon,
          action: () => editCard(),
        },
      ]
    : [
        {
          id: 1,
          label: "Archive",
          icon: archiveIcon,
          action: () => archiveCard(),
        },
        {
          id: 2,
          label: "Edit",
          icon: editIcon,
          action: () => editCard(),
        },
      ];

  return (
    <BaseWhiteCard className="p-4 d-flex flex-column justify-content-between">
      <div className="row justify-content-between">
        <div className="col-12 mb-3 col-md-5 mb-md-0 d-flex">
          <RsbCaseCardPreviewDotStyled
            isArchive={archived}
            style={{ marginRight: "10px" }}
          />
          <TagElementCaseType
            overflow
            style={{ marginRight: "10px" }}
          >
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.new_case_type}
            </Bold14Font>
          </TagElementCaseType>
          <TagElementPurple
            overflow
            // style={{ marginRight: "10px", height: "1.5rem" }}
            style={{ marginRight: "10px" }}
          >
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.case_type ? caseObject.case_type.name : "no type"}
            </Bold14Font>
          </TagElementPurple>
          <TagElementCyan
            overflow
            // style={{ height: "1.5rem" }}
          >
            <Bold14Font style={{ color: "inherit" }}>
              {caseObject.firm_name ? caseObject.firm_name : "no firm"}
            </Bold14Font>
          </TagElementCyan>
        </div>
        {/*<div className="d-flex" style={{ maxWidth: rem("300px") }}></div>*/}
        {/*<div*/}
        {/*  className="d-flex justify-content-between"*/}
        {/*  style={{ width: rem("350px") }}*/}
        {/*></div>*/}

        {/* TODO: width < 1544 - change buttons layout */}
        <div className="col-12 d-flex justify-content-center mb-3 col-md-7 justify-content-md-end mb-md-0">
          <div
            className={`d-flex flex-wrap ${
              width > 767 ? "" : "w-100"
            } justify-content-${
              width > 767 || width < 325 ? "center" : "between"
            }`}
            style={{ maxWidth: "387px" }}
          >
            {width > 767
              ? crudCardButtons.map((el) => (
                  <Button
                    key={el.id}
                    secondary
                    clickHandler={el.action}
                    disabled={isBtnLoading}
                    style={{ marginRight: "10px", marginBottom: "5px" }}
                  >
                    <Fragment>
                      <img
                        src={el.icon}
                        alt=""
                        className="img-fluid mr-3"
                        style={{ width: "16px" }}
                      />
                      <Bold14Font textColor={theme.colors.blue}>
                        {el.label === "Archive"
                          ? archived
                            ? "Un-archive"
                            : "Archive"
                          : el.label}
                      </Bold14Font>
                    </Fragment>
                  </Button>
                ))
              : crudCardButtons.map((el) => {
                  return (
                    <CRUDButton
                      archiveButton={el.label === "Archive"}
                      editButton={el.label === "Edit"}
                      deleteButton={el.label === "Delete"}
                      archivedStatus={archived}
                      onClick={el.action}
                      disabled={isBtnLoading}
                      className="mr-2 mb-2"
                    />
                  );
                })}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Bold30Font style={{ fontSize: "1.575rem" }}>
            {caseObject.label ? caseObject.label : "New case"}
          </Bold30Font>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <div className="d-flex" title="Client role">
            <img src={personIcon} alt="" className="img-fluid mr-2" />
            {/* TODO: Is this right field? */}
            {caseObject.parties.length ? (
              <Medium14Font>{caseObject.parties[0].role}</Medium14Font>
            ) : (
              <NA />
            )}
          </div>
          <div className="d-flex" title="last updated">
            <img src={pencilIcon} alt="" className="img-fluid mr-2" />
            <Medium14Font>
              {moment(caseObject.updated_at).format("DD-MM-YYYY")}
            </Medium14Font>
          </div>
        </div>
      </div>
    </BaseWhiteCard>
  );
};

export default withTheme(CaseArenaViewCard);
