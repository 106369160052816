import styled from "styled-components/macro";

const PanelStyled = styled.div`
  //margin-top: 30px;
  //margin-bottom: 30px;
  border-bottom-left-radius: ${({ first }) => (first ? "4px" : 0)};
  border-top-left-radius: ${({ first }) => (first ? "4px" : 0)};
  border-bottom-right-radius: ${({ last }) => (last ? "4px" : 0)};
  border-top-right-radius: ${({ last }) => (last ? "4px" : 0)};
  border: 1px solid #dcdce6;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.white : "transparent"};
  height: 52px;
  cursor: pointer;
  ${({ active, theme }) =>
    active ? "border-bottom-color: " + theme.colors.blue : ""};
  ${({ active, theme }) => (active ? "border-bottom-width: 3px" : "")};
`;

export default PanelStyled;
