import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const SignUpGoogleAuthFormStyled = styled.form.attrs(({ onSubmit, ref }) => ({
  onSubmit: onSubmit,
  ref: ref,
}))`
  width: ${rem("470px")};

  @media (max-width: 767.98px) {
    width: 50rem;
  }
`;

export default SignUpGoogleAuthFormStyled;
