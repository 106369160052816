import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewPage,
  deletePageById,
  getPagesList,
  updatePageById,
} from "../../Auth/auth";
import { route } from "../../../routes";
import {
  HELP,
  PAGES,
} from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import SideBar from "../../../components/SideBar/SideBar";
import { PulseLoader } from "react-spinners";
import TinyMCE from "../../../components/TinyMCE";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SHOW_MODAL,
} from "../../../redux/types";
import InputNew from "../../../components/InputNew/InputNew";
import FormattedContentDivStyled from "./styled/FormattedContentDivStyled";
import SelectAnotherTry from "../../../components/Select/SelectAnotherTry";

export const FLOW_PAGES = "ADMIN/FLOW_PAGES";
export const FLOW_TUTORIALS = "ADMIN/FLOW_TUTORIALS";

const selectFilterFunction = (flow) => {
  switch (flow) {
    case FLOW_PAGES:
      return (page) => page.link !== null;

    case FLOW_TUTORIALS:
      return (page) => page.link === null;

    default:
      return () => false;
  }
};

const createSelectOptions = (array) => {
  return array.map((item, index) => ({
    label: item.title,
    value: index,
  }));
};

const PagesAndTutorials = ({ flow, theme }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const isAdmin = useSelector((state) => state.auth.user.is_admin);
  const [activeItem, setActiveItem] = useState(0);
  const [isDataChanged, setIsDataChanged] = useState(false);

  // data states
  const [pagesList, setPagesList] = useState([]);
  const [pagesListSelectOptions, setPagesListSelectOptions] = useState([]);
  const [pagesPreviousList, setPagesPreviousList] = useState([]);
  const obj = { title: "New tutorial item", body: "" };

  // get data

  const saveData = (resp) => {
    const filteredRespList = resp.filter(selectFilterFunction(flow));
    if (filteredRespList.length) {
      setPagesList(filteredRespList);
      setPagesPreviousList(filteredRespList);
      setPagesListSelectOptions(createSelectOptions(filteredRespList));
    } else if (!filteredRespList.length && flow === FLOW_TUTORIALS) {
      // console.log("?");
      setPagesList([obj]);
      setPagesPreviousList([obj]);
      setPagesListSelectOptions(createSelectOptions([obj]));
    }
  };

  const getDataFromAPI = async () => {
    const resp = await getPagesList();
    if (resp) {
      saveData(resp);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  // check if text changed
  useEffect(() => {
    if (
      pagesList.length &&
      pagesPreviousList.length &&
      (pagesList[activeItem].title !== pagesPreviousList[activeItem].title ||
        pagesList[activeItem].body !== pagesPreviousList[activeItem].body)
    ) {
      setIsDataChanged(true);
    } else {
      setIsDataChanged(false);
    }
  }, [pagesList, pagesPreviousList, activeItem]);

  // send data

  // button handlers

  const onClickSaveHandler = async () => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    let resp;
    if (flow === FLOW_PAGES) {
      resp = await updatePageById(
        pagesList[activeItem].id,
        dispatch,
        pagesList[activeItem]
      );
    } else if (flow === FLOW_TUTORIALS) {
      resp = pagesList[activeItem].id
        ? await updatePageById(
            pagesList[activeItem].id,
            dispatch,
            pagesList[activeItem]
          )
        : await createNewPage(dispatch, pagesList[activeItem]);
    }

    if (resp) {
      if (flow === FLOW_PAGES) {
        changeListContentBody(
          resp.body,
          pagesPreviousList,
          setPagesPreviousList
        );
      } else if (flow === FLOW_TUTORIALS) {
        changeListContentObject(resp, pagesPreviousList, setPagesPreviousList);
      }
    }
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  const onClickDeleteHandler = async () => {
    // TODO: Add modal
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        if (flow === FLOW_TUTORIALS && pagesList.length > 1) {
          if (pagesList[activeItem].id) {
            const resp = deletePageById(pagesList[activeItem].id, dispatch);
            if (resp) {
              setPagesList((prevState) =>
                prevState.filter((el) => el.id !== pagesList[activeItem].id)
              );
              setPagesPreviousList((prevState) =>
                prevState.filter((el) => el.id !== pagesList[activeItem].id)
              );
              setActiveItem(0);
            }
          } else {
            const pageListCopy = [...pagesList];
            pageListCopy.splice(activeItem, 1);
            setPagesList(pageListCopy);
            setPagesPreviousList(pageListCopy);
            setActiveItem(0);
          }
        }
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    // setIsSubmitting(false);
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  const onClickAddNewTutorialHandler = async () => {
    // if (pagesList[activeItem].body !== "") {
    setPagesList((prevState) => [...prevState, obj]);
    setPagesPreviousList((prevState) => [...prevState, obj]);
    setActiveItem((prevState) => prevState + 1);
    // }
  };

  // input handlers

  const changeListContentBody = (string, list, setList) => {
    const listCopy = [...list];
    listCopy.splice(activeItem, 1, {
      ...listCopy[activeItem],
      body: string,
    });
    setList(listCopy);
  };

  const onChangeSelectOptionHandler = (selectedOption) => {
    setActiveItem(selectedOption.value);
  };

  const changeListContentTitle = (string, list, setList) => {
    const listCopy = [...list];
    listCopy.splice(activeItem, 1, {
      ...listCopy[activeItem],
      title: string,
    });
    setList(listCopy);
  };
  const changeListContentObject = (object, list, setList) => {
    const listCopy = [...list];
    listCopy.splice(activeItem, 1, object);
    setList(listCopy);
  };

  const onChangePageBodyHandler = (content) => {
    changeListContentBody(content, pagesList, setPagesList);
  };

  const onChangeInputTitleHandler = (event) => {
    changeListContentTitle(event.target.value, pagesList, setPagesList);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const pageConfig = (flow) => {
    switch (flow) {
      case FLOW_TUTORIALS:
        return [
          {
            path: route.admin.baseApp.tutorials,
            title: "Tutorials",
            activeMenuItem: HELP,
          },
        ];

      case FLOW_PAGES:
        return [
          {
            path: route.admin.baseApp.pages,
            title: "Pages",
            activeMenuItem: PAGES,
          },
        ];

      default:
        return [];
    }
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig(flow)} />
      <div className="row mb-4">
        <div className="col d-flex flex-wrap justify-content-between">
          <Bold30Font>{pageConfig(flow)[0].title}</Bold30Font>
          {pagesList.length && isAdmin ? (
            <div>
              <ButtonNew
                clickHandler={onClickSaveHandler}
                primary
                loading={isSubmitting}
                disabled={isSubmitting}
                {...(flow === FLOW_TUTORIALS
                  ? { style: { marginRight: "20px" } }
                  : null)}
              >
                Save
              </ButtonNew>
              {flow === FLOW_TUTORIALS ? (
                <ButtonNew
                  clickHandler={onClickDeleteHandler}
                  danger
                  loading={isSubmitting}
                  disabled={pagesList.length === 1 || isSubmitting}
                >
                  Delete
                </ButtonNew>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="row justify-content-between">
        {!isAdmin ? (
          <div className="col-12 d-flex justify-content-end align-items-center">
            <Medium14Font style={{ marginRight: "10px" }}>
              Need help? Email us at
            </Medium14Font>
            <Bold14Font>
              <a href="mailto:support@trialproofer.ie">
                support@trialproofer.ie
              </a>
            </Bold14Font>
          </div>
        ) : null}
        <div className="col-3 d-none d-md-flex flex-column align-items-center">
          <div className="w-100 mb-3">
            <SideBar
              items={pagesList.map((item) => item.title)}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          </div>
          {isAdmin && flow === FLOW_TUTORIALS ? (
            <ButtonNew clickHandler={onClickAddNewTutorialHandler} tertiary>
              Add New Tutorial
            </ButtonNew>
          ) : null}
        </div>
        <div className="col-12 mb-3 d-flex d-md-none flex-column align-items-center">
          <SelectAnotherTry
            style={{ padding: "0" }}
            options={pagesListSelectOptions}
            onChange={onChangeSelectOptionHandler}
            value={
              pagesListSelectOptions.filter(
                (item) => item.value === activeItem
              )[0]
            }
          />
        </div>
        {pagesList.length ? (
          <div className="col-12 col-md-8">
            <div className="row mb-3">
              <div className="col position-relative">
                <Bold24Font>{pagesList[activeItem].title}</Bold24Font>
                {isDataChanged ? (
                  <div
                    className="alert alert-danger position-absolute"
                    style={{ padding: "0 1.25rem", top: "0", right: "15px" }}
                  >
                    You have unsaved data!
                  </div>
                ) : null}
              </div>
            </div>
            {flow === FLOW_TUTORIALS && isAdmin ? (
              <div style={{ margin: "0 -15px 1rem" }}>
                <InputNew
                  placeholder="Write tutorial title..."
                  name="title"
                  label="Title"
                  onChangeHandler={onChangeInputTitleHandler}
                  value={pagesList[activeItem].title}
                />
              </div>
            ) : null}
            <div className="row">
              {isAdmin ? (
                <div className="col">
                  {/*<FormattedContentDivStyled>*/}
                  <TinyMCE
                    key={activeItem}
                    content={pagesList[activeItem].body}
                    setContent={onChangePageBodyHandler}
                  />
                  {/*</FormattedContentDivStyled>*/}
                </div>
              ) : (
                <div className="col">
                  <FormattedContentDivStyled
                    dangerouslySetInnerHTML={{
                      __html: pagesList[activeItem].body,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="col">
            <FormattedContentDivStyled>
              <TinyMCE
                key={activeItem}
                content={pagesList[activeItem].body}
                setContent={onChangePageBodyHandler}
              />
            </FormattedContentDivStyled>
          </div>
        )}
      </div>
    </Fragment>
  );
};

PagesAndTutorials.propTypes = {
  flow: PropTypes.oneOf([FLOW_PAGES, FLOW_TUTORIALS]).isRequired,
};

export default withTheme(PagesAndTutorials);
