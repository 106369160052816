import React, { useEffect, useState, useRef, useContext } from "react";
import { NotebookContext } from "@/context";
import styled from "styled-components";
import DragHandle from "@editor/components/DragHandle";
import { listenForOutsideClick } from "@/hooks/onClickOutside";

import caseLegendClose from "@/assets/img/svg/case-legend-close.svg";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Container = styled.div`
  position: absolute;
  background: white;
  // width: 400px;
  // height: 600px;
  z-index: 10;
  border: 1px solid rgba(55, 74, 194, 0.1);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: -24px;
  disply: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 134px;
  box-shadow: 0px 3.923076868057251px 17.653846740722656px 0px #3939391a;
  cursor: default;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const H1 = styled.h1`
  width: min-content;
  font-size: 18px;
  font-weight: 700;
  color: #0f122f;
  margin-bottom: 22px;
`;
const H2 = styled.h2`
  width: min-content;
  font-size: 15px;
  font-weight: 500;
  color: #0f122f;
  text-transform: uppercase;
  margin-bottom: 19px;
`;
const H3 = styled.h3`
  width: min-content;
  font-size: 14px;
  font-weight: 600;
  color: #6a6c7d;
  margin-bottom: 13px;
`;

const Tags = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

// Objects:
// party, evidence, witness, authority, topic

// Tags:
// relevance
// 'themes', 'subthemes', 'issues', 'keyfacts', 'cause of action', 'elements'

// other
// 'objective', 'key section', 'exam line', 'application papers'

const CaseLegendPanel = ({ close }) => {
  const [listening, setListening] = useState(false);
  const menuRef = useRef(null);

  const { caseObject } = useContext(NotebookContext);

  useEffect(
    listenForOutsideClick(listening, setListening, menuRef, (e) => {
      close();
    })
  );

  const objects = [
    {
      type: "party",
      label: "party",
    },
    {
      type: "evidence",
      label: "evidence",
    },
    {
      type: "witness",
      label: "witness",
    },
    {
      type: "authority",
      label: "authority",
    },
    {
      type: "topic",
      label: "topic",
    },
    //
  ];

  const relevence = [
    {
      type: "theme",
      label: "themes",
    },
    {
      type: "subtheme",
      label: "subthemes",
    },
    {
      type: "issue",
      label: "issues",
    },
    {
      type: "keyfact",
      label: "keyfacts",
    },
    {
      type: "causesofaction",
      label:
        caseObject.new_case_type === "Criminal"
          ? "Offences"
          : "cause of action",
    },
    {
      type: "causesofactionelement",
      label: caseObject.new_case_type === "Criminal" ? "Offences" : "elements",
    },
    //
  ];

  const other = [
    {
      type: "objective",
      label: "objective",
    },
    {
      type: "keysection",
      label: "key section",
    },
    {
      type: "examline",
      label: "exam line",
    },
    {
      type: "paper",
      label: "application papers",
    },
    //
  ];

  return (
    <Container ref={menuRef}>
      <CloseButton src={caseLegendClose} alt="Close" onClick={close} />
      <H1>Legend</H1>
      <H2>Objects:</H2>
      {/*
       */}
      <Tags style={{ paddingBottom: 16, borderBottom: "1px solid #374AC210" }}>
        {objects.map((object) => (
          <div className={`tag tag-${object.type}`} style={{ marginRight: 8 }}>
            <DragHandle
              className={`fill--${object.type.toLowerCase()}`}
              // fill="blue"
              style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
            />
            {capitalize(object.label)}
          </div>
        ))}
      </Tags>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 16,
        }}
      >
        <H2 style={{}}>Tags:</H2>
        <H3>Relevence</H3>
        {/*
         */}
        <Tags style={{ marginBottom: 16 }}>
          {relevence.map((object) => (
            <div
              className={`tag tag-${object.type}`}
              style={{ marginRight: 8 }}
            >
              <DragHandle
                className={`fill--${object.type.toLowerCase()}`}
                // fill="blue"
                style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
              />
              {capitalize(object.label)}
            </div>
          ))}
        </Tags>
        <H3>Other</H3>
        {/*
         */}
        <Tags>
          {other.map((object) => (
            <div
              className={`tag tag-${object.type}`}
              style={{ marginRight: 8 }}
            >
              <DragHandle
                className={`fill--${object.type.toLowerCase()}`}
                // fill="blue"
                style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
              />
              {capitalize(object.label)}
            </div>
          ))}
        </Tags>
      </div>
    </Container>
  );
};

export default CaseLegendPanel;
