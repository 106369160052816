import styled from "styled-components/macro";
import { Bold18Font, Medium18Font } from "../../../FontsNewComponent/Fonts";

const AdminMenuStyled = styled.div`
  display: flex;
  .menu-item {
    color: ${({ theme }) => theme.colors.dark};
    cursor: pointer;
    :active {
      color: ${({ theme }) => theme.colors.blue};
    }

    &__active {
      ${Bold18Font} {
        position: relative;

        &:after {
          content: "";
          width: 8px;
          height: 3px;
          border-radius: 2px;
          background-color: ${({ theme }) => theme.colors.blue};
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
    }
  }

  ${Bold18Font} {
    @media (max-width: 575.98px) {
      font-size: 1.675rem;
    }
  }

  ${Medium18Font} {
    @media (max-width: 575.98px) {
      font-size: 1.675rem;
    }
  }
`;

export default AdminMenuStyled;
