import React, { useState, useEffect, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import { handleFlatRelevanceElements } from "@/utils/function";
import { loadAccessToken, uploadAuthorityFile } from "@/containers/Auth/auth";
import { maxFileSize } from "@/utils";
import moment from "moment";

import { Medium14Font } from "@/components/FontsNewComponent/Fonts";
import { parseISO } from "date-fns";
import axios from "axios";
import CloseIcon from "@/components/svgIcons/CloseIcon";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
import CustomReactDatepicker from "@/components/inputs/CustomReactDatepicker";
import TextArea from "@/components/TextArea/TextArea";
import UploadSingleFileInput from "@/components/inputs/UploadSingleFileInput";
import ButtonAnotherTry from "@/components/buttons/Button/ButtonAnotherTry";
import EditObjectPanel from "@/components/Notebook/EditObjectPanel";

import { Container, ButtonContainer, Column } from "./styled";
import {
  SHOW_RIGHT_SIDE_BAR,
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
} from "@/redux/types";
import { Select } from "antd";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";

import AssociateElementsThemesIssuesForm from "@/components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import AssociationTable from "@/components/AssociationTable";

// authority types constants:
const LEGISLATION = "Legislation";
const CASE = "Case";
const COURT_RULE = "Court Rule";
const ACADEMIC_TEXT = "Academic Text";
const OTHER = "Other";

const NEW_AUTHORITY_LABEL = "New Authority";

const authorityTypeOptions = [
  {
    label: LEGISLATION,
    value: LEGISLATION,
  },
  {
    label: CASE,
    value: CASE,
  },
  {
    label: COURT_RULE,
    value: COURT_RULE,
  },
  {
    label: ACADEMIC_TEXT,
    value: ACADEMIC_TEXT,
  },
  {
    label: OTHER,
    value: OTHER,
  },
];

// authority status constants:
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const MIXED = "Mixed";

const authorityStatusOptions = [
  {
    label: SUPPORTING,
    value: SUPPORTING,
  },
  {
    label: OPPOSING,
    value: OPPOSING,
  },
  {
    label: MIXED,
    value: MIXED,
  },
];

// authority Jurisdictions:
const DOMESTIC = "Domestic";
const EUROPEAN_UNION = "European Union";
const INTERNATIONAL = "International";

const authorityJurisdictionOptions = [
  {
    label: DOMESTIC,
    value: DOMESTIC,
  },
  {
    label: EUROPEAN_UNION,
    value: EUROPEAN_UNION,
  },
  {
    label: INTERNATIONAL,
    value: INTERNATIONAL,
  },
];

const EditAuthority = (props) => {
  const prevWitObj = useSelector((state) => state.rightSideBar.editableObject);
  const [authorityObject, setAuthorityObject] = useState(
    { ...prevWitObj } || {}
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  const { caseObject, setCaseObject } = useContext(NotebookContext);

  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];

  const [file, setFile] = useState(new File([""], "Select file"));

  useEffect(() => {
    if (prevWitObj) {
      const newObj = { ...authorityObject, ...prevWitObj };
      setAuthorityObject((oldWit) => ({ ...oldWit, ...prevWitObj }));
    }
  }, [prevWitObj]);

  useEffect(() => {
    setFlagRelevanceSettings((o) => ({
      ...o,
      objects: handleFlatRelevanceElements(
        authorityObject.causes_of_action_parent ?? [],
        authorityObject.causes_of_action ?? [],
        authorityObject.causes_of_action_defensive ?? [],
        authorityObject.issues_parent ?? [],
        authorityObject.issues ?? [],
        authorityObject.themes_parent ?? [],
        authorityObject.themes ?? [],
        authorityObject.case
      ),

      actions: [
        {
          type: "addButton",
          buttonText: "Associate Elements, Themes, Issues",
          callback: () => handleAssociateElementsThemesIssues(authorityObject),
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => {
            handleAssociateElementsThemesIssues(authorityObject, index);
          },
        },
        {
          type: "delete",
          clickHandler: (index) => {
            switch (index) {
              case 0:
                updateAuthorityObject({
                  causes_of_action: [],
                  causes_of_action_parent: [],
                  causes_of_action_defensive: [],
                });
                break;

              case 1:
                updateAuthorityObject({
                  themes: [],
                  themes_parent: [],
                });
                break;

              case 2:
                updateAuthorityObject({
                  issues: [],
                  issues_parent: [],
                });
                break;

              default:
                break;
            }
          },
        },
      ],
    }));
  }, [authorityObject, caseObject]);

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  useEffect(() => {
    if (!authorityObject || Object.keys(authorityObject).length === 0) {
      fetchObject();
    } else {
      const object = authorityObject;

      setAuthorityObject((wit) => ({ ...wit, ...object }));

      if (object.file_new) {
        setFile(object.file_new);
      } else if (object.file) {
        if (object.file) {
          setFile(new File([""], object.file));
        }
      }
    }
  }, []);

  const fetchObject = async () => {
    setDataLoading(true);
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(`/api/cases/${caseId}/authority/${props.tagId}`, config)
      .then((res) => {
        setDataLoading(false);
        if (res && res.data && res.data.data) {
          const object = res.data.data;

          setAuthorityObject((wit) => ({ ...object, ...wit }));

          if (object.file) {
            setFile(new File([""], object.file));
          }
        }
      });
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  const saveResult = async () => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let body = {
      ...authorityObject,
    };

    delete body.file_new;

    let endpoint = `/api/cases/${caseId}/authority/${props.tagId}`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (file.size !== 0) {
      let formData = new FormData();
      formData.append("file", file);
      const fileResp = await uploadAuthorityFile(
        props.tagId,
        caseId,
        dispatch,
        formData
      );
    }

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "witness" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "evidence"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: authorityObject.title },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.Authority.findIndex(
      (i) => i.id == props.tagId
    );
    const newAuthority = [
      ...caseLibrary.Authority.slice(0, itemIndex),
      {
        ...caseLibrary.Authority[itemIndex],
        label: authorityObject.title,
      },
      ...caseLibrary.Authority.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      Authority: newAuthority,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const updateAuthorityObject = (data) => {
    const obj = { ...authorityObject, ...data };
    setAuthorityObject(obj);
    dispatch({
      type: SET_EDITABLE_OBJECT,
      editableObject: {
        ...obj,
        // witnesses_res: witnesses,
      },
    });
  };

  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      // type: SHOW_RIGHT_SIDE_BAR_TWO,
      url: history.location.pathname,
      rightBarWidth: "18%",
      editableObject: {
        ...authorityObject,
        // witnesses_res: witnesses,
      },
      content: (
        <AssociateElementsThemesIssuesForm
          key={authorityObject.id}
          caseId={caseId}
          updateCaseObject={(d) => {
            setCaseObject((obj) => ({ ...obj, d }));
            setAuthorityObject((obj) => ({ ...obj, case: { ...obj.case, d } }));
          }}
          evidenceObject={authorityObject}
          setEvidenceObject={(data) => {
            updateAuthorityObject(data);
          }}
          selectedMenuTab={index}
          addToSwapContent={[
            <EditObjectPanel {...props} authorityObject={authorityObject} />,
          ]}
          rightBarWidth={"18%"}
          // otherSidebar
        />
      ),
      swapContent: {
        content: (
          <EditObjectPanel authorityObject={authorityObject} {...props} />
        ),
      },
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const setAuthorityFlagRelevanceElements = (causes_of_action) => {
    updateAuthorityObject({ causes_of_action });
  };
  const setAuthorityFlagRelevanceDefenceElements = (
    causes_of_action_defensive
  ) => {
    updateAuthorityObject({ causes_of_action_defensive });
  };
  const setAuthorityFlagRelevanceParentElements = (causes_of_action_parent) => {
    updateAuthorityObject({ causes_of_action_parent });
  };
  const setAuthorityFlagRelevanceThemes = (themes) => {
    updateAuthorityObject({ themes });
  };
  const setAuthorityFlagRelevanceParentThemes = (themes_parent) => {
    updateAuthorityObject({ themes_parent });
  };
  const setAuthorityFlagRelevanceIssues = (issues) => {
    updateAuthorityObject({ issues });
  };
  const setAuthorityFlagRelevanceParentIssues = (issues_parent) => {
    updateAuthorityObject({ issues_parent });
  };
  const flagRelevanceSettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        callback: () => handleAssociateElementsThemesIssues(authorityObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(authorityObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          switch (index) {
            case 0:
              updateAuthorityObject({
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              });
              break;

            case 1:
              updateAuthorityObject({
                themes: [],
                themes_parent: [],
              });
              break;

            case 2:
              updateAuthorityObject({
                issues: [],
                issues_parent: [],
              });
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      authorityObject.causes_of_action_parent ?? [],
      authorityObject.causes_of_action ?? [],
      authorityObject.causes_of_action_defensive ?? [],
      authorityObject.issues_parent ?? [],
      authorityObject.issues ?? [],
      authorityObject.themes_parent ?? [],
      authorityObject.themes ?? [],
      authorityObject.case
    ),
    setObjectsFunctions: {
      setElements: setAuthorityFlagRelevanceElements,
      setThemes: setAuthorityFlagRelevanceThemes,
      setIssues: setAuthorityFlagRelevanceIssues,
    },
    setParentObjectsFunctions: {
      setElementsParent: setAuthorityFlagRelevanceParentElements,
      setThemesParent: setAuthorityFlagRelevanceParentThemes,
      setIssuesParent: setAuthorityFlagRelevanceParentIssues,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setAuthorityFlagRelevanceDefenceElements([
                          ...listDefElementsCopy,
                        ]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        updateAuthorityObject({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        updateAuthorityObject({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        updateAuthorityObject({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const [flagRelevanceSettings, setFlagRelevanceSettings] = useState(
    flagRelevanceSettingsDef
  );

  if (
    dataLoading ||
    !caseObject ||
    !authorityObject ||
    Object.keys(authorityObject).length === 0
  ) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={authorityObject.title}
              onChangeHandler={(e) => {
                e.persist();
                setAuthorityObject((obj) => ({
                  ...obj,
                  title: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Type</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={authorityTypeOptions}
                  value={
                    authorityObject.type
                      ? {
                          label: authorityObject.type,
                          value: authorityObject.type,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setAuthorityObject((obj) => ({
                      ...obj,
                      type: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Status</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={authorityStatusOptions}
                  value={
                    authorityObject.status
                      ? {
                          label: authorityObject.status,
                          value: authorityObject.status,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setAuthorityObject((obj) => ({
                      ...obj,
                      status: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Jurisdiction</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={authorityJurisdictionOptions}
                  value={
                    authorityObject.jurisdiction
                      ? {
                          label: authorityObject.jurisdiction,
                          value: authorityObject.jurisdiction,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setAuthorityObject((obj) => ({
                      ...obj,
                      jurisdiction: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className={"col-12"}>
            <AssociationTable
              settings={flagRelevanceSettings}
              label="Flag Relevance"
              compact
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <UploadSingleFileInput
              label="Upload File"
              file={file}
              setFile={(file) => {
                setFile(file);
                setAuthorityObject((obj) => ({
                  ...obj,
                  file_new: file,
                }));
              }}
              validTypes={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "application/pdf",
              ]}
              maxFileSize={maxFileSize}
            />
            {(file
              ? file.name === authorityObject.file
              : authorityObject.file_id) && (
              <div className="container-fluid" style={{ marginTop: 12 }}>
                <a
                  href={`/app/cases/${caseId}/documents/${authorityObject.file_id}`}
                  target="_blank"
                >
                  <ButtonNew
                    clickHandler={() => {}}
                    primary
                    style={{ width: "100%" }}
                  >
                    View attachment
                  </ButtonNew>
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="key_sections"
              type="text"
              placeholder="Sections"
              label="Sections"
              value={authorityObject.key_sections}
              onChangeHandler={(e) => {
                e.persist();
                setAuthorityObject((obj) => ({
                  ...obj,
                  key_sections: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="citation"
              type="text"
              placeholder="Citation"
              label="Citation"
              value={authorityObject.citation}
              onChangeHandler={(e) => {
                e.persist();
                setAuthorityObject((obj) => ({
                  ...obj,
                  citation: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        {/*
         */}
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult();
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

const MultiSelect = ({
  onChange,
  placeholder = "Placeholder",
  label = "Label",
  options = [
    { label: "label1", value: "value1" },
    { label: "label2", value: "value2" },
    { label: "label3", value: "value3" },
  ],
  type = "objective",
  value,
  labelInValue = true,
}) => {
  return (
    <div className="form-group row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            labelInValue={labelInValue}
            value={value}
            showSearch
            filterOption={(input, option) => {
              return ("" + option.label)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            // onSelect={(value) => {
            // }}
            tagRender={(props) => {
              return (
                <span
                  style={{ marginRight: 5, marginBottom: 2, marginTop: 2 }}
                  className={`tag tag-${type}`}
                >
                  {props.label}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditAuthority);
