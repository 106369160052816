import React from "react";
import PropTypes from "prop-types";
import SignUpForm from "../../../components/forms/SignUpForm";
import SignPagesWrapper from "../../../components/SignPagesWrapper";

export const signUpFlow = {
  INVITE_BY_TOKEN: "INVITE_BY_TOKEN",
};

const SignUpPage = ({ flow }) => {
  return (
    <SignPagesWrapper>
      <SignUpForm flow={flow} />
    </SignPagesWrapper>
  );
};

SignUpPage.propTypes = {
  flow: PropTypes.string,
};

export default SignUpPage;
