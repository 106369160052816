import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import NotebooksList from "./NotebooksList";

import PageConfiguration from "@/components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "@/components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { getCaseById } from "@/containers/Auth/auth";

const NotebooksListPage = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [caseObject, setCaseObject] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // Get data from API
  const getDataFromAPI = async () => {
    const caseResp = await getCaseById(id, dispatch);
    if (is_admin && !caseResp) {
      history.push("/admin/all-cases");
    }
    if (caseResp) {
      setCaseObject(caseResp);
      setIsLoading(false);
    }
  };

  // Set data to state after component mount
  useEffect(() => {
    getDataFromAPI();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/notebooks`
        : `/admin/all-cases/${id}/notebooks`,
      title: "Notebooks",
      activeMenuItem: CASES,
    },
  ];

  return (
    <div className="d-flex flex-column">
      <PageConfiguration configArray={pageConfig} />
      <NotebooksList />
    </div>
  );
};

export default withTheme(NotebooksListPage);
