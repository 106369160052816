import { ReactRenderer } from "@tiptap/react";
import { PluginKey } from "prosemirror-state";
import tippy from "tippy.js";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { TagsSuggestionsList } from "../../components/SuggestionsList/TagsSuggestionsList";

// https://dev.trialproofer.ie/api/cases/1/notes/autocomplete?text=evidence'

//

import { loadAccessToken } from "@/containers/Auth/auth";

export default () => {
  return {
    char: "",
    pluginKey: new PluginKey("custom"),
    items: async ({ query, words }) => {
      const { pathname } = window.location;
      const caseId = pathname.split("/")[3];

      const config = {
        headers: {
          Authorization: `Bearer ${await loadAccessToken()}`,
        },
      };

      let res1;

      if (words.word1) {
        res1 = await axios.get(
          `/api/cases/${caseId}/notes/autocomplete?text=${
            words.word3 || words.word2 || words.word1
          }`,
          config
        );
      }

      let results = [];

      // autocomplete things
      if (res1 && res1.data && res1.data.items) {
        results = [
          ...results,
          ...res1.data.items.map((i) => ({
            ...i,
            tagId: i.id,
            suggestionType: "item",
          })),
        ];
      }
      if (res1 && res1.data && res1.data.create) {
        results = [
          ...results,
          ...res1.data.create.map((i) => ({
            ...i,
            suggestionType: "create",
          })),
        ];
      }

      return results;
    },

    render: () => {
      let reactRenderer;
      let popup;

      return {
        onStart: (props) => {
          reactRenderer = new ReactRenderer(TagsSuggestionsList, {
            props: {
              ...props,
              hideWhenNoneFound: true,
              tags: true,
            },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            let transaction = props.view.state.tr.setMeta("cancelPopup", true);
            props.view.dispatch(transaction);
            props.view.focus();
          }

          if (reactRenderer) {
            return reactRenderer.ref?.onKeyDown(props);
          }
        },

        onExit() {
          // console.log(popup);
          // console.log(reactRenderer);
          if (popup) {
            popup[0].destroy();
          }

          if (reactRenderer) {
            reactRenderer.destroy();
          }
        },
      };
    },
  };
};
