export const partyTypes = {
  MOVING_PARTY: "Moving Party",
  RESPONDING_PARTY: "Responding Party",
  OTHER_PARTY: "Other",
};
export const criminalPartyRoles = [
  {
    label: "Defendant",
    value: "Defendant",
    partyType: partyTypes.RESPONDING_PARTY,
  },
  {
    label: "Prosecutor",
    value: "Prosecutor",
    partyType: partyTypes.MOVING_PARTY,
  },

];
export const partyRoles = [
  {
    label: "Plaintiff",
    value: "Plaintiff",
    partyType: partyTypes.MOVING_PARTY,
  },
  {
    label: "Defendant",
    value: "Defendant",
    partyType: partyTypes.RESPONDING_PARTY,
  },
  {
    label: "Third Party",
    value: "Third Party",
    partyType: partyTypes.OTHER_PARTY,
  },
  {
    label: "Applicant",
    value: "Applicant",
    partyType: partyTypes.MOVING_PARTY,
  },
  {
    label: "Respondent",
    value: "Respondent",
    partyType: partyTypes.RESPONDING_PARTY,
  },
  {
    label: "Notice Party",
    value: "Notice Party",
    partyType: partyTypes.OTHER_PARTY,
  },
  {
    label: "Appellant",
    value: "Appellant",
    partyType: partyTypes.MOVING_PARTY,
  },
];
