import styled from "styled-components/macro";
import rem from "../../../utils/rem";
import { Bold24Font, Medium18Font } from "../../Fonts/Fonts";

const FeatureCardStyled = styled.div`
  max-width: ${rem("270px")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767.98px) {
    ${Bold24Font} {
      font-size: 2.4rem;
    }

    ${Medium18Font} {
      font-size: 2.03rem;
    }
  }
`;

export default FeatureCardStyled;
