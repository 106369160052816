import React from "react";
import rem from "../../utils/rem";
import logo from "../../assets/img3/svg/logo-green-white-beta.svg";
import logoDark from "../../assets/img3/svg/logo-green-dark-beta.svg";
import logoWhite from "../../assets/img3/svg/logo-white-beta.svg";

const Logo = ({ dark, white, ...props }) => {
  let logoImage = logo;

  if (dark) {
    logoImage = logoDark;
  } else if (white) {
    logoImage = logoWhite;
  }

  return (
    <div style={{ width: rem("128px") }} {...props}>
      <img src={logoImage} alt="" className="img-fluid" />
    </div>
  );
};

export default Logo;
