import React, { useState, useEffect, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import { loadAccessToken, getCaseById } from "@/containers/Auth/auth";
import { maxFileSize } from "@/utils";

import { Medium14Font } from "@/components/FontsNewComponent/Fonts";
import axios from "axios";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";

import { Container, ButtonContainer, Column } from "./styled";
import { HIDE_RIGHT_SIDE_BAR } from "@/redux/types";
import { Select } from "antd";
import { Bold14Font, Medium18Font } from "@/components/FontsNewComponent/Fonts";
import ToggleButtonGray from "@/components/buttons/ToggleButton/ToggleButtonGray";

import Label from "@/components/forms/AddCardForm/Label";

const EditParty = (props) => {
  const [partyObject, setPartyObject] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  const { caseObject, setCaseObject } = useContext(NotebookContext);

  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  const getDataFromAPI = async () => {
    setDataLoading(true);
    const caseResp = await getCaseById(caseId, dispatch);
    if (caseResp) {
      setCaseObject(caseResp);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (caseObject) {
      const parObj = caseObject.parties.find((i) => i.id == props.tagId);
      if (parObj) {
        setPartyObject(parObj);
      } else {
        getDataFromAPI();
      }
    }
  }, [caseObject]);

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  const saveResult = async () => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let body = {
      ...partyObject,
    };

    let endpoint = `/api/cases/${caseId}/parties/${props.tagId}`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    const partyIndex = caseObject.parties.findIndex((i) => i.id == props.tagId);
    const newParties = [
      ...caseObject.parties.slice(0, partyIndex),
      {
        ...caseObject.parties[partyIndex],
        name: partyObject.name,
      },
      ...caseObject.parties.slice(partyIndex + 1),
    ];

    setCaseObject((obj) => ({
      ...obj,
      parties: newParties,
    }));

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "party" ||
                  subNode.type === "comment" ||
                  subNode.type === "witness" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "evidence"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: partyObject.name },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.Party.findIndex((i) => i.id == props.tagId);
    const newParty = [
      ...caseLibrary.Party.slice(0, itemIndex),
      {
        ...caseLibrary.Party[itemIndex],
        label: partyObject.name,
      },
      ...caseLibrary.Party.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      Party: newParty,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  if (
    dataLoading ||
    !caseObject ||
    !partyObject ||
    Object.keys(partyObject).length === 0
  ) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={partyObject.name}
              onChangeHandler={(e) => {
                e.persist();
                setPartyObject((obj) => ({
                  ...obj,
                  name: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>
        <div className="form-group">
          <Label name={"Role"} />
          <Medium18Font
            as="div"
            className="pl-3"
            style={{ marginTop: "-10px" }}
          >
            {partyObject.role}
          </Medium18Font>
        </div>
        <div className="form-group">
          <Label name={"Client"} />
          <div className="container-fluid">
            <ToggleButtonGray
              checked={partyObject.client}
              disabled
              onChange={(e) => {
                e.preventDefault();
                // setClient(e.target.checked);
              }}
            />
          </div>
        </div>

        {/*
         */}
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult();
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

export default withTheme(EditParty);
