import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { Bold14Font, Bold18Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox/Checkbox";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { PulseLoader } from "react-spinners";
import { getReliefsList } from "../../../containers/Auth/auth";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";

const ACTION_TYPE_CREATE = "create";
const ACTION_TYPE_EDIT = "edit";

const addParentReliefIdToChildProof = (reliefList) => {
  return reliefList.reduce((newReliefs, relief) => {
    const proofs = relief.proofs.reduce((newProofs, proof) => {
      newProofs.push({ ...proof, id_relief: relief.id });
      return newProofs;
    }, []);
    newReliefs.push({ ...relief, proofs });
    return newReliefs;
  }, []);
};

const ReliefsAndProofs = ({
  object,
  setObject,
  routeParams,
  alreadySavedReliefs,
  alreadySavedProofs,
  actionType,
  theme,
  loadingAfterCallback = false,
}) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { caseId, applicationId } = routeParams;
  const dispatch = useDispatch();
  const editableStringsRef = useRef([]);
  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };
  const caseObject = useSelector((state) => state.data.store.case);
  // panel elements states
  const [reliefsList, setReliefsList] = useState([]);
  const [reliefsListSelectOptions, setReliefsListSelectOptions] = useState([]);
  const [reliefSelectedId, setReliefSelectedId] = useState(null);
  const [reliefSelectedObject, setReliefSelectedObject] = useState(null);
  const [proofsStandardList, setProofsStandardList] = useState([]);
  const [proofsCustomList, setProofsCustomList] = useState([]);
  const [proofsSelectedItemsList, setProofsSelectedItemsList] = useState([]);

  // save data from response object to state
  const saveData = (reliefsList) => {
    const alreadySavedReliefsIdsList = alreadySavedReliefs.map(
      (relief) => relief.id
    );

    const reliefSelectOptionsList = [];

    reliefsList.forEach((relief) => {
      if (alreadySavedReliefsIdsList.indexOf(relief.id) === -1) {
        reliefSelectOptionsList.push({
          label: relief.name,
          value: relief.id,
        });
      }
    });

    setReliefsListSelectOptions(reliefSelectOptionsList);
  };

  const saveProofs = (list) => {
    const standardProofsOfSelectedRelief = list.filter(
      (el) => !checkIfProofIsCustom(el)
    );
    let customProofsOfSelectedRelief = list.filter(checkIfProofIsCustom);
    // setProofsSelectedItemsList(standardProofsOfSelectedRelief);
    if (actionType === ACTION_TYPE_CREATE) {
      setProofsSelectedItemsList(standardProofsOfSelectedRelief);
    } else if (actionType === ACTION_TYPE_EDIT) {
      setProofsSelectedItemsList(
        object.proofs.filter((proof) => proof.id_user !== caseObject.user.id)
      );
    }
    if (reliefSelectedId) {
      let i = alreadySavedReliefs.find((v) => v.id === reliefSelectedId);
      if (i) {
        customProofsOfSelectedRelief = customProofsOfSelectedRelief.filter(
          (v) => {
            return alreadySavedProofs.find((item) => v.id === item.id);
          }
        );
        i.proofs.forEach((proof) => {
          if (
            (proof.id_user === caseObject.user.id ||
              String(proof.id).indexOf("new_") !== -1) &&
            !customProofsOfSelectedRelief.find((v) => {
              return v.id === proof.id;
            })
          ) {
            customProofsOfSelectedRelief.push(proof);
          }
        });
      }

      setProofsStandardList(standardProofsOfSelectedRelief);
      setProofsCustomList(customProofsOfSelectedRelief);
    }
  };

  // get data from API
  const getDataFromAPI = async () => {
    const resp = await getReliefsList(caseId, dispatch);
    if (resp) {
      const customReliefList = addParentReliefIdToChildProof(resp);
      setReliefsList(customReliefList);
      saveData(customReliefList);
      setIsLoading(false);
    }
  };

  // get data from prop object
  const getDataFromAPIAndProp = async () => {
    let currentRelief;
    if (String(object.id).indexOf("new_") === -1) {
      const resp = await getReliefsList(caseId, dispatch);
      if (resp) {
        currentRelief = resp.find((el) => el.id === object.id);
      }
    } else {
      currentRelief = object;
    }
    setReliefsList([currentRelief]);
    setReliefSelectedId(currentRelief.id);
    saveProofs(currentRelief.proofs);
    setIsLoading(false);
  };

  // make request to API
  useEffect(() => {
    if (isLoading && actionType === ACTION_TYPE_CREATE) {
      getDataFromAPI();
    } else if (isLoading && actionType === ACTION_TYPE_EDIT) {
      getDataFromAPIAndProp();
    }
  }, []);

  // If true - than this proof created by current user and its custom
  const checkIfProofIsCustom = (proof) => proof.id_user === caseObject.user.id;

  // Save ID of selected element to filter proofs
  const onChangeSelectedElementHandler = (selectedOption) => {
    setReliefSelectedObject(selectedOption);
    setReliefSelectedId(selectedOption.value);
  };

  // Show proofs that linked with selected relief option
  useEffect(() => {
    if (reliefSelectedId) {
      const selectedReliefObject = reliefsList.find(
        (reliefElement) => reliefElement.id === reliefSelectedId
      );
      saveProofs(selectedReliefObject.proofs);
    }
  }, [reliefSelectedId]);

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const onChangeCustomProofNameHandler = (idx, value) => {
    setProofsCustomList((prevState) => {
      const proofsCustomListCopy = [...prevState];
      const newProofObject = {
        ...prevState[idx],
        name: value,
      };
      proofsCustomListCopy.splice(idx, 1, newProofObject);
      return proofsCustomListCopy;
    });
  };

  const onClickAddCustomProofHandler = () => {
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      setProofsCustomList((prevState) => [
        ...prevState,
        { id: "new_" + Math.random(), name: "" },
      ]);
    }
  };

  const onClickDeleteCustomProofHandler = async (proofId, index) => {
    const proofsCustomListCopy = [...proofsCustomList];
    proofsCustomListCopy.splice(index, 1);
    setProofsCustomList(proofsCustomListCopy);
  };

  const onClickSaveReliefToApplicationHandler = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      if (actionType === ACTION_TYPE_CREATE) {
        let obj_ = { ...reliefSelectedObject };
        obj_.id = reliefSelectedObject.value;
        obj_.name = reliefSelectedObject.label;
        obj_.id_user = 1;
        obj_.proofs = [
          ...proofsSelectedItemsList,
          ...proofsCustomList.filter((v) => v.name),
        ];
        const submitObjectToApplication = {
          reliefs: [...alreadySavedReliefs, ...[obj_]],
          proofs: [...alreadySavedProofs, ...obj_.proofs],
        };
        if (loadingAfterCallback) {
          setObject(submitObjectToApplication, () => {
            dispatch({ type: HIDE_RIGHT_SIDE_BAR });
            setIsSubmitting(false);
          });
        } else {
          setObject(submitObjectToApplication);
        }
      } else {
        const index = alreadySavedReliefs.findIndex((v) => {
          return object.id === v.id;
        });
        const submitObjectToApplication = {
          reliefs: alreadySavedReliefs,
        };

        if (index !== -1) {
          submitObjectToApplication.reliefs[index].proofs = [
            ...proofsSelectedItemsList,
            ...proofsCustomList.filter((v) => v.name),
          ];
        }
        submitObjectToApplication.proofs = [];
        submitObjectToApplication.reliefs.forEach((v) => {
          submitObjectToApplication.proofs = [
            ...submitObjectToApplication.proofs,
            ...v.proofs,
          ];
        });
        if (loadingAfterCallback) {
          setObject(submitObjectToApplication, () => {
            dispatch({ type: HIDE_RIGHT_SIDE_BAR });
            setIsSubmitting(false);
          });
        } else {
          setObject(submitObjectToApplication);
        }
      }
      if (!loadingAfterCallback) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        setIsSubmitting(false);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {actionType === ACTION_TYPE_CREATE ? (
          <SelectAnotherTry
            options={reliefsListSelectOptions}
            onChange={onChangeSelectedElementHandler}
            value={reliefSelectedObject}
            label="Add Relief"
            style={{ marginBottom: "20px" }}
          />
        ) : (
          <div className="container-fluid mb-3">
            <Bold18Font>{object.name}</Bold18Font>
          </div>
        )}
        {!!proofsStandardList.length || !!proofsCustomList.length ? (
          <AnimatedDiv className="form-group">
            <div className="container-fluid">
              {proofsStandardList.length ? (
                <div style={{ marginBottom: "20px" }}>
                  <Bold14Font>Proofs</Bold14Font>
                  {proofsStandardList.map((el) => (
                    <Checkbox
                      key={el.id}
                      checked={
                        proofsSelectedItemsList.findIndex(
                          (item) => item.id === el.id
                        ) !== -1
                      }
                      label={el.name}
                      labelColor={theme.colors.dark}
                      onChange={() =>
                        onChangeCheckBoxHandler(
                          el,
                          proofsSelectedItemsList,
                          setProofsSelectedItemsList
                        )
                      }
                    />
                  ))}
                </div>
              ) : null}
              {proofsCustomList.length ? (
                <div>
                  <Bold14Font>Custom Proofs</Bold14Font>
                  {proofsCustomList.map((el, index) => (
                    <AnimatedDiv key={el.id} style={{ marginBottom: "4px" }}>
                      <EditableStringClassComponent
                        index={index}
                        object={proofsCustomList[index].name}
                        editState={el.name === ""}
                        editName={(idx, value) =>
                          onChangeCustomProofNameHandler(idx, value)
                        }
                        deleteAction={() =>
                          onClickDeleteCustomProofHandler(el.id, index)
                        }
                        // doneEditingCallback={onClickDoneEditingStringHandler}
                        ref={(ref) => assignRef(ref, index)}
                      />
                    </AnimatedDiv>
                  ))}
                </div>
              ) : null}
            </div>
          </AnimatedDiv>
        ) : null}
        {reliefSelectedId ? (
          <div
            className="form-group d-flex justify-content-center"
            style={{ marginTop: "4px" }}
          >
            <ListAddButton
              className="d-flex"
              label="Add Custom Proof"
              clickHandler={onClickAddCustomProofHandler}
            />
          </div>
        ) : null}
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                onClick={onClickSaveReliefToApplicationHandler}
                loading={isSubmitting}
                disabled={isSubmitting || !reliefSelectedId}
                wide
                primary
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ReliefsAndProofs.propTypes = {
  object: PropTypes.object,
  setObject: PropTypes.func,
  routeParams: PropTypes.object,
  alreadySavedReliefs: PropTypes.array,
  alreadySavedProofs: PropTypes.array,
  actionType: PropTypes.oneOf([ACTION_TYPE_CREATE, ACTION_TYPE_EDIT]),
};

ReliefsAndProofs.defaultProps = {
  actionType: ACTION_TYPE_CREATE,
};

export default withTheme(ReliefsAndProofs);
