// import React, {Fragment, useState} from "react";
import React, { Fragment, Component, createRef } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { theme } from "../../../styled-components/Theme/Theme";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import { connect } from "react-redux";
import InputNew from "../../InputNew/InputNew";

class EditableLabelClassComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: props.editState,
      label: this.props.object,
      isObjectString: typeof this.props.object === "string",
    };
  }

  setEdit = (state) => {
    this.setState((prevState) => ({
      ...prevState,
      edit: state,
    }));
  };

  doneEditing = async () => {
    if (
      this.state.isObjectString ? !this.props.object : !this.props.object.name
    ) {
      handleErrorMessage("Field shouldn't be empty", this.props.dispatch);
      return false;
    }
    if (
      this.state.isObjectString
        ? this.props.object.length > 255
        : this.props.object.name.length > 255
    ) {
      handleErrorMessage("Field value is to long", this.props.dispatch);
      return false;
    }
    if (this.props.doneEditingCallback) {
      await this.props.doneEditingCallback(this.props.index, this.state.label);
    }
    this.setEdit(false);
    return true;
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.doneEditing();
    }
  };

  render() {
    if (this.state.edit) {
      return (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between w-100">
            <InputNew
              withoutContent={true}
              required
              maxLength={255}
              value={this.state.label}
              onKeyDown={this._handleKeyDown}
              onChange={(e) => {
                this.setState({ label: e.target.value });
              }}
              autoFocus={true}
            />
            <div className="d-flex">
              <ListActionButton
                clickHandler={() => {
                  this.doneEditing();
                }}
                className={"ml-2"}
                type={"done"}
              />
              {this.props.deleteAction ? (
                <ListActionButton
                  clickHandler={() => {
                    this.props.deleteAction(
                      this.props.index,
                      this.props.object.id
                    );
                  }}
                  type={"delete"}
                />
              ) : null}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <span
          onClick={() => {
            if (this.props.startEditingCallback) {
              this.props.startEditingCallback(this.props.index);
            }
            this.setEdit(true);
          }}
        >
          {this.state.isObjectString
            ? this.props.object
            : this.props.object.name}
        </span>
      );
    }
  }
}

const mapDispatchToProps = {
  dispatch: (data) => data,
};

EditableLabelClassComponent.propTypes = {
  object: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
  editState: PropTypes.bool,
  deleteAction: PropTypes.func,
  editName: PropTypes.func.isRequired,
  /**
   * Execute callback func after start editing btn pressed.
   * Can used to close previous editing string
   */
  startEditingCallback: PropTypes.func,
  /**
   * Execute callback func after done editing btn pressed.
   * Can used to send data to API
   */
  doneEditingCallback: PropTypes.func,
};

export default connect(null, mapDispatchToProps, null, {
  forwardRef: true,
})(withTheme(EditableLabelClassComponent));
