import React, {useState} from "react";
import SubscribeFormStyled from "./styled/SubscribeFormStyled";
import Input from "../../Input";
import SubscribeFormButtonPosition from "./styled/SubscribeFormButtonPosition";
import SubscribeFormInputStyled from "./styled/SubscribeFormInputStyled";
import Button from "../../buttons/Button";
import LandingPageSubscribeButtonStyled from "../../buttons/Button/customStyled/LandingPageSubscribeButtonStyled";
import {useDispatch, useSelector} from "react-redux";
import {handleErrorMessage} from "../../Modal/modalErrorHandler";
import {subNews} from "../../../containers/Auth/auth";
import {MODAL_SUCCESS, SAVE_MODAL_DATA, SHOW_MODAL} from "../../../redux/types";

const SubscribeForm = () => {
  const auth = useSelector((state) => state.auth);
  const [email, setEmail] = useState(auth.isAuth && auth.user && auth.user.email ? auth.user.email : '');
  const [sending, setSending] = useState(false);
  const dispatch = useDispatch();
  const click = async (e) => {
    if (!sending) {
      setSending(true);
      e.preventDefault();
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase()) || String(email).toLowerCase().indexOf('+') !== -1) {
        handleErrorMessage('Please enter a valid email', dispatch);
      } else {
        let resp = await subNews(email, dispatch);
        if (!resp.response) {
          handleErrorMessage('You already subscribed', dispatch);
        } else {
          dispatch({ type: SAVE_MODAL_DATA, payload: "Thank you for subscribing to our newsletter!" });
          dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS });
        }
      }
      setEmail('');
      setSending(false);
    }
  };
  return (
    <SubscribeFormStyled>
      <div className="form-group">
        <Input
          name="subscribe"
          value={email}
          onChangeHandler={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="Your email address"
          customStyle={SubscribeFormInputStyled}
        />
        <SubscribeFormButtonPosition className="landing-page__footer-subscribe-btn-position">
          <Button
            primary
            loading={sending}
            disabled={sending}
            as={LandingPageSubscribeButtonStyled}
            className="landing-page__footer-subscribe-btn"
            clickHandler={click}
          >
            Save
          </Button>
        </SubscribeFormButtonPosition>
      </div>
    </SubscribeFormStyled>
  );
};

export default SubscribeForm;
