import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const RectangleButtonStyled = styled.button.attrs({ className: "btn" })`
  width: ${rem(`36px`)};
  height: ${rem(`36px`)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export default RectangleButtonStyled;
