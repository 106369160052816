import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import Button from "../buttons/Button";
import { Medium14Font } from "../Fonts/Fonts";
import LandingPageNavBarListItemStyled from "./styled/LandingPageNavBarListItemStyled";
import NavBarButtonsStyled from "../buttons/Button/customStyled/NavBarButtonsStyled";
import logo from "../../assets/img3/svg/logo-green-white-beta.svg";
import LandingPageHeaderImgWrapperStyled from "../../containers/LandingPage2/styled/LandingPageHeaderImgWrapperStyled";
import LandingPageNavBarStyled from "./styled/LandingPageNavBarStyled";
import { useScroll } from "../../hooks/useScroll";

const FEATURES = "FEATURES";
const ABOUT_US = "ABOUT_US";
const PLANS = "PLANS";
const TESTIMONIALS = "TESTIMONIALS";
const CONTACT_US = "CONTACT_US";

const fromTopSection = -200;
// const fromBottomSection = 100;

const LandingPageNavBar = ({ refsObject, header, theme, testimonials }) => {
  const [activeButton, setActiveButton] = useState(null);
  const { scrollY, scrollDirection } = useScroll();
  const [canSwitch, setCanSwitch] = useState(true);
  const menu = [
    {
      id: 1,
      text: "Features",
      link: "#features",
      activeType: FEATURES,
    },
    {
      id: 2,
      text: "About Us",
      link: "#about-us",
      activeType: ABOUT_US,
    },
    {
      id: 3,
      text: "Plans",
      link: "#plans",
      activeType: PLANS,
    },
  ];
  if (testimonials.length) {
    menu.push({
      id: 4,
      text: "Testimonials",
      link: "#testimonials",
      activeType: TESTIMONIALS,
    });
  }
  menu.push({
    id: 5,
    text: "Contact Us",
    link: "#contact-us",
    activeType: CONTACT_US,
  });

  useEffect(() => {
    // setActiveButton(null);
    if (canSwitch) {
      if (
        scrollY >=
          refsObject.startSections[0].current.getBoundingClientRect().top +
            document.documentElement.scrollTop +
            fromTopSection &&
        scrollY <=
          refsObject.endSections[0].current.getBoundingClientRect().bottom +
            document.documentElement.scrollTop
      ) {
        setActiveButton(null);
      }
      if (
        scrollY >=
          refsObject.startSections[1].current.getBoundingClientRect().top +
            document.documentElement.scrollTop +
            fromTopSection &&
        scrollY <=
          refsObject.endSections[1].current.getBoundingClientRect().bottom +
            document.documentElement.scrollTop
      ) {
        setActiveButton(menu[0].activeType);
      }
      if (
        scrollY >=
          refsObject.startSections[2].current.getBoundingClientRect().top +
            document.documentElement.scrollTop +
            fromTopSection &&
        scrollY <=
          refsObject.endSections[2].current.getBoundingClientRect().bottom +
            document.documentElement.scrollTop
      ) {
        setActiveButton(menu[1].activeType);
      }
      if (
        scrollY >=
          refsObject.startSections[3].current.getBoundingClientRect().top +
            document.documentElement.scrollTop +
            fromTopSection &&
        scrollY <=
          refsObject.endSections[3].current.getBoundingClientRect().bottom +
            document.documentElement.scrollTop
      ) {
        setActiveButton(menu[2].activeType);
      }

      if (testimonials.length) {
        if (
          scrollY >=
            refsObject.startSections[4].current.getBoundingClientRect().top +
              document.documentElement.scrollTop +
              fromTopSection &&
          scrollY <=
            refsObject.endSections[4].current.getBoundingClientRect().bottom +
              document.documentElement.scrollTop
        ) {
          setActiveButton(menu[3].activeType);
        }
        if (
          scrollY >=
            refsObject.startSections[5].current.getBoundingClientRect().top +
              document.documentElement.scrollTop +
              fromTopSection &&
          scrollY <=
            refsObject.endSections[5].current.getBoundingClientRect().bottom +
              document.documentElement.scrollTop
        ) {
          setActiveButton(menu[4].activeType);
        }
      } else {
        if (
          scrollY >=
            refsObject.startSections[5].current.getBoundingClientRect().top +
              document.documentElement.scrollTop +
              fromTopSection &&
          scrollY <=
            refsObject.endSections[5].current.getBoundingClientRect().bottom +
              document.documentElement.scrollTop
        ) {
          setActiveButton(menu[3].activeType);
        }
      }

      if (
        scrollY >=
        refsObject.endSections[5].current.getBoundingClientRect().top +
          document.documentElement.scrollTop
      ) {
        setActiveButton(null);
      }
    }
  }, [scrollY, canSwitch]);

  /**
   * Scroll to element in page
   * @param refElement
   * @param offsetElement
   */
  const scrollToTargetHandler = (refElement, offsetElement) => {
    const elementPosition = refElement.current.getBoundingClientRect().top;
    const offsetElementPosition = offsetElement.current.clientHeight.toFixed();
    const scrollPosition = elementPosition - offsetElementPosition - 50;
    setCanSwitch(false);
    scrollTo(
      scrollPosition,
      () => {
        setCanSwitch(true);
      },
      false,
      parseFloat(window.pageYOffset.toFixed()) +
        parseFloat(scrollPosition.toFixed())
    );
  };
  function scrollTo(offset, callback, to, elementPosition) {
    const fixedOffset = offset.toFixed();
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset && to) {
        window.removeEventListener("scroll", onScroll);
        callback();
      }
      if (!to && elementPosition === parseFloat(window.pageYOffset.toFixed())) {
        window.removeEventListener("scroll", onScroll);
        callback();
      }
    };

    window.addEventListener("scroll", onScroll);
    onScroll();
    if (to) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    } else {
      window.scrollBy({
        top: offset,
        behavior: "smooth",
      });
    }
  }
  const onClickHandler = (refElement, offsetElement, activeButton) => {
    setActiveButton(null);
    scrollToTargetHandler(refElement, offsetElement);

    setActiveButton(activeButton);
  };

  return (
    // <nav className="d-flex justify-content-between w-100">
    <LandingPageNavBarStyled className="d-flex align-items-center">
      <ul>
        <li>
          <LandingPageHeaderImgWrapperStyled>
            <img
              onClick={() => {
                setActiveButton(null);
                setCanSwitch(false);
                scrollTo(
                  0,
                  () => {
                    setCanSwitch(true);
                  },
                  true
                );
              }}
              src={logo}
              alt=""
              className="img-fluid cursor-pointer"
            />
          </LandingPageHeaderImgWrapperStyled>
        </li>
      </ul>
      <ul className="d-lg-flex d-none justify-content-center w-100">
        {menu.map((el) => (
          <LandingPageNavBarListItemStyled key={el.id}>
            <Button
              isActive={el.activeType === activeButton}
              customStyle={NavBarButtonsStyled}
              clickHandler={() =>
                onClickHandler(
                  refsObject.startSections[el.id],
                  header,
                  el.activeType
                )
              }
            >
              <Medium14Font textColor="inherit" style={{ color: "inherit" }}>
                {el.text}
              </Medium14Font>
            </Button>
          </LandingPageNavBarListItemStyled>
        ))}
      </ul>
    </LandingPageNavBarStyled>
    // </nav>
  );
};

export default withTheme(LandingPageNavBar);
