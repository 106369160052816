import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ListAddButton from "../../buttons/ListAddButton";
import Label from "../AddCardForm/Label";
import { theme } from "../../../styled-components/Theme/Theme";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import {
  addCauseOfAction,
  createCustomCOA,
  getCauseOfActions,
  updateCauseOfAction,
} from "../../../containers/Auth/auth";
import { PulseLoader } from "react-spinners";
import { Bold18Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import EditableStringClassComponent from "./EditableStringClassComponent";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import InputNew from "../../InputNew/InputNew";

const CauseOfActionForm = ({
  object,
  id_case,
  afterSubmit,
  alreadySavedCOAList,
  isCriminal,
  sendRequest,
  counterclaim,
  loadingAfterCallback = false,
}) => {
  const dispatch = useDispatch();
  const [object_, setObject] = useState(object);
  const [selectedElement, setSelectedElement] = useState("");
  const [defaultValue, setDefaultValue] = useState(null);
  const [customTypeName, setCustomTypeName] = useState("");
  const [allElements, setAllElements] = useState([]);
  const [customTypeAdd, setCustomTypeAdd] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [select, setSelect] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInit, setIsInit] = useState(true);
  let editableStringsOfDefenceRef = useRef([]);
  let editableStringsOfCustomRef = useRef([]);
  const fillCauseOfActions = async () => {
    let resp = await getCauseOfActions(id_case, dispatch, isCriminal);
    if (resp) {
      // List of saved COA with considering counterclaim field
      const savedCOATypesList = [...alreadySavedCOAList]
        .filter((el) => el.counterclaim == counterclaim)
        .map((el) => el.type);
      // 2. Create list of COA
      let options = [];
      resp.forEach(function (v) {
        // If we creating new CAO:
        if (!object_.id) {
          if (savedCOATypesList.findIndex((el) => el.id === v.id) === -1) {
            options.push({ value: v.id, label: v.name });
          }
        }
        // else - we editing existing CAO and we need save it's type in selector!
        else {
          if (savedCOATypesList.findIndex((el) => el.id === v.id) === -1) {
            options.push({ value: v.id, label: v.name });
          }
          if (
            options.findIndex((option) => option.value === object_.type.id) ===
            -1
          ) {
            options.push({
              value: object_.type.id,
              label: object_.type.name,
            });
          }
        }
      });
      setTypeOptions(options);
      setTypes(resp);
    }
  };
  useEffect(() => {
    const getData = async () => {
      await fillCauseOfActions();
      setIsInit(false);
    };
    getData();
  }, [counterclaim]);

  useEffect(() => {
    typeOptions.forEach((type, index) => {
      if (object_.type && object_.type.id === type.value) {
        setDefaultValue(type);
        setSelect(type);
        setSelectedElement(type.value);
      }
    });
  }, [typeOptions]);

  useEffect(() => {
    types.forEach((v, i) => {
      if (v.id === selectedElement) {
        if (v) {
          setAllElements(v.elements);
        }
      }
    });

    let ids;
    if (object && object_.elements && object_.elements) {
      ids = object_.elements.map((e) => {
        return e.id;
      });
    }
    if (!ids && types.length) {
      ids = types.find((v) => v.id === selectedElement)
        ? types
            .find((v) => v.id === selectedElement)
            .elements.map((e) => {
              return e.id;
            })
        : undefined;
    }
    if (ids) {
      setSelectedIds(ids);
    }
  }, [selectedElement]);

  const addDefenceElement = async (e) => {
    let canAdd = true;
    if (
      editableStringsOfDefenceRef.current &&
      editableStringsOfDefenceRef.current.length
    ) {
      editableStringsOfDefenceRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      let defence_elements;
      if (!object_.defence_elements) {
        defence_elements = [{ name: "" }];
      } else {
        defence_elements = [...object_.defence_elements, { name: "" }];
      }
      setObject({
        ...object_,
        ...{ defence_elements },
      });
    }
  };

  const addCustomElement = async (e) => {
    let canAdd = true;
    if (
      editableStringsOfCustomRef.current &&
      editableStringsOfCustomRef.current.length
    ) {
      editableStringsOfCustomRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      let elements;
      if (!object_.elements) {
        elements = [{ id: "custom_" + Math.random(), name: "", custom: true }];
      } else {
        elements = [
          ...object_.elements,
          { id: "custom_" + Math.random(), name: "", custom: true },
        ];
      }
      setObject({
        ...object_,
        ...{ elements },
      });
    }
  };

  const deleteCauseOfActionElement = (index, id) => {
    let ids = selectedIds.filter((v) => {
      return v !== id;
    });
    setSelectedIds(ids);
    setObject({
      ...object_,
      ...{
        elements: object_.elements.filter((v, i) => {
          return id !== v.id;
        }),
      },
    });
  };

  const deleteCauseOfActionDefenceElement = (index) => {
    setObject({
      ...object_,
      ...{
        defence_elements: object_.defence_elements.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };

  const editCauseOfActionElement = (index, name, id) => {
    let elements = JSON.parse(JSON.stringify(object_.elements));
    elements.forEach((element) => {
      if (element.id === id) {
        element.name = name;
      }
    });
    setObject({
      ...object_,
      ...{ elements },
    });
  };

  const editCauseOfActionDefenceElement = (index, name) => {
    let defence_elements = object_.defence_elements;
    defence_elements[index].name = name;
    setObject({
      ...object_,
      ...{ defence_elements },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      let resp;
      setIsLoading(true);

      const newElements = [];
      newElements.push(
        ...allElements.filter((e) => selectedIds.indexOf(e.id) !== -1)
      );
      if (object_ && object_.elements && object_.elements.length) {
        const customElements = object_.elements
          .filter((el) => el.custom === true)
          .map((el) => {
            return { ...el };
          });
        newElements.push(...customElements);
      }
      let data = {
        defence_elements: object_.defence_elements,
        elements: newElements,
        counterclaim: object_.counterclaim,
        id_type: selectedElement,
        type: { id: selectedElement, name: select.label },
      };
      if (sendRequest) {
        if (object.id) {
          resp = await updateCauseOfAction(id_case, object.id, dispatch, data);
        } else {
          resp = await addCauseOfAction(id_case, dispatch, data);
        }
      } else {
        resp = {
          id: object_.id ?? `new_${Math.random()}`,
          ...data,
        };
      }
      if (!loadingAfterCallback) {
        setIsLoading(false);
      }
      if (resp) {
        if (!loadingAfterCallback) {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        }
        afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update",
          () => {
            if (loadingAfterCallback) {
              setIsLoading(false);
              dispatch({ type: HIDE_RIGHT_SIDE_BAR });
            }
          }
        );
      }
    }
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedElement(selectedOption.value);
    setSelect(selectedOption);
    if (object_ && object_.elements) {
      setObject((prevState) => {
        return {
          ...prevState,
          elements: prevState.elements.filter(
            (element) => element.custom === true
          ),
        };
      });
    }
  };

  if (isInit) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader size={7} color={theme.colors.blue} />
      </div>
    );
  }

  const assignDefenceRef = (ref, i) => {
    return (editableStringsOfDefenceRef.current[i] = ref);
  };

  const assignCustomRef = (ref, i) => {
    return (editableStringsOfCustomRef.current[i] = ref);
  };
  const addCustomType = () => {
    setCustomTypeAdd(true);
  };
  const saveCustomType = async () => {
    setIsLoading(true);
    let resp = await createCustomCOA(id_case, dispatch, {
      name: customTypeName,
      is_criminal: isCriminal,
    });
    await fillCauseOfActions();
    setSelectedElement(resp.id);
    setSelect({ value: resp.id, label: resp.name });
    setIsLoading(false);
    setCustomTypeName("");
    setCustomTypeAdd(false);
  };
  const onChangeCustomTypeNameHandler = (e) => {
    setCustomTypeName(e.target.value);
  };
  return (
    <Fragment>
      {customTypeAdd ? (
        <Fragment>
          <div className="form-group">
            <InputNew
              placeholder={`Enter ${
                isCriminal ? "Offence" : "Cause of action"
              } name`}
              label={`Custom ${isCriminal ? "Offence" : "Cause of action"}`}
              name="name"
              autoFocus
              value={customTypeName}
              onChangeHandler={onChangeCustomTypeNameHandler}
              maxLength={125}
              required
            />
          </div>
          <div style={{ height: "100%", overflowY: "auto" }} />
        </Fragment>
      ) : (
        <Fragment>
          <div className="form-group">
            <Label
              className={"row"}
              name={isCriminal ? "Offence" : "Cause of Action"}
            />
            <div className="container-fluid">
              {object.id ? (
                <Bold18Font>{defaultValue && defaultValue.label}</Bold18Font>
              ) : (
                <SelectAnotherTry
                  options={typeOptions}
                  value={select}
                  placeholder={`Select ${
                    isCriminal ? "Offence" : "Cause of action"
                  }`}
                  defaultValue={defaultValue}
                  onChange={handleTypeChange}
                />
              )}
            </div>
          </div>
          {object.id ? null : (
            <div className="form-group d-flex justify-content-center">
              <ListAddButton
                className="d-flex"
                label={`Add Custom ${
                  isCriminal ? "Offence" : "Cause of action"
                }`}
                clickHandler={addCustomType}
              />
            </div>
          )}
          <div style={{ height: "100%", overflowY: "auto" }}>
            {allElements && allElements.length
              ? allElements.map((elem, i) => {
                  return (
                    <AnimatedDiv
                      style={{ cursor: "pointer" }}
                      key={elem.id ?? i}
                      onClick={() => {
                        let ids = [...selectedIds];
                        if (selectedIds.indexOf(elem.id) === -1) {
                          ids.push(elem.id);
                        } else if (selectedIds.indexOf(elem.id) !== -1) {
                          ids = selectedIds.filter((e) => {
                            return e !== elem.id;
                          });
                        }
                        setSelectedIds(ids);
                      }}
                      className="form-group pl-3 d-flex justify-content-between"
                    >
                      <Checkbox
                        className="pt-1"
                        checked={selectedIds.indexOf(elem.id) !== -1}
                      />
                      <div className="w-100">
                        <Medium14Font textColor={theme.colors.dark}>
                          {elem.name}
                        </Medium14Font>
                      </div>
                    </AnimatedDiv>
                  );
                })
              : null}
            {object_ && object_.elements && object_.elements.length ? (
              <Fragment>
                <AnimatedDiv className="form-group mb-0">
                  <Label className={"row"} name={"Custom elements"} />
                </AnimatedDiv>
                {object_.elements
                  .filter((el) => el.custom === true)
                  .map((elem, i) => {
                    return (
                      <AnimatedDiv
                        key={elem.id ?? i}
                        className="form-group pl-3 d-flex justify-content-between"
                      >
                        {elem.custom ? (
                          <EditableStringClassComponent
                            editName={editCauseOfActionElement}
                            editState={
                              elem.id.toString().indexOf("custom") !== -1
                            }
                            deleteAction={deleteCauseOfActionElement}
                            object={elem}
                            index={i}
                            ref={(ref) => assignCustomRef(ref, i)}
                          />
                        ) : (
                          <div className="w-100">
                            <Medium14Font textColor={theme.colors.dark}>
                              {elem.name}
                            </Medium14Font>
                          </div>
                        )}
                      </AnimatedDiv>
                    );
                  })}
              </Fragment>
            ) : null}
            {selectedElement ? (
              <div className="form-group">
                <div className="container-fluid">
                  <ListAddButton
                    className="d-flex"
                    label="Add Custom Element"
                    clickHandler={addCustomElement}
                  />
                </div>
              </div>
            ) : null}
            {object_.defence_elements && object_.defence_elements.length ? (
              <Fragment>
                <AnimatedDiv className="form-group mb-0">
                  <Label className={"row"} name={"Defence elements"} />
                </AnimatedDiv>
                {object_.defence_elements.map((d_e, i) => {
                  {
                    return (
                      <AnimatedDiv
                        key={d_e.id ?? i}
                        className="form-group pl-3"
                      >
                        <EditableStringClassComponent
                          editName={editCauseOfActionDefenceElement}
                          editState={typeof d_e.id === "undefined"}
                          deleteAction={deleteCauseOfActionDefenceElement}
                          object={d_e}
                          index={i}
                          ref={(ref) => assignDefenceRef(ref, i)}
                        />
                      </AnimatedDiv>
                    );
                  }
                })}
              </Fragment>
            ) : null}
            {selectedElement ? (
              <div className="form-group">
                <div className="container-fluid">
                  <ListAddButton
                    className="d-flex"
                    label="Add Defence Element"
                    clickHandler={addDefenceElement}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      )}
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <ButtonNew
            clickHandler={customTypeAdd ? saveCustomType : handleSubmit}
            loading={isLoading}
            disabled={
              isLoading ||
              (selectedElement &&
                selectedElement.length === 0 &&
                !customTypeAdd) ||
              (!customTypeName && customTypeAdd)
            }
            wide
            primary
          >
            Save
          </ButtonNew>
        </div>
      </div>
    </Fragment>
  );
};

CauseOfActionForm.propTypes = {
  object: PropTypes.object,
  afterSubmit: PropTypes.func.isRequired,

  /**
   * Array of already saved COA, to prevent add them second time
   */
  alreadySavedCOAList: PropTypes.array.isRequired,
};

export default CauseOfActionForm;
