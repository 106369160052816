import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import MoonLoader from "react-spinners/MoonLoader";

import CRUDButtonStyled from "./styled/CRUDButtonStyled";

import archiveIcon from "../../../assets/img3/svg/archive-icon.svg";
import editIcon from "../../../assets/img3/svg/edit-icon.svg";
import deleteIcon from "../../../assets/img3/svg/delete-icon.svg";

import { Bold14Font } from "../../FontsNewComponent/Fonts";

const CRUDButton = ({
  archiveButton,
  archivedStatus,
  editButton,
  deleteButton,
  theme,
  ...props
}) => {
  if (archiveButton) {
    return (
      <CRUDButtonStyled {...props}>
        {props.disabled ? (
          <MoonLoader size={42} color={theme.colors.blue} />
        ) : (
          // "some text"
          <Fragment>
            <img src={archiveIcon} alt="" className="img-fluid" />
            <Bold14Font textColor={theme.colors.blue}>
              {archivedStatus ? "Un-archive" : "Archive"}
            </Bold14Font>
          </Fragment>
        )}
      </CRUDButtonStyled>
    );
  }

  if (editButton) {
    return (
      <CRUDButtonStyled {...props}>
        <img src={editIcon} alt="" className="img-fluid" />
        <Bold14Font textColor={theme.colors.blue}>Edit</Bold14Font>
      </CRUDButtonStyled>
    );
  }

  if (deleteButton) {
    return (
      <CRUDButtonStyled {...props}>
        <img src={deleteIcon} alt="" className="img-fluid" />
        <Bold14Font textColor={theme.colors.blue}>Delete</Bold14Font>
      </CRUDButtonStyled>
    );
  }

  if (!archiveButton && !editButton && !deleteButton) {
    return (
      <CRUDButtonStyled {...props}>
        <Bold14Font textColor={theme.colors.blue}>?</Bold14Font>
      </CRUDButtonStyled>
    );
  }
};

CRUDButton.propTypes = {
  archiveButton: PropTypes.bool,
  archivedStatus: PropTypes.bool,
  editButton: PropTypes.bool,
  deleteButton: PropTypes.bool,
};

export default withTheme(CRUDButton);
