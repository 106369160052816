import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
} from "../../../redux/types";
import Checkbox from "../../Checkbox/Checkbox";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { updateEvidenceCoreBook } from "../../../containers/Auth/auth";
import NoRecords from "../../NoRecords";
import { Bold14Font } from "../../FontsNewComponent/Fonts";

const AddToCoreForm = ({
  allEvidenceList,
  setAllEvidenceList,
  filterFunction,
  routePaths,
}) => {
  // page states
  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const { caseId } = routePaths;

  // data states
  const [allEvidenceList_, setAllEvidenceList_] = useState([
    ...allEvidenceList,
  ]);

  // get data
  const saveData = (list) => {};

  // send data

  const sendDataHandler = async (submitObj) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    const resp = await updateEvidenceCoreBook(caseId, dispatch, submitObj);
    if (resp) {
      setAllEvidenceList(resp);
    }
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  // button handlers

  const onClickSaveButtonHandler = async () => {
    if (!isSubmitting) {
      const sendObj = { core_book: {} };
      allEvidenceList_.forEach((el) => {
        sendObj.core_book[el.id] = !!el.core_book;
      });
      await sendDataHandler(sendObj);
    }
  };

  // input handlers

  const onChangeCheckboxHandler = (item) => {
    const itemCopy = { ...item };
    const allEvidenceListCopy = [...allEvidenceList_];
    const index = allEvidenceList_.findIndex((el) => el.id === itemCopy.id);
    itemCopy.core_book = itemCopy.core_book === 1 ? 0 : 1;
    allEvidenceListCopy.splice(index, 1, itemCopy);
    setAllEvidenceList_(allEvidenceListCopy);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="container-fluid">
          {allEvidenceList_.length ? (
            allEvidenceList_.map((item) => {
              return (
                <Checkbox
                  label={item.label}
                  checked={item.core_book === 1}
                  onChange={() => {
                    onChangeCheckboxHandler(item);
                  }}
                />
              );
            })
          ) : (
            <NoRecords>
              <Bold14Font>Your Evidence will appear here</Bold14Font>
            </NoRecords>
          )}
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group" style={{ marginTop: "20px" }}>
        <div className="container-fluid">
          {/*<form onSubmit={sendDataHandler}>*/}
          <ButtonNew
            loading={isSubmitting}
            disabled={isSubmitting}
            clickHandler={onClickSaveButtonHandler}
            wide
            primary
          >
            Save
          </ButtonNew>
          {/*</form>*/}
        </div>
      </div>
    </Fragment>
  );
};

export default AddToCoreForm;
