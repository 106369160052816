import React from "react";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
// onClick={() => editor.chain().focus().setFontFamily('Comic Sans MS, Comic Sans').run()}
//   editor.isActive('textStyle', { fontFamily: 'Comic Sans MS, Comic Sans' })

const families = [
  {
    label: "Default",
    value: "system-ui",
  },
  {
    label: "Comic Sans",
    value: "Comic Sans MS, Comic Sans",
  },
  {
    label: "monospace",
    value: "monospace",
  },
  {
    label: "curisve",
    value: "cursive",
  },
];

const FontFamily = ({ editor }) => {
  return (
    <div style={{ width: 124 }}>
      {/* https://tiptap.dev/api/extensions/font-family */}
      <SelectAnotherTry
        defaultValue={"system-ui"}
        value={families.find(
          (i) =>
            i.value ===
            (editor.getAttributes("textStyle").fontFamily || "system-ui")
        )}
        options={families}
        onChange={(v) => {
          editor.chain().focus().setFontFamily(v.value).run();
        }}
        compact
      />
    </div>
  );
};

export default FontFamily;
