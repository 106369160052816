import styled from "styled-components/macro";

const ModalBackdropStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.dark};
  opacity: 0.2;
  z-index: 200;
`;

export default ModalBackdropStyled;
