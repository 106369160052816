import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import { Medium14Font } from "../../../components/FontsNewComponent/Fonts";
import { useDispatch, useSelector } from "react-redux";
import { getCaseById, getCaseLog } from "../../Auth/auth";
import Table from "../../../components/Table/Table";
import LiStyled from "../../../components/Table/Styled/LiStyled";
import {
  APPLICATION,
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  DOCUMENT,
  EVIDENCE_DOCUMENTS,
  EXAMWITNESS,
  SUB_EVIDENCE,
  TOPIC,
  WITNESSES,
} from "../Documents/Documents";
import moment from "moment";
import LiTStyled from "../../../components/Table/Styled/LiTStyled";
import { reorderFields } from "../../../utils/function";

const fields_map = {
  witnesses: "associated witness",
  "type.name": "name",
  "issue.name": "issue",
  "theme.name": "theme",
  "classes.label": "label",
  "case_type.name": "case type",
  new_case_type: "type",
  "causes_of_action.name": "causes of action",
  "application.label": "application",
  "witness.name": "witness",
  evidences: "evidence",
  "party.name": "party",
  reason_to_testimony: "reason for testimony",
  max_users: "team limit",
};
const classes = [
  { label: "Evidence", value: EVIDENCE_DOCUMENTS },
  { label: "Case Document", value: CASE_DOCUMENTS },
  { label: "Authority", value: AUTHORITIES_DOCUMENTS },
  { label: "Application Papers", value: APPLICATION_DOCUMENTS },
];
export default function CaseLog({ case_id }) {
  const [isLoading, setIsLoading] = useState(true);
  const [logData, setLogData] = useState({});
  const [logItems, setLogItems] = useState([]);
  const [page, setPage] = useState(1);
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  function upsert(array, item) {
    const i = array.findIndex((_item) => _item.id === item.id);
    if (i > -1) array[i] = item;
    else array.push(item);
  }
  const getLogData = async () => {
    let log = await getCaseLog(case_id, page, dispatch);
    if (log) {
      setLogData(log);
      let data = [...logItems];
      log.data.forEach((v) => {
        upsert(data, v);
      });
      setLogItems(data);
    }
  };
  const mergeItems = (items, data_map, key, v, filtered) => {
    let id = String(v.id).replace(/([\d]+)_(.*)/, "$1");
    try {
      if (data_map[key]) {
        let k = Object.keys(data_map[key]).find(
          (k) => String(k).replace(/([\d]+)_(.*)/, "$1") === id
        );
        if (k) {
          if (filtered) {
            data_map[key][k].forEach((v, index) => {
              if (
                filtered &&
                ((filtered[key] &&
                  filtered[key][k] &&
                  filtered[key][k].indexOf(v) === -1) ||
                  !filtered[key] ||
                  !filtered[k])
              ) {
                items.push(v);
              }
              data_map[key][k].slice(index, 1);
            });
            if (!data_map[key][k].length) {
              delete data_map[key][k];
            }
          } else {
            items = [...items, ...data_map[key][k]];
            // delete data_map[key][k];
          }
        }
      }
    } catch (e) {
      return items;
    }
    return items;
  };
  const displayStringChange = (v_old, v) => {
    return (
      <Fragment>
        {v_old ? (
          <Fragment>
            <Medium14Font cross textColor={theme.colors.burgundy}>
              {v_old}
            </Medium14Font>
            <Medium14Font className="ml-1 mr-1">→</Medium14Font>
          </Fragment>
        ) : null}
        <Medium14Font textColor={theme.colors.approve}>{v}</Medium14Font>
      </Fragment>
    );
  };
  const displayChildField = (name, parent_name, data_map, old_map, action) => {
    if (parent_name === "causes of action") {
      if (!old_map["causes of action"] && !data_map["causes of action"]) {
        parent_name = "offence";
      }
    }
    return (
      <Fragment>
        {!Object.keys(data_map[name]).length &&
        old_map[name] &&
        Object.keys(old_map[name]).length
          ? Object.keys(old_map[name]).map((key, index) => {
              let items_new = [];
              let items_old =
                old_map[name] && old_map[name][key] ? old_map[name][key] : [];
              if (
                name === "elements" &&
                old_map["defence elements"] &&
                old_map["defence elements"][key]
              ) {
                items_old = [...items_old, ...old_map["defence elements"][key]];
                delete old_map["defence elements"][key];
              }
              let display_name = key.replace(/([\d]+)_(.*)/, "$2");
              if (JSON.stringify(items_new) === JSON.stringify(items_old)) {
                return "";
              }
              if (!items_old) {
                items_old = [];
              }
              let id = key.replace(/([\d]+)_(.*)/, "$1");
              const p_name = `${parent_name}${
                parent_name === "causes of action" ? "" : "s"
              }`;
              if (
                old_map[p_name] &&
                old_map[p_name].find((i_) => i_.id == id)
              ) {
                return "";
              }
              return (
                <LiStyled className="ml-2" key={index}>
                  <Medium14Font className="mr-1">
                    {parent_name.replace("causes of action", "cause of action")}
                  </Medium14Font>
                  <Medium14Font textColor={theme.colors.approve}>
                    {display_name}
                  </Medium14Font>
                  {items_old.filter((v) => items_new.indexOf(v) === -1)
                    .length ? (
                    <ul className="ml-3">
                      {items_old
                        .filter((v) => items_new.indexOf(v) === -1)
                        .map((v, index) => {
                          return (
                            <LiTStyled className="ml-2" key={index}>
                              <Medium14Font
                                cross
                                textColor={theme.colors.burgundy}
                              >
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                  {items_new.filter((v) => items_old.indexOf(v) === -1)
                    .length ? (
                    <ul className="ml-3">
                      {items_new
                        .filter((v) => items_old.indexOf(v) === -1)
                        .map((v, index) => {
                          return (
                            <LiTStyled key={index} className="ml-2">
                              <Medium14Font textColor={theme.colors.approve}>
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                </LiStyled>
              );
            })
          : null}
        {Object.keys(data_map[name]).map((key, i) => {
          let items_new = data_map[name][key];
          if (
            name === "elements" &&
            data_map["defence elements"] &&
            data_map["defence elements"][key]
          ) {
            items_new = [...items_new, ...data_map["defence elements"][key]];
            delete data_map["defence elements"][key];
          }
          let items_old =
            old_map[name] && old_map[name][key] ? old_map[name][key] : [];
          if (
            name === "elements" &&
            old_map["defence elements"] &&
            old_map["defence elements"][key]
          ) {
            items_old = [...items_old, ...old_map["defence elements"][key]];
            delete old_map["defence elements"][key];
          }
          let display_name = key.replace(/([\d]+)_(.*)/, "$2");
          if (JSON.stringify(items_new) === JSON.stringify(items_old)) {
            return "";
          }
          if (!items_old) {
            items_old = [];
          }
          let id = key.replace(/([\d]+)_(.*)/, "$1");
          const p_name = `${parent_name}${
            parent_name === "causes of action" || parent_name === "offence"
              ? ""
              : "s"
          }`;
          //TODO MB rollback
          if (old_map[p_name] && old_map[p_name].find((i_) => i_.id == id)) {
            return "";
          }
          if (data_map[p_name] && data_map[p_name].find((i_) => i_.id == id)) {
            return "";
          }
          return (
            <LiStyled className="ml-2" key={i}>
              <Medium14Font className="mr-1">
                {parent_name.replace("causes of action", "cause of action")}
              </Medium14Font>
              <Medium14Font textColor={theme.colors.approve}>
                {display_name}
              </Medium14Font>
              {items_old.filter((v) => items_new.indexOf(v) === -1).length ? (
                <ul className="ml-3">
                  {items_old
                    .filter((v) => items_new.indexOf(v) === -1)
                    .map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font cross textColor={theme.colors.burgundy}>
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                </ul>
              ) : null}
              {items_new.filter((v) => items_old.indexOf(v) === -1).length ? (
                <ul className="ml-3">
                  {items_new
                    .filter((v) => items_old.indexOf(v) === -1)
                    .map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font textColor={theme.colors.approve}>
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                </ul>
              ) : null}
            </LiStyled>
          );
        })}
      </Fragment>
    );
  };
  const displayChildFieldObjective = (
    name,
    parent_name,
    data_map,
    old_map,
    action,
    type
  ) => {
    return (
      <Fragment>
        {!Object.keys(data_map[name]).length &&
        old_map[name] &&
        Object.keys(old_map[name]).length &&
        !data_map[parent_name].length
          ? Object.keys(old_map[name]).map((key, i) => {
              let items_new = [];
              let items_old =
                old_map[name] && old_map[name][key] ? old_map[name][key] : [];
              if (
                name === "elements" &&
                old_map["defence elements"] &&
                old_map["defence elements"][key]
              ) {
                items_old = [...items_old, ...old_map["defence elements"][key]];
                delete old_map["defence elements"][key];
              }
              let display_name = key.replace(/([\d]+)_(.*)/, "$2");
              if (JSON.stringify(items_new) === JSON.stringify(items_old)) {
                return "";
              }
              if (!items_old) {
                items_old = [];
              }
              let id = key.replace(/([\d]+)_(.*)/, "$1");
              const p_name = `${parent_name}${
                parent_name === "causes of action" || parent_name === "offence"
                  ? ""
                  : "s"
              }`;
              if (
                old_map[p_name] &&
                old_map[p_name].find((i_) => i_.id == id)
              ) {
                return "";
              }
              return (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">exam lines</Medium14Font>
                  <Medium14Font cross textColor={theme.colors.burgundy}>
                    {display_name}
                  </Medium14Font>
                  {items_old.filter((v) => items_new.indexOf(v) === -1)
                    .length ? (
                    <ul className="ml-3">
                      {items_old
                        .filter((v) => items_new.indexOf(v) === -1)
                        .map((v, i) => {
                          return (
                            <LiTStyled className="ml-2" key={i}>
                              <Medium14Font
                                cross
                                textColor={theme.colors.burgundy}
                              >
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                  {items_new.filter((v) => items_old.indexOf(v) === -1)
                    .length ? (
                    <ul className="ml-3">
                      {items_new
                        .filter((v) => items_old.indexOf(v) === -1)
                        .map((v, i) => {
                          return (
                            <LiTStyled className="ml-2" key={i}>
                              <Medium14Font textColor={theme.colors.approve}>
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                </LiStyled>
              );
            })
          : null}
        {Object.keys(data_map[name]).map((key, index) => {
          let items_new = data_map[name][key];
          if (
            name === "elements" &&
            data_map["defence elements"] &&
            data_map["defence elements"][key]
          ) {
            items_new = [...items_new, ...data_map["defence elements"][key]];
            delete data_map["defence elements"][key];
          }
          let items_old =
            old_map[name] && old_map[name][key] ? old_map[name][key] : [];
          if (
            name === "elements" &&
            old_map["defence elements"] &&
            old_map["defence elements"][key]
          ) {
            items_old = [...items_old, ...old_map["defence elements"][key]];
            delete old_map["defence elements"][key];
          }
          let display_name = key.replace(/([\d]+)_(.*)/, "$2");
          if (JSON.stringify(items_new) === JSON.stringify(items_old)) {
            return "";
          }
          if (!items_old) {
            items_old = [];
          }
          let id = key.replace(/([\d]+)_(.*)/, "$1");
          const p_name = `${parent_name}${
            parent_name === "causes of action" || parent_name === "offence"
              ? ""
              : "s"
          }`;
          if (old_map[p_name] && old_map[p_name].find((i_) => i_.id == id)) {
            return "";
          }
          return (
            <LiStyled className="ml-2" key={index}>
              <Medium14Font className="mr-1">exam lines</Medium14Font>
              <Medium14Font textColor={theme.colors.approve}>
                {display_name}
              </Medium14Font>
              {items_old.filter((v) => items_new.indexOf(v) === -1).length ? (
                <ul className="ml-3">
                  {items_old
                    .filter((v) => items_new.indexOf(v) === -1)
                    .map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font cross textColor={theme.colors.burgundy}>
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                </ul>
              ) : null}
              {items_new.filter((v) => items_old.indexOf(v) === -1).length ? (
                <ul className="ml-3">
                  {items_new
                    .filter((v) => items_old.indexOf(v) === -1)
                    .map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font textColor={theme.colors.approve}>
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                </ul>
              ) : null}
            </LiStyled>
          );
        })}
      </Fragment>
    );
  };
  const displayLinkedFields = (
    parent,
    linked,
    data_map,
    old_map,
    action,
    name
  ) => {
    if (action === "created" || action === "deleted") {
      return (
        <Fragment>
          {data_map[parent].map((v, i) => {
            let items = [];
            linked.forEach((item) => {
              items = mergeItems(items, data_map, item, v);
            });
            return (
              <LiStyled className="ml-2" key={i}>
                <Medium14Font className="mr-1">{name}</Medium14Font>
                <Medium14Font
                  cross={action === "deleted"}
                  textColor={
                    action === "created"
                      ? theme.colors.approve
                      : theme.colors.burgundy
                  }
                >
                  {v.name}
                </Medium14Font>
                {items.length ? (
                  <ul className="ml-3">
                    {items.map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font
                            cross={action === "deleted"}
                            textColor={
                              action === "created"
                                ? theme.colors.approve
                                : theme.colors.burgundy
                            }
                          >
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                  </ul>
                ) : null}
              </LiStyled>
            );
          })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {Object.values(old_map[parent])
            .filter((v) => {
              return (
                !data_map[parent] ||
                !data_map[parent].find((e) => e.id === v.id)
              );
            })
            .map((v, i) => {
              let items = [];
              linked.forEach((item) => {
                items = mergeItems(items, old_map, item, v, data_map);
              });
              return (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">{name}</Medium14Font>
                  <Medium14Font cross textColor={theme.colors.burgundy}>
                    {v.name}
                  </Medium14Font>
                  {items.length ? (
                    <ul className="ml-3">
                      {items.map((v, i) => {
                        return (
                          <LiTStyled className="ml-2" key={i}>
                            <Medium14Font
                              cross
                              textColor={theme.colors.burgundy}
                            >
                              {v}
                            </Medium14Font>
                          </LiTStyled>
                        );
                      })}
                    </ul>
                  ) : null}
                </LiStyled>
              );
            })}
          {Object.values(data_map[parent])
            .filter((v) => {
              return (
                !old_map[parent] ||
                !old_map[parent].find((e) => e.id === v.id) ||
                old_map[parent].find((e) => e.id === v.id).name !== v.name
              );
            })
            .map((v, i) => {
              let items = [];
              let old_items = [];
              linked.forEach((item) => {
                items = mergeItems(items, data_map, item, v);
              });
              linked.forEach((item) => {
                old_items = mergeItems(old_items, old_map, item, v);
              });
              let old_name;
              try {
                old_name = old_map[parent].find((e) => e.id === v.id).name;
              } catch (e) {
                old_name = "";
              }
              return (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">{name}</Medium14Font>
                  {displayStringChange(old_name, v.name)}
                  {items.length ? (
                    <ul className="ml-3">
                      {items
                        .filter((e) => {
                          return old_items.indexOf(e) === -1;
                        })
                        .map((v, i) => {
                          return (
                            <LiTStyled className="ml-2" key={i}>
                              <Medium14Font textColor={theme.colors.approve}>
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                </LiStyled>
              );
            })}
        </Fragment>
      );
    }
  };
  const displayLinkedFieldsObjective = (
    parent,
    linked,
    data_map,
    old_map,
    action,
    name,
    type
  ) => {
    if (action === "created" || action === "deleted") {
      return (
        <Fragment>
          <LiStyled className="ml-2">
            <Medium14Font className="mr-1">objectives</Medium14Font>
            <ul className="ml-3">
              {data_map[parent].map((v, i) => {
                return (
                  <LiTStyled className="ml-2" key={i}>
                    <Medium14Font
                      cross={action === "deleted"}
                      textColor={
                        action === "created"
                          ? theme.colors.approve
                          : theme.colors.burgundy
                      }
                    >
                      {v.name}
                    </Medium14Font>
                  </LiTStyled>
                );
              })}
            </ul>
          </LiStyled>
          {data_map[parent].map((v, i) => {
            let items = [];
            linked.forEach((item) => {
              items = mergeItems(items, data_map, item, v);
            });
            return items.length ? (
              <LiStyled className="ml-2" key={i}>
                <Medium14Font className="mr-1">{name}</Medium14Font>
                <Medium14Font
                  cross={action === "deleted"}
                  textColor={
                    action === "created"
                      ? theme.colors.approve
                      : theme.colors.burgundy
                  }
                >
                  {v.name}
                </Medium14Font>
                {items.length ? (
                  <ul className="ml-3">
                    {items.map((v, i) => {
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font
                            cross={action === "deleted"}
                            textColor={
                              action === "created"
                                ? theme.colors.approve
                                : theme.colors.burgundy
                            }
                          >
                            {v}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                  </ul>
                ) : null}
              </LiStyled>
            ) : null;
          })}
        </Fragment>
      );
    } else {
      let old_obj = old_map[parent].filter((v) => {
        return (
          !data_map[parent] || !data_map[parent].find((e) => e.id === v.id)
        );
      });
      let new_obj = data_map[parent].filter((v) => {
        return (
          !old_map[parent] ||
          !old_map[parent].find((e) => e.id === v.id) ||
          old_map[parent].find((e) => e.id === v.id).name !== v.name
        );
      });
      return (
        <Fragment>
          {old_obj.length || new_obj.length ? (
            <LiStyled className="ml-2">
              <Medium14Font className="mr-1">objectives</Medium14Font>
              <ul className="ml-3">
                {old_obj.map((v, i) => {
                  return (
                    <LiTStyled className="ml-2" key={i}>
                      <Medium14Font cross textColor={theme.colors.burgundy}>
                        {v.name}
                      </Medium14Font>
                    </LiTStyled>
                  );
                })}
                {new_obj.map((v, i) => {
                  return (
                    <LiTStyled className="ml-2" key={i}>
                      <Medium14Font textColor={theme.colors.approve}>
                        {v.name}
                      </Medium14Font>
                    </LiTStyled>
                  );
                })}
              </ul>
            </LiStyled>
          ) : null}
          {old_map[parent]
            .filter((v) => {
              return (
                !data_map[parent] ||
                !data_map[parent].find((e) => e.id === v.id)
              );
            })
            .map((v, i) => {
              let items = [];
              linked.forEach((item) => {
                items = mergeItems(items, old_map, item, v, data_map);
              });
              return items.length ? (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">{name}</Medium14Font>
                  <Medium14Font cross textColor={theme.colors.burgundy}>
                    {v.name}
                  </Medium14Font>
                  {items.length ? (
                    <ul className="ml-3">
                      {items.map((v, i) => {
                        return (
                          <LiTStyled className="ml-2" key={i}>
                            <Medium14Font
                              cross
                              textColor={theme.colors.burgundy}
                            >
                              {v}
                            </Medium14Font>
                          </LiTStyled>
                        );
                      })}
                    </ul>
                  ) : null}
                </LiStyled>
              ) : null;
            })}
          {data_map[parent]
            .filter((v) => {
              return (
                !old_map[parent] ||
                !old_map[parent].find((e) => e.id === v.id) ||
                old_map[parent].find((e) => e.id === v.id).name !== v.name
              );
            })
            .map((v, i) => {
              let items = [];
              let old_items = [];
              linked.forEach((item) => {
                items = mergeItems(items, data_map, item, v);
              });
              linked.forEach((item) => {
                old_items = mergeItems(old_items, old_map, item, v);
              });
              let old_name;
              try {
                old_name = old_map[parent].find((e) => e.id === v.id).name;
              } catch (e) {
                old_name = "";
              }
              return items.length ? (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">objectives</Medium14Font>
                  {displayStringChange(old_name, v.name)}
                  {items.length ? (
                    <ul className="ml-3">
                      {items
                        .filter((e) => {
                          return old_items.indexOf(e) === -1;
                        })
                        .map((v, i) => {
                          return (
                            <LiTStyled className="ml-2" key={i}>
                              <Medium14Font textColor={theme.colors.approve}>
                                {v}
                              </Medium14Font>
                            </LiTStyled>
                          );
                        })}
                    </ul>
                  ) : null}
                </LiStyled>
              ) : null;
            })}
        </Fragment>
      );
    }
  };
  const displayObjectField = (data_map, old_map, field, action, name) => {
    if (action === "created" || action === "deleted") {
      return Object.keys(data_map[field]).map((k, i) => {
        return (
          <LiStyled className="ml-2" key={i}>
            <Medium14Font className="mr-1">{name}</Medium14Font>
            <Medium14Font
              cross={action === "deleted"}
              textColor={
                action === "created"
                  ? theme.colors.approve
                  : theme.colors.burgundy
              }
            >
              {data_map[field][k].name ?? data_map[field][k].label}
            </Medium14Font>
            <ul className="ml-3">
              {Object.keys(data_map[field][k])
                .filter((kv) => kv !== "name" && kv !== "label")
                .map((kv, i) => {
                  return (
                    <LiTStyled className="ml-2" key={i}>
                      <Medium14Font className="mr-1">{kv}</Medium14Font>
                      <Medium14Font
                        cross={action === "deleted"}
                        textColor={
                          action === "created"
                            ? theme.colors.approve
                            : theme.colors.burgundy
                        }
                      >
                        {typeof data_map[field][k][kv] === "boolean"
                          ? data_map[field][k][kv]
                            ? "Yes"
                            : "No"
                          : data_map[field][k][kv]}
                      </Medium14Font>
                    </LiTStyled>
                  );
                })}
            </ul>
          </LiStyled>
        );
      });
    } else {
      return (
        <Fragment>
          {Object.keys(old_map[field])
            .filter((k) => {
              return !data_map[field][k];
            })
            .map((k, i) => {
              const field_label_old =
                old_map[field][k].name ?? old_map[field][k].label;
              return (
                <LiStyled className="ml-2" key={i}>
                  <Medium14Font className="mr-1">{name}</Medium14Font>
                  <Medium14Font cross textColor={theme.colors.burgundy}>
                    {field_label_old}
                  </Medium14Font>
                  <ul className="ml-3">
                    {Object.keys(old_map[field][k]).map((kv, i) => {
                      let val_old;
                      val_old =
                        typeof old_map[field][k][kv] === "boolean"
                          ? old_map[field][k][kv]
                            ? "Yes"
                            : "No"
                          : old_map[field][k][kv];
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font className="mr-1">{kv}</Medium14Font>
                          <Medium14Font cross textColor={theme.colors.burgundy}>
                            {val_old}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                  </ul>
                </LiStyled>
              );
            })}
          {Object.keys(data_map[field]).map((k, i) => {
            let field_label =
              data_map[field][k].name ?? data_map[field][k].label;
            let field_label_old;
            try {
              field_label_old =
                old_map[field][k].name ?? old_map[field][k].label;
            } catch (e) {
              field_label_old = "";
            }
            return (
              <LiStyled className="ml-2" key={i}>
                <Medium14Font className="mr-1">{name}</Medium14Font>
                <Medium14Font
                  cross={action === "deleted"}
                  textColor={
                    action === "created"
                      ? theme.colors.approve
                      : theme.colors.burgundy
                  }
                >
                  {displayStringChange(field_label_old, field_label)}
                </Medium14Font>
                <ul className="ml-3">
                  {Object.keys(data_map[field][k])
                    .filter((kv) => kv !== "name" && kv !== "label")
                    .filter((kv) => {
                      return (
                        !old_map[field][k] ||
                        data_map[field][k][kv] !== old_map[field][k][kv]
                      );
                    })
                    .map((kv, i) => {
                      const val_new =
                        typeof data_map[field][k][kv] === "boolean"
                          ? data_map[field][k][kv]
                            ? "Yes"
                            : "No"
                          : data_map[field][k][kv];
                      let val_old;
                      if (!old_map[field][k]) {
                        val_old = "";
                      } else {
                        val_old =
                          typeof old_map[field][k][kv] === "boolean"
                            ? old_map[field][k][kv]
                              ? "Yes"
                              : "No"
                            : old_map[field][k][kv];
                      }
                      return (
                        <LiTStyled className="ml-2" key={i}>
                          <Medium14Font className="mr-1">{kv}</Medium14Font>
                          <Medium14Font
                            cross={action === "deleted"}
                            textColor={
                              action === "created"
                                ? theme.colors.approve
                                : theme.colors.burgundy
                            }
                          >
                            {displayStringChange(val_old, val_new)}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                </ul>
              </LiStyled>
            );
          })}
        </Fragment>
      );
    }
  };
  const displayCase = (data_map, old_map, action) => {
    return Object.keys(data_map)
      .sort((a, b) => {
        return reorderFields(CASE_DOCUMENTS, a, b);
      })
      .map((field, index) => {
        let v, v_old, is_object;
        switch (typeof data_map[field]) {
          case "object":
            v = data_map[field];
            v_old = old_map[field]
              ? Object.values(old_map[field]).filter(
                  (not) => Object.values(data_map[field]).indexOf(not) === -1
                )
              : false;
            is_object = true;
            break;
          case "boolean":
            v = data_map[field] ? "Yes" : "No";
            v_old = old_map[field] ? "Yes" : "No";
            is_object = false;
            break;
          default:
            if (field === "counterclaim" || field === "core book") {
              v = data_map[field] ? "Yes" : "No";
              v_old = old_map[field] ? "Yes" : "No";
            } else if (field === "document type" || field === "class name") {
              let f_new = classes.find((f) => f.value === data_map[field]);
              let f_old = classes.find((f) => f.value === old_map[field]);
              v = f_new ? f_new.label : data_map[field];
              v_old = f_old ? f_old.label : old_map[field];
            } else if (field === "date") {
              v = data_map[field]
                ? moment(data_map[field]).format("DD-MM-YYYY")
                : "";
              v_old = old_map[field]
                ? moment(old_map[field]).format("DD-MM-YYYY")
                : "";
            } else {
              v = data_map[field];
              v_old = old_map[field];
            }
            is_object = false;
            break;
        }
        if (field === "causes of action") {
          return displayLinkedFields(
            "causes of action",
            ["elements", "defence elements"],
            data_map,
            old_map,
            action,
            "cause of action"
          );
        } else if (field === "offence") {
          return displayLinkedFields(
            "offence",
            ["elements", "defence elements"],
            data_map,
            old_map,
            action,
            "offence"
          );
        } else if (field === "themes") {
          return displayLinkedFields(
            "themes",
            ["sub themes"],
            data_map,
            old_map,
            action,
            "theme"
          );
        } else if (field === "issues") {
          return displayLinkedFields(
            "issues",
            ["key facts"],
            data_map,
            old_map,
            action,
            "issue"
          );
        } else if (field === "elements" || field === "defence elements") {
          return displayChildField(
            field,
            "causes of action",
            data_map,
            old_map,
            action
          );
        } else if (field === "key facts") {
          return displayChildField(field, "issue", data_map, old_map, action);
        } else if (field === "sub themes") {
          return displayChildField(field, "theme", data_map, old_map, action);
        } else if (field === "parties") {
          return displayObjectField(data_map, old_map, field, action, "party");
        }
        if (action === "created" || action === "deleted") {
          if (field === "archived" || field === "team limit") {
            return "";
          }
          return (
            <LiStyled className="ml-2">
              <Medium14Font className="mr-1">{field}</Medium14Font>
              <Medium14Font
                cross={action === "deleted"}
                textColor={
                  action === "created"
                    ? theme.colors.approve
                    : theme.colors.burgundy
                }
              >
                {typeof v === "string" ? v : ""}
              </Medium14Font>
            </LiStyled>
          );
        } else {
          if (is_object === false && v === v_old) {
            return "";
          }
          return (
            <LiStyled className="ml-2" key={index}>
              <Medium14Font className="mr-1">{field}</Medium14Font>
              {is_object ? (
                <Fragment>
                  {v_old && v_old.length
                    ? v_old.map((v_, index) => (
                        <Fragment key={index}>
                          <Medium14Font cross textColor={theme.colors.burgundy}>
                            {v_}
                          </Medium14Font>{" "}
                          {v ? (
                            <Medium14Font className="mr-1">, </Medium14Font>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ))
                    : null}
                  {v && v.length
                    ? v.map((v_, index) => (
                        <Fragment key={index}>
                          <Medium14Font textColor={theme.colors.approve}>
                            {v_}
                          </Medium14Font>
                          {v.length - 1 !== index ? (
                            <Medium14Font className="mr-1">, </Medium14Font>
                          ) : (
                            ""
                          )}
                        </Fragment>
                      ))
                    : null}
                </Fragment>
              ) : (
                <Fragment>
                  {v_old ? (
                    <Fragment>
                      <Medium14Font cross textColor={theme.colors.burgundy}>
                        {v_old}
                      </Medium14Font>
                      <Medium14Font className="ml-1 mr-1">→</Medium14Font>
                    </Fragment>
                  ) : null}
                  <Medium14Font textColor={theme.colors.approve}>
                    {v}
                  </Medium14Font>
                </Fragment>
              )}
            </LiStyled>
          );
        }
      });
  };
  const displayAny = (data_map, old_map, action, object_type) => {
    return Object.keys(data_map)
      .sort((a, b) => {
        return reorderFields(object_type, a, b);
      })
      .map((field, index) => {
        let v, v_old, is_object;
        switch (typeof data_map[field]) {
          case "object":
            v = data_map[field];
            v_old = old_map[field]
              ? Object.values(old_map[field]).filter(
                  (not) => Object.values(data_map[field]).indexOf(not) === -1
                )
              : false;
            is_object = true;
            break;
          case "boolean":
            v = data_map[field] ? "Yes" : "No";
            v_old = old_map[field] ? "Yes" : "No";
            is_object = false;
            break;
          default:
            if (field === "counterclaim" || field === "core book") {
              v = data_map[field] ? "Yes" : "No";
              v_old = old_map[field] ? "Yes" : "No";
            } else if (field === "document type" || field === "class name") {
              let f_new = classes.find((f) => f.value === data_map[field]);
              let f_old = classes.find((f) => f.value === old_map[field]);
              v = f_new ? f_new.label : data_map[field];
              v_old = f_old ? f_old.label : old_map[field];
            } else if (field === "date") {
              v = data_map[field]
                ? moment(data_map[field]).format("DD-MM-YYYY")
                : "";
              v_old = old_map[field]
                ? moment(old_map[field]).format("DD-MM-YYYY")
                : "";
            } else {
              v = data_map[field];
              v_old = old_map[field];
            }
            is_object = false;
            break;
        }
        if (field === "ordering") {
          return '';
        }
        if (field === "causes of action") {
          return displayLinkedFields(
            "causes of action",
            ["elements", "defence elements"],
            data_map,
            old_map,
            action,
            "cause of action"
          );
        } else if (field === "offence") {
          return displayLinkedFields(
            "offence",
            ["elements", "defence elements"],
            data_map,
            old_map,
            action,
            "offence"
          );
        } else if (
          field === "objectives" &&
          (object_type === TOPIC || object_type === WITNESSES)
        ) {
          return displayLinkedFieldsObjective(
            field,
            ["exam lines"],
            data_map,
            old_map,
            action,
            "exam lines",
            object_type
          );
        } else if (
          field === "exam lines" &&
          (object_type === TOPIC || object_type === WITNESSES)
        ) {
          return displayChildFieldObjective(
            field,
            "objectives",
            data_map,
            old_map,
            action,
            object_type
          );
        } else if (field === "themes") {
          return displayLinkedFields(
            "themes",
            ["sub themes"],
            data_map,
            old_map,
            action,
            "theme"
          );
        } else if (field === "issues") {
          return displayLinkedFields(
            "issues",
            ["key facts"],
            data_map,
            old_map,
            action,
            "issue"
          );
        } else if (field === "reliefs") {
          return displayLinkedFields(
            "reliefs",
            ["proofs"],
            data_map,
            old_map,
            action,
            "relief"
          );
        } else if (field === "elements" || field === "defence elements") {
          return displayChildField(
            field,
            "causes of action",
            data_map,
            old_map,
            action
          );
        } else if (field === "key facts") {
          return displayChildField(field, "issue", data_map, old_map, action);
        } else if (field === "proofs") {
          return displayChildField(field, "relief", data_map, old_map, action);
        } else if (field === "sub themes") {
          return displayChildField(field, "theme", data_map, old_map, action);
        }
        if (action === "created" || action === "deleted") {
          if (typeof v === "string" || typeof v === "number") {
            return (
              <LiStyled className="ml-2" key={index}>
                <Medium14Font className="mr-1">{field}</Medium14Font>
                <Medium14Font
                  cross={action === "deleted"}
                  textColor={
                    action === "created"
                      ? theme.colors.approve
                      : theme.colors.burgundy
                  }
                >
                  {v}
                </Medium14Font>
              </LiStyled>
            );
          } else {
            return (
              <LiStyled className="ml-2" key={index}>
                <Medium14Font className="mr-1">{field}</Medium14Font>
                <Medium14Font
                  cross={action === "deleted"}
                  textColor={
                    action === "created"
                      ? theme.colors.approve
                      : theme.colors.burgundy
                  }
                >
                  <ul className="ml-3">
                    {v.map((item, index) => {
                      return (
                        <LiTStyled key={index} className="ml-2">
                          <Medium14Font
                            cross={action === "deleted"}
                            textColor={
                              action === "created"
                                ? theme.colors.approve
                                : theme.colors.burgundy
                            }
                          >
                            {item}
                          </Medium14Font>
                        </LiTStyled>
                      );
                    })}
                  </ul>
                </Medium14Font>
              </LiStyled>
            );
          }
        } else {
          if (is_object === false && v === v_old) {
            return "";
          }
          return (
            <LiStyled className="ml-2" key={index}>
              <Medium14Font className="mr-1">{field}</Medium14Font>
              {is_object ? (
                <ul className="ml-3">
                  {v_old && v_old.length
                    ? v_old.map((v_, index) => {
                        console.log(field);
                        return (
                          <LiTStyled key={index} className="ml-2">
                            <Medium14Font
                              cross
                              textColor={theme.colors.burgundy}
                            >
                              {v_}
                            </Medium14Font>
                          </LiTStyled>
                        );
                      })
                    : null}
                  {v && v.length
                    ? v.map((v_, index) => (
                        <LiTStyled key={index} className="ml-2">
                          <Medium14Font textColor={theme.colors.approve}>
                            {v_}
                          </Medium14Font>
                        </LiTStyled>
                      ))
                    : null}
                </ul>
              ) : (
                <Fragment>
                  {v_old ? (
                    <Fragment>
                      <Medium14Font cross textColor={theme.colors.burgundy}>
                        {v_old}
                      </Medium14Font>
                      <Medium14Font className="ml-1 mr-1">→</Medium14Font>
                    </Fragment>
                  ) : null}
                  <Medium14Font textColor={theme.colors.approve}>
                    {v}
                  </Medium14Font>
                </Fragment>
              )}
            </LiStyled>
          );
        }
      });
  };
  const changePage = async () => {
    setMoreDataLoading(true);
    await getLogData();
    setMoreDataLoading(false);
  };
  useEffect(() => {
    if (page !== 1) {
      changePage();
    }
  }, [page]);
  useEffect(() => {
    let setData = async () => {
      let caseResp = await getCaseById(case_id, dispatch);
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
      await getLogData();
      setIsLoading(false);
    };
    setData();
  }, []);
  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex mt-2">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  const logSettings = {
    collapsed: true,
    fields: [
      {
        name: "date",
        label: "DATE",
        width: "15%",
        renderer: (object) => {
          return <Medium14Font>{object.date}</Medium14Font>;
        },
      },
      {
        name: "user_name",
        label: "USER",
        width: "20%",
        renderer: (object) => {
          return <Medium14Font>{object.user_name}</Medium14Font>;
        },
      },
      {
        name: "object_name",
        label: "OBJECT",
        width: "20%",
        renderer: (object) => {
          const base_route = is_admin
            ? "/admin/all-cases/" + case_id
            : "/app/cases/" + case_id;
          let return_;
          let link;
          switch (object.object_type) {
            case CASE_DOCUMENTS:
              link = `${base_route}`;
              break;
            case EVIDENCE_DOCUMENTS:
              link = `${base_route}/trial-hub/evidence/${object.fake_id}`;
              break;
            case WITNESSES:
              link = `${base_route}/trial-hub/witness/${object.fake_id}`;
              break;
            case EXAMWITNESS:
              link = `${base_route}/trial-hub/witness/${object.fake_id}`;
              break;
            case AUTHORITIES_DOCUMENTS:
              if (object.parent_object_id) {
                link = `${base_route}/applications-hub/${object.parent_object_id}`;
              } else {
                link = `${base_route}/trial-hub/authorities/${object.fake_id}`;
              }
              break;
            case SUB_EVIDENCE:
              link = object.linked_object
                ? `${base_route}/documents/${object.linked_object}`
                : "";
              break;
            case TOPIC:
              link = object.linked_object
                ? `${base_route}/trial-hub/witness/${object.linked_object}`
                : "";
              break;
            case DOCUMENT:
              link = `${base_route}/documents/${object.fake_id}`;
              break;
            case APPLICATION:
              link = `${base_route}/applications-hub/${object.fake_id}`;
              break;
            default:
              link = false;
              break;
          }
          if (link) {
            if (object.object_type === SUB_EVIDENCE) {
              return_ = (
                <Medium14Font
                  pinter
                  onClick={() => {
                    history.push({
                      state: { subevidence: object.subject_id },
                      pathname: link,
                    });
                  }}
                  textColor={theme.colors.blue}
                >
                  {object.object_name}
                </Medium14Font>
              );
            } else {
              return_ = (
                <Link to={link}>
                  <Medium14Font textColor={theme.colors.blue}>
                    {object.object_name}
                  </Medium14Font>
                </Link>
              );
            }
          } else {
            return_ = <Medium14Font>{object.object_name}</Medium14Font>;
          }
          return return_;
        },
      },
      {
        name: "properties:",
        label: "ACTION",
        width: "45%",
        renderer: (object) => {
          let data_map = {};
          let old_map = {};
          let action;
          if (
            object.description.indexOf("Created") === 0 ||
            object.description.indexOf("Deleted") === 0
          ) {
            action =
              object.description.indexOf("Deleted") === 0
                ? "deleted"
                : "created";
            Object.keys(object.properties.attributes).forEach(
              (field, index, array) => {
                if (
                  object.properties.attributes[field] !== null &&
                  object.properties.attributes[field] !== ""
                ) {
                  let _field = fields_map[field]
                    ? fields_map[field]
                    : String(field).replaceAll("_", " ");
                  data_map[_field] = object.properties.attributes[field];
                }
              }
            );
          } else {
            action = "updated";
            Object.keys(object.properties.attributes).forEach(
              (field, index, array) => {
                if (
                  object.properties.attributes[field] !== null &&
                  object.properties.attributes[field] !== ""
                ) {
                  let _field = fields_map[field]
                    ? fields_map[field]
                    : String(field).replaceAll("_", " ");
                  data_map[_field] = object.properties.attributes[field];
                }
              }
            );
            Object.keys(object.properties.old).forEach(
              (field, index, array) => {
                if (
                  object.properties.old[field] !== null &&
                  object.properties.old[field] !== ""
                ) {
                  let _field = fields_map[field]
                    ? fields_map[field]
                    : String(field).replaceAll("_", " ");
                  old_map[_field] = object.properties.old[field];
                }
              }
            );
          }
          let display;
          switch (object.object_type) {
            case CASE_DOCUMENTS:
              if (
                Object.keys(data_map).indexOf("file") === -1 &&
                Object.keys(data_map).indexOf("file_size") === -1 &&
                Object.keys(data_map).indexOf("date") === -1 &&
                Object.keys(data_map).indexOf("name") === -1 &&
                Object.keys(data_map).indexOf("bundles") === -1
              ) {
                display = displayCase(data_map, old_map, action);
              } else {
                display = displayAny(data_map, old_map, action, DOCUMENT);
              }
              break;
            default:
              display = displayAny(
                data_map,
                old_map,
                action,
                object.object_type
              );
          }
          return (
            <Fragment>
              <Medium14Font>{object.description}:</Medium14Font>
              <br />
              <ul className="ml-2">{display}</ul>
            </Fragment>
          );
        },
      },
    ],
  };
  return (
    <Table
      settings={logSettings}
      data={logItems}
      setPage={setPage}
      moreDataLoading={moreDataLoading}
      pagination={logData.meta.pagination}
    />
  );
}
