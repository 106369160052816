export const groupedChapters = {
  opening: {
    label: "Opening",
    order: 0,
    freestyle: true,
    items: [
      {
        id: 99,
        label: "Opening",
        group: "opening",
      },
    ],
  },
  finale: {
    label: "Finale",
    order: 5,
    freestyle: true,
    items: [
      {
        id: 40,
        label: "Finale",
        group: "finale",
      },
    ],
  },
  // "Freestyle 2": {
  //   order: 2,
  //   freestyle: true,
  //   items: [
  //     {
  //       id: 20,
  //       label: "Freestyle 2",
  //     },
  //   ],
  // },
  // "Freestyle 3": {
  //   order: 3,
  //   freestyle: true,
  //   items: [
  //     {
  //       id: 30,
  //       label: "Freestyle 3",
  //     },
  //   ],
  // },
  witness: {
    label: "Witness",
    order: 2,
    items: [
      {
        id: 2,
        label: "Chapter 2",
        group: "witness",
      },
      {
        id: 3,
        label: "Chapter 3",
        group: "witness",
      },
      {
        id: 4,
        label: "Chapter 4",
        group: "witness",
      },
      {
        id: 5,
        label: "Chapter 5",
        group: "witness",
      },
    ],
  },
  evidence: {
    label: "Evidence",
    order: 3,
    items: [
      {
        id: 6,
        label: "Chapter 6",
        group: "evidence",
      },
      {
        id: 7,
        label: "Chapter 7",
        group: "evidence",
      },
      {
        id: 8,
        label: "Chapter 8",
        group: "evidence",
      },
    ],
  },
  "cause-of-action": {
    label: "Cause of action",
    order: 4,
    items: [
      {
        id: 9,
        label: "Chapter 9",
        group: "cause-of-action",
      },
      {
        id: 10,
        label: "Chapter 10",
        group: "cause-of-action",
      },
      {
        id: 11,
        label: "Chapter 11",
        group: "cause-of-action",
      },
    ],
  },
};

// var groupBy = function (xs, key) {
//   return xs.reduce(function (rv, x) {
//     (rv[x[key]] = rv[x[key]] || []).push(x);
//     return rv;
//   }, {});
// };

// const chapters = [
//   {
//     id: 1,
//     label: "Chapter 1",
//     group: "none",
//   },
//   {
//     id: 2,
//     label: "Chapter 2",
//     group: "Group 1",
//   },
//   {
//     id: 3,
//     label: "Chapter 3",
//     group: "Group 1",
//   },
//   {
//     id: 4,
//     label: "Chapter 4",
//     group: "Group 1",
//   },
//   {
//     id: 5,
//     label: "Chapter 5",
//     group: "Group 1",
//   },
//   {
//     id: 6,
//     label: "Chapter 6",
//     group: "Group 2",
//   },
//   {
//     id: 7,
//     label: "Chapter 7",
//     group: "Group 2",
//   },
//   {
//     id: 8,
//     label: "Chapter 8",
//     group: "Group 2",
//   },
//   {
//     id: 9,
//     label: "Chapter 9",
//     group: "Group 3",
//   },
//   {
//     id: 10,
//     label: "Chapter 10",
//     group: "Group 3",
//   },
//   {
//     id: 11,
//     label: "Chapter 11",
//     group: "Group 3",
//   },
// ];

// export const groupedChapters = groupBy(chapters, "group");
