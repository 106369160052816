import React, { Fragment, useEffect, useState } from "react";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch } from "react-redux";
import Label from "../AddCardForm/Label";
import { getEvidenceList } from "../../../containers/Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import Checkbox from "../../Checkbox/Checkbox";
import Button from "../../buttons/Button/Button";
import ButtonNew from "../../buttons/Button/ButtonNew";

const RelatedEvidenceForm = ({ evidenceObject, setEvidenceObject, caseId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [object_, setObject] = useState(evidenceObject);
  const [submitting, setSubmitting] = useState(false);
  const [relatedEvidences, setRelatedEvidences] = useState(
    evidenceObject && evidenceObject.related_evidence
      ? evidenceObject.related_evidence.map((v) => v.id)
      : []
  );
  const [evidences, setEvidences] = useState([]);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    let rel_ev = [];
    relatedEvidences.forEach((v) => {
      let item = evidences.find((i) => i.real_id === v);
      rel_ev.push({ id: item.real_id, label: item.label });
    });
    if (evidenceObject.classes) {
      setEvidenceObject({ related_evidence: rel_ev });
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    } else {
      setEvidenceObject(rel_ev);
      setSubmitting(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let resp = await getEvidenceList(caseId, dispatch);
      setEvidences(
        resp.filter((v) => {
          if (evidenceObject.classes) {
            return v.real_id !== evidenceObject.classes.id;
          } else {
            return v.real_id !== evidenceObject.real_id;
          }
        })
      );
      setIsLoading(false);
    };
    getData();
  }, []);
  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  const addCheckbox = (item) => {
    let clone = [...relatedEvidences];
    const index = clone.indexOf(item.real_id);
    if (index === -1) {
      clone.push(item.real_id);
    } else {
      clone.splice(index, 1);
    }
    setRelatedEvidences(clone);
  };

  return (
    <Fragment>
      <div className="form-group">
        <Label name={"Related evidence"} />
      </div>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="container-fluid">
          {evidences.map((item) => {
            return (
              <Checkbox
                label={item.label}
                checked={relatedEvidences.indexOf(item.real_id) !== -1}
                onChange={() => {
                  addCheckbox(item);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="form-group" style={{ marginTop: "20px" }}>
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            style={{ height: "86%", overflowY: "auto" }}
          >
            <ButtonNew
              type="submit"
              loading={submitting}
              disabled={submitting}
              // clickHandler={handleSubmit}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default RelatedEvidenceForm;
