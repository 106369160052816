import styled from "styled-components/macro";

const SubscriptionPageStyled = styled.div`
  min-width: 320px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
`;

export default SubscriptionPageStyled;
