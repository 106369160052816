import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { Bold14Font, Bold18Font } from "../../FontsNewComponent/Fonts";
import deleteIcon from "../../../assets/img/svg/deleteCard.svg";
import DivCardStyled from "../../baseAppComponents/Subscription/styled/DivCardStyled";
import { useDispatch, useSelector } from "react-redux";
import ModalCheckCardStyled from "./styled/ModalCheckCardStyled";
import Button from "../../buttons/Button/Button";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { RESET_MODAL } from "../../../redux/types";

const ModalCheckCard = ({
  data,
  onClickUpdateCard,
  onClickContinue,
  theme,
}) => {
  // page states
  const [isLoading, setIsLoading] = useState(false);

  // data states
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  return (
    <ModalCheckCardStyled>
      <DivCardStyled className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <Bold18Font textColor={theme.colors.white}>
            {user.card_brand ? user.card_brand.toUpperCase() : ""}
          </Bold18Font>
          <Bold18Font className="ml-3" textColor={theme.colors.white}>
            {user.card_last_four}
          </Bold18Font>
        </div>
        <Bold18Font textColor={theme.colors.white}>Active card</Bold18Font>
      </DivCardStyled>
      <div className="form-group">
        <div className="d-flex justify-content-end mt-2">
          <ButtonNew
            className="mr-3"
            secondary
            clickHandler={() => {
              dispatch({ type: RESET_MODAL });
            }}
            disabled={isLoading}
            loading={isLoading}
          >
            <Bold14Font textColor={theme.colors.blue}>Cancel</Bold14Font>
          </ButtonNew>
          <ButtonNew
            className="mr-3"
            secondary
            clickHandler={() => {
              setIsLoading(true);
              onClickUpdateCard();
            }}
            disabled={isLoading}
            loading={isLoading}
          >
            <Bold14Font textColor={theme.colors.blue}>Update Card</Bold14Font>
          </ButtonNew>
          <ButtonNew
            type={"submit"}
            disabled={isLoading}
            primary
            loading={isLoading}
            clickHandler={() => {
              setIsLoading(true);
              onClickContinue();
            }}
          >
            Subscribe
          </ButtonNew>
        </div>
      </div>
    </ModalCheckCardStyled>
  );
};

ModalCheckCard.propTypes = {
  data: PropTypes.object.isRequired,
  onClickUpdateCard: PropTypes.func.isRequired,
  onClickContinue: PropTypes.func.isRequired,
};

export default withTheme(ModalCheckCard);
