import React from "react";
import PropTypes from "prop-types";
import TextAreaStyled from "./styled/TextAreaStyled";

import { Bold14Font } from "../Fonts/Fonts";

const TextArea = ({ label, customStyle, ...props }) => {
  return (
    <label className="w-100">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col">
            <Bold14Font>{label}</Bold14Font>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TextAreaStyled as={customStyle} {...props} />
          </div>
        </div>
      </div>
    </label>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};

export default TextArea;
