import React from "react";
import NoDocsIcon from "../../assets/img/svg/no_documents_icon.svg";

const NoRecords = ({ children, ...props }) => {
  return (
    <div className="row">
      <div
        className="col d-flex flex-column justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div className="mb-4">
          <img src={NoDocsIcon} />
        </div>
        <div className="text-center" {...props}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default NoRecords;
