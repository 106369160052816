import React from "react";
import styled from "styled-components";

const UndoIcon = ({ opacity = "0.25" }) => (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M12.6333 9.4C10.65 9.7 8.9 10.3 7.26667 11.4L4 8.5V15.5H12.1667L9.01667 12.8C13.3333 10.2 19.2833 11 22.4333 14.7C22.6667 15 22.9 15.2 23.0167 15.5L25.1167 14.6C22.55 10.8 17.65 8.7 12.6333 9.4Z"
        fill="black"
      />
    </g>
  </svg>
);

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
  ${(props) => props.active && ` background: rgba(0, 0, 0, 0.05);`}
`;

const Text = styled.span`
  font-size: 14px;
  color: ${(props) => props.color};
  text-select: none;
`;

const Undo = ({ editor }) => {
  const active = editor.can().undo();

  return (
    <Container
      onClick={() => {
        editor.chain().focus().undo().run();
      }}
    >
      <UndoIcon opacity={active ? "0.7" : "0.25"} />
      <Text color={active ? "#0F122F" : "#6a6c7d"}>Undo</Text>
    </Container>
  );
};

export default Undo;
