import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CaseCardTypeStyled = styled.div`
  margin-right: 9px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.purpleBorder};
  background-color: ${({ theme }) => theme.colors.purpleFill};
  padding: 3px 6px;
  font-size: ${rem(`14px`)};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default CaseCardTypeStyled;
