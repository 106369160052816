import styled from "styled-components";

import plusIcon from "@/assets/img/notebook/chapters-add.svg";
// import moreIcon from "@/assets/img/notebook/more.svg";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
`;

export const ToolbarInputs = styled.div`
  width: 70%;
  display: flex;
`;

export const PlusIcon = styled.img.attrs({
  src: plusIcon,
})`
  width: 22px;
  margin-right: 4px;
`;

export const Row = styled.div`
  display: flex;
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyBetween && `justify-content: space-between;`}
${(props) =>
    props.groupHover &&
    `
:hover {
opacity: 0.8;
}
`}
`;

export const AddChapterLabel = styled.span`
  margin-right: 5px;
  color: #374ac2;
  font-weight: 600;
  font-size: 13px;
  user-select: none;
  //
`;

export const NewApplicationButton = styled.div`
  background: #fcfcff;
  border: 1px solid #dcdce6;
  height: 42px;
  widht: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
`;
