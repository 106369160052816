import React, { Fragment, useState, useEffect } from "react";
import { withTheme } from "styled-components/macro";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  createApplicationPaperFileById,
  createNewApplication,
  createNewApplicationPaper,
  deleteApplicationPaperById,
  getApplicationPaperById,
  getApplicationPapersList,
  updateApplicationById,
  updateApplicationPaperById,
} from "../../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import moment from "moment";
import Form from "../../../../components/forms/Form";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import PulseLoader from "react-spinners/PulseLoader";
import ButtonAnotherTry from "../../../../components/buttons/Button/ButtonAnotherTry";
import InputNew from "../../../../components/InputNew/InputNew";
import RadioButtonGroup from "../../../../components/buttons/RadioButtonGroup";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import UploadSingleFileInput from "../../../../components/inputs/UploadSingleFileInput";
import AssociationTable, {
  AssociationTableListElement,
} from "../../../../components/AssociationTable";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import CloseIcon from "../../../../components/svgIcons/CloseIcon";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AllocateParties, {
  addPartyTypeField,
  groupPartiesByType,
} from "../../../../components/forms/ApplicationsHubForms/AllocateParties";
import Table from "../../../../components/Table/Table";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import Arrow from "../../../../components/buttons/Button/Arrow";
import EvidenceImport from "../../TrialHub/Evidence/EvidenceImport";
import AssociateProofs from "../../../../components/forms/ApplicationsHubForms/AssociateProofs";
import bulkIcon from "../../../../assets/img3/svg/bulk-icon.svg";
import useWindowSize from "../../../../hooks/useWindowSize";
import { maxFileSize } from "../../../../utils";
import NoRecords from "../../../../components/NoRecords";
import { VIEW_SINGLE_FORM_AUTHORITIES } from "./ApplicationAuthorities";

export const VIEW_TABLE_PAPERS = "APPLICATION_PAPERS_VIEW/TABLE";
export const VIEW_SINGLE_FORM_PAPERS = "APPLICATION_PAPERS_VIEW/SINGLE_FORM";
export const VIEW_BULK_IMPORT_PAPERS = "APPLICATION_PAPERS_VIEW/BULK_IMPORT";

const MOTION = "Motion";
const AFFIDAVIT = "Affidavit";
const EXHIBIT = "Exhibit";
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";

const docTypesList = [
  {
    title: <Medium14Font>{MOTION}</Medium14Font>,
    type: MOTION,
  },
  {
    title: <Medium14Font>{AFFIDAVIT}</Medium14Font>,
    type: AFFIDAVIT,
  },
  {
    title: <Medium14Font>{EXHIBIT}</Medium14Font>,
    type: EXHIBIT,
  },
];

const probativeStatusList = [
  {
    title: <Medium14Font>{SUPPORTING}</Medium14Font>,
    type: SUPPORTING,
  },
  {
    title: <Medium14Font>{OPPOSING}</Medium14Font>,
    type: OPPOSING,
  },
];

const AddEditApplicationPapers = ({
  applicationObject,
  // pageSubmitState,
  blockBlurEvent,
  setApplicationObject,
  caseObject,
  setBlockBlurEvent,
  resetStatesViaKeyValue,
  setSelectedObjectFromLocation,
  changeFlowStage,
  selectedObjectFromLocation,
  setApplicationPaperSubmitObject,
  setApplicationPapersCurrentView,
  theme,
}) => {
  // page states
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const { width } = useWindowSize();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const [submitObject, setSubmitObject] = useState(null);
  const { id, applicationId } = useParams();
  const [currentView, setCurrentView] = useState(
    location &&
      location.state &&
      location.state.unique_data &&
      location.state.unique_data.length
      ? VIEW_BULK_IMPORT_PAPERS
      : null
  );
  const [
    keyValueToBulkImportApplicationsPapers,
    setKeyValueToBulkImportApplicationsPapers,
  ] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  // application papers states
  const [applicationPapersList, setApplicationPapersList] = useState([]);
  const [
    applicationPaperSelectedObject,
    setApplicationPaperSelectedObject,
  ] = useState(selectedObjectFromLocation ? selectedObjectFromLocation : null);
  const [applicationPaperLabel, setApplicationPaperLabel] = useState("");
  const [applicationPaperDocType, setApplicationPaperDocType] = useState(null);
  const [applicationPaperDate, setApplicationPaperDate] = useState(null);

  const [
    applicationPaperProbativeStatus,
    setApplicationPaperProbativeStatus,
  ] = useState(null);
  // const [applicationPaperFile, setApplicationPaperFile] = useState(
  //   new File([""], "")
  // );
  const [applicationPaperFile, setApplicationPaperFile] = useState(null);
  const [
    applicationPapersAllocatedPartiesList,
    setApplicationPapersAllocatedPartiesList,
  ] = useState([]);
  const [groupedPartiesByTypeObject, setGroupedPartiesByTypeObject] = useState(
    null
  );
  const [
    applicationPaperReliefsList,
    setApplicationPaperReliefsList,
  ] = useState([]);
  const [applicationPaperProofsList, setApplicationPaperProofsList] = useState(
    []
  );

  // get data

  const saveDataList = (list) => {
    setApplicationPapersList(list);
  };
  const changeObject = (data) => {
    if (!applicationPaperSelectedObject && !data.parties) {
      data.parties = [];
    }
    if (!applicationPaperSelectedObject && !data.proofs) {
      data.proofs = [];
    }
    if (!applicationPaperSelectedObject && !data.reliefs) {
      data.reliefs = [];
    }
    setApplicationPaperSelectedObject((prevState) => ({
      ...prevState,
      ...data,
    }));
    setApplicationPaperSubmitObject((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const saveDataObject = (object) => {
    setApplicationPaperLabel(object.label);
    setApplicationPaperDocType(object.doc_type);
    setApplicationPaperDate(object.date ? parseISO(object.date) : null);
    setApplicationPaperProbativeStatus(object.probative_status);
    if (typeof object.file === "object") {
      setApplicationPaperFile(object.file);
    } else {
      setApplicationPaperFile(new File([""], object.file ?? null));
    }
    setApplicationPapersAllocatedPartiesList(
      addPartyTypeField(object.parties ?? [])
    );
    setGroupedPartiesByTypeObject(groupPartiesByType(object.parties ?? []));
    setApplicationPaperReliefsList(object.reliefs ?? []);
    setApplicationPaperProofsList(object.proofs ?? []);
  };

  const getDataListFromAPI = async () => {
    const resp = await getApplicationPapersList(applicationId, id, dispatch);
    if (resp) {
      saveDataList(resp);
      setIsLoading(false);
    }
  };

  const getDataObjectFromAPI = async () => {
    const resp = await getApplicationPaperById(
      applicationPaperSelectedObject.id,
      applicationId,
      id,
      dispatch
    );
    if (resp) {
      saveDataObject(resp);
      if (setApplicationPaperSubmitObject) {
        setApplicationPaperSubmitObject(resp);
      }
      setIsLoading(false);
    }
  };

  // get application papers list
  useEffect(() => {
    if (
      !applicationPapersList.length &&
      applicationObject.id &&
      !applicationPaperSelectedObject
    ) {
      getDataListFromAPI();
    } else {
      setIsLoading(false);
    }
  }, []);

  // configure view logic based on applicationPapersList
  useEffect(() => {
    if (!applicationId) {
      setCurrentView(VIEW_TABLE_PAPERS);
      if (setApplicationPapersCurrentView) {
        setApplicationPapersCurrentView(VIEW_TABLE_PAPERS);
      }
      setIsLoading(false);
    } else if (currentView !== VIEW_BULK_IMPORT_PAPERS) {
      if (applicationPaperSelectedObject) {
        setCurrentView(VIEW_SINGLE_FORM_PAPERS);
        if (setApplicationPapersCurrentView) {
          setApplicationPapersCurrentView(VIEW_SINGLE_FORM_PAPERS);
        }
        saveDataObject(applicationPaperSelectedObject);
        if (setApplicationPaperSubmitObject) {
          setApplicationPaperSubmitObject(applicationPaperSelectedObject);
        }
      } else {
        setCurrentView(VIEW_TABLE_PAPERS);
        if (setApplicationPapersCurrentView) {
          setApplicationPapersCurrentView(VIEW_TABLE_PAPERS);
        }
      }
    }
  }, [applicationPapersList.length, applicationPaperSelectedObject]);

  const submitDataHandler = async (submitObj = submitObject) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    let applicationPaperObjectResp;
    if (!applicationPaperSelectedObject || !applicationPaperSelectedObject.id) {
      applicationPaperObjectResp = await createNewApplicationPaper(
        applicationId,
        id,
        dispatch,
        applicationPaperSelectedObject
      );
    } else {
      applicationPaperObjectResp = await updateApplicationPaperById(
        applicationPaperSelectedObject.id,
        applicationId,
        id,
        dispatch,
        applicationPaperSelectedObject
      );
    }
    if (
      applicationPaperSelectedObject &&
      applicationPaperSelectedObject.file &&
      typeof applicationPaperSelectedObject.file.size !== "undefined" &&
      applicationPaperSelectedObject.file.size !== 0
    ) {
      let formData = new FormData();
      formData.append("file", applicationPaperSelectedObject.file);
      applicationPaperObjectResp = await createApplicationPaperFileById(
        applicationPaperObjectResp.id,
        applicationId,
        id,
        dispatch,
        formData
      );
    }
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  // btn methods

  const onClickBulkImportHandler = () => {
    setCurrentView(VIEW_BULK_IMPORT_PAPERS);
    if (setApplicationPapersCurrentView) {
      setApplicationPapersCurrentView(VIEW_BULK_IMPORT_PAPERS);
    }
  };

  const onClickDocTypeRadioButtonHandler = async (data) => {
    changeObject({ doc_type: data });
  };

  const onClickProbativeStatusRadioButtonHandler = async (data) => {
    changeObject({ probative_status: data });
  };

  const onClickAllocatePartiesHandler = async () => {
    console.log(999);
    console.log(applicationObject);
    console.log(caseObject);
    console.log(applicationPaperSelectedObject);
    console.log(setApplicationPaperSelectedObject);
    console.log(changeObject);
    console.log(applicationPapersAllocatedPartiesList);
    console.log({ caseId: id, applicationId: applicationId });
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AllocateParties
          applicationObject={applicationObject}
          caseObject={caseObject}
          applicationPaperObject={applicationPaperSelectedObject}
          setApplicationPaperObject={setApplicationPaperSelectedObject}
          returnData={changeObject}
          savedPartiesList={applicationPapersAllocatedPartiesList}
          routeParams={{ caseId: id, applicationId: applicationId }}
        />
      ),
      title: "Parties",
    });
  };

  const onClickAssociateReliefAndProofsHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateProofs
          applicationObject={applicationObject}
          applicationPaperObject={applicationPaperSelectedObject}
          setApplicationPaperObject={changeObject}
          savedReliefs={applicationPaperReliefsList}
          returnObject={true}
          savedProofs={applicationPaperProofsList}
          routeParams={{ caseId: id, applicationId: applicationId }}
        />
      ),
      title: "Reliefs and Proofs",
    });
  };

  const onClickEditTableRowHandler = (object) => {
    setApplicationPaperSelectedObject(object);
    saveDataObject(object);
    if (setApplicationPaperSubmitObject) {
      setApplicationPaperSubmitObject(object);
    }
  };

  const onClickDeleteTableRowHandler = async (object) => {
    const resp = deleteApplicationPaperById(
      object.id,
      applicationId,
      id,
      dispatch
    );
    if (resp) {
      setApplicationPapersList((prevState) =>
        prevState.filter((el) => el.id !== object.id)
      );
    }
  };

  const onClickAddNewPaperHandler = async () => {
    if (!applicationObject.id) {
      if (!addLoading) {
        setAddLoading(true);
        let resp = await createNewApplication(
          caseObject.id,
          dispatch,
          applicationObject
        );
        setApplicationObject(resp);
        setAddLoading(false);
        setCurrentView(VIEW_SINGLE_FORM_PAPERS);
        if (setApplicationPapersCurrentView) {
          setApplicationPapersCurrentView(VIEW_SINGLE_FORM_PAPERS);
        }
        history.push(
          !is_admin
            ? `/app/cases/${id}/applications-hub/edit/${resp.id}`
            : `/admin/all-cases/${id}/applications-hub/edit/${resp.id}`
        );
      }
    } else {
      if (!addLoading) {
        setAddLoading(true);
        let resp = await updateApplicationById(
          applicationId,
          caseObject.id,
          dispatch,
          {
            proofs: applicationObject.proofs,
            reliefs: applicationObject.reliefs,
            moving_parties: applicationObject.moving_parties,
            respondent_parties: applicationObject.respondent_parties,
          }
        );
        setApplicationObject(resp);
        setAddLoading(false);
      }
      setCurrentView(VIEW_SINGLE_FORM_PAPERS);
      if (setApplicationPapersCurrentView) {
        setApplicationPapersCurrentView(VIEW_SINGLE_FORM_PAPERS);
      }
    }
  };

  const onClickCloseFormHandler = async () => {
    await submitDataHandler();
    setCurrentView(VIEW_TABLE_PAPERS);
    if (setApplicationPapersCurrentView) {
      setApplicationPapersCurrentView(VIEW_TABLE_PAPERS);
    }
    setSelectedObjectFromLocation(null);
    setApplicationPaperSelectedObject(null);
    resetStatesViaKeyValue((prevState) => prevState + 1);
  };

  const onClickBasicInfoHandler = () => {
    changeFlowStage(0);
  };

  const onClickApplicationAuthoritiesHandler = () => {
    changeFlowStage(2);
  };

  const onClickSingleEvidenceButtonHandler = () => {
    // TODO: Does I need this here?
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    setKeyValueToBulkImportApplicationsPapers((prevState) => prevState + 1);
    setCurrentView(VIEW_SINGLE_FORM_PAPERS);
    if (setApplicationPapersCurrentView) {
      setApplicationPapersCurrentView(VIEW_SINGLE_FORM_PAPERS);
    }
  };

  const onClickReturnToApplicationsPapersHandler = (list) => {
    setApplicationPapersList((prevState) => [...prevState, ...list]);
    setCurrentView(VIEW_TABLE_PAPERS);
    if (setApplicationPapersCurrentView) {
      setApplicationPapersCurrentView(VIEW_TABLE_PAPERS);
    }
  };

  // input methods

  const onChangeApplicationPaperLabelHandler = (event) => {
    event.persist();
    if (event.target.value.length <= 125) {
      changeObject({ label: event.target.value });
    }
  };

  const onChangeDateHandler = async (date) => {
    const submitObj = {
      date: moment(date).format("YYYY-MM-DD"),
    };
    changeObject(submitObj);
    // await submitDataHandler(submitObj);
  };

  const onChangeApplicationPaperFileHandler = async (file) => {
    changeObject({ file });
  };

  const navButtons = (
    <div className="d-flex">
      <ButtonNew
        style={{ marginRight: "10px" }}
        withIcon
        secondary
        clickHandler={onClickBasicInfoHandler}
      >
        <Fragment>
          <Arrow className="mr-1" style={{ transform: "rotate(180deg)" }} />
          <Bold14Font textColor={theme.colors.blue}>Basic Info</Bold14Font>
        </Fragment>
      </ButtonNew>
      <ButtonNew
        withIcon
        secondary
        clickHandler={onClickApplicationAuthoritiesHandler}
      >
        <Fragment>
          <Bold14Font textColor={theme.colors.blue}>
            Application Authorities
          </Bold14Font>
          <Arrow className="ml-1" />
        </Fragment>
      </ButtonNew>
    </div>
  );

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const allocatePartySettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Allocate Party",
        icon: plusIcon,
        callback: () => onClickAllocatePartiesHandler(),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => onClickAllocatePartiesHandler(),
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const uniquePartyTypesList = Object.keys(groupedPartiesByTypeObject);
          const filteredListOfPartiesByPartyType = applicationPapersAllocatedPartiesList.filter(
            (x) => x.type !== uniquePartyTypesList[index]
          );
          const submitObj = {
            parties: [...filteredListOfPartiesByPartyType],
          };
          changeObject(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      ...groupedPartiesByTypeObject,
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      setGroupedPartiesByTypeObject: (data) =>
        setGroupedPartiesByTypeObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const type = listCopy[0].type;
                      const groupedPartiesByTypeObjectCopy = {
                        ...groupedPartiesByTypeObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedPartiesByTypeObjectCopy,
                          [type]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = { parties: plainArray };
                        changeObject(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            // return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const allocateProofsSettings = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Reliefs and Proofs",
        icon: plusIcon,
        callback: () => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const submitObj = {
            reliefs: [],
            proofs: [],
          };
          changeObject(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      "Reliefs and Proofs": applicationPaperSelectedObject
        ? [
            ...applicationPaperSelectedObject.reliefs,
            ...applicationPaperSelectedObject.proofs,
          ]
        : [],
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      // setApplicationPaperSelectedObject: (data) =>
      //   setApplicationPaperSelectedObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element, index) => (
              <AssociationTableListElement key={index}>
                <Bold14Font textColor={theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const role = "Reliefs and Proofs";
                      const groupedReliefObjectCopy = {
                        "Reliefs and Proofs": [
                          ...applicationPaperSelectedObject.reliefs,
                          ...applicationPaperSelectedObject.proofs,
                        ],
                      };
                      const findElementIndex = index;
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedReliefObjectCopy,
                          [role]: listCopy,
                        };
                        // setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = {
                          reliefs: plainArray.filter(
                            (relief) => "pivot" in relief
                          ),
                          proofs: plainArray.filter(
                            (proof) => !("pivot" in proof)
                          ),
                        };
                        changeObject(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            // return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const onCloseHandler = () => {
    setCurrentView(VIEW_TABLE_PAPERS);
    if (setApplicationPapersCurrentView) {
      setApplicationPapersCurrentView(VIEW_TABLE_PAPERS);
    }
    setSelectedObjectFromLocation(null);
    setApplicationPaperSelectedObject(null);
    resetStatesViaKeyValue((prevState) => prevState + 1);
  };
  const applicationPapersTable = {
    collapsed: false,
    actions: [
      {
        type: "edit",
        callback: (object) => onClickEditTableRowHandler(object),
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              await onClickDeleteTableRowHandler(object);
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.id}</Medium14Font>;
        },
      },
      {
        name: "label",
        label: "DOC LABEL",
        renderer: (object) => {
          return <Medium14Font>{object.label}</Medium14Font>;
        },
      },
      {
        name: "doc_type",
        label: "DOC TYPE",
        renderer: (object) => {
          return <Medium14Font>{object.doc_type}</Medium14Font>;
        },
      },
      {
        name: "date",
        label: "DATE",
        renderer: (object) => {
          return (
            <Medium14Font>
              {object.date ? format(parseISO(object.date), "dd-MM-yyyy") : ""}
            </Medium14Font>
          );
        },
      },
      {
        name: "probative_status",
        label: "PROBATIVE STATUS",
        renderer: (object) => {
          return <Medium14Font>{object.probative_status}</Medium14Font>;
        },
      },
      {
        name: "parties",
        label: "PARTIES",
        renderer: (object) => {
          if (object.parties && object.parties.length) {
            return (
              <ul>
                {object.parties.map((party) => (
                  <LiStyled key={party.id}>
                    <Medium14Font>{party.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
      {
        name: "reliefs",
        label: "RELIEFS",
        renderer: (object) => {
          if (
            (object.reliefs && object.reliefs.length) ||
            (object.proofs && object.proofs.length)
          ) {
            return (
              <ul>
                {[...object.reliefs, ...object.proofs].map((el, index) => (
                  <LiStyled key={index}>
                    <Medium14Font style={{ color: theme.colors.darkOpacity }}>
                      {el.name}
                    </Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  switch (currentView) {
    case VIEW_SINGLE_FORM_PAPERS:
      return (
        <Fragment>
          <AnimatedDiv className="container-fluid">
            <div className="row">
              <Form
                className="d-flex justify-content-between"
                style={{ padding: "11px 0" }}
              >
                <Bold24Font
                  as="div"
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  Single Import
                </Bold24Font>
                <ButtonAnotherTry
                  tertiary
                  clickHandler={onClickBulkImportHandler}
                  className="d-flex align-items-center"
                >
                  <img
                    src={bulkIcon}
                    alt=""
                    style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                  />
                  <Bold14Font
                    style={{
                      color: theme.colors.blue,
                      whiteSpace: "nowrap",
                      marginRight: "30px",
                    }}
                  >
                    Bulk Import
                  </Bold14Font>
                </ButtonAnotherTry>
              </Form>
            </div>
            <div className="row">
              <Form style={{ overflow: "visible" }}>
                <div style={{ padding: "0 15px" }}>
                  <div className="form-group">
                    <InputNew
                      label="Doc Label"
                      placeholder="Enter Doc Label"
                      name="label"
                      value={applicationPaperLabel}
                      onChangeHandler={onChangeApplicationPaperLabelHandler}
                    />
                  </div>
                  <div
                    className="form-group"
                    onMouseEnter={() => setBlockBlurEvent(true)}
                    onMouseLeave={() => setBlockBlurEvent(false)}
                  >
                    <RadioButtonGroup
                      label="Doc Type"
                      itemsObjects={docTypesList}
                      checkedButton={applicationPaperDocType}
                      setCheckedButton={onClickDocTypeRadioButtonHandler}
                    />
                  </div>
                  <div className="form-group row">
                    <div
                      className="col-12 col-sm-6"
                      // style={{ padding: "0 30px" }}
                    >
                      <CustomReactDatepicker
                        selected={applicationPaperDate}
                        onChange={onChangeDateHandler}
                        dateFormat={"dd-MM-yyyy"}
                        dateFormatCalendar={"dd-MM-yyyy"}
                        label="Issue Date"
                        name="date"
                        placeholderText="Select Date"
                      />
                    </div>
                  </div>
                  <div
                    className="form-group"
                    onMouseEnter={() => setBlockBlurEvent(true)}
                    onMouseLeave={() => setBlockBlurEvent(false)}
                  >
                    <RadioButtonGroup
                      label="Probative Status"
                      itemsObjects={probativeStatusList}
                      checkedButton={applicationPaperProbativeStatus}
                      setCheckedButton={
                        onClickProbativeStatusRadioButtonHandler
                      }
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-12 col-sm-6">
                      <UploadSingleFileInput
                        label="Upload File"
                        file={applicationPaperFile}
                        setFile={onChangeApplicationPaperFileHandler}
                        validTypes={[
                          "image/jpeg",
                          "image/jpg",
                          "image/png",
                          "application/pdf",
                        ]}
                        maxFileSize={maxFileSize}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div
                      className={
                        applicationPapersAllocatedPartiesList.length
                          ? "col-12"
                          : "col-12 col-sm-6"
                      }
                    >
                      <AssociationTable
                        settings={allocatePartySettings}
                        label="Party"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div
                      className={
                        applicationPaperReliefsList.length ||
                        applicationPaperProofsList.length
                          ? "col-12"
                          : "col-12 col-sm-6"
                      }
                    >
                      <AssociationTable
                        settings={allocateProofsSettings}
                        label="Reliefs and Proofs"
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </AnimatedDiv>
          <div className="row mb-4">
            <div className="col-12 d-flex flex-wrap justify-content-end">
              <div className="d-flex">
                <ButtonNew
                  secondary
                  style={{
                    marginBottom: width < 482 ? "8px" : "0",
                    marginRight: "10px",
                  }}
                  clickHandler={onCloseHandler}
                >
                  Discard
                </ButtonNew>
                <ButtonNew
                  primary
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  style={{
                    marginBottom: width < 482 ? "8px" : "0",
                    // marginRight: "10px",
                  }}
                  clickHandler={onClickCloseFormHandler}
                >
                  Save Paper
                </ButtonNew>
              </div>
              {/*{navButtons}*/}
            </div>
            {/*<div className="col-12 col-sm-6"></div>*/}
          </div>
        </Fragment>
      );
    case VIEW_TABLE_PAPERS:
      return (
        <Fragment>
          <AnimatedDiv className="container-fluid">
            {!applicationPapersList.length ? (
              <NoRecords>
                <Bold18Font textColor={"#0f122f"}>
                  Your Application Papers will appear here
                </Bold18Font>
              </NoRecords>
            ) : (
              <Table
                settings={applicationPapersTable}
                data={applicationPapersList}
              />
            )}
          </AnimatedDiv>
          <div className="row mb-4">
            <div className="col-12 d-flex flex-wrap justify-content-between">
              <ButtonNew
                primary
                disabled={addLoading}
                loading={addLoading}
                style={{
                  marginBottom: width < 505 ? "8px" : "0",
                  marginRight: "10px",
                }}
                clickHandler={onClickAddNewPaperHandler}
              >
                Add New Paper
              </ButtonNew>
              {navButtons}
            </div>
          </div>
        </Fragment>
      );
    case VIEW_BULK_IMPORT_PAPERS:
      return (
        <EvidenceImport
          key={keyValueToBulkImportApplicationsPapers}
          noTopLabelAndButtons
          createSingleElementHandler={onClickSingleEvidenceButtonHandler}
          applicationObject={applicationObject}
          changeApplicationPapersView={onClickReturnToApplicationsPapersHandler}
          isApplicationPapersBulkImportFlow
        />
      );
    default:
      return <Fragment />;
  }
};

AddEditApplicationPapers.propTypes = {
  applicationObject: PropTypes.object.isRequired,
  pageSubmitState: PropTypes.object.isRequired,
  blockBlurEvent: PropTypes.bool.isRequired,
  setBlockBlurEvent: PropTypes.func.isRequired,
  resetStatesViaKeyValue: PropTypes.func.isRequired,
  changeFlowStage: PropTypes.func.isRequired,
};

export default withTheme(AddEditApplicationPapers);
