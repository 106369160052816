import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const ToggleButtonSliderStyled = styled.span`
  position: absolute;
  ${({ disabled }) => (disabled ? null : "cursor: pointer;")};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;

  :before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: -4px;
    bottom: -4px;
    border-radius: 50%;
    background-color: ${({ theme, checked }) =>
      checked ? theme.colors.blue : theme.colors.gray};
    transition: 0.4s;
  }
`;

// background-color: ${({ theme, checked }) => checked ? theme.colors.blue : theme.colors.gray };

export default ToggleButtonSliderStyled;
