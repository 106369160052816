import styled from "styled-components/macro";
import { minButtonHeight } from "./ButtonStyled";
import rem from "../../../../utils/rem";

const SecondaryButtonStyled = styled.button`
  // Get min height from base button component to calc border radius
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  padding: ${rem("9px 20px")};
  background: ${({ theme }) => theme.colors.light};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`;

export default SecondaryButtonStyled;
