import {
  MODAL_BLOCK_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "./redux/types";
import { blockUnblockUser } from "./containers/Auth/auth";

/**
 * Toggle block status of user in admin interface
 * @param object
 * @param setObject
 * @param dispatch
 * @param changeObject
 */
export const blockToggleUser = (object, setObject, dispatch, changeObject) => {
  dispatch({
    type: SAVE_MODAL_DATA,
    extraData: object.active,
    payload: object.active
      ? `Are You sure, You want to block ${object.name}?`
      : `Are You sure, You want to UN-block ${object.name}?`,
    beforeCloseHandler: async () => {
      const resp = await blockUnblockUser(
        object.id,
        { active: !object.active },
        dispatch
      );
      if (changeObject) {
        changeObject(resp, "update");
      } else if (setObject) {
        setObject(resp);
      }
    },
  });
  dispatch({ type: SHOW_MODAL, payload: MODAL_BLOCK_ELEMENT });
};
