import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const TextAreaStyled = styled.textarea`
  width: 100%;
  height: ${rem("100px")};
  outline: none;
  border-radius: ${rem("21px")};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: ${({ customPadding }) => customPadding ? customPadding : rem("10px 20px")};
  background: ${({ theme }) => theme.colors.white};
  resize: none;
  font-family: "PT Root UI", sans-serif;
  font-size: 14px;
  font-weight: 500;

  :hover,
  :focus,
  :active {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }

  @media (max-width: 1199.98px) {
    font-size: 1.3rem;
  }

  @media (max-width: 767.98px) {
    font-size: 1.7rem;
  }
`;

export default TextAreaStyled;
