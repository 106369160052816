import styled from "styled-components/macro";

const BaseAppLayoutLeftSidebarMenuBadgeStyled = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 4px;
  border: 1px solid rgba(220, 220, 230, 0.2);
  background: ${({ theme }) => theme.colors.blue};

  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 5px;
`;

export default BaseAppLayoutLeftSidebarMenuBadgeStyled;
