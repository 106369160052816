import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const BadgeAdminMenuStyled = styled.div`
  padding-left: ${rem(`10px`)};
  padding-right: ${rem(`10px`)};
  border-radius: ${rem(`21px`)};
  border: 1px solid ${({ theme }) => theme.colors.gray};

`;

export default BadgeAdminMenuStyled;
