import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

// TODO: Include colors to theme?
const SubscribeFormInputStyled = styled.input`
  padding-right: ${rem(`45px`)};
  border-radius: 50vh;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(252, 252, 255, 0.1);
  color: ${({ theme }) => theme.colors.white};

  ::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 575.98px) {
    padding-right: 7.25rem;
  }

  @media (max-width: 767.98px) {
    padding-right: 4.8rem;
  }
`;

export default SubscribeFormInputStyled;
