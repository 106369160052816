import styled from "styled-components/macro";

const AccessDivStyled = styled.div`
  background-color: ${({theme, access}) => !access ? theme.colors.blue : access === 'request_sanded' ? theme.colors.gray : theme.colors.white};
  cursor: pointer;
  border-top: ${({theme, access}) => access === 'got_access' ? `1px solid ${theme.colors.gray}`: 'none' };
  margin-right: -20px;
  margin-left: -20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  @media (max-width: 991px) {
     margin-right: -15px;
      margin-left: -15px;
  }
    @media (max-width: 767px) {
    margin-right: -18px;
    margin-left: -18px;
  }
    @media (max-width: 575.98px) {
        margin-right: -10px;
    margin-left: -10px;
    }

`;
export default AccessDivStyled;
