import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  previewAuthorityFile,
  previewEvidenceFile,
} from "../../../containers/Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import { useHistory, useRouteMatch } from "react-router-dom";
import { route } from "../../../routes";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  Bold14Font,
  Medium14Font,
  Bold24Font,
} from "../../FontsNewComponent/Fonts";
import { TagElementBlue } from "../../TagElementStyled/TagElementStyled";
import NA from "../../NA";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";
import pencilIcon from "../../../assets/img3/svg/pancil_icon.svg";
import moment from "moment";
import Button from "../../buttons/Button/Button";
import eyeIcon from "../../../assets/img3/svg/eye-icon.svg";
import CRUDButton from "../../buttons/CRUDButton/CRUDButton";
import { checkIfUserIsOwnerOfTheCase } from "../../../containers/Auth/utils";

const EvidencePreviewDetail = ({
  caseId,
  caseObject,
  evidence,
  handleEvidenceDelete,
}) => {
  const NotAvailable = <NA />;
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let resp;
      resp = await previewEvidenceFile(evidence.id, caseId, dispatch);
      if (resp) {
        setSrc(resp);
      }
      setIsLoading(false);
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <div
        className="container-fluid"
        style={{ height: "100%", overflowY: "auto" }}
      >
        <div className="form-group">
          <Bold24Font>{evidence.label}</Bold24Font>
        </div>
        <div className="form-group">
          <Bold14Font>Evidence Type</Bold14Font>
          <div className="d-flex flex-wrap">
            <Medium14Font textColor={theme.colors.darkOpacity}>
              {evidence.type ? evidence.type : NotAvailable}
            </Medium14Font>
          </div>
        </div>
        <div className="form-group">
          <Bold14Font>Associated Witnesses</Bold14Font>
          <div className="d-flex flex-wrap">
            {evidence.witnesses && evidence.witnesses.length
              ? evidence.witnesses
                  .map((el) => (
                    <TagElementBlue key={el.id} theme={theme}>
                      <Bold14Font style={{ color: "inherit" }}>
                        {el.name}
                      </Bold14Font>
                    </TagElementBlue>
                  ))
              : NotAvailable}
          </div>
        </div>
        <div className="form-group">
          <Bold14Font>Elements</Bold14Font>
          <div className="d-flex flex-wrap">
            {(evidence.causes_of_action && evidence.causes_of_action.length) ||
            (evidence.causes_of_action_parent &&
              evidence.causes_of_action_parent.length) ||
            (evidence.causes_of_action_defensive &&
              evidence.causes_of_action_defensive.length) ? (
              <Fragment>
                <ShowCoa object={evidence} case_object={caseObject} />
              </Fragment>
            ) : (
              NotAvailable
            )}
          </div>
        </div>
        <div className="form-group">
          <Bold14Font>Issues</Bold14Font>
          <div className="d-flex flex-wrap">
            {(evidence.issues && evidence.issues.length) ||
            (evidence.issues_parent && evidence.issues_parent.length) ? (
              <Fragment>
                <ShowIssues object={evidence} case_object={caseObject} />
              </Fragment>
            ) : (
              NotAvailable
            )}
          </div>
        </div>
        <div className="form-group">
          <Bold14Font>Themes</Bold14Font>
          <div className="d-flex flex-wrap">
            {(evidence.themes && evidence.themes.length) ||
            (evidence.themes_parent && evidence.themes_parent.length) ? (
              <Fragment>
                <ShowThemes object={evidence} case_object={caseObject} />
              </Fragment>
            ) : (
              NotAvailable
            )}
          </div>
        </div>
        <div className="form-group">
          <Bold14Font>File Preview</Bold14Font>
          <div className="d-flex flex-wrap">
            {src ? (
              <img
                style={{ maxWidth: "290px", maxHeight: "250px" }}
                src={src}
                alt={"preview"}
              />
            ) : (
              NotAvailable
            )}
          </div>
        </div>
      </div>
      <div
        className="container-fluid pt-3 px-3"
        style={{ borderTop: `1px solid ${theme.colors.gray}` }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-between mb-4">
            <div className="d-flex" title="Client role">
              <Medium14Font>Date of last update</Medium14Font>
            </div>
            <div className="d-flex" title="last updated">
              <img src={pencilIcon} alt="" className="img-fluid mr-2" />
              <Medium14Font>
                {moment(evidence.updated_at).format("DD-MM-YYYY")}
              </Medium14Font>
            </div>
          </div>
          <div className="col-12 mb-4">
            <Button
              primary
              wide
              clickHandler={() => {
                history.push(
                  `${
                    is_admin ? "/admin/all-cases/" : "/app/cases/"
                  }${caseId}/trial-hub/evidence/${evidence.id}`
                );
              }}
            >
              <img
                src={eyeIcon}
                alt=""
                className="img-fluid mr-2 align-bottom"
              />
              <Bold14Font textColor={theme.colors.white}>
                View Evidence
              </Bold14Font>
            </Button>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-around">
            <CRUDButton
              className="mr-2 mb-2"
              editButton
              onClick={() => {
                history.push(
                  `${
                    is_admin ? "/admin/all-cases/" : "/app/cases/"
                  }${caseId}/trial-hub/evidence/edit/${evidence.id}`
                );
              }}
            />
            {!is_admin && checkIfUserIsOwnerOfTheCase(caseObject) ? (
              <CRUDButton
                deleteButton
                onClick={() => {
                  handleEvidenceDelete(evidence)
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EvidencePreviewDetail;
