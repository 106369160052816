import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CaseSearchInputStyled = styled.input`
  width: 100%;
  height: 42px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 10px 45px;
  background: ${({ theme }) => theme.colors.white};
  font-family: "PT Root UI", sans-serif;
  font-size: 14px;
  font-weight: 500;

  :hover,
  :focus,
  :active {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.darkOpacity};
    font-family: "PT Root UI", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  ${({ positionStart, positionMiddle, positionEnd }) => {
    if (positionStart) {
      return `
              border-top-left-radius: 50vh;
              border-bottom-left-radius: 50vh;
              border-right-width: 0.5px;
            `;
    }
    if (!positionStart && positionMiddle && positionEnd) {
      return `
              border-left-width: 0.5px;
              border-top-right-radius: 50vh;
              border-bottom-right-radius: 50vh;
            `;
    }
    if (positionMiddle) {
      return `
              border-radius: unset;
              border-right-width: 0.5px;
              border-left-width: 0.5px;
            `;
    }

    if (positionEnd) {
      return `
              border-top-right-radius: 50vh;
              border-bottom-right-radius: 50vh;
              border-left-width: 0.5px;
            `;
    }

    return `
          border-radius: 50vh;
        `;
  }};
`;

export default CaseSearchInputStyled;
