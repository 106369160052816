import styled from "styled-components/macro";

const ListAddButtonStyled = styled.div`
  color: ${({theme}) => theme.colors.blue};
  display: inherit;
  cursor: pointer;
  :hover {
    color:  ${({theme}) => theme.colors.blueHover};
    & .list-add-button {
    background-color:  ${({theme}) => theme.colors.blueHover};
    }
  }

`;

export default ListAddButtonStyled;
