// API request methods
export const GET = "API_METHOD/GET";
export const PUT = "API_METHOD/PUT";
export const POST = "API_METHOD/POST";
export const DELETE = "API_METHOD/DELETE";

// App actions:
export const SAVE_REQUESTED_URL = "APP/SAVE_REQUESTED_URL";
export const RESET_REQUESTED_URL = "APP/RESET_REQUESTED_URL";
export const HIDE_TECH_DATA = "APP/HIDE_TECH_DATA";
export const SET_IS_SUBMITTING_DATA_TRUE = "APP/SET_IS_SUBMITTING_DATA_TRUE";
export const SET_IS_SUBMITTING_DATA_FALSE = "APP/SET_IS_SUBMITTING_DATA_FALSE";
export const SET_CONTACT_US = "APP/SET_CONTACT_US";
export const SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS =
  "APP/SET_UPDATE_KEY_SECTIONS_FORM_SETTINGS ";

// Auth actions:
export const SIGN_UP = "AUTH/SIGN_UP";
export const UPDATE_USER = "AUTH/UPDATE_USER";
export const UPDATE_USER_PHOTO = "AUTH/UPDATE_USER_PHOTO";
export const SIGN_IN = "AUTH/SIGN_IN";
export const SIGN_OUT = "AUTH/SIGN_OUT";
export const RESET_SIGN_UP_FLOW = "AUTH/RESET_SIGN_UP_FLOW";
export const AUTO_LOG_IN = "AUTH/AUTO_LOG_IN";
export const SAVE_USER_EMAIL = "AUTH/SAVE_USER_EMAIL";
export const SET_AUTH_ACTION = "AUTH/SET_AUTH_ACTION";
export const RESET_AUTH_ACTION = "AUTH/RESET_AUTH_ACTION";
export const RESET_PASSWORD = "AUTH/RESET_PASSWORD";
export const SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET =
  "AUTH/SET_USER_AUTH_TRUE_FOR_PASSWORD_RESET";
export const SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET =
  "AUTH/SET_USER_AUTH_FALSE_AFTER_PASSWORD_RESET";

// Subscription actions:
export const SAVE_SUBSCRIPTION = "SUBSCRIPTION/SAVE_SUBSCRIPTION";
export const RESET_SUBSCRIPTION = "SUBSCRIPTION/RESET_SUBSCRIPTION";

// Left Side Bar actions:
export const SHOW_LEFT_SIDE_BAR = "LSB/SHOW_LEFT_SIDE_BAR";
export const HIDE_LEFT_SIDE_BAR = "LSB/HIDE_LEFT_SIDE_BAR";
export const TOGGLE_LEFT_SIDE_BAR = "LSB/TOGGLE_LEFT_SIDE_BAR";
export const TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR =
  "LSB/TOGGLE_CAN_CLOSE_LEFT_SIDE_BAR";
export const SET_LEFT_SIDE_BAR_ACTIVE_ITEM = "LSB/SET_ACTIVE_ITEM";

// Right Side Bar actions:
export const SHOW_RIGHT_SIDE_BAR = "RSB/SHOW_RIGHT_SIDE_BAR";
export const SHOW_RIGHT_SIDE_BAR_TWO = "RSB/SHOW_RIGHT_SIDE_BAR_TWO";
export const HIDE_RIGHT_SIDE_BAR = "RSB/HIDE_RIGHT_SIDE_BAR";
export const HIDE_RIGHT_SIDE_BAR_TWO = "RSB/HIDE_RIGHT_SIDE_BAR_TWO";
export const SET_EDITABLE_OBJECT = "RSB/SET_EDITABLE_OBJECT";
export const SET_AFTERCLOSE_ACTION = "RSB/SET_AFTERCLOSE_ACTION";
export const SET_CUSTOM_CLOSE_ACTION = "RSB/SET_CUSTOM_CLOSE_ACTION";
export const SET_RIGHT_SIDE_BAR_TITLE = "RSB/SET_RIGHT_SIDE_BAR_TITLE";
export const RESET_RIGHT_SIDE_BAR_TITLE = "RSB/RESET_RIGHT_SIDE_BAR_TITLE";

// Breadcrumbs actions:
export const UPDATE_BREADCRUMBS = "BREADCRUMBS/UPDATE_BREADCRUMBS";
export const RESET_BREADCRUMBS = "BREADCRUMBS/RESET_BREADCRUMBS";
export const SET_FIRST_PAGE = "BREADCRUMBS/SET_FIRST_PAGE";
export const SET_LAST_PAGE = "BREADCRUMBS/SET_LAST_PAGE";

// Modal actions:
export const SHOW_MODAL = "MODAL/SHOW_MODAL";
export const HIDE_MODAL = "MODAL/HIDE_MODAL";
export const MODAL_DELETE_ELEMENT = "MODAL/SHOW_MODAL_DELETE";
export const MODAL_BLOCK_ELEMENT = "MODAL/SHOW_MODAL_BLOCK";
export const MODAL_CONFIRM_ELEMENT = "MODAL/SHOW_MODAL_CONFIRM";
export const MODAL_DOC_TYPE = "MODAL/SHOW_MODAL_DOC_TYPE";
export const MODAL_ADD_CARD_ELEMENT = "MODAL/SHOW_MODAL_ADD_CARD";
export const MODAL_DELETE_KEY_SECTION = "MODAL/MODAL_DELETE_KEY_SECTION";
export const MODAL_CREATE_KEY_SECTION = "MODAL/MODAL_CREATE_KEY_SECTION";
export const SAVE_MODAL_DATA = "MODAL/SAVE_MODAL_DATA";
export const RESET_MODAL = "MODAL/RESET_MODAL";
export const MODAL_SUCCESS = "MODAL/MODAL_SUCCESS";
export const MODAL_ERROR = "MODAL/MODAL_ERROR";
export const MODAL_SUCCESS_EMAIL_VERIFICATION =
  "MODAL/TYPE_MODAL_SUCCESS_EMAIL_VERIFICATION";
export const MODAL_SUCCESS_PASSWORD_RESET =
  "MODAL/TYPE_MODAL_SUCCESS_PASSWORD_RESET";
export const MODAL_SUCCESS_PASSWORD_RESET_ADMIN =
  "MODAL/TYPE_MODAL_SUCCESS_PASSWORD_RESET_ADMIN";
export const MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION =
  "MODAL/TYPE_MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION";
export const MODAL_ERROR_SIGN_IN = "MODAL/TYPE_MODAL_ERROR_SIGN_IN";
export const MODAL_ALERT = "MODAL/TYPE_MODAL_ALERT";
export const MODAL_ADMIN_GRANT_FREE_LICENSE =
  "MODAL/MODAL_ADMIN_GRANT_FREE_LICENSE";

export const HIDE_COOKIE_MESSAGE = "COOKIE/HIDE_MESSAGE";

// Data actions:
// export const DATA_SAVE_LIST = "DATA/SAVE_DATA";
export const DATA_ADD_TO_STORE = "DATA/DATA_ADD_TO_STORE";
export const DATA_REMOVE_FROM_STORE = "DATA/DATA_REMOVE_FROM_STORE";
export const DATA_RESET_STORE = "DATA/DATA_RESET_STORE";

export const EXTRACT_DATA_SET = "EXTRACT/DATA_SET";
export const EXTRACT_DATA_INITIAL = "EXTRACT/INITIAL";
