import styled from "styled-components/macro";

const ArrowStyled = styled.div`
  border-radius: 8px;
  background-color: 	rgba(203, 207, 238, 0.1);
  width: 24px;
  height: 24px;
`;

export default ArrowStyled;
