import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import {
  criminalPartyRoles,
  partyRoles,
  partyTypes,
} from "../../../containers/BaseApp/Cases/partyTypesAndRoles";
import { Bold14Font, Bold18Font } from "../../FontsNewComponent/Fonts";
import Checkbox from "../../Checkbox";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  createNewApplicationPaper,
  updateApplicationPaperById,
} from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import NoRecords from "../../NoRecords";

/**
 * Add type field to each party
 * @param partiesList
 * @param case_type
 * @returns {*}
 */
export const addPartyTypeField = (partiesList, case_type) => {
  return partiesList.map((party) => {
    const partyCopy = { ...party };
    const roles = case_type === 'Criminal' ? criminalPartyRoles : partyRoles;
    partyCopy.type = roles.find((el) => el.value === party.role).partyType;
    return partyCopy;
  });
};

/**
 * Return object with parties grouped by type
 * @param partiesList
 * @param case_type
 * @returns {{}}
 */
export const groupPartiesByType = (partiesList, case_type) => {
  const partiesWithTypesList = addPartyTypeField(partiesList, case_type);
  const uniquePartiesTypes = Object.values(partyTypes);
  const groupedObject = {};
  uniquePartiesTypes.forEach((type) => {
    groupedObject[type] = partiesWithTypesList.filter(
      (party) => party.type === type
    );
  });
  return groupedObject;
};

const AllocateParties = ({
  applicationObject,
  returnData,
  caseObject,
  applicationPaperObject,
  setApplicationPaperObject,
  savedPartiesList,
  routeParams,
  theme,
}) => {
  // page states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { caseId, applicationId } = routeParams;
  const dispatch = useDispatch();

  // parties states
  const [selectedPartiesList, setSelectedPartiesList] = useState(
    savedPartiesList
  );
  const [groupedPartiesByTypeObject, setGroupedPartiesByTypeObject] = useState(
    {}
  );

  // set parties type for each party
  useEffect(() => {
    const groupedObject = groupPartiesByType([
      ...applicationObject.moving_parties,
      ...applicationObject.respondent_parties,
    ], caseObject.new_case_type);
    setGroupedPartiesByTypeObject({ ...groupedObject });
  }, []);

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [
        ...prevState,
        {
          ...item,
        },
      ]);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      setFunction([...tempArray]);
    }
  };

  const onClickSaveAllocatePartiesHandler = async () => {
    setIsSubmitting(true);
    const submitObject = {
      parties: selectedPartiesList,
    };
    let resp;
    if (returnData) {
      returnData(submitObject);
      setIsSubmitting(false);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    } else {
      if (!applicationPaperObject) {
        resp = await createNewApplicationPaper(
          applicationId,
          caseId,
          dispatch,
          submitObject
        );
      } else {
        resp = await updateApplicationPaperById(
          applicationPaperObject.id,
          applicationId,
          caseId,
          dispatch,
          submitObject
        );
      }
    }
    if (resp) {
      setApplicationPaperObject(resp);
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    }
    setIsSubmitting(false);
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="container-fluid">
          {applicationObject.moving_parties.length ||
          applicationObject.respondent_parties.length ? (
            Object.keys(groupedPartiesByTypeObject).map((type, index) => (
              <Fragment key={index}>
                {groupedPartiesByTypeObject[type].length ? (
                  <Fragment>
                    <Bold14Font as="div" style={{ marginBottom: "8px" }}>
                      {type}
                    </Bold14Font>
                    <div style={{ marginBottom: "30px" }}>
                      {groupedPartiesByTypeObject[type].map((party) => (
                        <Checkbox
                          checked={selectedPartiesList.find(
                            (el) => el.id === party.id
                          )}
                          label={party.name}
                          onChange={() =>
                            onChangeCheckBoxHandler(
                              party,
                              selectedPartiesList,
                              setSelectedPartiesList
                            )
                          }
                          labelColor={theme.colors.dark}
                        />
                      ))}
                    </div>
                  </Fragment>
                ) : null}
              </Fragment>
            ))
          ) : (
            <NoRecords>
              <Bold18Font textColor={"#0f122f"}>
                Your selected parties from application basic info will appear
                here
              </Bold18Font>
            </NoRecords>
          )}
        </div>
      </div>

      <DividerHorizontal />
      <div className="form-group mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ButtonNew
                loading={isSubmitting}
                disabled={isSubmitting}
                wide
                primary
                onClick={onClickSaveAllocatePartiesHandler}
              >
                Save
              </ButtonNew>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AllocateParties.propTypes = {
  applicationObject: PropTypes.object.isRequired,
  applicationPaperObject: PropTypes.object.isRequired,
  setApplicationPaperObject: PropTypes.func.isRequired,
  savedPartiesList: PropTypes.array.isRequired,
  routeParams: PropTypes.shape({
    caseId: PropTypes.string.isRequired,
    applicationId: PropTypes.string.isRequired,
  }),
};

export default withTheme(AllocateParties);
