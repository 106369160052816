import React, { useState, useEffect, useContext } from "react";
import { NotebookContext } from "@/context";
import { withTheme } from "styled-components/macro";
import { Label } from "./styled";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import Tag from "./CaseLibraryTag";

import expandIcon from "@/assets/img3/expand.svg";
import closeRectangle from "@/assets/img/notebook/close-rectangle.svg";
import Button from "@/components/buttons/Button/Button";
import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "@/components/FontsNewComponent/Fonts";
import plusIcon from "@/assets/img/notebook/chapters-add.svg";
import InputStyled from "@/components/InputNew/styled/InputStyled";
import TextAreaStyled from "@/components/TextArea/styled/TextAreaStyled";

import CasesMenuStyled from "@/components/baseAppComponents/CasesMenu/styled/CasesMenuStyled";

export const PlusIcon = styled.img.attrs({
  src: plusIcon,
})`
  width: 22px;
`;

export const CloseIcon = styled.img`
  width: 24px;
  position: absolute;
  right: 0px;
  top: -3px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const Row = styled.div`
  display: flex;
  ${(props) => props.alignCenter && `align-items: center;`}
  ${(props) => props.justifyBetween && `justify-content: space-between;`}
  ${(props) =>
    props.groupHover &&
    `:hover {
      opacity: 0.8;
      }
    `}
`;

export const AddChapterLabel = styled.span`
  margin-left: 5px;
  color: #374ac2;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
  //
`;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const Section = ({
  opened = false,
  label: sectionLabel = "Label",
  items = [],
  type,

  elements,
  themes,
  issues,

  theme,

  endpointLabel,
  setCaseLibrary,

  handleCauseOfAction,
  handleParties,
  handleTheme,
  handleIssues,
  handleRelief,
  setSectionsOpened,
  notebookType,
  applicationId,
  search,
}) => {
  const [adding, setAdding] = useState(false);
  const [activeTab, setActiveTab] = useState("elements");
  const [newObjName, setNewObjName] = useState("");
  const { id: caseId, notebook_id: notebookId } = useParams();

  const { caseObject } = useContext(NotebookContext);

  // return (
  //   <Row
  //     alignCenter
  //     groupHover
  //     style={{ cursor: "pointer" }}
  //     onClick={() => setAdding(!adding)}
  //   >
  //     <PlusIcon />
  //     <AddChapterLabel>Add chapter</AddChapterLabel>
  //   </Row>
  // );

  const tabsPanelItems = [
    {
      id: 1,
      text:
        notebookType && notebookType === "Application"
          ? "Proofs"
          : caseObject.new_case_type === "Criminal"
          ? "Offences"
          : "Elements",
      activeType: "elements",
    },
    {
      id: 2,
      text: `Themes`,
      activeType: "themes",
    },
    {
      id: 3,
      text: `Issues`,
      activeType: "issues",
    },
  ];

  if (type === "relevance") {
    return (
      <div
        className="d-flex flex-column"
        style={{
          borderBottom: "1px solid #DCDCE6",
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 15,
        }}
      >
        <div
          className="d-flex flex-items-center"
          onClick={() =>
            setSectionsOpened((sections) => ({
              ...sections,
              [type]: !sections[type],
            }))
          }
          style={{ marginBottom: 15, cursor: "pointer" }}
        >
          <img
            src={expandIcon}
            alt="expand"
            style={{
              transform: opened ? "rotate(180deg)" : "rotate(0deg)",
              cursor: "pointer",
            }}
          />
          <Label>{sectionLabel}</Label>
        </div>
        {opened && (
          <>
            <CasesMenuStyled
              className={`d-flex flex-wrap justify-content-between`}
              style={{ paddingBottom: 15 }}
            >
              {/*<div className="d-flex mb-3">*/}
              {/*<div className="d-flex flex-wrap justify-content-end justify-content-sm-center mb-3">*/}
              <div
                className="d-flex flex-wrap justify-content-end justify-content-sm-center"
                style={{
                  borderBottom: "1px solid #DCDCE6",
                  paddingBottom: "4px",
                }}
              >
                {tabsPanelItems.map((el, i) => (
                  <div
                    onClick={() => {
                      setActiveTab(el.activeType);
                    }}
                    style={
                      el.activeType === activeTab
                        ? {
                            borderBottom: "2px solid #374AC2",
                            marginBottom: -5,
                          }
                        : {}
                    }
                    className={i > 0 ? "d-flex ml-3" : "d-flex"}
                    key={i}
                  >
                    <div
                      className={`menu-item mr-2 ${
                        el.activeType === activeTab ? "menu-item__active" : ""
                      }`}
                    >
                      {el.activeType === activeTab ? (
                        <Bold14Font
                          style={{
                            color: theme.colors.blue,
                          }}
                        >
                          {el.text}
                        </Bold14Font>
                      ) : (
                        <Medium14Font
                          style={{
                            color: theme.colors.darkOpacity,
                          }}
                        >
                          {el.text}
                        </Medium14Font>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </CasesMenuStyled>
            {activeTab === "issues" && (
              <>
                <div
                  style={{
                    maxHeight: "366px",
                    overflow: "auto",
                  }}
                >
                  {issues.length > 0 ? (
                    issues.map((item) => {
                      // const tag = { title: item, type: sectionLabel };

                      // console.log(item);
                      return <Tag tag={item} key={`issue-${item.id}`} />;
                    })
                  ) : (
                    <div style={{ paddingBottom: 15 }}>none</div>
                  )}
                </div>

                {search === "" && (
                  <Row
                    alignCenter
                    groupHover
                    style={{
                      cursor: "pointer",
                      marginBottom: 15,
                      marginTop: 8,
                    }}
                    onClick={() => {
                      handleIssues({});
                    }}
                  >
                    <PlusIcon />
                    <AddChapterLabel>Add Issue</AddChapterLabel>
                  </Row>
                )}
              </>
            )}
            {activeTab === "themes" && (
              <>
                <div
                  style={{
                    maxHeight: "366px",
                    overflow: "auto",
                  }}
                >
                  {themes.length > 0 ? (
                    themes.map((item) => {
                      // const tag = { title: item, type: sectionLabel };

                      // console.log(item);
                      return <Tag tag={item} key={`theme-${item.id}`} />;
                    })
                  ) : (
                    <div style={{ paddingBottom: 15 }}>none</div>
                  )}
                </div>
                {search === "" && (
                  <Row
                    alignCenter
                    groupHover
                    style={{
                      cursor: "pointer",
                      marginBottom: 15,
                      marginTop: 8,
                    }}
                    onClick={() => {
                      handleTheme({});
                    }}
                  >
                    <PlusIcon />
                    <AddChapterLabel>Add Theme</AddChapterLabel>
                  </Row>
                )}
              </>
            )}
            {activeTab === "elements" && (
              <>
                <div
                  style={{
                    maxHeight: "366px",
                    overflow: "auto",
                  }}
                >
                  {elements.length > 0 ? (
                    elements.map((item) => {
                      // const tag = { title: item, type: sectionLabel };

                      // console.log(item);
                      return <Tag tag={item} key={`element-${item.id}`} />;
                    })
                  ) : (
                    <div style={{ paddingBottom: 15 }}>none</div>
                  )}
                </div>
                {search === "" && (
                  <Row
                    alignCenter
                    groupHover
                    style={{
                      cursor: "pointer",
                      marginBottom: 15,
                      marginTop: 8,
                    }}
                    onClick={() => {
                      if (notebookType === "Application") {
                        handleRelief({ counterclaim: false }, false);
                      } else {
                        handleCauseOfAction({ counterclaim: false }, false);
                      }
                    }}
                  >
                    <PlusIcon />
                    <AddChapterLabel>
                      Add{" "}
                      {notebookType === "Application"
                        ? "Relief/Proof"
                        : caseObject.new_case_type === "Criminal"
                        ? "Offences"
                        : "Cause of Action"}
                    </AddChapterLabel>
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
    /*
     * RELEVENCE:
     * * elements
     * * themes
     * * issues
     */
    // active={activeTab}
    // activeFont={Bold18Font}
    // font={Medium18Font}
  }

  return (
    <div
      className="d-flex flex-column"
      style={{
        borderBottom: "1px solid #DCDCE6",
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
      }}
    >
      <div
        className="d-flex flex-items-center"
        onClick={() =>
          setSectionsOpened((sections) => ({
            ...sections,
            [type]: !sections[type],
          }))
        }
        style={{ marginBottom: 15, cursor: "pointer" }}
      >
        <img
          src={expandIcon}
          alt="expand"
          style={{
            transform: opened ? "rotate(180deg)" : "rotate(0deg)",
            cursor: "pointer",
          }}
        />
        <Label>{sectionLabel}</Label>
      </div>
      {opened && (
        <>
          <div
            style={{
              maxHeight: "366px",
              overflow: "auto",
            }}
          >
            {items.length > 0 ? (
              items.map((item) => {
                // const tag = { title: item, type: sectionLabel };

                // console.log(item);
                return <Tag tag={item} key={`${item.id}-${item.tagId}`} />;
              })
            ) : (
              <div style={{ paddingBottom: 15 }}>none</div>
            )}
          </div>
          {search === "" && type !== "key-sections" && type !== "party" && (
            <>
              {adding ? (
                <AddingMenu
                  type={type}
                  newObjName={newObjName}
                  setNewObjName={setNewObjName}
                  opened={opened}
                  setAdding={setAdding}
                  caseId={caseId}
                  endpointLabel={endpointLabel}
                  applicationId={applicationId}
                  setCaseLibrary={setCaseLibrary}
                />
              ) : (
                <Row
                  alignCenter
                  groupHover
                  style={{ cursor: "pointer", marginBottom: 15, marginTop: 8 }}
                  onClick={() => setAdding(!adding)}
                >
                  <PlusIcon />
                  <AddChapterLabel>Add {sectionLabel}</AddChapterLabel>
                </Row>
              )}
            </>
          )}
          {search === "" && type === "party" && (
            <>
              <Row
                alignCenter
                groupHover
                style={{ cursor: "pointer", marginBottom: 15, marginTop: 8 }}
                onClick={() => handleParties({})}
              >
                <PlusIcon />
                <AddChapterLabel>Add {sectionLabel}</AddChapterLabel>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};

const AddingMenu = ({
  type,
  newObjName,
  setNewObjName,
  opened,
  setAdding,
  caseId,
  endpointLabel,
  applicationId,
  setCaseLibrary,
}) => {
  const onSubmit = async (text) => {
    if (text !== "") {
      const config = {
        headers: {
          Authorization: `Bearer ${await loadAccessToken()}`,
        },
      };
      let res;

      if (type === "application-papers") {
        res = await axios.post(
          `/api/cases/${caseId}/notes/objects?type=${endpointLabel}&id_case=${caseId}&id_application=${applicationId}`,
          {
            text,
          },
          config
        );
      } else {
        res = await axios.post(
          `/api/cases/${caseId}/notes/objects?type=${endpointLabel}&id_case=${caseId}`,
          {
            text,
          },
          config
        );
      }

      if (res && res.data && res.data.data) {
        setNewObjName("");
        setAdding(false);

        setCaseLibrary((caseLibrary) => ({
          ...caseLibrary,
          [endpointLabel]: [...caseLibrary[endpointLabel], res.data.data],
        }));
      }
    }
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        setNewObjName("");
        setAdding(false);
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  useEffect(() => {
    const onEnter = (e) => {
      if (e.key === "Enter") {
        if (type !== "Note/comment") {
          onSubmit(newObjName);
        }
      }
    };

    window.addEventListener("keydown", onEnter);
    return () => window.removeEventListener("keydown", onEnter);
  }, [newObjName]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ position: "relative" }}>
        <Bold14Font className="d-block mb-2">Name</Bold14Font>
        {type === "Note/comment" ? (
          <TextAreaStyled
            value={newObjName}
            type="text"
            placeholder="Enter name"
            onChange={(e) => {
              setNewObjName(e.target.value);
            }}
            maxLength={125}
            style={{
              background: "white",
              borderRadius: "22px",
              // marginLeft: "-15px",
              // marginRight: "-15px",
              marginBottom: "15px",
            }}
            autoFocus
          />
        ) : (
          <InputStyled
            value={newObjName}
            type="text"
            placeholder="Enter name"
            onChange={(e) => setNewObjName(e.target.value)}
            maxLength={125}
            style={{
              background: "white",
              borderRadius: "22px",
              // marginLeft: "-15px",
              // marginRight: "-15px",
              marginBottom: "15px",
            }}
            autoFocus
          />
        )}

        <CloseIcon
          src={closeRectangle}
          alt="close"
          style={{
            transform: opened ? "rotate(180deg)" : "rotate(0deg)",
            cursor: "pointer",
          }}
          onClick={() => {
            setNewObjName("");
            setAdding(false);
          }}
        />
      </div>
      <Button
        type="submit"
        wide
        primary
        style={{ marginBottom: 15 }}
        disabled={newObjName === ""}
        clickHandler={() => onSubmit(newObjName)}
      >
        Save
      </Button>
    </div>
  );
};

export default withTheme(Section);
