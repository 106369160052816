import styled from "styled-components/macro";

const FormStyled = styled.div`
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  border: ${({ theme, hideBorders }) => hideBorders ? 'none': `1px solid ${theme.colors.gray}`};
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 25px;
  //overflow-y: scroll;
  overflow-y: auto;
  ${({ title }) => (!title ? "padding-top: 25px" : null)};
`;

export default FormStyled;
