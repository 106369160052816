import { SHOW_MODAL, HIDE_MODAL, SAVE_MODAL_DATA, RESET_MODAL } from "../types";

const initialState = {
  isModalShow: false,
  cardModalShow: false,
  type: null,
  data: null, // description
  afterCloseHandler: null,
  beforeCloseHandler: null,
  extraData: null, // some data that modal need to use in (for example - user object to operate with)
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isModalShow: true,
        type: action.payload,
      };

    case HIDE_MODAL:
      return { ...state, isModalShow: false };

    case SAVE_MODAL_DATA:
      return {
        ...state,
        header: action.header,
        data: action.payload,
        afterCloseHandler: action.afterCloseHandler,
        beforeCloseHandler: action.beforeCloseHandler,
        extraData: action.extraData,
      };

    case RESET_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default modalReducer;
