import React from "react";
import { ThemeProvider } from "styled-components/macro";

export const theme = {
  colors: {
    dark: "#0f122f",
    darkOpacity: "rgba(15, 18, 47, 0.6)",
    blue: "#374ac2",
    blue_second: "#475df9",
    gray: "#dcdce6",
    grayOpacity: "rgba(220, 220,230, 0.1)",
    white: "#ffffff",
    whiteOpacity: "rgba(255, 255, 255, 0.6)",
    lightGray: "#f5f5fa",
    light: "#fcfcff",
    lightBorder: "#cbcfee",
    blueHover: "#556ae6",

    orange_second: "#ffab0f",
    orangeFill_second: "#feffef",
    orangeBorder_second: "#ffe9d1",

    turquoise_second: "#2de1e0",
    turquoiseFill_second: "#f7fdfd",
    turquoiseBorder_second: "#d6f0f0",

    case_type_border: "rgba(220, 220, 230, 0.48)",

    purple_second: "#5831a8",
    purpleFill_second: "#f5f6fc",
    purpleBorder_second: "#cfd3f0",

    cyan_second: "#0db7ff",
    cyanFill_second: "#f3fafd",
    cyanBorder_second: "#cfecf9",


    orange: "#ff9017",
    orangeFill: "#fff9f3",
    orangeBorder: "#ffe9d1",

    turquoise: "#259b9a",
    turquoiseFill: "#f4fafa",
    turquoiseBorder: "#cee8e8",

    purple: "#7f48f4",
    purpleFill: "#f5f6fc",
    purpleBorder: "#cfd3f0",

    cyan: "#0d9edf",
    cyanFill: "#f3fafd",
    cyanBorder: "#cfecf9",


    burgundy: "#c23752",
    burgundyHover: "#d74461",


    approve: "#259B9A",
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
