import React from "react";
import styled from "styled-components";
import Select from "react-select";
import rem from "../../../utils/rem";

const SelectStyledNew = styled(Select).attrs(({ classNamePrefix }) => ({
  classNamePrefix: classNamePrefix,
}))`
  & .Select__control {
    height: ${rem(`42px`)};
    border-radius: 50vh;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme, backgroundColor }) => backgroundColor ? backgroundColor : theme.colors.light};

    ${({ backgroundColor }) => backgroundColor ? `
    & .Select__indicators {
      background-color: #fcfcff;
      border-top-right-radius: 50vh;
      border-bottom-right-radius: 50vh;
    }` : null}
    &:hover {
      border-color: ${({ theme }) => theme.colors.blue};

      & .Select__indicators .Select__indicator-separator {
        background: ${({ theme }) => theme.colors.blue};
      }
    }

    & .Select__placeholder {
      font-size: 14px;
      @media (max-width: 767.98px) {
        //top: 65%;
        font-size: 1rem;
      }
    }

    & .Select__value-container {
      padding-left: 21px;
      padding-bottom: 0;
      padding-top: 0;

    }

    & .Select__indicators {
      & .Select__indicator-separator {
        margin-top: 0;
        margin-bottom: 0;
      }

      & .Select__indicator .Select__dropdown-indicator {
        background-color: ${({ theme }) => theme.colors.lightGray};
      }
    }
  }

  & .Select__single-value {
    //top: 65%;
    font-size: 14px;
    @media (max-width: 767.98px) {
      font-size: 1rem;
    }
  }

  & .Select__menu {
    border-radius: 21px;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.light};
    font-size: 14px;
    & .Select__menu-list {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 21px;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: ${({ theme }) => theme.colors.gray};
      }

      & .Select__option {
        &:not(:first-child) {
          border-top: 1px solid ${({ theme }) => theme.colors.gray};
        }
        &:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
        }

        @media (max-width: 575.98px) {
          font-size: 1rem;
        }

        &:hover {
          background-color: #f0f0fa;
        }
      }
    }
  }
`;

export default ({
                  options,
                  classNamePrefix,
                  name,
                  onChangeHandler,
                  ...props
                }) => (
  <SelectStyledNew
    options={options}
    classNamePrefix={classNamePrefix}
    name={name}
    onChange={onChangeHandler}
    {...props}
  />
);
