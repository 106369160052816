import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext } from "@/context";
import { NodeViewWrapper } from "@tiptap/react";

import ContextMenu from "./Menu";
import DragHandle from "@editor/components/DragHandle";

export default (props) => {
  const menuRef = useRef(null);
  const editor = props.editor;

  const { setContent } = useContext(EditorContext);
  const [showMenu, setShowMenu] = useState(false);

  const tagType = props.node.attrs.tagType
    ? `tag-${props.node.attrs.tagType}`
    : props.extension.options.HTMLAttributes.class.split(" ")[1];

  // div style={{ display: "inline-block", position: "relative" }}
  return (
    <>
      <NodeViewWrapper
        className={`tag ${tagType}`}
        as="span"
        draggable="true"
        data-drag-handle=""
        ref={menuRef}
        data-type="annotation"
        style={{
          position: "relative",
          opacity: 0.5,
          // paddingRight: 136
        }}
        onClick={(e) => {
          if (e.target.className === "tag-action") return;
          if (showMenu) {
            setShowMenu(false);
          } else {
            setShowMenu(true);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DragHandle
            className={`fill--${tagType.substring(4)}`}
            // fill="blue"
            style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
          />
          <span>{props.node.attrs.id}</span>
        </div>
      </NodeViewWrapper>
      {showMenu && (
        <ContextMenu
          tag={props.node.attrs}
          setShowMenu={setShowMenu}
          propsNode={props.node}
        />
      )}
    </>
  );
};
