import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";

import { handleFlatRelevanceElements, setTagField } from "@/utils/function";

import { Medium14Font } from "@/components/FontsNewComponent/Fonts";
import axios from "axios";
import CloseIcon from "@/components/svgIcons/CloseIcon";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
import TextArea from "@/components/TextArea/TextArea";
import { loadAccessToken } from "@/containers/Auth/auth";
import ButtonAnotherTry from "@/components/buttons/Button/ButtonAnotherTry";
import EditObjectPanel from "@/components/Notebook/EditObjectPanel";

import { Container, ButtonContainer, Column } from "./styled";
import { SHOW_RIGHT_SIDE_BAR_TWO, SHOW_RIGHT_SIDE_BAR } from "@/redux/types";
import { HIDE_RIGHT_SIDE_BAR, SET_EDITABLE_OBJECT } from "@/redux/types";
import { Select } from "antd";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";

import AssociateElementsThemesIssuesForm from "@/components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";

import plusIcon from "@/assets/img3/svg/button-plus-icon.svg";

import AssociationTable from "@/components/AssociationTable";
const { Option } = Select;
const children = [];

const allClass = [
  {
    label: "Factual",
    value: "Factual",
  },
  {
    label: "Expert",
    value: "Expert",
  },
];

for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const EditWitness = (props) => {
  const prevWitObj = useSelector((state) => state.rightSideBar.editableObject);
  const [witnessObject, setWitnessObject] = useState({ ...prevWitObj } || {});

  useEffect(() => {
    if (prevWitObj) {
      const newObj = { ...witnessObject, ...prevWitObj };
      setWitnessObject((oldWit) => ({ ...oldWit, ...prevWitObj }));
    }
  }, [prevWitObj]);

  useEffect(() => {
    setFlagRelevanceSettings((o) => ({
      ...o,
      objects: handleFlatRelevanceElements(
        witnessObject.causes_of_action_parent ?? [],
        witnessObject.causes_of_action ?? [],
        witnessObject.causes_of_action_defensive ?? [],
        witnessObject.issues_parent ?? [],
        witnessObject.issues ?? [],
        witnessObject.themes_parent ?? [],
        witnessObject.themes ?? [],
        witnessObject.case

        // prevWitObj.causes_of_action_parent ?? [],
        // prevWitObj.causes_of_action ?? [],
        // prevWitObj.causes_of_action_defensive ?? [],
        // prevWitObj.issues_parent ?? [],
        // prevWitObj.issues ?? [],
        // prevWitObj.themes_parent ?? [],
        // prevWitObj.themes ?? [],
        // prevWitObj.case
      ),

      actions: [
        // if addButton needed, it  must always be first in array!
        {
          type: "addButton",
          buttonText: "Associate Elements, Themes, Issues",
          callback: () => handleAssociateElementsThemesIssues(witnessObject),
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => {
            handleAssociateElementsThemesIssues(witnessObject, index);
          },
        },
        {
          type: "delete",
          clickHandler: (index) => {
            switch (index) {
              case 0:
                updateWitnessObject({
                  causes_of_action: [],
                  causes_of_action_parent: [],
                  causes_of_action_defensive: [],
                });
                break;

              case 1:
                updateWitnessObject({
                  themes: [],
                  themes_parent: [],
                });
                break;

              case 2:
                updateWitnessObject({
                  issues: [],
                  issues_parent: [],
                });
                break;

              default:
                break;
            }
          },
        },
      ],
    }));
  }, [witnessObject, caseObject]);

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  const { caseObject, setCaseObject } = useContext(NotebookContext);

  // const caseObject = useSelector((state) => state.data.store.case);
  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];
  const [parties, setParties] = useState(props.id);

  // This object fiels

  // Witness name – input field
  const [label, setLabel] = useState(props.id);

  // Party – dropdown of all case parties
  const [party, setParty] = useState(undefined);

  // Class – dropdown
  const [classs, setClasss] = useState(undefined);

  // Relevance – reuse old component and Flag Relevance panel
  // TODO

  // Associated evidence – multiselect
  const [evidence, setEvidence] = useState([]);

  // Objectives – multiselect
  const [objectives, setObjectives] = useState([]);

  // Topics – multiselect
  const [topics, setTopics] = useState([]);

  // Exam lines – multiselect
  const [examLines, setExamLines] = useState([]);

  // Reason for testimony – text area
  const [reason, setReason] = useState(undefined);

  // Context & background – text area
  const [context, setContext] = useState(undefined);

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  useEffect(() => {
    if (!witnessObject || Object.keys(witnessObject).length === 0) {
      fetchObject();
    } else {
      const object = witnessObject;

      setWitnessObject((wit) => ({ ...wit, ...object }));

      if (object.topics_new) {
        setTopics(object.topics_new);
      } else {
        setTopics(
          object.topics.map((i) => ({ label: i.name, value: i.fake_id }))
        );
      }

      if (object.evidence_new) {
        setEvidence(object.evidence_new);
      } else {
        setEvidence(
          object.evidences.map((i) => ({ label: i.label, value: i.fake_id }))
        );
      }
    }
  }, []);

  const fetchObject = async () => {
    setDataLoading(true);
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(`/api/cases/${caseId}/witness/${props.tagId}`, config)
      .then((res) => {
        setDataLoading(false);
        if (res && res.data && res.data.data) {
          const object = res.data.data;

          const oldObj = {
            causes_of_action_parent:
              witnessObject.causes_of_action_parent || [],
            causes_of_action: witnessObject.causes_of_action || [],
            causes_of_action_defensive:
              witnessObject.causes_of_action_defensive || [],
            issues_parent: witnessObject.issues_parent || [],
            issues: witnessObject.issues || [],
            themes_parent: witnessObject.themes_parent || [],
            themes: witnessObject.themes || [],
          };

          // setWitnessObject((wit) => ({ ...oldObj, ...object }));
          setWitnessObject((wit) => ({ ...object, ...wit }));

          // caseObject.parties
          const neededParty = caseObject.parties.find(
            (i) => i.id == object.party.id
          );
          setParty({
            label: neededParty.name,
            value: neededParty.id,
          });
          setClasss(
            [
              {
                label: "Factual",
                value: "Factual",
              },
              {
                label: "Expert",
                value: "Expert",
              },
            ].find((i) => i.value === object.class)
          );
          setReason(object.reason_to_testimony);
          setContext(object.background);

          setEvidence(
            object.evidences.map((i) => ({ label: i.label, value: i.fake_id }))
          );
          setTopics(
            object.topics.map((i) => ({ label: i.name, value: i.fake_id }))
          );
        }
      });
  };
  // caseLibrary.Objective.map((i) => ({
  //   value: i.id,
  //   label: i.label,
  // }));

  const createObject = async (text, type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    let res;

    return axios.post(
      `/api/cases/${caseId}/notes/objects?type=${type}&id_case=${caseId}`,
      {
        text,
      },
      config
    );

    //    if (res && res.data && res.data.data) {
    //      //
    //    }
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  // useEffect(() => {
  //   const onEnter = (e) => {
  //     if (e.key === "Enter") {
  //       saveResult(label);
  //     }
  //   };

  //   window.addEventListener("keydown", onEnter);
  //   return () => window.removeEventListener("keydown", onEnter);
  // }, [label]);

  const saveResult = async (name) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    // 1. all evidences which are selected but not present in case library
    // -> create evidence for that

    let allEvidence = (evidence || witnessObject.evidence_new).filter(
      (i) => !!i.label
    );
    let allTopics = (topics || witnessObject.topics_new).filter(
      (i) => !!i.label
    );
    // let allObjectives = objectives.filter((i) => !!i.label);
    // let allExamLines = examLines.filter((i) => !!i.label);

    for (let ev of evidence || witnessObject.evidence_new) {
      if (!ev.label && !caseLibrary.Evidence.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Evidence").then((res) => {
          allEvidence.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Evidence: [...caseLibrary.Evidence, res.data.data],
          }));
        });
      }
    }

    // for (let ev of objectives) {
    //   if (!ev.label && !caseLibrary.Evidence.some((i) => i.id == ev.value)) {
    //     await createObject(ev.value, "Objective").then((res) => {
    //       allObjectives.push({
    //         label: res.data.data.label,
    //         value: res.data.data.id,
    //       });
    //       setCaseLibrary((caseLibrary) => ({
    //         ...caseLibrary,
    //         Objective: [...caseLibrary.Objective, res.data.data],
    //       }));
    //     });
    //   }
    // }

    // for (let ev of examLines) {
    //   if (!ev.label && !caseLibrary.Evidence.some((i) => i.id == ev.value)) {
    //     await createObject(ev.value, "ExamLine").then((res) => {
    //       allExamLines.push({
    //         label: res.data.data.label,
    //         value: res.data.data.id,
    //       });
    //       setCaseLibrary((caseLibrary) => ({
    //         ...caseLibrary,
    //         ExamLine: [...caseLibrary.ExamLine, res.data.data],
    //       }));
    //     });
    //   }
    // }

    for (let ev of topics || witnessObject.topics_new) {
      if (!ev.label && !caseLibrary.Topic.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Topic").then((res) => {
          allTopics.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Topic: [...caseLibrary.Topic, res.data.data],
          }));
        });
      }
    }

    // setParty(object.party)
    // setClasss(object.class)
    // setReason(object.reason_to_testimony)
    // setContext(object.background)

    let body = {
      ...witnessObject,
      // name: name,
      // evidences: [],
      // evidences: allEvidence.map((i) => ({
      //   id: i.value,
      //   type: i.type || "Secondary",
      //   // label: i.label,
      // })),
      // topics: allTopics.map((i) => ({
      //   id: i.value,
      //   name: i.label,
      // })),

      // objectives: allObjectives.map((i) => i.value),
      // exam_lines: allExamLines.map((i) => i.value),

      evidences: allEvidence.map((i) => i.value),
      topics: allTopics.map((i) => i.value),
      // TODO

      // party: {
      //   id: party.value,
      //   name: party.label,
      // },
      id_party: witnessObject.party && witnessObject.party.id,
      // class: classs.value,
      // reason_to_testimony: reason,
      // background: context,
    };
    if (!witnessObject.party) {
      delete body.id_party;
    }

    let endpoint = `/api/cases/${caseId}/witness/${props.tagId}/new`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: witnessObject.name },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.Witness.findIndex((i) => i.id == props.tagId);
    const newWitness = [
      ...caseLibrary.Witness.slice(0, itemIndex),
      {
        ...caseLibrary.Witness[itemIndex],
        label: witnessObject.name,
      },
      ...caseLibrary.Witness.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      Witness: newWitness,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const updateWitnessObject = (data) => {
    const obj = { ...witnessObject, ...data };
    setWitnessObject(obj);
    dispatch({
      type: SET_EDITABLE_OBJECT,
      editableObject: {
        ...obj,
        // evidence_res: evidence,
        // topics_res: topics,
      },
    });
  };

  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      // type: SHOW_RIGHT_SIDE_BAR_TWO,
      url: history.location.pathname,
      rightBarWidth: "18%",
      editableObject: {
        ...witnessObject,
        // evidence_res: evidence,
        // topics_res: topics,
      },
      content: (
        <AssociateElementsThemesIssuesForm
          key={witnessObject.id}
          caseId={caseId}
          noAdd
          updateCaseObject={(d) => {
            setCaseObject((obj) => ({ ...obj, d }));
            setWitnessObject((obj) => ({ ...obj, case: { ...obj.case, d } }));
          }}
          evidenceObject={witnessObject}
          setEvidenceObject={(data) => {
            updateWitnessObject(data);
          }}
          selectedMenuTab={index}
          addToSwapContent={[
            <EditObjectPanel {...props} witnessObject={witnessObject} />,
          ]}
          rightBarWidth={"18%"}
          // otherSidebar
        />
      ),
      swapContent: {
        content: <EditObjectPanel witnessObject={witnessObject} {...props} />,
      },
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const setWitnessFlagRelevanceElements = (causes_of_action) => {
    updateWitnessObject({ causes_of_action });
  };
  const setWitnessFlagRelevanceDefenceElements = (
    causes_of_action_defensive
  ) => {
    updateWitnessObject({ causes_of_action_defensive });
  };
  const setWitnessFlagRelevanceParentElements = (causes_of_action_parent) => {
    updateWitnessObject({ causes_of_action_parent });
  };
  const setWitnessFlagRelevanceThemes = (themes) => {
    updateWitnessObject({ themes });
  };
  const setWitnessFlagRelevanceParentThemes = (themes_parent) => {
    updateWitnessObject({ themes_parent });
  };
  const setWitnessFlagRelevanceIssues = (issues) => {
    updateWitnessObject({ issues });
  };
  const setWitnessFlagRelevanceParentIssues = (issues_parent) => {
    updateWitnessObject({ issues_parent });
  };
  const flagRelevanceSettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        callback: () => handleAssociateElementsThemesIssues(witnessObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(witnessObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          switch (index) {
            case 0:
              updateWitnessObject({
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              });
              break;

            case 1:
              updateWitnessObject({
                themes: [],
                themes_parent: [],
              });
              break;

            case 2:
              updateWitnessObject({
                issues: [],
                issues_parent: [],
              });
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      witnessObject.causes_of_action_parent ?? [],
      witnessObject.causes_of_action ?? [],
      witnessObject.causes_of_action_defensive ?? [],
      witnessObject.issues_parent ?? [],
      witnessObject.issues ?? [],
      witnessObject.themes_parent ?? [],
      witnessObject.themes ?? [],
      witnessObject.case

      // prevWitObj.causes_of_action_parent ?? [],
      // prevWitObj.causes_of_action ?? [],
      // prevWitObj.causes_of_action_defensive ?? [],
      // prevWitObj.issues_parent ?? [],
      // prevWitObj.issues ?? [],
      // prevWitObj.themes_parent ?? [],
      // prevWitObj.themes ?? [],
      // prevWitObj.case
    ),
    setObjectsFunctions: {
      setElements: setWitnessFlagRelevanceElements,
      setThemes: setWitnessFlagRelevanceThemes,
      setIssues: setWitnessFlagRelevanceIssues,
    },
    setParentObjectsFunctions: {
      setElementsParent: setWitnessFlagRelevanceParentElements,
      setThemesParent: setWitnessFlagRelevanceParentThemes,
      setIssuesParent: setWitnessFlagRelevanceParentIssues,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setWitnessFlagRelevanceDefenceElements([
                          ...listDefElementsCopy,
                        ]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        updateWitnessObject({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        updateWitnessObject({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        updateWitnessObject({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const [flagRelevanceSettings, setFlagRelevanceSettings] = useState(
    flagRelevanceSettingsDef
  );

  if (
    dataLoading ||
    !caseObject ||
    !witnessObject ||
    Object.keys(witnessObject).length === 0
  ) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={witnessObject.name}
              onChangeHandler={(e) => {
                e.persist();
                setWitnessObject((obj) => ({
                  ...obj,
                  name: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Party</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={caseObject.parties.map((i) => ({
                    label: i.name,
                    value: i.id,
                  }))}
                  value={
                    witnessObject.party
                      ? {
                          label: witnessObject.party.name,
                          value: witnessObject.party.id,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setWitnessObject((obj) => ({
                      ...obj,
                      party: {
                        name: v.label,
                        id: v.value,
                      },
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Class</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={allClass}
                  value={allClass.find((i) => i.value === witnessObject.class)}
                  onChange={(v) =>
                    setWitnessObject((obj) => ({
                      ...obj,
                      class: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div
            className={
              "col-12"
              // witnessObject.causes_of_action.length ||
              // witnessObject.issues.length ||
              // witnessObject.themes.length
              // ? "col-12"
              // : "col-12 col-sm-6"
            }
          >
            <AssociationTable
              settings={flagRelevanceSettings}
              label="Flag Relevance"
              compact
            />
          </div>
        </div>

        {/*
        <MultiSelect
          label="Associated evidence"
          placeholder="Evidence"
          value={witnessObject.evidences.map((i) => ({
            value: i.fake_id,
            label: i.label,
          }))}
          onChange={(value) =>
            setWitnessObject((obj) => ({
              ...obj,
              evidences: value,
            }))
          }
          options={caseLibrary.Evidence.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="evidence"
          labelInValue
        />
        */}

        <MultiSelect
          label="Associated evidence"
          placeholder="Evidence"
          value={evidence}
          onChange={(value) => {
            setEvidence(value);
            setWitnessObject((obj) => ({
              ...obj,
              evidence_new: value,
            }));
          }}
          options={caseLibrary.Evidence.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="evidence"
        />

        {/*

        <MultiSelect
          label="Objectives"
          placeholder="Objectives"
          value={objectives}
          onChange={(value) => setObjectives(value)}
          options={caseLibrary.Objective.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="objective"
        />

        <MultiSelect
          label="Exam Lines"
          placeholder="Exam Lines"
          value={examLines}
          onChange={(value) => setExamLines(value)}
          options={caseLibrary.ExamLine.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="examline"
        />

        */}

        <MultiSelect
          label="Topics"
          placeholder="Topics"
          value={topics}
          onChange={(value) => {
            setTopics(value);
            setWitnessObject((obj) => ({
              ...obj,
              topics_new: value,
            }));
          }}
          options={caseLibrary.Topic.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="topic"
        />
        {/*
        <MultiSelect
          label="Topics"
          placeholder="Topics"
          value={witnessObject.topics.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          onChange={(value) => {
            setWitnessObject((obj) => ({
              ...obj,
              topics: value,
            }));
          }}
          options={caseLibrary.Topic.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="topic"
          labelInValue
        />
        */}

        <div className="form-group row">
          <div className="col-12">
            <TextArea
              label="Reason for testimony"
              value={witnessObject.reason_to_testimony}
              onChange={(e) => {
                e.persist();
                setWitnessObject((obj) => ({
                  ...obj,
                  reason_to_testimony: e.target.value,
                }));
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <TextArea
              label="Context"
              value={witnessObject.background}
              onChange={(e) => {
                e.persist();
                setWitnessObject((obj) => ({
                  ...obj,
                  background: e.target.value,
                }));
              }}
            />
          </div>
        </div>
        {/*
         */}
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult(label);
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

const MultiSelect = ({
  onChange,
  placeholder = "Placeholder",
  label = "Label",
  options = [
    { label: "label1", value: "value1" },
    { label: "label2", value: "value2" },
    { label: "label3", value: "value3" },
  ],
  type = "objective",
  value,
  labelInValue = true,
}) => {
  return (
    <div className="form-group row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            labelInValue={labelInValue}
            value={value}
            showSearch
            filterOption={(input, option) => {
              return ("" + option.label)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            // onSelect={(value) => {
            // }}
            tagRender={(props) => {
              return (
                <span
                  style={{ marginRight: 5, marginBottom: 2, marginTop: 2 }}
                  className={`tag tag-${type}`}
                >
                  {props.label}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditWitness);
