import React, { Fragment, useState } from "react";
import CaseCardStyled from "./styled/CaseCardStyled";
import CaseCardTypeStyled from "./styled/CaseCardTypeStyled";
import CaseCardFirmNameStyled from "./styled/CaseCardFirmNameStyled";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import { useHistory } from "react-router-dom";
import moment from "moment";
import pancilIcon from "../../../assets/img/svg/pancil_icon.svg";
import personIcon from "../../../assets/img/svg/person.svg";
import {
  DATA_ADD_TO_STORE,
  HIDE_RIGHT_SIDE_BAR,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import RSBCaseCardPreview from "../../rightSideBarViews/RSBCaseCardPreview";
import Avatar from "react-avatar";
import UserNameDivStyled from "./styled/UserNameDivStyled";
import AccessDivStyled from "./styled/AccessDivStyled";
import { requireAccessToCase } from "../../../containers/Auth/auth";
import { route } from "../../../routes";
import { theme } from "../../../styled-components/Theme/Theme";
import { PulseLoader } from "react-spinners";
import CaseCardLabelOverflowStyled from "./styled/CaseCardLabelOverflowStyled";
import {
  TagElementCaseType,
  TagElementCoa,
  TagElementCyan,
  TagElementPurple,
} from "../../TagElementStyled/TagElementStyled";
import RSBCaseCardInvitePreview from "../../rightSideBarViews/RSBCaseCardInvitePreview";

const CaseCard = ({
  archived,
  invited,
  deleteCaseFromView,
  archiveCaseInView,
  moveFromInvitedList,
  sendRequestCaseInView,
  setSelectedRowInTable,
  ...props
}) => {
  const { caseObject } = props;
  const [sendingRequest, setSendingRequest] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  // On card click open RSB with info:
  const viewCard = (caseObject) => {
    if (is_admin) {
      if (!caseObject.request_access) {
        if (!sendingRequest) {
          setSendingRequest(true);
          requireAccessToCase(caseObject.id, dispatch)
            .then((resp) => {
              sendRequestCaseInView(resp.id);
              setSendingRequest(false);
            })
            .catch((error) => setSendingRequest(false));
        }
      } else if (caseObject.request_access === "got_access") {
        showRSBWithCardInfo();
        setSelectedRowInTable(caseObject.id);
      }
    } else {
      if (!caseObject.invited) {
        showRSBWithCardInfo();
        setSelectedRowInTable(caseObject.id);
      } else {
        showRSBWithInviteInfo();
      }
    }
  };

  const showRSBWithCardInfo = () => {
    dispatch({
      type: DATA_ADD_TO_STORE,
      keyName: "selectedCase",
      data: caseObject,
    });
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <RSBCaseCardPreview
          key={caseObject.id}
          caseObject={caseObject}
          caseId={caseObject.id}
          deleteCaseFromView={deleteCaseFromView}
          archiveCaseInView={archiveCaseInView}
          afterCloseCallback={() => setSelectedRowInTable(0)}
        />
      ),
      title: "Case Preview",
    });
  };

  const showRSBWithInviteInfo = () => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <RSBCaseCardInvitePreview
          caseObject={caseObject}
          showPreview={showRSBWithCardInfo}
          deleteCaseFromView={deleteCaseFromView}
          moveFromInvitedList={moveFromInvitedList}
          afterCloseCallback={() => setSelectedRowInTable(0)}
        />
      ),
      title: "Case Preview",
    });
  };
  const sendRequest = () => {
    if (!caseObject.request_access) {
      if (!sendingRequest) {
        setSendingRequest(true);
        requireAccessToCase(caseObject.id, dispatch)
          .then((resp) => {
            sendRequestCaseInView(resp.id);
            setSendingRequest(false);
          })
          .catch((error) => setSendingRequest(false));
      }
    } else if (caseObject.request_access === "got_access") {
      showRSBWithCardInfo();
    }
  };
  const dateUpdated = moment(caseObject.updated_at).format("DD-MM-YYYY");
  const clients = caseObject.parties
    ? caseObject.parties.filter((v) => {
        return v.client == true;
      })
    : [];
  const not_client = caseObject.parties
    ? caseObject.parties.filter((v) => {
        return v.client == false;
      })
    : [];
  return (
    <CaseCardStyled
      admin={is_admin}
      onClick={() => viewCard(caseObject)}
      // className="m-1"
      archived={caseObject.archived}
      invited={caseObject.invited}
    >
      {/* TODO: Does I need here container-fluid? */}
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="row mb-2">
          <div className="col d-flex justify-content-between">
            {/* Case Type */}
            {caseObject.case_type && caseObject.case_type.name ? (
              <TagElementPurple
                overflow
                style={{ marginRight: "10px" }}
                title="Case Type"
              >
                <Bold14Font style={{ color: "inherit" }}>
                  {caseObject.case_type.name}
                </Bold14Font>
              </TagElementPurple>
            ) : (
              <Medium14Font as="div" title="Case Type">
                N/A
              </Medium14Font>
            )}
            {/* Firm Name */}
            {is_admin ? (
              <div />
            ) : caseObject.firm_name ? (
              <TagElementCyan overflow title="Firm Name">
                <Bold14Font style={{ color: "inherit" }}>
                  {caseObject.firm_name}
                </Bold14Font>
              </TagElementCyan>
            ) : (
              <Medium14Font as="div" title="Firm Name">
                N/A
              </Medium14Font>
            )}
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <TagElementCaseType
              overflow
              style={{ marginRight: "10px" }}
              title="Type"
            >
              <Bold14Font style={{ color: "inherit" }}>
                {caseObject.new_case_type}
              </Bold14Font>
            </TagElementCaseType>
          </div>
        </div>
        <Bold18Font className="mb-2 text-left" as={CaseCardLabelOverflowStyled}>
          {caseObject.label ?? "New case"}
        </Bold18Font>
        <div className="row">
          <div className="col">
            {caseObject.causes_of_action
              ? caseObject.causes_of_action
                  .filter(
                    (v) => typeof v.type !== "undefined"
                  )
                  .map((c_o_a, index) => {
                    if (index < 2) {
                      return (
                        <Medium14Font key={c_o_a.id} as="p" className="mb-1">
                          {c_o_a.counterclaim ? '(C-CLAIM) ' : ''}{c_o_a.type.name}
                        </Medium14Font>
                      );
                    }
                  })
              : null}
          </div>
        </div>
        <div className="row">
          <div className="col d-flex flex-wrap justify-content-between justify-content-xl-between align-items-center">
            {/* Clients role in case */}
            <Medium14Font title="Client role">
              {is_admin ? null : (
                <Fragment>
                  <img className="mr-2" src={personIcon} alt={"person"} />
                  {clients.length ? (
                    clients[0].role
                  ) : (
                    <Medium14Font>N/A</Medium14Font>
                  )}
                </Fragment>
              )}
            </Medium14Font>
            {/* Last updated */}
            <Medium14Font title="last updated">
              <img className="mr-2" src={pancilIcon} alt={"date"} />
              {dateUpdated}
            </Medium14Font>
          </div>
        </div>
        {is_admin ? (
          <Fragment>
            <UserNameDivStyled
              onClick={() => {
                history.push(
                  `${route.admin.baseApp.user}/${caseObject.user.id}/details`
                );
              }}
              className="row"
            >
              <div className="col p-3 pt-4">
                <Avatar
                  size={32}
                  round={"32px"}
                  name={caseObject.user ? caseObject.user.name : ""}
                  src={caseObject.user ? caseObject.user.photo : ""}
                />
                <Bold14Font className="ml-3">
                  {caseObject.user ? caseObject.user.name : ""}
                </Bold14Font>
              </div>
            </UserNameDivStyled>
            <AccessDivStyled
              onClick={() => {}}
              access={caseObject.request_access}
              className="row justify-content-center align-items-center pt-2 pb-2"
            >
              {sendingRequest ? (
                <PulseLoader size={7} color={theme.colors.white} />
              ) : !caseObject.request_access ? (
                <Bold14Font textColor={theme.colors.white}>
                  Send Request
                </Bold14Font>
              ) : caseObject.request_access === "request_sanded" ? (
                <Bold14Font textColor={theme.colors.dark}>
                  Waiting for answer..
                </Bold14Font>
              ) : (
                <Bold14Font textColor={theme.colors.blue}>View Case</Bold14Font>
              )}
            </AccessDivStyled>
          </Fragment>
        ) : null}
      </div>
    </CaseCardStyled>
  );
};

export default CaseCard;
