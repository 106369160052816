import styled from "styled-components";
import backIcon from "@/assets/img/notebook/button_arrow_back.svg";

export const BackButton = styled.img.attrs({
  src: backIcon,
})`
  cursor: pointer;
  margin-right: 8px;
`;

export const BackLabel = styled.span``;

export const TagsLabel = styled.div`
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 25px;
  font-weight: 500;
  color: #0f122f80;
  font-size: 14px;
`;

export const BackRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 220px);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  border-bottom: 0.75px solid #dcdce6;
  padding: 15px;
  padding-bottom: 15px;
  //
`;

export const Title = styled.h2`
  color: rgba(15, 18, 47, 0.6);
  font-size: 18px;
  font-weight: 600;
  //
`;

export const TagsContainer = styled.div`
  padding-left: 15px;
`;

export const ChapterName = styled.div`
  padding-left: 15px;

  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #0f122f;
`;
