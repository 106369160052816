import React from "react";
import SearchFilterBar from "@/components/baseAppComponents/SearchFilterBar";
import { searchFilterBarTypes } from "@/components/baseAppComponents/SearchFilterBar/SearchFilterBar";
import useWindowSize from "@/hooks/useWindowSize";

const setClassName = (width, className) => {
  if ((width > 1200 && width < 1600) || width < 992) {
    return className;
  }
  return "";
};

export default ({
  view,
  setView,
  filterType,
  setFilterType,
  searchQuery,
  setSearchQuery,
  //

  bundlesOptions,
  setBundlesOptions,
  selectViewOptions,
  setSelectViewOptions,
}) => {
  const { width } = useWindowSize();

  const searchFilterBarSettingsObject = {
    fields: [
      {
        type: searchFilterBarTypes.SEARCH,
        fieldName: "search",
        placeholder: "Search...",
        connectedData: {
          state: searchQuery,
          setState: setSearchQuery,
        },
        style: { width: "66.66%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "view",
        placeholder: "View",
        connectedData: {
          // state: view,
          // setState: setView,
          state: selectViewOptions,
          setState: (option) => setView(option.value),
          // defaultValue: selectViewOption,
        },
        style: { width: "16.66%" },
      },
      {
        type: searchFilterBarTypes.FILTER,
        fieldName: "Type",
        placeholder: "Type",
        connectedData: {
          // state: filterType,
          // setState: (option) => setFilterType(option.value),
          state: bundlesOptions,
          setState: (option) => setFilterType(option.value),
          noDefaultValue: true,
        },
        style: { width: "16.66%" },
      },
    ],
  };

  return (
    <SearchFilterBar
      settingsObject={searchFilterBarSettingsObject}
      className={`mr-3${setClassName(width, " mb-2")}`}
    />
  );
};
