import React, { useState } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { colors } from "@atlaskit/theme";

import Group from "../Group";
import {
  Row,
  GroupLabel,
  ExpandIcon,
  GrabIcon,
  MenuIcon,
  MoreIcon,
  GroupRow,
} from "../styled";
import Menu from "../Chapter/Menu";
// import Chapter from "./Chapter";

const Container = styled.div`
  margin: 4px;
  display: flex;
  flex-direction: column;
`;

const Column = ({
  title,
  label,
  quotes,
  index,
  defaultExpanded = true,
  isFreestyle,

  selectedChapter,
  setSelectedChapter,

  editItem,
  removeItem,
}) => {
  const [menu, toggleMenu] = useState(false);
  const [expanded, setExpanded] = useState(defaultExpanded);

  if (isFreestyle) {
    return (
      <Draggable draggableId={title} index={index}>
        {(provided, snapshot) => (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <GroupRow
              alignCenter
              justifyBetween
              style={{
                marginBottom: "8px",
                // paddingBottom: 4,
                // borderBottom: expanded
                //   ? "1px solid #374AC220"
                //   : "1px solid transparent",
                cursor: "pointer",
                paddingBottom: 8,
              }}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}
              selected={selectedChapter && quotes[0].id === selectedChapter.id}
            >
              <Row
                alignCenter
                onClick={() => {
                  setSelectedChapter({ ...quotes[0], group: title });
                }}
                style={{ maxWidth: "85%", cursor: "pointer" }}
              >
                <GrabIcon />
                <GroupLabel
                  style={{ marginLeft: 16 }}
                  selected={
                    selectedChapter && quotes[0].id === selectedChapter.id
                  }
                >
                  {label}
                </GroupLabel>
              </Row>
              <MoreIcon show={menu} onClick={() => toggleMenu(!menu)} />
              {menu && (
                <Menu
                  close={() => toggleMenu(false)}
                  item={quotes[0]}
                  editItem={editItem}
                  removeItem={removeItem}
                  freestyle
                  listId={title}
                />
              )}
            </GroupRow>
          </Container>
        )}
      </Draggable>
    );
  }

  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Row
            alignCenter
            justifyBetween
            onClick={() => setExpanded(!expanded)}
            style={{
              marginBottom: "8px",
              // borderBottom: expanded
              //   ? "1px solid #374AC220"
              //   : "1px solid transparent",
              cursor: "pointer",

              paddingBottom: 8,
              borderBottom: "1px solid #E2E4F4",
            }}
            {...provided.dragHandleProps}
            aria-label={`${title} quote list`}
          >
            <Row alignCenter>
              <GrabIcon />
              <GroupLabel style={{ marginLeft: 16 }}>{label}</GroupLabel>
            </Row>
            <ExpandIcon
              style={{
                transform: !expanded ? "rotate(0)" : "rotate(180deg)",
                cursor: "pointer",
              }}
            />
          </Row>
          {expanded && (
            <Group
              listId={title}
              listType={title}
              // style={{
              //   backgroundColor: snapshot.isDragging ? "green" : null,
              // }}
              quotes={quotes}
              selectedChapter={selectedChapter}
              setSelectedChapter={setSelectedChapter}
              editItem={editItem}
              removeItem={removeItem}
            />
          )}
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
