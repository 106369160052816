import React from "react";
import PropTypes from "prop-types";
import TextAreaStyled from "../../TextArea/styled/TextAreaStyled";
import {theme} from "../../../styled-components/Theme/Theme";
import {Medium14Font} from "../../FontsNewComponent/Fonts";


const TextAreaSideBar = ({ name, value, placeholder, customStyle, onChange, onBlur, withMargin, withoutLimit, customPadding, noPadding }) => {

  return (
    <label className="w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col" style={noPadding ? {padding: 0} : null}>
            {withoutLimit ? null : <Medium14Font style={{color: theme.colors.darkOpacity, position: 'absolute', left: 'calc(100% - 75px)', top: '10%'}}>Optional</Medium14Font>}
            <TextAreaStyled
              customPadding={customPadding}
              name={name}
              placeholder={placeholder}
              as={customStyle}
              value={value}
              onChange={onChange}
              maxLength={withoutLimit ? null : 300}
              onBlur={onBlur}
            />
            {withoutLimit ? null : <Medium14Font style={{color: theme.colors.darkOpacity, position: 'absolute', left: 'calc(100% - 70px)', bottom: '10%'}}>{value ? value.length : 0}/300</Medium14Font>}
          </div>
        </div>
      </div>
    </label>
  );
};

TextAreaSideBar.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};

export default TextAreaSideBar;
