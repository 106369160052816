import React, { Fragment } from "react";
import Form from "../../../../../components/forms/Form";
import TextArea from "../../../../../components/inputs/TextArea/TextArea";
import AssociationTable from "../../../../../components/AssociationTable";

const ContextAndRelevance = ({
  contextAndBackground,
  witnessFlagRelevanceElements,
  witnessFlagRelevanceThemes,
  witnessFlagRelevanceIssues,
  flagRelevanceSettings,
}) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <Form className="col">
            {contextAndBackground}

            {/* Flag Relevance */}
            <div className="form-group row">
              <div
                className={
                  witnessFlagRelevanceElements.length ||
                  witnessFlagRelevanceThemes.length ||
                  witnessFlagRelevanceIssues.length
                    ? "col-12"
                    : "col-12 col-sm-6"
                }
              >
                <div style={{ padding: "0 15px" }}>
                  <AssociationTable
                    settings={flagRelevanceSettings}
                    label="Flag Relevance"
                  />
                </div>
              </div>
            </div>
            <button style={{ display: "none" }} />
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default ContextAndRelevance;
