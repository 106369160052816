import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import SearchFilterBarStyled from "./styled/SearchFilterBarStyled";
import CasesSearchInput from "../CasesSearchFilterBar/CasesSearchInput";
import closeIcon from "../../../assets/img3/svg/close-black-icon.svg";
import CasesDropdownFilter from "../CasesSearchFilterBar/CasesDropdownFilter";
import useWindowSize from "../../../hooks/useWindowSize";
import magnifierIcon from "../../../assets/img3/svg/magnifier-icon.svg";

export const searchFilterBarTypes = {
  SEARCH: "SEARCH",
  FILTER: "FILTER",
};

export const fieldPosition = {
  START: "START",
  MIDDLE: "MIDDLE",
  END: "END",
};

// const settingsObjectExample = {
//   fields: [
//     {
//       type: searchFilterBarTypes,
//       fieldName: "",
//       connectedData: {
//         state: STATE,
//         setState: SET_STATE_FUNCTION,
//       },
//       placeholder: "",
//       style: {
//         ...Object,
//       },
//     },
//   ],
// };

const BREAKPOINT_VALUE_TWO_IN_ROW = 991.98;
const BREAKPOINT_VALUE_ONE_IN_ROW = 575.98;

const rowsMode = (width, arrayLength, style, elementIndex) => {
  if (
    width <= BREAKPOINT_VALUE_TWO_IN_ROW &&
    width > BREAKPOINT_VALUE_ONE_IN_ROW
  ) {
    if (arrayLength % 2 !== 0 && elementIndex === 0) {
      return "100%";
    }
    return "50%";
  } else if (width <= BREAKPOINT_VALUE_ONE_IN_ROW) {
    return "100%";
  }

  if (style && style.width) {
    // const number = parseInt(styles.width);
    // console.log(`${(100 - number) / (arrayLength - 1)}%`);
    // return `${(100 - number) / (arrayLength - 1)}%`;
    return style.width;
  }

  return `${100 / arrayLength}%`;
};

const positionStartRowsModeChange = (
  width,
  position,
  elementIndex,
  arrayLength
) => {
  if (
    width <= BREAKPOINT_VALUE_TWO_IN_ROW &&
    width > BREAKPOINT_VALUE_ONE_IN_ROW
  ) {
    if (arrayLength % 2 === 0) {
      return elementIndex % 2 === 0;
    }
    return elementIndex % 2 !== 0;
  } else if (width <= BREAKPOINT_VALUE_ONE_IN_ROW) {
    return false;
  }
  return position === fieldPosition.START;
};

const positionMiddleRowsModeChange = (width, position) => {
  if (width <= BREAKPOINT_VALUE_TWO_IN_ROW) {
    return false;
  }
  return position === fieldPosition.MIDDLE;
};

const positionEndRowsModeChange = (width, position, elementIndex) => {
  if (
    width <= BREAKPOINT_VALUE_TWO_IN_ROW &&
    width > BREAKPOINT_VALUE_ONE_IN_ROW
  ) {
    if (elementIndex === 0) {
      return false;
    }
    return true;
  } else if (width <= BREAKPOINT_VALUE_ONE_IN_ROW) {
    return false;
  }
  return position === fieldPosition.END;
};

const SearchFilterBar = ({ settingsObject, theme, ...props }) => {
  const { width } = useWindowSize();

  const onChangeSearchBarValueHandler = (event, setState) => {
    setState(event.target.value);
  };

  const onChangeFilterOptionHandler = (selectedOption, setState) => {
    setState(selectedOption);
  };

  return (
    <SearchFilterBarStyled {...props}>
      {settingsObject &&
      settingsObject.fields &&
      settingsObject.fields.length ? (
        <div className="input-group">
          {settingsObject.fields.map((field, idx, array) => {
            // Check what position element have:
            let position;
            switch (idx) {
              case 0:
                position = fieldPosition.START;
                break;
              case array.length - 1:
                position = fieldPosition.END;
                break;
              default:
                position = fieldPosition.MIDDLE;
            }

            // render element defined in type
            switch (field.type) {
              case searchFilterBarTypes.SEARCH:
                return (
                  <div
                    className="position-relative"
                    key={idx}
                    style={{
                      width: rowsMode(width, array.length, field.style, idx),
                    }}
                  >
                    <CasesSearchInput
                      className={
                        width <= BREAKPOINT_VALUE_TWO_IN_ROW ? "mb-2" : ""
                      }
                      style={{
                        background: `url(${magnifierIcon}) no-repeat left 20px top 50%, ${theme.colors.white}`,
                      }}
                      positionStart={positionStartRowsModeChange(
                        width,
                        position,
                        idx,
                        array.length
                      )}
                      positionMiddle={positionMiddleRowsModeChange(
                        width,
                        position
                      )}
                      positionEnd={positionEndRowsModeChange(
                        width,
                        position,
                        idx
                      )}
                      type="text"
                      name={field.name}
                      value={field.connectedData.state}
                      onChange={(event) =>
                        onChangeSearchBarValueHandler(
                          event,
                          field.connectedData.setState
                        )
                      }
                      {...(field.placeholder
                        ? { placeholder: field.placeholder }
                        : null)}
                    />
                    <button
                      className={`btn${
                        field.connectedData.state ? "" : " d-none"
                      }`}
                      onClick={() => field.connectedData.setState("")}
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom:
                          width <= BREAKPOINT_VALUE_TWO_IN_ROW ? "14px" : "6px",
                        zIndex: 100,
                      }}
                    >
                      <img src={closeIcon} alt="" className="img-fluid" />
                    </button>
                  </div>
                );

              case searchFilterBarTypes.FILTER:
                return (
                  <div
                    className="position-relative"
                    key={idx}
                    style={{
                      width: rowsMode(width, array.length, field.style, idx),
                    }}
                  >
                    <CasesDropdownFilter
                      className={
                        width <= BREAKPOINT_VALUE_TWO_IN_ROW ? "mb-2" : ""
                      }
                      positionStart={positionStartRowsModeChange(
                        width,
                        position,
                        idx,
                        array.length
                      )}
                      positionMiddle={positionMiddleRowsModeChange(
                        width,
                        position
                      )}
                      positionEnd={positionEndRowsModeChange(
                        width,
                        position,
                        idx
                      )}
                      options={field.connectedData.state}
                      onChange={(selectedOption) =>
                        onChangeFilterOptionHandler(
                          selectedOption,
                          field.connectedData.setState
                        )
                      }
                      defaultValue={
                        field.connectedData.defaultValue
                          ? field.connectedData.state.find(
                              (el) =>
                                el.value ===
                                field.connectedData.defaultValue.value
                            )
                          : null
                      }
                      {...(field.placeholder
                        ? { placeholder: field.placeholder }
                        : null)}
                    />
                  </div>
                );

              default:
                throw new Error("Wrong type of field");
            }
          })}
        </div>
      ) : null}
    </SearchFilterBarStyled>
  );
};

SearchFilterBar.propTypes = {
  settingsObject: PropTypes.shape({
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(searchFilterBarTypes)).isRequired,
        fieldName: PropTypes.string.isRequired,
        connectedData: PropTypes.shape({
          state: PropTypes.any.isRequired,
          setState: PropTypes.func.isRequired,
          defaultValue: PropTypes.any,
        }).isRequired,
        placeholder: PropTypes.string,
        style: PropTypes.object,
      }).isRequired
    ).isRequired,
  }),
};

export default withTheme(SearchFilterBar);
