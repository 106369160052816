import React, { Fragment, useEffect, useRef, useState } from "react";
import FormHeader from "../forms/Form/styled/FormHeader";
import { Bold14Font, Bold18Font } from "../FontsNewComponent/Fonts";
import PropTypes from "prop-types";
import FormStyled from "../forms/Form/styled/FormStyled";
import ListAddButton from "../buttons/ListAddButton";
import { theme } from "../../styled-components/Theme/Theme";
import TableRowStyled from "./Styled/TableRowStyled";
import ListActionButton from "../buttons/ListActionButton/ListActionButton";
import collapseIcon from "../../assets/img/svg/collapse.svg";
import TableDivStyled from "./Styled/TableDivStyled";
import TablePopupPositionStyled from "./Styled/TablePopupPositionStyled";
import styled from "styled-components/macro";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PulseLoader from "react-spinners/PulseLoader";
import moment from "moment";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const OverflowDiw = styled.td`
  padding: 10px 15px;
  ${({ pointer }) => (pointer ? `cursor: pointer;` : null)};
  border: none !important;
  //overflow-wrap: anywhere;
`;
const TableElement = styled.table`
  width: calc(100% - 1px);
`;
const ActionsTd = styled.td`
  padding: 10px 15px;
  border: none !important;
  display: -webkit-box;
  float: right;
`;
const CollapseTr = styled.tr`
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  outline: 0 !important;
  font-size: 0;
  line-height: 0;
`;
const sortableIcon = (
  <svg
    style={{ marginRight: "20px" }}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="59"
    viewBox="0 0 10 59"
  >
    <g>
      <g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 27a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 27a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 35a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 35a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 43a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 43a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 51a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 51a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 59a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 59a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 19a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M1.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
        <g>
          <path
            fill="#dcdce6"
            d="M8.5 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          />
        </g>
      </g>
    </g>
  </svg>
);
const IconDiv = styled.div`
  width: 18px;
  height: 18px;
`;
const Sorting = (props) => (
  <IconDiv>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      style={{ marginLeft: "5px", cursor: "pointer" }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          opacity={!props.direction || props.direction === "desc" ? 0.6 : 1}
          d="M3.27283 7.36356H14.7272C14.9488 7.36356 15.1406 7.28265 15.3025 7.12069C15.4642 6.95878 15.5455 6.76705 15.5455 6.54545C15.5455 6.32385 15.4643 6.13221 15.3025 5.97007L9.57527 0.242867C9.41345 0.0811347 9.22176 0 9.00003 0C8.7783 0 8.58661 0.0811347 8.42465 0.242867L2.69746 5.97007C2.5355 6.13203 2.45459 6.32385 2.45459 6.54545C2.45459 6.767 2.5355 6.95878 2.69746 7.12069C2.85959 7.28265 3.05128 7.36356 3.27283 7.36356Z"
          fill={
            !props.direction
              ? "rgba(15, 18, 47, 0.6)"
              : props.direction && props.direction === "desc"
              ? "#CBCFEE"
              : "#374AC2"
          }
        />
        <path
          opacity={!props.direction || props.direction === "asc" ? 0.6 : 1}
          d="M14.7272 10.6367H3.27283C3.0511 10.6367 2.85941 10.7177 2.69746 10.8795C2.5355 11.0414 2.45459 11.2331 2.45459 11.4547C2.45459 11.6763 2.5355 11.8681 2.69746 12.03L8.42465 17.7571C8.58679 17.9191 8.77847 18.0001 9.00003 18.0001C9.22158 18.0001 9.41345 17.9191 9.57527 17.7571L15.3025 12.0299C15.4642 11.8681 15.5455 11.6763 15.5455 11.4546C15.5455 11.2331 15.4643 11.0414 15.3025 10.8794C15.1406 10.7175 14.9488 10.6367 14.7272 10.6367Z"
          fill={
            !props.direction
              ? "rgba(15, 18, 47, 0.6)"
              : props.direction && props.direction === "asc"
              ? "#CBCFEE"
              : "#374AC2"
          }
        />
      </g>
    </svg>
  </IconDiv>
);
const CollapseTd = styled.td`
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  outline: 0 !important;
  font-size: 0;
  line-height: 0;
`;
export default function Table(props) {
  let {
    title,
    hideTitle,
    setPage,
    addButtonFunction,
    data,
    moreDataLoading,
    settings,
    addButtonLabel,
    object_id,
    customButton,
    selectedId,
    pagination,
    canDrag,
  } = props;
  const [collapsedItems, setCollapsedItems] = useState([]);
  const [content, setContent] = useState([]);
  const [popupInRow, setPopupInRow] = useState(null);
  const [sortCondition, setSortCondition] = useState(null);
  const ref = useRef();
  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });
  const onScroll = () => {
    if (pagination && setPage) {
      if (!ref.current) {
        return;
      }
      let offset = 0;
      const top = ref.current.getBoundingClientRect().top;
      if (
        !moreDataLoading &&
        pagination.total_pages > pagination.current_page &&
        top + offset >= 0 &&
        top - offset <= window.innerHeight
      ) {
        setPage(pagination.current_page + 1);
      }
    }
  };
  const TableData = ({ object, value, objectClone }) => {
    const content__ = React.useMemo(() => {
      return (
        <TableDivStyled
          key={pagination ? object.id : "row_" + i}
          {...(value.maxWidth ? { style: { maxWidth: value.maxWidth } } : null)}
          {...(value.width ? { style: { width: value.width } } : null)}
          clickAction={settings.clickAction}
          onClick={
            settings.clickAction
              ? () => {
                  // settings.clickAction(objectClone);
                  settings.clickAction(object);
                }
              : null
          }
        >
          {value.renderer(objectClone, object)}
        </TableDivStyled>
      );
    }, [object, value, objectClone]);
    return content__;
  };
  const sort = (a, b) => {
    if (!sortCondition) {
      return 1;
    } else {
      if (
        [
          "label",
          "title",
          "name",
          "type",
          "document_type",
          "document_type_name",
          "status",
          "case_type",
          "doc_type",
          "new_case_type",
          "key_sections",
          "probative_status",
          "sections",
          "firm_name",
          "record_no",
          "jurisdiction",
          "citation",
        ].indexOf(sortCondition.field) !== -1
      ) {
        return sortAlphabetical(a, b);
      }
      if (
        ["date", "created_at", "updated_at"].indexOf(sortCondition.field) !== -1
      ) {
        return sortByDateTime(a, b);
      }
      return sortDefault(a, b);
    }
  };
  useEffect(() => {
    const temp_content = [];
    const clone = [...data].sort(sort);
    clone.forEach(function (object, index) {
      let objectClone = { ...object };
      let canCollapse = false;
      let row_array = settings.fields.map((value, i) => {
        if (
          settings.collapsed &&
          Array.isArray(object[value.name]) &&
          object[value.name].length > 1
        ) {
          canCollapse = true;
        }
        if (
          Array.isArray(object[value.name]) &&
          canCollapse &&
          collapsedItems.indexOf(object.id) === -1
        ) {
          objectClone[value.name] = object[value.name].filter((v_, i_) => {
            return i_ === 0;
          });
        }
        return (
          <TableDivStyled
            key={"row_" + i}
            {...(value.maxWidth
              ? { style: { maxWidth: value.maxWidth } }
              : null)}
            {...(value.width ? { style: { width: value.width } } : null)}
            clickAction={settings.clickAction}
            onClick={
              settings.clickAction
                ? () => {
                    // settings.clickAction(objectClone);
                    settings.clickAction(object);
                  }
                : null
            }
          >
            {value.renderer(objectClone, object)}
          </TableDivStyled>
        );
      });
      if (settings.actions && settings.actions.length) {
        if (
          !settings.actionShowCondition ||
          settings.actionShowCondition(object, index)
        ) {
          row_array.push(
            <ActionsTd
              key={index}
              // className="col d-flex align-items-center justify-content-end"
            >
              {settings.actions.map((action, i) => {
                return (
                  <ListActionButton
                    key={i}
                    type={
                      action.type !== "block"
                        ? action.type
                        : object.active
                        ? "block"
                        : "unblock"
                    }
                    clickHandler={
                      action.type !== "options"
                        ? (event) => {
                            action.callback(object, event);
                          }
                        : (event) => {
                            action.callback(
                              object,
                              event,
                              popupInRow,
                              setPopupInRow,
                              index
                            );
                          }
                    }
                  />
                );
              })}
              {popupInRow === index ? (
                settings.actions.find((x) => x.type === "options") ? (
                  <TablePopupPositionStyled>
                    {React.cloneElement(
                      settings.actions.find((x) => x.type === "options")
                        .popupComponent,
                      {
                        idForHandler: object.id,
                        closeHandler: () => {
                          setPopupInRow(null);
                        },
                      }
                    )}
                  </TablePopupPositionStyled>
                ) : null
              ) : null}
            </ActionsTd>
          );
        } else {
          row_array.push(<ActionsTd key={index} />);
        }
      }
      if (settings.sortable) {
        row_array.unshift(
          <TableDivStyled key={"drag_element"}>
            {canDrag ? sortableIcon : null}
          </TableDivStyled>
        );
      }
      temp_content.push({ id: object.id, data: row_array, canCollapse });
    });
    setContent(temp_content);
  }, [data, collapsedItems, popupInRow, canDrag, sortCondition]);
  const changeSorting = (item) => {
    let sort_function;
    if (item.sortCondition === "date") {
      sort_function = sortByDateTime;
    } else if (item.sortCondition === "string") {
      sort_function = sortAlphabetical;
    } else {
      sort_function = sortDefault;
    }
    if (sortCondition && sortCondition.field === item.name) {
      if (sortCondition.direction === "desc") {
        setSortCondition(null);
      } else {
        setSortCondition({
          field: item.name,
          direction: "desc",
          sort_function,
        });
      }
    } else {
      setSortCondition({
        field: item.name,
        direction: "asc",
        sort_function,
      });
    }
  };
  const sortByDateTime = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      return moment(item_a[sortCondition.field] ?? "2000-01-01 00:00:00").diff(
        moment(item_b[sortCondition.field] ?? "2000-01-01 00:00:00")
      );
    } else {
      return 1;
    }
  };
  const sortDefault = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      return item_a[sortCondition.field] - item_b[sortCondition.field];
    } else {
      return 1;
    }
  };
  const sortAlphabetical = (a, b) => {
    if (sortCondition) {
      let item_a = Object.assign(
        {},
        sortCondition.direction === "desc" ? b : a
      );
      let item_b = Object.assign(
        {},
        sortCondition.direction === "desc" ? a : b
      );
      if (!item_a[sortCondition.field]) {
        item_a[sortCondition.field] = "";
      }
      if (!item_b[sortCondition.field]) {
        item_b[sortCondition.field] = "";
      }
      item_a[sortCondition.field] =
        typeof item_a[sortCondition.field] === "string"
          ? item_a[sortCondition.field].toLowerCase()
          : item_a[sortCondition.field].toString();
      item_b[sortCondition.field] =
        typeof item_b[sortCondition.field] === "string"
          ? item_b[sortCondition.field].toLowerCase()
          : item_b[sortCondition.field].toString();
      return item_a[sortCondition.field].localeCompare(
        item_b[sortCondition.field],
        undefined,
        {
          numeric: true,
          sensitivity: "base",
        }
      );
    }
    return 1;
  };
  const changeCollapsed = (id) => {
    if (collapsedItems.indexOf(id) === -1) {
      setCollapsedItems([...collapsedItems, id]);
    } else {
      setCollapsedItems(
        collapsedItems.filter((i) => {
          return i !== id;
        })
      );
    }
  };
  let TBody;
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(data, result.source.index, result.destination.index);
    return settings.setNewOrder({ object_id, items });
  };
  if (canDrag) {
    const Items = React.memo(function Items() {
      return content.map((v, i) => {
        return (
          <Draggable key={v.id} draggableId={v.id.toString()} index={i}>
            {(provided, snapshot) => (
              <TableRowStyled
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                selected={selectedId && selectedId === v.id}
                collapsed={collapsedItems.indexOf(v.id) !== -1}
                isDragging={snapshot.isDragging}
                key={"row_" + i}
              >
                {v.data}
              </TableRowStyled>
            )}
          </Draggable>
        );
      });
    });
    TBody = () => {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={String(object_id)}>
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                <Items />
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      );
    };
  } else {
    TBody = () => {
      return (
        <tbody>
          {content.map((v, i) => {
            return (
              <Fragment key={"row_" + i}>
                <TableRowStyled
                  ref={pagination && i === content.length - 1 ? ref : null}
                  selected={selectedId && selectedId === v.id}
                  collapsed={collapsedItems.indexOf(v.id) !== -1}
                >
                  {v.data}
                </TableRowStyled>
                {v.canCollapse ? (
                  <CollapseTr key={"div_" + i}>
                    <CollapseTd
                      colSpan={
                        settings.actions.length
                          ? settings.fields.length + 1
                          : settings.fields.length
                      }
                    >
                      <div
                        className="align-items-center d-flex justify-content-center"
                        style={{ height: 0, overFlow: "visible" }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            transform:
                              collapsedItems.indexOf(v.id) !== -1
                                ? "rotate(180deg)"
                                : "unset",
                          }}
                          onClick={() => {
                            changeCollapsed(v.id);
                          }}
                          src={collapseIcon}
                          alt={"collapse"}
                        />
                      </div>
                    </CollapseTd>
                  </CollapseTr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      );
    };
  }
  return (
    <Fragment>
      <div
        ref={(ref) => {
          if (props.getRef) {
            return props.getRef(ref);
          }
        }}
        className={props.divClass ?? "row"}
        style={props.withoutMargin ? null : { marginBottom: "30px" }}
      >
        {title ? (
          <FormStyled
            title={title}
            style={{ paddingBottom: 0, marginBottom: 0 }}
          >
            <FormHeader
              style={{
                marginBottom: 0,
                paddingRight: "30px",
                paddingLeft: settings.sortable && !canDrag ? 0 : "30px",
                borderBottom: "unset",
              }}
              className="d-flex flex-wrap align-items-center justify-content-between"
            >
              <Bold18Font
                as="div"
                className="d-flex align-items-center justify-content-between"
              >
                <div style={{ height: "100%" }}>
                  {settings.sortable && !canDrag ? sortableIcon : null}
                </div>
                {hideTitle ? null : title}
              </Bold18Font>
              <div className="d-flex align-items-baseline">
                {addButtonFunction ? (
                  <ListAddButton
                    label={addButtonLabel}
                    clickHandler={addButtonFunction}
                    style={{ marginRight: customButton ? "24px" : "0" }}
                  />
                ) : null}
                {customButton ?? null}
              </div>
            </FormHeader>
          </FormStyled>
        ) : null}
        {data && data.length ? (
          <div className="table-responsive" style={{ overflowY: "hidden" }}>
            <TableElement className="table" style={{ ...props.style }}>
              <thead>
                <tr style={{ padding: "10px 15px" }}>
                  {settings.sortable ? <td style={{ width: "5%" }} /> : null}
                  {settings.fields.map((value, i) => {
                    return (
                      <OverflowDiw
                        onClick={
                          value.canSort
                            ? () => {
                                changeSorting(value);
                              }
                            : null
                        }
                        pointer={value.canSort}
                        key={value.name}
                        {...(value.width
                          ? { style: { width: value.width } }
                          : null)}
                      >
                        <div className={"d-flex align-items-baseline"}>
                          <Bold14Font
                            style={{ color: theme.colors.darkOpacity }}
                          >
                            {value.label}
                          </Bold14Font>
                          {value.canSort ? (
                            <Sorting
                              direction={
                                sortCondition &&
                                value.name === sortCondition.field
                                  ? sortCondition.direction
                                  : null
                              }
                            />
                          ) : null}
                        </div>
                      </OverflowDiw>
                    );
                  })}
                  {settings.actions && settings.actions.length ? (
                    <OverflowDiw />
                  ) : null}
                </tr>
              </thead>
              <TBody />
            </TableElement>
          </div>
        ) : null}
      </div>
      {moreDataLoading ? (
        <div className="h-100 justify-content-center align-items-center d-flex">
          <PulseLoader size={30} color={theme.colors.blue} />
        </div>
      ) : null}
    </Fragment>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  addButtonFunction: PropTypes.func,
  addButtonLabel: PropTypes.string,
  data: PropTypes.array.isRequired,
  customButton: PropTypes.object,
  settings: PropTypes.object.isRequired,
  selectedId: PropTypes.number,
  withoutMargin: PropTypes.bool,
};

Table.defaultProps = {
  hideTitle: false,
};
