import React, { Fragment, useEffect, useState } from "react";
import { route } from "../../../routes";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { Bold30Font } from "../../../components/FontsNewComponent/Fonts";
import blueBlockIcon from "../../../assets/img/svg/blueBlockIcon.svg";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  addAdminUser,
  blockUnblockUser,
  deleteAdminUser,
  getAdminUserById,
  updateAdminUser,
  updateImageAdminUser,
} from "../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import SelectNew from "../../../components/Select/SelectNew";
import {
  closeBtnText,
  createCountryOptionsList,
  roleOptions,
} from "../../../utils/constants";
import Label from "../../../components/forms/AddCardForm/Label";
import InputNew from "../../../components/InputNew/InputNew";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import Avatar from "react-avatar";
import addImage from "../../../assets/img/svg/addImage.svg";
import {
  MODAL_DELETE_ELEMENT,
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { withTheme } from "styled-components/macro";
import { blockToggleUser } from "../../../defaultActions";
import { USER_LIST } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import PhoneInput from "react-phone-number-input";
import { maxFileSize } from "../../../utils";

export const AddEditUser = () => {
  const history = useHistory();
  const [object, setObject] = useState({
    email: "",
    name: "",
    role: "",
    phone: "",
    country: "",
  });
  const [phone, setPhone] = useState(object.phone);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [blocking, setBlocking] = useState(false);
  const [name, setName] = useState(object.name);
  const [email, setEmail] = useState(object.email);
  const [role, setRole] = useState(object.role);
  const [country, setCountry] = useState(object.country);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (match.params && match.params.id) {
        let obj = await getAdminUserById(match.params.id, dispatch);
        setObject(obj);
        setName(obj.name);
        setEmail(obj.email);
        setRole(obj.role);
        setCountry(obj.country);
        setPhone(obj.phone);
      }
      setIsLoading(false);
    };
    getData();
  }, []);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          email,
        },
      };
    });
  }, [email]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          name,
        },
      };
    });
  }, [name]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          phone,
        },
      };
    });
  }, [phone]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          country,
        },
      };
    });
  }, [country]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          role,
        },
      };
    });
  }, [role]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const pageConfig = [
    {
      path: route.admin.baseApp.userList,
      title: "User List",
      activeMenuItem: USER_LIST,
    },
    {
      path: route.admin.baseApp.userList,
      title: object.id ? "Edit User" : "Create User",
      activeMenuItem: USER_LIST,
    },
  ];
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }
  const addFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const handleFiles = (files) => {
    if (files.length) {
      let file = files[0];
      if (validateFile(file)) {
        if (file.size < maxFileSize) {
          setFile(file);
          toBase64(file).then((resp) => {
            setObject((prevState) => {
              return { ...prevState, ...{ photo: resp } };
            });
          });
        } else {
          dispatch({ type: SAVE_MODAL_DATA, payload: "File is to big" });
          dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
        }
      } else {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload:
            "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format.",
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
      }
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    return validTypes.indexOf(file.type) !== -1;
  };
  const blockToggle = () => {
    setBlocking(true);
    blockToggleUser(object, setObject, dispatch);
    setBlocking(false);
  };
  const updateImage = async (id) => {
    if (is_admin) {
      if (file || !object.photo) {
        let formData = new FormData();
        formData.append("photo", object.photo ? file : null);
        let image = await updateImageAdminUser(id, formData, dispatch);
        if (!image) {
          return false;
        }
        return true;
      }
    }
    return true;
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      if (is_admin) {
        if (!object.id) {
          let resp = await addAdminUser(object, dispatch);
          if (resp) {
            let res = await updateImage(resp.id);
            if (res) {
              history.push(route.admin.baseApp.userList);
            }
          }
        } else {
          let resp = await updateAdminUser(object.id, object, dispatch);
          let res = await updateImage(object.id);
          if (res && resp) {
            history.push(route.admin.baseApp.userList);
          }
        }
      } else {
      }
      setIsSubmitting(false);
    }
  };
  const goToPaymentHistory = () => {
    history.push(`${route.admin.baseApp.user}/${object.id}/payment-history`);
  };
  const deleteFunction = () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteAdminUser(object.id, dispatch);
        history.push(route.admin.baseApp.allCases);
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };
  const file_input = React.createRef();
  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-sm-12 col-xl-3">
          <Bold30Font>{!object.id ? "Create User" : "Edit User"}</Bold30Font>
        </div>
        <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-end">
          {object.id && is_admin ? (
            <Fragment>
              <ButtonNew clickHandler={goToPaymentHistory} secondary>
                Payment History
              </ButtonNew>
              <div
                className="ml-3"
                style={{
                  width: "1px",
                  backgroundColor: theme.colors.gray,
                  height: "20px",
                }}
              />
            </Fragment>
          ) : null}
          {is_admin ? (
            <Fragment>
              {/*<ButtonNew*/}
              {/*  className="ml-3"*/}
              {/*  clickHandler={deleteFunction}*/}
              {/*  secondary*/}
              {/*  delete_={true}*/}
              {/*>*/}
              {/*  Delete*/}
              {/*</ButtonNew>*/}
              {object.id ? (
                <ButtonNew
                  className="ml-3"
                  clickHandler={blockToggle}
                  loading={blocking}
                  withIcon
                  secondary
                >
                  <img className="mr-1" src={blueBlockIcon} alt={"block"} />{" "}
                  {!object.active ? "Un-Block" : "Block"}
                </ButtonNew>
              ) : null}
            </Fragment>
          ) : null}
          <ButtonNew
            primary
            loading={isSubmitting}
            disabled={isSubmitting}
            clickHandler={submitForm}
            className="ml-3"
          >
            {closeBtnText}
          </ButtonNew>
        </div>
      </div>
      <form
      // onSubmit={submitForm}
      >
        <div
          className="row bg-white ml-0 mr-0 p-4 mt-3"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={addFile}
        >
          <div className="mb-3">
            <label className="ml-3">
              <Avatar
                style={{
                  cursor: "pointer",
                  height: "122px",
                  border: `1px solid ${theme.colors.gray}`,
                }}
                size={120}
                round={"120px"}
                name={object.name}
                src={object.photo ?? addImage}
              />
              <input
                ref={file_input}
                type="file"
                name="file"
                id="file"
                style={{ display: "none" }}
                onChange={() => {
                  if (file_input.current) {
                    handleFiles(file_input.current.files);
                  }
                }}
              />
            </label>
          </div>
          <div className="col-md-9 mb-3 d-flex align-items-center">
            {object.photo ? (
              <Fragment>
                <ButtonNew
                  style={{ maxHeight: "50px" }}
                  className="ml-4"
                  primary
                  clickHandler={(e) => {
                    e.preventDefault();
                    if (file_input.current) {
                      file_input.current.click();
                    }
                  }}
                >
                  Upload New Picture
                </ButtonNew>
                <ButtonNew
                  tyle={{ maxHeight: "50px" }}
                  className="ml-4"
                  delete_
                  secondary
                  clickHandler={(e) => {
                    e.preventDefault();
                    setObject((prevState) => {
                      return { ...prevState, ...{ photo: null } };
                    });
                  }}
                >
                  Delete
                </ButtonNew>
              </Fragment>
            ) : null}
          </div>
          <div className="col-md-6 col-sm-12">
            <InputNew
              required
              label={"First & Last Name"}
              onChangeHandler={(e) => {
                setName(e.target.value);
              }}
              value={name}
              placeholder={"Enter Name"}
              name={"name"}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Label className="row" name={"Role"} />
            <SelectNew
              options={roleOptions}
              value={roleOptions.find((v) => v.value === object.role)}
              className={"ml-3 mr-3"}
              onChangeHandler={(selected_value) => {
                setRole(selected_value.value);
              }}
              placeholder={"Select Role"}
              classNamePrefix="Select"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <InputNew
              label={"Email"}
              required
              type={"email"}
              value={email}
              onChangeHandler={(e) => {
                setEmail(e.target.value);
              }}
              placeholder={"Enter Email"}
              name={"email"}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Label className="row" name={"Country"} />
            <SelectNew
              options={createCountryOptionsList()}
              value={createCountryOptionsList().find(
                (v) => v.value === country
              )}
              onChangeHandler={(selected_value) => {
                setCountry(selected_value.value);
              }}
              placeholder={"Select Country"}
              className={"ml-3 mr-3"}
              classNamePrefix="Select"
            />
          </div>
          <div className="col-md-6 col-sm-12">
            <Label className="row" name={"Phone"} />
            <div className="pl-3 pr-3">
              <PhoneInput
                international
                placeholder="Enter your phone"
                value={phone}
                onChange={setPhone}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            {is_admin ? null : (
              <InputNew
                label={"Password"}
                required
                placeholder={"Enter Password"}
                name={"password"}
                type={"password"}
              />
            )}
          </div>
          <button className="d-none">&nbsp;</button>
        </div>
      </form>
    </Fragment>
  );
};

export default withTheme(AddEditUser);
