import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import BaseAppLayoutStyled from "../../../components/baseAppComponents/BaseAppLayout/styled/BaseAppLayoutStyled";
import Logo from "../../../components/Logo";
import BaseAppGlobalStyles from "../../../styled-components/styles/BaseAppGlobalStyles";

const StaticPageLayout = ({ children, ...props }) => {
  // page states
  const history = useHistory();

  const onClickLogoHandler = () => {
    history.push(route.index);
  };

  return (
    <Fragment>
      <BaseAppGlobalStyles />
      <BaseAppLayoutStyled {...props}>
        <div className="container-fluid h-100 my-3">
          <div className="row h-100">
            {/* LSB */}
            <div className="col-md-3 col-xl-2 d-none d-md-block">
              <a href={route.index}>
                <Logo dark />
              </a>
            </div>

            {/* Central */}
            <div className="col-12 col-xl-8 col-md-6 d-flex flex-column align-items-center">
              <div
                style={{
                  minWidth: "320px",
                  maxWidth: "800px",
                  textAlign: "justify",
                }}
              >
                <div className="d-block d-md-none mb-3">
                  <a href={route.index}>
                    <Logo dark />
                  </a>
                </div>
                {children}
              </div>
            </div>

            {/* RSB */}
            <div className="col-md-3 col-xl-2" />
          </div>
        </div>
      </BaseAppLayoutStyled>
    </Fragment>
  );
};

export default StaticPageLayout;
