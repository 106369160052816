import {EXTRACT_DATA_SET, EXTRACT_DATA_INITIAL} from "../types";

const initialState = {
  data: {
    witnesses: [],
    label: '',
    themes_parent: [],
    themes: [],
    issues_parent: [],
    issues: [],
    causes_of_action_parent: [],
    causes_of_action: [],
    causes_of_action_defensive: [],
    proofs: [],
    reliefs: [],
  }
};

const extractDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTRACT_DATA_SET:
      return {
        ...state,
        data: action.payload,
      };
    case EXTRACT_DATA_INITIAL:
      return {
        ...state,
        data: initialState.data,
      };
    default:
      return state;
  }
};

export default extractDataReducer;
