import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Popover } from "antd";

import tableIcon from "@/assets/img/notebook/toolbar/table.svg";

const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  margin-top: 1px;
  cursor: pointer;
  width: 25px;
  height: 25px;
`;

const PopoverContainer = styled.div`
  // padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Rect = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  margin-bottom: 4px;
  border: 1px solid ${(props) => (props.selected ? "purple" : "black")};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const MatSiz = styled.div`
  height: 20px;
`;

const rows = [1, 2, 3, 4, 5];
const columns = [1, 2, 3, 4, 5];

const Table = ({ editor }) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const [hoveredRect, setHoveredRect] = useState(undefined);
  const [noOfTables, setNoOfTable] = useState(1);

  useEffect(() => {
    [...document.getElementsByTagName("table")].forEach((el) => {
      el.addEventListener("click", (e) => {
        if (e.offsetY > el.offsetHeight) {
          editor.commands.addRowAfter();
        }
      });
    });
    // return () => {

    // }
    //
  }, [noOfTables]);

  // document.getElementsByTagName('table')[0].offsetHeight
  // e.offsetY

  const matrixDefault = rows.map((row) => {
    const r = [];
    columns.map((column) => {
      const selected = hoveredRect && hoveredRect[0] < row;
      r.push(
        <Rect
          onMouseOver={() => {
            setHoveredRect([row, column]);
          }}
          selected={true}
          style={{
            borderColor: selected ? "purple" : "black",
          }}
          // selected={hoveredRect && hoveredRect[0] < row}
        >
          {column}
        </Rect>
      );
    });
    return <Row>{r}</Row>;
  });

  const [matrix, setMatrix] = useState(matrixDefault);

  useEffect(() => {
    setMatrix(
      rows.map((row) => {
        const r = [];
        columns.map((column) => {
          const selected =
            hoveredRect && row <= hoveredRect[0] && column <= hoveredRect[1];
          r.push(
            <Rect
              onMouseOver={() => {
                setHoveredRect([row, column]);
              }}
              onClick={() => {
                setNoOfTable((no) => no + 1);
                editor
                  .chain()
                  .focus()
                  .insertTable({
                    rows: hoveredRect[0],
                    cols: hoveredRect[1],
                    withHeaderRow: true,
                  })
                  .run();
                setPopupOpened(false);
                setHoveredRect(undefined);
              }}
              style={{
                borderColor: selected ? "#374AC2" : "#DCDCE6",
                cursor: "pointer",
              }}
              // selected={true}
              // selected={hoveredRect && hoveredRect[0] < row}
            ></Rect>
          );
        });
        return <Row>{r}</Row>;
      })
    );
  }, [hoveredRect]);

  const content = (
    <PopoverContainer onMouseOut={() => setHoveredRect(undefined)}>
      {matrix}

      <MatSiz>{hoveredRect && `${hoveredRect[0]}x${hoveredRect[1]}`}</MatSiz>
    </PopoverContainer>
  );

  return (
    <Container>
      <Popover
        content={content}
        trigger="click"
        placement="bottom"
        open={popupOpened}
        onOpenChange={(v) => setPopupOpened(v)}
      >
        <Icon
          src={tableIcon}
          alt=""
          // onClick={() =>
          //   editor
          //     .chain()
          //     .focus()
          //     .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          //     .run()
          // }
        />
      </Popover>
    </Container>
  );
};

export default Table;
