import styled from "styled-components/macro";

const LeftSideBarAvatarButtonArrowStyled = styled.span.attrs({
  className: "app-arrow-down-icon",
})`
  font-size: 6px;
  color: ${({ theme }) => theme.colors.white};
`;

export default LeftSideBarAvatarButtonArrowStyled;
