import styled from "styled-components/macro";

const LeftSideBarUserLayoutStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export default LeftSideBarUserLayoutStyled;
