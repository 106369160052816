import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { useDispatch, useSelector } from "react-redux";
import { getCaseById } from "../../Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import BaseWhiteCardStyled from "../../../components/baseAppComponents/BaseWhiteCard/styled/BaseWhiteCardStyled";
import CaseArenaViewCard from "../../../components/baseAppComponents/CaseArenaViewCard";
import trialHubIcon from "../../../assets/img3/svg/trial-hub-icon.svg";
import notebooksIcon from "../../../assets/img3/svg/notebooks-icon.svg";
import documentsHubIcon from "../../../assets/img3/svg/documents-hub-icon.svg";
import applicationsHubIcon from "../../../assets/img3/svg/applications-hub-icon.svg";
import trialProofsIcon from "../../../assets/img3/svg/trial-proofs-icon.svg";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Medium14Font,
  Medium18Font,
} from "../../../components/FontsNewComponent/Fonts";
import Table from "../../../components/Table";
import CasesMenu from "../../../components/baseAppComponents/CasesMenu";
import LiStyled from "../../../components/Table/Styled/LiStyled";
import CornerButton from "../../../components/buttons/CornerButton";
import { route } from "../../../routes";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import useWindowSize from "../../../hooks/useWindowSize";
import CaseLog from "./CaseLog";

const C_O_A = "CASE_ARENA/C_O_A";
const THEMES = "CASE_ARENA/THEMES";
const ISSUES = "CASE_ARENA/ISSUES";
const CASE_SUMMARY = "CASE_ARENA/CASE_SUMMARY";
const CASE_LOG = "CASE_ARENA/CASE_LOG";

// Tabs content
const C_O_A_Tab = () => <Table title="" data="" settings="" />;

const tabsSwitchContent = (activeType) => {
  switch (activeType) {
    default:
      return C_O_A_Tab;
  }
};

const CaseArena = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [caseObject, setCaseObject] = useState({});
  const [type, setType] = useState(C_O_A);
  const [causesOfAction, setCausesOfAction] = useState([]);
  const dispatch = useDispatch();
  const { case_id } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const { width } = useWindowSize();
  const [hubsAndTrialProofs, setHubsAndTrialProofs] = useState([]);
  const tabsPanelItems = [
    {
      id: 1,
      text:
        caseObject.new_case_type === "Criminal"
          ? "Offence"
          : "Causes of Action",
      activeType: C_O_A,
      action: (type, func) => func(type),
    },
    {
      id: 2,
      text: "Themes",
      activeType: THEMES,
      action: (type, func) => func(type),
    },
    {
      id: 3,
      text: "Issues",
      activeType: ISSUES,
      action: (type, func) => func(type),
    },
    {
      id: 4,
      text: "Case Summary",
      activeType: CASE_SUMMARY,
      action: (type, func) => func(type),
    },
    {
      id: 5,
      text: "Audit Log",
      activeType: CASE_LOG,
      action: (type, func) => func(type),
    },
  ];
  // Load case and disable loading state
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCaseById(case_id, dispatch, false, true);
      if (is_admin && !response) {
        history.push("/admin/all-cases");
      }
      setCaseObject(response);
      setHubsAndTrialProofs([
        {
          id: 0,
          label: "Notebooks",
          icon: notebooksIcon,
          features: [],
          action: (history) => {
            // dispatch({
            //   type: SHOW_RIGHT_SIDE_BAR,
            //   url: history.location.pathname,
            //   content: <div>case library</div>,
            //   title: "Case Preview",
            // });
            history.push(`${url}/notebooks`);
            // history.push(`${url}/notebook`);
          },
        },
        {
          id: 1,
          label: "Trial Hub",
          icon: trialHubIcon,
          features: [
            {
              id: 1,
              feature: "Evidence",
            },
            {
              id: 2,
              feature: "Witness",
            },
            {
              id: 3,
              feature: "X-Exam",
            },
            {
              id: 4,
              feature: "Authorities",
            },
          ],
          action: (history) => history.push(`${url}/trial-hub`),
        },
        {
          id: 2,
          label: "Documents Hub",
          icon: documentsHubIcon,
          features: [
            {
              id: 1,
              feature: "Case Docs",
            },
            {
              id: 2,
              feature: "Evidence",
            },
            {
              id: 3,
              feature: "Authorities",
            },
            {
              id: 4,
              feature: "Application Papers",
            },
            {
              id: 5,
              feature: "Other",
            },
          ],
          action: (history) => history.push(`${url}/documents`),
        },
        {
          id: 3,
          label: "Applications Hub",
          icon: applicationsHubIcon,
          features: response.applications.length
            ? response.applications.map((v) => {
                return {
                  id: v.id,
                  feature: v.label,
                  link: `${url}/applications-hub/${v.fake_id}`,
                };
              })
            : [
                {
                  id: 3,
                  feature: "Injunction Application",
                },
              ],
          action: (history) => history.push(`${url}/applications-hub`),
        },
        {
          id: 4,
          label: "Trial Proofs",
          icon: trialProofsIcon,
          features: [
            {
              id: 1,
              feature: "Witness Proofs",
            },
            {
              id: 2,
              feature: "X-Exam Proofs",
            },
            {
              id: 3,
              feature: "Authorities",
            },
          ],
          action: (history) => history.push(`${url}/trial-proofs`),
        },
      ]);
      if (response) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCausesOfAction(caseObject ? caseObject.causes_of_action : []);
  }, [caseObject]);

  // Render loader
  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  // C_O_A Tables settings
  const causeOfActionSettings = {
    collapsed: true,
    actions: [],
    //[
    //       {
    //         type: "edit",
    //         callback: (object) => {
    //           handleCauseOfAction(object);
    //         },
    //       },
    //       {
    //         type: "delete",
    //         callback: (object) => {
    //           dispatch({
    //             type: SAVE_MODAL_DATA,
    //             payload: "You won't be able to restore data",
    //             beforeCloseHandler: async () => {
    //               await deleteCauseOfAction(case_id, object.id, dispatch);
    //               causeOfActionChange(object, "delete");
    //             },
    //           });
    //           dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    //         },
    //       },
    //     ]
    fields: [
      {
        name: "name",
        label:
          caseObject.new_case_type === "Criminal"
            ? "OFFENCE"
            : "CAUSES OF ACTION",
        renderer: (object) => {
          return <Medium14Font>{object.type.name}</Medium14Font>;
        },
      },
      {
        name: "elements",
        label: "ELEMENTS",
        renderer: (object) => {
          if (object.elements && object.elements.length) {
            return (
              <ul>
                {object.elements.map((element) => {
                  return (
                    <LiStyled key={element.id}>
                      <Medium14Font>{element.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
      {
        name: "defence_elements",
        label: "DEFENCE ELEMENTS",
        renderer: (object) => {
          if (object.defence_elements && object.defence_elements.length) {
            return (
              <ul>
                {object.defence_elements.map((element) => {
                  return (
                    <LiStyled key={element.id}>
                      <Medium14Font>{element.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };

  // C_O_A Tables filters
  let filtered_c_of_a = causesOfAction.filter((elem_) => {
    return elem_.counterclaim == false;
  });
  let filtered_c_of_a_counterclaim = causesOfAction.filter((elem_) => {
    return elem_.counterclaim == true;
  });

  // Theme settings
  const themesSettings = {
    collapsed: true,
    actions: [],
    // [
    //       {
    //         type: "edit",
    //         callback: (object) => {
    //           handleTheme(object);
    //         },
    //       },
    //       {
    //         type: "delete",
    //         callback: (object) => {
    //           dispatch({
    //             type: SAVE_MODAL_DATA,
    //             payload: "You won't be able to restore data",
    //             beforeCloseHandler: async () => {
    //               await deleteThemes(case_id, object.id, dispatch);
    //               themesChange(object, "delete");
    //             },
    //           });
    //           dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    //         },
    //       },
    //     ]
    fields: [
      {
        name: "name",
        label: "THEME NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "sub_themes",
        label: "SUBTHEMES",
        renderer: (object) => {
          if (object.sub_themes && object.sub_themes.length) {
            return (
              <ul>
                {object.sub_themes.map((theme_) => {
                  return (
                    <LiStyled key={theme_.id}>
                      <Medium14Font>{theme_.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };

  // Issues settings
  const issuesSettings = {
    collapsed: true,
    actions: [],
    // [
    //       {
    //         type: "edit",
    //         callback: (object) => {
    //           handleIssues(object);
    //         },
    //       },
    //       {
    //         type: "delete",
    //         callback: (object) => {
    //           dispatch({
    //             type: SAVE_MODAL_DATA,
    //             payload: "You won't be able to restore data",
    //             beforeCloseHandler: async () => {
    //               await deleteIssues(case_id, object.id, dispatch);
    //               issuesChange(object, "delete");
    //             },
    //           });
    //           dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    //         },
    //       },
    //     ]
    fields: [
      {
        name: "name",
        label: "ISSUES NAME",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "key_facts",
        label: "KEY FACTS",
        renderer: (object) => {
          if (object.key_facts && object.key_facts.length) {
            return (
              <ul>
                {object.key_facts.map((fact) => {
                  return (
                    <LiStyled key={fact.id}>
                      <Medium14Font>{fact.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          }
          return null;
        },
      },
    ],
  };

  // Breadcrumbs configuration object
  const pageConfig = !is_admin
    ? [
        {
          path: "/app/cases",
          title: `${is_admin ? "All Cases" : "Cases"}`,
          activeMenuItem: CASES,
        },
        {
          path: `/app/cases/${case_id}`,
          title: `${caseObject ? caseObject.label || "New Case" : null}`,
          activeMenuItem: CASES,
        },
      ]
    : [
        {
          path: `${route.admin.baseApp.allCases}`,
          title: "All cases",
          activeMenuItem: CASES,
        },
        {
          path: `${route.admin.baseApp.allCases}/${case_id}`,
          title: `${caseObject ? caseObject.label || "New Case" : null}`,
          activeMenuItem: CASES,
        },
      ];

  const getType = (type) => {
    setType(type);
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row mb-4">
        <div className="col">
          <CaseArenaViewCard caseObject={caseObject} />
        </div>
      </div>
      <div className="row mb-4">
        {hubsAndTrialProofs.map((el) => (
          <div
            key={el.id}
            className="col-12 mb-3 col-md-6 col-lg col-xl mb-xl-0"
          >
            <BaseWhiteCardStyled className="h-100 p-3 d-flex flex-column justify-content-between position-relative">
              <div className="row mb-4">
                <div
                  className={`col d-flex${
                    width < 1922 && width >= 992
                      ? " flex-wrap flex-column align-items-center"
                      : ""
                  }`}
                >
                  <img
                    src={el.icon}
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "70px", marginRight: "10px" }}
                  />
                  <Bold24Font>{el.label}</Bold24Font>
                </div>
              </div>
              <div className="row h-100">
                <div className="col-12">
                  <ul style={{ listStyle: "disc inside" }}>
                    {el.features.map((feature) => {
                      if (!feature.link) {
                        return (
                          <li key={feature.id}>
                            <Medium14Font>{feature.feature}</Medium14Font>
                          </li>
                        );
                      } else {
                        return (
                          <li>
                            <Link key={feature.id} to={feature.link}>
                              <Medium14Font>{feature.feature}</Medium14Font>
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <CornerButton
                    onClick={() => el.action(history)}
                    style={{
                      position: "relative",
                      right: "-1rem",
                      bottom: "-1rem",
                    }}
                  >
                    <Bold14Font textColor={theme.colors.white}>
                      View All
                    </Bold14Font>
                  </CornerButton>
                </div>
              </div>
            </BaseWhiteCardStyled>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col">
          {/* Tabs panel */}
          <BaseWhiteCardStyled
            className="d-flex align-items-center p-4"
            style={{ height: "70px" }}
          >
            <CasesMenu
              active={type}
              casesMenu={tabsPanelItems}
              getType={getType}
              activeFont={Bold18Font}
              font={Medium18Font}
              noButton
              noBadges
            />
          </BaseWhiteCardStyled>

          {/* Rendering different tabs based on type state */}
          {type === C_O_A ? (
            <div className="px-3">
              <Table data={filtered_c_of_a} settings={causeOfActionSettings} />
              {filtered_c_of_a_counterclaim &&
              filtered_c_of_a_counterclaim.length ? (
                <Fragment>
                  <Bold18Font>Counterclaim</Bold18Font>
                  <Table
                    data={filtered_c_of_a_counterclaim}
                    settings={causeOfActionSettings}
                  />
                </Fragment>
              ) : null}
            </div>
          ) : null}
          {type === THEMES ? (
            <div className="px-3">
              <Table settings={themesSettings} data={caseObject.themes} />
            </div>
          ) : null}
          {type === ISSUES ? (
            <div className="px-3">
              <Table settings={issuesSettings} data={caseObject.issues} />
            </div>
          ) : null}
          {type === CASE_SUMMARY ? (
            <BaseWhiteCardStyled
              className="my-3 p-3 w-100"
              // style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
            >
              <Medium14Font as="p">
                {caseObject.case_theory ? caseObject.case_theory : "N/A"}
              </Medium14Font>
            </BaseWhiteCardStyled>
          ) : null}
          {type === CASE_LOG ? (
            <div className="px-3">
              <CaseLog case_id={case_id} />
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(CaseArena);
