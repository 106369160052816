import styled from "styled-components";

import lockIcon from "@/assets/img/notebook/private-lock.svg";
import unlockIcon from "@/assets/img/notebook/shared-lock.svg";

export const Container = styled.div`
  padding: 15px;
`;

export const AvatarDiv = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.borderColor};
  background: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
`;

export const LockIcon = styled.img.attrs({
  src: lockIcon,
})`
  margin-left: 4px;
`;

export const UnlockIcon = styled.img.attrs({
  src: unlockIcon,
})`
  margin-left: 4px;
`;

export const CollabContainer = styled.div`
  margin-right: 8px;
`;

export const NotebookType = styled.div`
  ${(props) =>
    props.type === "Trial" &&
    `
    background: #259B9A10;
    border: 1px solid #259B9A;
    color: #259B9A;
  `}
  ${(props) =>
    props.type === "Custom" &&
    `
    background: rgba(55, 74, 194, 0.063);
    color: rgb(55, 74, 194);
    border: 1px solid rgb(55, 74, 194);
    // border-color: rgba(55, 74, 194, 0.125);
  `}
  ${(props) =>
    props.type === "Application" &&
    `
    background: rgba(255, 144, 23, 0.063);
    color: rgb(255, 144, 23);
    border: 1px solid rgb(255, 144, 23);
    // border-color: rgba(255, 144, 23, 0.125);
  `}
font-weight: 400;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PopoverContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const PopoverText1 = styled.span`
  color: #374ac2;
  font-weight: 600;
  line-height: 22px;
  font-size: 15px;
`;

export const PopoverText2 = styled.span`
  color: #6a6c7d;
  /* font-size: 13px; */
  line-height: 22px;
`;
