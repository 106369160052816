import styled from "styled-components/macro";
import rem from "../../../../../utils/rem";

const BaseAppLayoutCentralPartStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //align-items: center;
  //max-width: ${rem(`1170px`)};
`;

export default BaseAppLayoutCentralPartStyled;
