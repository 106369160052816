import React, { useContext, useEffect } from "react";

import { EditorContent } from "@tiptap/react";
import { EditorContext, NotebookContext } from "@/context";
import ConvertBubble from "@editor/components/ConvertBubble";

import Toolbar from "./components/Toolbar";

import { Container } from "./styled";

import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Editor = () => {
  const { editor, setLastStepCreateTag, content } = useContext(EditorContext);
  const {
    setEvidenceItems,
    selectedChapter,
    columns,
    setColumns,
    setCaseLibrary,
  } = useContext(NotebookContext);
  const { pathname } = useLocation();
  const { id: caseId, notebook_id: notebookId } = useParams();

  const onCreateTag = async (text, tType, range) => {
    const caseId = pathname.split("/")[3];

    let tagType = tType;
    if (tagType === "exam") tagType = "ExamLine";
    // if (tagType === 'exam') tagType = 'ExamLine'

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let res;
    // let body;
    // let endpointTagType = tagType;

    res = await axios
      .post(
        `/api/cases/${caseId}/notes/objects?type=${capitalize(
          tagType
        )}&text=${text}&id_case=${caseId}`,
        {},
        config
      )
      .catch((error) => {
        alert(error);
      });

    tagType = tagType.toLowerCase();

    if (res && res.data && res.data.data) {
      setCaseLibrary((caseLibrary) => ({
        ...caseLibrary,
        [res.data.data.type]: [
          ...caseLibrary[res.data.data.type],
          {
            id: res.data.data.id,
            label: res.data.data.label,
            type: res.data.data.type,
          },
        ],
      }));

      const newTag = {
        tagId: res.data.data.id,
        id: res.data.data.label,
        tagType,
      };

      editor
        .chain()
        .insertContentAt(
          range,
          `<tag class="tag-${tagType}" data-tagType="${tagType}" data-id="${res.data.data.label}" data-tagId="${res.data.data.id}">${res.data.data.label}</tag>`
        )
        .focus()
        .setMeta("createTag", newTag)
        .run();
      setLastStepCreateTag(newTag);

      if (tagType === "evidence") {
        setEvidenceItems((prev) => [
          ...prev,
          {
            id: res.data.data.label,
            tagId: res.data.data.id,
            tagType,
          },
        ]);
      }
    }
  };

  return (
    <Container className="d-flex flex-column">
      {editor && <ConvertBubble editor={editor} onCreateTag={onCreateTag} />}
      <Toolbar editor={editor} />
      <EditorContent className="workspace-editor" editor={editor} />
    </Container>
  );
};

export default Editor;
