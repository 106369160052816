import React, { useState, useContext } from "react";
import { NotebookContext } from "@/context";
import styled from "styled-components";
import Button from "@/components/buttons/Button/Button";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";

import backIcon from "@/assets/img/notebook/button_arrow_back.svg";

import FlagRelevanceFilter from "@/components/FlagRelevanceFilter/FlagRelevanceFilter";

export const BackButton = styled.img.attrs({
  src: backIcon,
})`
  cursor: pointer;
  margin-right: 8px;
`;

export const BackLabel = styled.span``;

const BackRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const Container = styled.div`
  width: 18%;
  min-width: 18%;
  max-width: 18%;
  border-left: 1px solid rgba(55, 74, 194, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  background: white;
  overflow-y: auto;
  z-index: 10;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  border-bottom: 0.75px solid #dcdce6;
  padding: 15px;
  padding-bottom: 15px;
  //
`;

export const Title = styled.h2`
  color: rgba(15, 18, 47, 0.6);
  font-size: 18px;
  font-weight: 600;
  //
`;
export default ({ setFilterQuery, show, setShowFilter, setSectionsOpened }) => {
  const [flagRelevance, setFlagRelevance] = useState([]);
  const { caseObject } = useContext(NotebookContext);

  const onFlagRelevanceChange = (options) => {
    setFlagRelevance(options);
  };

  return (
    <Container show={show}>
      <Column>
        <Heading>
          <div
            style={{
              height: 40,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Title>Relevance filter</Title>
          </div>
        </Heading>
        <Heading
          onClick={() => setShowFilter(false)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <BackRow>
            <BackButton />
            <Bold14Font style={{ color: "#374AC2", cursor: "pointer" }}>
              Back to case library
            </Bold14Font>
          </BackRow>
        </Heading>
        <Column style={{ padding: "15px 0" }}>
          <FlagRelevanceFilter
            size={12}
            flagRelevance={flagRelevance}
            caseObject={caseObject}
            onFlagRelevanceChange={onFlagRelevanceChange}
            withLabels
            noMinWidth
            itemStyle={{
              flex: 0,
              // maxHeight: 80,
            }}
            style={{
              marginBottom: 15,
              width: "100%",
              // maxHeight: 80,
            }}
          />
        </Column>
      </Column>
      <Column
        style={{
          padding: "20px 15px 15px 15px",
          borderTop: "1px solid #DCDCE6",
        }}
      >
        <Button
          type="submit"
          wide
          primary
          disabled={false}
          clickHandler={async () => {
            console.log(flagRelevance);
            console.log(caseObject);
            // console.log(
            //   flagRelevance
            //     .join("&relevance[]=")
            //     .replaceAll("issue", "Issue")
            //     .replaceAll("coa", "CauseOfAction")
            //     .replaceAll("theme", "Theme")
            //     .replaceAll("element", "Element")
            // );
            // /case-library?relevance[]=KeyFact_96&relevance[]=CausesOfAction_1
            setFilterQuery(
              flagRelevance
                .join("&relevance[]=")
                .replaceAll("issue", "Issue")
                .replaceAll("coa", "CauseOfAction")
                .replaceAll("element", "CausesOfActionElement")
                .replaceAll("theme", "Theme")
                .replaceAll("subtheme", "SubTheme")
            );
            setSectionsOpened({
              relevance: true,
              evidence: true,
              "application-papers": true,
              "key-sections": true,
              witness: true,
              objectives: true,
              topics: true,
              "exam-lines": true,
              party: true,
              authority: true,
              "Note/comment": true,
            });
            setShowFilter(false);
          }}
        >
          Apply
        </Button>
      </Column>
    </Container>
  );
};
