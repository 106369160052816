import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageFooterSocialLinkWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${rem(`166px`)};

  @media (max-width: 767.98px) {
    max-width: 100%;
  }
`;

export default LandingPageFooterSocialLinkWrapperStyled;
