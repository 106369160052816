import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const PlanCardIconWrapperStyled = styled.div`
  padding: 15px;
  width: 120px;
  height: 120px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightGray};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default PlanCardIconWrapperStyled;
