import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Label from "../AddCardForm/Label";
import Select from "../../Select";
import Button from "../../buttons/Button/Button";
import styled, { css, keyframes } from "styled-components/macro";
import dragAndDrop from "../../../assets/img/svg/Drag and Drop.svg";
import { Bold18Font, Medium18Font } from "../../FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_ERROR,
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import {
  authoritiesBulkImportRequest,
  bulkImportApplicationPaperFiles,
  bulkImportEvidence,
  casesBulkImportRequest,
  getApplicationsList,
  getBundles,
} from "../../../containers/Auth/auth";
import { pulse } from "react-animations";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "../../../containers/BaseApp/Documents/Documents";
import PulseLoader from "react-spinners/PulseLoader";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { useHistory } from "react-router-dom";
import ButtonNew from "../../buttons/Button/ButtonNew";
import NewBundleForm from "../DocumentForms/NewBundleForm";
import AuthoritiesBulkImport from "../../../containers/BaseApp/TrialHub/Authorities/AuthoritiesBulkImport";
import { maxFileSize } from "../../../utils";

const pulseAnimation = keyframes`${pulse}`;
const validTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const DashedDiv = styled.div`
  border-radius: 21px;
  padding: 30px;
  border: 3px dashed #dcdce6;
  background-color: #fcfcff;
  ${({ hoover }) =>
    hoover &&
    css`
      animation: 1.5s ${pulseAnimation};
    `}
`;

const ElementDiv = styled.div`
  margin-top: ${({ first }) => (first ? 0 : "10px")};
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: ${({ last }) => (last ? "20px" : "10px")};
`;

const AddNewDocumentForm = ({ caseId }) => {
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [applications, setApplications] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [applicationsLoading, setApplicationsLoading] = useState(true);
  const [docClass, setDocClass] = useState("");
  const [docClassSelectedOption, setDocClassSelectedOption] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [hideBundle, setHideBundles] = useState(true);
  const [hoover, setHoover] = useState(false);
  const [showApplicationSelect, setShowApplicationSelect] = useState(false);
  const dispatch = useDispatch();
  const [showNewBundlePanel, setShowNewBundlePanel] = useState(false);
  const history = useHistory();

  const dragOver = (e) => {
    e.preventDefault();
    setHoover(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHoover(false);
  };

  const onBundleChange = (select) => {
    setSelectedBundles(select);
  };
  useEffect(() => {
    setSelectedBundles([]);
    const getAuthorities = async () => {
      if (showApplicationSelect && !applications.length) {
        setApplicationsLoading(true);
        const resp_applications = await getApplicationsList(caseId, dispatch);
        setApplications(
          resp_applications.map((v) => {
            return { value: v.id, label: v.label };
          })
        );
        setApplicationsLoading(false);
      }
    };
    getAuthorities();
  }, [docClass]);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const openAnotherPage = (data) => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    history.push(data);
  };
  const requestRouter = async (formData) => {
    if (docClass === EVIDENCE_DOCUMENTS) {
      let data = await bulkImportEvidence(caseId, dispatch, formData);
      openAnotherPage({
        pathname: `/${
          is_admin ? "admin/all-cases" : "app/cases"
        }/${caseId}/trial-hub/evidence/import`,
        state: { unique_data: data },
      });
    } else if (docClass === AUTHORITIES_DOCUMENTS) {
      let data = await authoritiesBulkImportRequest(caseId, dispatch, formData);
      openAnotherPage({
        pathname: `/${
          is_admin ? "admin/all-cases" : "app/cases"
        }/${caseId}/trial-hub/authorities/import`,
        state: { unique_data: data },
      });
    } else if (docClass === APPLICATION_DOCUMENTS) {
      formData.append("id_application", applicationId);
      let data = await bulkImportApplicationPaperFiles(
        applicationId,
        caseId,
        dispatch,
        formData
      );
      openAnotherPage({
        pathname: `/${
          is_admin ? "admin/all-cases" : "app/cases"
        }/${caseId}/applications-hub/edit/${applicationId}`,
        state: { applicationWorkflowStage: 1, unique_data: data },
      });
    } else if (docClass === CASE_DOCUMENTS) {
      let data = await casesBulkImportRequest(caseId, dispatch, formData);
      openAnotherPage({
        pathname: `/${
          is_admin ? "admin/all-cases" : "app/cases"
        }/${caseId}/documents/import`,
        state: { unique_data: data },
      });
    }
  };
  const handleFiles = async (files) => {
    let submitFiles = [];
    if (files.length) {
      files.forEach(function (file_) {
        if (validateFile(file_, validTypes)) {
          if (file_.size < maxFileSize) {
            submitFiles.push(file_);
          } else {
            dispatch({ type: SAVE_MODAL_DATA, payload: "File is to big" });
            dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
            return false;
          }
        } else {
          dispatch({
            type: SAVE_MODAL_DATA,
            payload:
              "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format.",
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
          return false;
        }
      });
    }
    if (submitFiles.length) {
      let formData = new FormData();
      submitFiles.forEach(function (file, index) {
        formData.append(index, file);
      });
      formData.append(
        "bundles",
        JSON.stringify(
          selectedBundles ? selectedBundles.map((v) => v.value) : ""
        )
      );
      await requestRouter(formData);
    }
  };

  const onClickHandler = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const fileInput = useRef(null);

  const validateFile = (file, validTypes) =>
    validTypes.indexOf(file.type) !== -1;

  const addFile = (e) => {
    e.preventDefault();
    if (!docClass) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "Select class first",
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR });
      return;
    }
    if (showApplicationSelect && !applicationId) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: "Select Application first",
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR });
      return;
    }
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      const resp = await getBundles(dispatch, caseId);
      setBundles(resp);
      setDataLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (showNewBundlePanel === false) {
      // dispatch({ type: SET_AFTERCLOSE_HANDLER, payload: () => {} });
    }
  }, [showNewBundlePanel]);

  let selects = bundles
    .filter((v) => {
      return v.class_name === docClass;
    })
    .map((e) => {
      return { label: e.label, value: e.id };
    });

  const onClickAddBundleHandler = () => {
    setShowNewBundlePanel(true);
  };

  const onClickSaveNewBundleHandler = (bundle) => {
    const bundlesListCopy = [...bundles, bundle];
    setBundles(bundlesListCopy);
    setSelectedBundles([]);
    setShowNewBundlePanel(false);
  };
  const classes = [
    { label: "Evidence", value: EVIDENCE_DOCUMENTS },
    { label: "Case Document", value: CASE_DOCUMENTS },
    { label: "Authority", value: AUTHORITIES_DOCUMENTS },
    { label: "Application Papers", value: APPLICATION_DOCUMENTS },
  ];
  const onDocClassChange = (selectedOption) => {
    setDocClass(selectedOption.value);
    setDocClassSelectedOption(selectedOption);
    if (selectedOption.value === APPLICATION_DOCUMENTS) {
      setShowApplicationSelect(true);
    } else {
      setShowApplicationSelect(false);
    }
    setHideBundles(false);
  };
  const onApplicationChange = (selectedOption) => {
    setApplicationId(selectedOption.value);
  };
  if (dataLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return showNewBundlePanel ? (
    <NewBundleForm
      idCase={caseId}
      addNewBundleToList={onClickSaveNewBundleHandler}
      noHideRSB
      setShowNewBundlePanel={setShowNewBundlePanel}
    />
  ) : (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div
          className="form-group"
          style={hideBundle ? { marginBottom: "60px" } : null}
        >
          <Label name={"Doc Class"} />
          <SelectAnotherTry
            options={classes}
            onChange={onDocClassChange}
            value={docClassSelectedOption}
          />
        </div>
        {!hideBundle ? (
          <Fragment>
            <div className="form-group">
              <Label name={"Assign to Bundle (Optional)"} />
              <SelectAnotherTry
                options={selects}
                isClearable={false}
                isMulti
                placeholder={"Select bundle"}
                value={selectedBundles}
                // defaultValue={selectedBundles}
                defaultValue={
                  selectedBundles && selectedBundles.length
                    ? selects.filter((v) => {
                        return selectedBundles.find((b) => b.id === v.value);
                      })
                    : []
                }
                reactSelectStyles={{
                  control: (provided) => ({
                    ...provided,
                    height: "unset !important",
                    minHeight: "42px !important",
                    borderRadius: "21px !important",
                  }),
                }}
                onChange={onBundleChange}
              />
            </div>
            <div
              className="form-group"
              style={showApplicationSelect ? null : { marginBottom: "60px" }}
            >
              <div className="container-fluid d-flex justify-content-center">
                <ButtonNew tertiary wide clickHandler={onClickAddBundleHandler}>
                  New Bundle
                </ButtonNew>
              </div>
            </div>
          </Fragment>
        ) : null}
        {showApplicationSelect ? (
          applicationsLoading ? (
            <div
              className="form-group d-flex justify-content-center align-items-center"
              style={{ marginBottom: "60px" }}
            >
              <PulseLoader size={15} color={theme.colors.blue} />
            </div>
          ) : (
            <div className="form-group" style={{ marginBottom: "60px" }}>
              <Label name={"Application"} />
              <Select
                classNamePrefix="Select"
                className="pl-3 pr-3"
                placeholder="Select Application"
                options={applications}
                onChangeHandler={onApplicationChange}
              />
            </div>
          )
        ) : null}
        <div className="form-group">
          <div className="container-fluid">
            <DashedDiv
              hoover={hoover}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={addFile}
            >
              <ElementDiv first>
                <img src={dragAndDrop} alt="" />
              </ElementDiv>
              <ElementDiv>
                <Bold18Font textColor={theme.colors.dark}>
                  Drag & Drop Documents here
                </Bold18Font>
              </ElementDiv>
              <ElementDiv last>
                <Medium18Font textColor={theme.colors.darkOpacity}>
                  Or
                </Medium18Font>
              </ElementDiv>
              <Button
                clickHandler={onClickHandler}
                loading={isLoading}
                disabled={isLoading || hideBundle}
                wide
                primary
              >
                Upload file
              </Button>
              <input
                ref={fileInput}
                multiple={true}
                type="file"
                accept={validTypes.join(",")}
                onChange={() => {
                  if (fileInput.current) {
                    handleFiles(fileInput.current.files);
                  }
                }}
                style={{ display: "none" }}
              />
            </DashedDiv>
          </div>
        </div>
      </div>
      <DividerHorizontal />
    </Fragment>
  );
};

AddNewDocumentForm.propTypes = {
  caseId: PropTypes.number.isRequired,
  hideBundle: PropTypes.bool,
  noSaveButton: PropTypes.bool,
};

export default AddNewDocumentForm;
