import styled from "styled-components/macro";

const UserNameDivStyled = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  margin-right: -20px;
  margin-left: -20px;
  cursor: pointer;
  @media (max-width: 991px) {
     margin-right: -15px;
      margin-left: -15px;
  }
      @media (max-width: 767px) {
    margin-right: -18px;
    margin-left: -18px;
  }
     @media (max-width: 575.98px) {
        margin-right: -10px;
    margin-left: -10px;
    }
`;
export default UserNameDivStyled;
