import React, { Fragment, useEffect, useState } from "react";
import { Bold18Font, Medium14Font } from "../FontsNewComponent/Fonts";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../redux/types";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ButtonNew from "../buttons/Button/ButtonNew";
import { handleErrorMessage } from "../Modal/modalErrorHandler";
import ExamLinesFOrm from "../forms/WitnessForms/ExamLinesForm";
import Table from "../Table";
import ListAddButton from "../buttons/ListAddButton/ListAddButton";
import LiStyled from "../Table/Styled/LiStyled";
import SelectAnotherTry from "../Select/SelectAnotherTry";

const ExamLines = ({
  setSubmit,
  exam_lines,
  objects,
  witnessObject,
  evidencesList,
  witnessId,
  isXExamFlowFalse,
}) => {
  const [checkedEvidences, setCheckedEvidences] = useState([]);
  const [checkedTopics, setCheckedTopics] = useState([]);
  const [checkedObjectives, setCheckedObjectives] = useState([]);
  const [selectedEvidence, setSelectedEvidence] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [data, setData] = useState([]);
  const [dataTopics, setDataTopics] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [dataEvidences, setDataEvidences] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState(null);
  const selectedTopicHandler = (select) => {
    if (typeof select.core_book === 'undefined') {
      setSelectedType("Topic");
    } else {
      setSelectedType("Evidence");
    }
    setSelectedValue(select);
    setSelectedEvidence(select);
  };
  const addTopic = async () => {
    setSelectedValue(null);
    if (selectedEvidence) {
      addLine(selectedEvidence, checkedObjectives, selectedType);
      setSelectedEvidence(null);
    } else {
      handleErrorMessage(`Select Topic or Evidence first`, dispatch);
    }
  };
  useEffect(() => {
    if (exam_lines && exam_lines.evidences) {
      let evidences = [];
      exam_lines.evidences.forEach((v) => {
        const i = evidencesList.find((e) => e.id === v);
        if (i) {
          evidences.push(i);
        }
      });
      setCheckedEvidences(evidences);
    }
    if (exam_lines && exam_lines.topics) {
      let topics = [];
      exam_lines.topics.forEach((v) => {
        const i = objects.find((e) => e.id === v);
        if (i) {
          topics.push(i);
        }
      });
      setCheckedTopics(topics);
    }
    let dataList = {};
    let topicDataList_ = {};
    objects
      .filter((v) => {
        if (exam_lines && exam_lines.topics) {
          return exam_lines.topics.indexOf(v.id) !== -1;
        } else {
          return false;
        }
      })
      .forEach((topic) => {
        topicDataList_[topic.id] = [...topic.objectives];
      });
    evidencesList
      .filter((v) => {
        if (exam_lines && exam_lines.evidences) {
          return exam_lines.evidences.indexOf(v.id) !== -1;
        } else {
          return false;
        }
      })
      .forEach((evidence) => {
        dataList[evidence.id] = [...evidence.objectives];
      });
    setData(evidencesList);
    setTopicsList(objects);
    setDataEvidences(dataList);
    setDataTopics(topicDataList_);
  }, [objects, exam_lines, witnessObject, evidencesList]);
  const updateExamLines = (object) => {
    setSubmit(object);
    setSelectedObjective(null);
  };
  const setObj = (object, isEvidence) => {
    let topics = [...witnessObject.topics];
    let evidences = [...witnessObject.evidences];
    let index = topics.findIndex((v) => v.id === object.id);
    let index_evidence = evidences.findIndex((v) => v.id === object.id);
    if (index !== -1) {
      topics[index] = object;
    }
    if (index_evidence !== -1) {
      evidences[index_evidence] = object;
    }
    setSubmit({ topics: topics, evidences: evidences });
    setSelectedObjective(null);
  };
  useEffect(() => {
    if (selectedObjective) {
      let item = objects.find(
        (v) =>
          (v.objectives &&
            v.objectives.filter((i) => i.id === selectedObjective).length)
      );
      if (!item) {
        item = evidencesList.find(
          (v) =>
            (v.objectives &&
              v.objectives.filter((i) => i.id === selectedObjective).length)
        );
      }
      let obj;
      if (item.evidences) {
        item.evidences.forEach((v) => {
          if (v.objectives) {
            v.objectives.forEach((i) => {
              if (i.id === selectedObjective) {
                obj = i;
              }
            });
          }
        });
      }
      if (!obj) {
        obj = item.objectives.find((i) => i.id === selectedObjective);
      }
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <ExamLinesFOrm
            key={obj.id}
            objectives={objectives}
            item={item}
            witnessObject={witnessObject}
            witnessId={witnessId}
            objectiveObject={obj}
            setObject={setObj}
            caseId={id}
            isXExamFlowFalse={isXExamFlowFalse}
          />
        ),
        title: "X-Exam Lines",
      });
    }
  }, [selectedObjective]);
  const addLine = (object, check_obj, type) => {
    let objectives_list = [];
    objects.forEach((object_) => {
      const new_l = object_.objectives.filter(
        (v) => check_obj.indexOf(v.id) !== -1
      );
      if (new_l.length) {
        objectives_list = [...objectives_list, ...new_l];
      }
    });
    let ev = [...checkedEvidences];
    let topics = [...checkedTopics]
    if (type === 'Evidence') {
      ev = [...ev, ...[object]];
    } else {
      topics = [...topics, ...[object]]
    }
    let examLines = {
      exam_lines: {
        evidences: ev.map((v) => v.id),
        topics: topics.map((v) => v.id),
      },
    };
    updateExamLines(examLines);
    if (object.objectives.length) {
      setSelectedObjective(object.objectives[0].id);
    }
  };
  const handleDelete = async (id) => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        let clone = Object.assign(exam_lines, {});
        clone.evidences = exam_lines.evidences.filter((v) => v !== id);
        clone.topics = exam_lines.topics.filter((v) => v !== id);
        updateExamLines({ exam_lines: clone });
        setCheckedEvidences(checkedEvidences.filter((v) => v.id !== id));
        setCheckedTopics(checkedTopics.filter((v) => v.id !== id));
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  const tableSettings = {
    clickAction: (obj) => {
      setSelectedObjective(obj.id);
    },
    fields: [
      {
        name: "objectives",
        label: "OBJECTIVES",
        renderer: (object) => {
          return <Medium14Font>{object.name}</Medium14Font>;
        },
      },
      {
        name: "exam_lines",
        label: "EXAM LINES",
        renderer: (object) => {
          if (object.exam_lines && object.exam_lines.length) {
            return (
              <ul>
                {object.exam_lines.map((item, idx) => {
                  return (
                    <LiStyled key={idx}>
                      <Medium14Font>{item.name}</Medium14Font>
                    </LiStyled>
                  );
                })}
              </ul>
            );
          } else {
            return (
              <ListAddButton
                className="d-flex"
                label="Add Exam Lines"
                clickHandler={() => null}
              />
            );
          }
        },
      },
    ],
  };
  const options = [...objects
    .filter((v) => {
      return !checkedTopics.find((v_) => v.id === v_.id);
    })
    .map((v) => {
      if (v.name && !v.label) {
        v.label = v.name;
      }
      v.value = v.id;
      return v;
    }), ...evidencesList
    .filter((v) => {
      return !checkedEvidences.find((v_) => v.id === v_.id);
    })
    .map((v) => {
      if (v.name && !v.label) {
        v.label = v.name;
      }
      v.value = v.id;
      return v;
    })]
  return (
    <Fragment>
      <div className="p-3 col-lg-12 col-md-12">
        <div className={"row mb-3"}>
          <div className={"col-lg-6 d-flex h-100 col-md-12"}>
            <SelectAnotherTry
              style={{ marginLeft: "-30px" }}
              placeholder={"Select Topic or Evidence"}
              options={options}
              value={selectedValue}
              onChange={selectedTopicHandler}
            />
            <ButtonNew
              loading={updating}
              disabled={updating}
              primary
              clickHandler={addTopic}
            >
              Add
            </ButtonNew>
          </div>
        </div>
      </div>
      <div className="p-3 col-md-12">
        {data
          .filter((v) => {
            if (exam_lines && exam_lines.evidences) {
              return exam_lines.evidences.indexOf(v.id) !== -1;
            } else {
              return false;
            }
          })
          .map((evidence) => {
            return (
              <div
                key={`${JSON.stringify(evidence.objectives)}${
                  evidence.label ?? evidence.name
                }`}
              >
                <div className={"row"}>
                  <p className="d-flex align-items-center">
                    <Bold18Font className="mr-3">
                      {evidence.label ?? evidence.name}
                    </Bold18Font>
                    <ButtonNew
                      clickHandler={() => {
                        handleDelete(evidence.id);
                      }}
                      secondary
                      delete_
                    >
                      Delete
                    </ButtonNew>
                  </p>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Table
                      withoutMargin={true}
                      selectedId={selectedObjective}
                      data={dataEvidences[evidence.id] ?? []}
                      settings={tableSettings}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="p-3 col-md-12">
        {topicsList
          .filter((v) => {
            if (exam_lines && exam_lines.topics) {
              return exam_lines.topics.indexOf(v.id) !== -1;
            } else {
              return false;
            }
          })
          .map((topic) => {
            return (
              <div
                key={`${JSON.stringify(topic.objectives)}${
                  topic.label ?? topic.name
                }`}
              >
                <div className={"row"}>
                  <p className="d-flex align-items-center">
                    <Bold18Font className="mr-3">
                      {topic.label ?? topic.name}
                    </Bold18Font>
                    <ButtonNew
                      clickHandler={() => {
                        handleDelete(topic.id);
                      }}
                      secondary
                      delete_
                    >
                      Delete
                    </ButtonNew>
                  </p>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <Table
                      selectedId={selectedObjective}
                      data={dataTopics[topic.id] ?? []}
                      settings={tableSettings}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

ExamLines.defaultProps = {
  isXExamFlowFalse: true,
};

export default ExamLines;
