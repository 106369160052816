import styled from "styled-components/macro";
import { fadeIn } from "react-animations";
import { keyframes } from "styled-components";

const bounceAnimation = keyframes`${fadeIn}`;

const FreeLicensePlanCardStyled = styled.div`
  //width: 156px;
  width: 100%;
  height: 143px;
  border-radius: 4px;
  border: 1px solid
    ${({ theme, checked }) =>
      !checked ? theme.colors.gray : theme.colors.blue};
  background: ${({ theme }) => theme.colors.white};
  animation: 1s ${bounceAnimation};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ noPointer }) => (noPointer ? null : "cursor: pointer")};
  ${({ withPriceLayout }) => (withPriceLayout ? "padding-left: 30px" : null)};
`;

export default FreeLicensePlanCardStyled;
