import React, { useContext, useState } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { Container } from "./styled";

import separatorIcon from "@/assets/img/notebook/toolbar/separator.svg";

import Undo from "./actions/Undo";
import Redo from "./actions/Redo";
import Color from "./actions/Color";
import Highlight from "./actions/Highlight";
import FontFamily from "./actions/FontFamily";
import FontSize from "./actions/FontSize";

import Bold from "./actions/Bold";
import Italic from "./actions/Italic";
import Underline from "./actions/Underline";
import TextAlign from "./actions/TextAlign";
import OrderedList from "./actions/OrderedList";
import UnorderedList from "./actions/UnorderedList";
import Table from "./actions/Table";
import Image from "./actions/Image";

const Separator = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      flex: 0.3,
      // margin: "0 8px",
    }}
  >
    <img src={separatorIcon} alt="" />
  </div>
);

const Toolbar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <Container className="d-flex flex-row" style={{ fontSize: 14 }}>
      <Undo editor={editor} />
      <Redo editor={editor} />
      <Separator />
      <Color editor={editor} />
      <Separator />
      <Highlight editor={editor} />
      <FontFamily editor={editor} />
      <FontSize editor={editor} />
      {/*
       */}
      <Separator />
      <Bold editor={editor} />
      <Italic editor={editor} />
      <Underline editor={editor} />
      <TextAlign editor={editor} />
      <OrderedList editor={editor} />
      <UnorderedList editor={editor} />
      <Table editor={editor} />
      <Image editor={editor} />
    </Container>
  );
};

export default Toolbar;
