import { default as evidence } from "./evidence";
import { default as witness } from "./witness";
import { default as topic } from "./topic";
import { default as objective } from "./objective";
import { default as comment } from "./comment";
import { default as authority } from "./authority";
import { default as section } from "./section";
import { default as exam } from "./exam";
import { default as party } from "./party";

export { default as evidence } from "./evidence";
export { default as witness } from "./witness";
export { default as topic } from "./topic";
export { default as objective } from "./objective";
export { default as comment } from "./comment";
export { default as authority } from "./authority";
export { default as section } from "./section";
export { default as exam } from "./exam";
export { default as party } from "./party";

export default [
  {
    label: "evidence",
    extension: evidence,
  },
  {
    label: "witness",
    extension: witness,
  },
  {
    label: "topic",
    extension: topic,
  },
  {
    label: "objective",
    extension: objective,
  },
  {
    label: "comment",
    extension: comment,
  },
  {
    label: "authority",
    extension: authority,
  },
  {
    label: "section",
    extension: section,
  },
  {
    label: "exam",
    extension: exam,
  },
  {
    label: "party",
    extension: party,
  },
];
