import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import PulseLoader from "react-spinners/PulseLoader";

import uniquePropsCheck from "./utils/uniquePropsCheck";

import ButtonStyled from "./styled/ButtonStyled";
import PrimaryButtonStyled from "./styled/PrimaryButtonStyled";
import SecondaryButtonStyled from "./styled/SecondaryButtonStyled";
import DangerButtonStyled from "./styled/DangerButtonStyled";

import { Bold14Font } from "../../Fonts/Fonts";
import TertiaryButtonStyled from "./styled/TertiaryButtonStyled";
import DeleteIcon from "../../../assets/img/svg/deleteIcon.svg";

/**
 * Base button component. Have three basic designs, but can be customized.
 */
const Button = ({
  primary,
  secondary,
  delete_,
  tertiary,
  danger,
  customStyle,
  wide,
  children,
  clickHandler,
  type,
  loading,
  styles,
  withIcon,
  theme,
  disabled,
  ...props
}) => {
  return (
    <Fragment>
      {/* Primary btn render logic */}
      {primary && !secondary && !danger && !customStyle ? (
        <ButtonStyled
          as={PrimaryButtonStyled}
          wide={wide}
          disabled={disabled}
          onClick={clickHandler}
          type={type}
          {...props}
        >
          {loading ? (
            <PulseLoader size={7} color={theme.colors.white} />
          ) : (
            <Bold14Font
              textColor={theme.colors.white}
              className="d-flex justify-content-center align-items-center"
            >
              {children}
            </Bold14Font>
          )}
        </ButtonStyled>
      ) : null}

      {/* Secondary btn render logic */}
      {!primary && secondary && !danger && !customStyle ? (
        <ButtonStyled
          as={SecondaryButtonStyled}
          wide={wide}
          disabled={disabled}
          onClick={clickHandler}
          type={type}
          {...props}
        >
          {delete_ && !loading ? (
            <img
              alt={"delete"}
              style={{ marginRight: "10px", paddingBottom: "4px" }}
              src={DeleteIcon}
            />
          ) : null}
          {loading ? (
            <PulseLoader size={7} color={theme.colors.blue} />
          ) : (
            // <Bold14Font
            //   className={withIcon ? "d-flex align-items-center" : null}
            //   textColor={theme.colors.blue}
            // >
            <Fragment><div style={{color: theme.colors.blue, display: 'flex'}}>{children}</div></Fragment>
            // </Bold14Font>
          )}
        </ButtonStyled>
      ) : null}

      {/* Tertiary btn render logic */}
      {!primary && !secondary && tertiary && !danger && !customStyle ? (
        <ButtonStyled
          as={TertiaryButtonStyled}
          onClick={clickHandler}
          type={type}
          {...props}
        >
          {loading ? (
            <PulseLoader size={7} color={theme.colors.blue} />
          ) : (
            <Bold14Font textColor="inherit">{children}</Bold14Font>
          )}
        </ButtonStyled>
      ) : null}

      {/* danger btn render logic */}
      {!primary && !secondary && danger && !customStyle ? (
        <ButtonStyled
          as={DangerButtonStyled}
          onClick={clickHandler}
          type={type}
          {...props}
        >
          {" "}
          {loading ? (
            <PulseLoader size={7} color={theme.colors.white} />
          ) : (
            <Bold14Font textColor={theme.colors.white}>{children}</Bold14Font>
          )}
        </ButtonStyled>
      ) : null}

      {/* Custom styled button render logic */}
      {!primary && !secondary && !danger && customStyle ? (
        <ButtonStyled
          as={customStyle}
          onClick={clickHandler}
          type={type}
          {...props}
        >
          {children}
        </ButtonStyled>
      ) : null}
    </Fragment>
  );
};

const uniqueProps = {
  primary: PropTypes.bool,
  tertiary: PropTypes.bool,
  secondary: PropTypes.bool,
  danger: PropTypes.bool,
  delete_: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyle: PropTypes.oneOfType([PropTypes.element, () => null]),
};

Button.propTypes = {
  /**
   * Use Primary style of button
   */
  primary: (props, propName, componentName) =>
    uniquePropsCheck(props, propName, componentName, uniqueProps),
  /**
   * Use Secondary style of button
   */
  secondary: (props, propName, componentName) =>
    uniquePropsCheck(props, propName, componentName, uniqueProps),

  /**
   * Use Tertiary style of button
   */
  tertiary: (props, propName, componentName) =>
    uniquePropsCheck(props, propName, componentName, uniqueProps),

  /**
   * Use danger style of button
   */
  danger: (props, propName, componentName) =>
    uniquePropsCheck(props, propName, componentName, uniqueProps),

  /**
   * Custom button style. Must be styled-component.
   */
  customStyle: PropTypes.oneOfType([PropTypes.element, () => null]),

  /**
   * Make button width 100%
   */
  wide: PropTypes.bool,

  /**
   * Set button content
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),

  /**
   * Function that triggered on click
   */
  clickHandler: PropTypes.func.isRequired,

  /**
   * Button type: "submit" or "reset". Used in forms.
   */
  type: PropTypes.oneOf(["submit", "reset"]),
};

Button.defaultProps = {
  primary: false,
  secondary: false,
  delete_: false,
  danger: false,
  customStyle: null,
  wide: false,
};

export default withTheme(Button);
