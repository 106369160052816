import React, { useState } from "react";
import styled from "styled-components";

import caseLegendIcon from "@/assets/img/notebook/case-legend.svg";
import exportIcon from "@/assets/img/notebook/export.svg";

import CaseLegendPanel from "./CaseLegendPanel";

const Container = styled.div`
  display: flex;
  flex-direction: "row";
`;

const Button = styled.button`
  position: relative;
  color: #374ac2;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  border: 1px solid #cbcfee;
  border-radius: 24px;
  padding: 8px 15px;
  margin-bottom: 15px;
  margin-right: 15px;
  position: relative;
`;

const Icon = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Actions = () => (
  <Container>
    {/*
    <Export />
    */}
    <Legend />
  </Container>
);

const Legend = () => {
  const [showLegend, setShowLegend] = useState(false);

  return (
    <>
      <Button onClick={() => setShowLegend(!showLegend)}>
        <Icon src={caseLegendIcon} alt="Case legend" />
        <span>Legend</span>
      </Button>
      {showLegend && <CaseLegendPanel close={() => setShowLegend(false)} />}
    </>
  );
};

const Export = () => (
  <Button onClick={() => alert("Export here")}>
    <Icon src={exportIcon} alt="Case legend" />
    <span>Export</span>
  </Button>
);

export default Actions;
