import React, {Fragment, useEffect, useRef, useState} from "react";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Label from "../AddCardForm/Label";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import {HIDE_RIGHT_SIDE_BAR} from "../../../redux/types";
import {useDispatch} from "react-redux";
import TextAreaSideBar from "./TextAreaSideBar";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {updateEvidence} from "../../../containers/Auth/auth";
import {DividerHorizontal} from "../../dividers/DividerHorizontal";

const EvidenceNotesForm = ({
                             evidenceObject,
                             setEvidenceObject,
                             saveToAPIMethod,
                             withOutnotes,
                             ...props
                           }) => {
  const [object_, setObject] = useState(evidenceObject);
  let editableStringsRef = useRef([]);

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  // create first objective string if no notes
  useEffect(() => {
    if (!withOutnotes) {
      if (object_.notes && !object_.notes.length) {
        setObject((prevState) => ({
          ...prevState,
          notes: [{content: ""}],
        }));
      }
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submitObject = {
      preempt: object_.preempt,
      summary: object_.summary,
    };
    if (!withOutnotes) {
      submitObject.notes = object_.notes.filter(
        (el) => el.content !== ""
      );
    }
    let resp = submitObject;
    if (saveToAPIMethod) {
      resp = await updateEvidence(
        evidenceObject.id,
        evidenceObject.case.id,
        dispatch,
        submitObject
      );
    }
    if (resp) {
      setEvidenceObject(resp);
      dispatch({type: HIDE_RIGHT_SIDE_BAR});
    }
  };
  const dispatch = useDispatch();

  const deleteNote = (index) => {
    setObject({
      ...object_,
      ...{
        notes: object_.notes.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };

  const addNote = async (e) => {
    let notes;
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      if (object_.notes && !object_.notes.length) {
        notes = [{content: ""}];
      } else {
        notes = [...object_.notes, {content: ""}];
      }
      setObject({
        ...object_,
        ...{notes},
      });
    }
  };

  const editNote = (index, content) => {
    let notes = object_.notes;
    notes[index].content = content;
    setObject({
      ...object_,
      ...{notes},
    });
  };

  return (
    <Fragment>
      <div style={{minHeight: "92px", height: "100%", overflowY: "auto"}}>
        <div className="form-group">
          {object_.notes && object_.notes.length ? (
            <Fragment>
              <AnimatedDiv className="form-group mb-0">
                <Label className={"row"} name={"notes"}/>
              </AnimatedDiv>
              {object_.notes.map((sub_t, i) => (
                <AnimatedDiv key={i} className="form-group pl-3">
                  <EditableStringClassComponent
                    editName={editNote}
                    Note={true}
                    type={'textarea'}
                    editState={sub_t.content === ""}
                    deleteAction={deleteNote}
                    object={sub_t.content}
                    index={i}
                    ref={(ref) => assignRef(ref, i)}
                  />
                </AnimatedDiv>
              ))}
            </Fragment>
          ) : null}
          <div className="form-group">
            <div className="container-fluid">
              <ListAddButton
                className="d-flex"
                label="Add Note"
                clickHandler={addNote}
              />
            </div>
          </div>
        </div>
      </div>
      <DividerHorizontal/>
      <div className="form-group">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <ButtonNew
              type="submit"
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EvidenceNotesForm;
