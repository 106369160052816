import React, { useCallback } from "react";
import styled from "styled-components";

import imageIcon from "@/assets/img/notebook/toolbar/toolbar-image.svg";

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #dcdce6;
  padding: 4px 12px;
  border-radius: 100px;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-top: 1px;
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const Text = styled.span`
  margin-left: 4px;
  font-weight: 500;
  color: #0f122f;
`;

const Image = ({ editor }) => {
  const addImage = useCallback(() => {
    const url = window.prompt("Enter URL:", "");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  return (
    <Container onClick={addImage}>
      <Icon
        src={imageIcon}
        alt=""
        className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
      />
      <Text>Image</Text>
    </Container>
  );
};

export default Image;
