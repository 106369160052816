import styled from "styled-components/macro";

const DivAddCardStyled = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 3px dashed #dcdce6;
  padding: 15px 15px 15px 15px;
`;

export default DivAddCardStyled;
