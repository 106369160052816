import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import SideBarItemStyled from "./SideBarItemStyled";
import { Bold18Font } from "../FontsNewComponent/Fonts";

const SideBar = ({ items, activeItem, setActiveItem, theme }) => {
  return (
    <Fragment>
      {items.map((v, i) => {
        const is_active = activeItem === i;
        return (
          <SideBarItemStyled
            onClick={() => {
              setActiveItem(i);
            }}
            key={i}
            first={i === 0}
            last={items.length - 1 === i}
            active={is_active}
          >
            <Bold18Font
              textColor={
                is_active ? theme.colors.blue : theme.colors.darkOpacity
              }
            >
              {v}
            </Bold18Font>
          </SideBarItemStyled>
        );
      })}
    </Fragment>
  );
};

SideBar.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.number.isRequired,
  setActiveItem: PropTypes.func.isRequired,
};

export default withTheme(SideBar);
