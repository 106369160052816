import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const TestimonialCardStyled = styled.div.attrs({ className: "item" })`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background: ${({ theme }) => theme.colors.lightGray};
  position: relative;
`;

export default TestimonialCardStyled;
