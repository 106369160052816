import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../buttons/Button";
import BreadCrumbsStyled from "./styled/BreadCrumbsStyled";

import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import { useSelector } from "react-redux";
import BreadcrumbsButtonStyled from "../../buttons/Button/styled/BreadcrumbsButtonStyled";
import houseIcon from "../../../assets/img3/svg/house-icon.svg";
import breadcrumbArrowRightIcon from "../../../assets/img3/svg/breadcrumb-arrow-right-icon.svg";

const BreadCrumbs = () => {
  const history = useHistory();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.data);
  const isAdmin = useSelector((state) => state.auth.user.is_admin);

  const breadCrumbClickHandler = (breadcrumb, index) => {
    history.push(breadcrumb.path);
  };

  return (
    // <div className="container-fluid pt-3 pl-0 pr-0">
    <div className="row my-3">
      <div className="col d-flex">
        <BreadCrumbsStyled>
          {breadcrumbs.length ? (
            breadcrumbs.map((breadcrumb, i, array) => {
              // If first item
              if (i === 0 && array.length - 1 === 0) {
                return (
                  <Fragment key={i}>
                    <Button
                      // key={i}
                      style={{ display: "contents" }}
                      customStyle={BreadcrumbsButtonStyled}
                      clickHandler={() => breadCrumbClickHandler(breadcrumb, i)}
                    >
                      <Bold14Font style={{ fontSize: "0.975rem" }}>
                        {breadcrumb.title}
                      </Bold14Font>
                    </Button>
                  </Fragment>
                );
              } else if (i === array.length - 1) {
                return (
                  <Fragment key={i}>
                    <Button
                      style={{ display: "contents" }}
                      // key={i}
                      customStyle={BreadcrumbsButtonStyled}
                      clickHandler={() => breadCrumbClickHandler(breadcrumb, i)}
                    >
                      <Bold14Font style={{ fontSize: "0.975rem" }}>
                        {breadcrumb.title}
                      </Bold14Font>
                    </Button>
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={i}>
                    <Button
                      style={{ display: "contents" }}
                      // key={i}
                      customStyle={BreadcrumbsButtonStyled}
                      clickHandler={() => breadCrumbClickHandler(breadcrumb, i)}
                    >
                      <Medium14Font style={{ fontSize: "0.975rem" }}>
                        {breadcrumb.title}
                      </Medium14Font>
                    </Button>
                    <img
                      src={breadcrumbArrowRightIcon}
                      alt=""
                      className="img-fluid mx-2"
                      style={{ height: "9px" }}
                    />
                  </Fragment>
                );
              }
            })
          ) : (
            <div className="mb-5 mt-3" />
          )}
          {/*<img*/}
          {/*  src={houseIcon}*/}
          {/*  alt=""*/}
          {/*  width={16}*/}
          {/*  height={16}*/}
          {/*  className="img-fluid mr-2"*/}
          {/*  onClick={() => {*/}
          {/*    if (isAdmin) {*/}
          {/*      history.push("/admin/dashboard");*/}
          {/*    } else {*/}
          {/*      history.push("/app");*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  style={{ cursor: "pointer" }}*/}
          {/*/>*/}
        </BreadCrumbsStyled>
      </div>
    </div>
    // </div>
  );
};

export default BreadCrumbs;
