import {
  RESET_BREADCRUMBS,
  UPDATE_BREADCRUMBS,
  SET_FIRST_PAGE,
  SET_LAST_PAGE,
} from "../types";

const initialState = {
  data: [],
  first_page: [],
};

const breadcrumbsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BREADCRUMBS:
      if (
        state.first_page.length &&
        action.payload &&
        action.payload.length === 1 &&
        action.payload[0].title === "TrialProofer"
      ) {
        return state;
      }
      return {
        ...state,
        data: state.first_page
          ? state.first_page.concat(action.payload)
          : action.payload,
      };
    case SET_FIRST_PAGE:
      return {
        ...state,
        first_page: action.payload,
      };

    case SET_LAST_PAGE:
      const dataCopy = [...state.data];
      dataCopy.splice(dataCopy.length - 1, 1);
      return {
        ...state,
        data: [...dataCopy, action.payload],
      };

    case RESET_BREADCRUMBS:
      return {
        ...state,
        data: state.first_page,
      };

    default:
      return state;
  }
};

export default breadcrumbsReducer;
