import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext } from "@/context";
import { useDispatch } from "react-redux";
import axios from "axios";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import { loadAccessToken } from "@/containers/Auth/auth";

import { Container, ButtonContainer } from "./styled";
import { HIDE_RIGHT_SIDE_BAR } from "@/redux/types";

export default (props) => {
  const dispatch = useDispatch();
  const [label, setLabel] = useState(props.id);
  const [loading, setLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  useEffect(() => {
    const onEnter = (e) => {
      if (e.key === "Enter") {
        saveResult(label);
      }
    };

    window.addEventListener("keydown", onEnter);
    return () => window.removeEventListener("keydown", onEnter);
  }, [label]);

  const caseId = window.location.pathname.split("/")[3];

  // import { useHistory, useParams } from "react-router-dom";
  // const { id } = useParams();

  const saveResult = async (name) => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let tagType = props.tagType.toLowerCase();

    // key might be: name | label | title
    let key = "name";
    if (tagType === "authority") key = "title";
    if (tagType === "application") key = "label";
    if (tagType === "evidence") key = "label";

    let body = {
      [key]: name,
    };

    if (tagType === "examline") {
      body = {
        ...body,
        objectives: [33, 25, 27],
        // objectives: ["objective_exam_1", "objective_exam_2"],
        // objectives: [
        //   {
        //     name: "objective_exam_1",
        //   },
        //   {
        //     name: "objective_exam_2",
        //   },
        // ],
      };
    }

    // change object type to plural
    if (tagType === "application") tagType = "applications";
    if (tagType === "issue") tagType = "issues";
    if (tagType === "party") tagType = "parties";
    if (tagType === "theme") tagType = "themes";
    if (tagType === "topic") tagType = "topics";
    if (tagType === "causesofaction") tagType = "cause-of-action";
    if (tagType === "examline") tagType = "exam-lines";

    if (tagType === "paper") {
      alert("paper is not supported");
      return;
      // endpoint = `/api/cases/${caseId}/applications/{id_application}/papers/{id}`;
    }

    if (tagType === "cause-of-action") {
      body = {
        ...body,
        id_type: 18,
      };
    }

    if (tagType === "issues") {
      body = { ...body, key_facts: [] };
    }

    if (tagType === "themes") {
      body = { ...body, sub_themes: [] };
    }

    let endpoint = `/api/cases/${caseId}/${tagType}/${props.tagId}`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    // here we update editor state

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: label },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  return (
    <Container className="d-flex flex-column">
      <div className="form-group row">
        <div className="col-12">
          <InputNew
            name="name"
            type="text"
            placeholder="Enter tag name"
            label="Name"
            value={label}
            onChangeHandler={(e) => setLabel(e.target.value)}
            // autoFocus
          />
        </div>
      </div>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult(label);
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};
