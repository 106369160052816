import styled from "styled-components/macro";
import rem from "../../../utils/rem";
import {MODAL_CREATE_KEY_SECTION, MODAL_DELETE_KEY_SECTION} from "../../../redux/types";

const ModalStyled = styled.div`
  width: ${({ type}) => `${type === MODAL_DELETE_KEY_SECTION || type === MODAL_CREATE_KEY_SECTION ? rem(`250px`) : rem(`570px`)}`};
  //height: ${rem(`219px`)};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  //top: 30%;
  //left: 34%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 210;

  @media (max-width: 575.98px) {
    width: unset;
  }

  body {
    overflow: unset;
  }
`;

export default ModalStyled;
