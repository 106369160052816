import React from "react";
import PropTypes from "prop-types";
import ListActionButtonStyled from "./Styled/ListActionButtonStyled";
import pencilIcon from "../../../assets/img3/svg/pancil_icon.svg";
import deleteIcon from "../../../assets/img3/svg/delete_icon.svg";
import blockIcon from "../../../assets/img/svg/blockIcon.svg";
import unlockIcon from "../../../assets/img/svg/unlockIcon.svg";
import yesIcon from "../../../assets/img3/svg/yes_icon.svg";
import threeDotIcon from "../../../assets/img3/svg/three-dot-icon.svg";
import preview from "../../../assets/img/svg/preview.svg";
import labelIcon from "../../../assets/img3/svg/label-tags-icon.svg";

export default function ListAddButton(props) {
  let { type, clickHandler } = props;
  let icon;
  switch (type) {
    case "delete":
      icon = deleteIcon;
      break;
    case "edit":
      icon = pencilIcon;
      break;
    case "done":
      icon = yesIcon;
      break;
    case "block":
      icon = blockIcon;
      break;
    case "unblock":
      icon = unlockIcon;
      break;
    case "options":
      icon = threeDotIcon;
      break;
    case "preview":
      icon = preview;
      break;
    case "label":
      icon = labelIcon;
  }
  return (
    <ListActionButtonStyled
      onClick={clickHandler}
      className={
        "d-flex justify-content-center align-items-center mr-2 " +
        props.className
      }
    >
      <img src={icon} alt={type} width={14} height={14} />
    </ListActionButtonStyled>
  );
}
ListAddButton.propTypes = {
  type: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
};
