import styled from "styled-components/macro";

const LiTStyled = styled.li`
  list-style: none;
  :before {
    content: "-";
    color: ${({theme}) => theme.colors.blue};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 14px;
    margin-left: -1em;
  }
`;

export default LiTStyled;
