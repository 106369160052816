import React, { Fragment } from "react";
import PropTypes from "prop-types";
import modalSuccessIcon from "../../assets/img3/svg/modal-success-icon.svg";
import { Bold18Font, Medium14Font } from "../Fonts/Fonts";
import Button from "../buttons/Button/Button";
import { RESET_MODAL } from "../../redux/types";
import { useDispatch } from "react-redux";

const ModalWrapper = ({
  header,
  description,
  successIcon,
  buttons,
  buttonsLoading,
}) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="container-fluid p-4">
        <div className="row mb-3">
          <div className="col d-flex align-items-center">
            {successIcon ? (
              <img src={modalSuccessIcon} alt="" className="img-fluid mr-2" />
            ) : null}
            <Bold18Font>{header}</Bold18Font>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {typeof description === "string" ? (
              <Medium14Font as="p">{description}</Medium14Font>
            ) : (
              <Medium14Font as="div">{description}</Medium14Font>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end">
            {buttons.map((el, i) => (
              <Button
                key={i}
                primary
                clickHandler={() => el.clickHandler(dispatch)}
                loading={buttonsLoading}
                disabled={buttonsLoading}
                style={{ marginLeft: "10px" }}
              >
                {el.type}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ModalWrapper.propTypes = {
  header: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttons: PropTypes.arrayOf(PropTypes.object),
  buttonsLoading: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  buttons: [
    {
      type: "Okay",
      clickHandler: (dispatch) => {
        dispatch({ type: RESET_MODAL });
      },
    },
  ],
  buttonsLoading: false,
};

export default ModalWrapper;
