import React, { Fragment, useEffect, useRef, useState } from "react";
import { withTheme } from "styled-components/macro";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  deleteBundle,
  getBundles,
  updateBundle,
} from "../../../containers/Auth/auth";
import { useDispatch } from "react-redux";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import PulseLoader from "react-spinners/PulseLoader";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import { Bold14Font, Bold18Font } from "../../FontsNewComponent/Fonts";
import NoRecords from "../../NoRecords";

const mapEvidenceClassToText = {
  "App\\Cases": "Cases",
  "App\\Evidence": "Evidence",
  "App\\Authority": "Authority",
  "App\\Application": "Application",
  "App\\Papers": "Application Papers",
  "App\\Other": "Other",
};

const EditBundlesForm = ({ object, reloadDocuments, idCase, theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bundlesList, setBundlesList] = useState([]);
  const [uniqueClassesList, setUniqueClassesList] = useState([]);
  const [bundlesByClasses, setBundlesByClasses] = useState({});
  const [lastEditedBundle, setLastEditedBundle] = useState({});
  const dispatch = useDispatch();
  let editableStringsRef = useRef({});

  const saveData = async (resp) => {
    if (resp) {
      setBundlesList(resp);

      const uniqueBundlesClassesList = [];
      resp.forEach((bundle) => {
        if (uniqueBundlesClassesList.indexOf(bundle.class_name) === -1) {
          uniqueBundlesClassesList.push(bundle.class_name);
        }
      });
      setUniqueClassesList(uniqueBundlesClassesList);

      const groupedByClasses = {};
      uniqueBundlesClassesList.forEach((class_) => {
        groupedByClasses[class_] = resp.filter(
          (bundle) => bundle.class_name === class_
        );
      });
      setBundlesByClasses(groupedByClasses);
      await reloadDocuments();
    }
  };

  const getData = async () => {
    const resp = await getBundles(dispatch, idCase);
    saveData(resp);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!bundlesList.length && isLoading) {
      getData();
    } else {
      saveData(bundlesList);
    }
  }, [bundlesList]);

  const editBundleName = async (index, inputValue, class_) => {
    const bundlesByClassesCopy = { ...bundlesByClasses };
    const originalBundle = bundlesByClassesCopy[class_][index];
    const editedBundle = { ...originalBundle, label: inputValue };
    bundlesByClassesCopy[class_].splice(index, 1, editedBundle);
    setLastEditedBundle(editedBundle);
    setBundlesByClasses(bundlesByClassesCopy);
  };

  const doneEditingBundleLabelHandler = async () => {
    setIsSubmitting(true);
    if (Object.keys(lastEditedBundle).length) {
      const resp = await updateBundle(
        lastEditedBundle.id,
        dispatch,
        lastEditedBundle
      );
      if (resp) {
        await reloadDocuments();
      }
      setIsSubmitting(false);
    }
  };

  // Check all strings, and if them in editing state - set its edit state false
  const closeAllEditingStrings = () => {
    uniqueClassesList.forEach((class_) => {
      editableStringsRef.current[class_].forEach((editableStringComponent) => {
        if (editableStringComponent.state.edit === true) {
          editableStringComponent.doneEditing();
        }
      });
    });
  };

  const handleClosePanel = () => {
    closeAllEditingStrings();
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const deleteBundleHandler = async (index, class_) => {
    const bundleIdToDelete = bundlesByClasses[class_][index].id;
    const resp = await deleteBundle(bundleIdToDelete, dispatch);
    if (resp) {
      await reloadDocuments();
      setBundlesList((prevState) => [
        ...prevState.filter((bundle) => bundle.id !== bundleIdToDelete),
      ]);
    }
  };

  const beforeDeleteBundleModal = (index, class_) => {
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteBundleHandler(index, class_);
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  const assignRef = (ref, index, class_) => {
    if (!(class_ in editableStringsRef.current)) {
      editableStringsRef.current[class_] = [];
    }
    return (editableStringsRef.current[class_][index] = ref);
  };

  if (isLoading && !bundlesList.length) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  } else if (!isLoading && !bundlesList.length) {
    return (
      <div style={{ height: "100%" }}>
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Your Bundles will appear here
          </Bold18Font>
        </NoRecords>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}>
          {uniqueClassesList.map((class_, idx) => (
            <div className="form-group" key={idx}>
              <div className="container-fluid">
                <Bold14Font>{mapEvidenceClassToText[class_]}</Bold14Font>
                {bundlesByClasses[class_].map((bundle, index) => (
                  <div style={{ marginBottom: "4px" }}>
                    <EditableStringClassComponent
                      key={bundle.id}
                      object={bundlesByClasses[class_][index].label}
                      index={index}
                      editName={(index, inputValue) =>
                        editBundleName(index, inputValue, class_)
                      }
                      startEditingCallback={closeAllEditingStrings}
                      doneEditingCallback={doneEditingBundleLabelHandler}
                      deleteAction={(index) =>
                        beforeDeleteBundleModal(index, class_)
                      }
                      editState={bundle.label === ""}
                      ref={(ref) => assignRef(ref, index, class_)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="form-group">
          <div className="container-fluid">
            <ButtonNew
              wide
              primary
              loading={isSubmitting}
              disabled={isSubmitting}
              clickHandler={handleClosePanel}
            >
              Close
            </ButtonNew>
          </div>
        </div>
      </Fragment>
    );
  }
};
export default withTheme(EditBundlesForm);
