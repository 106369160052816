import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import CasesMenuStyled from "./styled/CasesMenuStyled";
import Button from "../../buttons/Button";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium30Font,
} from "../../FontsNewComponent/Fonts";
import Badge from "../../Badge";
import BadgeCasesMenuStyled from "../../Badge/styled/BadgeCasesMenuStyled";
import { useDispatch, useSelector } from "react-redux";
import { addCase } from "../../../containers/Auth/auth";
import { useHistory } from "react-router-dom";
import { route } from "../../../routes";
import { theme } from "../../../styled-components/Theme/Theme";
import plusIcon from "../../../assets/img3/svg/button-plus-icon.svg";
import { checkIfUserHaveSubscription } from "../../../containers/Auth/utils";

const CasesMenu = (props) => {
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const user = useSelector((state) => state.auth.user);

  const addNewCase = async () => {
    setCreating(true);
    try {
      history.push(
        `${is_admin ? route.admin.baseApp.cases_create : route.baseApp.cases_create}`);
    } catch (e) {}
    setCreating(false);
  };

  return (
    <Fragment>
      {/*<div className="container-fluid">*/}
      {/*<div className="row justify-content-between align-items-center mb-3">*/}
      <div className="row">
        <div className="col">
          <CasesMenuStyled
            className={`d-flex flex-wrap justify-content-between ${props.className}`}
          >
            {/*<div className="d-flex mb-3">*/}
            {/*<div className="d-flex flex-wrap justify-content-end justify-content-sm-center mb-3">*/}
            <div className="d-flex flex-wrap justify-content-end justify-content-sm-center">
              {props.casesMenu.map((el, i) => (
                <div
                  onClick={() => {
                    props.getType(el.activeType);
                  }}
                  className={i > 0 ? "d-flex ml-4 mb-1" : "d-flex mb-1"}
                  key={i}
                >
                  <div
                    className={`menu-item mr-2 ${
                      el.activeType === props.active ? "menu-item__active" : ""
                    }`}
                  >
                    {el.activeType === props.active ? (
                      <props.activeFont
                        style={{
                          color: theme.colors.blue,
                        }}
                      >
                        {el.text}
                      </props.activeFont>
                    ) : (
                      <props.font
                        style={{
                          color: theme.colors.darkOpacity,
                        }}
                      >
                        {el.text}
                      </props.font>
                    )}
                  </div>
                  {props.noBadges ? null : (
                    <Badge as={BadgeCasesMenuStyled}>
                      <Bold18Font textColor="#848695">{el.amount}</Bold18Font>
                    </Badge>
                  )}
                </div>
              ))}
            </div>
            <div className="">
              {is_admin || user.role === 'assistant' ? null : props.noButton ? null : (
                <Button
                  loading={creating}
                  disabled={creating}
                  clickHandler={addNewCase}
                  primary
                >
                  <div className="d-flex align-items-center">
                    <img src={plusIcon} alt="" className="img-fluid mr-2" />
                    <Bold14Font style={{ color: "inherit" }}>
                      Add New Case
                    </Bold14Font>
                  </div>
                </Button>
              )}
              {props.customButton ?? null}
            </div>
          </CasesMenuStyled>
        </div>
      </div>
      {/*</div>*/}
    </Fragment>
  );
};

CasesMenu.propTypes = {
  /**
   * Current active item
   */
  active: PropTypes.string.isRequired,
  /**
   * Array of objects to render. Must have text field and active type field.
   */
  casesMenu: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  /**
   * TODO: Need write docs
   */
  getType: PropTypes.func.isRequired,
  /**
   * Hide badges after menu item
   */
  noBadges: PropTypes.bool,
  /**
   * Hide "Add New Case" button
   */
  noButton: PropTypes.bool,

  activeFont: PropTypes.object,
  font: PropTypes.object,
};

CasesMenu.defaultProps = {
  noBadges: false,
  noButton: false,
  activeFont: Bold30Font,
  font: Medium30Font,
};

export default CasesMenu;
