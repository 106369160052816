import React, { Fragment, useEffect, useMemo, useState } from "react";
import { withTheme } from "styled-components/macro";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
  Medium14Font,
  Medium30Font,
} from "../../../../components/FontsNewComponent/Fonts";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar/TrialHubSearchSortBar";
import { PulseLoader } from "react-spinners";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteEventById,
  generateDocChronology,
  generateDocCoreBook,
  getCaseById,
  getEventsList,
  getEvidenceList,
} from "../../../Auth/auth";
import moment from "moment";
import CaseRowStyled from "../../../../components/baseAppComponents/AdminCaseTypes/styled/CaseRowStyled";
import ListActionButton from "../../../../components/buttons/ListActionButton/ListActionButton";
import Checkbox from "../../../../components/Checkbox";
import CustomReactDatepicker from "../../../../components/inputs/CustomReactDatepicker";
import CustomDatepickerRangeDivInput from "../../../../components/inputs/CustomReactDatepicker/CustomDatepickerRangeDivInput";
import { format } from "date-fns";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import addPlusIcon from "../../../../assets/img3/svg/add-plus-icon.svg";
import {
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SET_IS_SUBMITTING_DATA_FALSE,
  SET_IS_SUBMITTING_DATA_TRUE,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../../redux/types";
import AddEditEventForm from "../../../../components/forms/TrialProofsForm/AddEditEventForm";
import NoRecords from "../../../../components/NoRecords";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { PdfCoreBook } from "../../../../components/Pdf/PdfCoreBook";
import { saveAs } from "file-saver";
import SelectAnotherTry from "../../../../components/Select/SelectAnotherTry";
import Sorting from "../../../../components/Sorting";
import {
  changeSorting,
  sortAlphabetical,
  sortByDateTime,
  sortDefault,
} from "../../../../utils/function";

const dateFormatToCompare = "YYYYMMDD";

const dateFormat = "dd-MM-yyyy";

const dateToInt = (date) => {
  if (date !== null) {
    return parseInt(moment(date).format(dateFormatToCompare));
  }
  return 0;
};

const sortByDate = (itemA, itemB, selectedSortFunction = 1) => {
  if (itemA.date && itemB.date) {
    if (selectedSortFunction === 1) {
      return dateToInt(itemA.date) - dateToInt(itemB.date);
    } else {
      return dateToInt(itemB.date) - dateToInt(itemA.date);
    }
  } else if (itemA.date === null) {
    return 1;
  } else if (itemB.date === null) {
    return -1;
  }
};

const Chronology = ({ theme }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const [dataToPdf, setDataToPdf] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // data states
  const [searchBarValue, setSearchBarValue] = useState("");
  const [datePickerStartDate, setDatePickerStartDate] = useState(null);
  const [datePickerEndDate, setDatePickerEndDate] = useState(null);
  const [filterTypeOptionsList, setFilterTypeOptionsList] = useState([]);
  const [selectedFilterTypeOption, setSelectedFilterTypeOption] = useState(
    null
  );
  const [caseObject, setCaseObject] = useState(null);
  const [sortCondition, setSortCondition] = useState(null);
  const [evidenceList, setEvidenceList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [selectedEventObject, setSelectedEventObject] = useState(null);
  const [objectsOrderedByDateList, setObjectsOrderedByDateList] = useState([]);
  const [selectedObjectsToPDFList, setSelectedObjectsToPDFList] = useState([]);
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const selectByCheckboxesOptions = [
    { label: "All", value: false },
    { label: "Only selected", value: true },
  ];
  const [
    selectByCheckboxesSelectedOption,
    setSelectByCheckboxesSelectedOption,
  ] = useState(false);
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);

  // get data

  const saveData = (evidenceRespList, eventsRespList) => {
    const sortedList = [...evidenceRespList, ...eventsRespList].sort((a, b) =>
      sortByDate(a, b, selectedSortFunction)
    );
    setObjectsOrderedByDateList(
      sortedList.map((el, index) => {
        let type = 'evidence';
        if (!el.real_id) {
          el.label = el.title;
          type = 'event';
        }
        return {
          id: index + 1,
          type: type,
          checked: false,
          data: el,
        };
      })
    );
  };

  useEffect(() => {
    if (objectsOrderedByDateList.length || selectedObjectsToPDFList.length) {
      let sortedObjectsOrderedByDateList = [
        ...objectsOrderedByDateList,
      ].sort((a, b) => sortByDate(a.data, b.data, selectedSortFunction));
      sortedObjectsOrderedByDateList = sortedObjectsOrderedByDateList.map(
        (el, idx) => ({ ...el, id: idx + 1 })
      );
      setObjectsOrderedByDateList(sortedObjectsOrderedByDateList);

      let sortedSelectedObjectsToPDFList = [
        ...selectedObjectsToPDFList,
      ].sort((a, b) => sortByDate(a.data, b.data, selectedSortFunction));
      setSelectedObjectsToPDFList(sortedSelectedObjectsToPDFList);
    }
  }, [selectedSortFunction]);

  const getDataFromAPI = async () => {
    const caseObjectResp = await getCaseById(id, dispatch);
    const evidenceRespList = await getEvidenceList(id, dispatch);
    const eventsRespList = await getEventsList(id, dispatch);
    if (caseObjectResp && evidenceRespList && eventsRespList) {
      setCaseObject(caseObjectResp);
      setEvidenceList(evidenceRespList);
      setEventsList(eventsRespList);
      saveData(evidenceRespList, eventsRespList);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  useEffect(() => {
    saveData(evidenceList, eventsList);
  }, [eventsList]);

  useEffect(() => {
    if (selectedEventObject) {
      dispatch({
        type: SHOW_RIGHT_SIDE_BAR,
        url: history.location.pathname,
        content: (
          <AddEditEventForm
            key={selectedEventObject.id}
            routePaths={{ caseId: id }}
            eventObject={selectedEventObject}
            setEventObject={setSelectedEventObject}
            setEventsList={setEventsList}
          />
        ),
        afterCloseAction: () => setSelectedEventObject(null),
        title: `Edit Event: ${selectedEventObject.title}`,
      });
    }
  }, [selectedEventObject]);

  const filterTableRows = (value) => {
    let check = true;

    if (check && searchBarValue !== "") {
      check = value.data.label
        ? value.data.label
            .toLowerCase()
            .indexOf(searchBarValue.toLowerCase()) !== -1
        : value.data.title
            .toLowerCase()
            .indexOf(searchBarValue.toLowerCase()) !== -1;
    }

    // Filter by date
    if (check) {
      const checkedDate = dateToInt(value.data.date);
      let dateBefore;
      let dateAfter;
      if (datePickerStartDate && datePickerEndDate) {
        dateBefore = dateToInt(datePickerStartDate);
        dateAfter = dateToInt(datePickerEndDate);
        check = dateBefore <= checkedDate && checkedDate <= dateAfter;
      } else if (datePickerStartDate) {
        dateBefore = dateToInt(datePickerStartDate);
        check = dateBefore <= checkedDate;
      } else if (datePickerEndDate) {
        dateAfter = dateToInt(datePickerEndDate);
        check = checkedDate <= dateAfter;
      }
    }

    return check;
  };

  // Filter by check state:
  const filterByCheckState = (value) => {
    let check = true;

    if (check && selectByCheckboxesSelectedOption) {
      check = value.checked;
    }

    return check;
  };

  const generatePdfData = (list) => {
    const data = list
      .filter((el) => el.data.date !== null)
      .filter(filterTableRows)
      .map((el, idx) => ({
        ...el,
        id: idx + 1,
      }));
    return {
      headers: [
        {
          name: "id",
          label: "ID",
          width: "5%",
        },
        {
          name: "date",
          label: "Date",
          width: "30%",
        },
        {
          name: "name",
          label: "Evidence Item / Event",
          width: "65%",
        },
      ],
      rows: data.map((v) => {
        return {
          id: v.id,
          date: v.data.date ? moment(v.data.date).format("DD-MM-yyyy") : "N/A",
          name:
            v.type === "evidence" ? v.data.label : v.data.title + " (event)",
        };
      }),
    };
  };
  // send data
  const PdfExport = () => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const blob = await pdf(
              <PdfCoreBook
                document_type={"Chronology"}
                caseName={caseObject.label}
                data={dataToPdf}
              />
            ).toBlob();
            saveAs(blob, `${caseObject.label} Chronology.pdf`);
            setExporting(false);
          }
        }}
        loading={exporting}
        disabled={exporting}
        className="float-right"
        primary
      >
        Export to PDF
      </ButtonNew>
    );
  };
  const DocExport = ({ as_pdf }) => {
    return (
      <ButtonNew
        clickHandler={async () => {
          if (caseObject && dataToPdf) {
            setExporting(true);
            const data_to_doc = {
              data: dataToPdf,
              case_object: caseObject,
            };
            if (as_pdf) {
              data_to_doc.as_pdf = true;
            }
            const response = await generateDocChronology(dispatch, data_to_doc);
            const url = window.URL.createObjectURL(new Blob([response]));
            setExporting(false);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${caseObject.label} Chronology.${as_pdf ? "pdf" : "docx"}`
            );
            document.body.appendChild(link);
            link.click();
          }
        }}
        loading={exporting}
        disabled={exporting}
        className={`float-right ${!as_pdf ? "ml-2" : ""}`}
        primary
      >
        Export to {as_pdf ? "PDF" : "Docx"}
      </ButtonNew>
    );
  };
  useEffect(() => {
    // setDataToPdf(generatePdfData(objectsOrderedByDateList));
    setDataToPdf(generatePdfData(selectedObjectsToPDFList));
  }, [
    objectsOrderedByDateList,
    selectedObjectsToPDFList,
    searchBarValue,
    datePickerStartDate,
    datePickerEndDate,
    selectedSortFunction,
  ]);
  const ExportToPdf = useMemo(() => {
    return <DocExport as_pdf={true} />;
  }, [dataToPdf, caseObject, exporting]);
  const ExportToDoc = useMemo(() => {
    return <DocExport />;
  }, [dataToPdf, caseObject, exporting]);

  // button handlers

  const onClickExportToPDFHandler = () => {};

  const onClickClearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onClickAddEventHandler = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AddEditEventForm
          key={Math.random()}
          routePaths={{ caseId: id }}
          eventsList={eventsList}
          setEventsList={setEventsList}
        />
      ),
      afterCloseAction: () => setSelectedEventObject(null),
      title: "Add Event",
    });
  };

  const onClickClearDatesHandler = () => {
    setDatePickerStartDate(null);
    setDatePickerEndDate(null);
  };

  const onClickEditEventHandler = (element) => {
    setSelectedEventObject(element.data);
  };

  const onClickDeleteEventHandler = async (element) => {
    dispatch({ type: SET_IS_SUBMITTING_DATA_TRUE });
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        const resp = await deleteEventById(element.data.id, id, dispatch);
        if (resp) {
          setEventsList((prevState) =>
            [...prevState].filter((el) => el.id !== element.data.id)
          );
        }
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
    dispatch({ type: SET_IS_SUBMITTING_DATA_FALSE });
  };

  // input handlers

  const onChangeSearchBarValueHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const onChangeDatesHandler = (dates) => {
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
  };

  const onChangeCheckBoxHandler = (item, items, setFunction) => {
    const objectsOrderedByDateListCopy = [...objectsOrderedByDateList];
    const index = item.id - 1;

    const findItem = (element) => element.id === item.id;
    const foundItem = items.find(findItem);
    if (!foundItem) {
      setFunction((prevState) => [...prevState, { ...item, checked: true }]);
      objectsOrderedByDateListCopy.splice(index, 1, { ...item, checked: true });
      setObjectsOrderedByDateList(objectsOrderedByDateListCopy);
    } else {
      const foundItemIndex = items.findIndex(findItem);
      const tempArray = [...items];
      tempArray.splice(foundItemIndex, 1);
      objectsOrderedByDateListCopy.splice(index, 1, {
        ...item,
        checked: false,
      });
      setObjectsOrderedByDateList(objectsOrderedByDateListCopy);
      setFunction([...tempArray]);
    }
  };

  const onChangeHeaderCheckboxHandler = () => {
    if (isSelectedAllRows) {
      setIsSelectedAllRows(false);
      setObjectsOrderedByDateList((prevState) =>
        prevState.map((el) => ({ ...el, checked: false }))
      );
      setSelectedObjectsToPDFList([]);
    } else {
      setIsSelectedAllRows(true);
      setObjectsOrderedByDateList((prevState) =>
        prevState.map((el) => ({ ...el, checked: true }))
      );
      setSelectedObjectsToPDFList([...objectsOrderedByDateList]);
    }
  };
  const sort = (a, b) => {
    if (!sortCondition) {
      return 1;
    } else {
      if (
        [
          "label",
          "title",
          "name",
          "type",
          "document_type",
          "reference",
          "document_type_name",
          "status",
          "case_type",
          "doc_type",
          "new_case_type",
          "key_sections",
          "probative_status",
          "sections",
          "firm_name",
          "record_no",
          "jurisdiction",
          "citation",
        ].indexOf(sortCondition.field) !== -1
      ) {
        return sortAlphabetical(a.data, b.data, sortCondition);
      }
      if (
        ["date", "created_at", "updated_at"].indexOf(sortCondition.field) !== -1
      ) {
        return sortByDateTime(a.data, b.data, sortCondition);
      }
      return sortDefault(a, b, sortCondition);
    }
  };
  const onChangeSelectedByCheckboxesHandler = (selectedOption) => {
    setSelectByCheckboxesSelectedOption(selectedOption.value);
  };

  const onFilterDatesAlphabetChangeHandler = (selectedOption) => {
    setSelectedSortFunction(selectedOption.value);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? "/app/cases" : "/admin/all-cases",
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs`
        : `/admin/all-cases/${id}/trial-proofs`,
      title: `Trial Proofs`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/trial-proofs/court-documents/trial/chronology`
        : `/admin/all-cases/${id}/trial-proofs/court-documents/trial/chronology`,
      title: "Court Documents - Trial - Chronology",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col-12 col-sm-7 col-md-7">
          <Bold30Font style={{ marginRight: "16px" }}>
            {caseObject.label}
          </Bold30Font>
          <Medium30Font>Chronology</Medium30Font>
        </div>
        <div className="col-12 col-sm-5 col-md-5 d-flex justify-content-end align-items-center mb-3">
          {dataToPdf ? ExportToPdf : null}
          {dataToPdf ? ExportToDoc : null}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarInputName="searchbar"
            searchBarPlaceholder="Search"
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onChangeSearchBarValueHandler}
            clearSearchBarHandler={onClickClearSearchBarHandler}
            sortOptions={[
              { label: "Date - ASC.", value: 1 },
              { label: "Date - DESC.", value: 2 },
            ]}
            onFilterDatesAlphabetChangeHandler={
              onFilterDatesAlphabetChangeHandler
            }
          />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">Filter by:</div>
      </div>
      <div className="row mb-5">
        <div className="col-12 mb-2 col-sm-6 col-md-4">
          <CustomReactDatepicker
            // selected={datePickerStartDate}
            customInput={
              <CustomDatepickerRangeDivInput
                startDate={
                  datePickerStartDate
                    ? format(datePickerStartDate, dateFormat)
                    : ""
                }
                endDate={
                  datePickerEndDate ? format(datePickerEndDate, dateFormat) : ""
                }
                clearDatesHandler={onClickClearDatesHandler}
              />
            }
            startDate={datePickerStartDate}
            endDate={datePickerEndDate}
            onChange={onChangeDatesHandler}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            selectsRange
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
          />
        </div>
        <div className="col-12 mb-2 col-sm-6 col-md-4">
          <SelectAnotherTry
            options={selectByCheckboxesOptions}
            placeholder="Selection"
            onChange={onChangeSelectedByCheckboxesHandler}
          />
        </div>
        <div className="col-12 mb-2 col-sm col-md-4 d-flex justify-content-end">
          <ButtonNew secondary clickHandler={onClickAddEventHandler}>
            <div className="d-flex align-items-center">
              <img src={addPlusIcon} alt="" className="img-fluid mr-2" />
              <Bold14Font style={{ color: "inherit" }}>Add Event</Bold14Font>
            </div>
          </ButtonNew>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {objectsOrderedByDateList
            .filter((el) => el.data.date)
            .filter(filterTableRows)
            .filter(filterByCheckState).length ? (
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "85px", padding: "0 0 10px 20px" }}>
                    <div className="d-flex align-items-center">
                      <Checkbox
                        checked={isSelectedAllRows}
                        onChange={onChangeHeaderCheckboxHandler}
                      />
                      <div
                        onClick={() => {
                          changeSorting("id", sortCondition, setSortCondition);
                        }}
                        className="d-flex align-items-baseline"
                      >
                        <Bold14Font
                          pointer
                          style={{ color: theme.colors.darkOpacity }}
                        >
                          ID
                        </Bold14Font>
                        <Sorting
                          direction={
                            sortCondition && "id" === sortCondition.field
                              ? sortCondition.direction
                              : null
                          }
                        />
                      </div>
                    </div>
                  </th>
                  <th style={{ padding: "0 0 10px 0" }}>
                    <div
                      onClick={() => {
                        changeSorting("date", sortCondition, setSortCondition);
                      }}
                      className="d-flex align-items-baseline"
                    >
                      <Bold14Font
                        pointer
                        style={{ color: theme.colors.darkOpacity }}
                      >
                        DATE
                      </Bold14Font>
                      <Sorting
                        direction={
                          sortCondition && "date" === sortCondition.field
                            ? sortCondition.direction
                            : null
                        }
                      />
                    </div>
                  </th>
                  <th style={{ padding: "0 0 10px 0" }}>
                    <div
                      onClick={() => {
                        changeSorting("label", sortCondition, setSortCondition);
                      }}
                      className="d-flex align-items-baseline"
                    >
                      <Bold14Font
                        pointer
                        style={{ color: theme.colors.darkOpacity }}
                      >
                        EVIDENCE ITEM / EVENT
                      </Bold14Font>
                      <Sorting
                        direction={
                          sortCondition && "label" === sortCondition.field
                            ? sortCondition.direction
                            : null
                        }
                      />
                    </div>
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {objectsOrderedByDateList
                  .filter((el) => el.data.date)
                  .filter(filterTableRows)
                  .filter(filterByCheckState)
                  .sort(sort)
                  .map((el) => (
                    <Fragment key={el.id}>
                      <CaseRowStyled
                        as="tr"
                        // className="d-flex justify-content-between"
                        // key={el.id}
                      >
                        <td style={{ padding: "20px" }}>
                          <div className="d-flex align-items-center">
                            <Checkbox
                              checked={el.checked}
                              onChange={() =>
                                onChangeCheckBoxHandler(
                                  el,
                                  selectedObjectsToPDFList,
                                  setSelectedObjectsToPDFList
                                )
                              }
                            />
                            <Medium14Font>{el.id}</Medium14Font>
                          </div>
                        </td>
                        <td>
                          <Medium14Font
                            style={{
                              color: theme.colors.darkOpacity,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {el.data.date
                              ? moment(el.data.date).format("DD-MM-yyyy")
                              : "N/A"}
                          </Medium14Font>
                        </td>
                        <td>
                          <Medium14Font textColor={theme.colors.darkOpacity}>
                            {el.type === "evidence" ? (
                              <Bold14Font>{el.data.label}</Bold14Font>
                            ) : (
                              <Fragment>
                                <Bold14Font>{el.data.title}</Bold14Font> (event)
                              </Fragment>
                            )}
                          </Medium14Font>
                        </td>
                        <td>
                          {el.type === "event" ? (
                            <div className="d-flex justify-content-end">
                              <ListActionButton
                                type={"edit"}
                                clickHandler={() => onClickEditEventHandler(el)}
                              />
                              <ListActionButton
                                type={"delete"}
                                clickHandler={() =>
                                  onClickDeleteEventHandler(el)
                                }
                              />
                            </div>
                          ) : null}
                        </td>
                      </CaseRowStyled>
                      <tr style={{ height: "32px", border: "unset" }} />
                    </Fragment>
                  ))}
              </tbody>
            </table>
          ) : (
            <NoRecords>Your Evidence and Events will appear here</NoRecords>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(Chronology);
