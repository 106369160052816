import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { NodeViewWrapper } from "@tiptap/react";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_RIGHT_SIDE_BAR, HIDE_RIGHT_SIDE_BAR } from "@/redux/types";
import { useHistory, useParams } from "react-router-dom";
import {
  updateCase,
  getApplicationById,
  getCaseById,
} from "@/containers/Auth/auth";
import EditObjectPanel from "@/components/Notebook/EditObjectPanel";
import axios from "axios";

import ConnectionPopup from "./ConnectionPopup";
import ContextMenu from "./ContextMenu";
import CauseOfActionForm from "@/components/forms/CasesForms/CauseOfActionForm";
import ThemesForm from "@/components/forms/CasesForms/ThemesForm";
import IssuesForm from "@/components/forms/CasesForms/IssuesForm";
import ReliefsAndProofs from "@/components/forms/ApplicationsHubForms/ReliefsAndProofs";

import { loadAccessToken } from "@/containers/Auth/auth";
import { listenForOutsideClick } from "@/hooks/onClickOutside";

import DragHandle from "@editor/components/DragHandle";

import EditIcon from "@/assets/img/notebook/tag-edit-icon.svg";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
// <ellipse cx="1.2" cy="13.9275" rx="1.2" ry="1.11111" fill="black"/>
// <ellipse cx="6.79961" cy="13.9275" rx="1.2" ry="1.11111" fill="black"/>
// <ellipse cx="1.2" cy="8.00174" rx="1.2" ry="1.11111" fill="black"/>
// <ellipse cx="6.79961" cy="8.00174" rx="1.2" ry="1.11111" fill="black"/>
// <ellipse cx="1.2" cy="2.07595" rx="1.2" ry="1.11111" fill="black"/>
// <ellipse cx="6.79961" cy="2.07595" rx="1.2" ry="1.11111" fill="black"/>
// </svg>

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Evidence
// Witness
// Authority
// KeySection
// Topic
// Application
// Paper
// Proof
// Relief
// Objective
// ExamLine
// Theme
// SubTheme
// CausesOfAction
// CausesOfActionElement
// CausesOfActionDefenseElement
// Issue
// KeyFact

const getTagType = (t) => {
  let tagType = t;

  // if (tagType === "application") tagType = "applications";
  // if (tagType === "issue") tagType = "issues";
  // if (tagType === "party") tagType = "parties";
  // if (tagType === "theme") tagType = "themes";
  // if (tagType === "topic") tagType = "topics";
  if (tagType === "causesofaction") tagType = "CauseOfAction";

  return tagType;
};

export default (props) => {
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [selected, setSelected] = useState(false);
  const dispatch = useDispatch();
  const isSideBarPulled = useSelector(
    (state) => state.rightSideBar.isSideBarPulled
  );
  const { id: caseId, notebook_id: notebookId } = useParams();

  const [showMenu, setShowMenu] = useState(false);

  const [connectedElement, setConnectedElement] = useState(null);
  const [showConnection, setShowConnection] = useState(false);

  const {
    caseObject,
    setCaseObject,
    fetchCaseLibrary,
    filterQuery,
    notebook,
  } = useContext(NotebookContext);

  const {
    draggingItem,
    setDraggingItem,
    hoveredItem,
    setHoveredItem,
    newConnectionPos,
    newConnectionElement,
    setNewConnectionPos,
    setNewConnectionElement,
    setContent,
    editor,
  } = useContext(EditorContext);

  useEffect(() => {
    if (draggingItem) {
      setShowConnection(false);
      // console.log("hide");
    }
  }, [draggingItem]);

  const history = useHistory();
  const [causesOfAction, setCausesOfAction] = useState([]);

  useEffect(() => {
    setCausesOfAction(caseObject.causes_of_action ?? []);
  }, [caseObject]);

  const updateEditorState = (tagId, tagType, updatedLabel) => {
    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                // console.log("subnode");
                // console.log(subNode.type);
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  // console.log("---");
                  // console.log(subNode);
                  // console.log(tagId);
                  // console.log(tagType);
                  if (
                    subNode.attrs.tagId == tagId &&
                    subNode.attrs.tagType.toLowerCase() == tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: updatedLabel },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);
  };

  const changeObj = async (resp, type, obj) => {
    if (resp) {
      let caseObject_clone = JSON.parse(JSON.stringify(caseObject));
      if (type === "add") {
        caseObject_clone[obj].push(resp);
      } else if (type === "update") {
        caseObject_clone[obj].forEach((v, index) => {
          if (v.id === resp.id) {
            caseObject_clone[obj][index] = resp;
          }
        });
      } else if (type === "delete") {
        caseObject_clone[obj] = caseObject[obj].filter((v) => {
          return v.id !== resp.id;
        });
      }

      const res = await updateCase(caseObject.id, dispatch, caseObject_clone);
      setCaseObject(res);

      await sleep(3000);

      await fetchCaseLibrary(caseId, notebookId, filterQuery);
    }
  };

  const getDataFromAPI = async () => {
    // setDataLoading(true);
    const caseResp = await getCaseById(caseId, dispatch);
    if (caseResp) {
      setCaseObject(caseResp);
      return caseResp;
      // setDataLoading(false);
    }
  };

  const handleCauseOfAction = async (object, objType, counterclaim, tag) => {
    const caseObj = await getDataFromAPI();

    const coa = caseObj.causes_of_action ?? [];

    let coaObj;
    if (tag.tagType === "causesofactionelement") {
      coaObj = caseObj["causes_of_action"].find((i) =>
        i.elements.some((element) => element.id == tag.tagId)
      );
    } else if (tag.tagType === "causesofactiondefenseelement") {
      coaObj = caseObj["causes_of_action"].find((i) =>
        i["defence_elements"].some((element) => element.id == tag.tagId)
      );
    } else {
      coaObj = caseObj["causes_of_action"].find((i) => i.id == tag.tagId);
    }

    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <CauseOfActionForm
          caseObject={caseObj}
          key={coaObj.id}
          id_case={caseObj.id}
          counterclaim={counterclaim}
          object={coaObj}
          isCriminal={caseObj.new_case_type === "Criminal"}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "causes_of_action");
            callback();

            if (objType === "causesofaction") {
              // 1. update element itself

              // 2. update all of subelements
              resp["elements"].forEach((el) => {
                updateEditorState(el.id, "causesofactionelement", el.name);
              });
              resp["defence_elements"].forEach((el) => {
                updateEditorState(
                  el.id,
                  "causesofactiondefenseelement",
                  el.name
                );
              });
            } else if (
              objType === "causesofactionelement" ||
              objType === "causesofactiondefenseelement"
            ) {
              // updateEditorState(resp.id, "causeofaction", resp.name);
              resp["elements"].forEach((el) => {
                updateEditorState(el.id, "causesofactionelement", el.name);
              });
              resp["defence_elements"].forEach((el) => {
                updateEditorState(
                  el.id,
                  "causesofactiondefenseelement",
                  el.name
                );
              });
            }
          }}
          alreadySavedCOAList={coa}
          loadingAfterCallback
        />
      ),
      title:
        coaObj.type && coaObj.type.name
          ? "Edit: " + coaObj.type.name
          : counterclaim
          ? "Add Counterclaim"
          : caseObj.new_case_type === "Criminal"
          ? "Add Offence"
          : "Add Cause of action",
    });
  };

  const updateApplication = async (obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.put(
      `/api/cases/${caseId}/applications/${notebook.application.fake_id}`,
      {
        reliefs: obj.reliefs,
        proofs: obj.proofs,
      },
      config
    );
  };

  // const handleRelief = (object) => {
  //   dispatch({
  //     type: SHOW_RIGHT_SIDE_BAR,
  //     url: history.location.pathname,
  //     content: (
  //       <ReliefsAndProofs
  //         key={object.id}
  //         object={notebook.application}
  //         setObject={async (obj, callback) => {
  //           await updateApplication(obj);
  //           await sleep(2000);
  //           await fetchCaseLibrary(caseId, notebookId, filterQuery);
  //           callback();
  //         }}
  //         routeParams={{ caseId, applicationId: notebook.application }}
  //         alreadySavedReliefs={[]}
  //         alreadySavedProofs={[]}
  //         loadingAfterCallback
  //       />
  //     ),
  //     title: "Add Relief",
  //   });
  // };

  const handleRelief = (object, objType, app) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofs
          key={object.id}
          object={object}
          setObject={async (obj, callback) => {
            const upd = await updateApplication(obj);
            await sleep(2000);
            await fetchCaseLibrary(caseId, notebookId, filterQuery);

            obj.proofs.forEach((i) => {
              updateEditorState(i.id, "proof", i.name);
            });
            obj.reliefs.forEach((i) => {
              updateEditorState(i.id, "relief", i.name);
            });

            // if (objType === "relief") {
            //   // 1. update theme itself
            //   updateEditorState(
            //     props.node.attrs.tagId,
            //     props.node.attrs.tagType,
            //     resp.name
            //   );

            //   // 2. update all of subtheme
            //   resp["proofs"].forEach((subtheme) => {
            //     updateEditorState(subtheme.id, "subtheme", subtheme.name);
            //   });
            // }
            // if (objType === "proof") {
            //   console.log("neededProof");
            //   const neededProof = obj.proofs.find(
            //     (i) => i.id == props.node.attrs.tagId
            //   );
            //   console.log(neededProof);
            //   console.log(obj);
            //   console.log(object);
            //   // 1. update issue itself
            //   updateEditorState(neededProof.id, "proof", neededProof.name);
            // }

            callback();
          }}
          routeParams={{ caseId, applicationId: notebook.application.id }}
          alreadySavedReliefs={app.reliefs}
          alreadySavedProofs={app.proofs}
          actionType={"edit"}
          loadingAfterCallback
        />
      ),
      title: `Edit Relief: ${object.name}`,
    });
  };

  const handleTheme = (object, objType) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ThemesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          // subthemeToEdit={objType === "subtheme" && object.id}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "themes");
            callback();

            if (objType === "theme") {
              // 1. update theme itself
              updateEditorState(
                props.node.attrs.tagId,
                props.node.attrs.tagType,
                resp.name
              );

              // 2. update all of subtheme
              resp["sub_themes"].forEach((subtheme) => {
                updateEditorState(subtheme.id, "subtheme", subtheme.name);
              });
            } else if (objType === "subtheme") {
              // 1. update issue itself
              updateEditorState(resp.id, "theme", resp.name);

              // 2. update all of keyfacts
              resp["sub_themes"].forEach((subtheme) => {
                updateEditorState(subtheme.id, "subtheme", subtheme.name);
              });
            }
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Theme",
    });
  };

  // const handleKeySection = (object, objType) => {
  // };

  const handleIssues = (object, objType) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <IssuesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "issues");
            callback();

            if (objType === "issue") {
              // 1. update issue itself
              updateEditorState(
                props.node.attrs.tagId,
                props.node.attrs.tagType,
                resp.name
              );

              // 2. update all of keyfacts
              resp["key_facts"].forEach((keyfact) => {
                updateEditorState(keyfact.id, "keyfact", keyfact.name);
              });
            } else if (objType === "keyfact") {
              // 1. update issue itself
              updateEditorState(resp.id, "issue", resp.name);

              // 2. update all of keyfacts
              resp["key_facts"].forEach((keyfact) => {
                updateEditorState(keyfact.id, "keyfact", keyfact.name);
              });
            }
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add Issue",
    });
  };

  useEffect(() => {
    if (newConnectionElement && props.getPos() === newConnectionPos) {
      setConnectedElement(newConnectionElement);
      // TODO GO
      setShowConnection(true);

      window.localStorage.setItem(
        "lastConnectTag",
        JSON.stringify({
          connected: {
            id: newConnectionElement.label,
            tagType: newConnectionElement.type,
            tagId: newConnectionElement.id,
          },
          connecting: props.node.attrs,
        })
      );
      setNewConnectionPos(undefined);
      setNewConnectionElement(undefined);
    }
  }, [newConnectionElement, newConnectionPos]);

  useEffect(
    listenForOutsideClick(listening, setListening, menuRef, (e) => {
      setShowMenu(false);
      setSelected(false);
      if (e.target.className.indexOf("tag") === -1) {
        setShowConnection(false);
      }
    })
  );

  const tagType = props.node.attrs.tagType
    ? `tag-${props.node.attrs.tagType}`
    : props.extension.options.HTMLAttributes.class.split(" ")[1];

  // div style={{ display: "inline-block", position: "relative" }}
  return (
    <>
      <NodeViewWrapper
        className={`tag ${tagType}
          ${selected ? `${tagType}--selected` : ""}
          ${selected ? `tag--selected` : ""}
          ${
            hoveredItem &&
            hoveredItem.id === props.node.attrs.id &&
            hoveredItem.tagType === props.node.attrs.tagType
              ? `tag--highlighted ${tagType}--highlighted`
              : ""
          }
      `}
        as="span"
        draggable="true"
        data-drag-handle=""
        ref={menuRef}
        data-type="annotation"
        onDragStart={(e) => {
          // this event doesn't always work -_-
        }}
        onDrag={() => {
          // setDragging
          if (
            !draggingItem ||
            (draggingItem.id !== props.node.attrs.id &&
              draggingItem.tagType !== props.node.attrs.tagType)
          ) {
            setShowMenu(false);
            setShowConnection(false);
            setDraggingItem(props.node.attrs);
          }
        }}
        onDragEnd={(e) => {
          const caseLibraryRect = document
            .getElementById("case-library")
            .getBoundingClientRect();

          const x = e.clientX;
          const y = e.clientY;

          const isOverCaseLibrary =
            x > caseLibraryRect.left &&
            y > caseLibraryRect.top &&
            x < caseLibraryRect.right &&
            y < caseLibraryRect.bottom;

          // if dragged onto case library -> remove tag
          if (isOverCaseLibrary) {
            props.deleteNode();
          }
        }}
        onDragEnter={(e) => {
          // if dragged onto -> set this element as hovered
          const isSameElement =
            draggingItem &&
            draggingItem.id === props.node.attrs.id &&
            draggingItem.tagType === props.node.attrs.tagType;
          if (!isSameElement) {
            setHoveredItem(props.node.attrs);
          }
        }}
        onDragLeave={(e) => {
          if (e.target.parent) {
            // console.log(e.target.parent.className);
            // console.log(e.target.parent.class);
          }
          if (
            e.target.parent &&
            e.target.parent.className.indexOf("tag") > -1
          ) {
            return;
          }
          // if dragged out -> remove from hovered
          if (
            hoveredItem &&
            props.node.attrs.id === hoveredItem.id &&
            props.node.attrs.tagType === hoveredItem.tagType
          ) {
            setHoveredItem(undefined);
          }
        }}
        onDrop={async () => {
          if (
            hoveredItem.id === props.node.attrs.id &&
            hoveredItem.tagType === props.node.attrs.tagType
          ) {
            if (draggingItem) {
              const caseId = window.location.pathname.split("/")[3];
              const config = {
                headers: {
                  Authorization: `Bearer ${await loadAccessToken()}`,
                },
              };

              let err;
              const canConnectRes = await axios
                .get(
                  `/api/cases/${caseId}/canConnect?fromType=${capitalize(
                    getTagType(draggingItem.tagType)
                  )}&fromId=${draggingItem.tagId}&toType=${capitalize(
                    getTagType(hoveredItem.tagType)
                  )}&toId=${hoveredItem.tagId}&id_case=${caseId}`,
                  config
                )
                .catch((e) => {
                  err = e;
                });

              if (err) {
                alert(`internal server error: ${err.message}`);
                setDraggingItem(undefined);
                setHoveredItem(undefined);
                return;
              }

              if (canConnectRes && canConnectRes.data.canConnect) {
                const connectionRes = await axios
                  .get(
                    `/api/cases/${caseId}/connect?fromType=${capitalize(
                      getTagType(draggingItem.tagType)
                    )}&fromId=${draggingItem.tagId}&toType=${capitalize(
                      getTagType(hoveredItem.tagType)
                    )}&toId=${hoveredItem.tagId}&id_case=${caseId}`,
                    config
                  )
                  .catch((e) => {
                    alert(
                      `Server error when executing /api/cases/${caseId}/connect?fromType=${capitalize(
                        getTagType(draggingItem.tagType)
                      )}&fromId=${draggingItem.tagId}&toType=${capitalize(
                        getTagType(hoveredItem.tagType)
                      )}&toId=${hoveredItem.tagId}`
                    );
                  });

                if (connectionRes.data.result === "success") {
                  // setDraggingItem(undefined);
                  // setHoveredItem(undefined);
                  setConnectedElement(draggingItem);
                  window.localStorage.setItem(
                    "lastConnectTag",
                    JSON.stringify({
                      connected: draggingItem,
                      connecting: props.node.attrs,
                    })
                  );
                  setShowConnection(true);
                }
              } else {
                alert(
                  `Sorry you can't connect [${capitalize(
                    draggingItem.tagType
                  )}] to [${capitalize(hoveredItem.tagType)}]`
                );
                // setDraggingItem(undefined);
                // setHoveredItem(undefined);
              }
            }
            setDraggingItem(undefined);
            setHoveredItem(undefined);
          } else {
            // alert("else");
          }
        }}
        onClick={async (e) => {
          if (e.target.className === "tag-action") return;
          if (selected) {
            const tag = props.node.attrs;
            const deleteNode = props.deleteNode;
            // setShowMenu = { setShowMenu };
            if (
              tag.tagType === "theme" ||
              tag.tagType === "subtheme" ||
              tag.tagType === "causesofaction" ||
              tag.tagType === "causesofactionelement" ||
              tag.tagType === "causesofactiondefenseelement" ||
              tag.tagType === "issue" ||
              tag.tagType === "keyfact" ||
              tag.tagType === "relief" ||
              tag.tagType === "proof"
            ) {
              if (tag.tagType === "theme" || tag.tagType === "subtheme") {
                let themeObj;
                if (tag.tagType === "subtheme") {
                  themeObj = caseObject.themes.find((i) =>
                    i["sub_themes"].some((subtheme) => subtheme.id == tag.tagId)
                  );
                } else {
                  themeObj = caseObject.themes.find((i) => i.id == tag.tagId);
                }
                handleTheme(themeObj, tag.tagType);
              }
              if (
                tag.tagType === "causesofaction" ||
                tag.tagType === "causesofactionelement" ||
                tag.tagType === "causesofactiondefenseelement"
              ) {
                let coaObj;
                if (tag.tagType === "causesofactionelement") {
                  coaObj = caseObject["causes_of_action"].find((i) =>
                    i.elements.some((element) => element.id == tag.tagId)
                  );
                } else if (tag.tagType === "causesofactiondefenseelement") {
                  coaObj = caseObject["causes_of_action"].find((i) =>
                    i["defence_elements"].some(
                      (element) => element.id == tag.tagId
                    )
                  );
                } else {
                  coaObj = caseObject["causes_of_action"].find(
                    (i) => i.id == tag.tagId
                  );
                }
                handleCauseOfAction(coaObj, tag.tagType, null, tag);
              }
              if (tag.tagType === "issue" || tag.tagType === "keyfact") {
                let issueObj;
                if (tag.tagType === "keyfact") {
                  issueObj = caseObject.issues.find((i) =>
                    i["key_facts"].some((fact) => fact.id == tag.tagId)
                  );
                } else {
                  issueObj = caseObject.issues.find((i) => i.id == tag.tagId);
                }
                handleIssues(issueObj, tag.tagType);
              }
              if (tag.tagType === "relief" || tag.tagType === "proof") {
                const app = await getApplicationById(
                  notebook.application.fake_id,
                  caseId,
                  dispatch
                );

                let issueObj;
                if (tag.tagType === "proof") {
                  issueObj = app.reliefs.find(
                    (i) =>
                      i.id ==
                      app.proofs.find((i) => i.id == tag.tagId).reliefs[0].id
                  );
                }

                if (tag.tagType === "relief") {
                  issueObj = app.reliefs.find((i) => i.id == tag.tagId);
                }
                handleRelief(issueObj, tag.tagType, app);
              }
              //
            } else {
              let label = tag.tagType;
              if (label === "keysection") label = "key section";
              if (isSideBarPulled) {
                await dispatch({ type: HIDE_RIGHT_SIDE_BAR });
                await dispatch({
                  type: SHOW_RIGHT_SIDE_BAR,
                  url: window.location.pathname,
                  content: <EditObjectPanel {...tag} />,
                  rightBarWidth: "18%",
                  title: `Editing ${label}`,
                });
              } else {
                dispatch({
                  type: SHOW_RIGHT_SIDE_BAR,
                  url: window.location.pathname,
                  content: <EditObjectPanel {...tag} />,
                  rightBarWidth: "18%",
                  title: `Editing ${label}`,
                });
              }
            }

            // setShowMenu(false);
          } else {
            setSelected(true);
          }
        }}
        style={{ position: "relative" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingRight: selected ? 10 : 0,
          }}
        >
          <DragHandle
            className={`fill--${tagType.substring(4)}`}
            // fill="blue"
            style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }}
          />
          {/* <img */}
          {/*   style={{ width: 8, height: 16, marginRight: 10, opacity: 0.5 }} */}
          {/*   src={dragHandle} */}
          {/*   alt="" */}
          {/* /> */}

          <span>{props.node.attrs.id}</span>
          {selected && (
            <img
              src={EditIcon}
              alt="Edit"
              style={{
                width: "22px",
                position: "absolute",
                top: "-8px",
                right: "-8px",
                opacity: 0.9,
              }}
            />
          )}
        </div>

        {showConnection && connectedElement && (
          <ConnectionPopup
            connectedElement={connectedElement}
            tag={props.node.attrs}
            show={showConnection}
            hide={() => setShowConnection(false)}
          />
        )}
      </NodeViewWrapper>
      {showMenu && (
        <ContextMenu
          tag={props.node.attrs}
          deleteNode={props.deleteNode}
          setShowMenu={setShowMenu}
        />
      )}
    </>
  );
};
