import styled from "styled-components/macro";

const ListActionButtonStyled = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.colors.gray};
  background-color: ${({theme}) => theme.colors.lightGray};
  cursor: pointer;
  :hover {
    background-color:  ${({theme}) => theme.colors.gray};
  }
`;

export default ListActionButtonStyled;
