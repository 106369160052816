import styled from "styled-components/macro";
import PropTypes from "prop-types";

import rem from "../../../utils/rem";
import { Bold18Font, Bold90Font, Medium18Font } from "../../Fonts/Fonts";

const LandingPageSectionStyled = styled.section`
  width: 100%;
  border-radius: ${rem("0 0 0 296px")};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ first }) => (first ? `height: 100vh` : null)};
  // TODO: Check why border don't show
  ${({ border }) =>
    border ? `border: 1px solid ${({ theme }) => theme.colors.gray}` : null};
  // Customize padding of section
  ${({ padding }) => (padding ? `padding: ${rem(padding)}` : null)};
  // Customize background color
  background: ${({ background }) => (background ? background : "none")};

  position: relative;
  z-index: ${({ zIndex }) => (zIndex ? `${zIndex}` : 0)};

  &:not(:first-child) {
    margin-top: ${rem(`-296px`)};
  }
  @media (max-width: 1200px) {
    height: auto;
  }

  &:first-child {
    @media (min-width: 992px) and (max-width: 1198.98px) {
      padding-right: 1.25rem;

      ${Bold90Font} {
        font-size: 2.625rem;
      }
    }
  }
`;

LandingPageSectionStyled.propTypes = {
  /**
   * Set padding in element
   */
  padding: PropTypes.string,

  /**
   * Set background color
   */
  background: PropTypes.string,

  /**
   * Show border
   */
  border: PropTypes.bool,

  /**
   * Set Z-Index of element
   */
  zIndex: PropTypes.number,
};

export default LandingPageSectionStyled;
