import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import PlanCardStyled from "./styled/PlanCardStyled";

import planFeaturesMarkIcon from "../../assets/img3/svg/plan-features-mark-icon.svg";
import planCheckedIcon from "../../assets/img/svg/checked.svg";

import {
  Bold18Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../Fonts/Fonts";
import { Bold14Font } from "../FontsNewComponent/Fonts";
import PlanCardUpPartStyled from "./styled/PlanCardUpPartStyled";
import PlanCardIconWrapperStyled from "./styled/PlanCardIconWrapperStyled";
import Button from "../buttons/Button";
import PlanCardBottomPartStyled from "./styled/PlanCardBottomPartStyled";
import moment from "moment";
import { useSelector } from "react-redux";
const PlanCard = ({
  icon,
  title,
  cost,
  features,
  noButton,
  customButtonName,
  theme,
  cost_type,
  checked,
  onClick,
  activeSubscription,
  onBtnClick,
}) => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const onTrial = () => {
    if (activeSubscription && activeSubscription.trial_ends_at) {
      if (moment.utc(activeSubscription.trial_ends_at) > moment(new Date())) {
        return true;
      }
    }
    return false;
  };
  return (
    <PlanCardStyled onClick={onClick} checked={checked}>
      {checked ? (
        <img
          src={planCheckedIcon}
          width={24}
          style={{ position: "absolute", right: "15px" }}
          height={24}
          alt=""
        />
      ) : null}
      <PlanCardUpPartStyled clickable={typeof onClick !== "undefined"}>
        <div className="container-fluid p-3">
          <div className="row my-3">
            <div className="col d-flex justify-content-center">
              <PlanCardIconWrapperStyled width={120}>
                <img src={icon} alt="" className="img-fluid" />
              </PlanCardIconWrapperStyled>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col d-flex justify-content-center">
              <Medium18Font>{title}</Medium18Font>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex justify-content-center align-items-center">
              <Bold18Font textColor={theme.colors.blue}>€ </Bold18Font>
              <Bold30Font className="ml-1" textColor={theme.colors.blue}>
                {cost}
              </Bold30Font>
              <Medium18Font>/{cost_type}</Medium18Font>
            </div>
          </div>
        </div>
      </PlanCardUpPartStyled>
      <PlanCardBottomPartStyled
        clickable={typeof onClick !== "undefined"}
        className="container-fluid p-5 justify-content-between"
      >
        <div className="row mb-4">
          <div className="col">
            <Medium14Font>Features</Medium14Font>
          </div>
        </div>
        {features.map((el, i) => (
          <div className="row mb-3 align-items-center" key={i}>
            <div className="col-1">
              <img
                src={planFeaturesMarkIcon}
                alt=""
                style={{ width: "24px" }}
              />
            </div>
            <div className="col">
              <Medium14Font>
                {el.value !== "unlimited" && el.value >= 1024
                  ? el.description.replace("Mb", "Gb")
                  : el.description}
                :{" "}
                {checked && el.value !== "unlimited"
                  ? `${
                      activeSubscription &&
                      activeSubscription.used &&
                      activeSubscription.used[el.name]
                        ? activeSubscription.used[el.name] + "/"
                        : ""
                    }`
                  : ""}
                {el.value !== "unlimited" && el.value >= 1024
                  ? el.value / 1024 + " Gb"
                  : el.description === "Data allowance"
                  ? el.value >= 1024
                    ? el.value + " Gb"
                    : el.value + " Mb"
                  : el.value}
              </Medium14Font>
            </div>
          </div>
        ))}
        {/* TODO: Does I need hide free sections on cards if user test period expire */}
        {user && !user.trial_ends_at && (!activeSubscription) ? (
          title === "Basic" ? (
            <div className="row">
              <div className="col d-flex flex-column align-items-center">
                <Medium14Font>First 3 months free</Medium14Font>
                <Medium14Font
                  style={{
                    fontWeight: "700",
                    color: theme.colors.orange,
                    textDecoration: "underline",
                  }}
                >
                  No credit card required
                </Medium14Font>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col d-flex flex-column align-items-center">
                <Medium14Font>First month free</Medium14Font>
                <br />
              </div>
            </div>
          )
        ) : null}
        {noButton ? null : (
          <div className="row">
            <div className="col d-flex justify-content-center pt-3">
              {customButtonName && checked ? (
                <Button clickHandler={onBtnClick} secondary wide>
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {onTrial()
                      ? `Trial until ${moment(
                          activeSubscription.trial_ends_at
                        ).format("DD-MM-yyyy")}`
                      : "Current Plan"}
                  </Medium14Font>
                </Button>
              ) : customButtonName === "Downgrade" ? (
                <Button clickHandler={onBtnClick} secondary wide>
                  <Bold14Font textColor={theme.colors.blue}>
                    Downgrade
                  </Bold14Font>
                </Button>
              ) : customButtonName === "Upgrade" ? (
                <Button clickHandler={onBtnClick} primary wide>
                  Upgrade
                </Button>
              ) : (
                <Button clickHandler={onBtnClick} primary wide>
                  Get Started
                </Button>
              )}
            </div>
          </div>
        )}
      </PlanCardBottomPartStyled>
    </PlanCardStyled>
  );
};

PlanCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cost: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  noButton: PropTypes.bool,
  cost_type: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withTheme(PlanCard);
