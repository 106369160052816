import React, { Fragment } from "react";
import RoundButtonStyled from "./styled/RoundButtonStyled";

const RoundButton = ({ customStyle, children, ...props }) => {
  return (
    <Fragment>
      {customStyle ? (
        <RoundButtonStyled as={customStyle} {...props}>
          {children}
        </RoundButtonStyled>
      ) : (
        <RoundButtonStyled>{children}</RoundButtonStyled>
      )}
    </Fragment>
  );
};

export default RoundButton;
