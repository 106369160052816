import React, { Fragment, useEffect, useState } from "react";
import Button from "../../buttons/Button/Button";
import { HIDE_RIGHT_SIDE_BAR, SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import InputNew from "../../InputNew/InputNew";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import RadioButtonGroupRounded from "../../buttons/RadioButtonGroupRounded/RadioButtonGroupRounded";
import SelectNew from "../../Select/SelectNew";
import Label from "../AddCardForm/Label";
import { parseISO } from "date-fns";
import {
  subtypeCommunicationList,
  subtypeDocumentList,
  subtypeImageMediaList,
} from "../../../containers/BaseApp/TrialHub/Evidence/AddEditEvidence";
import CustomReactDatepicker from "../../inputs/CustomReactDatepicker";
import moment from "moment";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import EvidenceObjectivesForm from "./EvidenceObjectivesForm";
import { theme } from "../../../styled-components/Theme/Theme";
import SubEvidenceDocProperties from "./SubEvidenceDocProperties";
import { useHistory } from "react-router-dom";
import TextAreaSideBar from "./TextAreaSideBar";

const TESTIMONY = "Testimony";
const COMMUNICATION = "Communication";
const DOCUMENT = "Document";
const IMAGE_MEDIA = "Image/Media";
const AFFIDAVIT = "Affidavit";
const OTHER = "Other";
const types = [
  { value: TESTIMONY, label: TESTIMONY },
  { value: COMMUNICATION, label: COMMUNICATION },
  { value: DOCUMENT, label: DOCUMENT },
  { value: IMAGE_MEDIA, label: IMAGE_MEDIA },
  { value: AFFIDAVIT, label: AFFIDAVIT },
  { value: OTHER, label: OTHER },
];

const SubEvidenceFurtherDetailsForm = ({
  setEvidenceObject,
  documentId,
  evidenceId,
  sub_evidence,
  annotManager,
  withLabel,
  caseObject,
  gotObject,
  caseId,
  callback,
}) => {
  const history = useHistory();
  const evidence = useSelector((state) => state.rightSideBar.editableObject);
  const [object_, setObject] = useState(
    gotObject
      ? { ...gotObject, ...{ objectives: evidence.objectives } }
      : evidence
  );
  const [subtypeOptions, setSubtypeOptions] = useState(null);
  const handleSubmit = () => {
    if (withLabel) {
      setEvidenceObject({
        priority: object_.priority,
        summary: object_.summary,
        preempt: object_.preempt,
        date: object_.date ? moment(object_.date).format("YYYY-MM-DD") : "",
        label: object_.label,
      });
    } else {
      setEvidenceObject({
        priority: object_.priority,
        summary: object_.summary,
        preempt: object_.preempt,
        date: object_.date ? moment(object_.date).format("YYYY-MM-DD") : "",
      });
    }
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const dispatch = useDispatch();

  const setPriority = (priority) => {
    setObject({ ...object_, ...{ priority } });
  };
  const setDate = (date) => {
    setObject({
      ...object_,
      ...{
        date: date && date !== "" ? moment(date).format("YYYY-MM-DD") : null,
      },
    });
  };
  const changeLabel = (e) => {
    setObject({ ...object_, ...{ label: e.target.value } });
  };
  const setEvidenceType = (select) => {
    setObject({ ...object_, ...{ type: select.value } });
  };
  const setEvidenceSubType = (select) => {
    setObject({ ...object_, ...{ subtype: select.value } });
  };
  useEffect(() => {
    if (object_.type) {
      switch (object_.type) {
        case "Testimony" || "Affidavit" || "Other":
          setSubtypeOptions(null);
          break;

        case "Communication":
          setSubtypeOptions(subtypeCommunicationList);
          break;

        case "Document":
          setSubtypeOptions(subtypeDocumentList);
          break;

        case "Image/Media":
          setSubtypeOptions(subtypeImageMediaList);
          break;

        default:
          setSubtypeOptions(null);
      }
    }
  }, [object_]);
  const priorityButtonsObjects = [
    {
      title: <Medium14Font>1</Medium14Font>,
      type: 1,
    },
    {
      title: <Medium14Font>2</Medium14Font>,
      type: 2,
    },
    {
      title: <Medium14Font>3</Medium14Font>,
      type: 3,
    },
    {
      title: <Medium14Font>4</Medium14Font>,
      type: 4,
    },
  ];
  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {withLabel ? (
          <div className="form-group">
            <InputNew
              name={"label"}
              placeholder={"Label"}
              label={"Label"}
              value={object_.label}
              onChangeHandler={changeLabel}
            />
          </div>
        ) : null}

        <div className="form-group">
          <div className="container-fluid">
            <RadioButtonGroupRounded
              checkedButton={object_.priority}
              setCheckedButton={setPriority}
              itemsObjects={priorityButtonsObjects}
              label="Priority"
            />
          </div>
        </div>
        {/*<div className="form-group">*/}
        {/*  <Label name={"Evidence Type"} />*/}
        {/*  <div className="container-fluid">*/}
        {/*    <SelectNew*/}
        {/*      options={types}*/}
        {/*      value={types.find((v) => v.value === object_.type)}*/}
        {/*      onChangeHandler={setEvidenceType}*/}
        {/*      placeholder={"Select Type"}*/}
        {/*      classNamePrefix="Select"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="form-group">
          <CustomReactDatepicker
            selected={object_.date ? moment(object_.date).toDate() : null}
            onChange={(date) => setDate(date)}
            dateFormat={"dd-MM-yyyy"}
            dateFormatCalendar={"dd-MM-yyyy"}
            label="Date"
            name="date"
            placeholderText="Select Date"
          />
        </div>
        {subtypeOptions ? (
          <div className="form-group">
            <Label name={"Sub-Type"} />
            <div className="container-fluid">
              <SelectNew
                options={subtypeOptions}
                value={
                  subtypeOptions !== null
                    ? subtypeOptions.filter(
                        (option) => option.value === object_.subtype
                      )
                    : null
                }
                onChangeHandler={setEvidenceSubType}
                placeholder={"Select"}
                classNamePrefix="Select"
              />
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <Label name={"Summary"} />
          <TextAreaSideBar
            withMargin
            placeholder={"Enter Summary"}
            value={object_.summary}
            name={"summary"}
            onChange={(e) => setObject({ ...object_, summary: e.target.value })}
          />
        </div>
        <div className="form-group">
          <Label name={"Pre-empt"} />
          <TextAreaSideBar
            withMargin
            placeholder={"Enter Pre-empt"}
            value={object_.preempt}
            name={"preempt"}
            onChange={(e) => setObject({ ...object_, preempt: e.target.value })}
          />
        </div>
      </div>
      <div className="form-group">
        <div className="container-fluid">
          <form
            onSubmit={handleSubmit}
            style={{ height: "86%", overflowY: "auto" }}
          >
            <Button clickHandler={handleSubmit} wide primary>
              Save
            </Button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default SubEvidenceFurtherDetailsForm;
