import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Label from "../AddCardForm/Label";
import Button from "../../buttons/Button/Button";
import styled, { keyframes, css } from "styled-components/macro";
import dragAndDrop from "../../../assets/img/svg/Drag and Drop.svg";
import { Bold18Font, Medium18Font } from "../../FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import {
  MODAL_ERROR_SIGN_IN,
  SAVE_MODAL_DATA,
  SET_CUSTOM_CLOSE_ACTION,
  SHOW_MODAL,
} from "../../../redux/types";
import { useDispatch } from "react-redux";
import {
  authoritiesBulkImportRequest,
  bulkImportApplicationPaperFiles,
  bulkImportEvidence,
  bulkImportEvidenceWithTopic,
  bulkImportEvidenceWithWitness,
  casesBulkImportRequest,
  getBundles,
} from "../../../containers/Auth/auth";
import { pulse } from "react-animations";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "../../../containers/BaseApp/Documents/Documents";
import PulseLoader from "react-spinners/PulseLoader";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import { useRouteMatch } from "react-router-dom";
import { route } from "../../../routes";
import ButtonNew from "../../buttons/Button/ButtonNew";
import NewBundleForm from "../DocumentForms/NewBundleForm";
import { maxFileSize } from "../../../utils";
const pulseAnimation = keyframes`${pulse}`;
const validTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const DashedDiv = styled.div`
  border-radius: 21px;
  padding: 30px;
  border: 3px dashed #dcdce6;
  background-color: #fcfcff;
  ${({ hoover }) =>
    hoover &&
    css`
      animation: 1.5s ${pulseAnimation};
    `}
`;

const ElementDiv = styled.div`
  margin-top: ${({ first }) => (first ? 0 : "10px")};
  display: flex;
  text-align: center;
  justify-content: center;
  margin-bottom: ${({ last }) => (last ? "20px" : "10px")};
`;

const AddBundleForm = ({
  caseId,
  applicationId,
  callback,
  witnessObject,
  sendDataWithFiles,
  hideBundle,
  noSaveButton,
  isXExamFlowIsFalse,
  isApplicationPapersBulkImportFlow,
  isCasesBulkImportFlow,
  isApplicationAuthoritiesBulkImportFlow,
  applicationObject,
}) => {
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [hoover, setHoover] = useState(false);
  const dispatch = useDispatch();
  const authoritiesBulkImport =
    useRouteMatch(`${route.baseApp.trialHub.authoritiesBulkImport}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.authoritiesBulkImport}`);
  const isAuthoritiesBulkImportFlow = !!(
    authoritiesBulkImport && authoritiesBulkImport.isExact
  );
  const [showNewBundlePanel, setShowNewBundlePanel] = useState(false);

  const handleSubmit = async (e) => {
    if (!isLoading) {
      e.preventDefault();
      // let resp = await setIsLoading(true);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
    setHoover(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHoover(false);
  };

  const onBundleChange = (select) => {
    setSelectedBundles(select);
  };

  const requestRouter = async (formData) => {
    if (witnessObject) {
      if (isXExamFlowIsFalse) {
        // link evidence to witness
        if (witnessObject.id) {
          return await bulkImportEvidenceWithWitness(
            caseId,
            witnessObject.id,
            dispatch,
            formData
          );
        } else {
          return await bulkImportEvidence(caseId, dispatch, formData);
        }
      } else {
        // xExam
        return await bulkImportEvidenceWithTopic(
          caseId,
          witnessObject.witness.id,
          witnessObject.fake_id,
          dispatch,
          formData
        );
      }
    } else {
      // documents
      if (
        isAuthoritiesBulkImportFlow ||
        isApplicationAuthoritiesBulkImportFlow
      ) {
        if (isApplicationAuthoritiesBulkImportFlow) {
          formData.append("id_application", applicationObject.id);
        }
        return await authoritiesBulkImportRequest(caseId, dispatch, formData);
      } else if (isApplicationPapersBulkImportFlow) {
        return await bulkImportApplicationPaperFiles(
          applicationId,
          caseId,
          dispatch,
          formData
        );
      } else if (isCasesBulkImportFlow) {
        return await casesBulkImportRequest(caseId, dispatch, formData);
      } else {
        return await bulkImportEvidence(caseId, dispatch, formData);
      }
    }
  };

  const handleFiles = async (files) => {
    let submitFiles = [];
    if (files.length) {
      files.forEach(function (file_) {
        if (validateFile(file_, validTypes)) {
          if (file_.size < maxFileSize) {
            submitFiles.push(file_);
          } else {
            dispatch({ type: SAVE_MODAL_DATA, payload: "File is to big" });
            dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
            return false;
          }
        } else {
          dispatch({
            type: SAVE_MODAL_DATA,
            payload:
              "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format.",
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
          return false;
        }
      });
    }
    if (submitFiles.length) {
      let formData = new FormData();
      submitFiles.forEach(function (file, index) {
        formData.append(index, file);
      });
      formData.append(
        "bundles",
        JSON.stringify(selectedBundles ? selectedBundles.map((v) => v.value) : [])
      );
      const resp = await requestRouter(formData);
      if (resp) {
        callback(resp);
      }
    }
  };

  const onClickHandler = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const fileInput = useRef(null);

  const validateFile = (file, validTypes) =>
    validTypes.indexOf(file.type) !== -1;

  const addFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const saveData = (data) => {
    if (isAuthoritiesBulkImportFlow || isApplicationAuthoritiesBulkImportFlow) {
      setBundles(data.filter((v) => v.class_name === AUTHORITIES_DOCUMENTS));
    } else if (isApplicationPapersBulkImportFlow) {
      setBundles(data.filter((v) => v.class_name === APPLICATION_DOCUMENTS));
    } else if (isCasesBulkImportFlow) {
      setBundles(data.filter((v) => v.class_name === CASE_DOCUMENTS));
    } else {
      setBundles(data.filter((v) => v.class_name === EVIDENCE_DOCUMENTS));
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setDataLoading(true);
      const resp = await getBundles(dispatch, caseId);
      saveData(resp);
      setDataLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    if (showNewBundlePanel === false) {
      // dispatch({ type: SET_AFTERCLOSE_HANDLER, payload: () => {} });
    }
  }, [showNewBundlePanel]);

  let selects = bundles.map((e) => {
    return { label: e.label, value: e.id };
  });

  const onClickAddBundleHandler = () => {
    setShowNewBundlePanel(true);
    dispatch({
      type: SET_CUSTOM_CLOSE_ACTION,
      payload: () => {
        setShowNewBundlePanel(false);
        dispatch({ type: SET_CUSTOM_CLOSE_ACTION, payload: false });
      },
    });
  };

  const onClickSaveNewBundleHandler = (bundle) => {
    const bundlesListCopy = [...bundles, bundle];
    saveData(bundlesListCopy);
    setShowNewBundlePanel(false);
  };

  if (dataLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return showNewBundlePanel ? (
    <NewBundleForm
      idCase={caseId}
      addNewBundleToList={onClickSaveNewBundleHandler}
      noHideRSB
      noHeader
      setShowNewBundlePanel={setShowNewBundlePanel}
    />
  ) : (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {!hideBundle ? (
          <div className="form-group">
            <Label name={"Assign to Bundle (Optional)"} />
            <div className="container-fluid">
              <SelectAnotherTry
                style={{ padding: 0 }}
                options={selects}
                isMulti
                isClearable={false}
                placeholder={"Select bundle"}
                // defaultValue={selectedBundles}
                defaultValue={
                  selectedBundles && selectedBundles.length
                    ? selects.filter((v) => {
                        return selectedBundles.find((b) => b.id === v.value);
                      })
                    : []
                }
                reactSelectStyles={{
                  control: (provided) => ({
                    ...provided,
                    height: "unset !important",
                    minHeight: "42px !important",
                    borderRadius: "21px !important",
                  }),
                }}
                onChange={onBundleChange}
              />
            </div>
          </div>
        ) : null}
        <div className="form-group" style={{ marginBottom: "60px" }}>
          <div className="container-fluid d-flex justify-content-center">
            <ButtonNew tertiary wide clickHandler={onClickAddBundleHandler}>
              New Bundle
            </ButtonNew>
          </div>
        </div>
        <div className="form-group">
          <div className="container-fluid">
            <DashedDiv
              hoover={hoover}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={addFile}
            >
              <ElementDiv first>
                <img src={dragAndDrop} alt="" />
              </ElementDiv>
              <ElementDiv>
                <Bold18Font textColor={theme.colors.dark}>
                  Drag & Drop Documents here
                </Bold18Font>
              </ElementDiv>
              <ElementDiv last>
                <Medium18Font textColor={theme.colors.darkOpacity}>
                  Or
                </Medium18Font>
              </ElementDiv>
              <Button
                clickHandler={onClickHandler}
                loading={isLoading}
                disabled={isLoading}
                wide
                primary
              >
                Upload file
              </Button>
              <input
                ref={fileInput}
                multiple={true}
                type="file"
                accept={validTypes.join(",")}
                onChange={() => {
                  if (fileInput.current) {
                    handleFiles(fileInput.current.files);
                  }
                }}
                style={{ display: "none" }}
              />
            </DashedDiv>
          </div>
        </div>
      </div>

      {!noSaveButton ? (
        <Fragment>
          <DividerHorizontal />
          <div className="form-group">
            <div className="container-fluid">
              <Button
                clickHandler={handleSubmit}
                loading={isLoading}
                disabled={isLoading}
                wide
                primary
              >
                Save
              </Button>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

AddBundleForm.propTypes = {
  caseId: PropTypes.number.isRequired,
  callback: PropTypes.func,
  witnessObject: PropTypes.object,
  hideBundle: PropTypes.bool,
  noSaveButton: PropTypes.bool,
};

export default AddBundleForm;
