import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { NotebookContext } from "@/context";
import { useEditor, ReactNodeViewRenderer } from "@tiptap/react";
import { useLocation } from "react-router-dom";
import { mergeAttributes } from "@tiptap/core";
import { useParams } from "react-router-dom";
import { DOMParser } from "prosemirror-model";

import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import Tag from "@editor/components/Tag";
import History from "@tiptap/extension-history";
import BubbleMenu from "@tiptap/extension-bubble-menu";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";
import FontFamily from "@tiptap/extension-font-family";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import ListItem from "@tiptap/extension-list-item";
import Image from "@tiptap/extension-image";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Color } from "@tiptap/extension-color";

import { redo, undo, redoDepth } from "prosemirror-history";

import tags from "@editor/extensions/tags";
import custom from "@editor/extensions/tags/custom";

import RegexMention from "@editor/extensions/regex-mention";
import FontSizeExtension from "@editor/extensions/font-size";
import TagMarkExtension from "@editor/extensions/tag-mark";
import SubstituteTextExtension from "@editor/extensions/substitute-text";
import CreateTagNode from "@editor/extensions/create-tag-node";
import TagCreationExtension from "@editor/extensions/tag-creation";

import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

let lastRun = 0;

function throttleAsync(fn, wait) {
  async function throttled(...args) {
    const currentWait = lastRun + wait - Date.now();
    const shouldRun = currentWait <= 0;

    if (shouldRun) {
      lastRun = Date.now();
      console.log("run " + lastRun);
      return await fn(...args);
    } else {
      return await new Promise(function (resolve) {
        setTimeout(function () {
          console.log("resolve");
          resolve(throttled());
        }, currentWait);
      });
    }
  }

  return throttled;
}

var typingTimer; //timer identifier
var doneTypingInterval = 500; //time in ms, 5 seconds for example

const EditorContext = createContext();

const EditorProvider = ({ children }) => {
  const {
    setEvidenceItems,
    selectedChapter,
    caseLibrary,
    notebook,
    setCaseLibrary,
    lastActionConnect,
    setLastActionConnect,
  } = useContext(NotebookContext);
  const [draggingItem, setDraggingItem] = useState(undefined);
  const [hoveredItem, setHoveredItem] = useState(undefined);

  const [newConnectionPos, setNewConnectionPos] = useState(undefined);
  const [newConnectionElement, setNewConnectionElement] = useState(undefined);

  const [lastStepCreateTag, setLastStepCreateTag] = useState(undefined);
  const [canRedo, setCanRedo] = useState(true);

  const [isCreatingTag, setCreatingTag] = useState(false);
  const [creatingTagPos, setCreatingTagPos] = useState(undefined);

  const { pathname } = useLocation();
  const caseId = pathname.split("/")[3];

  const [content, setContent] = useState("");

  const alertUser = (e) => {
    var start = Date.now(),
      now = start;
    var delay = 1000; // msec
    while (now - start < delay) {
      now = Date.now();
    }

    // e.returnValue = "wait 5 secs";
    delete e["returnValue"];
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    if (selectedChapter) {
      editor.commands.setContent(selectedChapter.content || "");
      setContent(selectedChapter.content || "");
    }
  }, [selectedChapter, notebook]);

  const saveContent = async (content) => {
    if (selectedChapter && selectedChapter.id) {
      const config = {
        headers: {
          Authorization: `Bearer ${await loadAccessToken()}`,
        },
      };

      const notebookId = pathname.split("/")[5];

      const res = await axios
        .post(
          `/api/cases/${caseId}/note-book/${notebookId}/chapter/${selectedChapter.id}`,
          { content },
          config
        )
        .catch((err) => {
          alert("error: " + err.message);
        });
    }
  };

  useEffect(() => {
    if (content !== undefined) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => saveContent(content), doneTypingInterval);
      // const throttledRun = throttleAsync(() => saveContent(content), 500);
      // throttledRun();
      // saveContent(content);
    }
  }, [content]);

  const onCreateTag = async (editor, pos, posAfter) => {
    let nodeBefore;
    let to;

    if (pos) {
      editor.commands.setTextSelection(pos.pos);
      editor.commands.joinForward();

      if (editor.state.selection.$from.nodeAfter) {
        editor.commands.setTextSelection(
          pos.pos + editor.state.selection.$from.nodeAfter.text.length
        );
      }

      nodeBefore = editor.state.selection.$from.nodeBefore;
      to = editor.state.selection.to;
    } else {
      nodeBefore = editor.state.selection.$from.nodeBefore;
      to = editor.state.selection.anchor;
    }

    const nodeAttrs = nodeBefore.marks[0].attrs;
    const tagType = nodeAttrs.tagType;

    const prevText = nodeBefore && nodeBefore.text;

    const caseId = window.location.pathname.split("/")[3];
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    // return;

    // const tagType = "evidence";
    const label = prevText;

    let res;

    if (tagType === "evidence") {
      res = await axios
        .post(`/api/cases/${caseId}/evidence`, { label: label }, config)
        .catch((error) => {
          alert(error);
        });
    }

    if (tagType === "witness") {
      res = await axios
        .post(`/api/cases/${caseId}/witness`, { name: label }, config)
        .catch((error) => {
          alert(error);
        });
    }

    const newTag = {
      tagId: res.data.data.id,
      id: res.data.data.label,
      tagType,
    };

    if (res && res.data && res.data.data) {
      if (tagType === "evidence") {
        setCaseLibrary((caseLibrary) => ({
          ...caseLibrary,
          Evidence: [
            ...caseLibrary.Evidence,
            {
              id: res.data.data.id,
              label: res.data.data.label,
              type: "Evidence",
            },
          ],
        }));
        // setEvidenceItems((prev) => [...prev, newTag]);
      }
      if (tagType === "witness") {
        setCaseLibrary((caseLibrary) => ({
          ...caseLibrary,
          Witness: [
            ...caseLibrary.Witness,
            {
              id: res.data.data.id,
              label: res.data.data.name,
              type: "Witness",
            },
          ],
        }));
        // setEvidenceItems((prev) => [...prev, newTag]);
      }
    }

    editor
      .chain()
      .insertContentAt(
        {
          // from: editor.state.selection.anchor,
          from: to - prevText.length,
          to: to,
        },
        `<tag data-id="${label}" data-tagType=${tagType} data-tagId=${newTag.tagId} className={tag tag-${tagType}}>${label}</tag> `
      )
      .setMeta("createTag", newTag)
      .run();

    if (pos) {
      editor.commands.setTextSelection(posAfter);
    }

    setCreatingTag(false);
    return true;
  };

  const searchForExistingTags = (text, caseLibrary) => {
    let replacedText = text;

    caseLibrary.Witness &&
      caseLibrary.Witness.forEach((item) => {
        replacedText = replacedText.replace(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.Note &&
      caseLibrary.Note.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.Authority &&
      caseLibrary.Authority.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.Party &&
      caseLibrary.Party.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.ExamLine &&
      caseLibrary.ExamLine.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.Topic &&
      caseLibrary.Topic.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.Objective &&
      caseLibrary.Objective.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation>$3`
        );
      });
    caseLibrary.KeySection &&
      caseLibrary.KeySection.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Paper &&
      caseLibrary.Paper.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Evidence &&
      caseLibrary.Evidence.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.KeyFact &&
      caseLibrary.KeyFact.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Issue &&
      caseLibrary.Issue.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.SubTheme &&
      caseLibrary.SubTheme.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Theme &&
      caseLibrary.Theme.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Proof &&
      caseLibrary.Proof.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.Relief &&
      caseLibrary.Relief.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.CausesOfActionDefenseElement &&
      caseLibrary.CausesOfActionDefenseElement.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.CausesOfActionElement &&
      caseLibrary.CausesOfActionElement.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });
    caseLibrary.CausesOfAction &&
      caseLibrary.CausesOfAction.forEach((item) => {
        replacedText = replacedText.replaceAll(
          new RegExp(`(^|\\s)(${item.label})(\\s|$)`, "gi"),
          `$1<creation data-id=${
            item.label
          } data-tagType=${item.type.toLowerCase()} data-tagId=${
            item.id
          } className="tag tag-${item.type.toLowerCase()}"></creation> $3`
        );
      });

    // <tag data-id="${ tag.label }" data-tagType=${tag.type.toLowerCase()} data-tagId=${ tag.id } className={tag tag-${tag.type.toLowerCase()}}>${tag.label}</tag>
    return replacedText;
  };

  useEffect(() => {
    const handlePaste = (e) => onPaste(e, caseLibrary, editor);
    window.addEventListener("paste", handlePaste);
    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, [caseLibrary, editor]);

  const onPaste = (e, caseLibrary) => {
    var path = e.path || (e.composedPath && e.composedPath());

    if (path && path.some((el) => el.className.indexOf("ProseMirror") > -1)) {
      e.preventDefault();
      e.stopPropagation();

      let paste = (e.clipboardData || window.clipboardData).getData("text");

      const text = searchForExistingTags(paste, caseLibrary);

      editor.commands.insertContent(text);
    } else {
      //
    }
  };

  const editor = useEditor({
    editorProps: {
      handleDOMEvents: {
        drop: (view, e) => {
          // if on tag dropped onto other - don't change position
          // (stop default behavior)
          if (e.target.className.indexOf("tag") > -1) {
            e.preventDefault();
          }
        },
      },

      handlePaste(view, e, b, c) {
        // const pastedText = e.clipboardData.getData("text");
        // searchForExistingTags(pastedText);
        // const { state, dispatch } = view;

        // const transaction = view.state.tr.insert("hihi");

        // let firstNode;

        // view.state.doc.nodesBetween(0, 100, (node) => {
        //   if (!firstNode) {
        //     firstNode = node;
        //   }
        //   console.log("node");
        //   console.log(node);
        // });

        // const transaction = view.state.tr.replaceWith(
        //   state.selection.from,
        //   state.selection.to,
        //   "ayo"
        //   // `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
        //   // state.selection.from,
        //   // state.selection.to
        // );

        // const transaction = view.state.tr.setMeta(
        //   "replace-paste-tag",
        //   pastedText
        //   // `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
        //   // state.selection.from,
        //   // state.selection.to
        // );

        const transaction = view.state.tr.setMeta("paste", true);
        view.dispatch(transaction);
        return true;
        // return false;
      },

      // handlePaste: (view, e, slice) => {
      //   console.log("paste");
      //   return false;
      // },

      // clipboardTextParser: (str, $context) => {
      //   console.log("parse yo");
      //   console.log(str);
      //   return "yoooo";
      //   return false;
      //   if (/^https?:\/\/[\S]+\.[\S]+$/gim.test(str) == false) return;
      //   const doc = document.cloneNode(false);
      //   const dom = doc.createElement("div");
      //   dom.innerHTML = `
      // <p>${str} is url?</p>
      // `;
      //   const parser = DOMParser.fromSchema(this.editor.view.state.schema);
      //   return parser.parseSlice(dom, {
      //     preserveWhitespace: false,
      //     context: $context,
      //   });
      // },

      // transformPastedText(text) {
      //   console.log("pasted text");

      //   return text.replaceAll(
      //     "yo",
      //     `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
      //   );
      // },
      // transformPastedHTML(html) {
      //   console.log("pasted html");

      //   return html.replaceAll(
      //     "yo",
      //     `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
      //   );
      //   return html.replace("yo", "html");
      // },

      // transformPasted(text, b, c) {
      //   console.log("PASTEDDDDDDDDDDDDDDDDDD");
      //   console.log(text);
      //   console.log(b);
      //   console.log(c);
      //   return text.replaceAll(
      //     "yo",
      //     `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
      //   );
      // },

      // transformPastedHTML(text) {
      //   console.log("PASTEDDDDDDDDDDDDDDDDDD");
      //   return text.replaceAll(
      //     "test-yo",
      //     `<tag data-id="issue" data-tagType="issue" data-tagId="9"></tag>`
      //   );
      // },
    },
    onCreate({ editor }) {
      window.editor = editor;
      editor.view.dom.focus();
      editor.commands.focus("end");
    },
    onSelectionUpdate({ editor }) {
      const { selection } = editor.view.state;
      const { anchor } = selection;
    },
    onUpdate: ({ editor, transaction }) => {
      window.localStorage.setItem("lastConnectTag", null);
      // console.log(editor.getHTML());
      // when mark is active, but content for this mark is empty - remove this mark
      if (
        transaction.storedMarks &&
        transaction.storedMarks[0] &&
        transaction.storedMarks[0].type.name === "tag-creation"
      ) {
        editor.commands.unsetMark("tag-creation");
      }

      // send the content to an API here
      const html = editor.getHTML();
      setContent(html);
      console.log(html);

      if (transaction.meta.createTag) {
        window.localStorage.setItem(
          "lastCreatedTag",
          JSON.stringify(transaction.meta.createTag)
        );
      } else {
        window.localStorage.setItem("lastCreatedTag", undefined);
      }

      if (!transaction.meta.history$) {
        window.localStorage.setItem("canRedo", true);
      }

      // if (
      //   transaction.meta.insertTrailingSpace !== true &&
      //   transaction.meta.uiEvent !== "drop"
      // ) {
      //   const doesEndWithEmptyParagraph = html.endsWith("<p></p>");
      //   if (!doesEndWithEmptyParagraph) {
      //     const { selection } = editor.view.state;
      //     const { anchor } = selection;

      //     editor
      //       .chain()
      //       .focus("end")
      //       .insertContentAt(editor.state.doc.nodeSize, "<p></p>")
      //       .setMeta("insertTrailingSpace", true)
      //       .run();

      //     // editor.commands.setContent(`${html}<p></p>`);
      //     // editor.commands.setTextSelection(anchor);
      //   }
      // }
    },
    autofocus: "end",
    autoFocus: "end",
    extensions: [
      Highlight.configure({ multicolor: true }),
      FontFamily,
      Underline,
      BulletList,
      OrderedList,
      ListItem,
      Image,
      // Gapcursor,
      Table.configure({
        resizable: true,
        allowTableNodeSelection: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      TextStyle,
      Color,
      BubbleMenu.configure({
        element: document.querySelector(".menu"),
      }),
      // CustomHistory(lastStepCreateTag),
      History.extend({
        addCommands() {
          return {
            undo: () => async ({ state, dispatch, ...other }) => {
              if (dispatch) {
                if (
                  window.localStorage.getItem("lastConnectTag") &&
                  JSON.parse(window.localStorage.getItem("lastConnectTag")) !==
                    null
                ) {
                  const conObj = JSON.parse(
                    window.localStorage.getItem("lastConnectTag")
                  );

                  const config = {
                    headers: {
                      Authorization: `Bearer ${await loadAccessToken()}`,
                    },
                  };

                  const res = await axios.get(
                    `/api/cases/${caseId}/delete?fromId=${
                      conObj.connected.tagId
                    }&fromType=${capitalize(conObj.connected.tagType)}&toId=${
                      conObj.connecting.tagId
                    }&toType=${capitalize(conObj.connecting.tagType)}`,
                    config
                  );

                  window.localStorage.setItem("lastConnectTag", null);
                  window.dispatchEvent(new Event("storage"));
                  // alert("succesfully disconnected");

                  // then: alert user that objects are disconneted
                } else {
                  undo(state, dispatch);
                  if (window.localStorage.getItem("lastCreatedTag")) {
                    if (
                      window.localStorage.getItem("lastCreatedTag") ===
                      "undefined"
                    ) {
                      return;
                    }
                    const lastTag = JSON.parse(
                      window.localStorage.getItem("lastCreatedTag")
                    );
                    let res;
                    const caseId = window.location.pathname.split("/")[3];

                    const config = {
                      headers: {
                        Authorization: `Bearer ${await loadAccessToken()}`,
                      },
                    };

                    res = await axios
                      .delete(
                        `/api/cases/${caseId}/notes/objects?id=${
                          lastTag.tagId
                        }&type=${capitalize(
                          lastTag.tagType
                        )}&id_case=${caseId}`,
                        config
                      )
                      .catch((error) => {
                        alert(error);
                      });

                    if (lastTag.tagType === "evidence") {
                      setEvidenceItems((items) =>
                        items.filter((i) => i.tagId !== lastTag.tagId)
                      );
                    }

                    window.localStorage.setItem("canRedo", false);
                    window.localStorage.setItem("lastCreatedTag", undefined);
                    // editor.commands.redoDepth(0);
                    // editor.commands.undo();
                  } else {
                    // window.localStorage.setItem("canRedo", true);
                  }

                  // return
                }
              }
            },
            redo: () => ({ state, dispatch }) => {
              if (window.localStorage.getItem("canRedo") == "true") {
                return redo(state, dispatch);
              }
            },
          };
        },
      }),
      StarterKit.configure({
        // Disable an included extension
        history: false,
      }),
      // Document,
      // Paragraph,
      // Text,
      ...tags.map((tag) =>
        CustomMention.extend({ name: tag.label }).configure({
          atom: true,
          selectable: false,
          HTMLAttributes: {
            class: `tag tag-${tag.label}`,
          },
          suggestion: tag.extension,
          renderLabel: ({ options, node }) => {
            return `${node.attrs.label ?? node.attrs.id}`;
          },
        })
      ),
      RegexMention.configure({
        HTMLAttributes: {
          class: "tag tag-evidence",
        },
        suggestion: custom({ caseId }),
        renderLabel({ options, node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
      }),
      FontSizeExtension,
      TagMarkExtension.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              const editor = this.editor;
              if (editor && editor.isActive("tag-creation")) {
                onCreateTag(editor);
                return true;
              }
            },
            Escape: () => {
              if (this.editor.isActive("tag-creation")) {
                this.editor.commands.unsetMark("tag-creation", {
                  extendEmptyMarkRange: true,
                });
                return true;
              }
            },
          };
        },
        onCancel: () => {},
      }),
      // SubstituteTextExtension(),
      TagCreationExtension.configure({
        renderLabel({ options, node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
      }),
    ],

    content: content,
    // content: `<p>hey <creation data-id="test" data-tagType="evidence" data-tagid="1"></creation></p>`,
  });

  useEffect(() => {
    const onClick = (e) => {
      if (isCreatingTag) {
        if (editor && !editor.isActive("tag-creation")) {
          const posAfter = editor.state.selection.anchor;
          onCreateTag(editor, creatingTagPos, posAfter);
        }
      }
    };

    window.addEventListener("mousedown", onClick);
    return () => {
      window.removeEventListener("mousedown", onClick);
    };
  }, [editor, isCreatingTag, creatingTagPos]);

  useEffect(() => {
    editor && editor.commands.focus();
  }, [editor]);

  return (
    <EditorContext.Provider
      value={{
        editor,
        // setEditor,

        draggingItem,
        setDraggingItem,
        hoveredItem,
        setHoveredItem,

        newConnectionPos,
        newConnectionElement,
        setNewConnectionPos,
        setNewConnectionElement,

        lastStepCreateTag,
        setLastStepCreateTag,

        isCreatingTag,
        setCreatingTag,
        creatingTagPos,
        setCreatingTagPos,

        content,
        setContent,
        saveContent,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

const CustomHistory = (lastStep) =>
  History.extend({
    addCommands() {
      return {
        redoDepth: () => ({ state, dispatch }) => {
          return redoDepth(state);
        },
        undo: () => ({ state, dispatch, a, b, c }) => {
          return undo(state, dispatch);
        },
        redo: () => ({ state, dispatch }) => {
          return redo(state, dispatch);
        },
      };
    },
  });

const CustomMention = Mention.extend({
  name: "customMention",
  group: "inline",
  selectable: true,
  draggable: true,
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText("", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isMention;
        }),
    };
  },

  parseHTML() {
    return [
      {
        tag: "tag",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["tag", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Tag);
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            "data-id": attributes.id,
          };
        },
      },
      tagType: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagType"),
        renderHTML: (attributes) => {
          if (!attributes.tagType) {
            return {};
          }
          return {
            "data-tagType": attributes.tagType,
          };
        },
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }
          return {
            "data-label": attributes.label,
          };
        },
      },
      tagId: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-tagId"),
        renderHTML: (attributes) => {
          if (!attributes.tagId) {
            return {};
          }
          return {
            "data-tagId": attributes.tagId,
          };
        },
      },
    };
  },
});

export { EditorProvider, EditorContext };
