import React from "react";
import {withTheme} from "styled-components/macro";

import unpaidIcon from "../../assets/img3/svg/unpaid-icon.svg";

import { Bold14Font } from "../FontsNewComponent/Fonts";

const UnpaidBadge = ({theme}) => {
  return (
    <div className="d-flex">
      <img src={unpaidIcon} alt="" style={{ marginRight: "10px" }} />
      <Bold14Font textColor={theme.colors.burgundy}>Unpaid</Bold14Font>
    </div>
  );
};

export default withTheme(UnpaidBadge);
