// import React, {Fragment, useState} from "react";
import React, { Fragment, Component, createRef } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { theme } from "../../../styled-components/Theme/Theme";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import InputStyled from "../../Input/styled/InputStyled";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import { connect } from "react-redux";
import fa from "moment/locale/fa";
import InputNew from "../../InputNew/InputNew";
import TextAreaSideBar from "../DocumentForms/TextAreaSideBar";

class EditableStringClassComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: this.props.object,
      edit: props.editState,
      isObjectString: typeof this.props.object === "string",
    };
  }

  setEdit = (state) => {
    this.setState((prevState) => ({
      ...prevState,
      edit: state,
    }));
  };

  doneEditing = () => {
    if (
      this.state.isObjectString ? !this.props.object : !this.props.object.name
    ) {
      handleErrorMessage("Field shouldn't be empty", this.props.dispatch);
      return false;
    }
    if (
      this.state.isObjectString
        ? this.props.object.length > 255
        : this.props.object.name.length > 255
    ) {
      handleErrorMessage("Field value is to long", this.props.dispatch);
      return false;
    }
    this.setEdit(false);
    if (this.props.doneEditingCallback) {
      this.props.doneEditingCallback(this.props.index, this.state.initState);
    }
    return true;
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.doneEditing();
    }
  };
  render() {
    let value;
    if (!this.props.editName) {
      value = this.state.initState;
    } else {
      value = this.state.isObjectString
        ? this.props.object
        : this.props.object.name;
    }
    if (this.state.edit) {
      return (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between w-100">
            {this.props.type === "textarea" ? (
              <TextAreaSideBar
                required
                noPadding
                value={value}
                onKeyDown={this._handleKeyDown}
                onChange={(e) => {
                  !this.props.editName
                    ? this.setState({ initState: e.target.value })
                    : this.props.editName(
                        this.props.index,
                        e.target.value,
                        this.props.object.id
                      );
                }}
                autoFocus={!this.props.dontFocus}
              />
            ) : (
              <InputNew
                withoutContent={true}
                required
                maxLength={255}
                value={value}
                onKeyDown={this._handleKeyDown}
                onChange={(e) => {
                  !this.props.editName
                    ? this.setState({ initState: e.target.value })
                    : this.props.editName(
                        this.props.index,
                        e.target.value,
                        this.props.object.id
                      );
                }}
                autoFocus={!this.props.dontFocus}
              />
            )}
            <div className="d-flex">
              <ListActionButton
                clickHandler={() => {
                  this.doneEditing();
                }}
                className={"ml-2"}
                type={"done"}
              />
              {this.props.deleteAction ? (
                <ListActionButton
                  clickHandler={() => {
                    this.props.deleteAction(
                      this.props.index,
                      this.props.object.id
                    );
                  }}
                  type={"delete"}
                />
              ) : null}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between w-100">
            <Medium14Font
              style={{
                lineHeight: "28px",
                maxWidth: "210px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              title={
                this.state.isObjectString
                  ? this.props.object
                  : this.props.object.name
              }
              textColor={theme.colors.dark}
              as="div"
            >
              {this.state.isObjectString
                ? this.props.object
                : this.props.object.name}
            </Medium14Font>
            <div className="d-flex">
              <ListActionButton
                clickHandler={() => {
                  if (this.props.startEditingCallback) {
                    this.props.startEditingCallback();
                  }
                  this.setEdit(true);
                }}
                className={"ml-2"}
                type={"edit"}
              />
              {this.props.deleteAction ? (
                <ListActionButton
                  clickHandler={() => {
                    this.props.deleteAction(
                      this.props.index,
                      this.props.object.id
                    );
                  }}
                  type={"delete"}
                />
              ) : null}
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapDispatchToProps = {
  dispatch: (data) => data,
};

EditableStringClassComponent.propTypes = {
  object: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  index: PropTypes.number.isRequired,
  editState: PropTypes.bool,
  dontFocus: PropTypes.bool,
  deleteAction: PropTypes.func.isRequired,
  editName: PropTypes.func.isRequired,
  /**
   * Execute callback func after start editing btn pressed.
   * Can used to close previous editing string
   */
  startEditingCallback: PropTypes.func,
  /**
   * Execute callback func after done editing btn pressed.
   * Can used to send data to API
   */
  doneEditingCallback: PropTypes.func,
};

export default connect(null, mapDispatchToProps, null, {
  forwardRef: true,
})(withTheme(EditableStringClassComponent));
