// Doc label – input field
// Doc type – dropdown or radio buttons
// Issue date – date
// Probative status – dropdown or radio buttons
// Attachment

// Party – reuse component and panel
// Reliefs and Proofs – reuse component and panel

import React, { useState, useEffect, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import { handleFlatRelevanceElements } from "@/utils/function";
import {
  loadAccessToken,
  createApplicationPaperFileById,
  getApplicationById,
} from "@/containers/Auth/auth";
import { maxFileSize } from "@/utils";
import moment from "moment";

import { Medium14Font } from "@/components/FontsNewComponent/Fonts";
import { parseISO } from "date-fns";
import axios from "axios";
import CloseIcon from "@/components/svgIcons/CloseIcon";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
import CustomReactDatepicker from "@/components/inputs/CustomReactDatepicker";
import TextArea from "@/components/TextArea/TextArea";
import UploadSingleFileInput from "@/components/inputs/UploadSingleFileInput";
import ButtonAnotherTry from "@/components/buttons/Button/ButtonAnotherTry";
import EditObjectPanel from "@/components/Notebook/EditObjectPanel";

import { Container, ButtonContainer, Column } from "./styled";
import {
  SHOW_RIGHT_SIDE_BAR,
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
} from "@/redux/types";
import { Select } from "antd";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";

import AssociateElementsThemesIssuesForm from "@/components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";
import AssociationTable, {
  AssociationTableListElement,
} from "@/components/AssociationTable";

import AllocateParties, {
  addPartyTypeField,
  groupPartiesByType,
} from "@/components/forms/ApplicationsHubForms/AllocateParties";
import AssociateProofs from "@/components/forms/ApplicationsHubForms/AssociateProofs";

const MOTION = "Motion";
const AFFIDAVIT = "Affidavit";
const EXHIBIT = "Exhibit";
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";

const typeOptions = [
  {
    label: MOTION,
    value: MOTION,
  },
  {
    label: AFFIDAVIT,
    value: AFFIDAVIT,
  },
  {
    label: EXHIBIT,
    value: EXHIBIT,
  },
];

const statusOptions = [
  {
    label: SUPPORTING,
    value: SUPPORTING,
  },
  {
    label: OPPOSING,
    value: OPPOSING,
  },
];

const EditPaper = (props) => {
  const prevWitObj = useSelector((state) => state.rightSideBar.editableObject);
  const [paperObject, setPaperObject] = useState({ ...prevWitObj } || {});

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  const { caseObject, setCaseObject, notebook } = useContext(NotebookContext);
  const applicationId = notebook.application.fake_id;

  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];

  const [file, setFile] = useState(new File([""], "Select file"));
  console.log(paperObject);
  console.log(paperObject);
  console.log(paperObject);

  useEffect(() => {
    if (prevWitObj) {
      const newObj = { ...paperObject, ...prevWitObj };
      setPaperObject((oldWit) => ({ ...oldWit, ...prevWitObj }));
    }
  }, [prevWitObj]);

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  useEffect(() => {
    if (!paperObject || Object.keys(paperObject).length === 0) {
      fetchObject();
    } else {
      const object = paperObject;

      setPaperObject((wit) => ({ ...wit, ...object }));

      if (object.file_new) {
        setFile(object.file_new);
      } else if (object.file) {
        if (object.file) {
          setFile(new File([""], object.file));
        }
      }
    }
  }, []);

  const fetchObject = async () => {
    setDataLoading(true);
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(
        `/api/cases/${caseId}/applications/${applicationId}/papers/${props.tagId}`,
        config
      )
      .then((res) => {
        setDataLoading(false);
        if (res && res.data && res.data.data) {
          const object = res.data.data;

          setPaperObject((wit) => ({ ...object, ...wit }));

          if (object.file) {
            setFile(new File([""], object.file));
          }
        }
      });
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  const saveResult = async () => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let body = {
      ...paperObject,
    };

    delete body.file_new;

    let endpoint = `/api/cases/${caseId}/applications/${applicationId}/papers/${props.tagId}`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (file.size !== 0) {
      let formData = new FormData();
      formData.append("file", file);
      const fileResp = await createApplicationPaperFileById(
        props.tagId,
        applicationId,
        caseId,
        dispatch,
        formData
      );
    }

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "paper" ||
                  subNode.type === "comment" ||
                  subNode.type === "witness" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "evidence"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: paperObject.label },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.Paper.findIndex((i) => i.id == props.tagId);
    const newPaper = [
      ...caseLibrary.Paper.slice(0, itemIndex),
      {
        ...caseLibrary.Paper[itemIndex],
        label: paperObject.label,
      },
      ...caseLibrary.Paper.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      Paper: newPaper,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const updatePaperObject = (data) => {
    const obj = { ...paperObject, ...data };
    setPaperObject(obj);

    console.log("here");
    console.log(obj.parties);
    console.log(groupPartiesByType(obj.parties ?? []));
    setGroupedPartiesByTypeObject(groupPartiesByType(obj.parties ?? []));
    dispatch({
      type: SET_EDITABLE_OBJECT,
      editableObject: {
        ...obj,
        // witnesses_res: witnesses,
      },
    });
  };

  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      // type: SHOW_RIGHT_SIDE_BAR_TWO,
      url: history.location.pathname,
      rightBarWidth: "18%",
      editableObject: {
        ...paperObject,
        // witnesses_res: witnesses,
      },
      content: (
        <AssociateElementsThemesIssuesForm
          key={paperObject.id}
          caseId={caseId}
          updateCaseObject={(d) => {
            setCaseObject((obj) => ({ ...obj, d }));
            setPaperObject((obj) => ({ ...obj, case: { ...obj.case, d } }));
          }}
          evidenceObject={paperObject}
          setEvidenceObject={(data) => {
            updatePaperObject(data);
          }}
          selectedMenuTab={index}
          addToSwapContent={[
            <EditObjectPanel {...props} paperObject={paperObject} />,
          ]}
          rightBarWidth={"18%"}
          // otherSidebar
        />
      ),
      swapContent: {
        content: <EditObjectPanel paperObject={paperObject} {...props} />,
      },
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  //
  //
  // PARTY START //
  //
  //

  const [groupedPartiesByTypeObject, setGroupedPartiesByTypeObject] = useState(
    null
  );
  // const [
  //   applicationPapersAllocatedPartiesList,
  //   setApplicationPapersAllocatedPartiesList,
  // ] = useState([]);

  const changeObject = (data) => {
    if (!paperObject && !data.parties) {
      data.parties = [];
    }
    if (!paperObject && !data.proofs) {
      data.proofs = [];
    }
    if (!paperObject && !data.reliefs) {
      data.reliefs = [];
    }
    updatePaperObject({
      ...paperObject,
      ...data,
    });
  };

  const onClickAllocatePartiesHandler = async () => {
    const app = await getApplicationById(
      notebook.application.fake_id,
      caseId,
      dispatch
    );

    const applicationPapersAllocatedPartiesList = addPartyTypeField(
      paperObject.parties ?? []
    );

    console.log(999);
    console.log(notebook.application);
    console.log(caseObject);
    console.log(paperObject);
    console.log(setPaperObject);
    console.log(changeObject);
    console.log(applicationPapersAllocatedPartiesList);
    console.log({ caseId: caseId, applicationId: applicationId });

    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      rightBarWidth: "18%",
      editableObject: {
        ...paperObject,
      },
      content: (
        <AllocateParties
          applicationObject={app}
          caseObject={caseObject}
          applicationPaperObject={paperObject}
          setApplicationPaperObject={updatePaperObject}
          returnData={changeObject}
          savedPartiesList={applicationPapersAllocatedPartiesList}
          routeParams={{ caseId: caseId, applicationId: applicationId }}
        />
      ),
      swapContent: {
        content: <EditObjectPanel paperObject={paperObject} {...props} />,
      },
      title: "Parties",
    });
  };

  const applicationPapersAllocatedPartiesList = addPartyTypeField(
    paperObject.parties ?? []
  );
  const allocatePartySettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Allocate Party",
        callback: () => onClickAllocatePartiesHandler(),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => onClickAllocatePartiesHandler(),
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const uniquePartyTypesList = Object.keys(groupedPartiesByTypeObject);
          const filteredListOfPartiesByPartyType = applicationPapersAllocatedPartiesList.filter(
            (x) => x.type !== uniquePartyTypesList[index]
          );
          const submitObj = {
            parties: [...filteredListOfPartiesByPartyType],
          };
          changeObject(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      ...groupedPartiesByTypeObject,
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      setGroupedPartiesByTypeObject: (data) =>
        setGroupedPartiesByTypeObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element) => (
              <AssociationTableListElement key={element.id}>
                <Bold14Font textColor={props.theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    style={{ padding: "revert" }}
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const type = listCopy[0].type;
                      const groupedPartiesByTypeObjectCopy = {
                        ...groupedPartiesByTypeObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedPartiesByTypeObjectCopy,
                          [type]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = { parties: plainArray };
                        changeObject(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={props.theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            // return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const [allocatePartySettings, setAllocatePartySettings] = useState(
    allocatePartySettingsDef
  );

  useEffect(() => {
    console.log("update");
    console.log(paperObject);
    const applicationPapersAllocatedPartiesList = addPartyTypeField(
      paperObject.parties ?? []
    );
    const grouped = groupPartiesByType(paperObject.parties ?? []);
    console.log(groupPartiesByType(paperObject.parties ?? []));
    console.log(groupedPartiesByTypeObject);
    setAllocatePartySettings({
      actions: [
        // if addButton needed, it  must always be first in array!
        {
          type: "addButton",
          buttonText: "Allocate Party",
          callback: () => onClickAllocatePartiesHandler(),
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => onClickAllocatePartiesHandler(),
        },
        {
          type: "delete",
          clickHandler: async (index) => {
            const uniquePartyTypesList = Object.keys(grouped);
            const filteredListOfPartiesByPartyType = applicationPapersAllocatedPartiesList.filter(
              (x) => x.type !== uniquePartyTypesList[index]
            );
            const submitObj = {
              parties: [...filteredListOfPartiesByPartyType],
            };
            changeObject(submitObj);
          },
        },
      ],
      // Objects to render in rows
      objects: {
        // Object that contain proofs arrays grouped by relief key
        ...grouped,
      },
      // "setState" functions that changes objects (order as in objects field!)
      setObjectsFunctions: {
        // function that update object in view
        setGroupedPartiesByTypeObject: (data) =>
          setGroupedPartiesByTypeObject(data),
      },
      // fields to show in the rows
      fields: [
        {
          rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
          rowElements: (array, setArray, indexOfRow) => {
            if (array.length) {
              return array.map((element) => (
                <AssociationTableListElement key={element.id}>
                  <Bold14Font textColor={props.theme.colors.blue} as="div">
                    {element.name}
                  </Bold14Font>
                  <div>
                    <ButtonNew
                      tertiary
                      style={{ padding: "revert" }}
                      clickHandler={async () => {
                        const listCopy = [...array];
                        const type = listCopy[0].type;
                        const groupedPartiesByTypeObjectCopy = {
                          ...grouped,
                        };
                        const findElementIndex = listCopy.findIndex(
                          (x) => x.id === element.id
                        );
                        if (findElementIndex !== -1) {
                          listCopy.splice(findElementIndex, 1);
                          // setArray(listCopy);
                          // here we NOT set array, but set new object
                          const newObject = {
                            ...groupedPartiesByTypeObjectCopy,
                            [type]: listCopy,
                          };
                          setArray(newObject);
                          // make plain array with ID to send to api
                          const plainArray = [];
                          Object.keys(newObject).forEach((item) => {
                            newObject[item].forEach((el) => {
                              plainArray.push(el);
                            });
                          });
                          const submitObj = { parties: plainArray };
                          changeObject(submitObj);
                        }
                      }}
                    >
                      {<CloseIcon stroke={props.theme.colors.blue} />}
                    </ButtonNew>
                  </div>
                </AssociationTableListElement>
              ));
            } else {
              // return <Medium14Font>N/A</Medium14Font>;
            }
          },
        },
      ],
    });
  }, [caseObject, paperObject, groupedPartiesByTypeObject]);

  //
  //
  // PARTY END //
  //
  //

  //
  //
  // PROOF START //
  //
  //

  const onClickAssociateReliefAndProofsHandler = async () => {
    const app = await getApplicationById(
      notebook.application.fake_id,
      caseId,
      dispatch
    );

    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateProofs
          applicationObject={app}
          applicationPaperObject={paperObject}
          setApplicationPaperObject={updatePaperObject}
          savedReliefs={paperObject.reliefs ?? []}
          savedProofs={paperObject.proofs ?? []}
          returnObject={true}
          routeParams={{ caseId: caseId, applicationId: applicationId }}
        />
      ),
      editableObject: {
        ...paperObject,
      },
      swapContent: {
        content: <EditObjectPanel paperObject={paperObject} {...props} />,
      },
      title: "Reliefs and Proofs",
    });
  };

  const allocateProofsSettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Reliefs and Proofs",
        callback: () => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          onClickAssociateReliefAndProofsHandler();
        },
      },
      {
        type: "delete",
        clickHandler: async (index) => {
          const submitObj = {
            reliefs: [],
            proofs: [],
          };
          changeObject(submitObj);
        },
      },
    ],
    // Objects to render in rows
    objects: {
      // Object that contain proofs arrays grouped by relief key
      "Reliefs and Proofs":
        paperObject && paperObject.reliefs && paperObject.proofs
          ? [...paperObject.reliefs, ...paperObject.proofs]
          : [],
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      // function that update object in view
      // setPaperObject: (data) =>
      //   setPaperObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element, index) => (
              <AssociationTableListElement key={index}>
                <Bold14Font textColor={props.theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    style={{ padding: "revert" }}
                    clickHandler={async () => {
                      const listCopy = [...array];
                      const role = "Reliefs and Proofs";
                      const groupedReliefObjectCopy = {
                        "Reliefs and Proofs": [
                          ...paperObject.reliefs,
                          ...paperObject.proofs,
                        ],
                      };
                      const findElementIndex = index;
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...groupedReliefObjectCopy,
                          [role]: listCopy,
                        };
                        // setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });
                        const submitObj = {
                          reliefs: plainArray.filter(
                            (relief) => "pivot" in relief
                          ),
                          proofs: plainArray.filter(
                            (proof) => !("pivot" in proof)
                          ),
                        };
                        changeObject(submitObj);
                      }
                    }}
                  >
                    {<CloseIcon stroke={props.theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            // return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const [allocateProofsSettings, setAllocateProofsSettings] = useState(
    allocateProofsSettingsDef
  );

  useEffect(() => {
    setAllocateProofsSettings({
      actions: [
        // if addButton needed, it  must always be first in array!
        {
          type: "addButton",
          buttonText: "Associate Reliefs and Proofs",
          callback: () => {
            onClickAssociateReliefAndProofsHandler();
          },
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => {
            onClickAssociateReliefAndProofsHandler();
          },
        },
        {
          type: "delete",
          clickHandler: async (index) => {
            const submitObj = {
              reliefs: [],
              proofs: [],
            };
            changeObject(submitObj);
          },
        },
      ],
      // Objects to render in rows
      objects: {
        // Object that contain proofs arrays grouped by relief key
        "Reliefs and Proofs":
          paperObject && paperObject.reliefs && paperObject.proofs
            ? [...paperObject.reliefs, ...paperObject.proofs]
            : [],
      },
      // "setState" functions that changes objects (order as in objects field!)
      setObjectsFunctions: {
        // function that update object in view
        // setPaperObject: (data) =>
        //   setPaperObject(data),
      },
      // fields to show in the rows
      fields: [
        {
          rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
          rowElements: (array, setArray, indexOfRow) => {
            if (array.length) {
              return array.map((element, index) => (
                <AssociationTableListElement key={index}>
                  <Bold14Font textColor={props.theme.colors.blue} as="div">
                    {element.name}
                  </Bold14Font>
                  <div>
                    <ButtonNew
                      tertiary
                      style={{ padding: "revert" }}
                      clickHandler={async () => {
                        const listCopy = [...array];
                        const role = "Reliefs and Proofs";
                        const groupedReliefObjectCopy = {
                          "Reliefs and Proofs": [
                            ...paperObject.reliefs,
                            ...paperObject.proofs,
                          ],
                        };
                        const findElementIndex = index;
                        if (findElementIndex !== -1) {
                          listCopy.splice(findElementIndex, 1);
                          // setArray(listCopy);
                          // here we NOT set array, but set new object
                          const newObject = {
                            ...groupedReliefObjectCopy,
                            [role]: listCopy,
                          };
                          // setArray(newObject);
                          // make plain array with ID to send to api
                          const plainArray = [];
                          Object.keys(newObject).forEach((item) => {
                            newObject[item].forEach((el) => {
                              plainArray.push(el);
                            });
                          });
                          const submitObj = {
                            reliefs: plainArray.filter(
                              (relief) => "pivot" in relief
                            ),
                            proofs: plainArray.filter(
                              (proof) => !("pivot" in proof)
                            ),
                          };
                          changeObject(submitObj);
                        }
                      }}
                    >
                      {<CloseIcon stroke={props.theme.colors.blue} />}
                    </ButtonNew>
                  </div>
                </AssociationTableListElement>
              ));
            } else {
              // return <Medium14Font>N/A</Medium14Font>;
            }
          },
        },
      ],
    });
  }, [caseObject, paperObject]);

  //
  //
  // PROOF END //
  //
  //

  if (
    dataLoading ||
    !caseObject ||
    !paperObject ||
    Object.keys(paperObject).length === 0
  ) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={paperObject.label}
              onChangeHandler={(e) => {
                e.persist();
                setPaperObject((obj) => ({
                  ...obj,
                  label: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Doc type</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={typeOptions}
                  value={
                    paperObject.doc_type
                      ? {
                          label: paperObject.doc_type,
                          value: paperObject.doc_type,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setPaperObject((obj) => ({
                      ...obj,
                      doc_type: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Probative status</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={statusOptions}
                  value={
                    paperObject.probative_status
                      ? {
                          label: paperObject.probative_status,
                          value: paperObject.probative_status,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setPaperObject((obj) => ({
                      ...obj,
                      probative_status: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <CustomReactDatepicker
              // selected={date}
              // onChange={(date) => onChangeDateHandler(date)}

              selected={paperObject.date ? parseISO(paperObject.date) : ""}
              onChange={(date) => {
                setPaperObject((paper) => ({
                  ...paper,
                  date:
                    moment(date).format("YYYY-MM-DD") === "Invalid date"
                      ? null
                      : moment(date).format("YYYY-MM-DD"),
                }));
              }}
              dateFormat={"dd-MM-yyyy"}
              dateFormatCalendar={"dd-MM-yyyy"}
              label="Issue date"
              name="date"
              placeholderText="Select Date"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <UploadSingleFileInput
              label="Upload File"
              file={file}
              setFile={(file) => {
                setFile(file);
                setPaperObject((obj) => ({
                  ...obj,
                  file_new: file,
                }));
              }}
              validTypes={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "application/pdf",
              ]}
              maxFileSize={maxFileSize}
            />
            {(file ? file.name === paperObject.file : paperObject.file_id) && (
              <div className="container-fluid" style={{ marginTop: 12 }}>
                <a
                  href={`/app/cases/${caseId}/documents/${paperObject.file_id}`}
                  target="_blank"
                >
                  <ButtonNew
                    clickHandler={() => {}}
                    primary
                    style={{ width: "100%" }}
                  >
                    View attachment
                  </ButtonNew>
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <div className={"col-12"}>
            <AssociationTable
              settings={allocatePartySettings}
              label="Party"
              compact
            />
          </div>
        </div>

        <div className="form-group row">
          <div className={"col-12"}>
            <AssociationTable
              settings={allocateProofsSettings}
              label="Flag Relevance"
              compact
            />
          </div>
        </div>

        {/*
         */}
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult();
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

const MultiSelect = ({
  onChange,
  placeholder = "Placeholder",
  label = "Label",
  options = [
    { label: "label1", value: "value1" },
    { label: "label2", value: "value2" },
    { label: "label3", value: "value3" },
  ],
  type = "objective",
  value,
  labelInValue = true,
}) => {
  return (
    <div className="form-group row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            labelInValue={labelInValue}
            showSearch
            filterOption={(input, option) => {
              return ("" + option.label)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            value={value}
            // onSelect={(value) => {
            // }}
            tagRender={(props) => {
              return (
                <span
                  style={{ marginRight: 5, marginBottom: 2, marginTop: 2 }}
                  className={`tag tag-${type}`}
                >
                  {props.label}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditPaper);
