import React from "react";
import AssociationTableListElementStyled from "./styled/AssociationTableListElementStyled";

const AssociationTableListElement = ({ children, ...props }) => {
  return (
    <AssociationTableListElementStyled {...props}>
      {children}
    </AssociationTableListElementStyled>
  );
};

export { AssociationTableListElement };
