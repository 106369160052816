import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { CASES } from "../../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {
  createNewApplication,
  getApplicationsList,
  getCaseById,
} from "../../../Auth/auth";
import PageConfiguration from "../../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold18Font,
  Bold30Font,
  Medium30Font,
} from "../../../../components/FontsNewComponent/Fonts";
import TrialHubSearchSortBar from "../../../../components/baseAppComponents/TrialHubSearchSortBar";
import plusIcon from "../../../../assets/img3/svg/button-plus-icon.svg";
import ApplicationCard from "../../../../components/baseAppComponents/CaseCard/ApplicationCard";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import moment from "moment";
import NoRecords from "../../../../components/NoRecords";
import PageTitle from "../../../../components/PageTitle";

const sortAlphabetical = (a, b, order = "ASC") => {
  let item_a = Object.assign({}, order === "DESC" ? b : a);
  let item_b = Object.assign({}, order === "DESC" ? a : b);
  item_a.label =
    typeof item_a.label === "string"
      ? item_a.label.toLowerCase()
      : item_a.label.toString();
  item_b.label =
    typeof item_b.label === "string"
      ? item_b.label.toLowerCase()
      : item_b.label.toString();

  return item_b.label && item_b.label
    ? item_a.label.localeCompare(item_b.label, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    : item_a.title.localeCompare(item_b.title, undefined, {
        numeric: true,
        sensitivity: "base",
      });
};

const sortByDateTime = (item_a, item_b, order = "ASC") => {
  return order === "ASC"
    ? moment(item_b.updated_at).diff(moment(item_a.updated_at))
    : moment(item_a.updated_at).diff(moment(item_b.updated_at));
};

const sortOptions = [
  {
    value: 1,
    label: "Last Updated",
  },
  {
    value: 2,
    label: "Oldest",
  },
  {
    value: 3,
    label: "Label -  a-Z",
  },
  {
    value: 4,
    label: "Label -  Z-a",
  },
];

const ApplicationsCardView = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [caseObject, setCaseObject] = useState({});
  const [applicationsList, setApplicationsList] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("");
  const [selectedSortFunction, setSelectedSortFunction] = useState(1);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const history = useHistory();
  const dispatch = useDispatch();
  // Case ID
  const { id } = useParams();
  const { url } = useRouteMatch();

  // Get data from API
  const getDataFromAPI = async () => {
    const caseResp = await getCaseById(id, dispatch);
    if (is_admin && !caseResp) {
      history.push("/admin/all-cases");
    }
    const applicationsResp = await getApplicationsList(id, dispatch);
    if (caseResp && applicationsResp) {
      setCaseObject(caseResp);
      setApplicationsList(applicationsResp);
      setIsLoading(false);
    }
  };

  // Set data to state after component mount
  useEffect(() => {
    getDataFromAPI();
  }, []);

  // SearchSortBar methods

  const onSearchBarChangeHandler = (event) => {
    setSearchBarValue(event.target.value);
  };

  const clearSearchBarHandler = () => {
    setSearchBarValue("");
  };

  const onFilterDatesAlphabetChangeHandler = (value) => {
    setSelectedSortFunction(value.value);
  };

  const setSortFunction = (a, b) => {
    switch (selectedSortFunction) {
      // from newest updates
      case 1:
        return sortByDateTime(a, b);
      // from oldest updates
      case 2:
        return sortByDateTime(a, b, "DESC");
      //alphabetically a-Z
      case 3:
        return sortAlphabetical(a, b);
      // alphabetically from Z to A
      case 4:
        return sortAlphabetical(a, b, "DESC");
      default:
        break;
    }
  };

  const onClickAddApplicationHandler = async () => {
    history.push(`${url}/create`);
  };

  const onClickApplicationCardHandler = (applicationId) => {
    history.push(`${url}/${applicationId}`);
  };

  const searchField = (fieldValue) => {
    if (typeof fieldValue === "string") {
      return (
        fieldValue.toLowerCase().indexOf(searchBarValue.toLowerCase()) !== -1
      );
    } else if (typeof fieldValue === "object") {
      return (
        fieldValue.filter((string) => {
          if (string && string.name !== null) {
            return (
              string.name
                .toLowerCase()
                .indexOf(searchBarValue.toLowerCase()) !== -1
            );
          }
          return false;
        }).length > 0
      );
    }
  };

  const filterApplications = (value) => {
    let check = true;

    if (searchBarValue && check) {
      // search in evidence label and objectives
      if (value.label) {
        check =
          (value.label && searchField(value.label)) ||
          (value.objectives &&
            value.objectives.length &&
            searchField(value.objectives));
        // search in authorities title
      } else if (value.title) {
        check = value.title && searchField(value.title);
      }
    }

    return check;
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <PulseLoader color={theme.colors.blue} size={30} />
      </div>
    );
  }

  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/applications-hub`
        : `/admin/all-cases/${id}/applications-hub`,
      title: "Applications Hub",
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <PageTitle pageLabel="Applications Hub" rowClassName="row mb-3" />
      <div className="row">
        <div className="col">
          <TrialHubSearchSortBar
            searchBarValue={searchBarValue}
            onSearchBarChangeHandler={onSearchBarChangeHandler}
            clearSearchBarHandler={clearSearchBarHandler}
            searchBarInputName="search"
            searchBarPlaceholder="Search"
            sortOptions={sortOptions}
            onFilterDatesAlphabetChangeHandler={
              onFilterDatesAlphabetChangeHandler
            }
            selectedSortOption={selectedSortFunction - 1}
            buttons={
              <ButtonNew
                loading={isSubmitting}
                disabled={isSubmitting}
                clickHandler={onClickAddApplicationHandler}
                primary
              >
                <div className="d-flex align-items-center">
                  <img src={plusIcon} alt="" className="img-fluid mr-2" />
                  <Bold14Font style={{ color: "inherit" }}>
                    Add Application
                  </Bold14Font>
                </div>
              </ButtonNew>
            }
          />
        </div>
      </div>
      <div className="row">
        {applicationsList.length ? (
          applicationsList
            .sort(setSortFunction)
            .filter(filterApplications)
            .map((applicationObject) => (
              <div
                key={applicationObject.id}
                className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3"
              >
                <ApplicationCard
                  applicationObject={applicationObject}
                  onClick={() =>
                    onClickApplicationCardHandler(applicationObject.id)
                  }
                />
              </div>
            ))
        ) : (
          <div className="col">
            <NoRecords>
              <Bold18Font textColor={"#0f122f"}>
                Your Applications will appear here
              </Bold18Font>
            </NoRecords>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withTheme(ApplicationsCardView);
