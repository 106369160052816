import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";

import FreeLicensePlanCardStyled from "./styled/FreeLicensePlanCardStyled";

import basicPlanIcon from "../../assets/img3/svg/plan-starter-icon.svg";
import mediumPlanIcon from "../../assets/img3/svg/plan-medium-icon.svg";
import proPlanIcon from "../../assets/img3/svg/plan-pro-icon.svg";
import planCheckedIcon from "../../assets/img/svg/checked.svg";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
  Medium18Font,
} from "../FontsNewComponent/Fonts";

// TODO: Do not delete this todo! If names of plans change in backend - rename it here
const BASIC = "Basic";
const MEDIUM = "Standard";
const PRO = "Pro";

const FreeLicensePlanCard = ({
  subscriptionPlanObject,
  checked,
  setChecked,
  withPriceLayout,
  noPointer,
  theme,
  ...props
}) => {
  const [icon, setIcon] = useState(null);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (checked.price_id === subscriptionPlanObject.price_id) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [checked]);

  useEffect(() => {
    switch (subscriptionPlanObject.name) {
      case BASIC:
        setIcon(basicPlanIcon);
        break;
      case MEDIUM:
        setIcon(mediumPlanIcon);
        break;
      case PRO:
        setIcon(proPlanIcon);
        break;
      default:
        return undefined;
    }
  }, [subscriptionPlanObject]);

  const onCardClickHandler = () => {
    setChecked(subscriptionPlanObject);
    if (check) {
      setCheck(false);
      setChecked({});
    }
  };

  if (withPriceLayout) {
    return (
      <FreeLicensePlanCardStyled
        onClick={onCardClickHandler}
        checked={check}
        withPriceLayout={withPriceLayout}
        noPointer={noPointer}
        {...props}
      >
        {check ? (
          <img
            src={planCheckedIcon}
            width={24}
            style={{ position: "absolute", top: 0, right: 0 }}
            height={24}
            alt=""
          />
        ) : null}
        <div className="row w-100 flex-wrap" style={{ marginBottom: "10px" }}>
          <div className="col-4">
            <img src={icon} alt="" />
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col">
                <Medium18Font>{subscriptionPlanObject.name}</Medium18Font>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {"€"}
                <Bold14Font>{subscriptionPlanObject.price}</Bold14Font>
                <Medium14Font>
                  {" / "}
                  {subscriptionPlanObject.type === "monthly"
                    ? "1 month plan"
                    : "1 year plan"}
                </Medium14Font>
              </div>
            </div>
          </div>
        </div>
      </FreeLicensePlanCardStyled>
    );
  }

  return (
    <FreeLicensePlanCardStyled
      onClick={onCardClickHandler}
      checked={check}
      noPointer={noPointer}
      // TODO: Does I need {...props} here?
    >
      {check ? (
        <img
          src={planCheckedIcon}
          width={24}
          style={{ position: "absolute", top: 0, right: 0 }}
          height={24}
          alt=""
        />
      ) : null}
      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col d-flex justify-content-center">
          <img src={icon} alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <Bold18Font>{subscriptionPlanObject.name}</Bold18Font>
        </div>
      </div>
    </FreeLicensePlanCardStyled>
  );
};

FreeLicensePlanCard.propTypes = {
  subscriptionPlanObject: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  withPriceLayout: PropTypes.bool,
  noPointer: PropTypes.bool,
};

FreeLicensePlanCard.defaultProps = {
  withPriceLayout: false,
  noPointer: false,
};

export default withTheme(FreeLicensePlanCard);
