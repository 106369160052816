import React, {forwardRef, Fragment} from "react";
import PropTypes from "prop-types";

import InputStyled from "./styled/InputStyled";

import { Bold14Font } from "../FontsNewComponent/Fonts";

// TODO: Re-implement all props with ...props pattern
const InputNew = ({
  type,
  name,
  label,
  placeholder,
  customStyle,
  onChangeHandler,
  value,
  withoutContent,
  containerStyle,
  forwardRef,
  ...props
}) => {
  const input = (
    <div className="row">
      <div className="col">
        <InputStyled
          type={type}
          name={name}
          ref={forwardRef}
          placeholder={placeholder}
          as={customStyle}
          onChange={onChangeHandler}
          value={value}
          autoComplete="off"
          {...props}
        />
      </div>
    </div>
  );

  if (label) {
    return (
      <label className="w-100">
        {!withoutContent ? (
          <div className="container-fluid" style={containerStyle}>
            {label ? (
              <div className="row mb-2">
                <div className="col">
                  <Bold14Font>{label}</Bold14Font>
                </div>
              </div>
            ) : null}
            {input}
          </div>
        ) : (
          <Fragment>
            {label ? (
              <div className="row mb-2">
                <div className="col">
                  <Bold14Font>{label}</Bold14Font>
                </div>
              </div>
            ) : null}
            {input}
          </Fragment>
        )}
      </label>
    );
  }

  return !withoutContent ? (
    <div className="container-fluid">{input}</div>
  ) : (
    input
  );
};

InputNew.propTypes = {
  type: PropTypes.oneOf(["text", "password", "textarea", "email", "number"]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};
InputNew.defaultProps = {
  type: "text",
};

export default InputNew;
