import React from "react";
import Item from "./Item";

import useWindowSize from "@/hooks/useWindowSize";

const GridView = ({ notebooks, editNotebook, deleteNotebook }) => {
  const { width } = useWindowSize();

  return (
    <div className="row pb-5 pt-3">
      {notebooks.map((notebook) => (
        <div
          // key={value.id}
          className={
            width < 1600 ? "col-12 col-md-6 col-lg-4 mb-3" : "col-xl-3 mb-3"
          }
        >
          <Item
            key={notebook.id}
            notebook={notebook}
            editNotebook={editNotebook}
            deleteNotebook={deleteNotebook}
          />
        </div>
      ))}
    </div>
  );
};

export default GridView;
