import React, { Fragment, useEffect, useRef, useState } from "react";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import {
  createNewSubEvidence,
  deleteDocument,
  deleteSubEvidenceApi,
  getCaseById,
  getDocument,
  saveDocumentMarkups,
} from "../../Auth/auth";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
  Medium18Font,
} from "../../../components/FontsNewComponent/Fonts";
import styled from "styled-components/macro";
import {
  DATA_ADD_TO_STORE,
  EXTRACT_DATA_INITIAL,
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  MODAL_DELETE_KEY_SECTION,
  MODAL_DOC_TYPE,
  SAVE_MODAL_DATA,
  SET_EDITABLE_OBJECT,
  SHOW_MODAL,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import {
  APPLICATION_DOCUMENTS,
  AUTHORITIES_DOCUMENTS,
  CASE_DOCUMENTS,
  EVIDENCE_DOCUMENTS,
} from "./Documents";
import moment from "moment";
import rem from "../../../utils/rem";
import WebViewer from "@pdftron/webviewer";
import EvidenceDocProperties from "../../../components/forms/DocumentForms/EvidenceDocProperties";
import AuthorityDocProperties from "../../../components/forms/DocumentForms/AuthorityDocProperties";
import SubEvidence from "../../../components/forms/SubEvidence/SubEvidence";
import PapersDocProperties from "../../../components/forms/DocumentForms/PapersDocProperties";
import SubEvidenceDocProperties from "../../../components/forms/DocumentForms/SubEvidenceDocProperties";
import { setTagField } from "../../../utils/function";
import PopupStyled from "../../../components/PopupStyled/PopupStyled";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import DeleteIcon from "../../../assets/img/svg/deleteIcon.svg";
import { handleErrorMessage } from "../../../components/Modal/modalErrorHandler";
import Select from "../../../components/Select";
import ExtractForm from "../../../components/forms/DocumentForms/ExtractForm";
import SeparatorStyled from "../../../components/forms/DocumentForms/styled/SeparatorStyled";
import CaseDocProperties from "../../../components/forms/DocumentForms/CaseDocProperties";
import { TagElementBlue } from "../../../components/TagElementStyled/TagElementStyled";

const docType = (documentType) => {
  switch (documentType) {
    case EVIDENCE_DOCUMENTS:
      return "Ev Type";

    case AUTHORITIES_DOCUMENTS:
      return "Authority";

    case CASE_DOCUMENTS:
      return "Case";

    case APPLICATION_DOCUMENTS:
      return "Application";
    default:
      return "";
  }
};

const base64ToBlob = (base64) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: "application/pdf" });
};
const Separator = styled.div`
  border-top: 2px solid #dcdce6;
  margin-top: 30px;
  margin-bottom: 30px;
`;
const WebviewerDiv = styled.div`
  height: 100vh;
  border: ${({ isLoading }) => (!isLoading ? "1px solid #dcdce6" : "none")};
`;
const Priority = styled.div`
  width: 24px;
  height: 24px;
  padding: 1px;
  border-radius: 12px;
  background-color: #374ac2;
  text-align: center;
`;
const TypeStyled = styled.div`
  margin-right: 9px;
  border-radius: 4px;
  margin-left: 4px;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.purpleBorder};
  background-color: ${({ theme }) => theme.colors.purpleFill};
  padding: 3px 6px;
  font-size: ${rem(`14px`)};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const EvidenceDocumentData = ({ document, caseObject }) => {
  let date = document.date ?? document.classes ? document.classes.date : null;
  if (date) {
    date = moment(date).format("DD-MM-yyyy");
  }
  const tags = setTagField(caseObject, [document], true)[0].tags;
  const NotAvailable = <Medium14Font>N/A</Medium14Font>;
  return (
    <Fragment>
      <div className={"row mt-3"}>
        <div
          className={
            document.document_type === EVIDENCE_DOCUMENTS ? "col-6" : "col-12"
          }
        >
          <div className="row">
            <div
              className={
                document.document_type === EVIDENCE_DOCUMENTS
                  ? "col-4"
                  : "col-2"
              }
            >
              <Medium14Font textColor={theme.colors.darkOpacity}>
                {document.document_type === APPLICATION_DOCUMENTS ||
                (document.document_type === AUTHORITIES_DOCUMENTS &&
                  document.application_object)
                  ? "Reliefs and Proofs"
                  : "Relevance"}
                :
              </Medium14Font>
            </div>
            <div
              className={`${
                document.document_type === EVIDENCE_DOCUMENTS
                  ? "col-8"
                  : "col-10"
              } d-inline-flex flex-wrap`}
            >
              {document.document_type === APPLICATION_DOCUMENTS ||
              (document.document_type === AUTHORITIES_DOCUMENTS &&
                document.application_object) ? (
                (document.reliefs && document.reliefs.length) ||
                (document.proofs && document.proofs.length) ? (
                  <ul className="d-flex flex-wrap">
                    {document.reliefs
                      ? document.reliefs.map((relief) => (
                          <TagElementBlue key={relief.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {relief.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))
                      : null}
                    {document.proofs
                      ? document.proofs.map((proof) => (
                          <TagElementBlue key={proof.id} as="li">
                            <Bold14Font style={{ color: "inherit" }}>
                              {proof.name}
                            </Bold14Font>
                          </TagElementBlue>
                        ))
                      : null}
                  </ul>
                ) : (
                  NotAvailable
                )
              ) : tags.length ? (
                tags.map((f, idx) => {
                  return <Fragment key={idx}>{f.element}</Fragment>;
                })
              ) : (
                NotAvailable
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div
              className={
                document.document_type === EVIDENCE_DOCUMENTS
                  ? "col-4"
                  : "col-2"
              }
            >
              <Medium14Font textColor={theme.colors.darkOpacity}>
                Doc type ({docType(document.document_type)})
              </Medium14Font>
            </div>
            <div
              className={
                document.document_type === EVIDENCE_DOCUMENTS
                  ? "col-8"
                  : "col-10"
              }
            >
              {document.classes && document.classes.type ? (
                <TypeStyled>{document.classes.type}</TypeStyled>
              ) : (
                NotAvailable
              )}
            </div>
          </div>
        </div>
        {document.document_type === EVIDENCE_DOCUMENTS ? (
          <div className="col-6">
            <div className="row">
              <div className="align-items-baseline col-12 d-flex justify-content-end text-right">
                <Medium14Font
                  className="mr-1"
                  textColor={theme.colors.darkOpacity}
                >
                  Priority:
                </Medium14Font>{" "}
                {document.classes && document.classes.priority ? (
                  <Priority>
                    <Medium14Font
                      style={{ position: "relative", bottom: "2px" }}
                      textColor={"#ffffff"}
                    >
                      {document.classes.priority}
                    </Medium14Font>
                  </Priority>
                ) : (
                  NotAvailable
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 text-right">
                <Medium14Font
                  className="mr-1"
                  textColor={theme.colors.darkOpacity}
                >
                  Date:
                </Medium14Font>
                <Bold14Font>{date ?? NotAvailable}</Bold14Font>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};
const CaseDocumentData = ({ document, caseObject }) => {
  let date = document.date ?? null;
  if (date) {
    date = moment(date).format("DD-MM-yyyy");
  }
  const NotAvailable = <Medium14Font>N/A</Medium14Font>;
  return (
    <Fragment>
      <div className={"row mt-3"}>
        <div className="col-6">
          <div className="row">
            <div className="col-4">
              <Medium14Font textColor={theme.colors.darkOpacity}>
                Date
              </Medium14Font>
            </div>
            <div className="col-8 d-inline-flex flex-wrap">
              {date ? <Bold14Font>{date}</Bold14Font> : NotAvailable}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-4">
              <Medium14Font textColor={theme.colors.darkOpacity}>
                File
              </Medium14Font>
            </div>
            <div className="col-8">
              <Bold14Font>{document.file}</Bold14Font>
            </div>
          </div>
        </div>
        {document.document_type === EVIDENCE_DOCUMENTS ? (
          <div className="col-6">
            <div className="row">
              <div className="align-items-baseline col-12 d-flex justify-content-end text-right">
                <Medium14Font
                  className="mr-1"
                  textColor={theme.colors.darkOpacity}
                >
                  Priority:
                </Medium14Font>{" "}
                {document.classes && document.classes.priority ? (
                  <Priority>
                    <Medium14Font
                      style={{ position: "relative", bottom: "2px" }}
                      textColor={"#ffffff"}
                    >
                      {document.classes.priority}
                    </Medium14Font>
                  </Priority>
                ) : (
                  NotAvailable
                )}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 text-right">
                <Medium14Font
                  className="mr-1"
                  textColor={theme.colors.darkOpacity}
                >
                  Date:
                </Medium14Font>
                <Bold14Font>{date ?? NotAvailable}</Bold14Font>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};
const ViewDocument = () => {
  const location = useLocation();
  const [document, setDocument] = useState({});
  const [blob, setBlob] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [onDelete, setOnDelete] = useState(false);
  const [instance, setInstance] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupLeft, setPopupLeft] = useState(0);
  const [popupTop, setPopupTop] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [popupType, setPopupType] = useState("create");
  const [v_, setValue] = useState({});
  const [readOnly, setReadOnly] = useState(
    !location.state || !location.state.subevidence
  );
  const caseObject = useSelector((state) =>
    state.data.store ? state.data.store.case : {}
  );
  const [annotManager_, setAnnotManager] = useState(false);
  const { id, documentId } = useParams();
  const rightSideBar = useSelector(
    (state) => state.rightSideBar.isSideBarPulled
  );
  const editObject = useSelector((state) => state.rightSideBar.editableObject);
  const user_name = useSelector((state) => state.auth.user.name);
  const history = useHistory();
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  const viewer = useRef(null);
  useEffect(() => {
    if (annotManager_) {
      // if (!readOnly) {
      annotManager_.setReadOnly(readOnly);
      // }
      if (readOnly) {
        handleAnnotations();
      } else {
        instance.setToolbarGroup("toolbarGroup-Annotate");
      }
      if (readOnly) {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      } else {
        if (document && document.document_type === EVIDENCE_DOCUMENTS) {
          displayForm(document);
        }
      }
    }
  }, [readOnly]);

  const handleAnnotations = async (manager) => {
    if (!isLoading) {
      setIsLoading(true);
      let annotations;
      if (manager) {
        annotations = await manager.exportAnnotations();
      } else if (annotManager_) {
        annotations = await annotManager_.exportAnnotations();
      }
      if (annotations) {
        await saveDocumentMarkups(documentId, id, dispatch, {
          markups: annotations,
        });
      }
      setIsLoading(false);
    }
  };
  const updateRSB = (resp) => {
    setDocument(resp);
    displayForm(resp);
  };
  const displayForm = (doc, case_) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: doc,
      afterCloseAction: () => {
        handleAnnotations();
      },
      content: (
        <SubEvidence
          sub_evidence={doc.sub_evidence}
          caseObject={case_ ?? caseObject}
          callback={updateRSB}
          annotManager={annotManager_}
          caseId={id}
          documentId={documentId}
        />
      ),
      title: "Key Sections",
    });
  };

  useEffect(() => {
    window.doc_object = document;
    if (window.doc_object.sub_evidence) {
      window.doc_object.sub_evidence.forEach((v) => {
        v.case = caseObject;
      });
    }
  }, [documentId, document]);
  const addSubEvidence = async (annotation, manager, case_) => {
    await handleAnnotations(manager);
    let resp = await createNewSubEvidence(id, dispatch, documentId, {
      id: annotation.Id,
      page: annotation.getPageNumber(),
    });
    setDocument(resp.document);
    const updateObject = (resp_) => {
      let obj = { ...resp.sub_evidence, ...resp_ };
      dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
    };
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: resp.sub_evidence,
      afterCloseAction: () => {
        manager.deselectAnnotation(annotation);
      },
      content: (
        <SubEvidenceDocProperties
          callback={updateRSB}
          sub_evidence={resp.sub_evidence}
          caseObject={case_}
          key={resp.sub_evidence.id}
          annotManager={annotManager_}
          documentId={documentId}
          caseId={id}
        />
      ),
      swapContent: {
        content: (
          <SubEvidence
            sub_evidence={window.doc_object.sub_evidence}
            caseObject={case_}
            key={window.doc_object.id}
            callback={updateRSB}
            annotManager={annotManager_}
            caseId={id}
            documentId={documentId}
          />
        ),
        title: "Key Sections",
      },
      title: "Edit Key Section",
    });
  };
  const popupRef = useRef();
  useOnClickOutside(popupRef, () => {
    setShowPopup(false);
  });

  const deleteSubEvidence = async (annotation, manager, case_) => {
    await handleAnnotations(manager);
    let resp = await deleteSubEvidenceApi(
      id,
      dispatch,
      documentId,
      annotation.Id
    );
    setDocument(resp);
    displayForm(resp, case_);
  };
  const getMouseLocation = (e) => {
    const scrollElement = document.getElementById("DocumentViewer");
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;

    return {
      x: e.pageX + scrollLeft,
      y: e.pageY + scrollTop,
    };
  };
  useEffect(() => {
    const getData = async () => {
      setIsDocumentLoading(true);
      const caseResp = await getCaseById(id, dispatch);
      if (is_admin && !caseResp) {
        history.push("/admin/all-cases");
      }
      dispatch({
        type: DATA_ADD_TO_STORE,
        keyName: "case",
        data: caseResp,
      });
      const resp = await getDocument(id, documentId, dispatch);
      window.doc_object = resp;
      const fileNameArray = resp.file.split(".");
      const fileExtension = fileNameArray[fileNameArray.length - 1];
      let instance_;
      if (!instance) {
        instance_ = await WebViewer(
          {
            path: "/lib",
            licenseKey: process.env.MIX_PDFTRON_KEY,
            initialDoc: resp.content,
            extension: fileExtension,
            isReadOnly: true,
            annotationUser: user_name,
          },
          viewer.current
        );
      } else {
        instance_ = instance;
        instance.loadDocument(resp.content, {
          extension: fileExtension,
          isReadOnly: true,
        });
      }
      window.scrollTo(0, 0);

      const { docViewer, annotManager, iframeWindow } = instance_;

      window.documentV = docViewer;
      setAnnotManager(annotManager);
      window.annotManager = annotManager;
      window.instance_ = instance_;
      setInstance(instance_);
      docViewer.on("pageNumberUpdated", () => {
        setNumPages(window.documentV.getPageCount());
      });
      docViewer.on("documentLoaded", () => {
        setNumPages(window.documentV.getPageCount());
        const customElement = iframeWindow.document.createElement("div");
        window.document.body.onclick = function () {
          customElement.innerHTML = "";
          customElement.style.display = "none";
        };
        customElement.style.position = "absolute";
        customElement.style.left = 0;
        customElement.style.top = 0;
        customElement.style.width = "210px";
        customElement.style.height = "25px";
        customElement.style.display = "none";
        customElement.style.padding = "5px";
        customElement.className = "ContextMenuPopup";
        customElement.style.zIndex = 100000;
        customElement.id = "customPopup";
        iframeWindow.document.body.appendChild(customElement);
        iframeWindow.document.body.onmousedown = function () {
          customElement.innerHTML = "";
          customElement.style.display = "none";
        };
        // instance_.openElements(["leftPanel"]);
        if (resp && resp.markup && resp.markup.markup) {
          annotManager.importAnnotations(resp.markup.markup);
        }
        if (location.state && location.state.subevidence) {
          let ann_ = annotManager.getAnnotationById(location.state.subevidence);
          if (ann_) {
            annotManager.selectAnnotation(ann_);
            let ev = window.doc_object.sub_evidence.find(
              (v) => location.state.subevidence === v.id
            );
            if (ev.page && docViewer) {
              docViewer.setCurrentPage(ev.page);
            }
          }
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: window.doc_object.sub_evidence.find(
              (v) => location.state.subevidence === v.id
            ),
            afterCloseAction: () => {
              annotManager.deselectAnnotation(ann_);
            },
            content: (
              <SubEvidenceDocProperties
                caseObject={caseResp}
                caseId={id}
                sub_evidence={window.doc_object.sub_evidence}
                annotManager={annotManager}
                documentId={documentId}
                callback={updateRSB}
              />
            ),
            title: window.doc_object.sub_evidence.find(
              (v) => location.state.subevidence === v.id
            ).label,
            swapContent: {
              content: (
                <SubEvidence
                  sub_evidence={window.doc_object.sub_evidence}
                  caseObject={caseResp}
                  callback={updateRSB}
                  annotManager={annotManager}
                  caseId={id}
                  documentId={documentId}
                />
              ),
              title: "Key Sections",
            },
          });
        }
      });
      docViewer.on("mouseMove", (ev) => {
        updateMousePosition(ev);
      });
      docViewer.on("click", (ev) => {
        updateMousePosition(ev);
      });
      docViewer.on("mouseLeftDown", (ev) => {
        updateMousePosition(ev);
      });
      docViewer.on("mouseEnter", (ev) => {
        updateMousePosition(ev);
      });
      docViewer.on("tap", (ev) => {
        updateMousePosition(ev);
      });
      annotManager.on("annotationChanged", (annotations, action, options) => {
        if (action === "add" && !options.imported && !options.isUndoRedo) {
          if (resp && resp.document_type === EVIDENCE_DOCUMENTS) {
            annotations.forEach((v) => {
              if (v.Subject !== "Note" || !v.InReplyTo) {
                // addSubEvidence(v, annotManager, caseObject);
                // handleAnnotations(annotManager);
                const customElement = iframeWindow.document.getElementById(
                  "customPopup"
                );
                customElement.style.display = "flex";
                let k = 0;
                if (v.Subject === "Free Text") {
                  k = 110;
                }
                if (
                  iframeWindow.innerWidth >=
                  window.mousePosition.x + 210 + k
                ) {
                  customElement.style.left = window.mousePosition.x + k + "px";
                } else {
                  customElement.style.left =
                    window.mousePosition.x - 210 + "px";
                }
                if (iframeWindow.innerHeight >= window.mousePosition.y + 25) {
                  customElement.style.top = window.mousePosition.y + "px";
                } else {
                  customElement.style.top = window.mousePosition.y - 25 + "px";
                }
                customElement.innerHTML = "New Key Section?";
                const div = iframeWindow.document.createElement("div");
                div.style.display = "flex";
                customElement.appendChild(div);
                const yesButton = iframeWindow.document.createElement("button");
                yesButton.className = "Button active";
                yesButton.innerHTML =
                  '<svg id="Layer_1" style="width: 24px;" viewBox="0 0 612 792" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">\n' +
                  "\t.st0{clip-path:url(#SVGID_5_);fill:none;stroke:#41AD49;stroke-width:45;}\n" +
                  "\t.st1{fill:#41AD49;}\n" +
                  '</style><g><g><defs><rect height="512" id="SVGID_1_" width="512" x="50" y="140"></rect></defs><clipPath id="SVGID_5_"><use style="overflow:visible;" xlink:href="#SVGID_1_"></use></clipPath><path class="st0" d="M306,629.5c128.8,0,233.5-104.7,233.5-233.5S434.8,162.5,306,162.5S72.5,267.2,72.5,396    S177.2,629.5,306,629.5L306,629.5z"></path></g><polygon class="st1" points="421.4,271 241.9,450.5 174.9,383.5 122,436.4 241.9,556.2 257.3,540.8 257.4,540.8 474.3,323.9    421.4,271  "></polygon></g></svg>';
                const noButton = iframeWindow.document.createElement("button");
                noButton.className = "Button active";
                noButton.innerHTML =
                  '<svg id="Layer_1" style="width: 24px;" viewBox="0 0 612 792" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">\n' +
                  "\t.st3{clip-path:url(#SVGID_2_);fill:none;stroke:#E44061;stroke-width:45;}\n" +
                  "\t.st4{fill:#E44061;}\n" +
                  '</style><g><g><defs><rect height="512" id="SVGID_1_" width="512" x="50" y="140"/></defs><clipPath id="SVGID_2_"><use style="overflow:visible;" xlink:href="#SVGID_1_"/></clipPath><path class="st3" d="M306,629.5c128.8,0,233.5-104.7,233.5-233.5S434.8,162.5,306,162.5S72.5,267.2,72.5,396    S177.2,629.5,306,629.5L306,629.5z"/></g><polygon class="st4" points="348.7,396 448,296.7 405.3,254 306,353.3 206.7,254 164,296.7 263.3,396 164,495.3 206.7,538    306,438.7 405.3,538 448,495.3 348.7,396  "/></g></svg>';
                div.appendChild(yesButton);
                div.appendChild(noButton);
                customElement.onclick = function (event) {
                  event.stopPropagation();
                };
                customElement.onmousedown = function (event) {
                  event.stopPropagation();
                };
                yesButton.onclick = function () {
                  customElement.innerHTML = "";
                  customElement.style.display = "none";
                  addSubEvidence(v, annotManager, caseObject);
                  handleAnnotations(annotManager);
                };
                noButton.onclick = function () {
                  customElement.innerHTML = "";
                  customElement.style.display = "none";
                };
              }
            });
          }
        } else if (action === "delete") {
          if (resp && resp.document_type === EVIDENCE_DOCUMENTS) {
            annotations.forEach((v) => {
              if (
                window.doc_object &&
                window.doc_object.sub_evidence &&
                window.doc_object.sub_evidence.find((se) => se.id === v.Id)
              ) {
                dispatch({
                  type: SAVE_MODAL_DATA,
                  payload: "Delete key section?",
                  beforeCloseHandler: async () => {
                    deleteSubEvidence(v, annotManager, caseObject);
                    handleAnnotations(annotManager);
                  },
                  extraData: () => {
                    const annotHistoryManager = docViewer.getAnnotationHistoryManager();
                    annotHistoryManager.undo();
                  },
                });
                dispatch({
                  type: SHOW_MODAL,
                  payload: MODAL_DELETE_KEY_SECTION,
                });
              }
            });
          }
        }
      });
      annotManager.on("annotationSelected", (annotations, action) => {
        if (action === "selected") {
          if (annotations.length === 1) {
            const sub_e = window.doc_object.sub_evidence.find(
              (v) => v.id === annotations[0].Id
            );
            if (sub_e) {
              dispatch({
                type: SHOW_RIGHT_SIDE_BAR,
                url: history.location.pathname,
                editableObject: sub_e,
                afterCloseAction: () => {
                  annotManager.deselectAnnotation(annotations[0]);
                },
                content: (
                  <SubEvidenceDocProperties
                    caseObject={caseResp}
                    caseId={id}
                    sub_evidence={window.doc_object.sub_evidence}
                    annotManager={annotManager}
                    documentId={documentId}
                    callback={updateRSB}
                  />
                ),
                swapContent: {
                  content: (
                    <SubEvidence
                      sub_evidence={window.doc_object.sub_evidence}
                      caseObject={caseResp}
                      callback={updateRSB}
                      annotManager={annotManager}
                      caseId={id}
                      documentId={documentId}
                    />
                  ),
                  title: "Key Sections",
                },
                title: `Edit Key Section`,
              });
            }
          }
        } else if (action === "deselected") {
          // console.log('annotation deselection');
        }
        if (annotations === null && action === "deselected") {
          // console.log('all annotations deselected');
        }
      });
      if (caseResp) {
        setDocument(resp);
        setSelectedClass(resp.documnet_type);
        setIsDocumentLoading(false);
        return true;
      }
      return false;
    };
    getData();
  }, [documentId]);
  const pageConfig = [
    {
      path: !is_admin ? `/app/cases` : `/admin/all-cases`,
      title: `${is_admin ? "All Cases" : "Cases"}`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin ? `/app/cases/${id}` : `/admin/all-cases/${id}`,
      title: caseObject.label ?? "New Case",
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/documents`
        : `/admin/all-cases/${id}/documents`,
      title: `Documents Hub`,
      activeMenuItem: CASES,
    },
    {
      path: !is_admin
        ? `/app/cases/${id}/documents/${documentId}`
        : `/admin/all-cases/${id}/documents/${documentId}`,
      title: document.name ?? "New Document",
      activeMenuItem: CASES,
    },
  ];
  const handleDelete = async (event) => {
    event.persist();
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: "You won't be able to restore data",
      beforeCloseHandler: async () => {
        await deleteDocument(id, documentId, dispatch);
        history.push(
          !is_admin
            ? `/app/cases/${id}/documents`
            : `/admin/all-cases/${id}/documents`
        );
      },
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
  };

  const updateMousePosition = (ev) => {
    window.mousePosition = { x: ev.pageX, y: ev.pageY };
  };
  useEffect(() => {
    if (
      document &&
      location &&
      location.state &&
      location.state.openEditDocPanel
    ) {
      openDocProperties();
    }
  }, [document]);

  const openDocProperties = () => {
    let content;
    if (document) {
      switch (document.document_type) {
        case AUTHORITIES_DOCUMENTS:
          content = (
            <AuthorityDocProperties
              caseObject={caseObject}
              callback={setDocument}
              caseID={id}
            />
          );
          break;
        case EVIDENCE_DOCUMENTS:
          content = (
            <EvidenceDocProperties
              caseObject={caseObject}
              callback={setDocument}
              caseID={id}
            />
          );
          break;
        case APPLICATION_DOCUMENTS:
          content = (
            <PapersDocProperties
              caseObject={caseObject}
              callback={setDocument}
              caseID={id}
            />
          );
          break;
        case CASE_DOCUMENTS:
          content = (
            <CaseDocProperties
              caseObject={caseObject}
              callback={setDocument}
              caseID={id}
            />
          );
          break;
        default:
          content = "";
      }
    }
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      editableObject: document,
      content: content,
      title: "Doc Properties",
    });
  };
  const classes = [
    { label: "Evidence", value: EVIDENCE_DOCUMENTS },
    { label: "Case Document", value: CASE_DOCUMENTS },
    { label: "Authority", value: AUTHORITIES_DOCUMENTS },
    { label: "Application Papers", value: APPLICATION_DOCUMENTS },
  ];
  return (
    <Fragment>
      {isDocumentLoading ? (
        <div
          className="justify-content-center align-items-center d-flex"
          style={{ height: "100vh" }}
        >
          <PulseLoader size={30} color={theme.colors.blue} />
        </div>
      ) : (
        <Fragment>
          <PageConfiguration configArray={pageConfig} />
          <div className="row mb-2">
            <div className="col d-flex flex-wrap justify-content-between">
              <Bold24Font className="mb-2">
                {document.name ?? "New Document"}
              </Bold24Font>
              <div className="d-flex flex-wrap justify-content-end">
                <div className="d-flex flex-wrap">
                  <ButtonNew
                    style={{ marginRight: "20px", marginBottom: "10px" }}
                    clickHandler={async () => {
                      let doc = window.documentV.getDocument();
                      const pagesToExtract = window.instance_.getSelectedThumbnailPageNumbers();
                      instance_.openElements(["leftPanel"]);
                      if (!pagesToExtract.length) {
                        handleErrorMessage(
                          "First, you need to select the pages that you would like to extract into a new document. Use CTRL+Click in the thumbnails to select pages to be extracted.",
                          dispatch,
                          null,
                          true
                        );
                        return;
                      }
                      const annotList = window.annotManager
                        .getAnnotationsList()
                        .filter(
                          (annot) =>
                            pagesToExtract.indexOf(annot.PageNumber) > -1
                        );
                      const xfdfString = await window.annotManager.exportAnnotations(
                        { annotList }
                      );
                      const data = await doc.extractPages(
                        pagesToExtract,
                        xfdfString
                      );
                      const arr = new Uint8Array(data);
                      const blob_ = new Blob([arr], {
                        type: "application/pdf",
                      });
                      window.selected_class = window.doc_object.document_type;
                      window.copy_tags = false;
                      dispatch({ type: EXTRACT_DATA_INITIAL });
                      dispatch({
                        type: SAVE_MODAL_DATA,
                        payload: (
                          <div>
                            <div className="form-group">
                              <Select
                                classNamePrefix="Select"
                                className="pl-3 pr-3"
                                options={classes}
                                defaultValue={
                                  window.selected_class
                                    ? classes.find(
                                        (v) => window.selected_class === v.value
                                      )
                                    : classes[0]
                                }
                                onChangeHandler={(select) => {
                                  window.selected_class = select.value;
                                }}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <label>
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    window.copy_tags = e.target.checked;
                                  }}
                                />
                                <Bold14Font className="ml-2">
                                  Copy Tags?
                                </Bold14Font>
                              </label>
                            </div>
                          </div>
                        ),
                        beforeCloseHandler: async () => {
                          dispatch({
                            type: SHOW_RIGHT_SIDE_BAR,
                            url: history.location.pathname,
                            content: (
                              <ExtractForm
                                blob={blob_}
                                copyTags={window.copy_tags}
                                parentObject={window.doc_object.classes}
                                selectedClass={window.selected_class}
                                caseId={id}
                                caseObject={caseObject}
                              />
                            ),
                            title: "Extract Doc",
                          });
                        },
                      });
                      dispatch({ type: SHOW_MODAL, payload: MODAL_DOC_TYPE });
                    }}
                    loading={isLoading}
                    disabled={isLoading || numPages < 2}
                    type={"submit"}
                    primary
                  >
                    Extract
                  </ButtonNew>
                  <ButtonNew
                    style={{
                      marginRight: "20px",
                      marginBottom: "10px",
                      whiteSpace: "nowrap",
                    }}
                    clickHandler={() => {
                      readOnly ? setReadOnly(false) : setReadOnly(true);
                    }}
                    loading={isLoading}
                    disabled={isLoading}
                    type={"submit"}
                    primary
                  >
                    {readOnly ? "Mark Up" : "Exit Mark Up"}
                  </ButtonNew>
                  {document.document_type !== CASE_DOCUMENTS ? (
                    <ButtonNew
                      style={{
                        marginRight: "20px",
                        marginBottom: "10px",
                        whiteSpace: "nowrap",
                      }}
                      clickHandler={openDocProperties}
                      type={"submit"}
                      primary
                    >
                      Doc Properties
                    </ButtonNew>
                  ) : (
                    <ButtonNew
                      primary
                      style={{ marginRight: "20px", marginBottom: "10px" }}
                      clickHandler={openDocProperties}
                    >
                      Doc Properties
                    </ButtonNew>
                  )}
                  <ButtonNew
                    style={{ marginBottom: "10px" }}
                    clickHandler={handleDelete}
                    loading={onDelete}
                    disabled={onDelete}
                    secondary
                    // delete_
                  >
                    {/*Delete*/}
                    <img
                      alt={"delete"}
                      src={DeleteIcon}
                      style={{ marginBottom: "3px" }}
                    />
                  </ButtonNew>
                </div>
              </div>
            </div>
          </div>
          {document.document_type === EVIDENCE_DOCUMENTS ||
          document.document_type === AUTHORITIES_DOCUMENTS ? (
            <div className="mb-2">
              <SeparatorStyled className="mt-2" />{" "}
              <EvidenceDocumentData
                document={document}
                caseObject={caseObject}
              />
            </div>
          ) : document.document_type === CASE_DOCUMENTS ? (
            <div className="mb-2">
              <SeparatorStyled className="mt-2" />{" "}
              <CaseDocumentData document={document} caseObject={caseObject} />
            </div>
          ) : document.document_type === APPLICATION_DOCUMENTS ? (
            <div className="mb-2">
              <SeparatorStyled className="mt-2" />{" "}
              <EvidenceDocumentData
                document={document}
                caseObject={caseObject}
              />
            </div>
          ) : null}
        </Fragment>
      )}
      <WebviewerDiv
        className="webviewer mb-4"
        ref={viewer}
        isLoading={isDocumentLoading}
      >
        {showPopup ? (
          <PopupStyled
            ref={popupRef}
            className="text-center"
            left={popupLeft}
            top={popupTop}
          >
            <Medium18Font>
              {popupType === "create"
                ? "Create key section?"
                : "Delete key section?"}
            </Medium18Font>
            <div className="d-flex justify-content-between mt-3">
              <ButtonNew
                primary
                clickHandler={() => {
                  if (popupType === "create") {
                    addSubEvidence(v_, annotManager_, caseObject);
                  } else {
                    deleteSubEvidence(v_, annotManager_, caseObject);
                  }
                  setShowPopup(false);
                }}
              >
                Yes
              </ButtonNew>
              <ButtonNew
                secondary
                clickHandler={() => {
                  setShowPopup(false);
                }}
              >
                No
              </ButtonNew>
            </div>
          </PopupStyled>
        ) : null}
      </WebviewerDiv>
    </Fragment>
  );
};

export default ViewDocument;
