import React, { Fragment, useEffect, useState } from "react";
import Label from "../AddCardForm/Label";
import { useDispatch } from "react-redux";
import {
  updateApplicationPaperById,
  updateAuthorityById,
  updateEvidence,
} from "../../../containers/Auth/auth";
import { Medium14Font } from "../../FontsNewComponent/Fonts";
import { theme } from "../../../styled-components/Theme/Theme";
import ButtonNew from "../../buttons/Button/ButtonNew";
import CheckboxNew from "../../Checkbox/CheckboxNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";
import { useRouteMatch } from "react-router-dom";
import { route } from "../../../routes";

const TESTIMONY = "Testimony";
const COMMUNICATION = "Communication";
const DOCUMENT = "Document";
const IMAGE_MEDIA = "Image/Media";
const AFFIDAVIT = "Affidavit";
const OTHER = "Other";
const LEGISLATION = "Legislation";
const CASE = "Case";
const COURT_RULE = "Court Rule";
const ACADEMIC_TEXT = "Academic Text";
const MOTION = "Motion";
const EXHIBIT = "Exhibit";

const typesEvidence = [
  TESTIMONY,
  COMMUNICATION,
  DOCUMENT,
  IMAGE_MEDIA,
  AFFIDAVIT,
  OTHER,
];

const typesAuthorities = [LEGISLATION, CASE, COURT_RULE, ACADEMIC_TEXT, OTHER];
const typesApplicationPaper = [MOTION, AFFIDAVIT, EXHIBIT];

const EditEvidenceTypeForm = ({
  caseId,
  callback,
  evidence,
  applicationId,
  isApplicationPapersBulkImportFlow,
  isApplicationAuthoritiesBulkImportFlow,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [object, setObject] = useState(evidence ?? {});
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const authoritiesBulkImport =
    useRouteMatch(`${route.baseApp.trialHub.authoritiesBulkImport}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.authoritiesBulkImport}`);
  const isAuthoritiesBulkImportFlow = !!(
    authoritiesBulkImport && authoritiesBulkImport.isExact
  );
  useEffect(() => {
    if (evidence) {
      setObject(evidence);
      setType(
        isApplicationPapersBulkImportFlow
          ? evidence.doc_type
            ? evidence.doc_type
            : ""
          : evidence.type
          ? evidence.type
          : ""
      );
    }
  }, [evidence]);
  useEffect(() => {
    setObject((prevState) => {
      return { ...prevState, ...{ type } };
    });
  }, [type]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      let resp;
      if (
        isAuthoritiesBulkImportFlow ||
        isApplicationAuthoritiesBulkImportFlow
      ) {
        resp = await updateAuthorityById(evidence.id, caseId, dispatch, {
          type,
        });
      } else if (isApplicationPapersBulkImportFlow) {
        resp = await updateApplicationPaperById(
          evidence.id,
          applicationId,
          caseId,
          dispatch,
          { doc_type: type }
        );
      } else {
        resp = await updateEvidence(evidence.id, caseId, dispatch, {
          type,
        });
      }
      callback(resp);
      setIsLoading(false);
    }
  };

  const selectTypeSource = () => {
    if (isAuthoritiesBulkImportFlow || isApplicationAuthoritiesBulkImportFlow) {
      return typesAuthorities;
    } else if (isApplicationPapersBulkImportFlow) {
      return typesApplicationPaper;
    } else {
      return typesEvidence;
    }
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
        <div className="form-group">
          <Label name={"Label"} />
          <Medium14Font
            className="pl-3 pr-3"
            textColor={theme.colors.darkOpacity}
          >
            {isAuthoritiesBulkImportFlow ||
            isApplicationAuthoritiesBulkImportFlow
              ? object.title
              : object.label}
          </Medium14Font>
        </div>
        <div className="form-group">
          <Label
            name={
              isAuthoritiesBulkImportFlow ||
              isApplicationAuthoritiesBulkImportFlow
                ? "Authority Type"
                : isApplicationPapersBulkImportFlow
                ? "Paper Type"
                : "Evidence Type"
            }
          />
          <div className="container-fluid">
            {selectTypeSource().map((item) => {
              return (
                <CheckboxNew
                  label={item}
                  checked={item === type}
                  onChange={() => {
                    setType(item);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <ButtonNew
            clickHandler={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            wide
            primary
          >
            Save
          </ButtonNew>
        </div>
      </div>
    </Fragment>
  );
};
export default EditEvidenceTypeForm;
