import styled, { css } from "styled-components/macro";
import { Medium14Font } from "../../../FontsNewComponent/Fonts";

const hoverAndCheckedState = css`
  border-color: ${({ theme }) => theme.colors.blue};

  ${Medium14Font} {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const RadioButtonGroupButtonDivStyled = styled.div`
  ${({ w100 }) => (w100 ? "width: 100%;" : "min-width: 164px;")};
  border: 1px solid ${({ theme }) => theme.colors.gray};

  ${({ checked }) => (checked ? hoverAndCheckedState : null)};

  border-bottom-left-radius: ${({ first }) => (first ? "50vh" : 0)};
  border-top-left-radius: ${({ first }) => (first ? "50vh" : 0)};
  border-bottom-right-radius: ${({ last }) => (last ? "50vh" : 0)};
  border-top-right-radius: ${({ last }) => (last ? "50vh" : 0)};

  @media (max-width: 767.98px) {
    border-radius: 50vh;
  }

  //padding: 10px 39px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.colors.light};
  cursor: ${({ disabled }) => (disabled ? 'default' : `pointer`)};
  :hover {
    ${({ disabled }) => (disabled ? null : hoverAndCheckedState)};
  }


`;

export default RadioButtonGroupButtonDivStyled;
