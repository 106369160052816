import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  previewAuthorityFile,
  previewEvidenceFile,
} from "../../../containers/Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import { useHistory, useRouteMatch } from "react-router-dom";
import { route } from "../../../routes";
import ButtonNew from "../../buttons/Button/ButtonNew";
import NoRecords from "../../NoRecords";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";

const EvidencePreview = ({
  caseId,
  evidence,
  goToDetails,
  withNotes,
  detailsLink,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [src, setSrc] = useState("");
  const authoritiesBulkImport =
    useRouteMatch(`${route.baseApp.trialHub.authoritiesBulkImport}`) ||
    useRouteMatch(`${route.admin.baseApp.trialHub.authoritiesBulkImport}`);
  const isAuthoritiesBulkImportFlow = !!(
    authoritiesBulkImport && authoritiesBulkImport.isExact
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let resp;
      if (isAuthoritiesBulkImportFlow) {
        resp = await previewAuthorityFile(evidence.id, caseId, dispatch);
      } else {
        resp = await previewEvidenceFile(evidence.id, caseId, dispatch);
      }
      if (resp) {
        setSrc(resp);
      }
      setIsLoading(false);
    };
    getData();
  }, []);

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <div
        className="container-fluid"
        style={{ height: "100%", overflowY: "auto" }}
      >
        <div className="form-group">
          {!src ? (
            <NoRecords>
              <Bold14Font>No document attached</Bold14Font>
            </NoRecords>
          ) : (
            <img style={{ maxWidth: "290px" }} src={src} alt={"preview"} />
          )}
        </div>
        {withNotes ? (
          <Fragment>
            <div className="form-group">
              <Bold14Font>Question Cues and Notes: </Bold14Font>
              <Medium14Font textColor={theme.colors.darkOpacity}>
                {evidence.notes.map((note, i) => {
                  return (
                    <Fragment>
                      <Medium14Font textColor={theme.colors.darkOpacity}>
                        {note.content}
                      </Medium14Font>
                      {evidence.notes.length - 1 !== i ? <br /> : null}
                    </Fragment>
                  );
                })}
              </Medium14Font>
            </div>
            <div className="form-group">
              <Bold14Font>Preempt: </Bold14Font>
              <Medium14Font textColor={theme.colors.darkOpacity}>
                {evidence.preempt}
              </Medium14Font>
            </div>
          </Fragment>
        ) : null}
      </div>
      {goToDetails ? (
        <div className="form-group mr-3 ml-3">
          <ButtonNew
            wide
            primary
            clickHandler={() => {
              history.push(
                detailsLink
                  ? detailsLink
                  : `${
                      is_admin ? "/admin/all-cases/" : "/app/cases/"
                    }${caseId}/trial-hub/evidence/${evidence.id}`
              );
            }}
          >
            View Details
          </ButtonNew>
        </div>
      ) : null}
    </Fragment>
  );
};

export default EvidencePreview;
