import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useContext,
} from "react";
import { NotebookContext, EditorContext } from "@/context";

import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

import { wrapIn } from "prosemirror-commands";

import DragHandle from "@editor/components/DragHandle";

import styled from "styled-components";

import "./SuggestionsList.scss";

const PlusIcon = ({ color }) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.41667 1V11"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6H1"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const colorByTag = {
  theme: "#259b5e",
  evidence: "#374ac2",
  witness: "#f178b6",
  section: "#7f48f4",
  objective: "#ff9017",
  topic: "#0f122f",
  exam: "#6a6c7d",
  party: "#ff0000",
  authority: "#0fcbcb",
  comment: "#80822d",
  subtheme: "#52d93d",
  issue: "#bc3970",
  keyfact: "#ba61c8",
  causesofaction: "#3597ad",
  element: "#0ed4ff",
  paper: "#ff00f5",
};

export const Container = styled.div`
  padding: 4px 12px;
  :hover {
    background: ${(props) => props.color}10;
  }
`;

export const TagsSuggestionsList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cancelled, setCancelled] = useState(false);
  const {
    isCreatingTag,
    setCreatingTag,
    creatingTagPos,
    setCreatingTagPos,
  } = useContext(EditorContext);

  const [creatingTagType, setCreatingTagType] = useState(undefined);
  const [creatingTagName, setCreatingTagName] = useState(undefined);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({
        id: item.label,
        type: item.type.toLowerCase(),
        tagId: item.tagId,
      });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = (ind, isPointer) => {
    const isCreate =
      props.items[ind || selectedIndex].suggestionType === "create";

    if (isCreate) {
      const item = props.items[ind || selectedIndex];

      let to = props.editor.view.state.selection.to;
      let from = to - item.phrase.length;

      const tagType = item.object.toLowerCase();

      props.editor
        .chain()
        .insertContentAt(
          { from, to },
          `<tag-creation class="tag-${tagType}" data-tagType="${tagType}">${item.phrase}</tag-creation>`
        )
        .setTextSelection(to)
        .insertContent(" ")
        .run();

      setCreatingTag(true);
      setCreatingTagPos(props.editor.view.state.selection.$anchor);
      setCancelled(true);
      return false;
    } else {
      if (!cancelled) {
        selectItem(selectedIndex);
      }
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(
    ref,
    () => ({
      onKeyDown: ({ event }) => {
        if (!!props.items.length && !cancelled) {
          if (event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (event.key === "Enter") {
            enterHandler();
            return true;
          }

          if (event.key === "Escape") {
            setCancelled(true);
            return true;
          }

          return false;
        }
      },
    }),
    [cancelled, enterHandler]
  );

  if (cancelled) return null;

  // hide when non found
  if (!props.items.length) return null;

  const items = props.items.filter((i) => i.suggestionType === "item");
  const createOptions = props.items.filter(
    (i) => i.suggestionType === "create"
  );

  return (
    <div className="suggestion-items" ref={ref} style={{ minWidth: 200 }}>
      {items.length ? (
        items.map((item, index) => (
          <Container color={colorByTag[item.type.toLowerCase()]}>
            <button
              style={{ width: "100%" }}
              className={`tag tag-${item.type && item.type.toLowerCase()} ${
                index === +selectedIndex
                  ? `tag--selected tag-${
                      item.type && item.type.toLowerCase()
                    }--selected`
                  : ""
              }`}
              key={`suggestion-item-${index}`}
              onClick={() => selectItem(index)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DragHandle
                  className={`fill--${item.type && item.type.toLowerCase()}`}
                  // fill="blue"
                  style={{
                    width: 8,
                    height: 16,
                    marginRight: 10,
                    opacity: 0.5,
                  }}
                />
                <span>{item.label}</span>
              </div>
            </button>
          </Container>
        ))
      ) : (
        <div />
      )}
      {createOptions.length ? (
        createOptions.map((item, index) => (
          <Container>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                color: colorByTag[item.object.toLowerCase()],
                cursor: "pointer",
                padding: "0px 4px",
                borderColor:
                  items.length + index === +selectedIndex
                    ? colorByTag[item.object.toLowerCase()]
                    : "transparent",
              }}
              className={`suggestion-item ${
                items.length + index === +selectedIndex ? "is-selected" : ""
              }`}
              key={`suggestion-create-${index}`}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={(e) => {
                e.preventDefault();
                enterHandler(items.length + index, true);
              }}
            >
              <PlusIcon color={colorByTag[item.object.toLowerCase()]} />
              <span
                style={{
                  marginLeft: 5,
                  fontWeight: 500,
                }}
              >{`Create new ${item.object.toLowerCase()}`}</span>
            </span>
          </Container>
        ))
      ) : (
        <div />
      )}
    </div>
  );
});
