import React, { Fragment, useEffect, useState } from "react";
import {Route, Switch, useRouteMatch, useHistory} from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { theme } from "../../../styled-components/Theme/Theme";
import {useDispatch} from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {route} from "../../../routes";
import AdminMenu from "../../../components/baseAppComponents/AdminMenu/AdminMenu";
import {Bold30Font} from "../../../components/FontsNewComponent/Fonts";
import {
  getAdminCasesTypes,
  getAdminCausesOfActions, getAdminOffences,
  getAdminReliefs,
  getAdminTestimonials,
} from "../../Auth/auth";
import AdminCaseTypes from "../../../components/baseAppComponents/AdminCaseTypes/AdminCaseTypes";
import AdminCOA from "../../../components/baseAppComponents/AdminCOA/AdminCOA";
import {SETTINGS} from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import AdminReliefs from "../../../components/baseAppComponents/AdminReliefs/AdminReliefs";
import AdminTestimonial from "../../../components/baseAppComponents/AdminTestimonials/AdminTestimonial";
import AdminOffences from "../../../components/baseAppComponents/AdminOffences/AdminOffences";


const Settings = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [cOA, setCOA] = useState([]);
  const [offences, setOffences] = useState([]);
  const [reliefs, setReliefs] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();
  useEffect(() => {

    const getData = async () => {
      setIsLoading(true);
      let caseTypesData = await getAdminCasesTypes(dispatch);
      let cOAData = await getAdminCausesOfActions(dispatch);
      let reliefsData = await getAdminReliefs(dispatch);
      let resp = await getAdminTestimonials(dispatch);
      let offencesResp = await getAdminOffences(dispatch);
      setCaseTypes(caseTypesData);
      setCOA(cOAData);
      setReliefs(reliefsData);
      setTestimonials(resp);
      setOffences(offencesResp);
      setIsLoading(false);
    };
    getData();
  }, []);


  const getType = (type) => {
    history.push(type);
  };
  const Menu = [
    {
      text: "Causes of Action & Elements",
      amount: cOA.length,
      activeType: route.admin.baseApp.settings.causesOfAction,
    },
    {
      text: "Offences",
      amount: offences.length,
      activeType: route.admin.baseApp.settings.offences,
    },
    {
      text: "Reliefs & Proofs",
      amount: reliefs.length,
      activeType: route.admin.baseApp.settings.reliefsAndProofs,
    },
    {
      text: "Case Types",
      amount: caseTypes.length,
      activeType: route.admin.baseApp.settings.cateTypes,
    },
    {
      text: "Testimonials",
      amount: testimonials.length,
      activeType: route.admin.baseApp.settings.testimonial,
    },
  ];

  const pageConfig = [
    {
      path: route.admin.baseApp.settings.causesOfAction,
      title: "Settings",
      activeMenuItem: SETTINGS,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <p className="pt-2 pb-2 pl-3"><Bold30Font>Settings</Bold30Font></p>
      <AdminMenu getType={getType} active={history.location.pathname} casesMenu={Menu}/>
      {isLoading ? <div className="d-flex justify-content-center align-items-center w-100 h-100">
        <PulseLoader size={30} color={theme.colors.blue}/>
      </div> : <Switch>
        <Route path={`${route.admin.baseApp.settings.causesOfAction}`}>
          <AdminCOA cOA={cOA} updateCOA={(coa) => {
            setCOA(coa);
          }}/>
        </Route>
        <Route path={`${route.admin.baseApp.settings.offences}`}>
          <AdminOffences cOA={offences} updateCOA={(coa) => {
            setOffences(coa);
          }}/>
        </Route>
        <Route path={`${route.admin.baseApp.settings.cateTypes}`}>
          <AdminCaseTypes caseTypes={caseTypes} updateCases={(cases_types) => {
            setCaseTypes(cases_types);
          }}/>
        </Route>
        <Route path={`${route.admin.baseApp.settings.reliefsAndProofs}`}>
          <AdminReliefs Relief={reliefs} updateRelief={(relief) => {
            setReliefs(relief);
          }}/>
        </Route>
        <Route path={`${route.admin.baseApp.settings.testimonial}`}>
          <AdminTestimonial Testimony={testimonials} updateTestimony={(v) => {
            setTestimonials(v);
          }}/>
        </Route>
      </Switch>}
      <div className="row pb-5 justify-content-center">
      </div>
    </Fragment>
  );
};

export default Settings;
