import styled from "styled-components/macro";

const DivStyled = styled.div`
  border-radius: 4px;
  max-width: 570px;

  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.white};
`;

export default DivStyled;
