import styled from "styled-components/macro";
import PropTypes from "prop-types";

const RsbCaseCardPreviewDotStyled = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(37, 155, 154, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ theme, isArchive }) =>
      isArchive ? theme.colors.gray : theme.colors.turquoise};
  }
`;

RsbCaseCardPreviewDotStyled.propTypes = {
  /**
   * Set color for dot based on archive status
   */
  isArchive: PropTypes.bool
};

export default RsbCaseCardPreviewDotStyled;
