import React from "react";
import PropTypes from "prop-types";

import LandingPageSocialLinkStyled from "./styled/LandingPageSocialLinkStyled";

const LandingPageSocialLink = ({ href, footer, children }) => {
  return (
    <LandingPageSocialLinkStyled
      href={href}
      footer={footer}
      children={children}
    />
  );
};

LandingPageSocialLink.propTypes = {
  /**
   * Link to social network
   */
  href: PropTypes.string.isRequired,

  /**
   * Enable footer style for container
   */
  footer: PropTypes.bool,
};

LandingPageSocialLink.defaultProps = {
  footer: false,
};

export default LandingPageSocialLink;
