import React from "react";
import PanelStyled from "./styled/PanelStyled";
import PropTypes from "prop-types";
import { Bold18Font } from "../FontsNewComponent/Fonts";
import { theme } from "../../styled-components/Theme/Theme";
import IndexStyled from "./styled/IndexStyled";

export default function Panel(props) {
  let { items, activeItem, onChange, hideIcon } = props;
  // let coll_class = "col-" + 12 / items.length;
  let coll_class = "col";
  if (items.length === 4) {
    coll_class = "col-6 mb-1 mt-0 mb-sm-3 mt-sm-3 col-sm-" + 12 / items.length;
    coll_class = "col-6 mb-1 mt-0 mb-sm-3 mt-sm-3 col-sm-" + 12 / items.length;
  }
  if (items.length === 3) {
    coll_class =
      "col-12 mb-1 mt-0 mb-sm-3 mt-sm-3 col-sm-" +
      12 / items.length +
      "  pl-5 pl-sm-0 justify-content-sm-center";
  }
  if (items.length !== 3) {
    coll_class += " justify-content-center";
  }
  coll_class += " d-flex align-items-center";
  return (
    <div className={props.divClass ?? "row"} style={{ marginBottom: "30px" }}>
      {items.map((value, index) => {
        let is_active = index === activeItem;
        return (
          <PanelStyled
            key={index}
            className={coll_class}
            onClick={() => {
              onChange(index);
            }}
            first={index === 0}
            last={index === items.length - 1}
            active={is_active}
            {...props}
          >
            {hideIcon ? null : !value.icon ? (
              <IndexStyled active={is_active}>{index + 1}</IndexStyled>
            ) : (
              <img
                src={value.icon}
                alt=""
                className="img-fluid"
                style={{ marginRight: "10px", width: "24px" }}
              />
            )}
            <Bold18Font
              style={{
                color: is_active ? theme.colors.blue : theme.colors.darkOpacity,
              }}
            >
              {value.title ?? value}
            </Bold18Font>
          </PanelStyled>
        );
      })}
    </div>
  );
}
Panel.propTypes = {
  items: PropTypes.array.isRequired,
  activeItem: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
