import React from "react";

const Item = ({ notebook }) => {
  return (
    <div>
      <span />
      {notebook.title}
    </div>
  );
};

export default Item;
