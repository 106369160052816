import React, { useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import PropTypes from "prop-types";
import { Bold14Font, Medium18Font } from "../../FontsNewComponent/Fonts";
import RoundButton from "../../buttons/RoundButton";
import ExpandableButtonStyled from "../../buttons/RoundButton/customStyled/ExpandableButtonStyled";
import arrowDownIcon from "../../../assets/img3/svg/arrow-down-icon.svg";

/**
 * Expandable block. Must be placed in bootstrap <div className="container{-fluid}">...</div>
 * @param dataArray
 * @param visibleElements
 * @param config
 * @param children
 * @return {*}
 * @constructor
 */
const ExpandableBlock = ({
  dataObject,
  visibleElements,
  config,
  children,
  theme,
}) => {
  const [dataInVisiblePart, setDataInVisiblePart] = useState(null);
  const [dataInExpandedPart, setDataInExpandedPart] = useState(null);
  const [needExpand, setNeedExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onClickExpandButtonHandler = () => {
    setExpanded((prevState) => !prevState);
  };

  /**
   * Divide dataArray to dataInVisiblePart and dataInExpandedPart using visibleElements configuration.
   */
  useEffect(() => {
    const dataObjectCopy = { ...dataObject };
    const visiblePartArray = dataObjectCopy[config.field];
    const expandedPartArray = visiblePartArray
      ? visiblePartArray.splice(visibleElements, visiblePartArray.length)
      : [];
    setDataInVisiblePart(visiblePartArray);
    setDataInExpandedPart(expandedPartArray);

    if (expandedPartArray.length) {
      setNeedExpand(true);
    }
  }, []);

  if (dataInVisiblePart) {
    return (
      <div className="row">
        <div className="col-12 mb-2">
          <Bold14Font>{config ? config.title : null}</Bold14Font>
        </div>
        {/* Visible part */}
        <div className="col-12">
          {dataInVisiblePart.length ? (
            dataInVisiblePart.map((el) => (
              <Medium18Font key={el.id} as="p" style={{ marginBottom: "12px" }}>
                {config.render(el)}
              </Medium18Font>
            ))
          ) : (
            <Medium18Font as="p">N/A</Medium18Font>
          )}
        </div>
        {/* Expandable part */}
        {expanded ? (
          <div className="col-12">
            {dataInExpandedPart.length
              ? dataInExpandedPart.map((el) => (
                  <Medium18Font
                    key={el.id}
                    as="p"
                    style={{ marginBottom: "12px" }}
                  >
                    {config.render(el)}
                  </Medium18Font>
                ))
              : null}
          </div>
        ) : null}
        {/* Show more button */}
        {needExpand ? (
          <div className="col-12 mb-4">
            <div
              className="d-flex align-items-center"
              onClick={onClickExpandButtonHandler}
            >
              <RoundButton
                customStyle={ExpandableButtonStyled}
                style={{ marginRight: "10px" }}
              >
                <img
                  src={arrowDownIcon}
                  alt=""
                  width={6}
                  height={6}
                  style={{
                    // width: "6px",
                    transform: `rotate(${expanded ? "180deg" : "0"})`,
                  }}
                />
              </RoundButton>
              <Bold14Font
                as="div"
                style={{ color: theme.colors.blue, cursor: "pointer" }}
              >
                {expanded ? "Show less" : "Show more"}
              </Bold14Font>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  return null;
};

ExpandableBlock.propTypes = {
  /**
   * Array of incoming data
   */
  dataObject: PropTypes.object.isRequired,
  /**
   * How many elements should seen in visible part
   */
  visibleElements: PropTypes.number,
  // /**
  //  * Title of section
  //  */
  // title: PropTypes.string,
  /**
   * Config that used to render elements
   */
  config: PropTypes.object.isRequired,
};

ExpandableBlock.defaultProps = {
  visibleElements: 2,
  // title: "",
};

export default withTheme(ExpandableBlock);
