import styled from "styled-components/macro";
import { Bold14Font } from "../../../../components/FontsNewComponent/Fonts";

const PasswordFormInputLabelStyled = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${Bold14Font} {
    display: block;
    margin-left: 17px;
    margin-bottom: 10px;
  }
`;

export default PasswordFormInputLabelStyled;
