import React, { useState, useEffect, useRef, useContext } from "react";
import { EditorContext, NotebookContext } from "@/context";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components/macro";
import { PulseLoader } from "react-spinners";
import { useHistory, useParams } from "react-router-dom";
import { handleFlatRelevanceElements, setTagField } from "@/utils/function";
import { loadAccessToken, uploadEvidenceFile } from "@/containers/Auth/auth";
import { maxFileSize } from "@/utils";
import AssociateWitnessForm, {
  createWitnessListByRoles,
} from "@/components/forms/EvidenceForms/AssociateWitnessForm";
import moment from "moment";

import { Medium14Font } from "@/components/FontsNewComponent/Fonts";
import { parseISO } from "date-fns";
import axios from "axios";
import CloseIcon from "@/components/svgIcons/CloseIcon";

import InputNew from "@/components/InputNew/InputNew";
import ButtonNew from "@/components/buttons/Button/ButtonNew";
import SelectAnotherTry from "@/components/Select/SelectAnotherTry";
import CustomReactDatepicker from "@/components/inputs/CustomReactDatepicker";
import TextArea from "@/components/TextArea/TextArea";
import UploadSingleFileInput from "@/components/inputs/UploadSingleFileInput";
import ButtonAnotherTry from "@/components/buttons/Button/ButtonAnotherTry";
import EditObjectPanel from "@/components/Notebook/EditObjectPanel";

import { Container, ButtonContainer, Column } from "./styled";
import {
  SHOW_RIGHT_SIDE_BAR,
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
} from "@/redux/types";
import { Select } from "antd";
import { Bold14Font } from "@/components/FontsNewComponent/Fonts";

import AssociateElementsThemesIssuesForm from "@/components/forms/EvidenceForms/AssociateElementsThemesIssuesForm";

import AssociationTable, {
  AssociationTableListElement,
} from "@/components/AssociationTable";

// evidence types constants:
const TESTIMONY = "Testimony";
const COMMUNICATION = "Communication";
const DOCUMENT = "Document";
const IMAGE_MEDIA = "Image/Media";
const AFFIDAVIT = "Affidavit";
const OTHER = "Other";

// evidence status constants:
const SUPPORTING = "Supporting";
const OPPOSING = "Opposing";
const MIXED = "Mixed";

const statusOptions = [
  {
    label: SUPPORTING,
    value: SUPPORTING,
  },
  {
    label: OPPOSING,
    value: OPPOSING,
  },
  {
    label: MIXED,
    value: MIXED,
  },
];

const typeOptions = [
  {
    label: TESTIMONY,
    value: TESTIMONY,
  },
  {
    label: COMMUNICATION,
    value: COMMUNICATION,
  },
  {
    label: DOCUMENT,
    value: DOCUMENT,
  },
  {
    label: IMAGE_MEDIA,
    value: IMAGE_MEDIA,
  },
  {
    label: AFFIDAVIT,
    value: AFFIDAVIT,
  },
  {
    label: OTHER,
    value: OTHER,
  },
];

const coreOptions = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

const { Option } = Select;
const children = [];

for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const EditEvidence = (props) => {
  const prevWitObj = useSelector((state) => state.rightSideBar.editableObject);
  const [evidenceObject, setEvidenceObject] = useState({ ...prevWitObj } || {});

  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const { editor, setContent } = useContext(EditorContext);
  const { caseObject, setCaseObject } = useContext(NotebookContext);

  // const { id: caseId, notebook_id: notebookId } = useParams();
  const caseId = window.location.pathname.split("/")[3];

  const [file, setFile] = useState(new File([""], "Select file"));

  useEffect(() => {
    if (prevWitObj) {
      const newObj = { ...evidenceObject, ...prevWitObj };
      setEvidenceObject((oldWit) => ({ ...oldWit, ...prevWitObj }));
    }
  }, [prevWitObj]);

  useEffect(() => {
    setFlagRelevanceSettings((o) => ({
      ...o,
      objects: handleFlatRelevanceElements(
        evidenceObject.causes_of_action_parent ?? [],
        evidenceObject.causes_of_action ?? [],
        evidenceObject.causes_of_action_defensive ?? [],
        evidenceObject.issues_parent ?? [],
        evidenceObject.issues ?? [],
        evidenceObject.themes_parent ?? [],
        evidenceObject.themes ?? [],
        evidenceObject.case
      ),

      actions: [
        {
          type: "addButton",
          buttonText: "Associate Elements, Themes, Issues",
          callback: () => handleAssociateElementsThemesIssues(evidenceObject),
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => {
            handleAssociateElementsThemesIssues(evidenceObject, index);
          },
        },
        {
          type: "delete",
          clickHandler: (index) => {
            switch (index) {
              case 0:
                updateEvidenceObject({
                  causes_of_action: [],
                  causes_of_action_parent: [],
                  causes_of_action_defensive: [],
                });
                break;

              case 1:
                updateEvidenceObject({
                  themes: [],
                  themes_parent: [],
                });
                break;

              case 2:
                updateEvidenceObject({
                  issues: [],
                  issues_parent: [],
                });
                break;

              default:
                break;
            }
          },
        },
      ],
    }));
  }, [evidenceObject, caseObject]);

  // setWitnesses
  const [objectives, setObjectives] = useState([]);
  const [notes, setNotes] = useState([]);
  const [evidences, setEvidences] = useState([]);

  const { caseLibrary, setCaseLibrary } = useContext(NotebookContext);

  useEffect(() => {
    if (!evidenceObject || Object.keys(evidenceObject).length === 0) {
      fetchObject();
    } else {
      const object = evidenceObject;

      const witnessList = object.witnesses || [];
      const witnessesTableObjectCopy = createWitnessListByRoles(witnessList);
      setUniquePartyRolesList(Object.keys(witnessesTableObjectCopy));
      setWitnessTableObject({ ...witnessesTableObjectCopy });

      setEvidenceObject((wit) => ({ ...wit, ...object }));

      // if (object.file && !file) {
      if (object.file_new) {
        setFile(object.file_new);
      } else if (object.file) {
        if (object.file) {
          setFile(new File([""], object.file));
        }
      }

      if (object.evidences_new) {
        setEvidences(object.evidences_new);
      } else {
        setEvidences(
          object.related_evidence.map((i) => ({
            label: i.label,
            value: i.fake_id,
          }))
        );
      }

      if (object.objectives_new) {
        setObjectives(object.objectives_new);
      } else {
        setObjectives(
          object.objectives.map((i) => ({ label: i.label, value: i.id }))
        );
      }

      if (object.notes_new) {
        setNotes(object.notes_new);
      } else {
        setNotes(object.notes.map((i) => ({ label: i.label, value: i.id })));
      }
    }
  }, []);

  const fetchObject = async () => {
    setDataLoading(true);
    const { pathname } = window.location;
    const caseId = pathname.split("/")[3];

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };
    axios
      .get(`/api/cases/${caseId}/evidence/${props.tagId}`, config)
      .then((res) => {
        setDataLoading(false);
        if (res && res.data && res.data.data) {
          const object = res.data.data;

          const witnessList = object.witnesses || [];
          const witnessesTableObjectCopy = createWitnessListByRoles(
            witnessList
          );
          setUniquePartyRolesList(Object.keys(witnessesTableObjectCopy));
          setWitnessTableObject({ ...witnessesTableObjectCopy });

          setEvidenceObject((wit) => ({ ...object, ...wit }));

          setEvidences(
            object.related_evidence.map((i) => ({
              label: i.label,
              value: i.fake_id,
            }))
          );
          setObjectives(
            object.objectives.map((i) => ({ label: i.label, value: i.id }))
          );
          setNotes(object.notes.map((i) => ({ label: i.label, value: i.id })));

          if (object.file) {
            setFile(new File([""], object.file));
          }
        }
      });
  };

  const createObject = async (text, type) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    return axios.post(
      `/api/cases/${caseId}/notes/objects?type=${type}&id_case=${caseId}`,
      {
        text,
      },
      config
    );
  };

  useEffect(() => {
    const onEscape = (e) => {
      if (e.key === "Escape") {
        dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      }
    };

    window.addEventListener("keyup", onEscape);
    return () => window.removeEventListener("keyup", onEscape);
  }, []);

  const saveResult = async () => {
    setLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    let allEvidences = (evidences || evidenceObject.evidences_new).filter(
      (i) => !!i.label
    );
    let allNotes = (notes || evidenceObject.notes_new).filter((i) => !!i.label);
    let allObjectives = (objectives || evidenceObject.objectives_new).filter(
      (i) => !!i.label
    );

    for (let ev of objectives || evidenceObject.objectives_new) {
      if (!ev.label && !caseLibrary.Objective.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Objective").then((res) => {
          allObjectives.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Objective: [...caseLibrary.Objective, res.data.data],
          }));
        });
      }
    }

    for (let ev of notes || evidenceObject.notes_new) {
      if (!ev.label && !caseLibrary.Note.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Note").then((res) => {
          allNotes.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Note: [...caseLibrary.Note, res.data.data],
          }));
        });
      }
    }

    for (let ev of evidences || evidenceObject.evidences_new) {
      if (!ev.label && !caseLibrary.Evidence.some((i) => i.id == ev.value)) {
        await createObject(ev.value, "Evidence").then((res) => {
          allEvidences.push({
            label: res.data.data.label,
            value: res.data.data.id,
          });
          setCaseLibrary((caseLibrary) => ({
            ...caseLibrary,
            Evidence: [...caseLibrary.Evidence, res.data.data],
          }));
        });
      }
    }

    let body = {
      ...evidenceObject,

      objectives: allObjectives.map((i) => i.value),
      evidences: allEvidences.map((i) => i.value),
      notes: allNotes.map((i) => i.value),
    };

    delete body.topics_new;
    delete body.evidences_new;
    delete body.objectives_new;
    delete body.related_evidence;
    delete body.file_new;

    let endpoint = `/api/cases/${caseId}/evidence/${props.tagId}/new`;

    const res = await axios.put(endpoint, body, config).catch((error) => {
      alert(error);
    });

    if (file.size !== 0) {
      let formData = new FormData();
      formData.append("file", file);
      const fileResp = await uploadEvidenceFile(
        props.tagId,
        caseId,
        dispatch,
        formData
      );
    }

    if (!res) {
      dispatch({ type: HIDE_RIGHT_SIDE_BAR });
      return;
    }

    setLoading(false);

    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "witness" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "evidence"
                ) {
                  if (
                    subNode.attrs.tagId === props.tagId &&
                    subNode.attrs.tagType.toLowerCase() ===
                      props.tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: evidenceObject.label },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);

    // replace in case library [start]
    const itemIndex = caseLibrary.Evidence.findIndex(
      (i) => i.id == props.tagId
    );
    const newEvidence = [
      ...caseLibrary.Evidence.slice(0, itemIndex),
      {
        ...caseLibrary.Evidence[itemIndex],
        label: evidenceObject.label,
      },
      ...caseLibrary.Evidence.slice(itemIndex + 1),
    ];

    setCaseLibrary((caseLibrary) => ({
      ...caseLibrary,
      Evidence: newEvidence,
    }));
    // replace in case library [end]

    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };

  const updateEvidenceObject = (data) => {
    const obj = { ...evidenceObject, ...data };
    setWitnessList(obj.witnesses || []);
    setEvidenceObject(obj);
    dispatch({
      type: SET_EDITABLE_OBJECT,
      editableObject: {
        ...obj,
        // witnesses_res: witnesses,
      },
    });
  };

  const handleAssociateElementsThemesIssues = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      // type: SHOW_RIGHT_SIDE_BAR_TWO,
      url: history.location.pathname,
      rightBarWidth: "18%",
      editableObject: {
        ...evidenceObject,
        // witnesses_res: witnesses,
      },
      content: (
        <AssociateElementsThemesIssuesForm
          key={evidenceObject.id}
          caseId={caseId}
          updateCaseObject={(d) => {
            setCaseObject((obj) => ({ ...obj, d }));
            setEvidenceObject((obj) => ({ ...obj, case: { ...obj.case, d } }));
          }}
          evidenceObject={evidenceObject}
          setEvidenceObject={(data) => {
            updateEvidenceObject(data);
          }}
          selectedMenuTab={index}
          addToSwapContent={[
            <EditObjectPanel {...props} evidenceObject={evidenceObject} />,
          ]}
          rightBarWidth={"18%"}
          // otherSidebar
        />
      ),
      swapContent: {
        content: <EditObjectPanel evidenceObject={evidenceObject} {...props} />,
      },
      title: object.name ? "Edit: " + object.name : "Flag Relevance",
    });
  };

  const setEvidenceFlagRelevanceElements = (causes_of_action) => {
    updateEvidenceObject({ causes_of_action });
  };
  const setEvidenceFlagRelevanceDefenceElements = (
    causes_of_action_defensive
  ) => {
    updateEvidenceObject({ causes_of_action_defensive });
  };
  const setEvidenceFlagRelevanceParentElements = (causes_of_action_parent) => {
    updateEvidenceObject({ causes_of_action_parent });
  };
  const setEvidenceFlagRelevanceThemes = (themes) => {
    updateEvidenceObject({ themes });
  };
  const setEvidenceFlagRelevanceParentThemes = (themes_parent) => {
    updateEvidenceObject({ themes_parent });
  };
  const setEvidenceFlagRelevanceIssues = (issues) => {
    updateEvidenceObject({ issues });
  };
  const setEvidenceFlagRelevanceParentIssues = (issues_parent) => {
    updateEvidenceObject({ issues_parent });
  };
  const flagRelevanceSettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Elements, Themes, Issues",
        callback: () => handleAssociateElementsThemesIssues(evidenceObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateElementsThemesIssues(evidenceObject, index);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          switch (index) {
            case 0:
              updateEvidenceObject({
                causes_of_action: [],
                causes_of_action_parent: [],
                causes_of_action_defensive: [],
              });
              break;

            case 1:
              updateEvidenceObject({
                themes: [],
                themes_parent: [],
              });
              break;

            case 2:
              updateEvidenceObject({
                issues: [],
                issues_parent: [],
              });
              break;

            default:
              break;
          }
        },
      },
    ],
    // Objects to render in rows
    objects: handleFlatRelevanceElements(
      evidenceObject.causes_of_action_parent ?? [],
      evidenceObject.causes_of_action ?? [],
      evidenceObject.causes_of_action_defensive ?? [],
      evidenceObject.issues_parent ?? [],
      evidenceObject.issues ?? [],
      evidenceObject.themes_parent ?? [],
      evidenceObject.themes ?? [],
      evidenceObject.case
    ),
    setObjectsFunctions: {
      setElements: setEvidenceFlagRelevanceElements,
      setThemes: setEvidenceFlagRelevanceThemes,
      setIssues: setEvidenceFlagRelevanceIssues,
    },
    setParentObjectsFunctions: {
      setElementsParent: setEvidenceFlagRelevanceParentElements,
      setThemesParent: setEvidenceFlagRelevanceParentThemes,
      setIssuesParent: setEvidenceFlagRelevanceParentIssues,
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow, setParentArray) => {
          if (array.length) {
            return array.map((element, index) => (
              <element.tagColor key={index} as="li" index={element.index}>
                <Bold14Font style={{ color: "inherit" }}>
                  {element.name}
                </Bold14Font>
                <ButtonAnotherTry
                  tertiary
                  style={{ lineHeight: "0", padding: "0", marginLeft: "6px" }}
                  clickHandler={() => {
                    const listCopy = array.filter(
                      (el) => el.typeCOA === "children"
                    );
                    const listParentCopy = array.filter(
                      (el) => el.typeCOA === "parent"
                    );
                    const listDefElementsCopy = array.filter(
                      (el) => el.typeCOA === "children_def"
                    );
                    if (element.typeCOA === "parent") {
                      const findElementIndex = listParentCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listParentCopy.splice(findElementIndex, 1);
                        setParentArray([...listParentCopy]);
                      }
                    } else if (element.typeCOA === "children") {
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        setArray([...listCopy]);
                      }
                    } else if (element.typeCOA === "children_def") {
                      const findElementIndex = listDefElementsCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listDefElementsCopy.splice(findElementIndex, 1);
                        setEvidenceFlagRelevanceDefenceElements([
                          ...listDefElementsCopy,
                        ]);
                      }
                    }
                    switch (indexOfRow) {
                      case 0:
                        updateEvidenceObject({
                          causes_of_action: listCopy,
                          causes_of_action_defensive: listDefElementsCopy,
                          causes_of_action_parent: listParentCopy,
                        });
                        break;

                      case 1:
                        updateEvidenceObject({
                          themes: listCopy,
                          themes_parent: listParentCopy,
                        });
                        break;

                      case 2:
                        updateEvidenceObject({
                          issues: listCopy,
                          issues_parent: listParentCopy,
                        });
                        break;

                      default:
                        break;
                    }
                  }}
                >
                  <CloseIcon stroke={"inherit"} />
                </ButtonAnotherTry>
              </element.tagColor>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };
  const [flagRelevanceSettings, setFlagRelevanceSettings] = useState(
    flagRelevanceSettingsDef
  );

  const [witnessList, setWitnessList] = useState([]);
  const [witnessesTableObject, setWitnessTableObject] = useState({});
  const [uniquePartyRolesList, setUniquePartyRolesList] = useState([]);

  // useEffect(() => {
  //   if (witnessList) {
  //     const witnessesTableObjectCopy = createWitnessListByRoles(witnessList);
  //     setUniquePartyRolesList(Object.keys(witnessesTableObjectCopy));
  //     console.log(witnessList);
  //     setWitnessTableObject({ ...witnessesTableObjectCopy });
  //   }
  // }, [witnessList, evidenceObject]);

  const associateWitnessSettingsDef = {
    actions: [
      // if addButton needed, it  must always be first in array!
      {
        type: "addButton",
        buttonText: "Associate Witness",
        callback: () => handleAssociateWitness(evidenceObject),
      },
      // buttons in row
      {
        type: "edit",
        clickHandler: (index) => {
          handleAssociateWitness(evidenceObject);
        },
      },
      {
        type: "delete",
        clickHandler: (index) => {
          const filteredListOfWitnessesByPartyRole = witnessList.filter((x) => {
            if (x.party) {
              return x.party.role !== uniquePartyRolesList[index];
            } else {
              x.party = { role: "Unassigned" };
              return x.party.role !== uniquePartyRolesList[index];
            }
          });

          // ZOHO
          updateEvidenceObject({
            witnesses: [...filteredListOfWitnessesByPartyRole],
          });
          // setEvidenceObject((obj) => ({
          //   ...obj,
          //   witnesses: [...filteredListOfWitnessesByPartyRole],
          // }));
        },
      },
    ],
    // Objects to render in rows
    objects: {
      ...witnessesTableObject,
    },
    // "setState" functions that changes objects (order as in objects field!)
    setObjectsFunctions: {
      setWitnessTableObject: (data) => setWitnessTableObject(data),
    },
    // fields to show in the rows
    fields: [
      {
        rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
        rowElements: (array, setArray, indexOfRow) => {
          if (array.length) {
            return array.map((element, index) => (
              <AssociationTableListElement key={index}>
                <Bold14Font textColor={props.theme.colors.blue} as="div">
                  {element.name}
                </Bold14Font>
                <div>
                  <ButtonNew
                    tertiary
                    clickHandler={() => {
                      const listCopy = [...array];
                      const role = listCopy[0].party
                        ? listCopy[0].party.role
                        : "Unassigned";
                      const witnessesTableObjectCopy = {
                        ...witnessesTableObject,
                      };
                      const findElementIndex = listCopy.findIndex(
                        (x) => x.id === element.id
                      );
                      if (findElementIndex !== -1) {
                        listCopy.splice(findElementIndex, 1);
                        // setArray(listCopy);
                        // here we NOT set array, but set new object
                        const newObject = {
                          ...witnessesTableObjectCopy,
                          [role]: listCopy,
                        };
                        setArray(newObject);
                        // make plain array with ID to send to api
                        const plainArray = [];
                        Object.keys(newObject).forEach((item) => {
                          newObject[item].forEach((el) => {
                            plainArray.push(el);
                          });
                        });

                        // ZOHO

                        updateEvidenceObject({
                          witnesses: plainArray,
                        });
                        // setEvidenceObject((obj) => ({
                        //   ...obj,
                        //   witnesses: plainArray,
                        // }));
                      }
                    }}
                  >
                    {<CloseIcon stroke={props.theme.colors.blue} />}
                  </ButtonNew>
                </div>
              </AssociationTableListElement>
            ));
          } else {
            return <Medium14Font>N/A</Medium14Font>;
          }
        },
      },
    ],
  };

  const [associateWitnessSettings, setAssociateWitnessSettings] = useState(
    associateWitnessSettingsDef
  );

  useEffect(() => {
    setAssociateWitnessSettings({
      actions: [
        // if addButton needed, it  must always be first in array!
        {
          type: "addButton",
          buttonText: "Associate Witness",
          callback: () => handleAssociateWitness(evidenceObject),
        },
        // buttons in row
        {
          type: "edit",
          clickHandler: (index) => {
            handleAssociateWitness(evidenceObject);
          },
        },
        {
          type: "delete",
          clickHandler: (index) => {
            const filteredListOfWitnessesByPartyRole = witnessList.filter(
              (x) => {
                if (x.party) {
                  return x.party.role !== uniquePartyRolesList[index];
                } else {
                  x.party = { role: "Unassigned" };
                  return x.party.role !== uniquePartyRolesList[index];
                }
              }
            );

            // ZOHO
            updateEvidenceObject({
              witnesses: [...filteredListOfWitnessesByPartyRole],
            });
            // setEvidenceObject((obj) => ({
            //   ...obj,
            //   witnesses: [...filteredListOfWitnessesByPartyRole],
            // }));
          },
        },
      ],
      // Objects to render in rows
      objects: {
        ...witnessesTableObject,
      },
      // "setState" functions that changes objects (order as in objects field!)
      setObjectsFunctions: {
        setWitnessTableObject: (data) => setWitnessTableObject(data),
      },
      // fields to show in the rows
      fields: [
        {
          rowName: (object) => <Bold14Font>{object}:</Bold14Font>,
          rowElements: (array, setArray, indexOfRow) => {
            if (array.length) {
              return array.map((element, index) => (
                <AssociationTableListElement key={index}>
                  <Bold14Font textColor={props.theme.colors.blue} as="div">
                    {element.name}
                  </Bold14Font>
                  <div>
                    <ButtonNew
                      tertiary
                      clickHandler={() => {
                        const listCopy = [...array];
                        const role = listCopy[0].party
                          ? listCopy[0].party.role
                          : "Unassigned";
                        const witnessesTableObjectCopy = {
                          ...witnessesTableObject,
                        };
                        const findElementIndex = listCopy.findIndex(
                          (x) => x.id === element.id
                        );
                        if (findElementIndex !== -1) {
                          listCopy.splice(findElementIndex, 1);
                          // setArray(listCopy);
                          // here we NOT set array, but set new object
                          const newObject = {
                            ...witnessesTableObjectCopy,
                            [role]: listCopy,
                          };
                          setArray(newObject);
                          // make plain array with ID to send to api
                          const plainArray = [];
                          Object.keys(newObject).forEach((item) => {
                            newObject[item].forEach((el) => {
                              plainArray.push(el);
                            });
                          });

                          // ZOHO

                          updateEvidenceObject({
                            witnesses: plainArray,
                          });
                          // setEvidenceObject((obj) => ({
                          //   ...obj,
                          //   witnesses: plainArray,
                          // }));
                        }
                      }}
                    >
                      {<CloseIcon stroke={props.theme.colors.blue} />}
                    </ButtonNew>
                  </div>
                </AssociationTableListElement>
              ));
            } else {
              return <Medium14Font>N/A</Medium14Font>;
            }
          },
        },
      ],
    });
  }, [
    witnessesTableObject,
    setWitnessTableObject,
    witnessList,
    evidenceObject,
  ]);

  const handleAssociateWitness = (object, index = null) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <AssociateWitnessForm
          key={object.id}
          evidenceObject={object}
          // setEvidenceObject={setEvidenceObject}
          selectedMenuTab={index}
          caseId={caseId}
          caseObject={caseObject}
          // afterSubmit={associateWitnessChange}
          // returnFunction={setEvidenceAndSubmitObjects}
          returnFunction={(a) => {
            // ZOHO
            // setEvidenceObject((obj) => ({
            //   ...obj,
            //   ...a,
            // }));
            updateEvidenceObject(a);
          }}
        />
      ),
      rightBarWidth: "18%",
      swapContent: {
        content: <EditObjectPanel evidenceObject={evidenceObject} {...props} />,
      },
      title: object.name ? "Edit: " + object.name : "Associate Witness",
    });
  };

  if (
    dataLoading ||
    !caseObject ||
    !evidenceObject ||
    Object.keys(evidenceObject).length === 0
  ) {
    return (
      <>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: -48 }}
        >
          <PulseLoader color={props.theme.colors.blue} size={22} />
        </div>
        <div />
      </>
    );
  }

  return (
    <Container className="d-flex flex-column">
      <Column>
        <div className="form-group row">
          <div className="col-12">
            <InputNew
              name="name"
              type="text"
              placeholder="Enter tag name"
              label="Name"
              value={evidenceObject.label}
              onChangeHandler={(e) => {
                e.persist();
                setEvidenceObject((obj) => ({
                  ...obj,
                  label: e.target.value,
                }));
              }}
              // autoFocus
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Type</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={typeOptions}
                  value={
                    evidenceObject.type
                      ? {
                          label: evidenceObject.type,
                          value: evidenceObject.type,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setEvidenceObject((obj) => ({
                      ...obj,
                      type: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Status</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={statusOptions}
                  value={
                    evidenceObject.status
                      ? {
                          label: evidenceObject.status,
                          value: evidenceObject.status,
                        }
                      : undefined
                  }
                  onChange={(v) =>
                    setEvidenceObject((obj) => ({
                      ...obj,
                      status: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label className="w-100">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col">
                    <Bold14Font>Core Book</Bold14Font>
                  </div>
                </div>
                <SelectAnotherTry
                  placeholder={"Select"}
                  options={coreOptions}
                  value={
                    evidenceObject.core_book !== null
                      ? coreOptions.find(
                          (i) => i.value == evidenceObject.core_book
                        )
                      : undefined
                  }
                  onChange={(v) =>
                    setEvidenceObject((obj) => ({
                      ...obj,
                      core_book: v.value,
                    }))
                  }
                />
              </div>
            </label>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <CustomReactDatepicker
              // selected={date}
              // onChange={(date) => onChangeDateHandler(date)}

              selected={
                evidenceObject.date ? parseISO(evidenceObject.date) : ""
              }
              onChange={(date) => {
                setEvidenceObject((evidence) => ({
                  ...evidence,
                  date:
                    moment(date).format("YYYY-MM-DD") === "Invalid date"
                      ? null
                      : moment(date).format("YYYY-MM-DD"),
                }));
              }}
              dateFormat={"dd-MM-yyyy"}
              dateFormatCalendar={"dd-MM-yyyy"}
              label="Set Date"
              name="date"
              placeholderText="Select Date"
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <UploadSingleFileInput
              label="Upload File"
              file={file}
              setFile={(file) => {
                setFile(file);
                setEvidenceObject((obj) => ({
                  ...obj,
                  file_new: file,
                }));
              }}
              validTypes={[
                "image/jpeg",
                "image/jpg",
                "image/png",
                "application/pdf",
              ]}
              maxFileSize={maxFileSize}
            />
            {(file
              ? file.name === evidenceObject.file
              : evidenceObject.file_id) && (
              <div className="container-fluid" style={{ marginTop: 12 }}>
                <a
                  href={`/app/cases/${caseId}/documents/${evidenceObject.file_id}`}
                  target="_blank"
                >
                  <ButtonNew
                    clickHandler={() => {}}
                    primary
                    style={{ width: "100%" }}
                  >
                    View attachment
                  </ButtonNew>
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="form-group row">
          <div className={"col-12"}>
            {/* Associate Witness */}
            <AssociationTable
              settings={associateWitnessSettings}
              label="Associate Witness"
              compact
            />
          </div>
        </div>

        <div className="form-group row">
          <div className={"col-12"}>
            <AssociationTable
              settings={flagRelevanceSettings}
              label="Flag Relevance"
              compact
            />
          </div>
        </div>

        <MultiSelect
          label="Associated evidence"
          placeholder="Evidence"
          value={evidences}
          onChange={(value) => {
            setEvidences(value);
            setEvidenceObject((obj) => ({
              ...obj,
              evidences_new: value,
            }));
          }}
          options={caseLibrary.Evidence.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="evidence"
          disabled
        />

        <MultiSelect
          label="Objectives"
          placeholder="Objective"
          value={objectives}
          onChange={(value) => {
            setObjectives(value);
            setEvidenceObject((obj) => ({
              ...obj,
              objectives_new: value,
            }));
          }}
          options={caseLibrary.Objective.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="objective"
          disabled
        />

        <MultiSelect
          label="Notes"
          placeholder="Note"
          value={notes}
          onChange={(value) => {
            setNotes(value);
            setEvidenceObject((obj) => ({
              ...obj,
              notes_new: value,
            }));
          }}
          options={caseLibrary.Note.map((i) => ({
            value: i.id,
            label: i.label,
          }))}
          type="note"
          disabled
        />

        {/*
         */}
      </Column>
      <ButtonContainer>
        <ButtonNew
          clickHandler={() => {
            saveResult();
            //
          }}
          disabled={loading}
          loading={loading}
          type={"submit"}
          primary
        >
          Save
        </ButtonNew>
      </ButtonContainer>
    </Container>
  );
};

const MultiSelect = ({
  onChange,
  placeholder = "Placeholder",
  label = "Label",
  options = [
    { label: "label1", value: "value1" },
    { label: "label2", value: "value2" },
    { label: "label3", value: "value3" },
  ],
  type = "objective",
  value,
  labelInValue = true,
}) => {
  return (
    <div className="form-group row">
      <div className="col-12">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col">
              <Bold14Font>{label}</Bold14Font>
            </div>
          </div>
          <Select
            mode="tags"
            style={{
              width: "100%",
            }}
            placeholder={placeholder}
            onChange={onChange}
            options={options}
            labelInValue={labelInValue}
            value={value}
            showSearch
            filterOption={(input, option) => {
              return ("" + option.label)
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            // onSelect={(value) => {
            // }}
            tagRender={(props) => {
              return (
                <span
                  style={{ marginRight: 5, marginBottom: 2, marginTop: 2 }}
                  className={`tag tag-${type}`}
                >
                  {props.label}
                </span>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default withTheme(EditEvidence);
