import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { withTheme } from "styled-components/macro";

import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import {
  Bold14Font,
  Bold30Font,
} from "../../../components/FontsNewComponent/Fonts";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import PaymentHistory from "../../../components/baseAppComponents/Subscription/PaymentHistory";

import { route } from "../../../routes";
import {
  adminGetUserActiveSubscription,
  getAdminUserById,
  makeRequest,
} from "../../Auth/auth";
import { PulseLoader } from "react-spinners";
import UnpaidBadge from "../../../components/UnpaidBadge";
import {
  MODAL_ADMIN_GRANT_FREE_LICENSE,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../redux/types";
import { USER_LIST } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";

const UserPaymentHistory = ({ theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(null);

  const [activeSubscription, setActiveSubscription] = useState({});
  const [historySubscriptions, setHistorySubscriptions] = useState([]);
  const [isSubscriptionCanceled, setIsSubscriptionCancaled] = useState(false);
  const [updateSubscriptionsData, setUpdateSubscriptionsData] = useState(0);
  const [pageConfig, setPageConfig] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserAndUserSubscriptions = async () => {
      const userActiveSubscriptionResponse = await adminGetUserActiveSubscription(
        id,
        dispatch
      );
      const selectedUserResponse = await getAdminUserById(id, dispatch);
      if (userActiveSubscriptionResponse && selectedUserResponse) {
        setUser(selectedUserResponse);
        setActiveSubscription(userActiveSubscriptionResponse.data);
        setHistorySubscriptions(userActiveSubscriptionResponse.history);
        setIsSubscriptionCancaled(userActiveSubscriptionResponse.canceled);
        setPageConfig([
          {
            path: route.admin.baseApp.userList,
            title: "User List",
            activeMenuItem: USER_LIST,
          },
          {
            path: `${route.admin.baseApp.user}/${selectedUserResponse.id}/details`,
            title: selectedUserResponse.name,
            activeMenuItem: USER_LIST,
          },
          {
            path: `${route.admin.baseApp.user}/${selectedUserResponse.id}/details/payment-history`,
            title: "Payment History",
            activeMenuItem: USER_LIST,
          },
        ]);
        setIsLoading(false);
      }
    };
    getUserAndUserSubscriptions();
  }, [updateSubscriptionsData]);

  // useEffect(() => {
  // }, [user]);

  const onClickFreeLicenseHandler = () => {
    dispatch({
      type: SAVE_MODAL_DATA,
      beforeCloseHandler: () => {
        setIsLoading(true);
        setUpdateSubscriptionsData((prevState) => prevState + 1);
      },
      extraData: user,
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_ADMIN_GRANT_FREE_LICENSE });
  };

  // NB! Page config located in state
  // const pageConfig = [];

  if (isLoading) {
    return (
      <div className="row h-100">
        <div className="col d-flex justify-content-center align-items-center">
          <PulseLoader color={theme.colors.blue} size={45} />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col d-flex justify-content-between">
          <Bold30Font>Payment history</Bold30Font>
          <ButtonNew primary clickHandler={onClickFreeLicenseHandler}>
            <Bold14Font textColor={theme.colors.white}>Free License</Bold14Font>
          </ButtonNew>
        </div>
      </div>
      {isSubscriptionCanceled ? (
        <div className="row">
          <div className="col">
            <UnpaidBadge />
          </div>
        </div>
      ) : null}
      <PaymentHistory
        active={activeSubscription}
        history_={historySubscriptions}
        userObject={user}
      />
    </Fragment>
  );
};

export default withTheme(UserPaymentHistory);
