import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import {
  getEmailByToken,
  getSubscriptions,
  signUp,
  signUpWithGoogle,
} from "../../../containers/Auth/auth";
import Input from "../../Input";
import Button from "../../buttons/Button";
import SignUpFormStyled from "./styled/SignUpFormStyled";

import { Bold14Font, Bold30Font, Medium14Font } from "../../Fonts/Fonts";
import Select from "../../Select";
import { RESET_REQUESTED_URL, SIGN_IN, SIGN_UP } from "../../../redux/types";
import { route } from "../../../routes";
import modalErrorHandler, {
  handleErrorMessage,
} from "../../Modal/modalErrorHandler";
import {
  assistantRoleOption,
  createCountryOptionsList,
  roleOptions,
} from "../../../utils/constants";
import GoogleLogin from "react-google-login";
import Checkbox from "../../Checkbox/Checkbox";
import { theme } from "../../../styled-components/Theme/Theme";
import ButtonNew from "../../buttons/Button/ButtonNew";
import PhoneInput from "react-phone-number-input";
import "../../../assets/css/phoneInput.css";
import PulseLoader from "react-spinners/PulseLoader";
import { signUpFlow } from "../../../containers/Auth/SignUpPage/SignUpPage";
import InputNew from "../../InputNew/InputNew";
import SelectAnotherTry from "../../Select/SelectAnotherTry";

const SignUpForm = ({ flow, theme }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [minimalSubscriptionCost, setMinimalSubscriptionCost] = useState("");
  const [userData, setUserData] = useState({});
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectCountryList, setSelectCountryList] = useState(
    createCountryOptionsList()
  );
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [terms, setTerms] = useState(false);
  const [roleOptionsSignUp, setRoleOptionsSignUp] = useState(roleOptions);
  const userDataRedux = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const { inviteToken } = useParams();
  let form = useRef(null);

  useEffect(() => {
    // Set data from redux store to fields if this data exist
    if (
      Object.keys(userDataRedux).length !== 0 &&
      userDataRedux.constructor === Object
    ) {
      setUserData(userDataRedux);
    }
  }, []);

  const getDataInInviteFlow = async () => {
    setIsLoading(true);
    const resp = await getEmailByToken(inviteToken, dispatch);
    if (resp) {
      if (resp.got_account) {
        dispatch({ type: RESET_REQUESTED_URL });
        if (
          Object.keys(userDataRedux).length !== 0 &&
          userDataRedux.email === resp.email
        ) {
          history.replace(route.baseApp.cases);
        } else {
          history.replace(route.auth.signIn, { data: resp });
        }
      } else {
        setUserData({
          email: resp.email,
          name: resp.name,
          role: resp.role,
          country: resp.country,
        });
        let roles = [...roleOptions];
        if (resp.role === assistantRoleOption.value) {
          setRoleOptionsSignUp((prevState) => [
            ...roleOptions,
            assistantRoleOption,
          ]);
          roles.push(assistantRoleOption);
        }
        setSelectedRole(roles.find((el) => el.value === resp.role));
        setSelectedCountry(
          selectCountryList.find((el) => el.value === resp.country)
        );
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      try {
        let r = await getSubscriptions();
        setMinimalSubscriptionCost(r.monthly[0].price);
      } catch (e) {
        console.log(e);
      }
      if (flow === signUpFlow.INVITE_BY_TOKEN) {
        await getDataInInviteFlow();
      }
      setIsLoading(false);
    };
    init();
  }, []);

  const onInputChangeHandler = (event) => {
    event.persist();
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onRoleChangeHandler = (selectedOption) => {
    setUserData((prevState) => ({ ...prevState, role: selectedOption.value }));
    setSelectedRole(selectedOption);
  };

  const onCountryChangeHandler = (selectedOption) => {
    setUserData((prevState) => ({
      ...prevState,
      country: selectedOption.value,
    }));
    setSelectedCountry(selectedOption);
  };

  const onFormSubmitHandler = async (event) => {
    event.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    if (!terms) {
      handleErrorMessage(
        "You must agree to the TrialProofer Terms of Use and our Privacy Policy",
        dispatch
      );
      return;
    }
    if (!isLoading) {
      setIsLoading(true);
      const response = await signUp(userData, dispatch);
      if (response.status === 200) {
        dispatch({ type: SIGN_UP, payload: response.data });
        history.push(route.auth.confirmEmail);
      } else {
        modalErrorHandler(response, dispatch);
      }
      setIsLoading(false);
    }
  };
  const responseGoogle = async (resp) => {
    if (resp.accessToken) {
      setGoogleLoading(true);
      let data;
      if (flow === signUpFlow.INVITE_BY_TOKEN) {
        data = { role: userData.role, country: userData.country };
      } else {
        data = null;
      }
      let response = await signUpWithGoogle(resp.accessToken, dispatch, data);
      if (response.status === 200) {
        dispatch({ type: SIGN_IN, payload: response.data });
        history.push(route.auth.signUpWithGoogle);
      } else {
        modalErrorHandler(response, dispatch);
      }
      setGoogleLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="row p-4">
        <div className="col d-flex flex-column align-items-center">
          <Bold30Font as="p">Sign Up</Bold30Font>
          <Medium14Font
            as="p"
            textColor={theme.colors.darkOpacity}
            className="text-center"
          >
            Try it free, plans from €{minimalSubscriptionCost} per month
          </Medium14Font>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column align-items-center">
          <SignUpFormStyled
            onSubmit={onFormSubmitHandler}
            ref={(ref) => (form = ref)}
          >
            <div className="form-group">
              <Input
                name="name"
                placeholder="Enter your first & last name"
                label="First & Last Name"
                onChangeHandler={onInputChangeHandler}
                value={userData.name ? userData.name : ""}
                required
              />
            </div>
            <div className="form-group">
              <InputNew
                name="email"
                type="email"
                placeholder="Enter your email"
                label="Email"
                onChangeHandler={onInputChangeHandler}
                value={userData.email ? userData.email : ""}
                required
                disabled={flow === signUpFlow.INVITE_BY_TOKEN}
              />
            </div>
            {/*<div className="form-group">*/}
            {/*  <div className="container-fluid">*/}
            {/*    <Bold14Font className="d-block mb-2">Phone</Bold14Font>*/}
            {/*    <PhoneInput*/}
            {/*      required*/}
            {/*      international*/}
            {/*      placeholder="Enter your phone"*/}
            {/*      value={userData.phone ?? ""}*/}
            {/*      onChange={(phone) => {*/}
            {/*        setUserData((prevState) => ({*/}
            {/*          ...prevState,*/}
            {/*          phone: phone,*/}
            {/*        }));*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="form-group">
              <div className="container-fluid">
                <Bold14Font className="d-block mb-2">Role</Bold14Font>
                <SelectAnotherTry
                  // isSearchable={false}
                  name="role"
                  // classNamePrefix="Select"
                  options={roleOptionsSignUp}
                  // styles={{
                  //   input: () => ({
                  //     padding: 0,
                  //     margin: 0,
                  //   }),
                  // }}
                  // style={{ marginLeft: "-15px", marginRight: "-15px" }}
                  style={{ padding: "0" }}
                  onChange={onRoleChangeHandler}
                  value={selectedRole}
                  isDisabled={
                    flow === signUpFlow.INVITE_BY_TOKEN &&
                    selectedRole &&
                    selectedRole.value === assistantRoleOption.value
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <Input
                name="password"
                placeholder="Enter your password"
                label="Password"
                type="password"
                onChangeHandler={onInputChangeHandler}
                minLength={8}
                required
              />
            </div>
            <div className="form-group">
              <Input
                name="password_confirmation"
                placeholder="Confirm your password"
                label="Confirm Password"
                type="password"
                onChangeHandler={onInputChangeHandler}
                minLength={8}
                required
              />
            </div>
            <div className="form-group mb-4">
              <div className="container-fluid">
                <Bold14Font className="d-block mb-2">Country</Bold14Font>
                <SelectAnotherTry
                  options={selectCountryList}
                  onChange={onCountryChangeHandler}
                  // TODO:Implement load country from store
                  value={selectedCountry}
                  style={{ padding: "0" }}
                />
              </div>
            </div>
            <div className="container-fluid" style={{ marginLeft: "15px" }}>
              <div
                onClick={() => {
                  setTerms(!terms);
                }}
                className="row align-items-baseline"
                style={{ marginBottom: "1rem", marginTop: "30px" }}
              >
                <Checkbox checked={terms} onChange={setTerms} />
                <Medium14Font
                  style={{
                    color: theme.colors.darkOpacity,
                    marginRight: "10px",
                  }}
                >
                  By checking the checkbox below, you agree to our
                </Medium14Font>
                <Link to={route.termsAndConditions}>
                  <Bold14Font style={styles.change}>Terms of Use</Bold14Font>
                </Link>
                {", "}
                <Link to={route.privacyPolicy} style={{ marginLeft: "3px" }}>
                  <Bold14Font style={styles.change}>Privacy Policy</Bold14Font>
                </Link>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Button
                    primary
                    wide
                    type="submit"
                    clickHandler={() => null}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Sign Up
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex flex-column align-items-center">
                  <Medium14Font
                    textColor={theme.colors.darkOpacity}
                    className="my-2"
                  >
                    Or
                  </Medium14Font>
                </div>
              </div>
            </div>
          </SignUpFormStyled>
          <div
            className="d-flex flex-column align-items-center"
            style={{ paddingLeft: "15px", paddingRight: "15px", width: "100%" }}
          >
            <GoogleLogin
              clientId={process.env.MIX_GOOGLE_CLIENT_ID}
              buttonText="Login"
              onSuccess={responseGoogle}
              disabled={googleLoading}
              googleLoading={googleLoading}
              type="reset"
              render={({ disabled, onClick }) => (
                <ButtonNew
                  secondary
                  wide
                  loading={disabled}
                  clickHandler={() => {
                    setGoogleLoading(true);
                    onClick();
                  }}
                  disabled={disabled}
                >
                  <Bold14Font textColor={theme.colors.blue}>
                    Sign Up with Google
                  </Bold14Font>
                </ButtonNew>
              )}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
            <div className="d-flex align-items-center mt-3">
              <Medium14Font>Already have an account ?</Medium14Font>
              {/* TODO: convert to tertiary button */}
              <Button
                tertiary
                style={{ marginBottom: "2px" }}
                clickHandler={() => history.push(route.auth.signIn)}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  div: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: 0,
  },
  change: {
    color: theme.colors.blue,
    cursor: "pointer",
  },
};

SignUpForm.propTypes = {
  flow: PropTypes.string,
};

export default withTheme(SignUpForm);
