import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const FormHeader = styled.div`
  height: 70px;
  padding-left: 30px;
  ${({ withOutMargin }) =>
  withOutMargin
    ? null
    : 'margin-bottom: 30px'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  ${({ withCloseButton }) =>
    withCloseButton
      ? "justify-content: space-between; padding-right: 30px;"
      : null};

  @media (max-width: 575.98px) {
    margin-bottom: 0.875rem;
  }
`;

export default FormHeader;
