import {Bold14Font} from "../../FontsNewComponent/Fonts";
import React from "react";
import PropTypes from "prop-types";

export default function Label(props) {
  return <label className="w-100">
    <div className="container-fluid" {...props.containerProps}>
      <div className="row mb-2" {...props} >
        {props.button ? <div className="col d-flex justify-content-between align-items-center">
          <Bold14Font>{props.name}</Bold14Font>
          {props.button}
        </div> : <div className="col">
          <Bold14Font>{props.name}</Bold14Font>
        </div>}
      </div>
    </div>
  </label>
}
Label.propTypes = {
  name: PropTypes.any.isRequired,
};
