import React, { useState, useContext, useEffect } from "react";
import { NotebookContext, EditorContext } from "@/context";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Heading,
  Title,
  FilterButton,
  ActiveFilterButton,
  // SearchBar,
  Tags,
  CloseIcon,
} from "./styled";
import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

import Input from "@/components/InputNew/styled/InputStyled";
import { SHOW_RIGHT_SIDE_BAR } from "@/redux/types";
import Section from "./Section";
import Filter from "./Filter";

import closeIcon from "@/assets/img/notebook/close-icon.svg";

import { updateCase } from "@/containers/Auth/auth";

import CauseOfActionForm from "@/components/forms/CasesForms/CauseOfActionForm";
import PartiesForm from "@/components/forms/CasesForms/Parites";
import ThemesForm from "@/components/forms/CasesForms/ThemesForm";
import IssuesForm from "@/components/forms/CasesForms/IssuesForm";
import ReliefsAndProofs from "@/components/forms/ApplicationsHubForms/ReliefsAndProofs";
import { getCaseById } from "@/containers/Auth/auth";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const CaseLibrary = () => {
  // const search = "";
  // const setSearch = () => {};
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const {
    selectedChapter,
    fetchCaseLibrary,
    caseLibrary,
    setCaseLibrary,
    caseObject,
    setCaseObject,

    fetchNotebook,
    notebookLoading,
    setNotebookLoading,
    notebook,
    setNotebook,
    caseLoading,
    setCaseLoading,
  } = useContext(NotebookContext);
  const { editor } = useContext(EditorContext);
  const [causesOfAction, setCausesOfAction] = useState([]);
  const { id: caseId, notebook_id: notebookId } = useParams();

  useEffect(() => {
    if (caseObject) {
      setCausesOfAction(caseObject.causes_of_action ?? []);
    }
  }, [caseObject]);

  const [prevSectionsOpened, setPrevSectionsOpened] = useState({
    relevance: false,
    evidence: false,
    "application-papers": false,
    "key-sections": false,
    witness: false,
    objectives: false,
    topics: false,
    "exam-lines": false,
    party: false,
    authority: false,
    "Note/comment": false,
  });
  const [sectionsOpened, setSectionsOpened] = useState({
    relevance: false,
    evidence: false,
    "application-papers": false,
    "key-sections": false,
    witness: false,
    objectives: false,
    topics: false,
    "exam-lines": false,
    party: false,
    authority: false,
    "Note/comment": false,
  });

  useEffect(() => {
    fetchCaseLibrary(caseId, notebookId, filterQuery);
  }, [filterQuery]);

  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const getDataFromAPI = async () => {
    // setCaseLoading(true);
    const caseResp = await getCaseById(caseId, dispatch);
    // if (is_admin && !caseResp) {
    //   history.push("/admin/all-cases");
    // }
    if (caseResp) {
      console.log(caseResp);
      setCaseObject(caseResp);
      // setCaseLoading(false);
    }
  };

  const changeObj = async (resp, type, obj, callback) => {
    if (resp) {
      let caseObject_clone = JSON.parse(JSON.stringify(caseObject));
      if (type === "add") {
        caseObject_clone[obj].push(resp);
      } else if (type === "update") {
        caseObject_clone[obj].forEach((v, index) => {
          if (v.id === resp.id) {
            caseObject_clone[obj][index] = resp;
          }
        });
      } else if (type === "delete") {
        caseObject_clone[obj] = caseObject[obj].filter((v) => {
          return v.id !== resp.id;
        });
      }

      let er = false;
      const res = await updateCase(
        caseObject.id,
        dispatch,
        caseObject_clone,
        true
      ).catch(async (err) => {
        er = true;
        // alert("err");
        // fetch case
        await getDataFromAPI();
        await sleep(3000);
        await fetchCaseLibrary(caseId, notebookId, filterQuery);
        // if (callback) {
        //   callback();
        // }
        return;
      });
      if (!er) {
        console.log(res);
        console.log(resp);
        setCaseObject(res);

        await sleep(3000);

        await fetchCaseLibrary(caseId, notebookId, filterQuery);
      }
    }
  };

  const updateApplication = async (obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.put(
      `/api/cases/${caseId}/applications/${notebook.application.fake_id}`,
      {
        reliefs: obj.reliefs,
        proofs: obj.proofs,
      },
      config
    );
  };

  const handleRelief = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofs
          // key={object.id}
          object={notebook.application}
          setObject={async (obj, callback) => {
            await updateApplication(obj);
            await sleep(2000);
            await fetchCaseLibrary(caseId, notebookId, filterQuery);
            callback();
          }}
          routeParams={{ caseId, applicationId: notebook.application }}
          alreadySavedReliefs={[]}
          alreadySavedProofs={[]}
          loadingAfterCallback
        />
      ),
      title: "Add Relief",
    });
  };

  // const onClickAddReliefsHandler = (object) => {
  //   dispatch({
  //     type: SHOW_RIGHT_SIDE_BAR,
  //     url: history.location.pathname,
  //     content: (
  //       <ReliefsAndProofs
  //         key={object.id}
  //         object={notebook.application}
  //         setObject={async (obj, callback) => {
  //           await updateApplication(obj);
  //           await sleep(2000);
  //           await fetchCaseLibrary(caseId, notebookId, filterQuery);
  //           callback();
  //         }}
  //         routeParams={{ caseId, applicationId: notebook.application.id }}
  //         alreadySavedReliefs={notebook.application.reliefs}
  //         alreadySavedProofs={notebook.application.proofs}
  //       />
  //     ),
  //     title: "Add Relief",
  //   });
  // };

  const handleCauseOfAction = (object, counterclaim) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <CauseOfActionForm
          sendRequest
          caseObject={caseObject}
          key={object.id}
          id_case={caseObject.id}
          counterclaim={counterclaim}
          object={object}
          isCriminal={caseObject.new_case_type === "Criminal"}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "causes_of_action", callback);
            callback();
          }}
          alreadySavedCOAList={causesOfAction}
          loadingAfterCallback
          checkErr
        />
      ),
      title:
        object.type && object.type.name
          ? "Edit: " + object.type.name
          : counterclaim
          ? "Add Counterclaim"
          : caseObject.new_case_type === "Criminal"
          ? "Add Offence"
          : "Add Cause of action",
    });
  };

  const handleTheme = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ThemesForm
          sendRequest
          key={object.id}
          id_case={caseObject.id}
          object={object}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "themes");
            callback();
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Theme",
    });
  };

  const handleParties = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <PartiesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          sendRequest
          caseObject={caseObject}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "parties");
            callback();
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Party",
    });
  };

  const handleIssues = (object) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <IssuesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          sendRequest
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "issues");
            callback();
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add Issue",
    });
  };

  useEffect(() => {
    if (caseId && notebookId) {
      fetchCaseLibrary(caseId, notebookId, filterQuery);
    }
  }, [caseId, notebookId]);

  if (!selectedChapter) return null;

  if (!caseLibrary) return null;

  // if (!notebookObj) return null;

  if (notebookLoading || !notebook) return null;

  // console.log(caseLibrary.Authority);

  // console.log("auth labels");
  // caseLibrary.Authority.forEach((i) => {
  //   console.log(i.label);
  //   console.log(i);
  // });

  return (
    <Container>
      <Filter
        show={showFilter}
        setFilterQuery={setFilterQuery}
        setSectionsOpened={setSectionsOpened}
        setShowFilter={setShowFilter}
      />
      <Heading>
        <div
          className="d-flex flex-justify-between flex-align-center"
          style={{
            marginBottom: 12,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title>Case Library</Title>
          {filterQuery === "" ? (
            <FilterButton onClick={() => setShowFilter(!showFilter)} />
          ) : (
            <ActiveFilterButton onClick={() => setShowFilter(!showFilter)} />
          )}
        </div>
        <div style={{ position: "relative" }}>
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              if (search === "" && e.target.value !== "") {
                setPrevSectionsOpened(sectionsOpened);
                setSectionsOpened({
                  relevance: true,
                  evidence: true,
                  "application-papers": true,
                  "key-sections": true,
                  witness: true,
                  objectives: true,
                  topics: true,
                  "exam-lines": true,
                  party: true,
                  authority: true,
                  "Note/comment": true,
                });
              }
              if (e.target.value === "") {
                setSectionsOpened(prevSectionsOpened);
              } else {
              }
              setSearch(e.target.value);
            }}
          />
          {search !== "" && (
            <CloseIcon
              src={closeIcon}
              alt="Close"
              onClick={() => {
                setSectionsOpened(prevSectionsOpened);
                setSearch("");
              }}
            />
          )}
        </div>
      </Heading>
      <Tags>
        <Section
          search={search}
          label="Relevance"
          type="relevance"
          opened={sectionsOpened["relevance"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          handleCauseOfAction={handleCauseOfAction}
          handleTheme={handleTheme}
          handleIssues={handleIssues}
          handleRelief={handleRelief}
          elements={[
            ...(notebook.type !== "Application"
              ? caseLibrary.CausesOfAction || []
              : []),
            ...(notebook.type !== "Application"
              ? caseLibrary.CausesOfActionElement || []
              : []),
            ...(notebook.type !== "Application"
              ? caseLibrary.CausesOfActionDefenseElement || []
              : []),
            ...(notebook.type === "Application"
              ? caseLibrary.Relief || []
              : []),
            ...(notebook.type === "Application" ? caseLibrary.Proof || [] : []),
          ].filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          themes={[
            ...(caseLibrary.Theme || []),
            ...(caseLibrary.SubTheme || []),
          ].filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          issues={[
            ...(caseLibrary.Issue || []),
            ...(caseLibrary.KeyFact || []),
          ].filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Evidence"
          type="evidence"
          opened={sectionsOpened["evidence"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="Evidence"
          items={(caseLibrary.Evidence || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        {notebook.type === "Application" && (
          <Section
            search={search}
            label="Application Papers"
            type="application-papers"
            opened={sectionsOpened["application-papers"]}
            notebookType={notebook.type}
            applicationId={notebook.application.id}
            setSectionsOpened={setSectionsOpened}
            endpointLabel="Paper"
            items={(caseLibrary.Paper || []).filter(
              (i) =>
                ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
            )}
            setCaseLibrary={setCaseLibrary}
          />
        )}
        <Section
          search={search}
          label="Key Sections"
          type="key-sections"
          opened={sectionsOpened["key-sections"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="KeySection"
          items={(caseLibrary.KeySection || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Witness"
          type="witness"
          opened={sectionsOpened["witness"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="Witness"
          items={(caseLibrary.Witness || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Objectives"
          type="objectives"
          opened={sectionsOpened["objectives"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="Objective"
          items={(caseLibrary.Objective || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Topics"
          type="topics"
          opened={sectionsOpened["topics"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="Topic"
          items={(caseLibrary.Topic || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Exam Lines"
          type="exam-lines"
          opened={sectionsOpened["exam-lines"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="ExamLine"
          items={(caseLibrary.ExamLine || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        <Section
          search={search}
          label="Party"
          type="party"
          opened={sectionsOpened["party"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          handleParties={handleParties}
          endpointLabel="Party"
          items={(caseLibrary.Party || []).filter(
            (i) =>
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        {notebook.type !== "Application" && (
          <Section
            search={search}
            label="Authority"
            type="authority"
            opened={sectionsOpened["authority"]}
            notebookType={notebook.type}
            setSectionsOpened={setSectionsOpened}
            endpointLabel="Authority"
            items={(caseLibrary.Authority || []).filter(
              (i) =>
                i.label &&
                ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
            )}
            setCaseLibrary={setCaseLibrary}
          />
        )}
        <Section
          search={search}
          label="Note/Comment"
          type="Note/comment"
          opened={sectionsOpened["Note/comment"]}
          notebookType={notebook.type}
          setSectionsOpened={setSectionsOpened}
          endpointLabel="Note"
          items={(caseLibrary.Note || []).filter(
            (i) =>
              i.label &&
              ("" + i.label).toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          setCaseLibrary={setCaseLibrary}
        />
        {/*
         */}
      </Tags>
    </Container>
  );
};

// 1. common context
// 2. editor command to insert tag
// onClick:
// tr.editor.commands.insert(tag, 'Tag')
// tr.editor.commands.insertNode(Tag, tagProps)

// const ExpandIcon = () => <div>Expand</div>;

export default CaseLibrary;
