/**
 * Generate random number in range
 * @param min - default 1
 * @param max - default 100
 * @returns {number}
 */
export const randomNumberRange = (min = 1, max = 100) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const regUserCardFirstAndLastName = /^([a-zA-Z]{2,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/;

export const maxFileSize = 35 * 1024 * 1024;
