import React, {useEffect, useRef, useState} from "react";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import InputNew from "../../../components/InputNew/InputNew";
import {resendVerifySms, setUser2FA} from "../../Auth/auth";
import {useDispatch, useSelector} from "react-redux";
import AnimatedDiv from "../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Form from "../../../components/forms/Form";
import {MODAL_SUCCESS, SAVE_MODAL_DATA, SHOW_MODAL, UPDATE_USER,} from "../../../redux/types";
import {Bold14Font, Bold18Font, Medium14Font} from "../../../components/FontsNewComponent/Fonts";
import RadioButtonGroup from "../../../components/buttons/RadioButtonGroup";
import NoRecords from "../../../components/NoRecords";
import {TwoFAForm} from "../../../components/forms/2FAForm/TwoFAForm";

const twoFAButtonsObjects = [
  {
    title: <Medium14Font>Yes</Medium14Font>,
    type: true,
  },
  {
    title: <Medium14Font>No</Medium14Font>,
    type: false,
  },
];
let count_ = 60;
const SecurityForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [digits, setDigits] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [twoFA, setTwoFA] = useState(user['2fa']);
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState("");
  const [timer, setTimer] = useState(null);
  const [count, setCount] = useState(count_);
  let form = useRef(null);
  let refs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const makeRequest = async (code) => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      let data = {
        password,
        '2fa': twoFA,
      };
      if (code) {
        data.code = code;
      }
      const resp = await setUser2FA(
        data,
        dispatch
      );
      if (resp.result === "success") {
        if (!code) {
          setStep(2);
        } else {
          setStep(1);
          setPassword('');
          dispatch({type: UPDATE_USER, payload: resp.data});
          dispatch({type: SAVE_MODAL_DATA, payload: "Security updated!"});
          dispatch({type: SHOW_MODAL, payload: MODAL_SUCCESS});
        }
      }
      setIsSubmitting(false);
    }
  };
  const submitForm = async (e) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    await makeRequest();
  };
  const onInputFocusHandler = (event) => {
    event.persist();
    let k = Object.keys(digits).indexOf(event.target.name);
    if (k !== 0 && refs[k - 1].value === "") {
      refs[0].focus();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitForm(event);
    }
  };
  const resetTimer = () => {
    setCount(count_);
    const int_ = setInterval(() => {
      setCount(prevState => {
        if (prevState === 0) {
          clearInterval(int_);
          setTimer(null);
        } else {
          let st = prevState-1;
          return st;
        }
      });
    }, 1000);
    setTimer(int_);
  };
  useEffect(() => {
    if (step === 2 && !timer) {
      resetTimer();
    }
  }, [step]);
  return (
    <form onSubmit={submitForm} ref={(ref) => (form = ref)}>
      <Form title={"Security"} style={{overflow: "hidden"}}>
        {!user.phone ? <NoRecords><Bold18Font textColor={"#0f122f"}>
          Enter phone on the profile tab to enable this section
        </Bold18Font></NoRecords> : step === 1 ? <AnimatedDiv className="row pl-4 pr-4 pb-4 pt-0">
          <div className="form-group">
            <RadioButtonGroup
              label="2FA"
              itemsObjects={twoFAButtonsObjects}
              checkedButton={twoFA}
              setCheckedButton={setTwoFA}
            />
          </div>
          <div className="form-group">
            <InputNew
              onKeyPress={handleKeyPress}
              required
              minLength={8}
              label={"Password"}
              type="password"
              onChangeHandler={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
              placeholder={"Enter your new password"}
              name={"password"}
            />
          </div>
          <div className="col-sm-12 d-flex justify-content-end mt-4">
            <ButtonNew
              primary
              className="ml-2 mr-3"
              loading={isSubmitting}
              disabled={isSubmitting}
              clickHandler={() => null}
            >
              Submit
            </ButtonNew>
          </div>
        </AnimatedDiv> : <AnimatedDiv className="row pl-4 pr-4 pb-4 pt-0">
          <TwoFAForm resendFunction={resendVerifySms} resendData={null} count_={count_} cancelFunction={(e) => {
            if (e) {
              e.preventDefault();
            }
            setStep(1);
            setPassword('');
          }} isSubmitting={isSubmitting} makeRequest={makeRequest}/>
          {step === 1 ? <div className="col-sm-12 d-flex justify-content-end mt-4">
            <ButtonNew
              primary
              className="ml-2 mr-3"
              loading={isSubmitting}
              disabled={isSubmitting}
              clickHandler={() => null}
            >
              Submit
            </ButtonNew>
          </div> : null}
        </AnimatedDiv>}
      </Form>
    </form>
  );
};

export default SecurityForm;
