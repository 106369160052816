import React, { Fragment, useEffect, useState } from "react";
import CaseCardStyled from "./styled/CaseCardStyled";
import {
  Bold14Font,
  Bold18Font,
  Medium14Font,
} from "../../FontsNewComponent/Fonts";
import { useHistory, useParams } from "react-router-dom";
import { HIDE_RIGHT_SIDE_BAR, SHOW_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch, useSelector } from "react-redux";
import RSBCaseCardPreview from "../../rightSideBarViews/RSBCaseCardPreview";
import { requireAccessToCase } from "../../../containers/Auth/auth";
import CaseCardLabelOverflowStyled from "./styled/CaseCardLabelOverflowStyled";
import { TagElementBlue } from "../../TagElementStyled/TagElementStyled";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";

const TYPE_WITNESS = "Witness";
const TYPE_X_EXAM = "E-Exam";

const WitnessCard = ({
  archived,
  deleteCaseFromView,
  archiveCaseInView,
  sendRequestCaseInView,
  caseObject,
  // isXExamWitnessListPage,
  ...props
}) => {
  const { witnessObject_ } = props;
  const [sendingRequest, setSendingRequest] = useState(false);
  const [witnessObject, setWitnessObject] = useState(witnessObject_);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  // On card click open RSB with info:
  const viewCard = () => {
    if (witnessObject.witness_type === TYPE_WITNESS) {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/witness/${witnessObject.id}`
          : `/admin/all-cases/${id}/trial-hub/witness/${witnessObject.id}`
      );
    } else {
      history.push(
        !is_admin
          ? `/app/cases/${id}/trial-hub/x-exam/${witnessObject.id}`
          : `/admin/all-cases/${id}/trial-hub/x-exam/${witnessObject.id}`
      );
    }
  };

  // if X-Exam flow - add unique COA elements from topics to witness card
  useEffect(() => {
    if (
      // isXExamWitnessListPage &&
      // isXExamWitnessListPage.isExact &&
      witnessObject &&
      witnessObject.witness_type === TYPE_X_EXAM &&
      witnessObject.topics &&
      witnessObject.topics.length
    ) {
      // If X-Exam flow - set unique tags from all topics linked to this witness
      const uniqueParentElements = [];

      const mapObject = {
        causes_of_action: [],
        causes_of_action_defensive: [],
        issues_parent: [],
        issues: [],
        themes_parent: [],
        themes: [],
      };

      witnessObject.topics.forEach((object) => {
        object.causes_of_action_parent.forEach((el) => {
          if (
            uniqueParentElements.findIndex((item) => item.id === el.type.id) ===
            -1
          ) {
            uniqueParentElements.push(el.type);
          }
        });

        Object.keys(mapObject).forEach((key) => {
          object[key].forEach((el) => {
            if (mapObject[key].findIndex((item) => item.id === el.id) === -1) {
              mapObject[key].push(el);
            }
          });
        });
      });

      setWitnessObject((prevState) => ({
        ...prevState,
        causes_of_action_parent: [...uniqueParentElements],
        ...mapObject,
      }));
    }
  }, []);

  const showRSBWithCardInfo = () => {
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <RSBCaseCardPreview
          caseId={witnessObject.id}
          deleteCaseFromView={deleteCaseFromView}
          archiveCaseInView={archiveCaseInView}
        />
      ),
      title: "Case Preview",
    });
  };
  const sendRequest = () => {
    if (!witnessObject.request_access) {
      if (!sendingRequest) {
        setSendingRequest(true);
        requireAccessToCase(witnessObject.id, dispatch)
          .then((resp) => {
            sendRequestCaseInView(resp.id);
            setSendingRequest(false);
          })
          .catch((error) => setSendingRequest(false));
      }
    } else if (witnessObject.request_access === "got_access") {
      showRSBWithCardInfo();
    }
  };
  return (
    <CaseCardStyled
      admin={is_admin}
      onClick={viewCard}
      className="m-1"
      noArchivedTopBorder
      withPerson
    >
      {/* TODO: Does I need here container-fluid? */}
      <div className="d-flex flex-column justify-content-between h-100">
        <div className="mb-4">
          <div className="row mb-2">
            <div className="col d-flex justify-content-between flex-wrap">
              {/* Witness class */}
              {witnessObject.class ? (
                <TagElementBlue>
                  <Bold14Font style={{ color: "inherit" }}>
                    {witnessObject.class}
                  </Bold14Font>
                </TagElementBlue>
              ) : (
                <Medium14Font as="div">N/A</Medium14Font>
              )}
            </div>
          </div>
          {/* Witness Name */}
          <Bold18Font
            className="mb-2 text-left"
            as={CaseCardLabelOverflowStyled}
          >
            {witnessObject.name ?? "New Witness"}
          </Bold18Font>
        </div>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col">
            <Medium14Font as="div" style={{ marginBottom: "8px" }}>
              Elements
            </Medium14Font>
            <div className="d-flex flex-wrap">
              {witnessObject.causes_of_action_parent.length ||
              witnessObject.causes_of_action.length ||
              witnessObject.causes_of_action_defensive.length ? (
                <ShowCoa object={witnessObject} case_object={caseObject} />
              ) : (
                <Medium14Font as="div">None assigned</Medium14Font>
              )}
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col">
            <Medium14Font as="div" style={{ marginBottom: "8px" }}>
              Themes
            </Medium14Font>
            <div className="d-flex flex-wrap">
              {witnessObject.themes_parent.length ||
              witnessObject.themes.length ? (
                <ShowThemes object={witnessObject} case_object={caseObject}/>
              ) : (
                <Medium14Font as="div">None assigned</Medium14Font>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Medium14Font as="div" style={{ marginBottom: "8px" }}>
              Issues
            </Medium14Font>
            <div className="d-flex flex-wrap">
              {witnessObject.issues_parent.length ||
              witnessObject.issues.length ? (
                <ShowIssues object={witnessObject} case_object={caseObject} />
              ) : (
                <Medium14Font as="div">None assigned</Medium14Font>
              )}
            </div>
          </div>
        </div>
      </div>
    </CaseCardStyled>
  );
};

export default WitnessCard;
