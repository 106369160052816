import {
  DATA_ADD_TO_STORE,
  DATA_REMOVE_FROM_STORE,
  DATA_RESET_STORE,
} from "../types";

const initialState = {
  store: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_ADD_TO_STORE:
      return {
        ...state,
        store: {
          ...state.store,
          [action.keyName]: action.data,
        },
      };

    case DATA_REMOVE_FROM_STORE:
      let stateCopy = { ...state };
      delete stateCopy.store[action.keyName];
      if (Object.keys(stateCopy.store).length === 0) {
        stateCopy.store = null;
      }
      return stateCopy;

    case DATA_RESET_STORE:
      return initialState;

    default:
      return state;
  }
};

export default dataReducer;
