import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const CustomDatePickerDivInputStyled = styled.div`
  width: 100%;
  height: 42px;
  //outline: none;
  border-radius: 50vh;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: ${rem("10px 20px")};
  background: ${({ theme }) => theme.colors.white};
  font-family: "PT Root UI", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.blue};
  }

  :disabled {
    border: 1px solid ${({ theme }) => theme.colors.gray};
    background: ${({ theme }) => theme.colors.gray};
  }

  @media (max-width: 575.98px) {
    //height: 35px;
    border-radius: 50vh;
    font-size: 1rem;
  }
`;

export default CustomDatePickerDivInputStyled;
