import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  color: #374ac2;
  font-weight: 500;
`;

export const List = styled.div`
  width: 100px;
  background: white;
  border: 1px solid #dcdce6;
  border-radius: 4px;
`;

export const Option = styled.div`
  padding: 16px;
  cursor: pointer;
  min-width: 100px;
  :hover {
    background: rgba(55, 74, 194, 0.08);
  }
`;

export const SecondaryList = styled.div`
  position: absolute;
  width: 100px;
  min-width: 100px;
  right: -100px;

  background: white;
  border: 1px solid #dcdce6;
  border-radius: 4px;
`;
