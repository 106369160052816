import React from "react";
import PropTypes from "prop-types";
import TextAreaStyled from "./styled/TextAreaCasesStyled";

import {Bold14Font, Medium14Font} from "../FontsNewComponent/Fonts";
import {theme} from "../../styled-components/Theme/Theme";

const TextAreaCases = ({ name, value, placeholder, customStyle, onChange, onBlur }) => {

  return (
    <label className="w-100">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Medium14Font style={{color: theme.colors.darkOpacity, position: 'relative', left: 'calc(100% - 75px)', top: '23%'}}>Optional</Medium14Font>
            <TextAreaStyled
              name={name}
              placeholder={placeholder}
              as={customStyle}
              value={value}
              onChange={onChange}
              maxLength={300}
              onBlur={onBlur}
            />
            <Medium14Font style={{color: theme.colors.darkOpacity, position: 'relative', left: 'calc(100% - 70px)', bottom: '23%'}}>{value ? value.length : 0}/300</Medium14Font>
          </div>
        </div>
      </div>
    </label>
  );
};

TextAreaCases.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  customStyle: PropTypes.object,
};

export default TextAreaCases;
