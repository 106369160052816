import React from "react";
import AdminMenuStyled from "./styled/AdminMenuStyled";
import {
  Bold18Font,
  Medium18Font,
} from "../../FontsNewComponent/Fonts";
import Badge from "../../Badge";
import { theme } from "../../../styled-components/Theme/Theme";
import BadgeAdminMenuStyled from "./styled/BadgeAdminMenuStyled";

const AdminMenu = (props) => {
  return (
    <AdminMenuStyled>
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center mb-3">
          <div className="col d-flex flex-wrap justify-content-between">
            {/*<div className="d-flex mb-3">*/}
            <div className="d-flex flex-wrap justify-content-end justify-content-sm-center mb-3">
              {props.casesMenu.map((el, i) => (
                <div
                  onClick={() => {
                    props.getType(el.activeType);
                  }}
                  className={i > 0 ? "d-flex ml-4 mb-1" : "d-flex mb-1"}
                  key={i}
                >
                  <div
                    className={`menu-item mr-2 ${
                      el.activeType === props.active ? "menu-item__active" : ""
                    }`}
                  >
                    {el.activeType === props.active ? (
                      <Bold18Font
                        textColor={theme.colors.blue}
                      >
                        {el.text}
                      </Bold18Font>
                    ) : (
                      <Medium18Font textColor={theme.colors.darkOpacity}>
                        {el.text}
                      </Medium18Font>
                    )}
                  </div>
                  <Badge as={BadgeAdminMenuStyled}>
                    <Bold18Font textColor="#848695">{el.amount}</Bold18Font>
                  </Badge>
                </div>
              ))}
            </div>
            <div className="">
            </div>
          </div>
        </div>
      </div>
    </AdminMenuStyled>
  );
};

export default AdminMenu;
