import React, { Fragment, useEffect, useState } from "react";
import { addCase, getDashboardData } from "../../Auth/auth";
import PulseLoader from "react-spinners/PulseLoader";
import { DASHBOARD } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import { route } from "../../../routes";
import { useDispatch, useSelector } from "react-redux";
import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { theme } from "../../../styled-components/Theme/Theme";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Bold30Font,
  Medium14Font,
} from "../../../components/FontsNewComponent/Fonts";
import RsbCaseCardPreviewDotStyled from "../../../components/rightSideBarViews/styled/RSBCaseCardPreviewDotStyled";
import {
  TagElementCyan,
  TagElementPurple,
} from "../../../components/TagElementStyled/TagElementStyled";
import personIcon from "../../../assets/img3/svg/person.svg";
import CornerButton from "../../../components/buttons/CornerButton";
import BaseWhiteCardStyled from "../../../components/baseAppComponents/BaseWhiteCard/styled/BaseWhiteCardStyled";
import styled from "styled-components/macro";
import ArrowWhite from "../../../assets/img/svg/arrowWhite.svg";
import { useHistory } from "react-router-dom";
import trialHubIcon from "../../../assets/img3/svg/trial-hub-icon.svg";
import documentsHubIcon from "../../../assets/img3/svg/documents-hub-icon.svg";
import applicationsHubIcon from "../../../assets/img3/svg/applications-hub-icon.svg";
import analiticsIcon from "../../../assets/img3/svg/analitics-icon.svg";
import NoRecords from "../../../components/NoRecords";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";

const ArrowStyled = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: rgba(203, 207, 238, 0.1);
`;
export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDashboardData(dispatch);
      setData(response);
      if (response) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const pageConfig = [
    {
      path: `${route.baseApp.dashboard}`,
      title: "Dashboard",
      activeMenuItem: DASHBOARD,
    },
  ];

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  const goToCase = () => {
    history.push(
      `${is_admin ? "/admin/all-cases" : "/app/cases"}/${
        data.recent ? data.recent.id : ""
      }`
    );
  };

  const goToCasesList = () => {
    history.push(`${is_admin ? "/admin/all-cases" : "/app/cases"}`);
  };

  const goToCaseById = (id, addToLink) => {
    history.push(
      `${is_admin ? "/admin/all-cases" : "/app/cases"}/${id}${addToLink}`
    );
  };
  const hubsAndTrialProofs = [
    { id: 2 },
    {
      id: 1,
      label: "Trial Hub",
      icon: trialHubIcon,
      addToLink: "/trial-hub/evidence",
      total: data.trial_total ? data.trial_total : "0",
      features: data.trialhub
        ? data.trialhub.map((v) => {
            return { id: v.id, feature: v.label };
          })
        : [],
    },
    {
      id: 3,
      label: "Documents Hub",
      total: data.documents_total ? data.documents_total : "0",
      icon: documentsHubIcon,
      addToLink: "/documents",
      features: data.documents
        ? data.documents.map((v) => {
            return { id: v.id, feature: v.label };
          })
        : [],
    },
    {
      id: 4,
      label: "Applications Hub",
      icon: applicationsHubIcon,
      addToLink: "/applications-hub",
      total: data.applications_total ? data.applications_total : "0",
      features: data.applications
        ? data.applications.map((v) => {
            return { id: v.id, feature: v.label };
          })
        : [],
    },
  ];

  const onClickAddCaseHandler = async () => {
    try {
      let resp = await addCase(dispatch, {});
      history.push(
        `${
          is_admin ? route.admin.baseApp.cases_edit : route.baseApp.cases_edit
        }` +
          "/" +
          resp.id
      );
    } catch (e) {}
  };

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      {data.recent ? (
        <Fragment>
          <BaseWhiteCardStyled className="pt-4 pl-4 d-flex mb-4 flex-column justify-content-between">
            <div className="row justify-content-between">
              <div className="col-12 mb-3 col-xl-5 mb-xl-0 d-flex">
                <RsbCaseCardPreviewDotStyled
                  isArchive={false}
                  style={{ marginRight: "10px" }}
                />
                <TagElementPurple
                  overflow
                  // style={{ marginRight: "10px", height: "1.5rem" }}
                  style={{ marginRight: "10px" }}
                >
                  <Bold14Font style={{ color: "inherit" }}>
                    {data.recent.case_type
                      ? data.recent.case_type.name
                      : "no type"}
                  </Bold14Font>
                </TagElementPurple>
                <TagElementCyan
                  overflow
                  // style={{ height: "1.5rem" }}
                >
                  <Bold14Font style={{ color: "inherit" }}>
                    {data.recent.firm_name ? data.recent.firm_name : "no firm"}
                  </Bold14Font>
                </TagElementCyan>
              </div>
              <div className="col-12 d-flex justify-content-center mb-3 col-xl-7 justify-content-xl-end mb-xl-0">
                <div
                  className="d-flex flex-wrap justify-content-center"
                  style={{ maxWidth: "387px" }}
                >
                  <Medium14Font className="pr-4">Latest Work Item</Medium14Font>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col">
                <Bold30Font>
                  {data.recent.label ? data.recent.label : "New case"}
                </Bold30Font>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-between">
                <div className="d-flex" title="Client role">
                  <img src={personIcon} alt="" className="img-fluid mr-2" />
                  {data.recent.parties.length ? (
                    <Medium14Font className="d-flex align-items-center">
                      {data.recent.parties[0].role}
                    </Medium14Font>
                  ) : (
                    <Medium14Font className="d-flex align-items-center">
                      N/A
                    </Medium14Font>
                  )}
                </div>
                <CornerButton
                  onClick={goToCase}
                  className="d-flex align-items-center justify-content-between pl-4 pr-3"
                >
                  <Bold14Font
                    textColor={theme.colors.white}
                    style={{ marginRight: "8px" }}
                  >
                    View Case
                  </Bold14Font>
                  <ArrowStyled>
                    <img alt="arrow" src={ArrowWhite} />
                  </ArrowStyled>
                </CornerButton>
              </div>
            </div>
          </BaseWhiteCardStyled>
          <div className="row mb-4">
            {hubsAndTrialProofs
              .filter((v) => v.id !== 4)
              .map((el) => {
                if (el.id === 2) {
                  return (
                    <div key={el.id} className="col-12 mb-3 col-lg-6 col-xl-6">
                      <BaseWhiteCardStyled className="h-100 p-4 d-flex flex-column justify-content-between position-relative">
                        <div className="row mb-4 flex-wrap">
                          <div className="col-4 col-lg-12 col-xl-3">
                            <img
                              src={analiticsIcon}
                              alt=""
                              className="img-fluid"
                              style={{ maxWidth: "70px" }}
                            />
                          </div>
                          <div className="col-7 d-flex pl-3 flex-column col-lg-12 col-xl-8 justify-content-end">
                            <Bold24Font>Cases</Bold24Font>
                            <Medium14Font textColor={theme.colors.darkOpacity}>
                              {data.total_cases} Cases
                            </Medium14Font>
                          </div>
                        </div>
                        <div className="row h-100">
                          <div className="col-12">
                            <ul>
                              <li>
                                <Bold18Font textColor={theme.colors.dark}>
                                  {data.active_cases}
                                </Bold18Font>{" "}
                                <Medium14Font
                                  className="ml-2"
                                  textColor={theme.colors.darkOpacity}
                                >
                                  Active Cases
                                </Medium14Font>
                              </li>
                              <li>
                                <Bold18Font textColor={theme.colors.dark}>
                                  {data.archived_cases}
                                </Bold18Font>{" "}
                                <Medium14Font
                                  className="ml-2"
                                  textColor={theme.colors.darkOpacity}
                                >
                                  Archived Cases
                                </Medium14Font>
                              </li>
                              <li>
                                <Bold18Font textColor={theme.colors.dark}>
                                  {data.active_firms}
                                </Bold18Font>{" "}
                                <Medium14Font
                                  className="ml-2"
                                  textColor={theme.colors.darkOpacity}
                                >
                                  Active Firms
                                </Medium14Font>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                          }}
                        >
                          <CornerButton
                            onClick={goToCasesList}
                            className="d-flex align-items-center justify-content-between pl-4 pr-3"
                          >
                            <Bold14Font textColor={theme.colors.white}>
                              View All
                            </Bold14Font>
                            <ArrowStyled>
                              <img alt="arrow" src={ArrowWhite} />
                            </ArrowStyled>
                          </CornerButton>
                        </div>
                      </BaseWhiteCardStyled>
                    </div>
                  );
                }
                return (
                  <div key={el.id} className="col-12 mb-3 col-lg-6 col-xl-6">
                    <BaseWhiteCardStyled className="h-100 p-4 d-flex flex-column justify-content-between position-relative">
                      <div className="row mb-4 flex-wrap">
                        <div className="col-4 col-lg-12 col-xl-3">
                          <img
                            src={el.icon}
                            alt=""
                            className="img-fluid"
                            style={{ maxWidth: "70px" }}
                          />
                        </div>
                        <div className="col-7 d-flex pl-3 flex-column col-lg-12 col-xl-8 justify-content-end">
                          <Bold24Font>{el.label}</Bold24Font>
                          <Medium14Font textColor={theme.colors.darkOpacity}>
                            {el.total} Active
                          </Medium14Font>
                        </div>
                      </div>
                      <div className="row h-100">
                        <div className="col-12">
                          <ul style={{ listStyle: "disc inside" }}>
                            {el.features.map((feature) => (
                              <li
                                onClick={() => {
                                  goToCaseById(feature.id, el.addToLink);
                                }}
                                key={feature.id}
                              >
                                <Medium14Font pinter>
                                  {feature.feature}
                                </Medium14Font>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </BaseWhiteCardStyled>
                  </div>
                );
              })}

            {hubsAndTrialProofs
              .filter((v) => v.id === 4)
              .map((el, idx) => (
                <div key={el.id} className="col-12 mb-3 col-lg-6 col-xl-6">
                  <BaseWhiteCardStyled className="h-100 p-4 d-flex flex-column justify-content-between position-relative">
                    <div className="row mb-4 flex-wrap">
                      <div className="col-4 col-lg-12 col-xl-3">
                        <img
                          src={el.icon}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "70px" }}
                        />
                      </div>
                      <div className="col-7 d-flex pl-3 flex-column col-lg-12 col-xl-8 justify-content-end">
                        <Bold24Font>{el.label}</Bold24Font>
                        <Medium14Font textColor={theme.colors.darkOpacity}>
                          {el.total} Active
                        </Medium14Font>
                      </div>
                    </div>
                    <div className="row h-100">
                      <div className="col-12">
                        <ul style={{ listStyle: "disc inside" }}>
                          {el.features.map((feature) => (
                            <li
                              onClick={() => {
                                goToCaseById(feature.id, el.addToLink);
                              }}
                              key={feature.id}
                            >
                              <Medium14Font pinter>
                                {feature.feature}
                              </Medium14Font>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </BaseWhiteCardStyled>
                </div>
              ))}
          </div>
        </Fragment>
      ) : (
        <NoRecords className="d-flex flex-wrap justify-content-center align-items-center">
          <Bold18Font textColor={"#0f122f"}>
            Your data will appear here.
          </Bold18Font>
          {user.role !== "assistant" ? (
            <ButtonNew tertiary clickHandler={onClickAddCaseHandler}>
              <Bold18Font style={{ color: theme.colors.blue }}>
                Add case
              </Bold18Font>
            </ButtonNew>
          ) : null}
        </NoRecords>
      )}
    </Fragment>
  );
}
