/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
import { NotebookContext, EditorContext } from "@/context";
import { FloatingMenu } from "./styled";
import { useHistory, useParams } from "react-router-dom";

import EditObjectPanel from "@/components/Notebook/EditObjectPanel";
import { updateCase } from "@/containers/Auth/auth";

import { useDispatch, useSelector } from "react-redux";
import { SHOW_RIGHT_SIDE_BAR, HIDE_RIGHT_SIDE_BAR } from "@/redux/types";
import axios from "axios";
import { loadAccessToken } from "@/containers/Auth/auth";

import CauseOfActionForm from "@/components/forms/CasesForms/CauseOfActionForm";
import PartiesForm from "@/components/forms/CasesForms/Parites";
import ThemesForm from "@/components/forms/CasesForms/ThemesForm";
import IssuesForm from "@/components/forms/CasesForms/IssuesForm";
import ReliefsAndProofs from "@/components/forms/ApplicationsHubForms/ReliefsAndProofs";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default ({ tag, setShowMenu, deleteNode }) => {
  const dispatch = useDispatch();
  const isSideBarPulled = useSelector(
    (state) => state.rightSideBar.isSideBarPulled
  );
  const { id: caseId, notebook_id: notebookId } = useParams();
  const history = useHistory();
  const [causesOfAction, setCausesOfAction] = useState([]);

  const {
    notebook,
    notebookLoading,
    caseObject,
    setCaseObject,
    fetchCaseLibrary,
    filterQuery,
  } = useContext(NotebookContext);
  const {
    editor,
    setContent,
    // saveContent,
  } = useContext(EditorContext);

  const updateEditorState = (tagId, tagType, updatedLabel) => {
    const oldJson = editor.getJSON();
    const newJson = {
      ...oldJson,
      content: oldJson.content.map((node) => {
        return {
          ...node,
          content: node.content
            ? node.content.map((subNode) => {
                // console.log("subnode");
                // console.log(subNode.type);
                if (
                  subNode.type === "tag" ||
                  subNode.type === "authority" ||
                  subNode.type === "comment" ||
                  subNode.type === "evidence" ||
                  subNode.type === "exam" ||
                  subNode.type === "objective" ||
                  subNode.type === "party" ||
                  subNode.type === "section" ||
                  subNode.type === "topic" ||
                  subNode.type === "witness"
                ) {
                  // console.log("---");
                  // console.log(subNode);
                  // console.log(tagId);
                  // console.log(tagType);
                  if (
                    subNode.attrs.tagId == tagId &&
                    subNode.attrs.tagType.toLowerCase() == tagType.toLowerCase()
                  ) {
                    return {
                      ...subNode,
                      attrs: { ...subNode.attrs, id: updatedLabel },
                    };
                  }
                  return subNode;
                }
                return subNode;
              })
            : [],
        };
      }),
    };
    editor.commands.setContent(newJson);

    const newHtml = editor.getHTML();
    setContent(newHtml);
  };

  useEffect(() => {
    setCausesOfAction(caseObject.causes_of_action ?? []);
  }, [caseObject]);

  const changeObj = async (resp, type, obj) => {
    if (resp) {
      let caseObject_clone = JSON.parse(JSON.stringify(caseObject));
      if (type === "add") {
        caseObject_clone[obj].push(resp);
      } else if (type === "update") {
        caseObject_clone[obj].forEach((v, index) => {
          if (v.id === resp.id) {
            caseObject_clone[obj][index] = resp;
          }
        });
      } else if (type === "delete") {
        caseObject_clone[obj] = caseObject[obj].filter((v) => {
          return v.id !== resp.id;
        });
      }

      const res = await updateCase(caseObject.id, dispatch, caseObject_clone);
      setCaseObject(res);

      await sleep(3000);

      await fetchCaseLibrary(caseId, notebookId, filterQuery);
    }
  };

  const updateApplication = async (obj) => {
    const config = {
      headers: {
        Authorization: `Bearer ${await loadAccessToken()}`,
      },
    };

    const res = await axios.put(
      `/api/cases/${caseId}/applications/${notebook.application.id}`,
      {
        reliefs: obj.reliefs,
        proofs: obj.proofs,
      },
      config
    );
  };

  const handleRelief = () => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ReliefsAndProofs
          // key={object.id}
          object={notebook.application}
          setObject={async (obj, callback) => {
            await updateApplication(obj);
            await sleep(2000);
            await fetchCaseLibrary(caseId, notebookId, filterQuery);
            callback();
          }}
          routeParams={{ caseId, applicationId: notebook.application }}
          alreadySavedReliefs={[]}
          alreadySavedProofs={[]}
          loadingAfterCallback
        />
      ),
      title: "Add Relief",
    });
  };

  const handleCauseOfAction = (object, objType, counterclaim) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <CauseOfActionForm
          caseObject={caseObject}
          key={object.id}
          id_case={caseObject.id}
          counterclaim={counterclaim}
          object={object}
          // isCriminal={caseObject.new_case_type === "Criminal"}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "causes_of_action");
            callback();

            console.log("update");
            console.log(objType);
            if (objType === "causesofaction") {
              console.log("update self");
              console.log(resp);
              // 1. update element itself

              // 2. update all of subelements
              resp["elements"].forEach((el) => {
                console.log(el);
                updateEditorState(el.id, "causesofactionelement", el.name);
              });
              resp["defence_elements"].forEach((el) => {
                console.log(el);
                updateEditorState(
                  el.id,
                  "causesofactiondefenseelement",
                  el.name
                );
              });
            } else if (
              objType === "causesofactionelement" ||
              objType === "causesofactiondefenseelement"
            ) {
              // updateEditorState(resp.id, "causeofaction", resp.name);
              resp["elements"].forEach((el) => {
                updateEditorState(el.id, "causesofactionelement", el.name);
              });
              resp["defence_elements"].forEach((el) => {
                updateEditorState(
                  el.id,
                  "causesofactiondefenseelement",
                  el.name
                );
              });
            }
          }}
          alreadySavedCOAList={causesOfAction}
          loadingAfterCallback
        />
      ),
      title:
        object.type && object.type.name
          ? "Edit: " + object.type.name
          : counterclaim
          ? "Add Counterclaim"
          : caseObject.new_case_type === "Criminal"
          ? "Add Offence"
          : "Add Cause of action",
    });
  };

  const handleTheme = (object, objType) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <ThemesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          // subthemeToEdit={objType === "subtheme" && object.id}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "themes");
            callback();

            if (objType === "theme") {
              // 1. update theme itself
              updateEditorState(tag.tagId, tag.tagType, resp.name);

              // 2. update all of subtheme
              resp["sub_themes"].forEach((subtheme) => {
                updateEditorState(subtheme.id, "subtheme", subtheme.name);
              });
            } else if (objType === "subtheme") {
              // 1. update issue itself
              updateEditorState(resp.id, "theme", resp.name);

              // 2. update all of keyfacts
              resp["sub_themes"].forEach((subtheme) => {
                updateEditorState(subtheme.id, "subtheme", subtheme.name);
              });
            }
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Theme",
    });
  };

  const handleParties = (object, objType) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <PartiesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          caseObject={caseObject}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "parties");
            callback();

            if (objType === "issue") {
              // 1. update issue itself
              updateEditorState(tag.tagId, tag.tagType, resp.name);

              // 2. update all of keyfacts
            } else if (objType === "keyfact") {
              // 1. update issue itself
              // 2. update all of keyfacts
            }
            console.log(resp);
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add New Party",
    });
  };

  const handleIssues = (object, objType) => {
    dispatch({
      type: SHOW_RIGHT_SIDE_BAR,
      url: history.location.pathname,
      content: (
        <IssuesForm
          key={object.id}
          id_case={caseObject.id}
          object={object}
          afterSubmit={async (resp, type, callback) => {
            await changeObj(resp, type, "issues");
            callback();

            if (objType === "issue") {
              // 1. update issue itself
              updateEditorState(tag.tagId, tag.tagType, resp.name);

              // 2. update all of keyfacts
              resp["key_facts"].forEach((keyfact) => {
                updateEditorState(keyfact.id, "keyfact", keyfact.name);
              });
            } else if (objType === "keyfact") {
              // 1. update issue itself
              updateEditorState(resp.id, "issue", resp.name);

              // 2. update all of keyfacts
              resp["key_facts"].forEach((keyfact) => {
                updateEditorState(keyfact.id, "keyfact", keyfact.name);
              });
            }
          }}
          loadingAfterCallback
        />
      ),
      title: object.name ? "Edit: " + object.name : "Add Issue",
    });
  };

  if (notebookLoading || !notebook || !caseObject) return null;

  return (
    <FloatingMenu>
      <span className="tag-action">Connect to</span>
      <span
        className="tag-action"
        onClick={async () => {
          if (
            tag.tagType === "theme" ||
            tag.tagType === "subtheme" ||
            tag.tagType === "causesofaction" ||
            tag.tagType === "causesofactionelement" ||
            tag.tagType === "causesofactiondefenseelement" ||
            tag.tagType === "issue" ||
            tag.tagType === "keyfact"
          ) {
            if (tag.tagType === "theme" || tag.tagType === "subtheme") {
              let themeObj;
              if (tag.tagType === "subtheme") {
                themeObj = caseObject.themes.find((i) =>
                  i["sub_themes"].some((subtheme) => subtheme.id == tag.tagId)
                );
              } else {
                themeObj = caseObject.themes.find((i) => i.id == tag.tagId);
              }
              handleTheme(themeObj, tag.tagType);
            }
            if (
              tag.tagType === "causesofaction" ||
              tag.tagType === "causesofactionelement" ||
              tag.tagType === "causesofactiondefenseelement"
            ) {
              let coaObj;
              if (tag.tagType === "causesofactionelement") {
                coaObj = caseObject["causes_of_action"].find((i) =>
                  i.elements.some((element) => element.id == tag.tagId)
                );
              } else if (tag.tagType === "causesofactiondefenseelement") {
                coaObj = caseObject["causes_of_action"].find((i) =>
                  i["defence_elements"].some(
                    (element) => element.id == tag.tagId
                  )
                );
              } else {
                coaObj = caseObject["causes_of_action"].find(
                  (i) => i.id == tag.tagId
                );
              }
              handleCauseOfAction(coaObj, tag.tagType);
            }
            if (tag.tagType === "issue" || tag.tagType === "keyfact") {
              let issueObj;
              if (tag.tagType === "keyfact") {
                issueObj = caseObject.issues.find((i) =>
                  i["key_facts"].some((fact) => fact.id == tag.tagId)
                );
              } else {
                issueObj = caseObject.issues.find((i) => i.id == tag.tagId);
              }
              handleIssues(issueObj, tag.tagType);
            }
            //
          } else {
            if (isSideBarPulled) {
              await dispatch({ type: HIDE_RIGHT_SIDE_BAR });
              await dispatch({
                type: SHOW_RIGHT_SIDE_BAR,
                url: window.location.pathname,
                content: <EditObjectPanel {...tag} />,
                rightBarWidth: "18%",
                title: `Editing ${tag.tagType}`,
              });
            } else {
              dispatch({
                type: SHOW_RIGHT_SIDE_BAR,
                url: window.location.pathname,
                content: <EditObjectPanel {...tag} />,
                rightBarWidth: "18%",
                title: `Editing ${tag.tagType}`,
              });
            }
          }

          setShowMenu(false);
        }}
      >
        Edit
      </span>
      <span
        className="tag-action"
        onClick={() => {
          deleteNode();
        }}
      >
        Delete
      </span>
    </FloatingMenu>
  );
};
