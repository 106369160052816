import styled from "styled-components/macro";
import { minButtonHeight } from "./ButtonStyled";
import rem from "../../../../utils/rem";

const PrimaryButtonStyled = styled.button`
  // Get min height from base button component to calc border radius

  padding: ${rem("9px 20px")};
  background: ${({ theme }) => theme.colors.blue};

  :hover {
    background-color: ${({ theme }) => theme.colors.blueHover};
  }

`;

export default PrimaryButtonStyled;
