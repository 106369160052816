import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const LandingPageNavBarListItemStyled = styled.li`
  &:not(:last-child) {
    margin-right: ${rem(`51px`)};
  }
`;

export default LandingPageNavBarListItemStyled;
