import React, { Fragment } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import PageConfiguration from "../../../components/baseAppComponents/BreadCrumbs/PageConfiguration";
import { CASES } from "../../../components/baseAppComponents/BaseAppLayout/BaseAppLayoutLeftSideBar/BaseAppLayoutLeftSideBar";
import RestrictAccessScreen from "../../../components/RestrictAccessScreen";

const CaseForbidden = ({ theme }) => {
  const { case_id } = useParams();
  const location = useLocation();
  const history = useHistory();

  if (!location || !location.state || !location.state.caseName) {
    history.push("/app/cases");
    return null;
  }

  // Breadcrumbs configuration object
  const pageConfig = [
    {
      path: "/app/cases",
      title: "Cases",
      activeMenuItem: CASES,
    },
    {
      path: `/app/cases/${case_id}`,
      title: location.state.caseName,
      activeMenuItem: CASES,
    },
  ];

  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row">
        <div className="col">
          <RestrictAccessScreen errorObject={location.state} />
        </div>
      </div>
    </Fragment>
  );
};

export default withTheme(CaseForbidden);
