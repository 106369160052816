import styled from "styled-components/macro";
import rem from "../../../../../utils/rem";

const BaseAppLayoutLeftSidebarMenuIconStyled = styled.span`
  font-family: "Trial-Proofer";
  font-size: ${rem(`30px`)};
  color: ${({ theme }) => theme.colors.whiteOpacity};
`;

export default BaseAppLayoutLeftSidebarMenuIconStyled;
