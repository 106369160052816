import React, { Fragment } from "react";
import RectangleButtonStyled from "./styled/RectangleButtonStyled";
import LsbCloseBtn from "./styled/LsbCloseBtn";
import closeWhiteIcon from "../../../assets/img3/svg/close-white-icon.svg";
import closeBlackIcon from "../../../assets/img3/svg/close-black-icon.svg";
import LsbHamburgerBtn from "./styled/LsbHamburgerBtn";
import rem from "../../../utils/rem";
import RsbCloseBtn from "./styled/RsbCloseBtn";

const RectangleButton = ({ rsbCloseBtn, lsbCloseBtn, lsbHamburgerBtn, ...props }) => {
  return (
    <Fragment>
      {lsbCloseBtn ? (
        <RectangleButtonStyled {...props} as={LsbCloseBtn}>
          {/* TODO: Add close icon to icon pack */}
          <img src={closeWhiteIcon} alt="" className="img-fluid" />
        </RectangleButtonStyled>
      ) : null}
      {rsbCloseBtn ? (
        <RectangleButtonStyled {...props} as={RsbCloseBtn}>
          {/* TODO: Add close icon to icon pack */}
          <img src={closeBlackIcon} alt="" className="img-fluid" />
        </RectangleButtonStyled>
      ) : null}
      {lsbHamburgerBtn ? (
        <RectangleButtonStyled {...props} as={LsbHamburgerBtn}>
          <span
            className="app-menu-hamburger-icon"
            style={{ fontSize: rem("12px") }}
          />
        </RectangleButtonStyled>
      ) : null}
    </Fragment>
  );
};

export default RectangleButton;
