import styled from "styled-components/macro";

const TextAreaCasesStyled = styled.textarea`
  width: 100%;
  height: 100px;
  outline: none;
  border-radius: 21px;
  border: 1px solid ${({theme}) => theme.colors.gray};
  padding: 10px 75px 10px 20px;
  background: ${({theme}) => theme.colors.white};
  resize: none;
  ::placeholder {
    color: ${({theme}) => theme.colors.darkOpacity};
    font-family: "PT Root UI",serif;
    font-size: 14px;
    font-weight: 500;
  }
`;

export default TextAreaCasesStyled;
