import styled from "styled-components/macro";
import LandingPageSectionStyled from "../../../components/LandingPageSection";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Bold30Font,
  Bold60Font,
  Medium14Font,
  Medium18Font,
} from "../../../components/Fonts/Fonts";
import ImgWidthWrapperStyled from "../../../components/helpWrappers/ImgWidthWrapperStyled";
import FeatureCardStyled from "../../../components/FeatureCard/styled/FeatureCardStyled";
import PlanCardStyled from "../../../components/PlanCard/styled/PlanCardStyled";
import PlanCardIconWrapperStyled from "../../../components/PlanCard/styled/PlanCardIconWrapperStyled";
import TestimonialCardAvatarPosition from "../../../components/TestimonialCard/styled/TestimonialCardAvatarPosition";
import FeedbackFormStyled from "../../../components/forms/FeedbackForm/styled/FeedbackFormStyled";
import InputStyled from "../../../components/Input/styled/InputStyled";
import TextAreaStyled from "../../../components/TextArea/styled/TextAreaStyled";
import TestimonialCardStyled from "../../../components/TestimonialCard/styled/TestimonialCardStyled";
import SubscribeFormStyled from "../../../components/forms/SubscribeForm/styled/SubscribeFormStyled";
import SubscribeFormButtonPosition from "../../../components/forms/SubscribeForm/styled/SubscribeFormButtonPosition";
import ButtonStyled from "../../../components/buttons/Button/styled/ButtonStyled";
import rem from "../../../utils/rem";
const LandingPageStyled = styled.div`

  max-width: 1920px;
  min-width: 320px;
  margin: auto;

  @media (max-width: 575.98px) {
    ${LandingPageSectionStyled} {
      padding-left: 2.25rem;
      padding-right: 2.25rem;

      :not(:first-child) {
        padding-top: 25rem;
      }
    }

    .custom-fs {
      ${Bold18Font} {
        font-size: 1rem;
      }
    }

    ${ButtonStyled} {
      ${Bold18Font} {
        font-size: 1.6rem;
      }
    }

    ${Bold60Font} {
      font-size: 6rem;
    }

    ${Bold30Font} {
      font-size: 3.875rem;
    }

    ${Bold24Font} {
      font-size: 3rem;
    }

    ${Bold18Font} {
      font-size: 4rem;
    }

    ${Bold14Font} {
      font-size: 2rem;
    }

    ${Medium18Font} {
      font-size: 2.8rem;
    }

    ${Medium14Font} {
      font-size: 2.5rem;
    }

    ${FeatureCardStyled} {
      max-width: 40rem;
    }

    ${ImgWidthWrapperStyled} {
      width: 10rem;
    }

    ${PlanCardStyled} {
      width: 100%;

      ${PlanCardIconWrapperStyled} {
        width: 13.5rem;
        height: 13.5rem;
        padding: 1.8rem;
      }
    }

    ${TestimonialCardStyled} {
      width: unset;

      ${TestimonialCardAvatarPosition} {
        width: 8rem;
        top: -5rem;
      }
    }

    ${FeedbackFormStyled} {
      width: 100%;
    }

    ${InputStyled} {
      height: 4.5rem;
      border-radius: 50vh;
      padding-left: 2.25rem;
      font-size: 2rem;

      ::placeholder {
        font-size: 2rem;
      }
    }

    ${TextAreaStyled} {
      height: 17rem;
      padding-left: 2.25rem;
      padding-right: 2.25rem;
      font-size: 2rem;

      ::placeholder {
        font-size: 2rem;
      }
    }
    ${SubscribeFormStyled} {
      width: 100%;
      height: 40px;

      ${SubscribeFormButtonPosition} {
        ${ButtonStyled} {
          //width: 40px;
          height: 27px;
          border-radius: 50vh;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    .landing-page {
      &__about-us-text {
        font-size: 2.5rem;
      }
      &__footer-subscribe-text {
        font-size: 3rem;
      }
    }
  }
`;

export default LandingPageStyled;
