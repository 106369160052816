import React from "react";
import PropTypes from "prop-types";
import {withTheme} from "styled-components/macro";

import TextAreaStyled from "./styled/TextAreaStyled";

import {Medium14Font} from "../../FontsNewComponent/Fonts";


const TextAreaWide = ({label, without_max_len, customStyle, theme, ...props}) => {
  return (
    <div className="row">
      <div className="col">
        {without_max_len ? null : <Medium14Font
          style={{
            color: theme.colors.darkOpacity,
            position: "relative",
            left: "calc(100% - 75px)",
            top: "23%",
          }}
        >
          Optional
        </Medium14Font>}
        <TextAreaStyled as={customStyle} maxLength={without_max_len ? null : 300} {...props} />
        {without_max_len ? null : <Medium14Font
          style={{
            color: theme.colors.darkOpacity,
            position: "relative",
            left: "calc(100% - 70px)",
            bottom: "23%",
          }}
        >
          {props.value ? props.value.length : 0}/300
        </Medium14Font>}
      </div>
    </div>
  );
};

TextAreaWide.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  customStyle: PropTypes.object,
};

export default withTheme(TextAreaWide);
