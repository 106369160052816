import React from "react";
import ToggleButtonLabelStyled from "./styled/ToggleButtonLabelStyled";
import ToggleButtonSliderStyled from "./styled/ToggleButtonSliderStyled";

const ToggleButtonGray = (props) => {
  return (
    <ToggleButtonLabelStyled
      {...props.button_props}
      className={props.labelclass ?? null}
    >
      <input type="checkbox" {...props} />
      <ToggleButtonSliderStyled
        checked={props.checked}
        disabled={props.disabled}
      />
    </ToggleButtonLabelStyled>
  );
};

export default ToggleButtonGray;
