import styled, { keyframes } from "styled-components/macro";
import { bounceIn } from "react-animations";

const bounceAnimation = keyframes`${bounceIn}`;

const PopupStyled = styled.div`
  width: 174px;
  height: 100px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  left: ${({ left }) => `calc(27% + ${left}px)`};
  top ${({ top }) => `${top}px`};
  animation: 0.5s ${bounceAnimation};

  :after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    background-color: ${({ theme }) => theme.colors.white};
    transform: rotate(45deg);
    position: absolute;
    bottom: -5px;
    right: 150px;
  }
   @media (max-width: 1398px) {
       left: ${({ left }) => `calc(20% + ${left}px)`};
    }
      @media (max-width: 1398px) {
        top ${({ top }) => `calc(${top}px + 50px)`};
    }
   @media (max-width: 1200px) {
        top ${({ top }) => `${top}px`};
    }
      @media (max-width: 745px) {
         top ${({ top }) => `calc(${top}px + 120px)`};
    }
     @media (max-width: 575.98px) {
      left: ${({ left }) => `${left}px`};
       top ${({ top }) => `calc(${top}px + 180px)`};
     }
`;

export default PopupStyled;
