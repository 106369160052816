import React, { Fragment, useState, useEffect, createRef, useRef } from "react";
import PropTypes from "prop-types";
import Input from "../../Input";
import Button from "../../buttons/Button";
import { useDispatch } from "react-redux";
import ListAddButton from "../../buttons/ListAddButton";
import Label from "../AddCardForm/Label";
import { theme } from "../../../styled-components/Theme/Theme";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import EditableString from "./EditableString";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { addThemes, updateThemes } from "../../../containers/Auth/auth";
import EditableStringClassComponent from "./EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import InputNew from "../../InputNew/InputNew";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

export default function ThemesForm(prop) {
  let { object, id_case } = prop;
  const dispatch = useDispatch();
  const [object_, setObject] = useState(object);
  const [name, setName] = useState(object.name ?? "");
  const [isLoading, setIsLoading] = useState(false);
  const loadingAfterCallback = prop.loadingAfterCallback;
  let form = createRef();
  let editableStringsRef = useRef([]);

  const addSubtheme = async (e) => {
    let canAdd = true;
    if (editableStringsRef.current && editableStringsRef.current.length) {
      editableStringsRef.current.forEach((el) => {
        if (el) {
          canAdd = el.doneEditing();
        }
      });
    }
    if (canAdd) {
      let sub_themes;
      if (!object_.sub_themes) {
        sub_themes = [{ name: "" }];
      } else {
        sub_themes = [...object_.sub_themes, { name: "" }];
      }
      setObject({
        ...object_,
        ...{ sub_themes },
      });
    }
  };

  const deleteSubtheme = (index) => {
    setObject({
      ...object_,
      ...{
        sub_themes: object_.sub_themes.filter((v, i) => {
          return i !== index;
        }),
      },
    });
  };

  const editSubtheme = (index, name) => {
    let sub_themes = object_.sub_themes;
    sub_themes[index].name = name;
    setObject({
      ...object_,
      ...{ sub_themes },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      if (!form.current.checkValidity()) {
        form.current.reportValidity();
        return;
      }
      setIsLoading(true);
      let resp;
      let data = {
        name: name,
        sub_themes: object_.sub_themes
          ? object_.sub_themes.filter((el) => el.name !== "")
          : [],
      };
      if (prop.sendRequest) {
        if (object.id) {
          resp = await updateThemes(id_case, object.id, dispatch, data);
        } else {
          resp = await addThemes(id_case, dispatch, data);
        }
      } else {
        resp = {
          id: object_.id ?? `new_${Math.random()}`,
          ...data,
        };
      }
      if (!loadingAfterCallback) {
        setIsLoading(false);
      }
      if (resp) {
        prop.afterSubmit(
          resp,
          typeof object.id === "undefined" ? "add" : "update",
          () => {
            if (loadingAfterCallback) {
              setIsLoading(false);
              dispatch({ type: HIDE_RIGHT_SIDE_BAR });
            }
          }
        );
        if (!loadingAfterCallback) {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
        }
      }
    }
  };

  const assignRef = (ref, i) => {
    return (editableStringsRef.current[i] = ref);
  };

  return (
    <Fragment>
      <div className="form-group">
        <InputNew
          maxLength={255}
          type={"text"}
          required
          label={"Theme"}
          onChangeHandler={(e) => {
            setName(e.target.value);
          }}
          autoFocus
          value={name}
          placeholder={"Enter theme name"}
          name={"name"}
          form={`form-themes-${object.id}`}
        />
      </div>
      <div style={{ height: "100%", overflowY: "auto" }}>
        {object_.sub_themes && object_.sub_themes.length ? (
          <Fragment>
            <AnimatedDiv className="form-group mb-0">
              <Label className={"row"} name={"Subthemes"} />
            </AnimatedDiv>
            {object_.sub_themes.map((sub_t, i) => {
              return (
                <AnimatedDiv key={sub_t.id ?? i} className="form-group pl-3">
                  <EditableStringClassComponent
                    editName={editSubtheme}
                    editState={typeof sub_t.id === "undefined"}
                    deleteAction={deleteSubtheme}
                    object={sub_t}
                    index={i}
                    ref={(ref) => assignRef(ref, i)}
                  />
                </AnimatedDiv>
              );
            })}
          </Fragment>
        ) : null}
        <div className="form-group">
          <div className="container-fluid">
            <ListAddButton
              className="d-flex"
              label="Add Subtheme"
              clickHandler={addSubtheme}
            />
          </div>
        </div>
      </div>
      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form
            ref={form}
            id={`form-themes-${object.id}`}
            onSubmit={handleSubmit}
          >
            <ButtonNew
              //clickHandler={handleSubmit}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

ThemesForm.propTypes = {
  object: PropTypes.object,
  id_case: PropTypes.number.isRequired,
  afterSubmit: PropTypes.func.isRequired,
};
