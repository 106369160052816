import React from "react";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";

import StubPageStyled from "./styled/StubPageStyled";
import Button from "../buttons/Button";

import { Bold30Font, Bold90Font } from "../FontsNewComponent/Fonts";

const StubPage = ({ children, theme, onClick }) => {
  const history = useHistory();
  return (
    <StubPageStyled>
      <Bold90Font textColor={theme.colors.white}>{children}</Bold90Font>
      <Button
        secondary
        clickHandler={() => {
          if (onClick) {
            onClick();
          } else {
            history.goBack();
          }
        }}
      >
        <Bold30Font color={theme.colors.blue}>Go back</Bold30Font>
      </Button>
    </StubPageStyled>
  );
};

export default withTheme(StubPage);
