import React, { Fragment, useEffect, useState } from "react";
import { withTheme } from "styled-components/macro";
import AddEditAuthorities from "../../TrialHub/Authorities/AddEditAuthorities";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewApplication,
  createNewAuthority,
  deleteAuthorityById,
  getAuthoritiesList,
  updateApplicationById,
  updateAuthorityById,
  uploadAuthorityFile,
} from "../../../Auth/auth";
import AnimatedDiv from "../../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Table from "../../../../components/Table/Table";
import ButtonNew from "../../../../components/buttons/Button/ButtonNew";
import {
  HIDE_RIGHT_SIDE_BAR,
  MODAL_DELETE_ELEMENT,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
} from "../../../../redux/types";
import {
  Bold14Font,
  Bold18Font,
  Bold24Font,
  Medium14Font,
} from "../../../../components/FontsNewComponent/Fonts";
import { format, parseISO } from "date-fns";
import LiStyled from "../../../../components/Table/Styled/LiStyled";
import Arrow from "../../../../components/buttons/Button/Arrow";
import Form from "../../../../components/forms/Form";
import ButtonAnotherTry from "../../../../components/buttons/Button/ButtonAnotherTry";
import bulkIcon from "../../../../assets/img3/svg/bulk-icon.svg";
import EvidenceImport from "../../TrialHub/Evidence/EvidenceImport";
import { setTagField } from "../../../../utils/function";
import useWindowSize from "../../../../hooks/useWindowSize";
import NoRecords from "../../../../components/NoRecords";

export const VIEW_TABLE_AUTHORITIES = "APPLICATION_AUTHORITIES_VIEW/TABLE";
export const VIEW_SINGLE_FORM_AUTHORITIES =
  "APPLICATION_AUTHORITIES_VIEW/SINGLE_FORM";
export const VIEW_BULK_IMPORT_AUTHORITIES =
  "APPLICATION_AUTHORITIES_VIEW/BULK_IMPORT";

const ApplicationAuthorities = ({
  caseObject,
  applicationObject,
  setApplicationObject,
  changeFlowStage,
  selectedObjectFromLocation,
  setSelectedObjectFromLocation,
  blockBlurEventParent,
  setBlockBlurEventParent,
  setApplicationAuthoritySubmitObject,
  setApplicationAuthoritiesCurrentView,
  theme,
}) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const isSubmitting = useSelector((state) => state.app.isSubmitting);
  const [submitObject, setSubmitObject] = useState(null);
  const { id, applicationId } = useParams();
  const [currentView, setCurrentView] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const { width } = useWindowSize();

  // data states
  const [applicationAuthoritiesList, setApplicationAuthoritiesList] = useState(
    []
  );
  const [
    applicationAuthoritiesSelectedObject,
    setApplicationAuthoritiesSelectedObject,
  ] = useState(selectedObjectFromLocation ? selectedObjectFromLocation : null);

  // get data

  // const getDataObjectFromAPI = async () => {};

  // configure view logic based on applicationPapersList
  useEffect(() => {
    // if no application papers list - show add single paper form
    if (currentView !== VIEW_BULK_IMPORT_AUTHORITIES) {
      if (!applicationObject.id) {
        setCurrentView(VIEW_TABLE_AUTHORITIES);
        if (setApplicationAuthoritiesCurrentView) {
          setApplicationAuthoritiesCurrentView(VIEW_TABLE_AUTHORITIES);
        }
      } else if (applicationAuthoritiesSelectedObject) {
        setCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
        if (setApplicationAuthoritiesCurrentView) {
          setApplicationAuthoritiesCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
        }
        // getDataObjectFromAPI();
      }
      // else - show table view
      else {
        setCurrentView(VIEW_TABLE_AUTHORITIES);
        if (setApplicationAuthoritiesCurrentView) {
          setApplicationAuthoritiesCurrentView(VIEW_TABLE_AUTHORITIES);
        }
      }
    } // if (!applicationObject.authorities.length) {
    //   setApplicationAuthoritiesSelectedObject(null);
    // }
  }, [
    applicationAuthoritiesSelectedObject,
    applicationObject,
    applicationObject.authorities,
  ]);

  const onClickEditTableRowHandler = (object) => {
    setApplicationAuthoritiesSelectedObject(object);
    // saveDataObject(object);
  };

  const onClickDeleteTableRowHandler = async (object) => {
    const resp = await deleteAuthorityById(object.fake_id, id, dispatch);
    if (resp) {
      const applicationAuthoritiesListCopy = applicationObject.authorities.filter(
        (el) => el.id !== object.id
      );
      setApplicationObject((prevState) => ({
        ...prevState,
        authorities: applicationAuthoritiesListCopy,
      }));
    }
  };

  const onClickCloseFormHandler = async () => {
    let resp;
    if (
      applicationAuthoritiesSelectedObject &&
      "fake_id" in applicationAuthoritiesSelectedObject
    ) {
      resp = await updateAuthorityById(
        applicationAuthoritiesSelectedObject.fake_id,
        id,
        dispatch,
        applicationAuthoritiesSelectedObject
      );
      if (resp) {
        if (
          applicationAuthoritiesSelectedObject &&
          applicationAuthoritiesSelectedObject.file &&
          typeof applicationAuthoritiesSelectedObject.file.size !== 'undefined'  &&
          applicationAuthoritiesSelectedObject.file.size !== 0
        ) {
          let formData = new FormData();
          formData.append("file", applicationAuthoritiesSelectedObject.file);
          resp = await uploadAuthorityFile(
            resp && resp.id ? resp.id : resp.fake_id ?? resp.id,
            id,
            dispatch,
            formData
          );
        }
        setApplicationObject((prevState) => {
          const copyObject = { ...prevState };
          resp.fake_id = resp.id;
          const index = copyObject.authorities.findIndex(
            (el) => el.fake_id === resp.fake_id
          );
          copyObject.authorities[index] = resp;
          return copyObject;
        });
      }
    } else {
      let data = applicationAuthoritiesSelectedObject ?? {};
      data.id_application = applicationId;
      resp = await createNewAuthority(id, dispatch, data);
      if (resp) {
        if (!("fake_id" in resp)) {
          resp.fake_id = resp.id;
        }
        if (
          applicationAuthoritiesSelectedObject &&
          applicationAuthoritiesSelectedObject.file &&
          typeof applicationAuthoritiesSelectedObject.file.size !== "undefined" &&
          applicationAuthoritiesSelectedObject.file.size !== 0
        ) {
          let formData = new FormData();
          formData.append("file", applicationAuthoritiesSelectedObject.file);
          resp = await uploadAuthorityFile(
            // authorityObject.id ? authorityObject.id : respAuthority.id,
            resp && resp.id ? resp.id : resp.fake_id ?? resp.id,
            id,
            dispatch,
            formData
          );
        }
        setApplicationObject((prevState) => ({
          ...prevState,
          authorities: [...prevState.authorities, resp],
        }));
      }
    }
    setCurrentView(VIEW_TABLE_AUTHORITIES);
    if (setApplicationAuthoritiesCurrentView) {
      setApplicationAuthoritiesCurrentView(VIEW_TABLE_AUTHORITIES);
    }
    setApplicationAuthoritiesSelectedObject(null);
    setSelectedObjectFromLocation(null);
  };
  const onCloseAuthority = () => {
    setCurrentView(VIEW_TABLE_AUTHORITIES);
    if (setApplicationAuthoritiesCurrentView) {
      setApplicationAuthoritiesCurrentView(VIEW_TABLE_AUTHORITIES);
    }
    setApplicationAuthoritiesSelectedObject(null);
    setSelectedObjectFromLocation(null);
  };

  const onClickApplicationPapersHandler = () => {
    changeFlowStage(1);
  };

  const onClickReturnToApplicationHubHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${id}/applications-hub`
        : `/admin/all-cases/${id}/applications-hub`
    );
  };

  const onClickAddNewAuthorityHandler = async () => {
    if (!applicationObject.id) {
      if (!addLoading) {
        setAddLoading(true);
        let resp = await createNewApplication(
          caseObject.id,
          dispatch,
          applicationObject
        );
        setApplicationObject(resp);
        history.push(
          !is_admin
            ? `/app/cases/${id}/applications-hub/edit/${resp.id}`
            : `/admin/all-cases/${id}/applications-hub/edit/${resp.id}`
        );
        setAddLoading(false);
        setCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
        if (setApplicationAuthoritiesCurrentView) {
          setApplicationAuthoritiesCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
        }
      }
    } else {
      if (!addLoading) {
        setAddLoading(true);
        let resp = await updateApplicationById(
          applicationId,
          caseObject.id,
          dispatch,
          {
            proofs: applicationObject.proofs,
            reliefs: applicationObject.reliefs,
            moving_parties: applicationObject.moving_parties,
            respondent_parties: applicationObject.respondent_parties,
          }
        );
        setApplicationObject(resp);
        setAddLoading(false);
      }
      setCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
      if (setApplicationAuthoritiesCurrentView) {
        setApplicationAuthoritiesCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
      }
    }
  };

  const onClickSingleEvidenceButtonHandler = () => {
    // TODO: Does I need this here?
    // setKeyValueToBulkImportApplicationsPapers((prevState) => prevState + 1);
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
    setCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
    if (setApplicationAuthoritiesCurrentView) {
      setApplicationAuthoritiesCurrentView(VIEW_SINGLE_FORM_AUTHORITIES);
    }
  };

  const onClickReturnToApplicationsAuthoritiesHandler = (list) => {
    const listWithFakeId = list.map((el) => ({ ...el, fake_id: el.id }));
    const applicationObjectCopy = { ...applicationObject };
    applicationObjectCopy.authorities.push(...listWithFakeId);
    setApplicationObject(applicationObjectCopy);
    setCurrentView(VIEW_TABLE_AUTHORITIES);
    if (setApplicationAuthoritiesCurrentView) {
      setApplicationAuthoritiesCurrentView(VIEW_TABLE_AUTHORITIES);
    }
  };
  const onClickBulkImportHandler = () => {
    setCurrentView(VIEW_BULK_IMPORT_AUTHORITIES);
    if (setApplicationAuthoritiesCurrentView) {
      setApplicationAuthoritiesCurrentView(VIEW_BULK_IMPORT_AUTHORITIES);
    }
  };
  const applicationAuthoritiesTable = {
    collapsed: false,
    actions: [
      {
        type: "edit",
        callback: (object) => onClickEditTableRowHandler(object),
      },
      {
        type: "delete",
        callback: (object) => {
          dispatch({ type: HIDE_RIGHT_SIDE_BAR });
          dispatch({
            type: SAVE_MODAL_DATA,
            payload: "You won't be able to restore data",
            beforeCloseHandler: async () => {
              await onClickDeleteTableRowHandler(object);
            },
          });
          dispatch({ type: SHOW_MODAL, payload: MODAL_DELETE_ELEMENT });
        },
      },
    ],
    fields: [
      {
        name: "fake_id",
        label: "ID",
        renderer: (object) => {
          return <Medium14Font>{object.fake_id}</Medium14Font>;
        },
      },
      {
        name: "title",
        label: "TITLE",
        renderer: (object) => {
          return <Medium14Font>{object.title}</Medium14Font>;
        },
      },
      {
        name: "type",
        label: "TYPE",
        renderer: (object) => {
          return <Medium14Font>{object.type}</Medium14Font>;
        },
      },
      {
        name: "jurisdiction",
        label: "JURISDICTION",
        renderer: (object) => {
          return <Medium14Font>{object.jurisdiction}</Medium14Font>;
        },
      },
      {
        name: "citation",
        label: "CITATION",
        renderer: (object) => {
          return <Medium14Font>{object.citation}</Medium14Font>;
        },
      },
      {
        name: "key_sections",
        label: "SECTIONS",
        renderer: (object) => {
          return <Medium14Font>{object.key_sections}</Medium14Font>;
        },
      },
      {
        name: "status",
        label: "STATUS",
        renderer: (object) => {
          return <Medium14Font>{object.status}</Medium14Font>;
        },
      },
      {
        name: "reliefs",
        label: "RELIEFS",
        renderer: (object) => {
          if (
            (object.reliefs && object.reliefs.length) ||
            (object.proofs && object.proofs.length)
          ) {
            return (
              <ul>
                {[...object.reliefs, ...object.proofs].map((el, index) => (
                  <LiStyled key={index}>
                    <Medium14Font>{el.name}</Medium14Font>
                  </LiStyled>
                ))}
              </ul>
            );
          }
        },
      },
    ],
  };

  const navButtons = (
    <div className="d-flex">
      <ButtonNew
        style={{ marginRight: "10px" }}
        withIcon
        secondary
        clickHandler={onClickApplicationPapersHandler}
      >
        <Fragment>
          <Arrow className="mr-1" style={{ transform: "rotate(180deg)" }} />
          <Bold14Font textColor={theme.colors.blue}>
            Application Papers
          </Bold14Font>
        </Fragment>
      </ButtonNew>
      <ButtonNew
        withIcon
        secondary
        clickHandler={onClickReturnToApplicationHubHandler}
      >
        <Fragment>
          <Bold14Font textColor={theme.colors.blue}>
            Return to Application Hub
          </Bold14Font>
        </Fragment>
      </ButtonNew>
    </div>
  );

  switch (currentView) {
    case VIEW_SINGLE_FORM_AUTHORITIES:
      return (
        <Fragment>
          <div className="container-fluid">
            <div className="row">
              <Form
                className="d-flex justify-content-between"
                style={{ padding: "11px 0" }}
              >
                <Bold24Font
                  as="div"
                  className="d-flex justify-content-center"
                  style={{ width: "100%" }}
                >
                  Single Import
                </Bold24Font>
                <ButtonAnotherTry
                  tertiary
                  clickHandler={onClickBulkImportHandler}
                  className="d-flex align-items-center"
                >
                  <img
                    src={bulkIcon}
                    alt=""
                    style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                  />
                  <Bold14Font
                    style={{
                      color: theme.colors.blue,
                      whiteSpace: "nowrap",
                      marginRight: "30px",
                    }}
                  >
                    Bulk Import
                  </Bold14Font>
                </ButtonAnotherTry>
              </Form>
            </div>
          </div>
          <AddEditAuthorities
            isApplicationAuthoritiesFlow
            applicationAuthoritiesSelectedObject={
              applicationAuthoritiesSelectedObject
            }
            setApplicationAuthoritiesSelectedObject={
              setApplicationAuthoritiesSelectedObject
            }
            caseObject={caseObject}
            applicationObject={applicationObject}
            setApplicationObject={setApplicationObject}
            blockBlurEventParent={blockBlurEventParent}
            setApplicationAuthoritySubmitObject={
              setApplicationAuthoritySubmitObject
            }
          />
          <div className="row mb-4">
            <div className="col-12 d-flex flex-wrap justify-content-end">
              <div className="d-flex">
                <ButtonNew
                  secondary
                  style={{
                    marginBottom: width < 524 ? "8px" : "0",
                    marginRight: "10px",
                  }}
                  clickHandler={onCloseAuthority}
                  onMouseEnter={() => setBlockBlurEventParent(true)}
                  onMouseLeave={() => setBlockBlurEventParent(false)}
                >
                  Discard
                </ButtonNew>
                <ButtonNew
                  primary
                  style={{
                    marginBottom: width < 524 ? "8px" : "0",
                    // marginRight: "10px",
                  }}
                  clickHandler={onClickCloseFormHandler}
                >
                  Save Authority
                </ButtonNew>
              </div>
              {/*{navButtons}*/}
            </div>
          </div>
        </Fragment>
      );

    case VIEW_TABLE_AUTHORITIES:
      return (
        <Fragment>
          <AnimatedDiv className="container-fluid">
            {applicationObject.authorities.length ? (
              <Table
                settings={applicationAuthoritiesTable}
                data={setTagField(caseObject, applicationObject.authorities)}
                // data={applicationObject.authorities}
              />
            ) : (
              <NoRecords>
                <Bold18Font textColor={"#0f122f"}>
                  Your Application Authorities will appear here
                </Bold18Font>
              </NoRecords>
            )}
          </AnimatedDiv>
          <div className="row mb-4">
            <div className="col-12 d-flex flex-wrap justify-content-between">
              <ButtonNew
                primary
                style={{
                  marginBottom: width < 568 ? "8px" : "0",
                  marginRight: "10px",
                }}
                loading={addLoading}
                disabled={addLoading}
                clickHandler={onClickAddNewAuthorityHandler}
              >
                Add New Authority
              </ButtonNew>
              {navButtons}
            </div>
          </div>
        </Fragment>
      );

    case VIEW_BULK_IMPORT_AUTHORITIES:
      return (
        <EvidenceImport
          // key={keyValueToBulkImportApplicationsPapers}
          noTopLabelAndButtons
          createSingleElementHandler={onClickSingleEvidenceButtonHandler}
          applicationObject={applicationObject}
          changeApplicationPapersView={
            onClickReturnToApplicationsAuthoritiesHandler
          }
          isApplicationAuthoritiesBulkImportFlow
        />
      );
    default:
      return <Fragment />;
  }
};

export default withTheme(ApplicationAuthorities);
