import React, { Fragment, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { theme } from "../../../styled-components/Theme/Theme";
import addImage from "../../../assets/img/svg/addImage.svg";
import ButtonNew from "../../../components/buttons/Button/ButtonNew";
import InputNew from "../../../components/InputNew/InputNew";
import Label from "../../../components/forms/AddCardForm/Label";
import SelectNew from "../../../components/Select/SelectNew";
import {
  assistantRoleOption,
  createCountryOptionsList,
  roleOptions,
} from "../../../utils/constants";
import { updateProfile, updateUserImage } from "../../Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  MODAL_ERROR_SIGN_IN,
  MODAL_SUCCESS,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  UPDATE_USER,
  UPDATE_USER_PHOTO,
} from "../../../redux/types";
import AnimatedDiv from "../../../components/baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Form from "../../../components/forms/Form";
import PhoneInput from "react-phone-number-input";
import { maxFileSize } from "../../../utils";
import SelectAnotherTry from "../../../components/Select/SelectAnotherTry";

const UserForm = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [object, setObject] = useState({
    name: user.name,
    photo: user.photo,
    id: user.id,
    email: user.email,
    phone: user.phone,
    country: user.country,
    role: user.role,
  });
  const [name, setName] = useState(object.name);
  const [email, setEmail] = useState(object.email);
  const [role, setRole] = useState(object.role);
  const [phone, setPhone] = useState(object.phone);
  const [country, setCountry] = useState(object.country);
  const [file, setFile] = useState(null);
  const [selectRoleOptions, setSelectRoleOptions] = useState(roleOptions);

  useEffect(() => {
    if (user && user.role === "assistant") {
      setSelectRoleOptions((prevState) => [...prevState, assistantRoleOption]);
    }
  }, []);

  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          email,
        },
      };
    });
  }, [email]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          name,
        },
      };
    });
  }, [name]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          country,
        },
      };
    });
  }, [country]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          role,
        },
      };
    });
  }, [role]);
  useEffect(() => {
    setObject((prevState) => {
      return {
        ...prevState,
        ...{
          phone,
        },
      };
    });
  }, [phone]);
  const addFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };
  // const onClickHandler = () => {
  //   if (fileInput.current) {
  //     fileInput.current.click();
  //   }
  // };
  const handleFiles = (files) => {
    if (files.length) {
      let file = files[0];
      if (validateFile(file)) {
        if (file.size < maxFileSize) {
          setFile(file);
          toBase64(file).then((resp) => {
            setObject((prevState) => {
              return { ...prevState, ...{ photo: resp } };
            });
          });
        } else {
          dispatch({ type: SAVE_MODAL_DATA, payload: "File is to big" });
          dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
        }
      } else {
        dispatch({
          type: SAVE_MODAL_DATA,
          payload:
            "File format not supported at this time. Please try uploading in PDF, PNG or JPG file format.",
        });
        dispatch({ type: SHOW_MODAL, payload: MODAL_ERROR_SIGN_IN });
      }
    }
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    return validTypes.indexOf(file.type) !== -1;
  };
  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };
  const updateImage = async () => {
    if (file || !object.photo) {
      let formData = new FormData();
      formData.append("photo", object.photo ? file : null);
      let image = await updateUserImage(formData, dispatch);
      if (!image) {
        return false;
      }
      dispatch({ type: UPDATE_USER_PHOTO, payload: image.photo });
      return true;
    }
    return true;
  };
  const file_input = React.createRef();
  const submitForm = async (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let data_ = { ...object };
      if (!data_.phone) {
        data_.phone = "";
      }
      const updateProfileResponse = await updateProfile(data_, dispatch);
      const updateImageResponse = await updateImage();
      if (updateProfileResponse && updateImageResponse) {
        dispatch({ type: UPDATE_USER, payload: updateProfileResponse.data });
        dispatch({ type: SAVE_MODAL_DATA, payload: "All data updated!" });
        dispatch({ type: SHOW_MODAL, payload: MODAL_SUCCESS });
      }
      setIsSubmitting(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitForm(event);
    }
  };
  return (
    <Form title={"Profile"} style={{ overflow: "visible" }}>
      <AnimatedDiv
        className="row pl-4 pr-4 pb-4 pt-0"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={addFile}
      >
        <div className="mb-3">
          <label className="ml-3">
            <Avatar
              style={{
                cursor: "pointer",
                height: "122px",
                border: `1px solid ${theme.colors.gray}`,
              }}
              size={120}
              round={"120px"}
              name={object.name}
              src={object.photo ?? addImage}
            />
            <input
              ref={file_input}
              type="file"
              name="file"
              id="file"
              style={{ display: "none" }}
              onChange={() => {
                if (file_input.current) {
                  handleFiles(file_input.current.files);
                }
              }}
            />
          </label>
        </div>
        <div className="col-md-9 mb-3 d-flex align-items-center">
          {object.photo ? (
            <Fragment>
              <ButtonNew
                style={{ maxHeight: "50px" }}
                className="ml-4"
                primary
                clickHandler={() => {
                  if (file_input.current) {
                    file_input.current.click();
                  }
                }}
              >
                Upload New Picture
              </ButtonNew>
              <ButtonNew
                tyle={{ maxHeight: "50px" }}
                className="ml-4"
                delete_
                secondary
                clickHandler={() => {
                  setObject((prevState) => {
                    return { ...prevState, ...{ photo: null } };
                  });
                }}
              >
                Delete
              </ButtonNew>
            </Fragment>
          ) : null}
        </div>

        <div className="col-md-6 col-sm-12">
          <InputNew
            onKeyPress={handleKeyPress}
            required
            label={"First & Last Name"}
            onChangeHandler={(e) => {
              setName(e.target.value);
            }}
            value={object.name}
            placeholder={"Enter Name"}
            name={"name"}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Label className="row" name={"Role"} />
          <SelectAnotherTry
            options={selectRoleOptions}
            value={selectRoleOptions.find((v) => v.value === object.role)}
            // className={"ml-3 mr-3"}
            style={{ padding: "0" }}
            onChange={(selected_value) => {
              setRole(selected_value.value);
            }}
            placeholder={"Select Role"}
            // classNamePrefix="Select"
            isDisabled={user.role === assistantRoleOption.value}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <InputNew
            onKeyPress={handleKeyPress}
            label={"Email"}
            required
            disabled
            type={"email"}
            value={object.email}
            onChangeHandler={(e) => {
              setEmail(e.target.value);
            }}
            placeholder={"Enter Email"}
            name={"email"}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Label className="row" name={"Country"} />
          <SelectAnotherTry
            options={createCountryOptionsList()}
            value={createCountryOptionsList().find(
              (v) => v.value === object.country
            )}
            onChange={(selected_value) => {
              setCountry(selected_value.value);
            }}
            placeholder={"Select Country"}
            // className={"ml-3 mr-3"}
            // classNamePrefix="Select"
            style={{ padding: "0" }}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Label className="row" name={"Phone"} />
          <div className="pl-3 pr-3">
            <PhoneInput
              international
              placeholder="Enter your phone"
              value={phone}
              onChange={setPhone}
            />
          </div>
        </div>
        <div className="col-sm-12 d-flex justify-content-end mt-4">
          <ButtonNew
            secondary
            clickHandler={(e) => {
              e.preventDefault();
              setObject({
                name: user.name,
                photo: user.photo,
                id: user.id,
                email: user.email,
                phone: user.phone,
                country: user.country,
                role: user.role,
              });
            }}
          >
            Cancel
          </ButtonNew>
          <ButtonNew
            primary
            className="ml-2"
            loading={isSubmitting}
            disabled={isSubmitting}
            clickHandler={submitForm}
          >
            Save
          </ButtonNew>
        </div>
      </AnimatedDiv>
    </Form>
  );
};

export default UserForm;
