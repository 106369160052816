import React, { Fragment } from "react";
import { route } from "../../../routes";
import PageConfiguration from "../BreadCrumbs/PageConfiguration";
import planStarterIcon from "../../../assets/img3/svg/plan-starter-icon.svg";
import planMediumIcon from "../../../assets/img3/svg/plan-medium-icon.svg";
import planProIcon from "../../../assets/img3/svg/plan-pro-icon.svg";
import PlanCard from "../../PlanCard";
import {
  HIDE_MODAL,
  MODAL_ADD_CARD_ELEMENT,
  MODAL_CONFIRM_ELEMENT,
  MODAL_ERROR,
  MODAL_SUCCESS_SUBSCRIPTION_VERIFICATION,
  RESET_MODAL,
  SAVE_MODAL_DATA,
  SHOW_MODAL,
  UPDATE_USER,
} from "../../../redux/types";
import {
  getActiveSubscription,
  swapSubscription,
} from "../../../containers/Auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonNew from "../../buttons/Button/ButtonNew";
import ModalForm from "../../forms/AddCardForm/ModalForm";
import ModalCheckCard from "../../forms/AddCardForm/ModalCheckCard";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import { useStripe } from "@stripe/react-stripe-js";

const pageConfig = [
  {
    path: `${route.baseApp.subscription.plans}`,
    title: "Plans",
  },
];
const Prices = ({
  is_annually,
  plans,
  activeSubscription,
  setActiveSubscription,
  isLoading,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const user = useSelector((state) => state.auth.user);
  const Icons = [planStarterIcon, planMediumIcon, planProIcon];
  let plans_to_show, cost_type;
  if (is_annually) {
    plans_to_show = plans ? plans["annually"] : [];
    cost_type = "Year";
  } else {
    plans_to_show = plans ? plans["monthly"] : [];
    cost_type = "Month";
  }
  const history = useHistory();

  const checkUser = () => {};

  const afterCardSubmitted = async (user, el) => {
    dispatch({ type: UPDATE_USER, payload: user });
    dispatch({ type: HIDE_MODAL });
    setIsLoading(true);
    let resp = await swapSubscription(
      { product_id: el.product_id, price_id: el.price_id },
      dispatch
    );
    if (resp) {
      if (resp.result === "action_required") {
        let result = await stripe.confirmCardPayment(resp.secret, {
          payment_method: resp.payment_method,
        });
        if (result.error) {
          handleErrorMessage(result.error.message, dispatch);
          return;
        }
      }
      const activeSubscriptionResp = await getActiveSubscription(dispatch);
      if (activeSubscriptionResp) {
        await setActiveSubscription(activeSubscriptionResp.data);
      } else {
        await setActiveSubscription(el);
      }
      dispatch({ type: UPDATE_USER, payload: resp.user });
    }
    setIsLoading(false);
  };

  const showAddCardModal = (user, el) => {
    dispatch({ type: RESET_MODAL });
    dispatch({
      type: SAVE_MODAL_DATA,
      payload: (
        <ModalForm
          afterSubmit={(user) => afterCardSubmitted(user, el)}
          confirmButtonText="Subscribe"
        />
      ),
    });
    dispatch({ type: SHOW_MODAL, payload: MODAL_ADD_CARD_ELEMENT });
  };

  const onClickContinueCardHandler = async (plan) => {
    let resp = await swapSubscription(
      { product_id: plan.product_id, price_id: plan.price_id },
      dispatch
    );
    if (resp) {
      if (resp.result === "action_required") {
        let result = await stripe.confirmCardPayment(resp.secret, {
          payment_method: resp.payment_method,
        });
        if (result.error) {
          handleErrorMessage(result.error.message, dispatch);
          return;
        }
      }
      const activeSubscriptionResp = await getActiveSubscription(dispatch);
      if (activeSubscriptionResp) {
        await setActiveSubscription(activeSubscriptionResp.data);
      } else {
        await setActiveSubscription(plan);
      }
      dispatch({ type: UPDATE_USER, payload: resp.user });
    }
    dispatch({ type: HIDE_MODAL });
  };

  const changeSubscription = (plan) => {
    if (user.card_last_four || (plan.name.toLowerCase().indexOf('basic') !== -1 && !user.trial_ends_at)) {
      dispatch({
        type: SAVE_MODAL_DATA,
        payload: (
          <ModalCheckCard
            data={plan}
            onClickContinue={() => onClickContinueCardHandler(plan)}
            onClickUpdateCard={() => showAddCardModal(user, plan)}
          />
        ),
        // beforeCloseHandler: async () => {},
      });
      dispatch({ type: SHOW_MODAL, payload: MODAL_ADD_CARD_ELEMENT });
    } else {
      showAddCardModal(user, plan);
    }
  };
  return (
    <Fragment>
      <PageConfiguration configArray={pageConfig} />
      <div className="row p-3" style={{ marginBottom: "160px" }}>
        {plans_to_show.map((el, index) => (
          <div className="col-12 mb-3 col-xl-4 mb-xl-0" key={el.price_id}>
            <PlanCard
              checked={
                activeSubscription
                  ? el.price_id === activeSubscription.price_id
                  : false
              }
              features={el.features}
              activeSubscription={activeSubscription ?? null}
              icon={Icons[index]}
              cost={el.price}
              title={el.name}
              cost_type={cost_type}
              customButtonName={
                !activeSubscription
                  ? "Subscribe"
                  : el.features[0].value <
                      activeSubscription.features[0].value ||
                    activeSubscription.features[0].value === "unlimited"
                  ? "Downgrade"
                  : "Upgrade"
              }
              onBtnClick={() => {
                if (
                  activeSubscription &&
                  el.price_id !== activeSubscription.price_id
                ) {
                  changeSubscription(el, Icons[index]);
                } else if (!activeSubscription) {
                  changeSubscription(el, Icons[index]);
                }
              }}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Prices;
