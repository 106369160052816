import styled from "styled-components/macro";
import rem from "../../../../utils/rem";

const RoundButtonStyled = styled.button.attrs({ className: "btn" })`
  width: ${rem(`52px`)};
  height: ${rem(`52px`)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;

  :hover,
  :focus,
  :active {
    border: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: ${({ theme }) => theme.colors.light};
    outline: none;
  }
`;

export default RoundButtonStyled;
