import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const TestimonialCardAvatarPosition = styled.div`
  width: ${rem("82px")};
  position: absolute;
  top: ${rem("-35px")};
  left: ${rem("25px")};
`;

export default TestimonialCardAvatarPosition;
