import React, { Fragment, useEffect, useRef, useState } from "react";
import {Link, useHistory} from "react-router-dom";
import { withTheme } from "styled-components/macro";
import useWindowSize from "../../hooks/useWindowSize";
import rem from "../../utils/rem";
import { route } from "../../routes";

import LandingPageStyled from "./styled/LandingPageStyled";
import LpHeaderStyled from "./styled/LPHeaderStyled";
import LandingPageSectionStyled from "../../components/LandingPageSection";

import FeatureCard from "../../components/FeatureCard";

import welcomeBGEmblem from "../../assets/img3/svg/welcome-bg.svg";
import trialProoferImage from "../../assets/img2/landingPage/trialproofer-img.png";
import featuresSectionIcon from "../../assets/img3/svg/features-section-icon.svg";
import featureBuildCaseIcon from "../../assets/img3/svg/feature-build-case-icon.svg";
import featureSmartTaggingIcon from "../../assets/img3/svg/feature-smart-tagging-icon.svg";
import featureBirdsEyeViewIcon from "../../assets/img3/svg/feature-birds-eye-view-icon.svg";
import featureWitnessProofsIcon from "../../assets/img3/svg/feature-witness-proofs-icon.svg";
import aboutUsIcon from "../../assets/img3/svg/about-us-icon.png";
import logoInSquare from "../../assets/img3/svg/logo-in-square-beta.svg";
import planStarterIcon from "../../assets/img3/svg/plan-starter-icon.svg";
import planMediumIcon from "../../assets/img3/svg/plan-medium-icon.svg";
import planProIcon from "../../assets/img3/svg/plan-pro-icon.svg";
import testimonialsIcon from "../../assets/img3/svg/testimonials-icon.png";
import contactUsPhoneIcon from "../../assets/img3/svg/contact-us-phone-icon.svg";
import contactUsMailIcon from "../../assets/img3/svg/contact-us-mail-icon.svg";
import emblem from "../../assets/img3/svg/trialproofer-emblem.svg";

import {
  Bold14Font,
  Bold18Font,
  Bold60Font,
  Bold90Font,
  Medium14Font,
  Medium18Font,
} from "../../components/Fonts/Fonts";
import PlanCard from "../../components/PlanCard";
import LandingPageSocialLink from "../../components/LandingPageSocialLink";
import FeedbackForm from "../../components/forms/FeedbackForm";
import LandingPageFooterStyled from "./styled/LandingPageFooterStyled";
import ImgWidthWrapperStyled from "../../components/helpWrappers/ImgWidthWrapperStyled";
import LandingPageCarousel from "../../components/LandingPageCarousel";
import SignButtonStyled from "../../components/buttons/Button/customStyled/SignButtonStyled";
import Button from "../../components/buttons/Button";
import RoundButton from "../../components/buttons/RoundButton";
import playIcon from "../../assets/img3/svg/play-icon.svg";
import PlayButtonStyled from "../../components/buttons/RoundButton/customStyled/PlayButtonStyled";
import ToggleButton from "../../components/buttons/ToggleButton/ToggleButton";
import SubscribeForm from "../../components/forms/SubscribeForm";
import PulseLoader from "react-spinners/PulseLoader";
import {
  getAdminTestimonials,
  getSubscriptions,
  getTestimonials,
} from "../Auth/auth";
import LandingPageSignBtnsWrapperStyled from "./styled/LandingPageSignBtnsWrapperStyled";
import LandingPageFooterSocialLinkWrapperStyled from "./styled/LandingPageFooterSocialLinkWrapperStyled";
import LandingPageFooterEmblemWrapperStyled from "./styled/LandingPageFooterEmblemWrapperStyled";
import { useDispatch, useSelector } from "react-redux";
import {HIDE_COOKIE_MESSAGE, SAVE_SUBSCRIPTION} from "../../redux/types";
import LandingPageNavBar from "../../components/LandingPageNavBar";
import FullScreenBarLoader from "../../components/FullScreenBarLoader/FullScreenBarLoader";

const features = [
  {
    id: 1,
    icon: featureBuildCaseIcon,
    title: "Build Case",
    description:
      "Plot out your case forensically using the TrialProofer’s unique Build Mode to address all the elements in a cause of action",
  },
  {
    id: 2,
    icon: featureSmartTaggingIcon,
    title: "Smart Tagging",
    description:
      "Use our smart-tagging function to navigate items of Evidence by Theme, Element or Witness",
  },
  {
    id: 3,
    icon: featureBirdsEyeViewIcon,
    title: "Birds Eye View",
    description:
      "Analyse at a glance how all the strands of the case piece together",
  },
  {
    id: 4,
    icon: featureWitnessProofsIcon,
    title: "Witness Proofs",
    description:
      "Use TrialProofer’s unique trial aids to master your witness examination",
  },
];
const padding = "450px 0 160px 0";

// TODO: BUG width 337px => 336px. Appear horizontal scroll bar.
const LandingPage2 = ({ theme }) => {
  const [plans, setPlans] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [checkedPrice, setCheckedPrice] = useState("");
  const [is_annually, setIsAnnually] = useState(false);
  const Icons = [planStarterIcon, planMediumIcon, planProIcon];
  const { width, height } = useWindowSize();
  const [welcomeFontSize, setWelcomeFontSize] = useState({});
  const [testimonials, setTestimonies] = useState([]);
  const [isRowShowed, setIsRowShowed] = useState(true);
  const isUserAuth = useSelector((state) => state.auth.isAuth);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const dispatch = useDispatch();
  let header = useRef(null);
  const loadData = async () => {
    setLoadingPage(true);
    const resp = await getTestimonials(dispatch);
    setTestimonies(resp);
    setLoadingPage(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  let sectionsStart = [
    useRef(null), // Welcome section
    useRef(null), // Features section
    useRef(null), // About Us section
    useRef(null), // Plans section
    useRef(null), // Testimonials section
    useRef(null), // Contact Us section
  ];

  let sectionsEnd = [
    useRef(null), // Welcome section
    useRef(null), // Features section
    useRef(null), // About Us section
    useRef(null), // Plans section
    useRef(null), // Testimonials section
    useRef(null), // Contact Us section
  ];
  // sectionsEnd[0] = sectionsStart[0];

  useEffect(() => {
    getSubscriptions()
      .then((resp) => {
        setPlans(resp);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);

  const handleClick = (price, icon) => {
    price.icon = icon;
    setCheckedPrice(price);
  };

  const plansGetStartedBtnClickHandler = (price, icon) => {
    price.icon = icon;
    setCheckedPrice(price);
    dispatch({ type: SAVE_SUBSCRIPTION, payload: price });
    if (isUserAuth && user["email_verified_at"]) {
      history.push(route.subscription.step2);
    } else if (isUserAuth && !user["email_verified_at"]) {
      history.push(route.auth.confirmEmail);
    } else {
      history.push(route.auth.signUp);
    }
  };

  const handleChange = (event) => {
    setIsAnnually(event.target.checked);
  };

  let plans_to_show, cost_type;

  if (is_annually) {
    plans_to_show = plans ? plans["annually"] : [];
    cost_type = "Year";
  } else {
    plans_to_show = plans ? plans["monthly"] : [];
    cost_type = "Month";
  }

  const welcomeSectionText = (
    <Fragment>
      <Medium18Font as="p" textColor={theme.colors.whiteOpacity}>
        The innovative trial preparation tool for Lawyers.
      </Medium18Font>
      <Medium18Font as="p" textColor={theme.colors.whiteOpacity}>
        TrialProofer represents a state-of-the-art, visually intuitive mode of
        trial preparation, designed to help trial lawyers rapidly manage, locate
        and retrieve the key information necessary for effective trial advocacy.
      </Medium18Font>
    </Fragment>
  );

  const welcomeSectionButtons = (
    // <div className="d-flex align-items-center" style={{ width: rem("240px") }}>
    <div className="d-flex align-items-center" style={{ width: "100%" }}>
      <Button
        primary
        clickHandler={() => history.push(route.auth.signUp)}
        style={{ marginRight: "30px" }}
      >
        <span style={{ color: theme.colors.white }}>Sign Up</span>
      </Button>
      {/*<RoundButton customStyle={PlayButtonStyled}>*/}
      {/*  <img src={playIcon} alt="" className="img-fluid" />*/}
      {/*</RoundButton>*/}
      {/*<span className="custom-fs">*/}
      {/*  <Bold18Font textColor={theme.colors.whiteOpacity}>*/}
      {/*    Play Video*/}
      {/*  </Bold18Font>*/}
      {/*</span>*/}
    </div>
  );

  useEffect(() => {
    // Fixing welcome message splitting to three rows
    if (width < 1516 && width > 1199) {
      setWelcomeFontSize({ fontSize: "4rem" });
    } else {
      setWelcomeFontSize({});
    }

    // Change welcome section layout
    if (width < 1501 && width > 1199) {
      setIsRowShowed(false);
    } else {
      setIsRowShowed(true);
    }

    // Change font size on mobile devices:
    if (width < 576) {
    }
  }, [width, isRowShowed]);

  const signBtns = (
    <Fragment>
      <Button
        customStyle={SignButtonStyled}
        href={route.auth.signIn}
        // clickHandler={() => history.push(route.auth.signIn)}
        className="mr-3"
      >
        Sign In
      </Button>
      <Button
        customStyle={SignButtonStyled}
        href={route.auth.signUp}
        // clickHandler={() => history.push(route.auth.signUp)}
      >
        Sign Up
      </Button>
    </Fragment>
  );

  const redirectToAppBtn = (user) => {
    if (Object.keys(user).length) {
      if (user.is_admin) {
        return (
          <Fragment>
            <Button
              customStyle={SignButtonStyled}
              clickHandler={() => history.push(route.admin.baseApp.app)}
              className="mr-3"
            >
              Go to App
            </Button>
            <Button
              customStyle={SignButtonStyled}
              clickHandler={() => history.push(route.auth.logOut)}
            >
              Log Out
            </Button>
          </Fragment>
        );
      } else if (user.email_verified_at) {
        if (user.trial_ends_at || user.features.length) {
          // Redirect to dashboard\last viewed page
          return (
            <Fragment>
              <Button
                customStyle={SignButtonStyled}
                clickHandler={() => history.push(route.baseApp.app)}
                className="mr-3"
              >
                Go to App
              </Button>
              <Button
                customStyle={SignButtonStyled}
                clickHandler={() => history.push(route.auth.logOut)}
              >
                Log Out
              </Button>
            </Fragment>
          );
        } else {
          return (
            // Redirect to subscription flow
            <Fragment>
              <Button
                customStyle={SignButtonStyled}
                clickHandler={() => history.push(route.subscription.step2)}
                className="mr-3"
              >
                Start trial
              </Button>
              <Button
                customStyle={SignButtonStyled}
                clickHandler={() => history.push(route.auth.logOut)}
              >
                Log Out
              </Button>
            </Fragment>
          );
        }
      } else {
        return (
          <Fragment>
            <Button
              customStyle={SignButtonStyled}
              clickHandler={() => history.push(route.auth.confirmEmail)}
              className="mr-3"
            >
              Confirm e-mail
            </Button>
            <Button
              customStyle={SignButtonStyled}
              clickHandler={() => history.push(route.auth.logOut)}
            >
              Log Out
            </Button>
          </Fragment>
        );
      }
    }
  };

  if (isUserAuth) {
    if (is_admin) {
      history.push(route.admin.baseApp.dashboard);
    } else {
      history.push(route.baseApp.dashboard);
    }
  }
  if (loadingPage) {
    return <FullScreenBarLoader />;
  }
  return (
    <LandingPageStyled windowWidth={width}>
      <LpHeaderStyled ref={header}>
        <div className="container-fluid h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 h-100 d-flex justify-content-between align-items-center px-5">
              {/*<LandingPageHeaderImgWrapperStyled>*/}
              {/*  <Button*/}
              {/*    tertiary*/}
              {/*    clickHandler={() =>*/}
              {/*      window.scrollTo({ top: 0, behavior: "smooth" })*/}
              {/*    }*/}
              {/*  >*/}
              {/*    <img src={logo} alt="" className="img-fluid" />*/}
              {/*  </Button>*/}
              {/*</LandingPageHeaderImgWrapperStyled>*/}
              <LandingPageNavBar
                testimonials={testimonials}
                refsObject={{
                  startSections: sectionsStart,
                  endSections: sectionsEnd,
                }}
                header={header}
              />
              <LandingPageSignBtnsWrapperStyled>
                {isUserAuth ? redirectToAppBtn(user) : signBtns}
              </LandingPageSignBtnsWrapperStyled>
            </div>
          </div>
        </div>
      </LpHeaderStyled>
      <main>
        {/* Welcome section */}
        <LandingPageSectionStyled
          background={theme.colors.dark}
          first={height > 900}
          padding="102px 100px 100px 100px"
          zIndex={70}
          ref={sectionsStart[0]}
          // TODO: Re-implement first section 100 height of viewport
          // style={{ height: "calc(-0.025rem + 100vh)" }}
        >
          <div className="container-fluid p-5">
            <div className="row justify-content-around justify-content-lg-center">
              <div
                className="col-xl-5 col-lg-6"
                style={{
                  background: `url(${welcomeBGEmblem}) 0 0/427px auto no-repeat`,
                }}
              >
                <div className="row">
                  <div className="col pt-xl-5">
                    <Bold90Font
                      textColor={theme.colors.white}
                      style={welcomeFontSize}
                    >
                      Welcome to TrialProofer
                    </Bold90Font>
                  </div>
                </div>
                <div className={`row ${isRowShowed ? "" : "d-none"}`}>
                  <div className="col-12 col-sm-9">{welcomeSectionText}</div>
                </div>
                <div className={`row ${isRowShowed ? "" : "d-none"}`}>
                  <div className="col">{welcomeSectionButtons}</div>
                </div>
              </div>
              <div className="col-lg-5 col-xl-6">
                <img
                  src={trialProoferImage}
                  alt="TrialProofer App"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className={`row ${isRowShowed ? "d-none" : ""}`}>
              <div className="col">{welcomeSectionText}</div>
            </div>
            <div className={`row ${isRowShowed ? "d-none" : ""}`}>
              <div className="col">{welcomeSectionButtons}</div>
            </div>
          </div>
          <div ref={sectionsEnd[0]} />
        </LandingPageSectionStyled>
        {/* Features section */}
        <LandingPageSectionStyled
          padding={padding}
          background={theme.colors.lightGray}
          zIndex={60}
          // ref={sectionsEnd[1]}
        >
          <div className="container-fluid" style={{ maxWidth: rem(`1170px`) }}>
            <div className="row justify-content-center mb-5">
              <div className="col-2 d-flex flex-column justify-content-center align-items-center">
                <img
                  src={featuresSectionIcon}
                  alt=""
                  style={{ width: rem("35px") }}
                  ref={sectionsStart[1]}
                />
                <Bold60Font textColor={theme.colors.dark}>Features</Bold60Font>
              </div>
            </div>
            <div className="row justify-content-center align-items-start">
              {features.map((el) => (
                <div
                  className="col-12 col-sm-6 col-md-3 d-flex justify-content-center mb-5 mb-md-0"
                  key={el.id}
                >
                  <FeatureCard
                    description={el.description}
                    title={el.title}
                    icon={el.icon}
                  />
                </div>
              ))}
            </div>
          </div>
          <div ref={sectionsEnd[1]} />
        </LandingPageSectionStyled>
        {/* About Us section */}
        <LandingPageSectionStyled
          // padding={padding}
          padding={padding}
          background={theme.colors.white}
          zIndex={50}
          // ref={sectionsEnd[2]}
        >
          <div className="container-fluid" style={{ maxWidth: "1170px" }}>
            <div className="row justify-content-center justify-content-md-between">
              <div className="col-12 mb-5 col-md-6 mb-md-0 d-flex justify-content-center justify-content-md-start">
                <img
                  src={logoInSquare}
                  alt="Man"
                  className="img-fluid"
                  ref={sectionsStart[2]}
                />
              </div>
              <div className="col-12 col-sm-9 col-md-5 d-flex flex-column justify-content-center justify-content-md-start">
                <div className="d-flex flex-column align-items-center align-items-md-start">
                  <img
                    src={aboutUsIcon}
                    alt=""
                    style={{ width: rem("35px") }}
                  />
                  <Bold60Font>About Us</Bold60Font>
                </div>
                <Medium18Font as="p" className="landing-page__about-us-text">
                  At TrialProofer, we’re dedicated to the future of justice. We
                  believe excellence in advocacy is key to a justice to be proud
                  of. By providing trial lawyers with the best tools of the
                  trade, Trial Proofer allows you to focus on deliver optimum
                  outcomes for clients.
                </Medium18Font>
                <Medium18Font as="p" className="landing-page__about-us-text">
                  Trial proofer is designed in Ireland by lawyers specifically
                  for lawyers in common law systems. We always put the interests
                  of justice first.
                </Medium18Font>
                <div>
                  <Button
                    primary
                    clickHandler={() => history.push(route.auth.signUp)}
                  >
                    Try it FREE
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div ref={sectionsEnd[2]} />
        </LandingPageSectionStyled>
        {/* Plans section */}
        <LandingPageSectionStyled
          // padding={padding}
          padding={padding}
          background={theme.colors.lightGray}
          zIndex={40}
          // ref={sectionsEnd[3]}
        >
          <div className="container-fluid" style={{ maxWidth: "1170px" }}>
            <div className="row mb-3">
              <div className="col-12 col-sm">
                <Bold60Font ref={sectionsStart[3]}>Plans</Bold60Font>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 d-flex justify-content-center mb-4 col-sm justify-content-sm-start mb-sm-0">
                <Medium18Font>
                  Choose the plan that’s right for you. Downgrade or upgrade at
                  any time.
                </Medium18Font>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3 m-auto">
                <Medium14Font
                  style={{ lineHeight: "25px" }}
                  className="d-block mr-5 mr-sm-5 mr-md-2"
                >
                  Monthly
                </Medium14Font>
                <div className="mt-3">
                  <ToggleButton
                    checked={is_annually}
                    onChange={handleChange}
                    className="d-block mx-2"
                  />
                </div>
                <Medium14Font
                  style={{ lineHeight: "25px" }}
                  className="d-block ml-5 ml-sm-5 ml-md-2"
                >
                  Annually
                </Medium14Font>
              </div>
            </div>
            <div
              className={loading ? "container-fluid h-75" : "container-fluid"}
              style={{ maxWidth: "1170px" }}
            >
              {loading ? (
                <div className="h-100 justify-content-center align-items-center d-flex">
                  <PulseLoader size={30} color={theme.colors.blue} />
                </div>
              ) : (
                <div className="row mb-5">
                  {plans_to_show.map((el, index) => (
                    <div
                      className="col-12 mb-3 col-sm-4 mb-sm-0"
                      key={el.price_id}
                    >
                      <PlanCard
                        // onClick={() => {
                        //   handleClick(el, Icons[index]);
                        // }}
                        checked={el.price_id === checkedPrice.price_id}
                        features={el.features}
                        icon={Icons[index]}
                        cost={el.price}
                        title={el.name}
                        cost_type={cost_type}
                        // noButton
                        onBtnClick={() =>
                          plansGetStartedBtnClickHandler(el, Icons[index])
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div ref={sectionsEnd[3]} />
        </LandingPageSectionStyled>
        {/* Testimonials section */}
        {testimonials.length ? (
          <LandingPageSectionStyled
            padding={padding}
            background={theme.colors.white}
            zIndex={30}
            // ref={sectionsEnd[4]}
          >
            <div className="container-fluid" style={{ maxWidth: "1170px" }}>
              <div className="row mb-5">
                <div className="col d-flex flex-column align-items-center">
                  <img
                    src={testimonialsIcon}
                    alt=""
                    style={{ width: rem("37px") }}
                    ref={sectionsStart[4]}
                  />
                  <Bold90Font>Testimonials</Bold90Font>
                </div>
              </div>
              <div className="row">
                <div
                  className="col"
                  style={{ paddingLeft: "14px", paddingRight: "12px" }}
                >
                  <LandingPageCarousel testimonials={testimonials} />
                </div>
              </div>
            </div>
            <div ref={sectionsEnd[4]} />
          </LandingPageSectionStyled>
        ) : null}
        m{/* Contact Us section */}
        <LandingPageSectionStyled
          padding={padding}
          background={theme.colors.blue}
          zIndex={20}
          // ref={sectionsEnd[5]}
        >
          <div className="container-fluid" style={{ maxWidth: rem("1170px") }}>
            <div className="row">
              <div className="col-12 d-flex flex-column justify-content-center mb-5 col-sm-6 mb-sm-0">
                <div className="row mb-5">
                  <div className="col-12 col-sm-10">
                    <Bold60Font
                      textColor={theme.colors.white}
                      ref={sectionsStart[5]}
                    >
                      Let’s Advocate Together
                    </Bold60Font>
                  </div>
                </div>
                {/*<div className="row">*/}
                {/*  <div className="col-12 d-flex justify-content-between align-items-start">*/}
                {/*    <div className="d-flex">*/}
                {/*      <ImgWidthWrapperStyled width={24} className="mr-2">*/}
                {/*        <img*/}
                {/*          src={contactUsPhoneIcon}*/}
                {/*          alt=""*/}
                {/*          className="img-fluid"*/}
                {/*        />*/}
                {/*      </ImgWidthWrapperStyled>*/}
                {/*      <div>*/}
                {/*        <Medium14Font*/}
                {/*          as="p"*/}
                {/*          textColor={theme.colors.whiteOpacity}*/}
                {/*        >*/}
                {/*          + 123 223 2332*/}
                {/*        </Medium14Font>*/}
                {/*        <Medium14Font*/}
                {/*          as="p"*/}
                {/*          textColor={theme.colors.whiteOpacity}*/}
                {/*        >*/}
                {/*          + 123 223 2332*/}
                {/*        </Medium14Font>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="row mb-5">
                  <div className="col-12 d-flex justify-content-between align-items-start">
                    <div className="d-flex" style={{ minWidth: rem("248px") }}>
                      <ImgWidthWrapperStyled width={24} className="mr-2">
                        <img
                          src={contactUsMailIcon}
                          alt=""
                          className="img-fluid"
                        />
                      </ImgWidthWrapperStyled>

                      <a href="mailto:support@trialproofer.ie">
                        <Medium14Font textColor={theme.colors.whiteOpacity}>
                          support@trialproofer.ie
                        </Medium14Font>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex">
                    {/*<LandingPageSocialLink href="https://instagram.com">*/}
                    {/*  <span className="icon-social-instagram-icon" />*/}
                    {/*</LandingPageSocialLink>*/}
                    <LandingPageSocialLink href="https://twitter.com/trialproofer">
                      <span className="icon-social-tweeter-icon" />
                    </LandingPageSocialLink>
                    {/*<LandingPageSocialLink href="https://facebook.com">*/}
                    {/*  <span className="icon-social-facebook-icon" />*/}
                    {/*</LandingPageSocialLink>*/}
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center col-sm-6">
                <FeedbackForm />
              </div>
            </div>
          </div>
          <div ref={sectionsEnd[5]} />
        </LandingPageSectionStyled>
      </main>
      <LandingPageFooterStyled>
        <div className="container-fluid">
          <div className="row footer__border-divider">
            <div className="col">
              <div className="container-fluid" style={{ maxWidth: "1170px" }}>
                <div className="row align-items-center pb-4">
                  <div className="col-12 d-flex flex-column align-items-center mb-4 col-sm-6 align-items-sm-start mb-sm-0">
                    <Bold18Font
                      textColor={theme.colors.white}
                      className="landing-page__footer-subscribe-text"
                    >
                      Subscribe to our newsletter
                    </Bold18Font>
                    <Bold14Font textColor={theme.colors.whiteOpacity}>
                      A monthly digest of the latest news, articles, and
                      resources.
                    </Bold14Font>
                  </div>
                  <div className="col-12 d-flex justify-content-center col-sm-6 justify-content-sm-end">
                    <SubscribeForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row footer__border-divider">
            <div className="col">
              <div className="container-fluid" style={{ maxWidth: "1170px" }}>
                <div className="row align-items-center py-4 footer__border-divider">
                  <div className="col">
                    <LandingPageFooterEmblemWrapperStyled>
                      <img src={emblem} alt="" />
                    </LandingPageFooterEmblemWrapperStyled>
                  </div>
                  <div className="col d-flex justify-content-end">
                    <LandingPageFooterSocialLinkWrapperStyled>
                      {/*<LandingPageSocialLink footer href="http://instagram.com">*/}
                      {/*  <span className="icon-social-instagram-icon" />*/}
                      {/*</LandingPageSocialLink>*/}
                      <LandingPageSocialLink
                        footer
                        href="https://twitter.com/trialproofer"
                      >
                        <span className="icon-social-tweeter-icon" />
                      </LandingPageSocialLink>
                      {/*<LandingPageSocialLink footer href="https://facebook.com">*/}
                      {/*  <span className="icon-social-facebook-icon" />*/}
                      {/*</LandingPageSocialLink>*/}
                    </LandingPageFooterSocialLinkWrapperStyled>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="container-fluid" style={{ maxWidth: "1170px" }}>
                <div className="row py-4">
                  <div className="col-12 order-last mb-3 col-sm order-sm-first mb-sm-0 d-flex justify-content-center justify-content-sm-start align-items-center">
                    <Medium14Font textColor={theme.colors.whiteOpacity}>
                      (c) TrialProofer. All right reserved.
                    </Medium14Font>
                  </div>
                  <div className="col-12 mb-3 col-sm mb-sm-0 d-flex justify-content-center align-items-center justify-content-sm-end">
                    <div
                      className="d-block d-sm-flex justify-content-sm-between"
                    >
                      <Button
                        as="a"
                        href={`${route.termsAndConditions}`}
                        tertiary
                      >
                        <Medium14Font textColor={theme.colors.whiteOpacity}>
                          Terms & Conditions
                        </Medium14Font>
                      </Button>
                      <Button as="a" href={`${route.privacyPolicy}`} tertiary>
                        <Medium14Font textColor={theme.colors.whiteOpacity}>
                          Privacy Policy
                        </Medium14Font>
                      </Button>
                      <Button as="a" href={`${route.cookiesPolicy}`} tertiary>
                        <Medium14Font textColor={theme.colors.whiteOpacity}>
                          Cookies Policy
                        </Medium14Font>
                      </Button>
                      <Button
                        as="a"
                        href={`${route.securityStatement}`}
                        tertiary
                      >
                        <Medium14Font textColor={theme.colors.whiteOpacity}>
                          Security Statement
                        </Medium14Font>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LandingPageFooterStyled>
    </LandingPageStyled>
  );
};

export default withTheme(LandingPage2);
