import React, { Fragment, useEffect, useRef, useState } from "react";
import AnimatedDiv from "../../baseAppComponents/BaseAppLayout/styled/AnimatedDiv";
import Label from "../AddCardForm/Label";
import ListAddButton from "../../buttons/ListAddButton/ListAddButton";
import { HIDE_RIGHT_SIDE_BAR } from "../../../redux/types";
import { useDispatch } from "react-redux";
import TextAreaSideBar from "./TextAreaSideBar";
import EditableStringClassComponent from "../CasesForms/EditableStringClassComponent";
import ButtonNew from "../../buttons/Button/ButtonNew";
import { updateEvidence } from "../../../containers/Auth/auth";
import { DividerHorizontal } from "../../dividers/DividerHorizontal";

const EvidenceSubEvidenceForm = ({
  evidenceObject,
  setEvidenceObject,
}) => {
  const [evidences, setEvidences] = useState(evidenceObject.sub_evidence);
  const [changes, setChanges] = useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    const clone = [...evidences];
    Object.keys(changes).forEach(id => {
      const item = clone.find(v => v.id === id);
      item.label = changes[id];
    })
    const submitObject = {
      sub_evidence: clone,
    };
    setEvidenceObject(submitObject);
    dispatch({ type: HIDE_RIGHT_SIDE_BAR });
  };
  const dispatch = useDispatch();

  const doneEditingCallback = (id, name) => {
    const clone = {...changes};
    clone[id] = name;
    setChanges(clone);
  };

  return (
    <Fragment>
      <div style={{ minHeight: "92px", height: "100%", overflowY: "auto" }}>
        <div className="form-group">
          {evidences && evidences.length ? (
            <Fragment>
              {evidences.map((sub_t, i) => (
                <AnimatedDiv key={i} className="form-group pl-3">
                  <EditableStringClassComponent
                    doneEditingCallback={doneEditingCallback}
                    objective={true}
                    isObjectString={true}
                    editState={sub_t.label === ""}
                    object={changes[sub_t.id] ?? sub_t.label}
                    index={sub_t.id}
                  />
                </AnimatedDiv>
              ))}
            </Fragment>
          ) : null}
        </div>
      </div>

      <DividerHorizontal />
      <div className="form-group">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <ButtonNew
              type="submit"
              // clickHandler={handleSubmit}
              wide
              primary
            >
              Save
            </ButtonNew>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EvidenceSubEvidenceForm;
