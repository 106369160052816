import React from "react";
import RsbTabsMenuItemStyled from "./styled/RsbTabsMenuItemStyled";

import { Bold14Font } from "../FontsNewComponent/Fonts";

const RsbTabsMenu = ({ tabs, activeMenuTab, setActiveMenuTab }) => {
  const onClickTabItemHandler = (index) => {
    setActiveMenuTab(index);
  };

  return (
    <div
      className="container-fluid d-flex flex-wrap"
      // style={{ marginBottom: "50px" }}
    >
      {tabs.map((tab, index) => (
        <RsbTabsMenuItemStyled
          key={index}
          checked={activeMenuTab === index}
          onClick={() => onClickTabItemHandler(index)}
        >
          <Bold14Font style={{ color: "inherit" }}>{tab.title}</Bold14Font>
        </RsbTabsMenuItemStyled>
      ))}
    </div>
  );
};

export default RsbTabsMenu;
