import styled from "styled-components/macro";
import rem from "../../../utils/rem";

const BadgeCasesMenuStyled = styled.div`
  width: ${rem(`52px`)};
  height: ${rem(`43px`)};
  border-radius: ${rem(`21px`)};
  border: 1px solid ${({ theme }) => theme.colors.gray};

`;

export default BadgeCasesMenuStyled;
