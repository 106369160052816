import React from "react";

import SignPagesWrapper from "../../../components/SignPagesWrapper";
import EnterNewPasswordForm from "../../../components/forms/EnterNewPasswordForm";

const EnterNewPasswordPage = () => {
  return (
    <SignPagesWrapper>
      <div className="container-fluid">
        <div className="row">
          <div className="col d-flex flex-column justify-content-between">
            <div className="row">
              <div className="col">
                <EnterNewPasswordForm />
              </div>
            </div>
            <div className="row" />
            <div className="row">&nbsp;</div>
          </div>
        </div>
      </div>
    </SignPagesWrapper>
  );
};

export default EnterNewPasswordPage;
