import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components/macro";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_MODAL, UPDATE_USER } from "../../../redux/types";
import { handleErrorMessage } from "../../Modal/modalErrorHandler";
import { addCard, createStripeIntent } from "../../../containers/Auth/auth";
import FormHeader from "./styled/FormHeader";
import { Bold14Font, Bold18Font } from "../../FontsNewComponent/Fonts";
import Label from "./Label";
import InputStyled from "../../Input/styled/InputStyled";
import Button from "../../buttons/Button/Button";
import errorIcon from "../../../assets/img3/svg/error-icon.svg";
import { regUserCardFirstAndLastName } from "../../../utils";

const ModalForm = ({ afterSubmit, confirmButtonText, theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState("");
  const [doneElements, setDoneElements] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    name: false,
  });
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [fistNameLastName, setFirstNameLastName] = useState("");
  const card_last_four = useSelector((state) => state.auth.user.card_last_four);
  const close = () => {
    dispatch({ type: HIDE_MODAL });
  };
  const changeNameHandler = (value) => {
    setFirstNameLastName(value);
    doneElements["name"] = value !== "";
    setDoneElements(doneElements);
  };
  const elements = useElements();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const CardNumber = elements.getElement(CardNumberElement);
    setIsLoading(true);
    if (!regUserCardFirstAndLastName.test(fistNameLastName)) {
      setShowErrors("Please enter valid First & Last Name");
      setIsLoading(false);
      return;
    }
    setShowErrors("");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: CardNumber,
      billing_details: {
        name: fistNameLastName,
      },
    });
    if (error) {
      setIsLoading(false);
      setShowErrors(error.message);
      // handleErrorMessage(error.message, dispatch);
      return;
    }
    try {
      let intent_response = await createStripeIntent({
        payment_method: paymentMethod.id,
      });
      if (
        intent_response.data &&
        intent_response.data.intent &&
        intent_response.data.intent.status === "requires_confirmation"
      ) {
        stripe
          .confirmCardSetup(intent_response.data.intent.client_secret, {
            payment_method: intent_response.data.intent.payment_method,
          })
          .then(function (result) {
            if (result.error) {
              setIsLoading(false);
              handleErrorMessage(result.error.message, dispatch);
              return;
            } else {
              if (result.setupIntent.status === "succeeded") {
                addCard_(paymentMethod);
              }
            }
          });
      } else {
        addCard_(paymentMethod);
      }
    } catch (e) {
      setIsLoading(false);
      handleErrorMessage(e.message, dispatch);
      return;
    }
  };
  const addCard_ = async (paymentMethod) => {
    let resp = await addCard(
      {
        card: paymentMethod.id,
      },
      dispatch,
      true
    );
    if (resp && !(resp.response && resp.response.data.result === "error")) {
      afterSubmit(resp.user);
    } else {
      setShowErrors(resp.response.data.messages);
    }
    setIsLoading(false);
  };
  const createOptions = {
    style: {
      base: {
        letterSpacing: "0.025em",
        top: "5px",
        fontFamily: "PT Root UI, sans-serif",
        "::placeholder": {
          color: theme.colors.darkOpacity,
          fontFamily: "PT Root UI, sans-serif",
          fontSize: "14px",
          fontWight: 500,
        },
      },
      invalid: {
        color: theme.colors.burgundy,
      },
    },
  };
  const handleChange = (change) => {
    doneElements[change.elementType] = change.complete && !change.error;
    setDoneElements(doneElements);
  };

  return (
    <Fragment>
      <FormHeader className="d-flex align-items-center mb-0 pl-5">
        <Bold18Font>{card_last_four ? "Update Card" : "Add Card"}</Bold18Font>
      </FormHeader>
      <div className="p-3">
        <form onSubmit={handleSubmit}>
          <fieldset className={"form-vertical"}>
            {showErrors ? (
              <div className="alert alert-danger d-flex">
                <img
                  src={errorIcon}
                  alt=""
                  className="align-self-baseline mr-2"
                />
                <Bold14Font textColor={theme.colors.burgundy} as="p">
                  {showErrors}
                </Bold14Font>
              </div>
            ) : null}
            <div className="form-group">
              <Label name={"First & Last Name"} />
              <div className="pl-2">
                <InputStyled
                  type={"text"}
                  value={fistNameLastName}
                  onChange={(e) => {
                    changeNameHandler(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <Label name={"Card Number"} />
              <div className="pl-2">
                <CardNumberElement onChange={handleChange} {...createOptions} />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <Label name={"Expiration Date"} />
                <div className="pl-2">
                  <CardExpiryElement
                    onChange={handleChange}
                    {...createOptions}
                  />
                </div>
              </div>
              <div className="col-6">
                <Label name={"Security Code"} />
                <div className="pl-2">
                  <CardCvcElement onChange={handleChange} {...createOptions} />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-end mt-2">
                <Button className="mr-3" secondary clickHandler={close}>
                  <Bold14Font textColor={theme.colors.blue}>Cancel</Bold14Font>
                </Button>
                <Button
                  type={"submit"}
                  disabled={isLoading}
                  primary
                  loading={isLoading}
                  clickHandler={handleSubmit}
                >
                  {confirmButtonText}
                </Button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </Fragment>
  );
};

ModalForm.propTypes = {
  confirmButtonText: PropTypes.string,
};

ModalForm.defaultProps = {
  confirmButtonText: "Add Card",
};

export default withTheme(ModalForm);
