import React, { Fragment, useState, useEffect } from "react";
import { getPagesUnAuthList } from "../../Auth/auth";
import { useDispatch } from "react-redux";
import StaticPageLayout from "../StaticPageLayout/StaticPageLayout";
import PulseLoader from "react-spinners/PulseLoader";
import { theme } from "../../../styled-components/Theme/Theme";
import FormattedContentDivStyled from "../../Admin/PagesAndTutorials/styled/FormattedContentDivStyled";
import { Bold30Font } from "../../../components/FontsNewComponent/Fonts";

export const FLOW_PRIVACY_POLICY = "STATIC_PAGES/FLOW_PRIVACY_POLICY";
export const FLOW_TERMS_AND_CONDITIONS =
  "STATIC_PAGES/FLOW_TERMS_AND_CONDITIONS";
export const FLOW_SECURITY_STATEMENT = "STATIC_PAGES/FLOW_SECURITY_STATEMENT";
export const FLOW_COOKIES_POLICY = "STATIC_PAGES/FLOW_COOKIES_POLICY";

const selectFilterFunction = (flow) => {
  switch (flow) {
    case FLOW_PRIVACY_POLICY:
      return (el) => el.link === "privacy-policy";
    case FLOW_TERMS_AND_CONDITIONS:
      return (el) => el.link === "terms-and-conditions";
    case FLOW_SECURITY_STATEMENT:
      return (el) => el.link === "security-statement";
    case FLOW_COOKIES_POLICY:
      return (el) => el.link === "cookies-policy";
    default:
      return (el) => el;
  }
};

const StaticPage = ({ flow }) => {
  // page states
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  // data states
  const [pageDataObject, setPageDataObject] = useState(null);

  const saveData = (resp) => {
    if (Array.isArray(resp)) {
      setPageDataObject(resp.filter(selectFilterFunction(flow))[0]);
    } else {
      setPageDataObject(resp);
    }
  };

  const getDataFromAPI = async () => {
    if (isLoading) {
      const resp = await getPagesUnAuthList(flow, dispatch);
      if (resp) {
        saveData(resp);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getDataFromAPI();
  }, []);

  if (isLoading) {
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <PulseLoader size={30} color={theme.colors.blue} />
      </div>
    );
  }

  return (
    <Fragment>
      <StaticPageLayout
        {...(flow === FLOW_SECURITY_STATEMENT
          ? { style: { overflow: "hidden" } }
          : null)}
      >
        <div style={{ marginBottom: "16px" }}>
          <Bold30Font>{pageDataObject.title}</Bold30Font>
        </div>
        <FormattedContentDivStyled
          dangerouslySetInnerHTML={{ __html: pageDataObject.body }}
        />
      </StaticPageLayout>
    </Fragment>
  );
};

export default StaticPage;
