import React from "react";
import SelectStyled from "./styled/SelectStyled";
const Select = ({
  options,
  classNamePrefix,
  name,
  onChangeHandler,
  ...props
}) => {
  // return <SelectBase {...props} styles={colourStyles}/>;
  return (
    <SelectStyled
      name={name}
      options={options}
      classNamePrefix={classNamePrefix}
      onChange={onChangeHandler}
      // defaultMenuIsOpen
      {...props}
    />
  );
};

export default Select;
