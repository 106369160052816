import styled from "styled-components/macro";

const UploadSingleFileDivStyled = styled.div`
  width: 100%;
  height: 42px;
  border-radius: 21px;
  border: 3px dashed ${({ theme, hoover }) => hoover ? theme.colors.blueHover : theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :hover {
    border-color: ${({ theme }) => theme.colors.blueHover};
  }
`;

export default UploadSingleFileDivStyled;
