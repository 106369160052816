import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { ThemeContext } from "styled-components/macro";
import Label from "../AddCardForm/Label";
import { useDispatch, useSelector } from "react-redux";
import {
  getBundles,
  getDocument,
  updateEvidence,
} from "../../../containers/Auth/auth";
import ListActionButton from "../../buttons/ListActionButton/ListActionButton";
import { Bold14Font, Medium14Font } from "../../FontsNewComponent/Fonts";
import ButtonNew from "../../buttons/Button/ButtonNew";
import {
  HIDE_RIGHT_SIDE_BAR,
  SET_EDITABLE_OBJECT,
  SHOW_RIGHT_SIDE_BAR,
} from "../../../redux/types";
import { useHistory } from "react-router-dom";
import EvidenceFurtherDetailsForm from "./EvidenceFurtherDetailsForm";
import AssociateWitnessForm from "../EvidenceForms/AssociateWitnessForm";
import AssociateElementsThemesIssuesForm from "../EvidenceForms/AssociateElementsThemesIssuesForm";
import { ShowCoa, ShowIssues, ShowThemes } from "../../../utils/function";
import RsbTabsMenu from "../../RSBTabsMenu";
import TextAreaSideBar from "./TextAreaSideBar";
import moment from "moment";
import SelectAnotherTry from "../../Select/SelectAnotherTry";
import EvidenceObjectivesForm from "./EvidenceObjectivesForm";
import EvidenceSubEvidenceForm from "./EvidenceSubEvidenceForm";
import EvidenceNotesForm from "./EvidenceNotesForm";

const menuTabs = [
  { title: "Elements" },
  { title: "Themes" },
  { title: "Issues" },
];

const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  margin-bottom: 15px;
`;

const EvidenceDocProperties = ({ caseObject, callback, caseID }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenuTab, setActiveMenuTab] = useState(0);
  const [bundlesOptionsList, setBundlesOptionsList] = useState([]);
  const [selectedBundlesList, setSelectedBundlesList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const evidence = useSelector((state) => state.rightSideBar.editableObject);
  const is_admin = useSelector((state) => state.auth.user.is_admin);
  const form = useRef(null);
  const theme = useContext(ThemeContext);

  let witnesses = {
    Primary: [],
    Secondary: [],
  };

  const loadBundles = async () => {
    if (evidence.bundles.length) {
      setSelectedBundlesList(
        evidence.bundles.map((bundle) => ({
          label: bundle.label,
          value: bundle.id,
        }))
      );
    }
    const resp = await getBundles(dispatch, caseID);
    if (resp) {
      setBundlesOptionsList(
        resp
          .filter((bundle) => bundle.class_name === evidence.document_type)
          .map((bundle) => ({ label: bundle.label, value: bundle.id }))
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadBundles();
  }, []);

  const onBundleChange = (select) => {
    setSelectedBundlesList(select);
    if (select) {
      updateObject({
        bundles: select.map((bundle) => ({
          id: bundle.value,
          label: bundle.label,
        })),
      });
    } else {
      updateObject({
        bundles: [],
      });
    }
  };

  evidence.witnesses.forEach((w) => {
    if (!witnesses[w.type]) {
      witnesses[w.type] = [];
    }
    witnesses[w.type].push(w);
  });

  const evidenceWithCase = () => {
    const evidenceCopy = evidence;
    evidenceCopy.case = { ...caseObject };
    return evidenceCopy;
  };

  const editButton = (type_) => {
    let title_;
    if (type_ === "causes_of_action") {
      title_ = "Elements";
    } else if (type_ === "issues") {
      title_ = "Issues";
    } else if (type_ === "themes") {
      title_ = "Themes";
    }
    return (
      <ListActionButton
        clickHandler={() => {
          dispatch({
            type: SHOW_RIGHT_SIDE_BAR,
            url: history.location.pathname,
            editableObject: evidence,
            content: (
              <AssociateElementsThemesIssuesForm
                key={evidence.id}
                type={type_}
                evidenceObject={evidenceWithCase()}
                setEvidenceObject={updateObject}
                evidenceId={evidence.id}
                caseObject={caseObject}
                caseId={caseID}
              />
            ),
            swapContent: {
              content: (
                <EvidenceDocProperties
                  callback={callback}
                  caseObject={caseObject}
                  caseID={caseID}
                />
              ),
              title: "Doc Properties",
            },
            title: title_,
          });
        }}
        type={"edit"}
      />
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoading) {
      setIsLoading(true);
      const data_ = {
        bundles: evidence.bundles,
        causes_of_action_parent: evidence.causes_of_action_parent,
        causes_of_action: evidence.causes_of_action,
        causes_of_action_defensive: evidence.causes_of_action_defensive,
        themes_parent: evidence.themes_parent,
        themes: evidence.themes,
        subtype: evidence.subtype,
        issues_parent: evidence.issues_parent,
        issues: evidence.issues,
        sub_evidence: evidence.sub_evidence,
        related_evidence: evidence.related_evidence,
        objectives: evidence.objectives,
        witnesses: evidence.witnesses,
        notes: evidence.notes,
        summary: evidence.summary,
        preempt: evidence.preempt,
        from: evidence.from,
        to: evidence.to,
        date: evidence.date,
        type: evidence.type,
        priority: evidence.priority,
        core_book: evidence.classes.core_book ? 1 : 0,
      };
      await updateEvidence(evidence.classes.fake_id, caseID, dispatch, data_);
      let resp = await getDocument(
        caseObject.id,
        evidence.file_id ?? evidence.id,
        dispatch
      );
      callback(resp);
      setIsLoading(false);
      dispatch({
        type: HIDE_RIGHT_SIDE_BAR,
      });
    }
  };
  const updateObject = (resp) => {
    let obj = { ...evidence, ...resp };
    dispatch({ type: SET_EDITABLE_OBJECT, editableObject: obj });
  };

  const setEditBtnType = (activeTab) => {
    switch (activeTab) {
      case 0:
        return "causes_of_action";
      case 1:
        return "themes";
      case 2:
        return "issues";

      default:
        throw new Error("Wrong tab index");
    }
  };

  const onClickEditOnTrialHubHandler = () => {
    history.push(
      !is_admin
        ? `/app/cases/${caseID}/trial-hub/evidence/edit/${evidence.classes.fake_id}`
        : `/admin/all-cases/${caseID}/trial-hub/evidence/edit/${evidence.classes.fake_id}`
    );
  };

  return (
    <Fragment>
      <div style={{ height: "100%", overflowY: "auto" }}>
        <div className="form-group d-flex mb-4 pr-3">
          <SelectAnotherTry
            label="Assign to bundle"
            options={bundlesOptionsList}
            isClearable={false}
            isMulti
            placeholder={"Select bundle"}
            value={selectedBundlesList}
            defaultValue={
              selectedBundlesList && selectedBundlesList.length
                ? bundlesOptionsList.filter((v) =>
                    selectedBundlesList.find((b) => b.id === v.value)
                  )
                : []
            }
            reactSelectStyles={{
              control: (provided) => ({
                ...provided,
                height: "unset !important",
                minHeight: "42px !important",
                borderRadius: "21px !important",
              }),
            }}
            onChange={onBundleChange}
          />
        </div>
        <Separator />
        <Bold14Font as="div" className="mb-2" style={{ marginLeft: "15px" }}>
          Relevance
        </Bold14Font>
        <div className="form-group d-flex mb-4 pr-3">
          <RsbTabsMenu
            tabs={menuTabs}
            activeMenuTab={activeMenuTab}
            setActiveMenuTab={setActiveMenuTab}
          />
          {editButton(setEditBtnType(activeMenuTab))}
        </div>
        {activeMenuTab === 0 ? (
          <div className="form-group">
            {/*<Label name={"Elements"} button={editButton("causes_of_action")} />*/}
            <div className="d-flex pl-2 pr-2 flex-wrap">
              <ShowCoa object={evidence} case_object={caseObject} />
            </div>
          </div>
        ) : null}
        {activeMenuTab === 1 ? (
          <div className="form-group">
            {/*<Label name={"Themes"} button={editButton("themes")} />*/}
            <div className="d-flex pl-2 pr-2 flex-wrap">
              <ShowThemes object={evidence} case_object={caseObject} />
            </div>
          </div>
        ) : null}
        {activeMenuTab === 2 ? (
          <div className="form-group">
            {/*<Label name={"Issues"} button={editButton("issues")} />*/}
            <div className="d-flex pl-2 pr-2 flex-wrap">
              <ShowIssues object={evidence} case_object={caseObject} />
            </div>
          </div>
        ) : null}
        <Separator />
        <div className="form-group">
          <Label
            name={"Witness"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <AssociateWitnessForm
                        key={evidence.id}
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseID}
                        isDocPropertiesFlow
                      />
                    ),
                    swapContent: {
                      content: (
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />
                      ),
                      title: "Doc Properties",
                    },
                    title: "Witness",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {Object.keys(witnesses).map((w_k) => {
            return !witnesses[w_k].length ? null : (
              <div className="container-fluid">
                <div className="mb-2">
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {w_k}
                  </Medium14Font>
                </div>
                <div className="d-flex flex-column">
                  {witnesses[w_k].map((witness_, index) => {
                    return (
                      <Medium14Font
                        className={index > 0 ? "ml-2" : ""}
                        textColor={theme.colors.dark}
                      >
                        {witness_.name}
                      </Medium14Font>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <Separator />
        <div className="form-group">
          {evidence.summary ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Summary: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.summary}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.preempt ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Pre-empt: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.preempt}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
        </div>
        <div className="form-group">
          <Label
            name={"Notes / Comments"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <EvidenceNotesForm
                        key={evidence.id}
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseID}
                        fromDocs
                      />
                    ),
                    swapContent: {
                      content: [
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />,
                      ],
                      title: ["Doc Properties"],
                    },
                    title: "Notes / Comments",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {evidence.notes
            ? evidence.notes.map((obj, index) => {
              return (
                <div className="container-fluid">
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {index + 1}. {obj.content}
                  </Medium14Font>
                </div>
              );
            })
            : null}
        </div>
        <Separator />
        <div className="form-group">
          <Label
            name={"Objectives"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <EvidenceObjectivesForm
                        key={evidence.id}
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseID}
                        fromDocs
                      />
                    ),
                    swapContent: {
                      content: [
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />,
                      ],
                      title: ["Doc Properties"],
                    },
                    title: "Objectives",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {evidence.objectives
            ? evidence.objectives.map((obj, index) => {
                return (
                  <div className="container-fluid">
                    <Medium14Font textColor={theme.colors.darkOpacity}>
                      {index + 1}. {obj.name}
                    </Medium14Font>
                  </div>
                );
              })
            : null}
        </div>
        <Separator />
        <div className="form-group">
          <Label
            name={"Key Sections"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <EvidenceSubEvidenceForm
                        key={evidence.id}
                        evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseID}
                        fromDocs
                      />
                    ),
                    swapContent: {
                      content: [
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />,
                      ],
                      title: ["Doc Properties"],
                    },
                    title: "Key Sections",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {evidence.sub_evidence
            ? evidence.sub_evidence.map((obj, index) => {
              return (
                <div className="container-fluid">
                  <Medium14Font textColor={theme.colors.darkOpacity}>
                    {index + 1}. {obj.label}
                  </Medium14Font>
                </div>
              );
            })
            : null}
        </div>
        <Separator/>
        <div className="form-group">
          <Label
            name={"Further Details"}
            button={
              <ListActionButton
                clickHandler={() => {
                  dispatch({
                    type: SHOW_RIGHT_SIDE_BAR,
                    url: history.location.pathname,
                    editableObject: evidence,
                    content: (
                      <EvidenceFurtherDetailsForm
                        key={evidence.id}
                        // evidenceObject={evidence}
                        setEvidenceObject={updateObject}
                        evidenceId={evidence.id}
                        caseObject={caseObject}
                        caseId={caseID}
                        callback={callback}
                      />
                    ),
                    swapContent: {
                      content: (
                        <EvidenceDocProperties
                          callback={callback}
                          caseObject={caseObject}
                          caseID={caseID}
                        />
                      ),
                      title: "Doc Properties",
                    },
                    title: "Further Details",
                  });
                }}
                type={"edit"}
              />
            }
          />
          {evidence.date ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Date: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {moment(evidence.date).format("DD-MM-YYYY")}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.type ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Evidence Type: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.type}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.subtype ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Sub-Type: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.subtype}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.priority ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>Priority: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.priority}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.from ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>From: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.from}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
          {evidence.to ? (
            <Fragment>
              <div className="container-fluid">
                <Bold14Font>To: </Bold14Font>
                <Medium14Font textColor={theme.colors.darkOpacity}>
                  {evidence.to}
                </Medium14Font>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      <div>
        <div className="form-group">
          <div className="container-fluid">
            <ButtonNew
              wide
              secondary
              loading={isLoading}
              disabled={isLoading}
              clickHandler={onClickEditOnTrialHubHandler}
            >
              Edit in Trial Hub
            </ButtonNew>
          </div>
        </div>
        <Separator />
        <div className="form-group">
          <div className="container-fluid">
            <form onSubmit={handleSubmit} id={``} ref={form}>
              <ButtonNew
                wide
                primary
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                // clickHandler={handleSubmit}
              >
                Save
              </ButtonNew>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default EvidenceDocProperties;
