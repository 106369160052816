import styled from "styled-components/macro";

const IndexStyled = styled.span`
  width: 24px;
  height: 24px;
  text-align: center;
  font-family: "PT Root UI";
  font-size: 14px;
  margin-right: 15px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  border-radius: 12px;
  background-color: ${({theme, active}) => active ? theme.colors.blue : 'transparent;'};
  color: ${({theme, active}) => active ? theme.colors.white : theme.colors.darkOpacity};
  ${({active, theme}) => !active ? 'border: 1px solid ' + theme.colors.gray : ''}
`;

export default IndexStyled;
