import React, { Fragment } from "react";
import Table from "../../../../../components/Table/Table";
import NoRecords from "../../../../../components/NoRecords";
import { Bold18Font } from "../../../../../components/FontsNewComponent/Fonts";

const TopicsAndObjectives = ({
  witnessObject,
  evidenceTableSettings,
  selectedId,
}) => {
  return (
    <div className="container-fluid">
      {witnessObject && witnessObject.length ? (
        <Table
          selectedId={selectedId}
          settings={evidenceTableSettings}
          data={witnessObject.sort((a, b) => a.id - b.id)}
          hideTitle
        />
      ) : (
        <NoRecords>
          <Bold18Font textColor={"#0f122f"}>
            Your Linked Topics will appear here
          </Bold18Font>
        </NoRecords>
      )}
    </div>
  );
};

export default TopicsAndObjectives;
