import styled from "styled-components/macro";
import TertiaryButtonStyled from "../styled/TertiaryButtonStyled";
import { Medium14Font } from "../../../Fonts/Fonts";

const NavBarButtonsStyled = styled(TertiaryButtonStyled)`
  //outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.whiteOpacity};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
  ${Medium14Font} {
    color: ${({ theme }) => theme.colors.whiteOpacity};
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  ${({ isActive, theme }) =>
    isActive
      ? `
    color: ${theme.colors.white};
    border-bottom: 3px solid #259b9a;
  `
      : `margin-bottom: 3px`};
`;

export default NavBarButtonsStyled;
