import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import React from "react";

export default () => {
  return (
    <NodeViewWrapper
      className="react-component-with-content"
      style={{
        border: "1px solid blue",
        display: "inline-flex",
      }}
    >
      <NodeViewContent className="content" />
      <button>create</button>
      <button>cancel</button>
    </NodeViewWrapper>
  );
};
